import { Component, OnInit } from '@angular/core';
import { NgbCalendar, NgbDateParserFormatter, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { Router } from 'express-serve-static-core';
import { LedgerService } from '../../services/ledger.service';
import { HttpServiceService } from '../../services/http/http-service.service';
import { TranslateService } from '@ngx-translate/core';
import { BillBookervice } from '../../services/billBook.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-item-filters',
  templateUrl: './item-filters.component.html',
  styleUrls: ['./item-filters.component.scss']
})
export class ItemFiltersComponent implements OnInit {
  selectedOption: string = 'All';
  checkVal:any;
  listing$: Observable<any>;
  TimeValue = 'all';
  dateArray1: any = []
  dateArray2: any = []
  selectedOptionfilter: string;
  timeFilter:string;
  item1: string;
  modal: any;
  filterInvoiceNumber: any;
  selectedDate: NgbDateStruct;
  formattedDate: string;
  bundletype: boolean;

  constructor(public auth: AuthService,public translate: TranslateService,private calendar: NgbCalendar,public bookService: BillBookervice,
    public datepipe: DatePipe,public ledgerService: LedgerService,private http: HttpServiceService,public modalService: NgbModal,private ngbDateParserFormatter: NgbDateParserFormatter) { }

  ngOnInit(): void {
  }

  formatDate() {
    if (this.selectedDate) {
      const year = this.selectedDate.year;
      const month = this.selectedDate.month < 10 ? `0${this.selectedDate.month}` : this.selectedDate.month;
      const day = this.selectedDate.day < 10 ? `0${this.selectedDate.day}` : this.selectedDate.day;
  
      this.formattedDate = `${year}-${month}-${day}`;
    } else {
      this.formattedDate = ''; // Handle the case where no date is selected
    }
    console.log(this.formattedDate);
  }
  
  activeunc() {
    var header = document.getElementById("myDIV");
    var btns = header.getElementsByClassName("btn");
    for (var i = 0; i < btns.length; i++) {
      btns[i].addEventListener("click", function () {
        var current = document.getElementsByClassName("active");
        current[0].className = current[0].className.replace(" active", "");
        this.className += " active";
      });
    }

  }
  onDateSelect(date: NgbDateStruct): void {
    // Format the date to yyyy-mm-dd
    const formattedDate = this.ngbDateParserFormatter.format(date);
    console.log(formattedDate); // Log the date to the console
  }
  togglesentReceived(option:string){
    this.selectedOption = option;
    this.bookService.billBookAllFilters.invoice_status_type=option;
  }
 

}
