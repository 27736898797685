import { Component, OnInit,EventEmitter, Output } from '@angular/core';
import { BillBookervice } from 'src/app/shared/services/billBook.service';
import { AuthService } from '../../services/auth.service';
import { MarketService } from '../../services/market.service';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit {
  commentDesc: string;
  id: any;
  dateFormat: any;
  @Output() closeModalEvent = new EventEmitter<void>();
  constructor(public bookService: BillBookervice, public auth: AuthService,public marketService: MarketService) { }

  ngOnInit(): void {
    this.marketService.getComments(this.marketService.selectedProd.id);
    this.bookService.getComments(this.bookService.selectedInvoice.id);

  }
  onBackButtonClick() {
    this.closeModalEvent.emit();
  }
  DateFormatepipe() {
    if (this.auth.userData.emp_info.Business.default_date_format.id === 1) {
      this.dateFormat = 'dd-MM-yyyy';
    } else if (
      this.auth.userData.emp_info.Business.default_date_format.id === 2
    ) {
      this.dateFormat = 'MM-dd-yyyy';
    } else {
      this.dateFormat = 'yyyy-MM-dd';
    }
  }
  postComment(){
    this.id= this.bookService.selectedInvoice.id
    this.bookService.postComments(this.commentDesc,this.id);
    this.commentDesc='';
  }
  postCommentfromMarket(){
    this.id = this.marketService.selectedProd.id
    this.marketService.postComments(this.commentDesc,this.id);
    this.commentDesc='';
  }
}
