// import { BrowserModule } from '@angular/platform-browser';
// import { NgModule } from '@angular/core';
// import { FormsModule, ReactiveFormsModule, } from '@angular/forms';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { AppRoutingModule } from './app-routing.module';
// import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
// import { AppComponent } from './app.component';
// import { CookieService } from 'ngx-cookie-service';
// import { AdminGuard } from './shared/guard/admin.guard';
// import { HttpInterceptors } from './shared/services/_interceptors/http.interceptors';
// import { HttpErrorInterceptor } from './shared/services/_interceptors/http-error.interceptor';
// import {CommonModule, DatePipe} from '@angular/common';
// import { SharedModule } from './shared/shared.module';
// import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
// import { TranslateHttpLoader } from '@ngx-translate/http-loader';
// import { ToastrModule } from 'ngx-toastr';

// export function HttpLoaderFactory(http: HttpClient) {
//   return new TranslateHttpLoader(http, './assets/i18n/', '.json');
// }
// import { SocialLoginModule, SocialAuthServiceConfig, 
//   GoogleLoginProvider, FacebookLoginProvider 
// } from 'angularx-social-login';
// import { PricingComponent } from './pricing/pricing.component';
// import { PaymentSuccessComponent } from './components/user/user-profile/payment-success/payment-success.component';
// import { PaymentErrorComponent } from './components/user/user-profile/payment-error/payment-error.component';
// @NgModule({
//     declarations: [
//         AppComponent,
//         PricingComponent,
//         PaymentSuccessComponent, 
//         PaymentErrorComponent
//     ],
//     imports: [
//         SocialLoginModule,
//         BrowserModule.withServerTransition({ appId: 'serverApp' }),
//         // TransferHttpCacheModule,
//         CommonModule,
//         BrowserAnimationsModule,
//         SharedModule,
        
        
//         AppRoutingModule,
//         HttpClientModule,
//         ToastrModule.forRoot(),
//         TranslateModule.forRoot({
//           loader: {
//               provide: TranslateLoader,
//               useFactory: HttpLoaderFactory,
//               deps: [HttpClient]
//           },
//       }),
//     ],
//     providers: [
//         DatePipe, AdminGuard, CookieService,
        
//         {
//             provide: HTTP_INTERCEPTORS,
//             useClass: HttpInterceptors,
//             multi: true
//         }, {
//             provide: HTTP_INTERCEPTORS,
//             useClass: HttpErrorInterceptor,
//             multi: true
//         },
//         {
//           provide: 'SocialAuthServiceConfig',
//           useValue: {
//             autoLogin: false,
//             providers: [
//               {
//                 id: GoogleLoginProvider.PROVIDER_ID,
//                 provider: new GoogleLoginProvider(
//                   '191283059503-0n2eedc1j04kceb4qr93hdio5tfnmh0f.apps.googleusercontent.com'
//                 )
//               },
//               {
//                 id: FacebookLoginProvider.PROVIDER_ID,
//                 provider: new FacebookLoginProvider('330112898492776')
//               }
//             ]
//           } as SocialAuthServiceConfig,
//         }
//     ],
//     bootstrap: [AppComponent]
// })
// export class AppModule {
// }

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { CookieService } from 'ngx-cookie-service';
import { AdminGuard } from './shared/guard/admin.guard';
import { HttpInterceptors } from './shared/services/_interceptors/http.interceptors';
import { HttpErrorInterceptor } from './shared/services/_interceptors/http-error.interceptor';
import { CommonModule, DatePipe } from '@angular/common';
import { SharedModule } from './shared/shared.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToastrModule } from 'ngx-toastr';
//social
//  import { SocialLoginModule, SocialAuthServiceConfig, GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
import { PricingComponent } from './pricing/pricing.component';
import { PaymentSuccessComponent } from './components/user/user-profile/payment-success/payment-success.component';
import { PaymentErrorComponent } from './components/user/user-profile/payment-error/payment-error.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    PricingComponent,
    PaymentSuccessComponent,
    PaymentErrorComponent
  ],
  imports: [
    //social
    // SocialLoginModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    CommonModule,
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    }),
  ],
  //social
  providers: [
    DatePipe,
    AdminGuard,
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptors,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    // {
    //   provide: 'SocialAuthServiceConfig',
    //   useValue: {
    //     autoLogin: false,
    //     providers: [
    //       {
    //         id: GoogleLoginProvider.PROVIDER_ID,
    //         provider: new GoogleLoginProvider(
    //           '191283059503-0n2eedc1j04kceb4qr93hdio5tfnmh0f.apps.googleusercontent.com'
    //         )
    //       },
    //       {
    //         id: FacebookLoginProvider.PROVIDER_ID,
    //         provider: new FacebookLoginProvider('330112898492776')
    //       }
    //     ]
    //   } as SocialAuthServiceConfig,
    // }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }




