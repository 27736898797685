<div class="dir-ltr-fix">
    <div class="main-wrapper bg-white mx-0 my-3 py-2 position-relative b-r-20" >
        <div class="business-wraper">
            <div>
                <div>
                                
                    <h2 class="heading"><img class="img-30 cursor-pointer" (click)="backButton()"
                        src="../../assets/Back-white.svg">   <span> Contact Us </span></h2>
                </div>
                <div class="d-flex justify-content-center">
                    <div class="card">
                        <div class="card-body">
                         <p class="d-flex"><img src="../../assets/Location.svg" class="mr-5" alt="">701 Tillery Street, Unit 12-2093, Austin, Texas 78702, United States</p>
                         <p class="d-flex"><img src="../../assets/phone.svg" class="mr-5" alt="">+1 512 200 4696</p>
                         <p class="d-flex"><img src="../../assets/email.svg" class="mr-5" alt="">admin@accountect.com</p>
                            
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-center mt-4">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d59001840.85325654!2d-155.0743845!3d25.4988234!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c259badc2f3b9f%3A0xcb68a90994a5896e!2sCiwac%20Connected%20Accounting%20Inc.!5e0!3m2!1sen!2s!4v1671085655340!5m2!1sen!2s" width="750" height="350" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </div>
    </div>
</div>
