import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment.prod';
import { HttpServiceService } from '../../services/http/http-service.service';

@Component({
  selector: 'app-payment-reminder',
  templateUrl: './payment-reminder.component.html',
  styleUrls: ['./payment-reminder.component.scss']
})
export class PaymentReminderComponent implements OnInit {
  public build_type = environment.build_type;
  id: any
  user:any
  data:any
  sender:any
  constructor(public route: ActivatedRoute,private http: HttpServiceService,public toast: ToastrService,) { }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((params) => {
      this.user = params.get('user');
      this.id = params.get('id');
      
        this.paymentRe();
    });
  }
  paymentRe() {
    this.http.getpaymentLink(this.user, this.id).subscribe(res => {
      //  console.log('this is the ledger list', res);
      this.data = res['ledger_info']
      this.sender = res.sender_business
    }, (error) => {
      this.toast.error(error['error']['msg'])
    })
  }
}
