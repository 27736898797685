import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LedgerService } from '../../services/ledger.service';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent implements OnInit {

  constructor(private modalService: NgbModal, public ledgerService: LedgerService) { }

  ngOnInit(): void {
  }
  modalClose(){

    document.getElementById('snackbarClose').setAttribute("style", "display:none");
      }
}
