<div class="SUCCESSS">  
  <div class="bg-white mt-2">
    <img src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/payment successful.svg" class="img-500" alt="">
  </div>
  <div>
    <p>{{'Payment Success' | translate}}</p>
  </div>
  <div class="BtN mt-4">
    <button type="button" class="btn btn-primary" [routerLink]="['/']">
      <img class="img-30" src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/White tick.svg" />
    </button>
  </div>
</div>
  
