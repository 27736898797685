import { ElementRef, EventEmitter, Injectable, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbCalendar, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { environment } from 'src/environments/environment.prod';
import { AuthService } from './auth.service';
import { HttpServiceService } from './http/http-service.service';
import Swal from 'sweetalert2';
import { EmployeeService } from './employee.service';
import { TranslateService } from '@ngx-translate/core';
import { LedgerRightWindowComponent } from 'src/app/components/ledger/ledger-right-window/ledger-right-window.component';
import { debug, error } from 'console';
import { AudioRecordingService } from 'src/app/audio-recording.service';
import { PlanExpiredPopupComponent } from '../components/plan-expired-popup/plan-expired-popup.component';
import { MarketService } from './market.service';
declare var $: any;
@Injectable({
  providedIn: 'root'
})
export class LedgerService {

  openLedgerEvent: EventEmitter<{ accountName: string, invoiceType: number }> = new EventEmitter<{ accountName: string, invoiceType: number }>();

  subject: Subject<any> = new Subject();
  subjectMember: Subject<any> = new Subject();
  subjectLedger: Subject<any> = new Subject();
  notificationEvent: Subject<any> = new Subject();
  public invokeFunction = new EventEmitter();
  public socket: any;
  public SocketUrl
  limitmodal: any
  penddingRequests: any = []
  @ViewChild('maxlimit', { static: true })
  maxlimit: ElementRef;
  oldLedger: any
  limitnew: boolean = false
  Edititem:boolean = false;
  stockByRef: boolean = false
  public build_type = environment.build_type;
  public timer = 1000;
  stockbyref: boolean = false
  searchtype: any;
  public isPublicSelected: boolean = false;
  updateledger: boolean = false
  searchList: any;
  bankRoomID: any
  CardRoomID:any;
  CardRoom:any;
  ledgerView: boolean = true
  ledgerViewJ: boolean = true
  ServiceSelected: boolean = false
  bankId: any;
  makeadmin:any
  saletran: boolean = false
  selectedBuss: any = [];
  bankTransData: any
  selectedref: any
  share: boolean = false
  public isEditVal: boolean = false;
  ledgers: any;
  selectedLedger: any = '';
  search: boolean = false
  selectedTrans: any;
  showLedger: boolean = true;
  NotshowLedger:boolean = false
  SummaryView: boolean = false;
  showVendor: boolean=true;
  loadTrans: boolean = false;
  bookloader:boolean = false;
  loader: boolean=false;
  InvLoader: boolean=false;
  urlUser: string = '';
  prestartDate: any
  preendDate: any
  preserachquerey: any
  pretranstype: any
  loadBusi: boolean;
  imgload: boolean = false
  shareTransType: any
  cashDetail: any
  expenseDetailData: any
  bankDetailData: any
  PurchaseDetailData: any
  saleDetailData: any
  applied: boolean = false
  ledgerSubType: any
  searchvalue: any
  currentpage: any
  custVendLoadData:any
  dataFromRightWindow:any;
  bank_Id:any;
  card_Id:any;
  trans_type:any;
  invoice_Id:any;
  record_type:any="cash";
  totalpage: any
  ledgerLimitValue: any
  page = 1
  nextpagedata: any = []
  memberslist: any;
  minimumStockData: any;
  selectBusinessName: any='';
  selectBusinessNameAccount: any='';
  ledgersReqs: any;
  addLedgerForm: FormGroup;
  adjustmentForm: FormGroup;
  adjustmentFormBundle: FormGroup;
  addCashBookForm: FormGroup;
  addExpenseForm: FormGroup
  addSaleBookForm: FormGroup
  addBankForm: FormGroup;
  addCreditCard: FormGroup;
  addProduct:FormGroup;
  addBundle:FormGroup;
  addService:FormGroup;
  addInventory: FormGroup;
  addProductCat:FormGroup
  editInventory: FormGroup
  stockOut: FormGroup
  ledgerFilters: FormGroup;
  addPurchaseBookForm: FormGroup
  led_ListFilter: FormGroup;
  inviteOneWay: FormGroup;
  invitemember:FormGroup;
  addBankTansForm: FormGroup;
  addCreditTansForm:FormGroup;
  ledgerLimit: FormGroup;
  editBankForm: FormGroup;
  editCardForm:FormGroup;
  editTransaction: FormGroup;
  editOpeningBalance:FormGroup;
  selectedSubtype: any;
  previewUrl: any = [];
  uploadFile: boolean = false;
  fileData: any;
  invImg: any
  creditDetails:any
  loadLedgers: boolean = false;
  business_id:any;
  days: any
  subSnackbar: boolean;
  radioButon:boolean;
  bankNameforCashInOUT:any;
  urlTrans: any = '';
  singleTrans: any;
  businessMembers: any = [];
  InactiveMembers: any = []
  OtherbusinessMembers: any = [];
  OtherInactiveMembers: any = []
  roomCurrency: any;
  filters:any;
  // forBankCurrency
  reqfromadmin: any
  editEmployee: any = '';
  trans_types: any;
  cardIdeditdelete:any;
  allBanks: any;
  searchbyType: any = ''
  end_date: any = ''
  start_date: any = ''
  currentType: any
  foderData: any = []
  currentdata: any = []
  bankData: any
  cashbook_types: any
  ledger_currencies: any
  cashTrans: any = []
  cashTransData: any = []
  cashTransDataR: any = []
  purchaseTransData: any = []
  purchaseTransDataR: any = []
  expenseTransData: any = []
  expenseTransDataR: any = []
  categoryListing:any=[]
  saleTransData: any = []
  listing: any=[];
  bundleListing: any=[];
  cashcurrentPage = 1
  expensecurrentPage = 1
  transactioTypeforCashinOUt: any;
  banktransPage = 1
  idByref: any
  StockByRefData: any
  bankTransTotalPage: any
  stockByRefcurrentPage = 1
  stockByRefTotalPage: any
  salecurrentPage = 1
  purchasecurrentPage = 1
  stockcurrentPage = 1
  PstockcurrentPage = 1
  proCurrentPage = 1
  bundleCurrentPage =1
  Invallproduct: any
  productTotalPage: any
  bankcurrentPage = 1
  stockTotalPage: any
  deleteCard:any;
  roomId: any;
  // cashcurrentPage = 1
  cashTotalPage: any
  newreff: boolean = false
  expenseTotalPage: any
  purchaseTotalPage: any
  saleTotalPage: any
  bankTotalPage: any
  productStock: any
  productStockData: any
  InventoryPro: any
  invproduct: any = ''
  invservice: any = ''
  bundleSearch: any = ''
  Expense: any
  PurchaseTrans: any
  SaleData: any
  plusshare: boolean
  BankTrans: any
  CashFilters: FormGroup;
  DriveFilters: FormGroup;
  PurchaseFilters: FormGroup;
  ExpenseFilters: FormGroup;
  stockData: any
  quantity:number=1
  stockinByRef: FormGroup
  BatchList: any
  FinishProList: any
  StockProduct: any
  InventoryFilters: FormGroup;
  BundleFilters:FormGroup;
  SaleFilters: FormGroup;
  bankFilters: FormGroup;
  cardFilters: FormGroup;
  transLoad: boolean = false;
  OpentransLoad: boolean = false;
  transectionCurrency: any
  bankpostData: any
  ProDataByRef: any
  ledgerData: any
  cashpostData: any
  purchasepostData: any
  expensepostData: any
  SalePostData: any;
  response: boolean = false
  scroll: HTMLElement;
  querySearch: string = '';
  exptran: boolean = false
  ref: boolean = false
  purchtran: boolean = false
  cashshare: boolean = false
  bankshare: boolean = false
  shareafter: any
  backUpHistory: any
  ShareTranType: any
  shareSubType: any
  inventloader: boolean = false
  banksare: boolean = false
  leddataa: any;
  empData:any;
  crCardId:any;
  bankName:any;
  drivecurrentPage: any = 1;
  invnprotype: any=1;
  ledgerimgg: any;
  bankCardData:any;
  salaryimgg: any;
  employeeName:any;
  dataForled: any;
  dataForledCard:any;
  dataforEmployee:any;
  selectedlederFiles: any = "driveScreen";
  invoiice:boolean = false
  ledgerFolders: boolean = false
  SalaryFolders:boolean = false
  cashh: boolean = false;
  bundleStatus: boolean=false;
  public ShipCoutryId: any;
  public countryId: any;
  public subject1 = new Subject<any>();
  selDriveLedger: any = null;
  selDriveSalary: any = null;
  storageValue: any;
  ledgerService: any;
  selDriveLedgerName: any = null;
  driveTotalPage: any = 1;
  typeDrive: any;
  invoicebook: any;
  inVoiceRecord: any;
  selectedItem: any;
  tableData: any[] = [];
  tableDataEx: any[] = [];
  PurchasetableData:any[] = [];
  LedgertableData:any[] = [];
  selectedProductId: any;
  addingReference: boolean;
  editeCardId:any;
  editeCardTitle:any;
  isStockIn: boolean = false;
  stockType: any;
  cashModuleCashIn:boolean=false;
  cashModuleCashOut:boolean=false;
  ExistingRef: any;
  min: number = 1;
  selectedProductRef: any;
  stockoutProduct: any;
  uomtype: any;
  itemlistingId: any;
  PendingTransactionView: boolean = false;
  MainView: boolean = true;
  itemlistingtType: any;
  itemlistingVisibility:boolean;
  today = this.calendar.getToday();
  marketServiceLoad: boolean = false;
  selectedProductForDuplicate: any;
  findProductMarketOriented: any;
  isEditLedgerItemTableIndex: any = -1;
  loaderSale: boolean = false;
  selectCreditCard:any;
  selectCreditCardR:any;
  singleCardInOutDetails:any
  dynamicTitle: string;
  account_type: string;
  dynamicContent: string;
  dynamicContentExplain: string;
  accountTitle:string;
  dynamicTitleBody: string;
  dynamicTitleBodyExplain: string;
  dynamicBname:string;
  selectCreditCardDetail: any;
  cardDetails: any;
  addCreditCardAmount: any;
  invnCat:any;
  invncurrency: any;
  prefixNumeric: any='001';
  prefixAplha: any='';
  preFixType: any;
  bundleStockDetail: any;
  stockInformation: any;
  shippingCheck: boolean= false;
  preFixStatus: boolean=false;
  headingSubject = new BehaviorSubject<string>(''); // Default heading is an empty string
  heading$ = this.headingSubject.asObservable();
  rows: Array<{ field1: string; field2: string; field3: string }> = [];
  uom: any;
  cashListing: any=[];
  saleTransDataR: any = [];
  pendingtransactionCount: any;
  transactions_data: any=[];
  selectedOption: string;
  transactions_data_get: any=[];
  checkLimit: number=0;
  avalibleStorage: any;
  totalStorage: any;
  usedStorage: any;
  checkprefixtoggle: boolean;
  prefixText: string;
  cardPaymentType: any;
  invoiceId: any;
  selectAccount: any;
  purVend: boolean=true;
  expVend: boolean;
  selectedAccount: any;
  accountPaymentMethod: any;
  accountBankid: any;
  accountCardid: any;
  BundletStockData: any;
  BundleStockId: any;
  bundleType: boolean;
  currentMonth: string;
  slectedID: any;
  record_type_Rec:any;
  bank_id_Rec: any;
  credit_card_id: any;
  credit_card_id_Rec: any;
  cardNameRec: any;
  BankNameRec: any;
  InvallproductBundle: any=[];
  SummaryViewType: boolean= true;
  shareId: any;
  shareLink: any;
  sendbusinessreq: FormGroup;
  currentDay: string;
  invoiceFOLDER: any;
  connectBusId: any;
  Reverse:boolean= false;
  Flat:boolean=false;
  reverse: any;
  flat: any;
  itemMarketVal: any;
  bankTransDataR: any = [];

  addinvoiceCust: boolean=false;
  addinvoiceVend: boolean= false;
  selectedProdq: any;
  bundleRecord: Object;
  bankID: any;
  datepipe: any;
  BundleStock: any;
  CatID: any;

  // @ViewChild(PlanExpiredPopupComponent) planExpire;

  constructor(
    private calendar: NgbCalendar,
    private http: HttpServiceService,
    public auth: AuthService,
    public employeeService: EmployeeService,
    public toast: ToastrService,
    public translate: TranslateService,
    public router: Router,
    public audioService: AudioRecordingService,
    public modalService: NgbModal,
    public marketService: MarketService,
    public fb: FormBuilder) {
    if (this.build_type === 'ciwac') {
      this.SocketUrl = environment.prod_socket;
    }
    
  

    this.adjustmentForm = this.fb.group({
      product_id: [''],
      quantity: ['', [Validators.required, Validators.min(0)]],
      uom: [''],
      unit_price: [''],
      information: ['', Validators.required],
      item_files: [[]],
      created_at: [''],
      own_entry: true,
      stock_id: null,
      reference_no: null,
    });
    this.adjustmentFormBundle = this.fb.group({
      bundle_id: [''],
      quantity: ['', [Validators.required, Validators.min(0)]],
      reference_no: [''],
      information: [''],
      file: [],
      created_at: [''],
      adjustment: false
    });
    
    this.addLedgerForm = this.fb.group({
      id: [''],
      transaction_type: ['', Validators.required],
      amount: ['', Validators.required],
      starting_date: ['', Validators.required],
      ledger_room_id: ['', Validators.required],
      remarks: [''],
      information: ['', Validators.required],
      file: [],
      transaction_sub_type: ['', Validators.required],
      unit_price: [''],
      quantity: [''],
      sub_total: [''],
      is_other_expense: [false],
      cash_trans_id: [null],
      sale_trans_id: null,
      ledger_trans_id: null,
      purchase_trans_id: null,
      expense_trans_id: null,
      other_bank_trans_id: null,
      bank_id: null,
      invoice_id:[0],
      record_type:[''],
      credit_card_room_id:null,
     
    });

    this.editTransaction = this.fb.group({
      transaction_type: ['', Validators.required],
      amount_balance: ['', Validators.required],
      id: ['', Validators.required],
      starting_date: ['', Validators.required],
      transaction_sub_type: ['', Validators.required],
      is_other_expense: [false],
      remarks: [''],
      information: [''],
      file: [],
      transaction_items: [],
      quantity: null,
      unit_price: null,
      amount: null,
      ledger_room_id: null,
    })
    this.editOpeningBalance = this.fb.group({
      transaction_type: ['', Validators.required],
      starting_date: ['', Validators.required],
      amount_balance: ['', Validators.required],
      id: ['', Validators.required],
      information: [''],
      file: []
    })
    this.stockinByRef = this.fb.group({
      stock_id: [''],
      quantity: [''],
      information: [''],
      created_at: ['']
    });
    this.CashFilters = this.fb.group({
      transaction_type: [''],
      search_query: [''],
      start_date: [''],
      end_date: [''],
    });
    this.DriveFilters = this.fb.group({
      type: [''],
      search_query: [''],
      start_date: [''],
      end_date: [''],
    });
    this.ledgerLimit = this.fb.group({
      ledger_room_id: [''],
      max_amount: [''],
      max_date: [''],
    })
    this.editBankForm = this.fb.group({
      bank_room_id: [''],
      account_title: ['', Validators.required],
    })
    this.editCardForm=this.fb.group({
      account_title: [''],
      credit_card_room_id: ['',Validators.required]
    })
    this.PurchaseFilters = this.fb.group({
      transaction_type: [''],
      search_query: [''],
      start_date: [''],
      end_date: [''],
    });
    this.InventoryFilters = this.fb.group({
      transaction_type: [''],
      search_query: [''],
      start_date: [''],
      end_date: [''],
    });
    this.BundleFilters = this.fb.group({
      transaction_type: [''],
      search_query: [''],
      start_date: [''],
      end_date: [''],
    });
    this.ExpenseFilters = this.fb.group({
      transaction_type: [''],
      search_query: [''],
      start_date: [''],
      end_date: [''],
    });
    this.bankFilters = this.fb.group({
      transaction_type: [''],
      search_query: [''],
      start_date: [''],
      end_date: [''],
    });
    this.cardFilters = this.fb.group({
      transaction_type: [''],
      search_query: [''],
      start_date: [''],
      end_date: [''],
    });
    this.SaleFilters = this.fb.group({
      transaction_type: [''],
      search_query: [''],
      start_date: [''],
      end_date: [''],
      
    });
    this.addCashBookForm = this.fb.group({
       cash_transaction_type: ['', Validators.required],
      // cash_transaction_type: 1,
      amount: ['', Validators.required],
      starting_date: ['', Validators.required],
      // ledger_room_id: ['', Validators.required],
      remarks: [''],
      information: [''],
      file: [],
      sale_trans_id: null,
      ledger_trans_id: null,
      purchase_trans_id: null,
      amount_balance: null,
      expense_trans_id: null,
      other_bank_trans_id: null,
      cash_own_transaction: true,
      bank_id: null,
      cash_trans_id: null,
      id: null,
      record_type:null,
      credit_card_room_id:null
      // transaction_sub_type: ['', Validators.required]
    });
    this.addPurchaseBookForm = this.fb.group({
      purchase_transaction_type: ['', Validators.required],
      amount_balance: ['', Validators.required],
      starting_date: ['', Validators.required],
      // ledger_room_id: ['', Validators.required],
      // remarks: [''],
      information: [''],
      file: [],
      sale_trans_id: null,
      ledger_trans_id: null,
      purchase_trans_id: null,
      expense_trans_id: null,
      other_bank_trans_id: null,
      bank_id: null,
      cash_trans_id: null,
      id: null,
      unit_price: [''],
      quantity: [''],
      sub_total: [''],
      // transaction_sub_type: ['', Validators.required]
    });
    this.addSaleBookForm = this.fb.group({
      sale_transaction_type: ['', Validators.required],
      amount_balance: ['', Validators.required],
      starting_date: ['', Validators.required],
      information: [''],
      file: [],
      ledger_trans_id: null,
      purchase_trans_id: null,
      expense_trans_id: null,
      other_bank_trans_id: null,
      bank_id: null,
      cash_trans_id: null,
      unit_price: [''],
      quantity: [''],
      sub_total: [''],
      id: null
      // transaction_items: this.fb.array([
      //   unit_price: [''],
      //   quantity: [''],
      //   sub_total: [''],

      // ]),      
    });
    
    this.addExpenseForm = this.fb.group({
      // cash_transaction_type: ['', Validators.required],
      amount_balance: ['', Validators.required],
      starting_date: ['', Validators.required],
      // ledger_room_id: ['', Validators.required],
      // remarks: [''],
      information: [''],
      file: [],
      unit_price: [''],
      quantity: [''],
      sub_total: [''],
      sale_trans_id: null,
      ledger_trans_id: null,
      purchase_trans_id: null,
      expense_trans_id: null,
      other_bank_trans_id: null,
      bank_id: null,
      cash_trans_id: null,
      id: null,
       credit_card_room_id:null

      // transaction_sub_type: ['', Validators.required]
    });
    this.addBankTansForm = this.fb.group({
      bank_own_transaction:true,
      bank_transaction_type: ['', Validators.required],
      amount: ['', Validators.required],
      starting_date: ['', Validators.required],
      // ledger_room_id: ['', Validators.required],
      bank_room_id: ['', Validators.required],
      information: [''],
      file: [],
      id: null,
      sale_trans_id: null,
      ledger_trans_id: null,
      purchase_trans_id: null,
      expense_trans_id: null,
      other_bank_trans_id: null,
      bank_id: null,
      cash_trans_id: null,
      record_type:null,
      other_bank_room_id:null,
      credit_card_room_id:null,
      // transaction_sub_type: ['', Validators.required]
    });

    this.addCreditTansForm = this.fb.group({
      credit_card_own_transaction: true,
      credit_card_transaction_type: ['1'],
      amount: ['', Validators.required],
      credit_card_room_id:null,
      starting_date: ['', Validators.required],
      id:null,
      bank_room_id: 0,
      information: [''],
      file: [[0]], 
      record_type:null,
    });
   

    this.addBankForm = this.fb.group({
      bank_transaction_type: [''],
      amount: ['', Validators.required],
      starting_date: ['', Validators.required],
      account_title: [''],
      file:[[]],
      currency: [''],
    });
    this.addCreditCard=this.fb.group({
      credit_card_transaction_type: ['2', Validators.required],
      amount: ['', Validators.required],
      starting_date: ['', Validators.required],
      account_title: ['',Validators.required],
      information: '',
      currency:'',
      file:[[]],
    })
    this.addProduct = this.fb.group({
      created_at:[''],
      unit_price: ['', Validators.required],
      product_name: ['', Validators.required],
      reference_no: ['', Validators.required],
      quantity: ['', Validators.required],
      currency: ['', Validators.required],
      product_file: [],
      uom: [16, Validators.required],
      information: [''],
      market_visibility:[true],
      selling_price_per_unit: [''],
      service_status:[false],
      id: null,
    from_invoice: false,
    product_category: ['',Validators.required],
    minimum_stock_level: 0
    });

    this.addBundle = this.fb.group(
      {
        bundle_name:['', Validators.required],
        bundle_category:1,
        product_or_services_items:[],
        bundle_file:[[]],
        uom:2,
        information:[''],
        existing_stock:['', Validators.required],
        bundle_quantity:1,
        other_cost_per_bundle:['', Validators.required],
        bundle_cost:null,
        selling_price_per_unit:['', Validators.required],
        minimum_stock_level:null,
      }
    )
    this.addService = this.fb.group({
      unit_price: 0,
      product_name: ['', Validators.required],
      reference_no: [''],
       quantity: 0,
       currency: this.auth.userData.emp_info.Business?.currency?.id,
      product_file: [],
       uom: null,
      information: [''],
      market_visibility:true,
      selling_price_per_unit: ['',Validators.required],
      service_status:true,
      minimum_stock_level: 0,
      product_category:2,
      from_invoice: true,
      sales_price:['', Validators.required],
      id: null
    });
    // this.addService.get('market_visibility').valueChanges.subscribe((value) => {
    //   if (value) {
    //     this.addService.get('quantity').setValidators(Validators.required);
    //     this.addService.get('selling_price_per_unit').setValidators(Validators.required);
    //   } else {
    //     this.addService.get('quantity').clearValidators();
    //     this.addService.get('selling_price_per_unit').clearValidators();
    //   }
      
    //   this.addService.get('quantity').updateValueAndValidity();
    //   this.addService.get('selling_price_per_unit').updateValueAndValidity();
    // });
    // this.addProduct.get('market_visibility').valueChanges.subscribe((value) => {
    //   if (value) {
    //     this.addProduct.get('selling_price_per_unit').setValidators(Validators.required);
    //   } else {
    //     this.addProduct.get('selling_price_per_unit').clearValidators();
    //   }
    //   this.addProduct.get('selling_price_per_unit').updateValueAndValidity();
    // });
    this.addInventory = this.fb.group({
      id: null,
      product_room_id: [''], 
      unit_price: ['', Validators.required],
      product_name: ['', Validators.required],
      reference_no: ['Opening Stock'],
      quantity: ['', Validators.required],
      currency: this.auth.userData.emp_info.Business?.currency?.id,
      product_category:[''],
      product_id: [''],
      uom: [''],
      minimum_stock_level:[''],
      created_at: [''],
      information: [''],
      market_visibility:[true],
      product_file: [],
      selling_price_per_unit: ['',Validators.required],
      service_status:[true],
      sales_price:[''],
      own_entry: [true]
    });

    this.addProductCat = this.fb.group({
      title: ['', Validators.required],
    });
    this.editInventory = this.fb.group({
      product_name: ['', Validators.required],
      product_room_id: "",
      product_file: ""
    });
    this.stockOut = this.fb.group({
      information: [''],
      quantity: [null, Validators.required],
      stock_id: [''],
      stock_out_id: [''],
      created_at: [null, Validators.required],
    });
    this.ledgerFilters = this.fb.group({
      transaction_type: [''],
      debit_amount: [''],
      credit_amount: [''],
      search_query: [''],
      start_date: [''],
      end_date: [''],
    });
    this.inviteOneWay = this.fb.group({
      ledger_room_id: ['', Validators.required],
      type: ['', Validators.required],
      email_or_phone: ['', Validators.required],
    });
    this.invitemember = this.fb.group({
      email: ['', Validators.required],
    });
    this.sendbusinessreq = this.fb.group({
      email: ['', Validators.required],
    });
    this.led_ListFilter = this.fb.group({
      search_query: [''],
      filter_type: ['']
    });
    this.subject.pipe(distinctUntilChanged(), debounceTime(500)).subscribe((res) => {
      // ////  console.log('this is the response subject', res);
      if (true) {
        this.getAllBusines(res);
      }
    });
    this.subjectMember.pipe(distinctUntilChanged(), debounceTime(500)).subscribe((res) => {
      // ////  console.log('this is the response subject', res);
      if (true) {
        this.getAllMembers(res, this.searchtype);
      }
    });
    this.subjectLedger.pipe(distinctUntilChanged(), debounceTime(500)).subscribe((res) => {
      ////  console.log('this is the response subject', res);
      this.led_ListFilter.patchValue({
        search_query: res
      });
      this.ledgerList('');
    });
    this.getTransactionTypes();
    this.auth.ledgerCurrencies();
    this.auth.transactionTypes();
    this.notificationEvent.subscribe((data) => {
      // if (this.auth.emp_id.toString() !== data['sender'].toString()) {
      this.appenNotifications(data);
      ////  console.log("socket event", data)
      if (data['msg_type'].toString() != '1') {
        if (data['msg_type'].toString() === '2' || data['msg_type'].toString() === '3') {
          // 
          this.updateUserAuth();
        }
        if (data['msg_type'].toString() === '4') {
          this.ledgerList('');
        }
        if (data['msg_type'].toString() === '5') {
          if (this.ledgerView) {
            this.getSelectedTransold(Number(this.urlUser));
          } else {
            this.getSelectedTrans(Number(this.urlUser));
          }
        }
      }

      else if (data['msg_type'].toString() === '1') {
        if (data['parent_type'].toString() === '2') {
          this.ledgerList('');
        }
        if (data['parent_type'].toString() === '3') {
          this.ledgerList('');
          if (this.ledgerView) {
            this.getSelectedTransold(Number(this.urlUser));
          } else {
            this.getSelectedTrans(Number(this.urlUser));
          }
        }
        if (data['parent_type'].toString() === '1') {
          if (data['noti_type'].toString() === '2' || data['noti_type'].toString() === '1' || data['noti_type'].toString() === '21') {
            this.getBusinessMembers()
          }
          if (data['noti_type'].toString() === '3' || data['noti_type'].toString() === '4' || data['noti_type'].toString() === '19') {
            this.updateUserAuth();
            if (data['noti_type'].toString() === '3') {
              this.penddingjoinReq();
              this.router.navigate(['user/profile'])

            }
          }
          if (data['noti_type'].toString() === '23') {
            this.updateUserAuth();
            this.router.navigate(['/ledger/join/create'])
          }
          if (data['noti_type'].toString() === '28') {
            this.auth.AdminRequestRec();
          }
          if (data['noti_type'].toString() === '29') {
            this.updateUserAuth();
            this.getBusinessMembers()
          }
          if (data['noti_type'].toString() === '20') {
            // this.updateUserAuth();
            this.GetAdminRequesttoJoin()
          }
          if (data['noti_type'].toString() === '23') {
            // this.updateUserAuth();
            this.updateUserAuth();
            this.ledgerList('');
          }

        }
      }

      // }
    });
  }

  public callFunction() {
    this.invokeFunction.emit();
  }
  createTransactionItem() {
    console.log()
    return this.fb.group({
      unit_price: [''],
      quantity: [''],
      sub_total: [''],
    });
  }
  sendClickEvent() {
    this.subject1.next();
  }
  
  resetAdjustmentForm() {
    this.adjustmentForm.reset({
      product_id: [''],
      quantity: ['', [Validators.required, Validators.min(0)]],
      uom: [''],
      unit_price: [''],
      information: [''],
      item_files: [],
      created_at: [''],
      own_entry: true,
      stock_id: null,
      reference_no: null,
        // Add default values for other form controls here as needed
    });
}

  setHeading(heading: string) {
    this.headingSubject.next(heading);
  }

  // getClickEvent(): Observable<any>{ 
  //   return this.subject1.asObservable();
  // }



  pageEventSearch(event) {
    this.getAllBusines(this.querySearch, event);
  }
  getTransactionTypes() {
    this.http.getTransTypes().subscribe(res => {
     
      this.trans_types = res['data'];
    },
      (error) => {
        this.toast.error(error.error['msg']);
      }
    );
  }
  getledgerroom(query){
    this.ledgerFolders = true
    this.cashh = false
    this.invoiice = false
    this.SalaryFolders = false
    this.http.ledgerroom(this.DriveFilters?.value.search_query).subscribe(res=> {
        this.dataForled = res['result'];
        //  console.log('abdasad',this.leddataa)
    })
  }
  getInvoiceroom(query){
    this.ledgerFolders = true
    this.cashh = false
    this.invoiice = false
    this.SalaryFolders = false
    this.http.getinvoicefolders(this.DriveFilters?.value.search_query).subscribe(res=> {
        this.dataForled = res['result'];
        //  console.log('abdasad',this.leddataa)
    })
  }
  getDriveledgerroom(query)
  {
    this.loader=true;
    this.http.ledgerroom(query).subscribe(res=> {
      this.dataForled = res['result'];
      console.log(this.dataForled);
      this.loader=false
      //  console.log('abdasad',this.leddataa)
  })
  }
  getSalaryFolders(){
    this.SalaryFolders = true
    this.ledgerFolders = false
    this.cashh = false
    this.invoiice = false
    this.http.getsalaryemployeelist(this.page).subscribe(res=> {
      this.dataforEmployee = res['data']
      console.log("Employee list",this.dataforEmployee)
    })
  }
  getDriveFiles()
  {
    this.http.getDiveRecentFile(this.page).subscribe(res=>{
      this.avalibleStorage=res['available_storage'];
      this.totalStorage=res['total_storage'];
      this.usedStorage=res['used_storage'];
      console.log(res)
    })
  }
    // getDriveDataByType(type: any = 'ledger', query: any = '', page: number = this.drivecurrentPage) {
  //   this.driveApiLoader = true; 
  //   let filters = {
  //     search_query: this.DriveFilters?.value.search_query,
  //     type: type,
  //     end_date: this.DriveFilters?.value.end_date,
  //     start_date: this.DriveFilters?.value.start_date,
  //     page: page
  //   };
  //   if (type == 'ledger') {
  //     this.http.ledgerroom(query).subscribe((res: any) => {
  //       this.driveApiLoader = false;
  //       if (Number(res['page']) > 1) {
  //         res?.result?.forEach((element) => {
  //           this.driveChildData?.push(element);
  //         });
  //       } else {
  //         this.driveChildData = res['result'];
  //       }
  //       this.drivecurrentPage = Number(res['page']);
  //       this.driveTotalPage = Number(res['totalPages']);
  //     }, (error) => {
  //       this.driveApiLoader = false;
  //     });
  //   } else  if (type == 'invoice') {
  //     this.http.getBillBoardTypes().subscribe((res: any) => {
  //       this.driveApiLoader = false;
  //       if (Number(res['page']) > 1) {
  //         res?.result?.forEach((element) => {
  //           this.driveChildData?.push(element);
  //         });
  //       } else {
  //         this.driveChildData = res['result'];
  //       }
  //       this.drivecurrentPage = Number(res['page']);
  //       this.driveTotalPage = Number(res['totalPages']);
  //     }, (error) => {
  //       this.driveApiLoader = false;
  //     });
  //   } else {
  //     this.http
  //     .getfilesByCat(filters)
  //     .subscribe(
  //       (res) => {
  //         this.driveApiLoader = false;
  //         if (Number(res['page']) > 1) {
  //           res?.files_data?.forEach((element) => {
  //             this.driveChildData?.push(element);
  //           });
  //         } else {
  //           this.driveChildData = res['files_data'];
  //         }
  //         this.drivecurrentPage = Number(res['page']);
  //         this.driveTotalPage = Number(res['totalPages']);
  //       },
  //       (error) => {
  //         this.driveApiLoader = false;
  //         this.toast.error(error.error['msg']);
  //       }
  //     );
  //   }
  //   //  console.log('this is the data of drive of each type', this.driveChildData);
  // }

  // createLedger(accountType: string) {
  //   this.openModalEvent.emit(accountType);
  // }

// createLedger() {
//   debugger
//   this.openModalEvent.emit();
// }
createLedger(accountName: string, invoiceType: number) {
  // Emit an event with the provided parameters
  this.openLedgerEvent.emit({ accountName, invoiceType });
}
  getinvoicefolders(){
    this.ledgerFolders = false
    this.cashh = false
    this.invoiice = true
    this.http.getBillBoardTypes().subscribe(res=> {
      this.invoicebook = res['result']
      //  console.log('31 oct',this.invoicebook)
    })
  }
  selectLedScreen(item:any="driveScreen", type: any = 'ledger'){
    this.selectedlederFiles= item
    this.drivecurrentPage = 1;
    this.foderData = [];
    
    this.typeDrive = type;
  }
  getsalaryfiles(item: any = null){
    this.selDriveSalary = item?.id || this.selDriveSalary;
    this.http.getsalaryfiles(this.page,this.selDriveSalary,this.DriveFilters.value.search_query, this.DriveFilters.value.start_date, this.DriveFilters.value.end_date ).subscribe(res => {
      this.salaryimgg =res['files']
      this.employeeName = res['employee']
      console.log("Salary images",this.salaryimgg)
    })
  }
  getledgerroomFile(item: any = null) {
    this.loader=true;
    console.log(item.id);
    this.selDriveLedger = item?.id || this.selDriveLedger;
    this.selDriveLedgerName = item?item?.with_business?.business_name : this.selDriveLedgerName;
    this.http.getledgerroomfile(this.selDriveLedger, this.DriveFilters?.value.search_query, this.DriveFilters?.value.start_date, this.DriveFilters?.value.end_date, this.drivecurrentPage).subscribe(res=> {
      console.log(res);
      if (Number(res['page']) > 1) {
        this.loader=false;
        res?.files_data.forEach(element => {
          this.ledgerimgg?.push(element);
        });
      } else {
        // this.loader=false;
        // this.ledgerimgg = res['files_data'];
        // console.log(this.ledgerimgg);
        this.loader = false;
        this.ledgerimgg = res['files_data'].map(element => ({
          ...element,
          // Modify file_name to remove the specified number pattern
          file_name: this.removeNumberPattern(element.file_name)
        }));
        console.log(this.ledgerimgg);
      }
      this.loader=false;
      this.drivecurrentPage = Number(res['page']);
      this.driveTotalPage = Number(res['totalPages']);
    })
  }
  getInvoiceFiles(id){
    this.http.getinvoicefolders(id, this.DriveFilters?.value.search_query, this.DriveFilters?.value.start_date, this.DriveFilters?.value.end_date, this.drivecurrentPage).subscribe(res=> {
      console.log(res);
      this.invoiceFOLDER = res['result']
      this.loader=false;
      this.drivecurrentPage = Number(res['page']);
      this.driveTotalPage = Number(res['totalPages']);
    })
  }
  removeNumberPattern(fileName: string): string {
    return fileName.replace(/\d+/g, '');
  }
  getBankFile(type)
  {
      this.loader=true;
      this.cashh = true
      this.ledgerFolders = false
      this.invoiice =false
      this.currentType = type
      this.http.getBankFiles( '', '', '', this.drivecurrentPage).subscribe(res => {
        console.log(res);
        if (Number(res['page']) > 1) {
          res?.files_data.forEach(element => {
            this.dataForled?.push(element);
            this.loader=false
          });
        } else {
          this.dataForled = res['result'];
          console.log(this.dataForled);
          this.loader=false
        }
        
        this.drivecurrentPage = Number(res['page']);
        this.driveTotalPage = Number(res['totalPages']);
        this.storageValue = res;
      },
        (error) => {
          this.toast.error(error.error['msg']);
        }
      );
    
  }
  getCardFiles(type)
  {
      this.loader=true;
      this.cashh = true
      this.ledgerFolders = false
      this.invoiice =false
      this.currentType = type
      this.http.getCreditCardFiles( '', '', '', this.drivecurrentPage).subscribe(res => {
        console.log(res);
        if (Number(res['page']) > 1) {
          res?.files_data.forEach(element => {
            this.dataForled?.push(element);
            this.loader=false
          });
        } else {
          this.dataForled= res['result'];
          console.log(this.dataForled);
          this.loader=false
        }
        
        this.drivecurrentPage = Number(res['page']);
        this.driveTotalPage = Number(res['totalPages']);
        this.storageValue = res;
      },
        (error) => {
          this.toast.error(error.error['msg']);
        }
      );
    
  }
  getFilesByTypeInvoices(type) {
    this.loader=true;
    this.cashh = true
    this.ledgerFolders = false
    this.invoiice =false
    this.currentType = type
    this.http.getfilesByCatInvoices(this.roomId, this.DriveFilters?.value.search_query, this.DriveFilters?.value.start_date, this.DriveFilters?.value.end_date, this.drivecurrentPage,type).subscribe(res => {
      
      if (Number(res['page']) > 1) {
        res?.files_data.forEach(element => {
          this.foderData?.push(element);
          this.loader=false
        });
      } else {
        this.foderData = res['files_data'];
        console.log(this.foderData);
        this.loader=false
      }
      
      this.drivecurrentPage = Number(res['page']);
      this.driveTotalPage = Number(res['totalPages']);
      this.storageValue = res;
    },
      (error) => {
        this.toast.error(error.error['msg']);
      }
    );
  }


  getFilesByType(type) {
    this.loader=true;
    this.cashh = true
    this.ledgerFolders = false
    this.invoiice =false
    this.currentType = type
    this.http.getfilesByCat(type, this.DriveFilters?.value.search_query, this.DriveFilters?.value.start_date, this.DriveFilters?.value.end_date, this.drivecurrentPage, this.roomId).subscribe(res => {
      
      if (Number(res['page']) > 1) {
        res?.files_data.forEach(element => {
          this.foderData?.push(element);
          this.loader=false
        });
      } else {
        this.foderData = res['files_data'];
        console.log(this.foderData);
        this.loader=false
      }
      
      this.drivecurrentPage = Number(res['page']);
      this.driveTotalPage = Number(res['totalPages']);
      this.storageValue = res;
    },
      (error) => {
        this.toast.error(error.error['msg']);
      }
    );
  }


  getFilesByTypeBankCard(type,id) {
    this.loader=true;
    this.cashh = true
    this.ledgerFolders = false
    this.invoiice =false
    this.currentType = type
    this.http.getfilesByCat(type, this.DriveFilters?.value.search_query, this.DriveFilters?.value.start_date, this.DriveFilters?.value.end_date, this.drivecurrentPage, id).subscribe(res => {
      
      if (Number(res['page']) > 1) {
        res?.files_data.forEach(element => {
          this.foderData?.push(element);
          this.loader=false
        });
      } else {
        this.bankCardData = res['files_data'];
        console.log(this.bankCardData);
        this.loader=false
      }
      
      this.drivecurrentPage = Number(res['page']);
      this.driveTotalPage = Number(res['totalPages']);
      this.storageValue = res;
    },
      (error) => {
        this.toast.error(error.error['msg']);
      }
    );
  }
  getAllCreditCardData()
  {
    this.loader=true;
    this.http.getCreditCardDetails().subscribe(res => {
      console.log(res);
      this.cardDetails=res
      this.addCreditCardAmount=res;
      this.creditDetails=res['credit_card_room_data']
      
      this.loader=false;
    },
      (error) => {
        this.toast.error(error.error['msg']);
        this.loader=false;
      }
    ); 
  }
  
  getAllBank(transectionCurrency) {
    debugger;
    this.loader = true
    this.http.allBank(transectionCurrency, this.bankcurrentPage).subscribe(res => {
      if (this.bankcurrentPage === 1) {
        this.bankData = res['bank_room_data']
      }
      if (this.bankcurrentPage > 1) { 
        for (let item of res['bank_room_data'])
          this.bankData.push(item);
      }
      ////  console.log("cashDat",this.saleTransData);
      
      this.allBanks = res;
      this.bankcurrentPage = res['page']
      this.bankTotalPage = res['totalPages']
      this.loader = false
    },
      (error) => {
        this.toast.error(error.error['msg']);
        this.loader = false
      }
    );
  }
  
  getCashBookTypes() {
    this.http.getCashsTypes().subscribe(res => {
      this.cashbook_types = res['data'];
    },
      (error) => {
        this.toast.error(error.error['msg']);
      }
    );
  }
  CurrenciesList() {
    this.http.ledgerCurrencies().subscribe(res => {
      this.ledger_currencies = res['data'];
      this.addInventory.patchValue({
        currency: this.ledger_currencies[0].id
      })
      ////  console.log("currency", this.addInventory, this.ledger_currencies)
    }, (error) => {

    });
  }

  customerPendingRequest()
  {
    this.http.customerPendingRequest().subscribe(
      (res) => {
        console.log(res);
        this.ledgersReqs=res;
        this.penddingRequests = res['data'];
      },
      (err) => {
        this.toast.error(err['error']['msg']);
      }
    );
  }
  vendorPendingRequest()
  {
    this.http.vendorPendingRequest().subscribe(
      (res) => {
        this.ledgersReqs=res;
        this.penddingRequests = res['data'];
      },
      (err) => {
        this.toast.error(err['error']['msg']);
      }
    );
  }

  penddingjoinReq() {
    this.http.pendingJoiningrequest().subscribe(
      (res) => {
        this.penddingRequests = res['data'];
      },
      (err) => {
        this.toast.error(err['error']['msg']);
      }
    );
  }
  GetAdminRequesttoJoin() {
    this.http.getadminRequesttoJoin().subscribe(res => {
      this.reqfromadmin = res['detail'];
      this.router.navigate(['ledger/join/create'])
      // if(this.reqfromadmin.length){
      //   this.openModallogout(this.paymentmodal)
      // }
      // ////  console.log("status", this.status)
    }, (error) => {

    });
  }
  appenNotifications(data: any) {
    if (data['msg_type'].toString() === '1') {
      console.log('message', data['parent_type'].toString());
      
      if (data['parent_type'].toString() === '4' || data['parent_type'].toString() === '5' || data['parent_type'].toString() === '6' || data['parent_type'].toString() === '7' || data['parent_type'].toString() === '8' || data['parent_type'].toString() === '9' || data['parent_type'].toString() === '10') {
        this.auth.showNotifyPlus = true
      }
      if(data['parent_type'].toString() === '3'){
        this.auth.showNotifyLedger = true
      }
      this.auth.showNotify = true;
      if (this.auth.username !== data['username']) {
        this.toast.success(
          '<a href=' +
          data.action_link +
          ' target="_blank"> <b>' +
          data.title +
          '</b></a>\
              <br/>\
              <a href=' +
          data.action_link +
          ' target="_blank">' +
          data.desc +
          ' </a>',
          '',
          {
            positionClass: 'toast-bottom-right',
            enableHtml: true,
          }
        );
      }
    }
  }
  businessId:any
  updateUserAuth() {
    this.http.GetUserData().subscribe(data => {
    this.businessId = data?.emp_info?.Business?.id;
      console.log(this.businessId ,'Abdddd')
      this.auth.SetUserDataLanguage(data.emp_info);
    });
  }
  get selected_trans() {
    try {
      for (let item of this.trans_types) {
        for (let sub of item.sub_types) {
          if (sub.id.toString() == this.addLedgerForm.value.transaction_sub_type.toString()) {
            return sub;
          }
        }
      }
    } catch (e) {
      return '';
    }
  }
  searchLedgerList(event) {
    ////  console.log("event me", event.target.value);

    this.led_ListFilter.patchValue({
      search_query: event.target.value
    })
    this.ledgerList(this.auth.userData.emp_info.Business.currency.id)
  }
  
  getAllBusines(res: any = '', page: any = 1) {
    if (res) {
      this.searchvalue = res
      this.loadBusi = true;
      this.http.SearchBusinessLedger(res, page).subscribe(data => {
        this.searchList = data;
        this.loadBusi = false;
        console.log(data);
        ////  console.log('this is the search query result', data);
      }, (error) => {
        this.loadBusi = false;
      });
    } else {
      this.selectedBuss = [];
      this.searchList = '';
    }
  }
  getMarketListing()
  {
    this.loader=true;
    this.http.getmarketlisting(this.itemlistingId,this.invservice,this.itemlistingtType,this.proCurrentPage).subscribe(data => {
      console.log(data);
      this.searchList = data;
      this.loadBusi = false;
      this.listing=data['market_listing'];
      // let listing=data['market_listing']
      console.log(this.listing);
      if (this.proCurrentPage === 1) {
        this.Invallproduct = data['market_listing']
    
      }
      if (this.proCurrentPage > 1) {
        this.Invallproduct = data['market_listing']
      }
      this.proCurrentPage = data['page']
      this.productTotalPage = data['totalPages']
      this.InventoryPro = data;
      this.loader = false
      ////  console.log('this is the search query result', data);
    }, (error) => {
      this.loadBusi = false;
    });
  }

  getBundleMarketListing()
  {
    this.loader=true;
    this.http.getBundleLsiting(this.bundleSearch, this.bundleCurrentPage).subscribe(data => {
      console.log(data);
      debugger
      this.searchList = data;
      this.bundleRecord= data;
      this.loadBusi = false;
      this.bundleListing=data['bundles_data'];
      if (this.bundleCurrentPage === 1) {
        this.InvallproductBundle = data['bundles_data']
    
      }
      if (this.bundleCurrentPage > 1) {
        this.InvallproductBundle = data['bundles_data']
      }
      console.log(this.InvallproductBundle,'Bundle listing Count')
      // this.bundleCurrentPage = data['page']
      this.productTotalPage = data['totalPages']
      this.InventoryPro = data;
      this.loader = false
    }, (error) => {
      this.loadBusi = false;
    });
  }
  searchBundles(res: any = '33', page: any = 1) {
    if (res) {
      this.searchvalue = res
      this.loadBusi = true;
      console.log(this.querySearch);
      // alert("searchBundle");
      this.bundleType=false
      this.http.getMarketListingBundles(4248,this.querySearch,'all',page, this.bundleType,null).subscribe(data => {
        this.searchList = data;
        this.loadBusi = false;
        let listing=data['market_listing']
        console.log(listing);
        ////  console.log('this is the search query result', data);
      }, (error) => {
        this.loadBusi = false;
      });
    } else {
      this.selectedBuss = [];
      this.searchList = '';
    }
  }

  onValueChange(): void {
    const sellingPrice = this.addInventory.get('selling_price_per_unit').value;
    // Patch both form controls with the new value
    ;
    this.addInventory.patchValue({
      selling_price_per_unit: sellingPrice,
      sales_price: sellingPrice
    }, {emitEvent: false}); // Prevents emitting a value change event
  }
  getAllMembers(res: any = '', type) {
    this.loadBusi = true;
    this.http.searchMemberLedger(res, type).subscribe(data => {
      this.memberslist = data['business_members_info'];
      this.loadBusi = false;
      ////  console.log('this is the search query result', data);
    }, (error) => {
      this.loadBusi = false;
    });
  }
  getMemberListing()
  {
    this.http.getMemberList().subscribe((res)=>{
      console.log(res);
      this.penddingRequests = res['data'];
      console.log(this.penddingRequests);
    })
  }
  getBusinessMembers() {
    this.http.getbusinessmembers(this.auth.userData.emp_info.Business.id).subscribe(
      (res) => {
        this.businessMembers = res['business_members_info']
        this.InactiveMembers = res['inactive_accounts']
      }, err => {
        this.toast.error(err.msg);
      })
  }
  getOtherBusinessMembers(id) {
    this.http.getbusinessmembers(id).subscribe(
      (res) => {
        this.OtherbusinessMembers = res['business_members_info']
        this.OtherInactiveMembers = res['inactive_accounts']
      }, err => {
        this.toast.error(err.msg);
      })
  }
  resetFilterExp() {

    this.ExpenseFilters.patchValue({
      transaction_type: '',
      // debit_amount: '',
      // credit_amount: '',
      search_query: '',
      start_date: '',
      end_date: '',
    });
    this.expensecurrentPage = 1
    this.getExpenseTran()
  }
  resetFilterdrive(type: any = 'ledger') {

    this.DriveFilters.patchValue({
      search_query: '',
      start_date: '',
      end_date: '',
    });
    this.drivecurrentPage = 1;
    if (type == 'ledger') {
      this.getledgerroomFile();
    } else {
      this.getFilesByType(type?.toLowerCase());
    }
  }
  resetFilterInv() {

    this.InventoryFilters.patchValue({
      transaction_type: '',
      // debit_amount: '',
      // credit_amount: '',
      search_query: '',
      start_date: '',
      end_date: '',
    });
    this.stockcurrentPage = 1
    this.getProductStock(this.StockProduct.id)
    this.getStockProByRef(this.idByref, this.selectedref)
  }
  resetFilterBundle() {

    this.BundleFilters.patchValue({
      transaction_type: '',
      search_query: '',
      start_date: '',
      end_date: '',
    });
    this.stockcurrentPage = 1
    this.getProductStock(this.StockProduct.id)
    this.getStockProByRef(this.idByref, this.selectedref)
  }
  resetFilterCash() {

    this.CashFilters.patchValue({
      transaction_type: '',
      // debit_amount: '',
      // credit_amount: '',
      search_query: '',
      start_date: '',
      end_date: '',
    });
    this.cashcurrentPage = 1
    this.ReverseCashTran()
  }
  resetFilterBank() {

    this.bankFilters.patchValue({
      transaction_type: '',
      // debit_amount: '',
      // credit_amount: '',
      search_query: '',
      start_date: '',
      end_date: '',
    });
    this.banktransPage = 1
    this.getBankTranReverse(this.bankId)
  }
  resetFilterCard() {

    this.cardFilters.patchValue({
      transaction_type: '',
      // debit_amount: '',
      // credit_amount: '',
      search_query: '',
      start_date: '',
      end_date: '',
    });
    this.banktransPage = 1
    this.ReversegetSingleCreditDetails(this.deleteCard)
  }
  
  resetFilterSale() {

    this.SaleFilters.patchValue({
      transaction_type: '',
      // debit_amount: '',
      // credit_amount: '',
      search_query: '',
      start_date: '',
      end_date: '',
    });
    this.salecurrentPage = 1
    this.getSaleTran()
  }
  resetFilterPurchase() {

    this.PurchaseFilters.patchValue({
      transaction_type: '',
      // debit_amount: '',
      // credit_amount: '',
      search_query: '',
      start_date: '',
      end_date: '',
    });
    this.purchasecurrentPage = 1
    this.getPurchaseTran()
  }

  resetFilters(modal) {
    modal.dismiss();
    this.ledgerFilters.patchValue({
      transaction_type: '',
      debit_amount: '',
      credit_amount: '',
      search_query: '',
      start_date: '',
      end_date: '',
    });
    this.getSelectedTransold(Number(this.urlUser), 1);
  }
  selectFiltersDate(event, type) {
    if (type == 'start') {
      this.ledgerFilters.patchValue({
        start_date: event.year + '-' + event.month + '-' + event.day
      })
    } else {
      this.ledgerFilters.patchValue({
        end_date: event.year + '-' + event.month + '-' + event.day
      })
    }
    ////  console.log('this is the form value', this.ledgerFilters.value);
  }
  typeFilters(type) {
    this.ledgerFilters.patchValue({
      transaction_type: type
    })
    ////  console.log('this is the form value', this.ledgerFilters.value);
  }
  editOneWay(form) {
    if (form.value.type === 'phone') {
      form.patchValue({
        email_or_phone: form.value.email_or_phone['e164Number']
      });
    }
    this.http.editOneWay_Ledger(form.value).subscribe((res) => {
      this.toast.success(res['detail']);
      this.getSelectedTrans(Number(this.urlUser));
      this.modalService.dismissAll()
      this.ledgerList('');
    }, err => {
      this.toast.error(err.error.msg);
      this.transLoad = false;
      if (err.error.Business_Profile_exist === true) {
        let title, text, confirm, cancel;
        this.translate.get(err.error.msg).subscribe((data: any) => {
          title = data;
        }),
          this.translate.get('Yes').subscribe((data: any) => {
            confirm = data;
          }),
          this.translate.get('Cancel').subscribe((data: any) => {
            cancel = data;
          }),
          Swal.fire({
            // imageUrl: 'https://hiwac-nc.s3.us-east-2.amazonaws.com/images/deleteLogo.png', imageHeight: 80, imageAlt: 'A hiwac Logo',
            title: title,
            text: text,
            showCancelButton: true,
            confirmButtonColor: '#3D8BC9',
            cancelButtonColor: '#000000',
            cancelButtonText: cancel,
            confirmButtonText: confirm
          }).then((result) => {
            if (result.value) {
              this.http.connectBusinessInvite(err.error.Business_Profile_id, this.selectedLedger.id).subscribe(res => {

                if (res?.plan_expired || res?.limit_exceeded) {
                  this.sendClickEvent();
                }

                this.toast.success(res['Detail']);
                this.getSelectedTrans(Number(this.urlUser));
              }, (error) => {
                this.toast.error(error.error['msg']);
                this.modalService.dismissAll();
              })
            }
          })
      }

    })
  }
  // sendInviteLedger() {
  //   this.http.sendInviteOneWay(this.selectedLedger.id).subscribe(res => {
  //     this.toast.success(res['detail']);
  //     this.getSelectedTrans(Number(this.urlUser));
  //     this.ledgerList('');
  //     this.modalService.dismissAll();
  //   }, (error) => {
  //     this.toast.error(error.error['msg']);
  //     this.modalService.dismissAll();
  //   })
  // }
  SendInviteMember(){
    const emailValue = this.invitemember.get('email').value;
    console.log(this.invitemember.value)
    this.http.sendinviteMember(emailValue).subscribe(res =>{
      this.toast.success(res['detail']);
      this.modalService.dismissAll();
    },
    (error) => {
      this.toast.error(error.error['msg']);
      this.modalService.dismissAll();
    })
  }
  SendBusinessRequest(){
    const emailValue = this.sendbusinessreq.get('email').value;
    console.log(this.sendbusinessreq.value)
    this.http.SendConnectionRequest(emailValue).subscribe(res =>{
      this.toast.success(res['detail']);
      this.modalService.dismissAll();
    },
    (error) => {
      this.toast.error(error.error['msg']);
      this.modalService.dismissAll();
    })
  }
  Inviteoneway(){
    const emailValue = this.sendbusinessreq.get('email').value;
    console.log(this.sendbusinessreq.value)
    this.http.sendInviteOneWay(this.selectedLedger.id,emailValue).subscribe(res =>{
      this.toast.success(res['detail']);
      this.modalService.dismissAll();
    },
    (error) => {
      this.toast.error(error.error['msg']);
      this.modalService.dismissAll();
    })
  }
  searchBusiness(event) {
    this.page = 1
    this.subject.next(event.target.value);
  }
  searchMember(event, type) {
    this.searchtype = type
    this.subjectMember.next(event.target.value);
  }
  ledgerListFilter(event) {
    this.subjectLedger.next(event.target.value);
  }
  toggleTypeLedger(value) {
    this.addLedgerForm.patchValue({
      transaction_type: value
    });
  }
  selectLedgeDate(event) {
    this.addLedgerForm.patchValue({
      starting_date: event.year + '-' + event.month + '-' + event.day
    });
  }
  selectLedgeLimitDate(event) {
    this.ledgerLimit.patchValue({
      max_date: event.year + '-' + event.month + '-' + event.day
    });
  }
  selectstockOutDate(event) {
    ////  console.log("Date ya ai ha yahn,", event)
    if (event.month < 10 && event.day < 10) {
      this.stockOut.patchValue({
        created_at: event.year + '-' + '0' + event.month + '-' + '0' + event.day
      });
    }
    else if (event.month < 10 && event.day > 9) {
      this.stockOut.patchValue({
        created_at: event.year + '-' + '0' + event.month + '-' + event.day
      });
    }
    else if (event.month > 9 && event.day < 10) {
      this.stockOut.patchValue({
        created_at: event.year + '-' + event.month + '-' + '0' + event.day
      });
    }
    else {
      this.stockOut.patchValue({
        created_at: event.year + '-' + event.month + '-' + event.day
      });
    }

  }
  
  selectCashDate(event) {
    ////  console.log("Date ya ai ha yahn,", event)
    this.addBankForm.patchValue({
      starting_date: event.year + '-' + event.month + '-' + event.day
    })
    this.addCashBookForm.patchValue({
      starting_date: event.year + '-' + event.month + '-' + event.day
    });
    this.addCreditCard.patchValue({
      starting_date: event.year + '-' + event.month + '-' + event.day
    });
  }
  selectStockinDate(event) {
    this.stockinByRef.patchValue({
      created_at: event.year + '-' + event.month + '-' + event.day
    });
  }
  selectEmployeeDate(event) {
    this.employeeService.addEmpTransection.patchValue({
      starting_date: event.year + '-' + event.month + '-' + event.day
    });
  }
  selectRefDate(event) {
    let month: any = event.month<10?'0'+event.month: event.month;
    let day: any = event.day<10?'0'+event.day: event.day;
    this.addInventory.patchValue({
      created_at: event.year + '-' + month  + '-' + day
    });
    this.addProduct.patchValue({
      created_at: event.year + '-' + month  + '-' + day
    })
  }
  selectBankDate(event) {
    this.addBankTansForm.patchValue({
      starting_date: event.year + '-' + event.month + '-' + event.day
    });
  }
  selectpurchaseDate(event) {
    this.addPurchaseBookForm.patchValue({
      starting_date: event.year + '-' + event.month + '-' + event.day
    });
  }
  selectSaleDate(event) {
    this.addSaleBookForm.patchValue({
      starting_date: event.year + '-' + event.month + '-' + event.day
    });
  }
  selectExpDate(event) {
    this.addExpenseForm.patchValue({
      starting_date: event.year + '-' + event.month + '-' + event.day
    });
  }
  selectEmployeeFile(event) {
    this.fileData = event.target.files;
    // for (let file of event.target.files) {
    //   if (file.size / 1024 > 3000) {
    //     this.toast.warning('Each File size should be less then 3mb');
    //     return;
    //   }
    // }
    if (this.fileData.length > 5 || this.previewUrl.length + this.fileData.length > 5) {
      this.toast.warning('You can upload upto 5 files');
      return;
    }
    this.uploadFile = true;
    const formData = new FormData();
    for (let item of this.fileData) {
      formData.append('fileToUpload', item);
    }
    this.http.addTransactionFiles(formData).subscribe(res => {
      if (res?.plan_expired || res?.limit_exceeded) {
        this.sendClickEvent();
      }
      this.toast.success(res['detail']);
      for (let item of res['data']) {
        this.previewUrl.push(item);
      }

      let array = [];
      for (let item of this.previewUrl) {
        array.push(item.id)
      }
      this.employeeService.addEmpTransection.patchValue({
        files: array
      });
      this.uploadFile = false;
    }, (error) => {
      this.previewUrl = [];
      this.uploadFile = false;
      this.toast.error(error.error['msg']);
    })
  }
  
  selectCardFile(event) { 
    this.imgload = true
    this.fileData = event.target.files;
    if (this.fileData.length > 5 || this.previewUrl.length + this.fileData.length > 5) {
      this.toast.warning('You can upload upto 5 files');
      this.imgload = false
      return;
    }
    this.uploadFile = true;
    const formData = new FormData();
    for (let item of this.fileData) {
      formData.append('fileToUpload', item);
    }
    this.http.addBankFiles(formData).subscribe(res => {

      if (res?.plan_expired || res?.limit_exceeded) {
        this.sendClickEvent();
      }
 
      this.toast.success(res['detail']);

      for (let item of res['data']) {
        this.previewUrl.push(item);
      }

      let array = [];
      for (let item of this.previewUrl) {
        array.push(item.id)
      }
      this.addCreditTansForm.patchValue({
        file: array
      });
      this.imgload = false
      this.uploadFile = false;
    }, (error) => {
      this.previewUrl = [];
      this.uploadFile = false;
      this.toast.error(error.error['msg']);
      this.imgload = false
    })
  }
  selectAddCardFile(event) {
    this.imgload = true;
    this.fileData = event.target.files;
  
    if (this.fileData.length > 5 || this.previewUrl.length + this.fileData.length > 5) {
      this.toast.warning('You can upload up to 5 files');
      this.imgload = false;
      return;
    }
  
    this.uploadFile = true;
    const formData = new FormData();
    for (let item of this.fileData) {
      formData.append('fileToUpload', item);
    }
  
    this.http.addBankFiles(formData).subscribe(res => {
      if (res?.plan_expired || res?.limit_exceeded) {
        this.sendClickEvent();
      }
  
      this.toast.success(res['detail']);
  
      if (res['data'] && res['data'].length > 0) {
        for (let item of res['data']) {
          this.previewUrl.push(item);
        }
  
        const array = this.previewUrl.map(item => item.id);
        this.addCreditCard.patchValue({
          file: array
        });
      } else {
        this.addCreditCard.patchValue({
          file: []
        });
      }
  
      this.imgload = false;
      this.uploadFile = false;
    }, (error) => {
      this.previewUrl = [];
      this.uploadFile = false;
      this.toast.error(error.error['msg']);
      this.imgload = false;
    });
  }
  
  selectBankFile(event) { 
    this.imgload = true
    this.fileData = event.target.files;

    if (this.fileData.length > 5 || this.previewUrl.length + this.fileData.length > 5) {
      this.toast.warning('You can upload upto 5 files');
      this.imgload = false
      return;
    }
    this.uploadFile = true;
    const formData = new FormData();
    for (let item of this.fileData) {
      formData.append('fileToUpload', item);
    }
    this.http.addBankFiles(formData).subscribe(res => {

      if (res?.plan_expired || res?.limit_exceeded) {
        this.sendClickEvent();
      }
 
      this.toast.success(res['detail']);

      for (let item of res['data']) {
        this.previewUrl.push(item);
      }

      let array = [];
      for (let item of this.previewUrl) {
        array.push(item.id)
      }
      this.addBankTansForm.patchValue({
        file: array
      });
      this.imgload = false
      this.uploadFile = false;
    }, (error) => {
      this.previewUrl = [];
      this.uploadFile = false;
      this.toast.error(error.error['msg']);
      this.imgload = false
    })
  }
  // selectAddBankFile(event) { 
  //   this.imgload = true
  //   this.fileData = event.target.files;

  //   if (this.fileData.length > 5 || this.previewUrl.length + this.fileData.length > 5) {
  //     this.toast.warning('You can upload upto 5 files');
  //     this.imgload = false
  //     return;
  //   }
  //   this.uploadFile = true;
  //   const formData = new FormData();
  //   for (let item of this.fileData) {
  //     formData.append('fileToUpload', item);
  //   }
  //   this.http.addTransactionFile(formData).subscribe(res => {

  //     if (res?.plan_expired || res?.limit_exceeded) {
  //       this.sendClickEvent();
  //     }
 
  //     this.toast.success(res['detail']);

  //     for (let item of res['data']) {
  //       this.previewUrl.push(item);
  //     }

  //     let array = [];
  //     for (let item of this.previewUrl) {
  //       array.push(item.id)
  //     }
  //     this.addBankForm.patchValue({
  //       file: array
  //     });
  //     this.imgload = false
  //     this.uploadFile = false;
  //   }, (error) => {
  //     this.previewUrl = [];
  //     this.uploadFile = false;
  //     this.toast.error(error.error['msg']);
  //     this.imgload = false
  //   })
  // }

  selectAddBankFile(event) {
    this.imgload = true;
    this.fileData = event.target.files;
  
    if (this.fileData.length > 5 || this.previewUrl.length + this.fileData.length > 5) {
      this.toast.warning('You can upload up to 5 files');
      this.imgload = false;
      return;
    }
  
    this.uploadFile = true;
    const formData = new FormData();
    for (let item of this.fileData) {
      formData.append('fileToUpload', item);
    }
  
    this.http.addBankFiles(formData).subscribe(res => {
      if (res?.plan_expired || res?.limit_exceeded) {
        this.sendClickEvent();
      }
  
      this.toast.success(res['detail']);
  
      if (res['data'] && res['data'].length > 0) {
        for (let item of res['data']) {
          this.previewUrl.push(item);
        }
  
        const array = this.previewUrl.map(item => item.id);
        this.addBankForm.patchValue({
          file: array
        });
      } else {
        this.addBankForm.patchValue({
          file: []
        });
      }
  
      this.imgload = false;
      this.uploadFile = false;
    }, (error) => {
      this.previewUrl = [];
      this.uploadFile = false;
      this.toast.error(error.error['msg']);
      this.imgload = false;
    });
  }
  
  
  selectCashFile(event) {
    this.imgload = true
    this.fileData = event.target.files;
    if (this.fileData.length > 5 || this.previewUrl.length + this.fileData.length > 5) {
      this.toast.warning('You can upload upto 5 files');
      this.imgload = false
      return;
    }
    this.uploadFile = true;
    const formData = new FormData();
    for (let item of this.fileData) {
      formData.append('fileToUpload', item);
    }
    this.http.addCashFiles(formData).subscribe(res => {
      if (res?.plan_expired || res?.limit_exceeded) {
        this.sendClickEvent();
      }
      this.toast.success(res['detail']);
      this.imgload = false
      for (let item of res['data']) {
        this.previewUrl.push(item);
      }

      let array = [];
      for (let item of this.previewUrl) {
        array.push(item.id)
      }
      this.addCashBookForm.patchValue({
        file: array
      });
      this.uploadFile = false;
    }, (error) => {
      this.previewUrl = [];
      this.uploadFile = false;
      this.imgload = false
      this.toast.error(error.error['msg']);
    })
  }
  selectProdFile(event) {
    this.imgload = true
    this.fileData = event.target.files;
    if (this.fileData.length > 5 || this.previewUrl.length + this.fileData.length > 5) {
      this.toast.warning('You can upload upto 5 files');
      this.imgload = false
      return;
    }
    this.uploadFile = true;
    const formData = new FormData();
    for (let item of this.fileData) {
      formData.append('fileToUpload', item);
    }
    this.http.adduploadInvFiles(formData).subscribe(res => {
      if (res?.plan_expired || res?.limit_exceeded) {
        this.sendClickEvent();
      }
      this.toast.success(res['detail']);
      this.imgload = false
      for (let item of res['data']) {
        this.previewUrl.push(item);
      }

      let array = [];
      for (let item of  this.previewUrl) {
        array.push(item.id)
      }
      this.addProduct.patchValue({
        product_file: array
      });
      this.uploadFile = false;
    }, (error) => {
      this.previewUrl = [];
      this.uploadFile = false;
      this.imgload = false
      this.toast.error(error.error['msg']);
    })






    // this.imgload = true
    // this.fileData = event.target.files;
    // if (this.fileData.length > 5 || this.previewUrl.length + this.fileData.length > 5) {
    //   this.toast.warning('You can upload upto 5 files');
    //   this.imgload = false
    //   return;
    // }
    // this.uploadFile = true;
    // const formData = new FormData();
    // for (let item of this.fileData) {
    //   formData.append('fileToUpload', item);
    // }
    // this.http.adduploadInvFiles(formData).subscribe(res => {
    //   if (res?.plan_expired || res?.limit_exceeded) {
    //     this.sendClickEvent();
    //     this.imgload = false
    //     this.uploadFile = false;
    //     return
    //   }
    //   this.toast.success(res['detail']);
    //   this.invImg = res['data']
    //   console.log('Image inventory', this.invImg)
    //   this.inventloader = false
    //   for (let item of res['data']) {
    //     this.previewUrl.push(item);
    //   }

    //   let array = [];
    //   for (let item of this.previewUrl) {
    //     array.push(item.id)
    //   }
    //   this.addInventory.patchValue({
    //     product_file: this.invImg.id
    //   });
    //   this.uploadFile = false;
    //   this.imgload = false
    // }, (error) => {
    //   this.previewUrl = [];
    //   this.uploadFile = false;
    //   this.imgload = false
    //   this.toast.error(error.error['msg']);
    // })
  }
  selectServiceFile(event) {
    this.imgload = true
    this.fileData = event.target.files;
    if (this.fileData.length > 5 || this.previewUrl.length + this.fileData.length > 5) {
      this.toast.warning('You can upload upto 5 files');
      this.imgload = false
      return;
    }
    this.uploadFile = true;
    const formData = new FormData();
    for (let item of this.fileData) {
      formData.append('fileToUpload', item);
    }
    this.http.adduploadInvFiles(formData).subscribe(res => {
      if (res?.plan_expired || res?.limit_exceeded) {
        this.sendClickEvent();
      }
      this.toast.success(res['detail']);
      this.imgload = false
      for (let item of res['data']) {
        this.previewUrl.push(item);
      }

      let array = [];
      for (let item of this.previewUrl) {
        array.push(item.id)
      }
      this.addService.patchValue({
        product_file: array
      });
      this.uploadFile = false;
    }, (error) => {
      this.previewUrl = [];
      this.uploadFile = false;
      this.imgload = false
      this.toast.error(error.error['msg']);
    })
  }
  AdFile(event) {
    this.imgload = true;
    this.fileData = event.target.files;
    
    // Ensure that the total number of files does not exceed the limit
    if (this.fileData.length > 5 || this.previewUrl.length + this.fileData.length > 5) {
      this.toast.warning('You can upload up to 5 files');
      this.imgload = false;
      return;
    }
    
    this.uploadFile = true;
    
    // Iterate over each file and upload it individually
    for (let item of this.fileData) {
      const formData = new FormData();
      formData.append('fileToUpload', item);
      
      // Call the API for each file
      this.http.addTransactionFile(formData).subscribe(res => {
        if (res?.plan_expired || res?.limit_exceeded) {
          this.sendClickEvent();
        }
        
        console.log(res['data']);
        this.toast.success(res['detail']);
        
        // Push the received ID to previewUrl
        this.previewUrl.push(res['data']);
        
        // Patch the IDs to adjustmentForm
        let array = [];
        for (let item of this.previewUrl) {
          array.push(item.id)
        }
        this.adjustmentForm.patchValue({
          item_files: array
        });
        
        this.uploadFile = false;
      }, (error) => {
        this.previewUrl = [];
        this.uploadFile = false;
        this.imgload = false;
        this.toast.error(error.error['msg']);
      });
    }
}
AdFileBundle(event) {
  this.imgload = true;
  this.fileData = event.target.files;
  
  // Ensure that the total number of files does not exceed the limit
  if (this.fileData.length > 5 || this.previewUrl.length + this.fileData.length > 5) {
    this.toast.warning('You can upload up to 5 files');
    this.imgload = false;
    return;
  }
  
  this.uploadFile = true;
  
  // Iterate over each file and upload it individually
  for (let item of this.fileData) {
    const formData = new FormData();
    formData.append('fileToUpload', item);
    
    // Call the API for each file
    this.http.addTransactionFile(formData).subscribe(res => {
      if (res?.plan_expired || res?.limit_exceeded) {
        this.sendClickEvent();
      }
      
      console.log(res['data']);
      this.toast.success(res['detail']);
      
      // Push the received ID to previewUrl
      this.previewUrl.push(res['data']);
      
      // Patch the IDs to adjustmentForm
      let array = [];
      for (let item of this.previewUrl) {
        array.push(item.id)
      }
      this.adjustmentFormBundle.patchValue({
        file: array
      });
      
      this.uploadFile = false;
    }, (error) => {
      this.previewUrl = [];
      this.uploadFile = false;
      this.imgload = false;
      this.toast.error(error.error['msg']);
    });
  }
}
  
  

  deleteMarketServiceOrProductFile(id) {
    this.previewUrl =  this.previewUrl.filter(x => x.id !== id);
    let array = [];
    for (let item of  this.previewUrl) {
      array.push(item.id)
    }
    this.addService.patchValue({
      product_file: array
    });
    this.addProduct.patchValue({
      product_file: array
    });
  }
selectInvnFile(event) {
  this.imgload = true
  this.fileData = event.target.files;
  if (this.fileData.length > 5 || this.previewUrl.length + this.fileData.length > 5) {
    this.toast.warning('You can upload upto 5 files');
    this.imgload = false
    return;
  }
  this.uploadFile = true;
  const formData = new FormData();
  for (let item of this.fileData) {
    formData.append('fileToUpload', item);
  }
  this.http.adduploadInvFiles(formData).subscribe(res => {
    if (res?.plan_expired || res?.limit_exceeded) {
      this.sendClickEvent();
    }
    this.toast.success(res['detail']);
    this.imgload = false
    for (let item of res['data']) {
      this.previewUrl.push(item);
    }

    let array = [];
    for (let item of this.previewUrl) {
      array.push(item.id)
    }
    this.addInventory.patchValue({
      product_file: array
    });
    this.uploadFile = false;
  }, (error) => {
    this.previewUrl = [];
    this.uploadFile = false;
    this.imgload = false
    this.toast.error(error.error['msg']);
  })
}

selectAdFileAz(event) {
  this.imgload = true
  this.fileData = event.target.files;
  if (this.fileData.length > 5 || this.previewUrl.length + this.fileData.length > 5) {
    this.toast.warning('You can upload upto 5 files');
    this.imgload = false
    return;
  }
  this.uploadFile = true;
  const formData = new FormData();
  for (let item of this.fileData) {
    formData.append('fileToUpload', item);
  }
  this.http.addTransactionFile(formData).subscribe(res => {
    if (res?.plan_expired || res?.limit_exceeded) {
      this.sendClickEvent();
    }
    this.toast.success(res['detail']);
    this.imgload = false
    for (let item of res['data']) {
      this.previewUrl.push(item);
    }

    let array = [];
    for (let item of this.previewUrl) {
      array.push(item.id)
    }
    this.adjustmentForm.patchValue({
      item_files: array
    });
    this.uploadFile = false;
  }, (error) => {
    this.previewUrl = [];
    this.uploadFile = false;
    this.imgload = false
    this.toast.error(error.error['msg']);
  })
}

  // selectInvFile(event) {
  //   this.imgload = true
  //   this.fileData = event.target.files;
  //   if (this.fileData.length > 5 || this.previewUrl.length + this.fileData.length > 5) {
  //     this.toast.warning('You can upload upto 5 files');
  //     this.imgload = false
  //     return;
  //   }
  //   this.uploadFile = true;
  //   const formData = new FormData();
  //   for (let item of this.fileData) {
  //     formData.append('fileToUpload', item);
  //   }
  //   this.http.addInvFiles(formData).subscribe(res => {
  //     if (res?.plan_expired || res?.limit_exceeded) {
  //       this.sendClickEvent();
  //     }
  //     this.toast.success(res['detail']);
  //     this.imgload = false
  //     for (let item of res['data']) {
  //       this.previewUrl.push(item);
  //     }

  //     let array = [];
  //     for (let item of this.previewUrl) {
  //       array.push(item.id)
  //     }
  //     this.addInventory.patchValue({
  //       file: array
  //     });
  //     this.uploadFile = false;
  //   }, (error) => {
  //     this.previewUrl = [];
  //     this.uploadFile = false;
  //     this.imgload = false
  //     this.toast.error(error.error['msg']);
  //   })
  // }

 

  deleteInvFile(id) {
    debugger;
    this.previewUrl = this.previewUrl.filter(x => x.id !== id);
    let array = [];
    for (let item of this.previewUrl) {
      array.push(item.id)
    }
    this.addInventory.value.product_file=[]
    this.addInventory.patchValue({
      product_file: array
    });
  }
  EditselectInvFile(event) {
    this.imgload = true
    this.fileData = event.target.files;
    if (this.fileData.length > 5 || this.previewUrl.length + this.fileData.length > 5) {
      this.toast.warning('You can upload upto 5 files');
      this.imgload = false
      return;
    }
    this.uploadFile = true;
    const formData = new FormData();
    for (let item of this.fileData) {
      formData.append('fileToUpload', item);
    }
    this.http.adduploadInvFiles(formData).subscribe(res => {
      // if (res?.plan_expired || res?.limit_exceeded) {
      //   this.sendClickEvent();
      // }
      this.toast.success(res['detail']);
      this.imgload = false
      for (let item of res['data']) {
        this.previewUrl.push(item);
      }
  
      let array = [];
      for (let item of this.previewUrl) {
        array.push(item.id)
      }
      this.editInventory.patchValue({
        product_file: array
      });
      this.uploadFile = false;
    }, (error) => {
      this.previewUrl = [];
      this.uploadFile = false;
      this.imgload = false
      this.toast.error(error.error['msg']);
    })






    // this.fileData = event.target.files[0];
    // const formData = new FormData();
    // formData.append('fileToUpload', this.fileData);
    // this.http.addInvFiles(formData).subscribe(res => {
    //   this.toast.success(res['detail']);
    //   this.invImg = res['data']
    //   this.editInventory.patchValue({
    //     product_file: this.invImg.id
    //   });
    //   this.uploadFile = false;
    // }, (error) => {
    //   this.previewUrl = [];
    //   this.uploadFile = false;
    //   this.toast.error(error.error['msg']);
    // })
  }
  selectPurchaseFile(event) {
    this.imgload = true
    this.fileData = event.target.files;
    // for (let file of event.target.files) {
    //   if (file.size / 1024 > 3000) {
    //     this.toast.warning('Each File size should be less then 3mb');
    //     return;
    //   }
    // }
    if (this.fileData.length > 5 || this.previewUrl.length + this.fileData.length > 5) {
      this.toast.warning('You can upload upto 5 files');
      this.imgload = false
      return;
    }
    this.uploadFile = true;
    const formData = new FormData();
    for (let item of this.fileData) {
      formData.append('fileToUpload', item);
    }
    this.http.addPurchaseFiles(formData).subscribe(res => {
      if (res?.plan_expired || res?.limit_exceeded) {
        this.sendClickEvent();
      }

      this.toast.success(res['detail']);
      for (let item of res['data']) {
        this.previewUrl.push(item);
      }

      let array = [];
      for (let item of this.previewUrl) {
        array.push(item.id)
      }
      this.addPurchaseBookForm.patchValue({
        file: array
      });
      this.imgload = false
      this.uploadFile = false;
    }, (error) => {
      this.previewUrl = [];
      this.imgload = false
      this.uploadFile = false;
      this.toast.error(error.error['msg']);
    })
  }
  selectSaleFile(event) {
    this.imgload = true
    this.fileData = event.target.files;

    // for (let file of event.target.files) {
    //   if (file.size / 1024 > 3000) {
    //     this.toast.warning('Each File size should be less then 3mb');
    //     return;
    //   }
    // }
    if (this.fileData.length > 5 || this.previewUrl.length + this.fileData.length > 5) {
      this.toast.warning('You can upload upto 5 files');
      this.imgload = false
      return;
    }
    this.uploadFile = true;
    const formData = new FormData();
    for (let item of this.fileData) {
      formData.append('fileToUpload', item);
    }
    this.http.addSaleFiles(formData).subscribe(res => {
    // Check for plan limit
      if (res?.plan_expired || res?.limit_exceeded) {
        this.sendClickEvent();
      }

      this.toast.success(res['detail']);
      for (let item of res['data']) {
        this.previewUrl.push(item);
      }

      let array = [];
      for (let item of this.previewUrl) {
        array.push(item.id)
      }
      this.addSaleBookForm.patchValue({
        file: array
      });
      this.imgload = false
      this.uploadFile = false;
    }, (error) => {
      this.previewUrl = [];
      this.uploadFile = false;
      this.imgload = false
      this.toast.error(error.error['msg']);
    })
  }
  selectExpenseFile(event) {
    this.imgload = true
    this.fileData = event.target.files;
    // for (let file of event.target.files) {
    //   if (file.size / 1024 > 3000) {
    //     this.toast.warning('Each File size should be less then 3mb');
    //     return;
    //   }
    // }
    if (this.fileData.length > 5 || this.previewUrl.length + this.fileData.length > 5) {
      this.toast.warning('You can upload upto 5 files');
      this.imgload = false
      return;
    }
    this.uploadFile = true;
    const formData = new FormData();
    for (let item of this.fileData) {
      formData.append('fileToUpload', item);
    }
    this.http.addExpensehFiles(formData).subscribe(res => {

      if (res?.plan_expired || res?.limit_exceeded) {
        this.sendClickEvent();
      }

      this.toast.success(res['detail']);
      for (let item of res['data']) {
        this.previewUrl.push(item);
      }

      let array = [];
      for (let item of this.previewUrl) {
        array.push(item.id)
      }
      this.addExpenseForm.patchValue({
        file: array
      });
      this.uploadFile = false;
      this.imgload = false
    }, (error) => {
      this.previewUrl = [];
      this.uploadFile = false;
      this.imgload = false
      this.toast.error(error.error['msg']);
    })
  }
  toggleRights(event, type) {
    if (type.toString() == 'create') {
      if (event.target.checked) {
        this.editEmployee.add_ledger = true;
      } else {
        this.editEmployee.add_ledger = false;
      }
    } else {
      if (event.target.checked) {
        this.editEmployee.single_ledger_permission = true;
      } else {
        this.editEmployee.single_ledger_permission = false;
      }
    }
  }
  editUserRights(id, ledger: boolean = false, edit: boolean = false, modal) {
    let permission = {
      emp_id: id,
      add_ledger: ledger,
      edit_ledger: edit,
      ledger_id: this.selectedLedger.id

    }

    this.http.editSinglePermissions(permission).subscribe((res) => {
      this.toast.success(res['detail']);
      this.getSelectedTrans(Number(this.urlUser));
      modal.dismiss();
    }, (error) => {
      this.toast.error(error['error'].detail);
    })
  }
  inActivateMember(id) {
    this.http.editPermissions(id).subscribe((res) => {
      this.toast.success(res['detail']);
      this.getSelectedTrans(Number(this.urlUser));
    }, (error) => {
      this.toast.error(error['error'].detail);
    })
  }
  changeRoomCurr(modal) {
    this.http.editCurrencyRoom(this.selectedLedger.id, this.roomCurrency).subscribe((res) => {
      modal.dismiss();
      this.toast.success(res['detail']);
      this.getSelectedTrans(Number(this.urlUser));
      this.ledgerList('');
    }, err => {
      this.toast.error(err.msg);
    })
  }
  deleteFile(id) {
    this.previewUrl = this.previewUrl.filter(x => x.id !== id);
    let array = [];
    for (let item of this.previewUrl) {
      array.push(item.id)
    }
    this.addLedgerForm.patchValue({
      file: array
    });
    ////  console.log('this is the preview', this.previewUrl);
    ////  console.log('this is the form', this.addLedgerForm.value.file);
    //
  }
  deleteEmployeeFile(id) {
    this.previewUrl = this.previewUrl.filter(x => x.id !== id);
    let array = [];
    for (let item of this.previewUrl) {
      array.push(item.id)
    }
    this.employeeService.addEmpTransection.patchValue({
      files: array
    });
    ////  console.log('this is the preview', this.previewUrl);
    ////  console.log('this is the form', this.addCashBookForm.value.file);
    //
  }
  deleteCashFile(id) {
    this.previewUrl = this.previewUrl.filter(x => x.id !== id);
    let array = [];
    for (let item of this.previewUrl) {
      array.push(item.id)
    }
    this.addCashBookForm.patchValue({
      file: array
    });
  }
  deleteAddbankFile(id) {
    this.previewUrl = this.previewUrl.filter(x => x.id !== id);
    let array = [];
    for (let item of this.previewUrl) {
      array.push(item.id)
    }
    this.addBankForm.patchValue({
      file: array
    });
  }
  deleteAddCardFile(id) {
    this.previewUrl = this.previewUrl.filter(x => x.id !== id);
    let array = [];
    for (let item of this.previewUrl) {
      array.push(item.id)
    }
    this.addCreditCard.patchValue({
      file: array
    });
  }
  deleteInventoryFile(id) {
    this.previewUrl = this.previewUrl.filter(x => x.id !== id);
    let array = [];
    for (let item of this.previewUrl) {
      array.push(item.id)
    }
    this.editInventory.patchValue({
      product_file: array
    });
  }
  deleteSaleFile(id) {
    this.previewUrl = this.previewUrl.filter(x => x.id !== id);
    let array = [];
    for (let item of this.previewUrl) {
      array.push(item.id)
    }
    this.addSaleBookForm.patchValue({
      file: array
    });
    ////  console.log('this is the preview', this.previewUrl);
    ////  console.log('this is the form', this.addCashBookForm.value.file);
    //
  }
  deletePurchaseFile(id) {
    this.previewUrl = this.previewUrl.filter(x => x.id !== id);
    let array = [];
    for (let item of this.previewUrl) {
      array.push(item.id)
    }
    this.addPurchaseBookForm.patchValue({
      file: array
    });
    ////  console.log('this is the preview', this.previewUrl);
    ////  console.log('this is the form', this.addCashBookForm.value.file);
    //
  }
  deleteExpFile(id) {
    this.previewUrl = this.previewUrl.filter(x => x.id !== id);
    let array = [];
    for (let item of this.previewUrl) {
      array.push(item.id)
    }
    this.addExpenseForm.patchValue({
      file: array
    });
    ////  console.log('this is the preview', this.previewUrl);
    ////  console.log('this is the form', this.addCashBookForm.value.file);
    //
  }
  deleteBankFile(id) {
    this.previewUrl = this.previewUrl.filter(x => x.id !== id);
    let array = [];
    for (let item of this.previewUrl) {
      array.push(item.id)
    }
    this.addBankTansForm.patchValue({
      file: array
    });
    ////  console.log('this is the preview', this.previewUrl);
    ////  console.log('this is the form', this.addCashBookForm.value.file);
    //
  }
  deleteCardFile(id) {
    this.previewUrl = this.previewUrl.filter(x => x.id !== id);
    let array = [];
    for (let item of this.previewUrl) {
      array.push(item.id)
    }
    this.addBankTansForm.patchValue({
      file: array
    });
    ////  console.log('this is the preview', this.previewUrl);
    ////  console.log('this is the form', this.addCashBookForm.value.file);
    //
  }

  deleteBankTran(id, bankId,modal) {
    let title, text, confirm, cancel;
    this.translate.get('Delete Transaction').subscribe((data: any) => {
      title = data;
    }),
      this.translate.get('Are you sure you want to delete this transaction?').subscribe((data: any) => {
        text = data;
      }),
      this.translate.get('Yes').subscribe((data: any) => {
        confirm = data;
      }),
      this.translate.get('No').subscribe((data: any) => {
        cancel = data;
      }),
      Swal.fire({
          imageUrl: '../../../../assets/delete.png',
          imageHeight:'50',
          title: title,
          text: text,
          showCancelButton: true,
          cancelButtonColor: '#6e7d88',
          confirmButtonColor: '#dc3545',
          cancelButtonText: cancel,
          confirmButtonText: confirm
      }).then((result) => {
        if (result.value) {
          this.http.deleteBankTransaction(id).subscribe(res => {
            this.toast.success(res['details'])
            this.getSingleCreditDetails(this.deleteCard);
            this.modalService.dismissAll();
            // modal.dismiss();
            this.banktransPage = 1
           // this.getBankTran(bankId)
          }, (error) => {
            this.toast.error(error['error']['msg']);
          })
        } else {
          return;
        }
      });


  }
  searchInvPro() {
    this.getnventoryPro()
    this.modalService.dismissAll()
  }
  searchbundleItem() {
    this.productListforBundle();
  }
  searchInvService() {
    this.getMarketListing()
    this.modalService.dismissAll()
  }
  searchInvbundle() {
    this.getBundleMarketListing()
    this.modalService.dismissAll()
  }
  invnCategeory()
  {
    this.http.getProductCatListing().subscribe(res => {
      // 
      this.invnCat=res['result']
      this.categoryListing=res['result']
      this.CatID= this.invnCat.id
      console.log("invn cat",this.invnCat)

    },
      (error) => {
        this.toast.error(error.error['msg']);
        this.loader = false
      }
    );
  }
   DeleteProductCat(item){
    this.http.deleteProductCategory(item.id).subscribe(res => {
      this.toast.success(res['detail']);
      this.invnCategeory()
    }, (error) => {
      this.toast.success(error['error']['msg']);
    })
  }
  // EditProductCat(item, modal) {
  //   this.http.editProductCat(this.addProductCat.value.title, item.id).subscribe(res => {
  //       this.toast.success(res['detail']);
  //       console.log('Check API', res);
  //       modal.dismiss(); // Close the modal on success
  //   });
  // }
  
  postLedger(inventoryModal: any = null, isShowInventoryModel: boolean = false) {
    ;
    this.transLoad = true;
    console.log(this.dataFromRightWindow);
    // console.log(this.dataFromRightWindow.item_and_description.unit_price)
    console.log(this.bank_Id);
    if(this.dataFromRightWindow!=null)
    {
      this.LedgertableData=[{'unit_price':this.dataFromRightWindow[0].item_and_description.unit_price,'quantity':this.dataFromRightWindow[0].item_and_description.quantity,'sub_total':this.dataFromRightWindow[0].item_and_description.total_price}]
    }  
    // if(this.bank_Id!='')
    // {
    //   this.record_type="bank"
    // }
    if(this.trans_type=='')
    {
     this.trans_type=this.addLedgerForm.controls.transaction_type.value;
    }

    let obj={
      transaction_type:this.trans_type,
      amount:this.addLedgerForm.controls.amount.value,
      starting_date:this.addLedgerForm.controls.starting_date.value,
      ledger_room_id:this.addLedgerForm.controls.ledger_room_id.value,
      information:this.addLedgerForm.controls.information.value,
      remarks:this.addLedgerForm.controls.remarks.value,
      file:this.addLedgerForm.controls.file.value,
      is_other_expense:this.addLedgerForm.controls.is_other_expense.value,
      transaction_sub_type: parseInt(this.addLedgerForm.controls.transaction_sub_type.value),
      ledger_trans_id:this.addLedgerForm.controls.ledger_trans_id.value,
      purchase_trans_id:this.addLedgerForm.controls.purchase_trans_id.value,
      expense_trans_id:this.addLedgerForm.controls.expense_trans_id.value,
      bank_id:this.bank_Id,
      invoice_id:this.invoice_Id,
      record_type:this.record_type,
      // cash_trans_id:this.addLedgerForm.controls.cash_trans_id.value,
      transaction_items:this.LedgertableData,
      sale_trans_id:this.addLedgerForm.controls.sale_trans_id.value,
      credit_card_room_id:this.card_Id,
      // invoice_id:this.invoiceId
      }
    
      if(this.addLedgerForm.controls.transaction_sub_type.value==='4' ||this.addLedgerForm.controls.transaction_sub_type.value==='8' )
      {
        if(!obj.is_other_expense)
        {
          obj.is_other_expense=false;
          obj.record_type=null;
        }
        if(obj.is_other_expense)
        {
          obj.record_type=null;
        }
      }
     

    //  if (this.trans_type ==1 && this.addLedgerForm.controls.transaction_sub_type.value==4 )
    //  {
    //   obj.is_other_expense=false;
    //   obj.record_type=null;
    //  }
    //  if (this.trans_type ==2 && this.addLedgerForm.controls.transaction_sub_type.value==8 )
    //  {
    //   obj.is_other_expense=true;
    //   obj.record_type=null;
    //  }
    this.patchAudioFile('addLedgerForm').then((res: any) => {
      this.http.addTransaction(obj).subscribe(res => {
        this.http.getTransaction(this.selectAccount.id,1,this.filters,'').subscribe(data=>{
          console.log(data)
          this.pendingtransactionCount=data['pendingTransactionsCount']
          this.transactions_data_get = data['transactions_data'];
          this.transactions_data = this.transactions_data_get.filter(transaction => transaction.action === 'Received');
         })
        this.transLoad = false;
        if (res?.plan_expired || res?.limit_exceeded) {
          this.sendClickEvent();
        }

        this.toast.success(res['detail']);
        this.transectionCurrency = res['currency_id']
        this.ledgerData = res['data']
        // this.audioService.resetRecordedAudio();
        this.ledgerList('');
        this.salecurrentPage = 1;
        this.getSaleTran();
        this.modalService.dismissAll();
        ////  console.log("dkkdkdkd", this.plusshare);
  
        if (this.plusshare === false && this.selectedLedger.currency.id === this.auth.userData.emp_info.Business.currency.id) {
  
          if (this.ledgerData.transaction_sub_type.toString() === '3') {
            this.updateledger = true
  
            this.addPurchaseBookForm.patchValue({
              purchase_transaction_type: '2',
              amount_balance: this.ledgerData.amount_balance,
              starting_date: this.addLedgerForm.value.starting_date,
              information: this.addLedgerForm.value.information,
              file: this.addLedgerForm.value.file,
              ledger_trans_id: this.ledgerData.id,
              transaction_items:this.LedgertableData
            })
            this.postPurchaseBook()
          }
          if (this.ledgerData.transaction_sub_type.toString() === '5') {
            this.updateledger = true
  
            this.addPurchaseBookForm.patchValue({
              purchase_transaction_type: '1',
              amount_balance: this.ledgerData.amount_balance,
              starting_date: this.addLedgerForm.value.starting_date,
              information: this.addLedgerForm.value.information,
              file: this.addLedgerForm.value.file,
              ledger_trans_id: this.ledgerData.id,
              transaction_items:this.LedgertableData
            })
            this.postPurchaseBook()
          }
          if (this.ledgerData.transaction_sub_type.toString() === '1') {
            this.updateledger = true
            this.addSaleBookForm.patchValue({
              sale_transaction_type: '1',
              amount_balance: this.ledgerData.amount_balance,
              starting_date: this.addLedgerForm.value.starting_date,
              information: this.addLedgerForm.value.information,
              file: this.addLedgerForm.value.file,
              ledger_trans_id: this.ledgerData.id,
              transaction_items:this.LedgertableData
            })
  
            ////  console.log(this.addSaleBookForm);
  
            this.postSaleBook()
          }
          if (this.ledgerData.transaction_sub_type.toString() === '7') {
            this.updateledger = true
            this.addSaleBookForm.patchValue({
              sale_transaction_type: '2',
              amount_balance: this.ledgerData.amount_balance,
              starting_date: this.addLedgerForm.value.starting_date,
              information: this.addLedgerForm.value.information,
              file: this.addLedgerForm.value.file,
              ledger_trans_id: this.ledgerData.id,
              transaction_items:this.LedgertableData

            })
  
            ////  console.log(this.addSaleBookForm);
  
            this.postSaleBook()
          }
        }
        if (this.cashshare === true) {
          this.cashcurrentPage = 1
          this.getCashTran()
        }
        if (this.saletran === true) {
          this.salecurrentPage = 1
          this.getSaleTran()
        }
        if (this.bankshare === true) {
          this.banktransPage = 1
          this.getBankTran(this.bankId)
        }
        if (this.exptran === true) {
          this.expensecurrentPage = 1
          this.getExpenseTran()
        }
        if (this.purchtran === true) {
          this.purchasecurrentPage = 1
          this.getPurchaseTran()
        }
        this.getSelectedTransold(Number(this.urlUser));
        this.getnventoryPro();
        if (inventoryModal && isShowInventoryModel) {
          this.modalService.open(inventoryModal, {
            centered: true,
            windowClass: 'ledger-participant',
            size: 'lg',
          });
        }
       
      }, (error) => {
        this.transLoad = false;
        this.toast.error(error.error['msg']);
      })
    })

  }

  
  AddInventory() {
    const currencyValue = this.addInventory.get('minimum_stock_level').value;
    // // alert(currencyValue)
    this.transLoad = true;
    if (!this.addInventory.value.information) {
      this.addInventory.get('information').setValue('');
    }
    if (!this.addInventory.value.market_visibility) {
      this.addInventory.get('market_visibility').setValue(false);
    }
    if (!this.addInventory.value.product_file) {
      this.addInventory.get('product_file').setValue([]);
    }
    this.addInventory.patchValue({ service_status: false });
    this.addInventory.patchValue({
      minimum_stock_level: currencyValue
    })
    this.addInventory.patchValue({
      currency: 1
    })
    this.addInventory.patchValue({
      own_entry: true
    })
    console.log(this.addInventory.value);
    this.http.addInventeryProduct(this.addInventory.value).subscribe(res => {
      this.transLoad = false;
      if (res?.plan_expired || res?.limit_exceeded) {
        this.sendClickEvent();
        return;
      }
      console.log('Check api',res)
      this.toast.success(res['detail']);
      this.getnventoryPro()
      this.modalService.dismissAll()


    }, 
    // (error) => {
    //   this.transLoad = false;
    //   this.toast.error(error.error['msg']);
    // }
    )
  }

  EditBundle(modal)
  {
    this.transLoad = true;
    this.marketServiceLoad = true;
    // if (!this.addService.value?.product_file) {
    //   this.addProduct.get('product_file').setValue([]);
    // }
    this.http.editBundle(this.addBundle.value, this.BundleStockId).subscribe(res => {
      this.transLoad = false;
      if (modal) {
        modal.dismiss();
      }
      this.marketServiceLoad = false;
      console.log('Check api',res)
      this.toast.success(res['detail']);
      this.modalService.dismissAll()
    }, 
    (error) => {
      this.marketServiceLoad = false;
      this.transLoad = false;
      this.toast.error(error.error['msg']);
    }
    )
    this.addProduct.reset();
  }

  AddBundle(modal)
  {
    
    if (!this.addService.value?.product_file) {
      this.addProduct.get('product_file').setValue([]);
    }
    if(!this.addBundle.value.product_or_services_items)
    {
      // debugger;
      this.toast.warning("First You have to Enter Product")
    }
    else
    {
      this.transLoad = true;
      this.marketServiceLoad = true;
      this.http.addBundlePost(this.addBundle.value).subscribe(res => {
        this.transLoad = false;
        if (modal) {
          modal.dismiss();
        }
        this.marketServiceLoad = false;
        console.log('Check api',res)
        this.toast.success(res['detail']);
        this.modalService.dismissAll()
        this.getBundleMarketListing();
      }, 
      (error) => {
        this.marketServiceLoad = false;
        this.transLoad = false;
        this.toast.error(error.error['msg']);
      }
      )
      this.addProduct.reset();
    }
   
  }
  AddMarketProduct(modal: any = null) {
    return new Promise((resolve, reject) => {
      this.transLoad = true;
      this.marketServiceLoad = true;
      if (!this.addService.value?.product_file) {
        this.addProduct.get('product_file').setValue([]);
      }
      ;
      this.http.addProductMarket(this.addService.value).subscribe(res => {
        resolve(true);
        this.transLoad = false;
        if (modal) {
          modal.dismiss();
        }
        this.marketServiceLoad = false;
        console.log('Check api',res)
        this.toast.success(res['detail']);
        this.modalService.dismissAll()
      }, 
      (error) => {
        this.marketServiceLoad = false;
        this.transLoad = false;
        this.toast.error(error.error['msg']);
      }
      )
      this.addProduct.reset();
    })
   
  }

  AdditemProduct(modal: any = null) {
    
    // const currencyValue = this.addInventory.get('minimum_stock_level').value;
     // // alert(currencyValue)
   
     const sellingPrice = this.addInventory.get('selling_price_per_unit').value;
     // Patch both form controls with the new value
     if (this.addInventory.value.minimum_stock_level==null)
      {
        this.addInventory.patchValue({
          minimum_stock_level: 0,
        })
      }
     this.addInventory.patchValue({
       selling_price_per_unit: sellingPrice,
       sales_price: sellingPrice
     }); // Prevents emitting a value change event
   
     this.transLoad = true;
     if (!this.addInventory.value.information) {
       this.addInventory.get('information').setValue('');
     }
     if (!this.addInventory.value.market_visibility) {
       this.addInventory.get('market_visibility').setValue(false);
     }
     if (!this.addInventory.value.product_file) {
       this.addInventory.get('product_file').setValue([]);
     }
      this.addInventory.patchValue({ service_status: false });
      this.addInventory.patchValue({
        reference_no: 'opening stock'
      })
     this.addInventory.patchValue({
       currency: this.auth.userData.emp_info.Business?.currency?.id,
       own_entry: true,
     })
     console.log(this.addInventory.value);
     this.http.addProductMarket(this.addInventory.value).subscribe(res => {
       this.transLoad = false;
       if (res?.plan_expired || res?.limit_exceeded) {
         this.sendClickEvent();
         return;
       }
       console.log('Check api',res)
       this.toast.success(res['detail']);
       this.getproDuctAcceptReject(this.uom,'');
       this.productListforBundle()
       if (modal) { // Check if modal is provided
        modal.dismiss(); // Close the specific modal
      }
      
     }, 
     (error) => {
       this.transLoad = false;
       this.toast.error(error.error['msg']);
     }
     )
    
   }
   
   productListforBundle()
   {
    debugger;
     this.itemlistingId=this.businessId;
     this.itemlistingtType='product';
     this.getBundleMarketListing();
   }
   itemMarkerCheck(value)
   {
    // alert(value);
    this.itemMarketVal=value;
   }
   EditProduct(modal: any = null)
   {
         // // alert(currencyValue)
     
         const sellingPrice = this.addInventory.get('selling_price_per_unit').value;
         // Patch both form controls with the new value
         ;
         this.addInventory.patchValue({
           selling_price_per_unit: sellingPrice,
           sales_price: sellingPrice
         }); // Prevents emitting a value change event
       
         this.transLoad = true;
         if (!this.addInventory.value.information) {
           this.addInventory.get('information').setValue('');
         }
         if (!this.addInventory.value.market_visibility) {
           this.addInventory.get('market_visibility').setValue(false);
         }
         if (!this.addInventory.value.product_file) {
           this.addInventory.get('product_file').setValue([]);
         }
         
         this.addInventory.patchValue({
           currency: this.auth.userData.emp_info.Business?.currency?.id,
         })
         console.log(this.addInventory.value);
         this.http.editInvPro(this.addInventory.value).subscribe(res => {
           this.transLoad = false;
           if (res?.plan_expired || res?.limit_exceeded) {
             this.sendClickEvent();
             return;
           }
           console.log('Check api',res)
           this.toast.success(res['detail']);
           this.getProductStock(this.addInventory.value.product_room_id);
           if (modal) { // Check if modal is provided
            modal.dismiss(); // Close the specific modal
          }
         }, 
         (error) => {
           this.transLoad = false;
           this.toast.error(error.error['msg']);
         }
         )
         
   }
   EditProductMarket(modal: any = null)
   {
         debugger;     
         const sellingPrice = this.addInventory.get('selling_price_per_unit').value;
         // Patch both form controls with the new value
         ;
         this.addInventory.patchValue({
           selling_price_per_unit: sellingPrice,
           sales_price: sellingPrice
         }); // Prevents emitting a value change event
       
         this.transLoad = true;
         if (!this.addInventory.value.information) {
           this.addInventory.get('information').setValue('');
         }
         if (!this.addInventory.value.market_visibility) {
           this.addInventory.get('market_visibility').setValue(false);
         }
         if (!this.addInventory.value.product_file) {
           this.addInventory.get('product_file').setValue([]);
         }
         
         this.addInventory.patchValue({
           currency: this.auth.userData.emp_info.Business?.currency?.id,
         })
         console.log(this.addInventory.value);
         let ID=this.addInventory.value.product_room_id
         this.addInventory.patchValue({
          id: ID
         })
         this.http.EditMarketProduct(this.addInventory.value).subscribe(res => {
           this.transLoad = false;
           if (res?.plan_expired || res?.limit_exceeded) {
             this.sendClickEvent();
             return;
           }
           console.log('Check api',res)
           this.toast.success(res['detail']);
           this.getProductStock(this.addInventory.value.product_room_id);
           if (modal) { // Check if modal is provided
            modal.dismiss(); // Close the specific modal
          }
         }, 
         (error) => {
           this.transLoad = false;
           this.toast.error(error.error['msg']);
         }
         )
         
   }
   AdditemProductCross(modal: any = null) {
    // const currencyValue = this.addInventory.get('minimum_stock_level').value;
     // // alert(currencyValue)
     this.transLoad = true;
     if (!this.addInventory.value.information) {
       this.addInventory.get('information').setValue('');
     }
     if (!this.addInventory.value.market_visibility) {
       this.addInventory.get('market_visibility').setValue(false);
     }
     if (!this.addInventory.value.product_file) {
       this.addInventory.get('product_file').setValue([]);
     }
      this.addInventory.patchValue({ service_status: false });
      this.addInventory.patchValue({
        reference_no: 'opening stock'
      })
     // this.addInventory.patchValue({
     //   minimum_stock_level: currencyValue
     // })
     this.addInventory.patchValue({
       currency: this.auth.userData.emp_info.Business?.currency?.id,
       own_entry: true
     })
     console.log(this.addInventory.value);
     this.http.addProductMarket(this.addInventory.value).subscribe(res => {
       this.transLoad = false;
       if (res?.plan_expired || res?.limit_exceeded) {
         this.sendClickEvent();
         return;
       }
       console.log('Check api',res)
       this.toast.success(res['detail']);
      //  this.getnventoryPro()
       if (modal) { // Check if modal is provided
        modal.dismiss(); // Close the specific modal
      }
     }, 
     (error) => {
       this.transLoad = false;
       this.toast.error(error.error['msg']);
     }
     )
    
   }
   UOM()
   {
      this.http.getuomTypes().subscribe(res => {
        this.uomtype = res['data'];
        console.log(this.uomtype,"uom");
        this.checkLimit=1;
  
      },
        (error) => {
          this.toast.error(error.error['msg']);
        }
      );
   }


  AddMarketService(modal: any = null) {
    return new Promise((resolve, reject) => {
      this.loader=true;
      this.transLoad = true;
      this.marketServiceLoad=true;
      if (!this.addService.value?.product_file) {
        this.addService.get('product_file').setValue([]);
      }
      ;
      this.addService.patchValue({
        currency:  this.auth.userData.emp_info.Business?.currency?.id,
        unit_price: 0,
        service_status: true,
      });
      
      this.http.addProductMarket(this.addService.value).subscribe(res => {
        resolve(true);
        this.transLoad = false;
        this.loader=false;
        this.marketServiceLoad=false;
        
        console.log('Check api',res)
        this.toast.success(res['detail']);
        this.getMarketListing();
        if (modal) { // Check if modal is provided
          modal.dismiss(); // Close the specific modal
        }
      }, 
      (error) => {
        this.marketServiceLoad=false;
        this.transLoad = false;
        this.loader=false;
        this.toast.error(error.error['msg']);
      }
      )
      this.addService.reset();
    })
    
  }

  EditService(modal: any = null) {
    return new Promise((resolve, reject) => {
      this.loader=true;
      this.transLoad = true;
      this.marketServiceLoad=true;
      if (!this.addService.value?.product_file) {
        this.addService.get('product_file').setValue([]);
      }
      ;
      // this.addService.patchValue({
      //   currency:  this.auth.userData.emp_info.Business?.currency?.id,
      //   unit_price: 0,
      //   service_status: true,
      // });
      
      this.http.EditMarketProduct(this.addService.value).subscribe(res => {
        resolve(true);
        this.transLoad = false;
        this.loader=false;
        this.marketServiceLoad=false;
        if (modal) {
          modal.dismiss();
        }
        console.log('Check api',res)
        this.toast.success(res['detail']);
        this.getMarketListing();
        this.modalService.dismissAll()
      }, 
      (error) => {
        this.marketServiceLoad=false;
        this.transLoad = false;
        this.loader=false;
        this.toast.error(error.error['msg']);
      }
      )
      this.addService.reset();
    })
    
  }
  checkQuantity() {
    if (this.stockOut.value.quantity > this.ProDataByRef.reference_room_data.stock_left) {
      this.stockOut.patchValue({
        quantity: this.ProDataByRef.reference_room_data.stock_left
      });
      let toast;
      this.translate.get("Quantity can't be greater then").subscribe((data: any) => {
        toast = data;
      });
      this.toast.warning(toast + ' ' + this.ProDataByRef.reference_room_data.stock_left);
    }
  }

  getBatchListBundle(id , type) {
    this.http.getBatchlistBundle(id ,type).subscribe(res => {
      console.log(res);
      this.BatchList = res['bundle_stock_information'];
      this.stockData = res['bundle_room_data'];
      console.log(this.BatchList);
    },
      (error) => {
        this.toast.error(error.error['msg']);
      }
    );
  }
  getBatchList(id) {
    this.http.getBatchlist(id).subscribe(res => {
      // 
      console.log(res);
      this.BatchList = res['stock_available_info'];
      this.stockData = res['product_data'];
      console.log(this.BatchList);
    },
      (error) => {
        this.toast.error(error.error['msg']);
      }
    );
  }
  ngbDateFormat(date) {
    try {
      let transDate = { year: 0, month: 0, day: 0 };
      var dateObj = new Date(date);
      transDate.year = dateObj.getUTCFullYear();
      transDate.month = dateObj.getUTCMonth() + 1;
      transDate.day = dateObj.getUTCDate();
      return transDate;
    } catch (e) {
      return null;
    }
  }
  getfinishedproList(id) {
    this.http.getfinishedPro(id).subscribe(res => {
      // 
      this.FinishProList = res['stock_finished_info'];
      this.stockData = res['product_data']
    },
      (error) => {
        this.toast.error(error.error['msg']);
      }
    );
  }

  getfinishedbundList(id) {
    this.http.getfinishedBundle(id).subscribe(res => {
      // 
      this.FinishProList = res['stock_finished_info'];
      this.stockData = res['product_data']
    },
      (error) => {
        this.toast.error(error.error['msg']);
      }
    );
  }
  addStockin(){
    this.transLoad = true;
    if(this.today.month<10)
      {
        this.currentMonth= '0'+ this.today.month
      }
      else{
        this.currentMonth = this.today.month.toString();;
      }
      if (this.today.day<10)
        {
          this.currentDay= '0'+ this.today.day
        }
      else
        {
          this.currentDay=this.today.day.toString();
        }
      this.adjustmentForm.patchValue({
        created_at: this.today.year + '-' + this.currentMonth + '-' + this.currentDay ,
      })
    this.http.addStockIn(this.adjustmentForm.value).subscribe(res => {
      this.toast.success(res['detail']);
      this.stockcurrentPage = 1
      // this.getProductStock(this.addInventory.value.product_id)
      this.transLoad = false;
      this.modalService.dismissAll();
      this.getProductStock(this.slectedID)
      // this.get()
    }, (error) => {
      this.transLoad = false;
      this.toast.error(error.error['msg']);
    })
  }

  editeStockIn()
  {
    debugger;
    // if(this.today.month<10)
    //   {
    //     this.currentMonth= '0'+ this.today.month
    //   }
    //   if (this.today.day<10)
    //     {
    //       this.currentDay= '0'+ this.today.day
    //     }
    //   this.adjustmentForm.patchValue({
    //     created_at: this.today.year + '-' + this.currentMonth + '-' + this.currentDay ,
    //   })
    this.http.editStockIn(this.adjustmentForm.value).subscribe(res => {
      this.transLoad = false;
      this.toast.success(res['detail']);
      this.stockcurrentPage = 1
      // this.getProductStock(this.addInventory.value.product_id)
      this.modalService.dismissAll();
      this.getProductStock(this.slectedID)
      // this.get()
    }, (error) => {
      this.transLoad = false;
      this.toast.error(error.error['msg']);
    })
  }

  editeStockOut()
  {
    // if(this.today.month<10)
    //   {
    //     this.currentMonth= '0'+ this.today.month
    //   }
    //   if (this.today.day<10)
    //     {
    //       this.currentDay= '0'+ this.today.day
    //     }
    //   this.adjustmentForm.patchValue({
    //     created_at: this.today.year + '-' + this.currentMonth + '-' + this.currentDay ,
    //   })
    this.http.editStockOut(this.adjustmentForm.value).subscribe(res => {
      this.transLoad = false;
      this.toast.success(res['detail']);
      this.stockcurrentPage = 1
      // this.getProductStock(this.addInventory.value.product_id)
      this.modalService.dismissAll();
      this.getProductStock(this.slectedID)
      // this.get()
    }, (error) => {
      this.transLoad = false;
      this.toast.error(error.error['msg']);
    })
  }
  addStockout(){
    this.transLoad = true;

    if(this.today.month<10)
      {
        this.currentMonth= '0'+ this.today.month
      }
      else
      {
        this.currentMonth= this.today.month.toString();
      }
      if (this.today.day<10)
        {
          this.currentDay= '0'+ this.today.day
        }
        else
        {
          this.currentDay=this.today.day.toString();
        }
      this.adjustmentForm.patchValue({
        created_at: this.today.year + '-' + this.currentMonth + '-' + this.currentDay ,
      })
    this.http.postStockOut(this.adjustmentForm.value).subscribe(res => {
      this.toast.success(res['detail']);
      this.stockcurrentPage = 1
      // this.getProductStock(this.addInventory.value.product_id)
      this.transLoad = false;

      this.modalService.dismissAll();
      this.getProductStock(this.slectedID)
      // this.get()
    }, (error) => {
      this.transLoad = false;
      this.toast.error(error.error['msg']);
    })
  }
  addBundleAdjustment()
  {
    console.log("info 2 chck")
    this.transLoad=true;
    this.ledgerService=true;
    // this.adjustmentFormBundle.value.information=this.adjustmentForm.value.reference_no;
    debugger
    this.http.bundleStockIn(this.adjustmentFormBundle.value).subscribe(res => {
      this.transLoad = false;
      this.toast.success(res['detail']);
      this.stockcurrentPage = 1
      this.getBundleStock(this.adjustmentFormBundle.value.bundle_id);
      // this.getProductStock(this.addInventory.value.product_id)
      this.modalService.dismissAll();
      // this.get()
    }, (error) => {
      this.transLoad = false;
      this.toast.error(error.error['msg']);
    })

  }
  addBundleAdjustmentOut()
  {
    this.transLoad=true;
    console.log("info check")
    let obj={
      stock_id: this.BundleStockId,
      file: this.adjustmentFormBundle.value.file ? this.adjustmentFormBundle.value.file : [],
      quantity: this.adjustmentFormBundle.value.quantity,
      information: this.adjustmentFormBundle.value.information,
    }
    
    this.http.bundleStockout(obj).subscribe(res => {
      this.transLoad = false;
      this.toast.success(res['detail']);
      this.stockcurrentPage = 1
      this.getBundleStock(this.adjustmentFormBundle.value.bundle_id);
      this.modalService.dismissAll();
      // this.getProductStock(this.addInventory.value.product_id)
     
      // this.get()
    }, (error) => {
      this.transLoad = false;
      this.toast.error(error.error['msg']);
    })

  }

  AddInventoryStockIn() {

    this.transLoad = true;
    if (!this.addInventory.value.information) {
      this.addInventory.get('information').setValue('');
    }
    if (!this.addInventory.value.market_visibility) {
      this.addInventory.get('market_visibility').setValue(false);
    }
    if (!this.addInventory.value.product_file) {
      this.addInventory.get('product_file').setValue([]);
    }
    this.http.addStockIn(this.addInventory.value).subscribe(res => {
      this.transLoad = false;
      this.toast.success(res['detail']);
      this.stockcurrentPage = 1
      this.getProductStock(this.addInventory.value.product_id)
      this.modalService.dismissAll();
      // this.get()
    }, (error) => {
      this.transLoad = false;
      this.toast.error(error.error['msg']);
    })
  }
  postExpense(modal: any = '') {
    this.response = false
    ////  console.log('this is the transaction', this.addLedgerForm.value);
    this.transLoad = true;
    let obj={
      amount_balance:this.addExpenseForm.controls.amount_balance.value,
      starting_date:this.addExpenseForm.controls.starting_date.value,
      information:this.addExpenseForm.controls.information.value,
      file:this.addExpenseForm.controls.file.value,
      ledger_trans_id:this.addExpenseForm.controls.ledger_trans_id.value,
      purchase_trans_id:this.addExpenseForm.controls.purchase_trans_id.value,
      expense_trans_id:this.addExpenseForm.controls.expense_trans_id.value,
      bank_id:this.addExpenseForm.controls.bank_trans_id.value,
      cash_trans_id:this.addExpenseForm.controls.cash_trans_id.value,
      transaction_items:this.tableDataEx
      }
    this.patchAudioFile('addExpenseForm').then((res: any) => {
      this.http.addExpense(obj).subscribe(res => {
        this.toast.success(res['detail']);
        this.transLoad = false;
        this.response = true
        this.audioService.resetRecordedAudio();
        if (this.updateledger) {
          this.getSelectedTransold(Number(this.urlUser));
        }
        this.transectionCurrency = res['currency_id']
        this.expensepostData = res['data']
        if (this.plusshare === true) {
          this.ledgerList(this.transectionCurrency)
        }
        this.bankcurrentPage = 1
        this.getAllBank(this.transectionCurrency)
        this.modalService.dismissAll();
        this.expensecurrentPage = 1
        this.getExpenseTran()
        if (this.shareafter === false) {
          this.sahreModal(modal)
        }
        if (this.cashshare === true) {
          this.cashcurrentPage = 1
          this.getCashTran()
        }
        if (this.bankshare === true) {
          this.banktransPage = 1
          this.getBankTran(this.bankId)
        }
      }, (error) => {
        this.transLoad = false;
        this.toast.error(error.error['msg']);
      })
    })

  }
  postEditExpense() {
    this.response = false
    ////  console.log('this is the transaction', this.addLedgerForm.value);
    this.transLoad = true;
    let value = this.addExpenseForm.getRawValue();
    value = {...value, transaction_items:this.LedgertableData,}
    this.patchAudioFile('addExpenseForm').then((res: any) => {
      this.http.editExpense(value).subscribe(res => {
        this.toast.success(res['details']);
        this.transLoad = false;
        this.response = true
        // if(this.updateledger){
        //   this.getSelectedTrans(Number(this.urlUser));
        // }
        // this.transectionCurrency = res['currency_id']
        // this.expensepostData = res['data']
        // if(this.plusshare === true){
        //   this.ledgerList(this.transectionCurrency)
        // }
        // this.getAllBank(this.transectionCurrency)
        this.modalService.dismissAll();
        this.expensecurrentPage = 1
        this.getExpenseTran()
  
      }, (error) => {
        this.transLoad = false;
        this.toast.error(error.error['msg']);
      })
    })

  }
  // AcceptjoinReq(id) {
  //   this.http.AccptJoiningrequest(id).subscribe(
  //     (res) => {
  //       if (res?.plan_expired || res?.limit_exceeded) {
  //         this.sendClickEvent();
  //         return;
  //       }
  //       this.toast.success(res['detail']);
  //       this.ledgerService.penddingjoinReq();
  //     },
  //     (err) => {
  //       this.toast.error(err['msg']);
  //     }
  //   );
  // }
  // global function for patch audio message in all modules//
  patchAudioFile(form: any) {
    this.audioService.stopRecording();
    return new Promise((resolve, reject) => {
      if ( this.audioService.recordedAudio) {
        const formData = new FormData();
        formData.append('fileToUpload', this.audioService.recordedAudio);
        this.http.addCashFiles(formData).subscribe(res => {
          let array = [];
          for (let item of this[form].value.file) {
            array.push(item);
          }
          array.push(res.file_ids[0]);
          this[form].patchValue({
            file: array
          });
          resolve(true);
        }, (error) => {
          reject(false);
        })
       } else {
        resolve(true);
       }
    })
  }
  //Ends here//

  async postCashBook(modal: any = '', inventoryModal: any = null, isShowInventoryModel: boolean = false) {
    this.response = false
    this.transLoad = true;
    if(this.radioButon==false)
    {
      this.addCashBookForm.patchValue({
        bank_id: null,
        record_type: "ajustment",
      })
    }
    this.addCashBookForm.patchValue({
      cash_transaction_type: parseInt(this.addCashBookForm.value.cash_transaction_type, 10),
      cash_own_transaction: true
    });
      this.http.addCashBook(this.addCashBookForm.value).subscribe(res => {
        this.toast.success(res['detail']);
        this.response = true;
        this.transLoad = false;
        this.audioService.resetRecordedAudio();
        this.transectionCurrency = res['currency_id']
        this.cashpostData = res['data']
        if (this.updateledger) {
          this.getSelectedTransold(Number(this.urlUser));
        }
        if (this.plusshare === true) {
          this.ledgerList(this.transectionCurrency)
        }
        this.bankcurrentPage = 1
        this.getAllBank(this.transectionCurrency)
        this.modalService.dismissAll();
        if (this.shareafter === false) {
          // this.sahreModal(modal)
        }
        this.cashcurrentPage = 1
        this.ReverseCashTran();
      }, (error) => {
        this.transLoad = false;
        this.toast.error(error.error['msg']);
      })   
  }
  // postCashBook(modal: any = '') {
  //   this.response = false

  //   this.transLoad = true;
  //   if (this.audioService.recordedAudio) {
  //     const formData = new FormData();
  //     formData.append('fileToUpload', this.audioService.recordedAudio);

  //       this.http.addCashFiles(formData).subscribe(res => {
          
  //         let array = [];
  //         for (let item of this.addCashBookForm.value.file) {
  //           array.push(item);
  //         }
      
  //         array.push(res.file_ids[0]);
      
  //         this.addCashBookForm.patchValue({
  //         file : array
  //         });
    


  //       this.http.addCashBook(this.addCashBookForm.value).subscribe(res => {
  //         this.toast.success(res['detail']);
  //         this.transLoad = false;
  //         this.response = true
  //         this.audioService.resetRecordedAudio();
  //         this.transectionCurrency = res['currency_id']
  //         this.cashpostData = res['data']
  //         if (this.updateledger) {
  //           this.getSelectedTransold(Number(this.urlUser));
  //         }
  //         if (this.plusshare === true) {
  //           this.ledgerList(this.transectionCurrency)
  //         }
  //         this.bankcurrentPage = 1
  //         this.getAllBank(this.transectionCurrency)
  //         this.modalService.dismissAll();
  //         if (this.shareafter === false) {
  //           this.sahreModal(modal)
  //         }
  //         this.cashcurrentPage = 1
  //         this.getCashTran()
  //         if (this.saletran === true) {
  //           this.salecurrentPage = 1
  //           this.getSaleTran()
  //         }
  //         if (this.bankshare === true) {
  //           this.banktransPage = 1
  //           this.getBankTran(this.bankId)
  //         }
  //         if (this.exptran === true) {
  //           this.expensecurrentPage = 1
  //           this.getExpenseTran()
  //         }
  //         if (this.purchtran === true) {
  //           this.purchasecurrentPage = 1
  //           this.getPurchaseTran()
  //         }
  //       }, (error) => {
  //         this.transLoad = false;
  //         this.toast.error(error.error['msg']);
  //       })
  //     }, (error) => {

  //     })
  //   } else {
  //     this.http.addCashBook(this.addCashBookForm.value).subscribe(res => {
  //       this.toast.success(res['detail']);
  //       this.transLoad = false;
  //       this.response = true
  
  //       this.transectionCurrency = res['currency_id']
  //       this.cashpostData = res['data']
  //       if (this.updateledger) {
  //         this.getSelectedTransold(Number(this.urlUser));
  //       }
  //       if (this.plusshare === true) {
  //         this.ledgerList(this.transectionCurrency)
  //       }
  //       this.bankcurrentPage = 1
  //       this.getAllBank(this.transectionCurrency)
  //       this.modalService.dismissAll();
  //       if (this.shareafter === false) {
  //         this.sahreModal(modal)
  //       }
  //       this.cashcurrentPage = 1
  //       this.getCashTran()
  //       if (this.saletran === true) {
  //         this.salecurrentPage = 1
  //         this.getSaleTran()
  //       }
  //       if (this.bankshare === true) {
  //         this.banktransPage = 1
  //         this.getBankTran(this.bankId)
  //       }
  //       if (this.exptran === true) {
  //         this.expensecurrentPage = 1
  //         this.getExpenseTran()
  //       }
  //       if (this.purchtran === true) {
  //         this.purchasecurrentPage = 1
  //         this.getPurchaseTran()
  //       }
  //     }, (error) => {
  //       this.transLoad = false;
  //       this.toast.error(error.error['msg']);
  //     })
  //   }


    
  // }
  postEditCashBook() {
    this.response = false
    ////  console.log('this is the transaction', this.addLedgerForm.value);
    this.transLoad = true;
      this.http.addEditCashBook(this.addCashBookForm.value).subscribe(res => {
        this.toast.success(res['details']);
        this.transLoad = false;
        this.response = true
        this.transectionCurrency = res['currency_id']
        this.cashpostData = res['data']
        this.modalService.dismissAll();
        this.cashcurrentPage = 1
        this.getCashTran()
        this.ReverseCashTran()
      }, (error) => {
        this.transLoad = false;
        this.toast.error(error.error['msg']);
      })

  }

  // to Edit audio file when user remove audio
  popAudioFile(fileArray: any, form) {
    if (fileArray?.length && form) {
      let findAudio = fileArray.find(x => x.file_type == 'audio');
      let arrayIds = this[form].get('file').value;
      arrayIds.splice(arrayIds.indexOf(findAudio.id), 1);
      this[form].get('file').setValue(arrayIds);
    }
  }

  postPurchaseBook(modal: any = '') {
    this.response = false
    ////  console.log('this is the transaction', this.addLedgerForm.value);
    this.transLoad = true;
    let obj={
      purchase_transaction_type:this.addPurchaseBookForm.controls.purchase_transaction_type.value,
      amount_balance:this.addPurchaseBookForm.controls.amount_balance.value,
      starting_date:this.addPurchaseBookForm.controls.starting_date.value,
      information:this.addPurchaseBookForm.controls.information.value,
      file:this.addPurchaseBookForm.controls.file.value,
      ledger_trans_id:this.addPurchaseBookForm.controls.ledger_trans_id.value,
      purchase_trans_id:this.addPurchaseBookForm.controls.purchase_trans_id.value,
      expense_trans_id:this.addPurchaseBookForm.controls.expense_trans_id.value,
      bank_id:this.addPurchaseBookForm.controls.bank_trans_id.value,
      cash_trans_id:this.addPurchaseBookForm.controls.cash_trans_id.value,
      transaction_items:this.PurchasetableData
      }
    this.patchAudioFile('addPurchaseBookForm').then((res: any) => {
      this.http.addPurchaseBook(obj).subscribe(res => {
        this.toast.success(res['detail']);
        this.transLoad = false;
        this.response = true
        this.transectionCurrency = res['currency_id']
        this.purchasepostData = res['data']
        if (this.updateledger) {
          this.getSelectedTransold(Number(this.urlUser));
        }
        if (this.plusshare === true) {
          this.ledgerList(this.transectionCurrency)
        }
        this.bankcurrentPage = 1
        this.getAllBank(this.transectionCurrency)
        //this.modalService.dismissAll();
        this.purchasecurrentPage = 1
        this.getPurchaseTran()
        if (this.shareafter === false) {
          this.sahreModal(modal)
        }
        if (this.cashshare === true) {
          this.cashcurrentPage = 1
          this.getCashTran()
        }
        if (this.bankshare === true) {
          this.banktransPage = 1
          this.getBankTran(this.bankId)
        }
      }, (error) => {
        this.transLoad = false;
        this.toast.error(error.error['msg']);
      })
    })

  }
  addprodcat(modal){
    this.transLoad = true;
    this.http.addproductcategory(this.addProductCat.value.title).subscribe(res => {
      this.toast.success(res['detail']);
      this.invnCategeory();
      this.transLoad = false;
      if (modal) {
        modal.dismiss();
      }
  },(error) => {
    this.transLoad = false;
    this.toast.error(error.error['msg']);
  })

  }
  postEditPurchaseBook() {
    this.response = false
    ////  console.log('this is the transaction', this.addLedgerForm.value);
    this.transLoad = true;
    let value = this.addPurchaseBookForm.getRawValue();
    value = {...value, transaction_items:this.LedgertableData,}
    this.patchAudioFile('addPurchaseBookForm').then((res: any) => {
      this.http.editPurchaseBook(value).subscribe(res => {
        this.toast.success(res['details']);
        this.transLoad = false;
        this.response = true
 
        this.transectionCurrency = res['currency_id']
        this.purchasepostData = res['data']
        // if(this.updateledger){
        //   this.getSelectedTrans(Number(this.urlUser));
        // }
        // if(this.plusshare === true){
        //   this.ledgerList(this.transectionCurrency)
        // }
        // this.getAllBank(this.transectionCurrency)
        this.modalService.dismissAll();
        this.purchasecurrentPage = 1
        this.getPurchaseTran();
        
      }, (error) => {
        this.transLoad = false;
        this.toast.error(error.error['msg']);
      })
    })

  }
  EditMarketProd() {
    this.marketServiceLoad = true;
      this.http.EditMarketProduct(this.addProduct.value).subscribe(res => {
        this.marketServiceLoad = false;
        this.toast.success(res['details']);
        this.modalService.dismissAll();
        this.marketService.page = 0;
        this.marketService.getMarketlist(this.auth.userData?.emp_info?.Business?.id,'All')
      }, (error) => {
        this.marketServiceLoad = false;
        this.toast.error(error.error['msg']);
      })
  }
  addServices()
  {
    // // alert("hi");
    console.log(this.addService.value);
    this.marketServiceLoad = true;
    this.loader = true;
    this.addService.value.market_visibility=false;
    this.http.addInventeryProduct(this.addService.value).subscribe(res => {
      this.loader = false;
      this.marketServiceLoad = false;
      if (res?.plan_expired || res?.limit_exceeded) {
        this.sendClickEvent();
        return;   
      }
      console.log('Check api',res)
      this.toast.success(res['detail']);
      this.invnprotype=2;
      this.getnventoryPro();
      this.addService.reset();
      this.modalService.dismissAll()
    },(error)=>{
      this.addService.reset();
      this.marketServiceLoad = false;
      this.loader = false;
      this.toast.error(error.error['msg']);
    }
    )
  }
  EditMarketService(modal: any = null) {
    return new Promise((resolve, reject) => {
      this.marketServiceLoad = true;
      this.http.EditMarketProduct(this.addService.value).subscribe(res => {
        this.toast.success(res['details']);
        this.marketServiceLoad = false;
        this.modalService.dismissAll();
        resolve(true);
        this.marketService.page = 0;
        this.marketService.getMarketlist(this.auth.userData?.emp_info?.Business?.id,'All')
      }, (error) => {
        this.marketServiceLoad = false;
        this.toast.error(error.error['msg']);
      })
    })
    
}
  cPlusWithInvoiceRecords(id, ctype, type) {
    this.http.addinvoiceRecord(id, ctype, type).subscribe(res => {
      this.inVoiceRecord = res['']
      console.log('My Invoice Apiii', this.inVoiceRecord)
    })
  }
  async postSaleBook(modal: any = '') {
    console.log(this.addSaleBookForm.value,'Modal Valurd')
    this.response = false
    ////  console.log('this is the transaction', this.addLedgerForm.value);
    this.transLoad = true;
    let obj={
    sale_transaction_type:this.addSaleBookForm.controls.sale_transaction_type.value,
    amount_balance:this.addSaleBookForm.controls.amount_balance.value,
    starting_date:this.addSaleBookForm.controls.starting_date.value,
    information:this.addSaleBookForm.controls.information.value,
    file:this.addSaleBookForm.controls.file.value,
    ledger_trans_id:this.addSaleBookForm.controls.ledger_trans_id.value,
    purchase_trans_id:this.addSaleBookForm.controls.purchase_trans_id.value,
    expense_trans_id:this.addSaleBookForm.controls.expense_trans_id.value,
    bank_id:this.addSaleBookForm.controls.bank_trans_id.value,
    cash_trans_id:this.addSaleBookForm.controls.cash_trans_id.value,
    transaction_items:this.tableData
    }
    this.patchAudioFile('addSaleBookForm').then((res: any) => {
      this.http.addSaleBook(obj).subscribe(res => {
        this.toast.success(res['detail']);
        this.transLoad = false;
        this.response = true
        this.audioService.resetRecordedAudio();
        this.transectionCurrency = res['currency_id']
        this.SalePostData = res['data']
        if (this.updateledger) {
          this.getSelectedTransold(Number(this.urlUser));
        }
        if (this.plusshare === true) {
          this.ledgerList(this.transectionCurrency)
        }
        this.bankcurrentPage = 1
        this.getAllBank(this.transectionCurrency)
        //this.modalService.dismissAll();
        this.salecurrentPage = 1
        this.getSaleTran()
        if (this.shareafter === false) {
          this.sahreModal(modal)
        }
        if (this.cashshare === true) {
          this.cashcurrentPage = 1
          this.getCashTran()
        }
        if (this.bankshare === true) {
          this.banktransPage = 1
          this.getBankTran(this.bankId)
        }
  
      }, (error) => {
        this.transLoad = false;
        this.toast.error(error.error['msg']);
      })
    })

  }
  postSaleBookAfterInvoice(trans_type: any = null, amount: any = null, date: any = null, transId: any = null) {
    this.transLoad = true;
    let obj = {
      sale_transaction_type: trans_type,
      amount_balance: amount,
      starting_date: date,
      ledger_trans_id: transId,
      file: [],
      transaction_items: []
    }
    this.http.addSaleBook(obj).subscribe(res => {
      this.toast.success(res['detail']);
      this.transLoad = false;
    }, (error) => {
      this.transLoad = false;
      this.toast.error(error.error['msg']);
    })
  }
  postPurchaseBookAfterInvoice(trans_type: any = null, amount: any = null, date: any = null, transId: any = null) {
    this.transLoad = true;
    let obj = {
      purchase_transaction_type: trans_type,
      amount_balance: amount,
      starting_date: date,
      ledger_trans_id: transId,
      file: [],
      transaction_items: []
    }
    this.http.addPurchaseBook(obj).subscribe(res => {
      this.toast.success(res['detail']);
      this.transLoad = false;
      this.getSelectedTransold(Number(this.urlUser));
    }, (error) => {
      this.transLoad = false;
      this.toast.error(error.error['msg']);
    })
  }
  postEditSaleBook() {
    this.response = false
    ////  console.log('this is the transaction', this.addLedgerForm.value);
    this.transLoad = true;
    let value = this.addSaleBookForm.getRawValue();
    value = {...value, transaction_items:this.LedgertableData,}
    this.patchAudioFile('addSaleBookForm').then((res: any) => {
      this.http.addEditSaleBook(value).subscribe(res => {
        this.toast.success(res['details']);
        this.transLoad = false;
        this.response = true
        this.audioService.resetRecordedAudio();
        this.transectionCurrency = res['currency_id']
        this.SalePostData = res['data']
        // if(this.updateledger){
        //   this.getSelectedTrans(Number(this.urlUser));
        // }
        // if(this.plusshare === true){
        //   this.ledgerList(this.transectionCurrency)
        // }
        // this.getAllBank(this.transectionCurrency)
        this.modalService.dismissAll();
        this.salecurrentPage = 1
        this.getSaleTran()
      }, (error) => {
        this.transLoad = false;
        this.toast.error(error.error['msg']);
      })
    })

  }
  postankTran(modal: any = '', inventoryModal: any = null, isShowInventoryModel: boolean = false) {
    this.response = false
    ////  console.log('this is the transaction', this.addBankTansForm.value);
    this.transLoad = true;
    if(this.radioButon==false)
    {
      this.ledgerService.addBankTansForm.patchValue({
        bank_id: null,
        record_type: "ajustment",
      })
    }
    this.ledgerService,this.addBankTansForm.patchValue({
      bank_own_transaction: true,
    })
    this.patchAudioFile('addBankTansForm').then((res: any) => {
      this.http.addBankrans(this.addBankTansForm.value).subscribe(res => {
        this.toast.success(res['detail']);
        this.response = true;
        this.transLoad = false;
        this.audioService.resetRecordedAudio();
        this.transectionCurrency = res['currency_id']
        this.bankpostData = res['data']
        this.modalService.dismissAll();
        this.getBankTranReverse(this.addBankTansForm.value.bank_room_id)
        
      }, (error) => {
        this.transLoad = false;
        this.toast.error(error.error['msg']);
      })
    })
  }
  sahreModal(modal) {

    try {
      setTimeout(() => {
        this.modalService.open(modal, {
          centered: true,
          windowClass: 'ledger-debit',
          size: 'lg',
        });
      }, 3000)
    } catch (e) { }
    // this.shareafter= !this.shareafter 
  }
  postEditBankTran() {
    this.response = false
    ////  console.log('this is the transaction', this.addBankTansForm.value);
    this.transLoad = true;
    
    this.patchAudioFile('addBankTansForm').then((res: any) => {
      this.http.editBankrans(this.addBankTansForm.value).subscribe(res => {
        this.transLoad = false;
        this.toast.success(res['details']);
        this.response = true
        this.transectionCurrency = res['currency_id']
        this.bankpostData = res['data']
        this.modalService.dismissAll();
        this.banktransPage = 1
        this.getBankTranReverse(this.addBankTansForm.value.bank_room_id)
        this.getBankTran(this.addBankTansForm.value.bank_room_id)
      }, (error) => {
        this.transLoad = false;
        this.toast.error(error.error['msg']);
      })
    })

  }
  postEditCardTran() {
    this.response = false
    this.transLoad = true;
    this.http.editCreditTrans(this.addCreditTansForm.value).subscribe(res => {
    this.transLoad = false;
    this.toast.success(res['details']);
    this.response = true
    this.transectionCurrency = res['currency_id']
    this.bankpostData = res['data']
    this.modalService.dismissAll();
    this.banktransPage = 1
    this.getCreditTran(this.addCreditTansForm.value.credit_card_room_id)
    this.ReversegetSingleCreditDetails(this.deleteCard)
    }, (error) => {
      this.transLoad = false;
      this.toast.error(error.error['msg']);
    })
  }

  editBankName() {
    this.transLoad = true;
    this.http.editBankName(this.editBankForm.value).subscribe(
      res => {
        this.toast.success(res['detail']);
        this.transLoad = false;
        this.modalService.dismissAll();
        this.getAllBank(this.transectionCurrency);
        this.banktransPage = 1;
        this.getBankTran(this.bankRoomID);
      },
      error => {
        this.transLoad = false;
        this.toast.error(error.error['msg']);
      }
    );
  }
  

  editCardName() {
    this.transLoad = true;
    this.http.changeCardTitle(this.editCardForm.value).subscribe(
      res => {
        this.toast.success(res['detail']);
        this.transLoad = false;
        this.modalService.dismissAll();
        this.getSingleCreditDetails(this.deleteCard);
        this.banktransPage = 1;
        this.getCreditTran(this.bankRoomID);
      },
      error => {
        this.transLoad = false;
        this.toast.error(error.error['msg']);
      }
    );
  }
  
  addBank() {
    console.log('bank clicked');
    this.transLoad = true;
    // Ensure 'file' is set to an empty array if null
  if (!this.addBankForm.value.file) {
    this.addBankForm.patchValue({ file: [] });
  }
  
    // Convert bank_transaction_type to integer if it is a string
    if (typeof this.addBankForm.value.bank_transaction_type === 'string') {
      this.addBankForm.value.bank_transaction_type = parseInt(this.addBankForm.value.bank_transaction_type, 10);
    }
  
    this.http.addBank(this.addBankForm.value).subscribe(res => {
      this.transLoad = false;
      if (res?.plan_expired || res?.limit_exceeded) {
        this.sendClickEvent();
        return;
      }
      this.toast.success(res['detail']);
      this.bankcurrentPage = 1
      this.getAllBank(this.auth.userData.emp_info.Business.currency.id)
      
    }, (error) => {
      this.transLoad = false;
      this.toast.error(error.error['msg']);
    });
  }
  
  // addBank() {
  //   console.log('bank clicked');
  //   this.transLoad = true;
    
  //   this.http.addBank(this.addBankForm.value).subscribe(res => {
  //     this.transLoad = false;
  //     if (res?.plan_expired || res?.limit_exceeded) {
  //       this.sendClickEvent();
  //       return;
  //     }
  //     this.toast.success(res['detail']);
  //     this.bankcurrentPage = 1
  //     this.getAllBank(this.auth.userData.emp_info.Business.currency.id)
      
  //   }, (error) => {
  //     this.transLoad = false;
  //     this.toast.error(error.error['msg']);
  //   })
  // }

  
  addCreditForm()
  {
    this.transLoad = true;
    this.addCreditTansForm.patchValue({
      starting_date: this.today.year + '-' + this.today.month + '-' + this.today.day,
      amount:this.addCreditTansForm.get('amount').value,
      information:this.addCreditTansForm.get('information').value,

    });
    this.patchAudioFile('addCreditTansForm').then((res: any) => {
      this.addCreditTansForm.patchValue({
        file: [] 
      });
      const formValue = this.addCreditTansForm.value;
      this.addCreditTansForm.patchValue({
        credit_card_own_transaction: true,
      });
      formValue.credit_card_transaction_type = parseInt(formValue.credit_card_transaction_type);
      this.addCreditTansForm.value.credit_card_transaction_type=parseInt(this.addCreditTansForm.value.credit_card_transaction_type)
      this.http.addCreditCardDetail(this.addCreditTansForm.value).subscribe(res => {
        this.toast.success(res['detail']);
        this.modalService.dismissAll();
        this.addCreditTansForm.reset();
        this.audioService.resetRecordedAudio();
        this.transLoad = false;
        this.getSingleCreditDetails(this.deleteCard)
        this.ReversegetSingleCreditDetails(this.deleteCard)

      }, (error) => {
        this.transLoad = false;
        this.toast.error(error.error['msg']);
      }) 
    })
 
    // console.log(this.ledgerService.addCreditTansForm);
  }
  editCreditForm()
  {
    this.loader=true;
    this.addCreditTansForm.patchValue({
      starting_date: this.today.year + '-' + this.today.month + '-' + this.today.day,
      amount:this.addCreditTansForm.get('amount').value,
      information:this.addCreditTansForm.get('information').value,
    });
    this.patchAudioFile('addCreditTansForm').then((res: any) => {
      this.http.editCreditCardDetails(this.addCreditTansForm.value).subscribe(res => {
        this.toast.success(res['detail']);
        this.modalService.dismissAll();
        this.addCreditTansForm.reset();
        this.audioService.resetRecordedAudio();
        this.loader=false;
        this.getSingleCreditDetails(this.deleteCard)
      
      }, (error) => {
        this.transLoad = false;
        this.toast.error(error.error['msg']);
      }) 
    })
 
    // console.log(this.ledgerService.addCreditTansForm);
  }
  addCredit(modal)  {
  const formData = this.addCreditCard.value;
  this.addCreditCard.patchValue({
    amount: formData.amount,
    account_title: formData.account_title,
    starting_date: formData.starting_date,
  });
  if (!this.addCreditCard.value.file) {
    this.addCreditCard.patchValue({ file: [] });
  }
    console.log(this.addCreditCard.value);
    this.transLoad = true;
    this.addCreditCard.value.credit_card_transaction_type=parseInt(this.addCreditCard.value.credit_card_transaction_type)
    this.http.addCreditCard(this.addCreditCard.value).subscribe(res => {
      this.transLoad = false;
      if (res?.plan_expired || res?.limit_exceeded) {
        this.sendClickEvent();
        return;
      }
      this.toast.success(res['detail']);
      this.getAllCreditCardData();
      // this.modalService.dismissAll();
      this.bankcurrentPage = 1
      
              
    }, (error) => {
      this.transLoad = false;
      this.toast.error(error.error['msg']);
    }) 
  }

  

  CashresetFilters(modal) {
    modal.dismiss();
    this.CashFilters.patchValue({
      transaction_type: '',
      search_query: '',
      start_date: '',
      end_date: '',
    });
  }
  getCashTran() {

    this.loader = true
    if (this.CashFilters.value.search_query === null) {
      this.CashFilters.patchValue({
        search_query: ""
      })
    }
    if (this.CashFilters.value.end_date === null) {
      this.CashFilters.patchValue({
        end_date: ""
      })
    }
    if (this.CashFilters.value.start_date === null) {
      this.CashFilters.patchValue({
        start_date: ""
      })
    }
    if (this.CashFilters.value.transaction_type === null) {
      this.CashFilters.patchValue({
        transaction_type: ""
      })
    }
    this.http.getCashTransaction(this.CashFilters.value.transaction_type, this.CashFilters.value.search_query, this.CashFilters.value.start_date, this.CashFilters.value.end_date, this.cashcurrentPage).subscribe(res => {
      // 
      debugger;
      if (this.cashcurrentPage === 1) {
        this.cashTransData = res['cash_transaction_data'];
        this.cashListing=res['cash_transaction_data'];
        console.log(this.cashTransData,'cash listing');
      }
      if (this.cashcurrentPage > 1) {
        this.cashTransData = res['cash_transaction_data'];
        this.cashListing=res['cash_transaction_data'];
        console.log(this.cashTransData,'cash listing');
        // for (let item of res['cash_transaction_data'])
        //   this.cashTransData.push(item);
      }
      this.cashTrans = res;
      this.cashcurrentPage = res['page']
      this.cashTotalPage = res['totalPages']
      this.loader = false

    },
      (error) => {
        this.toast.error(error.error['msg']);
        this.loader = false
      }
    );
  }
  ReverseCashTran() {

    this.loader = true
    if (this.CashFilters.value.search_query === null) {
      this.CashFilters.patchValue({
        search_query: ""
      })
    }
    if (this.CashFilters.value.end_date === null) {
      this.CashFilters.patchValue({
        end_date: ""
      })
    }
    if (this.CashFilters.value.start_date === null) {
      this.CashFilters.patchValue({
        start_date: ""
      })
    }
    if (this.CashFilters.value.transaction_type === null) {
      this.CashFilters.patchValue({
        transaction_type: ""
      })
    }
    this.http.getCashTransaction(this.CashFilters.value.transaction_type, this.CashFilters.value.search_query, this.CashFilters.value.start_date, this.CashFilters.value.end_date, this.cashcurrentPage).subscribe(res => {
      console.log(this.loader)
      if (this.cashcurrentPage === 1) {
        this.cashTransDataR = res['cash_transaction_data'];
        console.log(this.cashTransDataR,'cash listing');
      }
      if (this.cashcurrentPage > 1) {
        // for (let item of res['cash_transaction_data'])
        //   this.cashTransDataR.push(item);
        this.cashTransDataR = res['cash_transaction_data'];
        console.log(this.cashTransDataR,'cash listing');
      }
      this.cashTransDataR.reverse()
      this.cashTrans = res;
      this.cashcurrentPage = res['page']
      this.cashTotalPage = res['totalPages']
      this.loader = false
    },
      (error) => {
        this.toast.error(error.error['msg']);
        this.loader = false
      }
    );
  }



  getdrivefile() {

    this.loader = true
    if (this.DriveFilters.value.search_query === null) {
      this.DriveFilters.patchValue({
        search_query: ""
      })
    }
    if (this.DriveFilters.value.end_date === null) {
      this.DriveFilters.patchValue({
        end_date: ""
      })
    }
    if (this.DriveFilters.value.start_date === null) {
      this.DriveFilters.patchValue({
        start_date: ""
      })
    }
    this.http.getfilesByCat(this.currentType, this.DriveFilters.value.search_query, this.DriveFilters.value.start_date, this.DriveFilters.value.end_date).subscribe(res => {
      
      // if(this.cashcurrentPage === 1){
      //   this.cashTransData = res['cash_transaction_data'];        
      // }
      // if(this.cashcurrentPage > 1){
      //   for(let item of res['cash_transaction_data'])
      //   this.cashTransData.push(item);
      // }


      // this.cashTrans = res ;
      // this.loader = false
      // this.cashcurrentPage = res['page']
      // this.cashTotalPage = res['totalPages']
      this.foderData = res['file_data']
      this.loader = false;
    },
      (error) => {
        this.toast.error(error.error['msg']);
        this.loader = false
      }
    );
  }
  numberExponentToLarge(numIn) {
    numIn += "";                                            // To cater to numric entries
    var sign = "";                                           // To remember the number sign
    numIn.charAt(0) == "-" && (numIn = numIn.substring(1), sign = "-"); // remove - sign & remember it
    var str = numIn.split(/[eE]/g);                        // Split numberic string at e or E
    if (str.length < 2) return sign + numIn;                   // Not an Exponent Number? Exit with orginal Num back
    var power = str[1];                                    // Get Exponent (Power) (could be + or -)
    if (power == 0 || power == -0) return sign + str[0];       // If 0 exponents (i.e. 0|-0|+0) then That's any easy one

    var deciSp = 1.1.toLocaleString().substring(1, 2);  // Get Deciaml Separator
    str = str[0].split(deciSp);                        // Split the Base Number into LH and RH at the decimal point
    var baseRH = str[1] || "",                         // RH Base part. Make sure we have a RH fraction else ""
      baseLH = str[0];                               // LH base part.

    if (power > 0) {   // ------- Positive Exponents (Process the RH Base Part)
      if (power > baseRH.length) baseRH += "0".repeat(power - baseRH.length); // Pad with "0" at RH
      baseRH = baseRH.slice(0, power) + deciSp + baseRH.slice(power);      // Insert decSep at the correct place into RH base
      if (baseRH.charAt(baseRH.length - 1) == deciSp) baseRH = baseRH.slice(0, -1); // If decSep at RH end? => remove it

    }
    //  else {         // ------- Negative Exponents (Process the LH Base Part)
    //     num= Math.abs(power) - baseLH.length;                               // Delta necessary 0's
    //     if (num>0) baseLH = "0".repeat(num) + baseLH;                       // Pad with "0" at LH
    //     baseLH = baseLH.slice(0, power) + deciSp + baseLH.slice(power);     // Insert "." at the correct place into LH base
    //     if (baseLH.charAt(0) == deciSp) baseLH="0" + baseLH;                // If decSep at LH most? => add "0"
    //  }
    //  console.log("CashIn date", sign + baseLH + baseRH);
    return sign + baseLH + baseRH;                                          // Return the long number (with sign)
  }


  getProductStock(id) {
    debugger;
    this.loader = true
    if (this.InventoryFilters.value.search_query === null) {
      this.InventoryFilters.patchValue({
        search_query: ""
      })
    }
    if (this.InventoryFilters.value.end_date === null) {
      this.InventoryFilters.patchValue({
        end_date: ""
      })
    }
    if (this.InventoryFilters.value.start_date === null) {
      this.InventoryFilters.patchValue({
        start_date: ""
      })
    }
    if (this.InventoryFilters.value.transaction_type === null) {
      this.InventoryFilters.patchValue({
        transaction_type: ""
      })
    }
    this.http.getinventoryProductStockData(id, this.InventoryFilters.value.start_date, this.InventoryFilters.value.end_date, this.InventoryFilters.value.transaction_type, this.InventoryFilters.value.search_query, this.stockcurrentPage).subscribe(res => {
      // 
      if (this.stockcurrentPage === 1) {
        this.productStockData = res['product_stock_data']
      }
      if (this.stockcurrentPage > 1) {
        for (let item of res['product_stock_data'])
          this.productStockData.push(item);
      }
      this.productStock = res;
      this.loader = false;
       console.log(this.productStock);
      this.adjustmentForm.patchValue({
        product_id: this.productStock.product_data.id,
        unit_price:this.productStock.product_data.unit_price,
        uom: this.productStock.product_stock_data[0].uom.id,
        created_at: this.today.year + '-' + this.today.month + '-' + this.today.day,
  
      })
      this.stockcurrentPage = res['page']
      this.stockTotalPage = res['totalPages']
      

    },
      (error) => {
        this.loader = false;
        this.toast.error(error.error['msg']);
        this.loader = false
      }
    );
  }

  // getBundleStock(id) {
  //   this.loader = true
  //   if (this.BundleFilters.value.search_query === null) {
  //     this.BundleFilters.patchValue({
  //       search_query: ""
  //     })
  //   }
  //   if (this.BundleFilters.value.end_date === null) {
  //     this.BundleFilters.patchValue({
  //       end_date: ""
  //     })
  //   }
  //   if (this.BundleFilters.value.start_date === null) {
  //     this.BundleFilters.patchValue({
  //       start_date: ""
  //     })
  //   }
  //   if (this.BundleFilters.value.transaction_type === null) {
  //     this.BundleFilters.patchValue({
  //       transaction_type: ""
  //     })
  //   }
  //   this.http.getinventoryBundleStockData(id, this.BundleFilters.value.start_date, this.BundleFilters.value.end_date, this.BundleFilters.value.transaction_type, this.BundleFilters.value.search_query, this.stockcurrentPage).subscribe(res => {
  //     if (this.stockcurrentPage === 1) {
  //       this.productStockData = res['product_stock_data']
  //       this.BundletStockData=res['bundle_stock_data']
  //     }
  //     if (this.stockcurrentPage > 1) {
  //       for (let item of res['product_stock_data']) {
  //         this.productStockData.push(item);
  //       }
  //       for (let item of res['bundle_stock_data']) {
  //         this.BundletStockData.push(item);
  //       }
  //     }
  //     this.productStock = res;
  //     this.BundleStock = res
  //      console.log(this.BundleStock);
  //     this.stockcurrentPage = res['page']
  //     this.stockTotalPage = res['totalPages']
  //     this.loader = false

  //   },
  //     (error) => {
  //       this.toast.error(error.error['msg']);
  //       this.loader = false
  //     }
  //   );
  // }

  getBundleStock(id) {
    this.loader = true;
    if (this.BundleFilters.value.search_query === null) {
      this.BundleFilters.patchValue({
        search_query: ""
      });
    }
    if (this.BundleFilters.value.end_date === null) {
      this.BundleFilters.patchValue({
        end_date: ""
      });
    }
    if (this.BundleFilters.value.start_date === null) {
      this.BundleFilters.patchValue({
        start_date: ""
      });
    }
    if (this.BundleFilters.value.transaction_type === null) {
      this.BundleFilters.patchValue({
        transaction_type: ""
      });
    }
    this.http.getinventoryBundleStockData(id, this.BundleFilters.value.start_date, this.BundleFilters.value.end_date, this.BundleFilters.value.transaction_type, this.BundleFilters.value.search_query, this.stockcurrentPage).subscribe(res => {
      if (this.stockcurrentPage === 1) {
        this.productStockData = res['product_stock_data'];
        this.BundletStockData=res['bundle_stock_data'];
      }
      if (this.stockcurrentPage > 1) {
        for (let item of res['product_stock_data']) {
          this.productStockData.push(item);
        }
        for (let item of res['bundle_stock_data']) {
          this.BundletStockData.push(item);
        }
      }
      this.productStock = res;
      this.BundleStock = res;
      console.log(this.BundleStock);

      this.stockcurrentPage = res['page'];
      this.stockTotalPage = res['totalPages'];
      this.loader = false;
    },
      (error) => {
        this.toast.error(error.error['msg']);
        this.loader = false;
      }
    );
  }

  
  navigateToCreate(accountName: string, invtype:number) {
    // console.log(`clicked ${accountName}`);
  //  this.dynamicTitle==accountName;
  // // alert("done")
  // // alert(this.auth.userData.emp_info.edit_ledger)
  // // alert(invtype);
    if(this.auth.userData.emp_info.edit_ledger === true){
      this.querySearch = '';
      this.searchList = [];
      this.selectedBuss = [];
      this.searchList = '';
      if (accountName==="Add Customer" || invtype===1 )
      { 
     // // alert(accountName)
        this.dynamicTitle="Add Customer"
        this.account_type="Customer"
        // // alert(`dynamicTitle ${this.dynamicTitle}` );
        this.dynamicContent="Search Connected Customer"
        this.dynamicContentExplain="Add a Customer on Accountect and send them a connection request"
        this.dynamicTitleBody="Add One-way Customer"
        this.dynamicTitleBodyExplain="Add a Customer that is not on Accountect yet."
        this.dynamicBname="Customer Name"
      }
      if(accountName==="Add Vendor" || invtype===2 || invtype===6)
      {
        this.account_type="Vendor"
        this.dynamicTitle="Add Vendor"
        this.dynamicContent="Search Connected Vendors"
        this.dynamicContentExplain="Add a Vendor on Accountect and send them a connection request" 
        this.dynamicTitleBody="Add One-way Vendor"
        this.dynamicTitleBodyExplain="Add a Vendor that is not on Accountect yet."
        this.dynamicBname="Vendor Name"
      }
      this.OpentransLoad = false
      // else 
      // {
      //   this.dynamicTitle="Add Vendor"
      //   // this.dynamicContent="Search Connected Vendors"
      //   // this.dynamicContentExplain="Add One-way Vendors"
      // }
      //// alert(`clicked ${accountName}`);
      // this.ledgerService.getAllBusines();
      this.showLedger = false;
      this.NotshowLedger = true
    
      // if (accountName==="Add Customer")
      // {
      //   this.ledgerService.showLedger = false;
      //   this.ledgerService.showVendor=true;
      // }
      // if (accountName==="Add Vendor")
      // {
      //   this.ledgerService.showLedger = true;
      //   this.ledgerService.showVendor=false;
      // }
     
    }
    else{
      let toast
      this.translate.get('You do not have Permission').subscribe((data: any) => {
        toast = data;
      });
      this.toast.error(toast);
    }

  }
  getnventoryProFilter() {
    this.loader = true
    this.http.getnventoryProFilter(this.invproduct, this.invnprotype, this.proCurrentPage).subscribe(res => {
      // 
      this.invncurrency=res['currency']
      console.log(res);
      if (this.proCurrentPage === 1) {
        this.Invallproduct = res['products_data']
    
      }
      if (this.proCurrentPage > 1) {
        for (let item of res['products_data'])
          this.Invallproduct.push(item);
      }
      this.proCurrentPage = res['page']
      this.productTotalPage = res['totalPages']
      this.InventoryPro = res;
      this.loader = false

    },
      (error) => {
        this.toast.error(error.error['msg']);
        this.loader = false
      }
    );
  }
  getnventoryPro() {
    this.loader = true
    this.http.getnventoryPro(this.invproduct, this.proCurrentPage).subscribe(res => {
      this.invncurrency=res['currency']
      console.log(res);
      if (this.proCurrentPage === 1) {
        this.Invallproduct = res['products_data']
        console.log("all prod", this.Invallproduct)
    
      }
      if (this.proCurrentPage > 1) {
        this.Invallproduct = res['products_data']
      }
      this.proCurrentPage = res['page']
      this.productTotalPage = res['totalPages']
      this.InventoryPro = res;
      this.loader = false
    },
      (error) => {
        this.toast.error(error.error['msg']);
        this.loader = false
      }
    );
  }
  getproDuctAcceptReject(uom ,querey)
  {
    this.loader = true
    //  
    this.uom=uom;
    this.invproduct=querey;
    this.http.getnventoryProAccptReject(this.invproduct, this.proCurrentPage,this.uom,this.bundleStatus).subscribe(res => {
      // 
      this.invncurrency=res['currency']
      console.log(res);
      if (this.proCurrentPage === 1) {
        this.Invallproduct = res['products_data']
        console.log("all prod", this.Invallproduct)
      }
      if (this.proCurrentPage > 1) {
        for (let item of res['products_data'])
          this.Invallproduct.push(item);
      }
      this.proCurrentPage = res['page']
      this.productTotalPage = res['totalPages']
      this.InventoryPro = res;
      this.loader = false
    },
      (error) => {
        this.toast.error(error.error['msg']);
        this.loader = false
      }
    );
  }
  getPurchaseTran() {
    this.loaderSale = true
    if (this.PurchaseFilters.value.search_query === null) {
      this.PurchaseFilters.patchValue({
        search_query: ""
      })
    }
    if (this.PurchaseFilters.value.end_date === null) {
      this.PurchaseFilters.patchValue({
        end_date: ""
      })
    }
    if (this.PurchaseFilters.value.start_date === null) {
      this.PurchaseFilters.patchValue({
        start_date: ""
      })
    }
    if (this.PurchaseFilters.value.transaction_type === null) {
      this.PurchaseFilters.patchValue({
        transaction_type: ""
      })
    }
    this.http.getPurchaseTransaction(this.PurchaseFilters.value.transaction_type, this.PurchaseFilters.value.search_query, this.PurchaseFilters.value.start_date, this.PurchaseFilters.value.end_date, this.purchasecurrentPage).subscribe(res => {
      //


      if (this.purchasecurrentPage === 1) {
        this.purchaseTransData = res['purchase_transaction_data'];
        // console.log(this.purchaseTransData);
      }
      if (this.purchasecurrentPage > 1) {
        this.purchaseTransData = res['purchase_transaction_data'];
      }
      
     
      this.purchasecurrentPage = res['page']
      this.purchaseTotalPage = res['totalPages']
      this.PurchaseTrans = res;
      this.loaderSale = false
    },
      (error) => {
        this.toast.error(error.error['msg']);
        this.loaderSale = false
      }
    );
  }
  // selectFile(event) {
  //   this.fileData = event.target.files;
  //   if (this.fileData.length > 5 || this.previewUrl.length + this.fileData.length > 5) {
  //     this.toast.warning('You can upload upto 5 files');
  //     return;
  //   }
  //   this.uploadFile = true;
  //   // console.log(this.uploadFile);
  //   const formData = new FormData();
  //   for (let item of this.fileData) {
  //     formData.append('fileToUpload', item);
  //   }
  //   this.http.addTransactionFiles(formData).subscribe(res => {
  //     if (res?.plan_expired || res?.limit_exceeded) {
  //       this.sendClickEvent();
  //     }
  //     console.log(res['data'])
  //     this.toast.success(res['detail']);
  //     for (let item of res['data']) {
  //       this.previewUrl.push(item);
  //     }

  //     let array = [];
  //     for (let item of this.previewUrl) {
  //       array.push(item.id)
  //     }
  //     const fileIds = res['data'].map(item => item.id);

  //     // Push file IDs to the file array of baseModel
  //     this.addLedgerForm.attachments.push(...fileIds);
  //     // this.baseModel.patchValue({
  //     //   file: array
  //     // });
  //     this.uploadFile = false;
  //     // console.log(this.baseModel);
  //   }, (error) => {
  //     this.previewUrl = [];
  //     this.uploadFile = false;
  //     this.toast.error(error.error['msg']);
  //   })
  // }

  selectFile(event) { 
    this.imgload = true
    this.fileData = event.target.files;
  
    if (this.fileData.length > 5 || this.previewUrl.length + this.fileData.length > 5) {
      this.toast.warning('You can upload upto 5 files');
      this.imgload = false
      return;
    }
    this.uploadFile = true;
    const formData = new FormData();
    for (let item of this.fileData) {
      formData.append('fileToUpload', item);
    }
    this.http.addTransactionFiles(formData).subscribe(res => {

      if (res?.plan_expired || res?.limit_exceeded) {
        this.sendClickEvent();
      }
 
      this.toast.success(res['detail']);

      for (let item of res['data']) {
        this.previewUrl.push(item);
      }

      let array = [];
      for (let item of this.previewUrl) {
        array.push(item.id)
      }
      this.addLedgerForm.patchValue({
        file: array
      });
      this.imgload = false
      this.uploadFile = false;
    }, (error) => {
      this.previewUrl = [];
      this.uploadFile = false;
      this.toast.error(error.error['msg']);
      this.imgload = false
    })
  }
  SummaryViewCheck(val)
  {
    this.SummaryViewType=val;
  }
  getExpCat()
  {
     this.http.getExpenseCategory("",1).subscribe(res => {
      this.loader = false;
      this.categoryListing = res?.result;
      console.log(this.categoryListing);
    }, (error: any) => {
      this.loader = false;
    })
  }
  getExpenseTran() {
    this.loader = true
    if (this.ExpenseFilters.value.search_query === null) {
      this.ExpenseFilters.patchValue({
        search_query: ""
      })
    }
    if (this.ExpenseFilters.value.end_date === null) {
      this.ExpenseFilters.patchValue({
        end_date: ""
      })
    }
    if (this.ExpenseFilters.value.start_date === null) {
      this.ExpenseFilters.patchValue({
        start_date: ""
      })
    }
    if (this.ExpenseFilters.value.transaction_type === null) {
      this.ExpenseFilters.patchValue({
        transaction_type: ""
      })
    }
    // this.http.getExpenseCategory("",1).subscribe(res => {
    //   this.loader = false;
    //   this.categoryListing = res?.result;
    //   console.log(this.categoryListing);
    // }, (error: any) => {
    //   this.loader = false;
    // })
    this.ExpenseFilters.patchValue({
      transaction_type: "all"
    })
    this.http.getExpenseTransaction(this.ExpenseFilters.value.search_query, this.ExpenseFilters.value.start_date, this.ExpenseFilters.value.end_date, this.expensecurrentPage,this.ExpenseFilters.value.transaction_type,).subscribe(res => {
      // 

      if (this.expensecurrentPage === 1) {
        this.expenseTransData = res['expense_transaction_data'];
        console.log(this.expenseTransData);
      }
      if (this.expensecurrentPage > 1) {
        for (let item of res['expense_transaction_data'])
          this.expenseTransData.push(item);
      }
      ////  console.log("cashDat",this.expenseTransData);
      this.loader = false
      this.expensecurrentPage = res['page']
      this.expenseTotalPage = res['totalPages']


      this.Expense = res;
      this.loader = false
    },
      (error) => {
        this.toast.error(error.error['msg']);
        this.loader = false
      }
    );
  }

  getSaleTran() {
   this.loaderSale=true;
    // this.loader= true;
    if (this.SaleFilters.value.search_query === null) {
      this.SaleFilters.patchValue({
        search_query: ""
      })
    }
    if (this.SaleFilters.value.end_date === null) {
      this.SaleFilters.patchValue({
        end_date: ""
      })
    }
    if (this.SaleFilters.value.start_date === null) {
      this.SaleFilters.patchValue({
        start_date: ""
      })
    }
    if (this.SaleFilters.value.transaction_type === null) {
      this.SaleFilters.patchValue({
        transaction_type: ""
      })
    }
    this.http.getSaleTransaction(this.SaleFilters.value.search_query, this.SaleFilters.value.start_date, this.SaleFilters.value.end_date, this.SaleFilters.value.transaction_type, this.salecurrentPage).subscribe(res => {
      // 

      console.log(this.loader);
      if (this.salecurrentPage === 1) {
        this.saleTransData = res['sale_transaction_data'];
      }
      if (this.salecurrentPage > 1) {
        this.saleTransData = res['sale_transaction_data'];
      }
      this.salecurrentPage = res['page']
      this.saleTotalPage = res['totalPages']
      this.getnventoryPro();
      this.invnCategeory();
      // this.loader= false;
      this.loaderSale=false;
      this.SaleData = res;
    },
      (error) => {
        this.loaderSale = false
        this.toast.error(error.error['msg']);
      }
    );
  }

  
  // getSingleCreditDetails(id)
  // {

  //   this.loader=true;
  //   this.filters={
  //     "credit_card_room_id":id,
  //   }
  //   this.editeCardId=id;
  //   this.http.getSingleCreditTransaction(this.filters).subscribe(res=>{
  //   //  console.log(res);
  //   this.loader=false;
  //    this.selectCreditCard=res['credit_card_transaction_data']
  //    this.singleCardInOutDetails=this.selectCreditCard[0];
  //    this.editeCardTitle=this.singleCardInOutDetails?.credit_card_room_object?.account_title
  //    console.log(this.selectCreditCard);
     
  //   })
  // }
  
  getSingleCreditDetails(id) {
    this.loader = true;
    console.log(this.cardFilters);
    this.filters = {};
      if (this.hasCardFilters()) {
      this.filters = Object.assign({}, this.filters, this.cardFilters.value);
    }
  
    this.filters["credit_card_room_id"] = id;
    this.editeCardId = id;
    
    this.http.getSingleCreditTransaction(this.filters,this.banktransPage).subscribe(res => {
      this.loader = false;
      this.selectCreditCard = res['credit_card_transaction_data'];
      this.banktransPage = res['page']
      this.bankTransTotalPage = res['totalPages']
      this.CardRoom = res['credit_card_room']
      this.CardRoomID = this.CardRoom.id
      this.singleCardInOutDetails = this.selectCreditCard[0];
      this.editeCardTitle = this.CardRoom?.account_title;
      console.log(this.selectCreditCard);
    });
  }
  ReversegetSingleCreditDetails(id) {
    this.loader = true;
    console.log(this.cardFilters);
    this.filters = {};
      if (this.hasCardFilters()) {
      this.filters = Object.assign({}, this.filters, this.cardFilters.value);
    }
    this.filters["credit_card_room_id"] = id;
    this.editeCardId = id;
    this.http.getSingleCreditTransaction(this.filters,this.banktransPage).subscribe(res => {
      this.loader = false;
      this.selectCreditCardR = res['credit_card_transaction_data'];
      this.CardRoom = res['credit_card_room']
      this.CardRoomID = this.CardRoom.id
      this.banktransPage = res['page']
      this.bankTransTotalPage = res['totalPages']
      this.singleCardInOutDetails = this.selectCreditCardR[0];
      this.selectCreditCardR.reverse()
      this.editeCardTitle = this.singleCardInOutDetails?.credit_card_room_object?.account_title;
      console.log(this.selectCreditCard);
    });
  }
  
  // Function to check if cardFilters has any values
  hasCardFilters() {
    const cardFiltersValue = this.cardFilters.value;
    return Object.values(cardFiltersValue).some(value => value !== null && value !== '');
  }


  
  



  addPrefix(numeric,type,pefixText)
  {
    if(this.checkprefixtoggle===false)
    {
      this.prefixText=''
    }
    else
    {
      this.prefixText=pefixText;
    }
     
    this.http.postInvoiceSeries(type, true, true, numeric, this.prefixText).subscribe(res => {
      console.log(res); 
      const detail = res['detail'];
      // Do something with the 'detail' property, for example, display it using ToastrService
      this.toast.success(detail, 'Success');
      this.prefixAplha=this.prefixText +'-' +numeric;
      this.preFixStatus=true;
      
    }, error => {
      // Handle error if needed
      console.error(error);
    });
  }
    

  getCreditTran(id){
    this.crCardId=id;
    this.http.getCreditTransaction(id,this.banktransPage).subscribe(res=>{
      this.selectCreditCardDetail=res['credit_card_transaction_data']
        console.log(this.selectCreditCardDetail);
    })
  }

  // editTransactionAccount()
  // {
  //   this.transLoad=true;
  //   this.http.editTransaction(this.addLedgerForm).subscribe(res=>{
  //     this.selectCreditCardDetail=res['credit_card_transaction_data']
  //       console.log(this.selectCreditCardDetail);
  //       this.transLoad=false;
  //     //console.log(res['credit_card_transaction_data']);
  //   })
  // }

  editTransactionAccount() {
    this.transLoad = true;
    const formData = this.editTransaction.value; // Extract form data
    delete formData.quantity;
    delete formData.unit_price;
    // delete formValue.id;
    // delete formValue.file;
    // delete formValue.transaction_items;
    // delete formValue.quantity;
    // delete formValue.unit_price;
    // delete formValue.amount;
    // delete formValue.ledger_room_id;
    this.http.editTransaction(formData).subscribe(res => {
    //   this.selectCreditCardDetail = res['credit_card_transaction_data'];
    //   console.log(this.selectCreditCardDetail);
      // this.transLoad = false;
    // }, error => {
    //   // Handle error
    //   console.error('Error editing transaction:', error);
    //   this.transLoad = false; // Make sure to reset transLoad flag in case of error
    // });
    
    this.http.getTransaction(this.selectAccount.id,1,this.filters,'').subscribe(data=>{
      console.log(data)
      this.pendingtransactionCount=data['pendingTransactionsCount']
      this.transactions_data_get = data['transactions_data'];
      this.transactions_data = this.transactions_data_get.filter(transaction => transaction.action === 'Received');
     })
    this.transLoad = false;
    if (res?.plan_expired || res?.limit_exceeded) {
      this.sendClickEvent();
    }

    this.toast.success(res['detail']);
    this.transectionCurrency = res['currency_id']
    this.ledgerData = res['data']
    // this.audioService.resetRecordedAudio();
    this.ledgerList('');
    this.salecurrentPage = 1;
    this.getSaleTran();
    this.modalService.dismissAll();
    ////  console.log("dkkdkdkd", this.plusshare);
    if (this.plusshare === false && this.selectedLedger.currency.id === this.auth.userData.emp_info.Business.currency.id) {
      if (this.ledgerData.transaction_sub_type.toString() === '3') {
        this.updateledger = true
        this.addPurchaseBookForm.patchValue({
          purchase_transaction_type: '2',
          amount_balance: this.ledgerData.amount_balance,
          starting_date: this.addLedgerForm.value.starting_date,
          information: this.addLedgerForm.value.information,
          file: this.addLedgerForm.value.file,
          ledger_trans_id: this.ledgerData.id,
          transaction_items:this.LedgertableData
        })
        this.postPurchaseBook()
      }
      if (this.ledgerData.transaction_sub_type.toString() === '5') {
        this.updateledger = true

        this.addPurchaseBookForm.patchValue({
          purchase_transaction_type: '1',
          amount_balance: this.ledgerData.amount_balance,
          starting_date: this.addLedgerForm.value.starting_date,
          information: this.addLedgerForm.value.information,
          file: this.addLedgerForm.value.file,
          ledger_trans_id: this.ledgerData.id,
          transaction_items:this.LedgertableData
        })
        this.postPurchaseBook()
      }
      if (this.ledgerData.transaction_sub_type.toString() === '1') {
        this.updateledger = true
        this.addSaleBookForm.patchValue({
          sale_transaction_type: '1',
          amount_balance: this.ledgerData.amount_balance,
          starting_date: this.addLedgerForm.value.starting_date,
          information: this.addLedgerForm.value.information,
          file: this.addLedgerForm.value.file,
          ledger_trans_id: this.ledgerData.id,
          transaction_items:this.LedgertableData
        })

        ////  console.log(this.addSaleBookForm);

        this.postSaleBook()
      }
      if (this.ledgerData.transaction_sub_type.toString() === '7') {
        this.updateledger = true
        this.addSaleBookForm.patchValue({
          sale_transaction_type: '2',
          amount_balance: this.ledgerData.amount_balance,
          starting_date: this.addLedgerForm.value.starting_date,
          information: this.addLedgerForm.value.information,
          file: this.addLedgerForm.value.file,
          ledger_trans_id: this.ledgerData.id,
          transaction_items:this.LedgertableData

        })

        ////  console.log(this.addSaleBookForm);

        this.postSaleBook()
      }
    }
    if (this.cashshare === true) {
      this.cashcurrentPage = 1
      this.getCashTran()
    }
    if (this.saletran === true) {
      this.salecurrentPage = 1
      this.getSaleTran()
    }
    if (this.bankshare === true) {
      this.banktransPage = 1
      this.getBankTran(this.bankId)
    }
    if (this.exptran === true) {
      this.expensecurrentPage = 1
      this.getExpenseTran()
    }
    if (this.purchtran === true) {
      this.purchasecurrentPage = 1
      this.getPurchaseTran()
    }
    this.getSelectedTransold(Number(this.urlUser));
    this.getnventoryPro(); 
  }, (error) => {
    this.transLoad = false;
    this.toast.error(error.error['msg']);
  })
  }
  
  // getCreditTran(id) {
  //   this.http.getCreditTransaction(id).subscribe(res => {
  //     console.log(res);
  
  //     // Convert the object into an array of objects with keys and values
  //     let cardData=Object.entries(res).map(([key, value]) => ({ key, value }));
  //   //  this.selectCreditCard = Object.entries(res).map(([key, value]) => ({ key, value }));
  //     this.selectCreditCard = cardData[0];
  //     console.log(this.selectCreditCard[0]);
  //   });
  // }
  

  getBankTran(id) {
    this.loader = true;
    if (this.bankFilters.value.search_query === null) {
      this.bankFilters.patchValue({
        search_query: ""
      })
    }
    if (this.bankFilters.value.end_date === null) {
      this.bankFilters.patchValue({
        end_date: ""
      })
    }
    if (this.bankFilters.value.start_date === null) {
      this.bankFilters.patchValue({
        start_date: ""
      })
    }
    if (this.bankFilters.value.transaction_type === null) {
      this.bankFilters.patchValue({
        transaction_type: "all"
      })
    }
    this.http.getBankTransaction(id,this.bankFilters.value.transaction_type, this.bankFilters.value.search_query, this.bankFilters.value.start_date, this.bankFilters.value.end_date, this.banktransPage).subscribe(res => {
      if (this.banktransPage === 1) {
        this.bankTransData = res['bank_transaction_data'];
        console.log(this.bankTransData,'Simpleee');
      }
      if (this.banktransPage > 1) {
        this.bankTransData = res['bank_transaction_data'];
        console.log(this.bankTransData,'Simpleee');
        // for (let item of res['bank_transaction_data'])
        //   this.bankTransData.push(item);
      }
      this.loader = false
      this.banktransPage = res['page']
      this.bankTransTotalPage = res['totalPages']
      this.loader = false
      this.BankTrans = res;
    },
      (error) => {
        this.loader = false
        this.toast.error(error.error['msg']);
      }
    );
  }
  getBankTranReverse(id) {
    this.loader = true;
    if (this.bankFilters.value.search_query === null) {
      this.bankFilters.patchValue({
        search_query: ""
      })
    }
    if (this.bankFilters.value.end_date === null) {
      this.bankFilters.patchValue({
        end_date: ""
      })
    }
    if (this.bankFilters.value.start_date === null) {
      this.bankFilters.patchValue({
        start_date: ""
      })
    }
    if (this.bankFilters.value.transaction_type === null) {
      this.bankFilters.patchValue({
        transaction_type: "all"
      })
    }
    this.http.getBankTransaction(id,this.bankFilters.value.transaction_type, this.bankFilters.value.search_query, this.bankFilters.value.start_date, this.bankFilters.value.end_date, this.banktransPage).subscribe(res => {
      if (this.banktransPage === 1) {
        this.bankTransDataR = res['bank_transaction_data'];
        this.bankID = res['bank_room']
        console.log(this.bankTransDataR,'Reverseee');
      }
      if (this.banktransPage > 1) {
        // for (let item of res['bank_transaction_data'])
        //   this.bankTransDataR.push(item);
        this.bankTransDataR = res['bank_transaction_data'];
        this.bankID = res['bank_room']
        console.log(this.bankTransDataR,'Reverseee');
      }
      this.bankTransDataR.reverse()
      this.loader = false
      this.banktransPage = res['page']
      this.bankTransTotalPage = res['totalPages']
      this.loader = false
      this.BankTrans = res;
    },
      (error) => {
        this.loader = false
        this.toast.error(error.error['msg']);
      }
    );
  }
  datesetter(date) {
    let newDate = date.split("-")
    //  console.log("Date after split", newDate);
    return newDate

  }
  addParticipant(item) {
    this.http.addParticipants(this.selectedTrans.ledger_room_data.id, item).subscribe(res => {
      this.toast.success(res['details']);
      this.modalService.dismissAll();
      this.getSelectedTrans(Number(this.urlUser));
    }, (error) => {
      this.toast.error(error['error']['msg']);
    })
  }
  //old one
  // deleteTransation(id) {
  //   let title, text, confirm, cancel;
  //   this.translate.get('Delete Transaction').subscribe((data: any) => {
  //     title = data;
  //   }),
  //     this.translate.get('Are you sure you want to delete this transaction?').subscribe((data: any) => {
  //       text = data;
  //     }),
  //     this.translate.get('Yes').subscribe((data: any) => {
  //       confirm = data;
  //     }),
  //     this.translate.get('No').subscribe((data: any) => {
  //       cancel = data;
  //     }),
  //     Swal.fire({
  //         imageUrl: '../../../../assets/delete.png',
  //         imageHeight:'50',
  //         title: title,
  //         text: text,
  //         showCancelButton: true,
  //         cancelButtonColor: '#6e7d88',
  //         confirmButtonColor: '#dc3545',
  //         cancelButtonText: cancel,
  //         confirmButtonText: confirm
  //     }).then((result) => {
  //       if (result.value) {
  //         this.http.deleteTransaction(id).subscribe(res => {
  //           this.toast.success(res['details']);

  //           if (this.ledgerView === true) {
  //             this.getSelectedTransold(
  //               Number(this.urlUser),
  //               Number(1)
  //             );
  //           }
  //           if (this.ledgerView === false) {
  //             this.getSelectedTrans(Number(this.urlUser));
  //           }
  //           this.modalService.dismissAll();
  //           this.ledgerList('');
  //         }, (error) => {
  //           this.toast.success(error['error']['msg']);
  //         })
  //       } else {
  //         return;
  //       }
  //     });
  // }

  getProductDetail(id) {
    this.http.getproductServiceDetail(id).subscribe(
      (res: any) => {
        this.selectedProdq = res['market_product_or_service_detail'];
        // this.itemId=this.selectedProdq.id
        console.log(this.selectedProdq);
         this.ledgerService.previewUrl=this.selectedProdq?.product_file;
         console.log(this.ledgerService.previewUrl);
        debugger;
        console.log("Product business id : " , this.selectedProdq.creator_Business.id)
      },
    );
  }
  deleteTransation(id) {
    this.http.deleteTransaction(id).subscribe(res => {
      this.toast.success(res['details']);

      // if (this.ledgerView === true) {
      //   this.getSelectedTransold(
      //     Number(this.urlUser),
      //     Number(1)
      //   );
      // }
      // if (this.ledgerView === false) {
      //   this.getSelectedTrans(Number(this.urlUser));
      // }
      this.modalService.dismissAll();
      this.ledgerList('');
    }, (error) => {
      this.toast.success(error['error']['msg']);
    })
  }
  acceptRejectRooms(roomId, state) {
    this.http.acceptRejectRoom(roomId, state).subscribe(res => {
      this.toast.success(res['detail']);
      this.ledgersRequests();
    }, (error) => {
      this.toast.success(error['error']['msg']);
    })
  }
 acceptRejectRoomsVendor(roomId, state) {
    this.transLoad = true
    this.http.acceptRejectRoomVendor(roomId, state,this.purVend,this.expVend,).subscribe(res => {
      this.toast.success(res['detail']);
      this.transLoad = false;
      this.ledgersRequests();
      this.modalService.dismissAll();
    }, (error) => {
      this.toast.success(error['error']['msg']);
    })
  }
  SetLedgerLimit() {
    this.transLoad = true
    this.ledgerLimit.patchValue({
      ledger_room_id: this.selectedLedger.id
    })
    this.http.setLedgerLimit(this.ledgerLimit.value).subscribe(res => {
      this.toast.success(res['details']);
      this.transLoad = false
      //this.modalService.dismissAll()
      this.getSelectedTransold(this.selectedLedger.id, 1, 'old', this.limitmodal);
      // this.ledgersRequests();
    }, (error) => {
      this.toast.success(error['error']['msg']);
      this.transLoad = false
    })
  }
  Backuphistory() {
    this.http.backuphistory().subscribe(res => {
      this.backUpHistory = res['data']
      // this.ledgersRequests();
    }, (error) => {
      this.toast.success(error['error']['msg']);
      this.transLoad = false
    })
  }
  resendRoomRequest(id) {
    this.http.resendRoomRequest(id).subscribe(res => {
      this.toast.success(res['detail']);
      this.ledgerList('');
    }, (error) => {
      this.toast.success(error['error']['msg']);
    })
  }
  resendTransactionRequest(id) {
    this.http.resendTransRequest(id).subscribe(res => {
      this.toast.success(res['detail']);
      this.getSelectedTrans(Number(this.urlUser));
      this.modalService.dismissAll();
    }, (error) => {
      this.toast.success(error['error']['msg']);
    })
  }
  postLedgerss(id) {
    this.http.createLedger(id).subscribe(data => {
      ////  console.log('this is ledger post', data);
      this.toast.success(data['detail']);
    }, (error) => {
      this.toast.error(error.error['msg']);
    });
  }
  acceptRejectTrans(id, status,patmentType:string='',accountBankId:any=null, accountcard: any=null ) {
    this.accountPaymentMethod=patmentType,
    this.accountBankid= accountBankId,
    this.accountCardid= accountcard,
    this.http.acceptTransaction(id, status,patmentType,accountBankId,accountcard).subscribe(res => {
      this.updateTransaction(this.selectAccount.id);
      this.toast.success(res['detail']);
      this.getSelectedTransold(Number(this.urlUser));
      this.modalService.dismissAll();
    }, (error) => {
      this.toast.error(error['error']['msg']);
    });
  }
// old one
  // selectLedger(modal, item) {
  //   this.loader=true;
  //   console.log("itam", item)
  //   this.selectAccount=item;
  //   this.filters={
  //     verification_status: "pending" 
  //   }
  //   this.selectedOption='sent';
  //   console.log(item);
  //   this.http.getTransaction(item.id,1,this.filters,'').subscribe(data=>{
  //    console.log(data)
  //    this.pendingtransactionCount=data['pendingTransactionsCount']
  //    this.transactions_data_get = data['transactions_data'];
  //    this.transactions_data = this.transactions_data_get.filter(transaction => transaction.action === 'Received');
  //    this.loadMainviewAccount();
  //    this.loader=false;
  //   })
  //   this.limitnew = false
  //   if (this.selectedLedger.id !== item.id) {
  //     this.selectedLedger = item;
  //     if (item.red_dot) {
  //       item.red_dot = false;
  //     }

  //     this.router.navigate(['/ledger/default'], {
  //       queryParams: {
  //         id: item.id,
  //       },
  //     });
  //     this.ledgerFiltersReset();
  //     // if(this.selectedLedger.is_max_limit_exceeded){

  //     //   this.modalService.open(modal, { centered: true, windowClass: 'paymentrimender', size: 'md' });
  //     // } 
  //   }
  // }

  selectLedger(modal, item) {
    this.loader = true;
    console.log("azfafrq", item);
    this.selectAccount = item;
    this.ShipCoutryId= item.with_business.shipping_country.id;
    this.countryId= item.with_business.country.id;
    this.filters = {
      verification_status: "pending" 
    };
    this.selectedOption = 'sent';
    console.log(item);
    console.log(this.loader);
    this.http.getTransaction(item.id, 1, this.filters, '').subscribe({
      next: data => {
        console.log(data);
        console.log(this.loader);
        this.loader = true;
        console.log(this.loader);
        this.pendingtransactionCount = data['pendingTransactionsCount'];
        this.transactions_data_get = data['transactions_data'];
        this.transactions_data = this.transactions_data_get.filter(transaction => transaction.action === 'Received');
        this.loadMainviewAccount();
      },
      complete: () => {
        this.loader = false;
        console.log(this.loader);
      }
    });
    this.limitnew = false;
    if (this.selectedLedger.id !== item.id) {
      this.selectedLedger = item;
      if (item.red_dot) {
        item.red_dot = false;
      }
  
      this.router.navigate(['/ledger/default'], {
        queryParams: {
          id: item.id,
        },
      });
      this.ledgerFiltersReset();
    }
}

  
  getPublicSharelink(check) {
    this.isPublicSelected=check;
    this.http.publicPermission(this.ledgerService.selectedLedger.id,check).subscribe(
      (res) => {
        console.log(res);
        this.shareId= res['unique_id'];
        this.shareLink = res['url'];
      },
      (error) => {
        this.toast.error(error['error']['msg']);
      }
    );
  }
  loadMainviewAccount()
{
    this.MainView = true
    this.PendingTransactionView = false
  }
  updateTransaction(accountId: number) {
    this.http.getTransaction(accountId, 1, this.filters, '').subscribe(data => {
        this.pendingtransactionCount = data['pendingTransactionsCount'];
        this.transactions_data_get = data['transactions_data'];
        this.transactions_data = this.transactions_data_get.filter(transaction => transaction.action === 'Received');
    });
}
  nextPage(event) {
    this.page = event;
    this.loadBusi = true;
    this.http.SearchBusinessLedger(this.searchvalue, this.page).subscribe(data => {
      this.searchList = data;
      this.loadBusi = false;
      // ////  console.log('this is the search query result', data);
    }, (error) => {
      this.loadBusi = false;
    });
  }
  ledgerFiltersReset() {
    this.ledgerFilters.patchValue({
      transaction_type: '',
      debit_amount: '',
      credit_amount: '',
      search_query: '',
      start_date: '',
      end_date: '',
    });
  }
  filterApplyTrans() {
    this.modalService.dismissAll();
    this.getSelectedTransold(Number(this.urlUser), 1);
  }
  getSelectedTrans(id, page: any = 1, order: any = 'new') {
    this.loadTrans = true;
    try {
      let user = this.ledgers.result.find(x => x.id.toString() === this.urlUser.toString());
      if (user) {
        this.selectedLedger = user;
        // ////  console.log("ledger...", this.selectedLedger);

      }
    } catch (e) { }

    this.http.getTransaction(id, page, this.ledgerFilters.value, order).subscribe(res => {
      // // alert("done");
      this.loadTrans = false;
      this.currentpage = Number(res['page']);
      this.totalpage = Number(res['totalPages']);
      if (Number(res['page']) > 1) {
        for (let item of res['transactions_data']) {
          this.selectedTrans.transactions_data.push(item);
        }
        try {
          var objDiv = $("#stransactionsList");
          var h = objDiv.get(0).scrollHeight;
          objDiv.animate({ scrollTop: 900 });



        } catch (e) { }
      } else {
        this.selectedTrans = res;
        try {
          var objDiv = $("#transactionsList");
          var h = objDiv.get(0).scrollHeight;
          objDiv.animate({ scrollTop: 1800 });



        } catch (e) { }
      }
      // setTimeout(() => {
      //   var div = document.getElementById('transactionsList');
      //   $('#transactionsList').animate({
      //     scrollTop: div.scrollHeight - div.clientHeight
      //   }, 300);
      // }, 100);
      this.ledgerList('');
      this.loadTrans = false;
      // ////  console.log('this is the transactions of selected', this.selectedTrans);
    }, (error) => {
      this.loadTrans = false;
      this.selectedTrans = '';
    });
  }
  getSelectedTransold(id, page: any = 1, order: any = 'old', model: any = '') {
    // 
    this.loadTrans=true;
    this.bookloader = true;
    try {
      let user = this.ledgers.result.find(x => x.id.toString() === this.urlUser.toString());
      if (user) {
        this.selectedLedger = user;
      }
    } catch (e) { }
    // // alert(id);
   
    
    this.http.getTransaction(id, page, this.ledgerFilters.value, order).subscribe(res => {
      this.custVendLoadData=res.ledger_room_data;
      this.business_id=res.ledger_room_data.with_business.id;
      ;
      
      this.currentpage = Number(res['page'])
      this.totalpage = Number(res['totalPages'])
      this.ledgerLimitValue = res['ledger_room_data']
      if (Number(res['page']) > 1) {
        this.currentdata = this.selectedTrans.transactions_data
        this.selectedTrans.transactions_data = []
        this.selectedTrans.transactions_data = res['transactions_data']
        this.bookloader = false;
        // for (let item of this.currentdata) {
        //   this.selectedTrans.transactions_data.push(item)
        // }
        try {
          var objDiv = $("#scroll-Table");
          var h = objDiv.get(0).scrollHeight;
          objDiv.animate({ scrollTop: 900 });
          this.loadTrans=false;
        } catch (e) { }
        //  console.log("New Data", res['transactions_data'], this.selectedTrans.transactions_data, res['transactions_data'].length, this.selectedTrans.transactions_data.length);
        // this.selectedTrans.transactions_data = this.nextpagedata.concat(this.selectedTrans.transactions_data)
      } else {
        this.selectedTrans = res;
        this.bookloader = false;
        this.loadTrans=false;
        try {
          var objDiv = $("#scroll-Table");
          var h = objDiv.get(0).scrollHeight;
          objDiv.animate({ scrollTop: 1800 });
          this.loadTrans=false;


        } catch (e) { }

      }
      if (res['ledger_room_data'].is_date_limit_exceeded || res['ledger_room_data'].is_max_limit_exceeded && this.limitnew === false) {
        // // alert("session");
       
        let idArrayget = JSON.parse(sessionStorage.getItem('idArray'))
        console.log(idArrayget);
         
        if (!idArrayget || !idArrayget.includes(id)  )
        {
          this.modalService.open(model, { centered: true, windowClass: 'paymentrimender', size: 'md' });
          let idArray = JSON.parse(sessionStorage.getItem('idArray')) || [];
  
          // Add the new 'id' to the array
          idArray.push(id);
      
          // Store the updated array back in session storage
          sessionStorage.setItem('idArray', JSON.stringify(idArray))
        }
      
        this.loadTrans=false;
      }
    }, (error) => {
      this.loadTrans = false;
      this.selectedTrans = '';
    });
  }

  ledgerList(transectionCurrency) {
    if (!this.ledgers) {
      this.loadLedgers = true;
    }
    this.http.getLedgerList(this.led_ListFilter.value, transectionCurrency).subscribe(data => {
      
      this.auth.showNotifyLedger = false;
      this.loadLedgers = false;
      this.ledgers = data;
      let user = this.ledgers.result.find(x => x.id.toString() === this.urlUser.toString());
      if (user) {
        this.selectedLedger = user;
      }
    }, (error) => {
      this.loadLedgers = false;
      this.toast.error(error.error['msg']);
    });
  }
  removeParticipant(id) {
    //
    this.http.removeParticipant(id, this.selectedLedger.id).subscribe(res => {
      this.toast.success(res['details']);
      this.getSelectedTrans(Number(this.urlUser));
    }, (error) => {
      this.toast.error(error.error['msg'])
    })
  }
  getCurrentPlan() {
    this.http.getPaymentPlans()
      .subscribe((res) => {
        this.days = res['subscription'].days_left

        if (this.days < 6) {
          this.subSnackbar = true
          // this.open()
        }
      });
  }
  ledgersRequests() {
    this.http.getLedgersRequests().subscribe(res => {
      this.ledgersReqs = res;
    }, (error) => {
      this.toast.error(error['error']['msg'])
    })
  }
  cancelReqOneWay(id) {
    this.http.cancelReqOneWayLed(id).subscribe(res => {
      this.toast.success(res['Detail']);
      this.modalService.dismissAll();
      this.getSelectedTrans(Number(this.urlUser));
    }, (error) => {
      this.toast.error(error.error['msg'])
    })
  }
  businessInvitePost(bus_id) {
    // let title, text, confirm, cancel;
    // this.translate.get('Do you want to connect your ledger with this Business?').subscribe((data: any) => {
    //   title = data;
    // }),
    //   this.translate.get('Yes').subscribe((data: any) => {
    //     confirm = data;
    //   }),
    //   this.translate.get('No').subscribe((data: any) => {
    //     cancel = data;
    //   }),
    //   Swal.fire({
    //     title: title,
    //     text: text,
    //     showCancelButton: true,
    //     confirmButtonColor: '#3D8BC9',
    //     cancelButtonColor: '#000000',
    //     cancelButtonText: cancel,
    //     confirmButtonText: confirm
    //   }).then((result) => {
        // if (result.value) {
          this.http.connectBusinessInvite(bus_id, this.selectedLedger.id).subscribe(res => {
            
            if (res?.plan_expired || res?.limit_exceeded) {
              this.sendClickEvent();
            }

            this.toast.success(res['Detail']);
            this.getSelectedTrans(Number(this.urlUser));
          }, (error) => {
            this.toast.error(error.error['msg']);
            this.modalService.dismissAll();
          })
        // }
      // });

  }
  Connect(bus_id) {
    if(this.connectBusId)
      {
        bus_id=this.connectBusId
      }
    // this.connectBusId=bus_id;
    this.http.connectBusinessInvite(bus_id, this.selectedLedger.id).subscribe(res => {        
      if (res?.plan_expired || res?.limit_exceeded) {
        this.sendClickEvent();
      }
      this.toast.success(res['Detail']);
      this.getSelectedTrans(Number(this.urlUser));
    }, (error) => {
      this.toast.error(error.error['msg']);
      this.modalService.dismissAll();
    })
  }
  
  cancelInviteLedger() {
    this.http.cancelInviteOneWay(this.selectedLedger.id).subscribe(res => {
      this.toast.success(res['Detail']);
      this.getSelectedTrans(Number(this.urlUser));
    }, (error) => {
      this.toast.error(error.error['msg']);
      this.modalService.dismissAll();
    })
  }
  businessInviteOneWay() {
    if (this.inviteOneWay.valid) {
      if (this.inviteOneWay.value.type === 'phone') {
        this.inviteOneWay.patchValue({
          invite: this.inviteOneWay.value.invite['e164Number']
        });
      }
    } else {
      return;
    }
    // this.http.sendInviteOneWay(this.inviteOneWay.value).subscribe(res => {
    //   this.toast.success(res['detail']);
    //   this.getSelectedTrans(Number(this.urlUser));
    //   this.modalService.dismissAll();
    // }, (error) => {
    //   this.toast.error(error.error['msg']);
    //   this.modalService.dismissAll();
    // })
  }
  addRemBusiness(item) {
    // if (this.selectedBuss.includes(item.id)) {
    //   this.selectedBuss = this.selectedBuss.filter(x => x !== item.id);
    // } else {
    //   this.selectedBuss.push(item.id);
    // }
  }
  public javascriptSocket() {
    // 
    if (this.build_type === 'ciwac') {
      const token = this.auth.userData.emp_info.user_id
      this.socket = new WebSocket(this.SocketUrl + 'socket/stream/?token=' + token);
      //  console.log('Connected to socket', this.SocketUrl + 'socket/stream/?token=' + token);
    }
    if (this.build_type === 'niwac') {
      const token = JSON.parse(localStorage.getItem('user'))['token'];
      this.socket = new WebSocket(this.SocketUrl + 'socket/stream/?token=' + token);
      //  console.log('Connected to socket', this.SocketUrl + 'socket/stream/?token=' + token);
    }



    if (this.socket.readyState != 1) {
      this.socket.onopen = (event) => {

        // receiver:  it will receive all incoming connection messages on soekcet.
      };
    }
    this.socket.onerror = (event) => {
      ////////  console.log(event);
      //////  console.log('error in socket');
    };
    this.socket.onclose = (event) => {
      //  console.log('Disconnected from socket');
      this.socket = '';
      setTimeout(() => {
        //  console.log('socket isss', this.socket);
        this.javascriptSocket();
      }, this.timer);
      this.timer *= 2;
    };
    this.socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log('this is the event receiving', data);
      
      //
      this.notificationEvent.next(data);
    };
  }
  get sortTransactions() {
    return this.selectedTrans.transactions_data.sort((a, b) => {
      return <any>new Date(b.starting_date) - <any>new Date(a.starting_date);
    });
  }


  toggleTypeOpen(value, type) {
    // this.selectEntry = value;
     
     console.log(type);
     this.cardPaymentType=type;
    if (type === 'cash') {
      this.addCashBookForm.patchValue({
        cash_transaction_type: parseInt(value),
      });
    }
    if(type==='cashin')
    {
      // // alert(value);
      this.addCashBookForm.patchValue({
        cash_transaction_type: value,
      });
    }
    if(type==='cashout')
    {
      // // alert(value);
      this.addCashBookForm.patchValue({
        cash_transaction_type: value,
      });
    }
    if (type === 'sale') {
      this.addSaleBookForm.patchValue({
        sale_transaction_type: value.toString()
      });
    }
    if (type === 'purchase') {
      this.addPurchaseBookForm.patchValue({
        purchase_transaction_type: value.toString()
      });
    }
    if (type === 'bank') {
      this.addBankForm.patchValue({
        bank_transaction_type: value.toString()
      });
    }
    if (type === 'banktran') {
      this.addBankTansForm.patchValue({
        bank_transaction_type: value.toString()
      });
    }
    if (type === 'Card') {
      this.addCreditCard.patchValue({
        credit_card_transaction_type: value.toString()
      });
    }
    if(type==='Charge')
    {
      this.addCreditTansForm.patchValue({
        credit_card_transaction_type: '2',
      });
    }
    if(type==='Payment')
    {
      this.addCreditTansForm.patchValue({
        credit_card_transaction_type: '1',
      });
    }
  }


  abc(modal, type: '', subtype, value) {
    this.shareafter = value
    this.ShareTranType = type
    this.shareSubType = subtype
    this.plusshare = true
    if (value === false) {
      if (this.ShareTranType === 'bank') {
        console.log("bank")
        this.postankTran(modal)
      }
      else if (this.ShareTranType === 'cash') {
        console.log("cash")
        this.postCashBook(modal)
      }
      else if (this.ShareTranType === 'sale') {
        console.log(this.addSaleBookForm.value,"my modal")
        this.postSaleBook(modal)
      }
      else if (this.ShareTranType === 'purchase') {
        this.postPurchaseBook(modal)
      }
      else {
        this.postExpense(modal)
      }

    }
    if (this.shareafter === true) {
      this.modalService.open(modal, {
        centered: true,
        windowClass: 'ledger-debit',
        size: 'lg',
      });
    }


    // ////  console.log("Lenth Bank", this.bankData.length);

  }
  editItemInTable(index: number, updatedItem: any) {
    const subTotal = updatedItem.quantity * updatedItem.unit_price;
    this.tableData[index] = {
      unit_price: updatedItem.unit_price,
      quantity: updatedItem.quantity,
      sub_total: subTotal
    };
  }
  addItemToTable() {
    if (this.isEditLedgerItemTableIndex>-1) {
      const subTotal = this.addSaleBookForm.controls['quantity'].value * this.addSaleBookForm.controls['unit_price'].value //this.selectedItem.quantity * this.selectedItem.unit_price;
      this.tableData[this.isEditLedgerItemTableIndex] ={
        unit_price: this.addSaleBookForm.controls['unit_price'].value,
        quantity: this.addSaleBookForm.controls['quantity'].value,
        sub_total: subTotal
      };
      this.isEditLedgerItemTableIndex = -1;
    } else {
    const subTotal = this.addSaleBookForm.controls['quantity'].value * this.addSaleBookForm.controls['unit_price'].value //this.selectedItem.quantity * this.selectedItem.unit_price;
    this.tableData.push({
      unit_price: this.addSaleBookForm.controls['unit_price'].value,
      quantity: this.addSaleBookForm.controls['quantity'].value,
      sub_total: subTotal
    });
    }
  }
  addItemToTableExpense() {
    if (this.isEditLedgerItemTableIndex>-1) {
      const subTotal = this.addExpenseForm.controls['quantity'].value * this.addExpenseForm.controls['unit_price'].value //this.selectedItem.quantity * this.selectedItem.unit_price;
      this.tableDataEx[this.isEditLedgerItemTableIndex] ={
        unit_price: this.addExpenseForm.controls['unit_price'].value,
        quantity: this.addExpenseForm.controls['quantity'].value,
        sub_total: subTotal
      };
      this.isEditLedgerItemTableIndex = -1;
    } else {
      const subTotal = this.addExpenseForm.controls['quantity'].value * this.addExpenseForm.controls['unit_price'].value //this.selectedItem.quantity * this.selectedItem.unit_price;
    
      this.tableDataEx.push({
        unit_price: this.addExpenseForm.controls['unit_price'].value,
        quantity: this.addExpenseForm.controls['quantity'].value,
        sub_total: subTotal
      });
    }
   
    
    console.log(this.tableDataEx,"mytableeEX")
    
  }
  calculateTotalEx(): number {
    let total = 0;
    for (const item of this.tableDataEx) {
      total += item.sub_total;
    }
    this.addExpenseForm.controls['amount_balance'].setValue(total);
    return total;
  }
  addItemToTableLedger() {
    if (this.isEditLedgerItemTableIndex>-1) {
      const subTotal = this.addLedgerForm.controls['quantity'].value * this.addLedgerForm.controls['unit_price'].value //this.selectedItem.quantity * this.selectedItem.unit_price;
      this.LedgertableData[this.isEditLedgerItemTableIndex] = {
        unit_price: this.addLedgerForm.controls['unit_price'].value,
        quantity: this.addLedgerForm.controls['quantity'].value,
        sub_total: subTotal
      };
      this.isEditLedgerItemTableIndex = -1;
    } else {
      const subTotal = this.addLedgerForm.controls['quantity'].value * this.addLedgerForm.controls['unit_price'].value //this.selectedItem.quantity * this.selectedItem.unit_price;
      this.LedgertableData.push({
        unit_price: this.addLedgerForm.controls['unit_price'].value,
        quantity: this.addLedgerForm.controls['quantity'].value,
        sub_total: subTotal
      });
    }
  }
  calculateTotalledger(): number {
    let totalamount = 0;
    for (const item of this.LedgertableData) {
      totalamount += item.sub_total;
    }
    this.addLedgerForm.controls['amount'].setValue(totalamount);
    return totalamount;
  }
  addItemToTablePurchase() {
    if (this.isEditLedgerItemTableIndex>-1) {
      const subTotal = this.addPurchaseBookForm.controls['quantity'].value * this.addPurchaseBookForm.controls['unit_price'].value //this.selectedItem.quantity * this.selectedItem.unit_price;
      this.PurchasetableData[this.isEditLedgerItemTableIndex] ={
        unit_price: this.addPurchaseBookForm.controls['unit_price'].value,
        quantity: this.addPurchaseBookForm.controls['quantity'].value,
        sub_total: subTotal
      };
      this.isEditLedgerItemTableIndex = -1;
    } else {
      const subTotal = this.addPurchaseBookForm.controls['quantity'].value * this.addPurchaseBookForm.controls['unit_price'].value //this.selectedItem.quantity * this.selectedItem.unit_price;
  
      this.PurchasetableData.push({
        unit_price: this.addPurchaseBookForm.controls['unit_price'].value,
        quantity: this.addPurchaseBookForm.controls['quantity'].value,
        sub_total: subTotal
      });
    }
  
    
    console.log(this.PurchasetableData,"mytablee purchase")
    
  }
  calculateTotalPurchase(): number {
    let totalamount = 0;
    for (const item of this.PurchasetableData) {
      totalamount += item.sub_total;
    }
    this.addPurchaseBookForm.controls['amount_balance'].setValue(totalamount);
    return totalamount;
  }

  calculateTotal(): number {
    let totalamount = 0;
    for (const item of this.tableData) {
      totalamount += item.sub_total;
    }
    this.addSaleBookForm.controls['amount_balance'].setValue(totalamount);
    return totalamount;  
  }
  returnAudio(files:any){
    let find = files.find(x => x.file_type == 'audio');
    return find?.file_path;
  }

  returnImages(files:any){
    let find = files.filter(x => x.file_type !== 'audio');
    return find;

  }
  payWithWeChat() {
    let objBody = {
      "mchid": "1900006XXX",
      "out_trade_no": "1217752501201407033233368318",
      "appid": "wxdace645e0bc2cXXX",
      "description": "Image形象店-深圳腾大-QQ公仔",
      "notify_url": "https://www.weixin.qq.com/wxpay/pay.php",
      "amount": {
        "total": 1,
        "currency": "CNY"
      },
      "payer": {
        "openid": "o4GgauInH_RCEdvrrNGrntXDuXXX"
      }
    }
    this.http.getWeChatSession(objBody).subscribe(res => {
      
    }, (error) => {

    })
  }

  ProductStock(data, modal, type) {
    this.stockType = type;
    this.selectedProductForDuplicate = data;
    this.selectedProductId = data?.id;
    this.getBatchList(data.id);
    data.alreadyAdded = true;
    this.modalService.open(modal, { centered: true, windowClass: 'buss-manual', size: 'lg' });
    this.getProductStock(data.id)
    
  }
  addExistingRef(modal, data) {
    //  console.log("data is,,,,,,", data);
    this.ExistingRef = data
    this.stockinByRef.reset()
    this.stockinByRef.reset()
    this.stockinByRef.patchValue({
      stock_id: this.ExistingRef.stock_id,
      created_at: this.today.year + '-' + this.today.month + '-' + this.today.day,
    })
    this.modalService.open(modal, {
      centered: true,
      windowClass: 'ledger-debit',
      size: 'lg',
    });
  }
  openStockOut(modal, stock, isRefMarket: boolean = false) {
    
    this.stockOut.reset();
    this.selectedProductRef = stock.id;
    this.stockoutProduct = stock;
    if (isRefMarket) {
      this.findProductMarketOriented = this.marketService.selectedProd?.products_and_services.find((item: any) => item?.product_name == this.selectedProductForDuplicate?.product_name);
    } else {
      this.findProductMarketOriented = null;
    }
    
    this.stockOut.patchValue({
      stock_id: stock.stock_id,
      quantity: this.findProductMarketOriented?this.findProductMarketOriented?.quantity: null
      // created_at: this.today.year + '-' + '0' + this.today.month + '-' + this.today.day,
    })
    if (this.today.month < 10 && this.today.day < 10) {
      this.stockOut.patchValue({
        created_at: this.today.year + '-' + '0' + this.today.month + '-' + '0' + this.today.day
      });
    }
    else if (this.today.month < 10 && this.today.day > 9) {
      this.stockOut.patchValue({
        created_at: this.today.year + '-' + '0' + this.today.month + '-' + this.today.day
      });
    }
    else if (this.today.month > 9 && this.today.day < 10) {
      this.stockOut.patchValue({
        created_at: this.today.year + '-' + this.today.month + '-' + '0' + this.today.day
      });
    }
    else {
      this.stockOut.patchValue({
        created_at: this.today.year + '-' + this.today.month + '-' + this.today.day
      });
    }
    this.modalService.open(modal, {
      centered: true,
      windowClass: 'ledger-debit',
      size: 'lg',
    });
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    if (charCode === 48) {
      if (this.min < 1) {
        return false;
      }
    }
    return true;
  }
  postStockInByRef(value: any = '') {
    this.transLoad = true
    if (this.stockinByRef.value.quantity) {
      this.stockinByRef.patchValue(
        {
          quantity: parseInt(this.stockinByRef.value.quantity)
        }
      )
    }
    this.http.addStockInByRef(this.stockinByRef.value).subscribe(data => {
      // this.auth.userData = data.emp_info;
      this.toast.success(data['detail']);
      this.modalService.dismissAll()
      this.transLoad = false
      if (value === 'false') {
        this.getStockProByRef(this.selectedProductId, this.selectedProductRef)
      }
      if (value === 'true') {
        this.stockcurrentPage = 1
        this.getProductStock(this.selectedProductId)
      }

    },
      (error) => {
        this.transLoad = false
        this.toast.error(error.error['msg']);
      }
    );
  }
  postStockIn(modal: any = null) {
    this.transLoad = true
    if (this.stockOut.value.quantity) {
      this.stockOut.patchValue(
        {
          quantity: parseInt(this.stockOut.value.quantity)
        }
      )
    }
    this.http.addStockInByRef(this.stockOut.value).subscribe(data => {
      // this.auth.userData = data.emp_info;
      this.toast.success(data['detail']);
      this.modalService.dismissAll()
      this.transLoad = false;
      if (modal) {
        this.modalService.open(modal, {
          centered: true,
          windowClass: 'ledger-debit',
          size: 'lg',
        });
      }
    },
      (error) => {
        this.transLoad = false
        this.toast.error(error.error['msg']);
      }
    );
  }
  getStockProByRef(proId, Ref) {
    this.selectedref = Ref
    this.stockByRef = true
    this.idByref = proId
    this.http.getstockbyRef(proId, Ref, this.InventoryFilters.value.start_date, this.InventoryFilters.value.end_date, this.InventoryFilters.value.transaction_type, this.InventoryFilters.value.search_query).subscribe(data => {
      if (this.stockByRefcurrentPage === 1) {
        this.StockByRefData = data['product_stock_data']
      }
      if (this.stockByRefcurrentPage > 1) {
        for (let item of data['product_stock_data'])
          this.StockByRefData.push(item);
      }
      this.stockByRefcurrentPage = data['page']
      this.stockByRefTotalPage = data['totalPages']
      this.loader = false
      this.ProDataByRef = data
      //  console.log("Data By Ref", this.ledgerService.ProDataByRef);

      // this.auth.userData = data.emp_info;
    },
      (error) => {
        this.toast.error(error.error['msg']);
      }
    );
  }
  getStockBundleByRef(proId, Ref) {
    this.selectedref = Ref
    this.idByref = proId
    this.ledgerService.selectedref = Ref
    this.ledgerService.stockByRef = true
    this.ledgerService.idByref = proId
    this.http.getstockbyRefBundle( Ref, this.ledgerService.BundleFilters.value.start_date, this.ledgerService.BundleFilters.value.end_date, this.ledgerService.BundleFilters.value.transaction_type, this.ledgerService.BundleFilters.value.search_query).subscribe(data => {
      this.stockbyref = true

      if (this.ledgerService.stockByRefcurrentPage === 1) {
        this.ledgerService.StockByRefData = data['bundle_room_data']
        this.ledgerService.bundleStockDetail= data['bundle_stock_data']
      }
      if (this.ledgerService.stockByRefcurrentPage > 1) {
        for (let item of data['bundle_stock_data'])
          this.ledgerService.ProDataByRef.push(item);
      }
      this.ledgerService.stockByRefcurrentPage = data['page']
      this.ledgerService.stockByRefTotalPage = data['totalPages']
      this.ledgerService.loader = false
      this.ledgerService.ProDataByRef = data
    },
      (error) => {
        this.toast.error(error.error['msg']);
      }
    );
  }
  checkStockOutValue() {
    if (this.stockOut.value.quantity > this.stockoutProduct.stock_left_quantity) {
      this.stockOut.patchValue({
        quantity: this.stockoutProduct.stock_left_quantity
      });
      let toast;
      this.translate.get("Quantity can't be greater then").subscribe((data: any) => {
        toast = data;
      });
      this.toast.warning(toast + this.stockoutProduct.stock_left_quantity);
    }
  }
  postStockOut(modal: any = null) {
    debugger;
    this.loader = true
    if (this.stockOut.value.quantity) {
      this.stockOut.patchValue(
        {
          quantity: parseInt(this.stockOut.value.quantity)
        }
      )
    }
    this.http.postStockOut(this.stockOut.value).subscribe(data => {
      // this.auth.userData = data.emp_info;
      this.loader = false
      this.toast.success(data['detail']);
      this.modalService.dismissAll();
      if (modal) {
        this.modalService.open(modal, {
          centered: true,
          windowClass: 'ledger-debit',
          size: 'lg',
        });
      }
    },
      (error) => {
        this.loader = false
        this.toast.error(error.error['msg']);
      }
    );
  }
  openStockInpro(modal) {
    this.addingReference = true;
    this.http.getuomTypes().subscribe(res => {
      this.uomtype = res['data'];
      this.newreff = true
      if (this.auth.userData.emp_info.employee_permissions.edit_inventory === true) {
        this.addInventory.reset()
        this.modalService.open(modal, {
          centered: true,
          windowClass: 'ledger-debit',
          size: 'lg',
        });
        this.addInventory.patchValue({
          currency: this.auth.userData?.emp_info?.Business?.currency?.id,
          product_name: this.productStock.product_data.product_name,
          product_file: this.productStock.product_data.id,
          product_id: this.selectedProductId,
          uom: this.uomtype[0].id,
          created_at: this.today.year + '-' + this.today.month + '-' + this.today.day,
          service_status: false
        });
        console.log("form value here", this.addInventory.value);
        //  console.log("Inve", this.ledgerService.addInventory.value);
      }
      else {
        let toast;
        this.translate.get('You have no permission').subscribe((data: any) => {
          toast = data;
        });
        this.toast.error(toast)
      }
    },
      (error) => {
        this.toast.error(error.error['msg']);
      }
    );
  }
  openInv(modal, type, id) {
    // { {  } }
    if (this.auth.userData.emp_info.employee_permissions.edit_inventory === true) {
      this.addInventory.reset()
      this.invImg = ''
      this.addInventory.value.product_file = []
      this.addInventory.value.product_name = []
      this.modalService.open(modal, {
        centered: true,
        windowClass: 'ledger-debit',
        size: 'lg',
      });
    }
    else {
      let toast;
      this.translate.get('You have no permission').subscribe((data: any) => {
        toast = data;
      });
      this.toast.error(toast)
    }

  }
  uomsList() {
    this.http.getuomTypes().subscribe(res => {
      this.uomtype = res['data'];
    },
      (error) => {
        this.toast.error(error.error['msg']);
      }
    );
   
  }
  postInv(modal) {
    this.http.getuomTypes().subscribe(res => {
      this.uomtype = res['data'];
      this.newreff = false
    this.addingReference = false;
    this.addInventory.patchValue({
      uom: this.uomtype[0].id,
      product_name: this.addInventory.value.product_name,
      currency: this.auth.userData?.emp_info?.Business?.currency?.id,
      created_at: this.today.year + '-' + this.today.month + '-' + this.today.day,
      service_status: false,
      market_visibility: false,
      // minimum_stock_level:this.addInventory.value.minimum_stock
    });
    if (!this.addInventory?.value?.product_file) {
      this.addInventory.patchValue({
        product_file: [],
      });
     
    }

    this.modalService.open(modal, {
      centered: true,
      windowClass: 'ledger-modal',
      size: 'lg',
    });

    },
      (error) => {
        this.toast.error(error.error['msg']);
      }
    );
   
  }
}
