import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpServiceService } from '../shared/services/http/http-service.service';
import { AuthService } from '../shared/services/auth.service';
import {FooterComponent} from '../shared/components/footer/footer.component'
@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {
  landingPlans: any = [];
  currentPlan: any;
  elementType = 'url';
  qr_value_ad = 'https://play.google.com/store/search?q=ciwac&c=apps';
  qr_value_ios='https://apps.apple.com/lk/app/ciwac/id1581386756';
  constructor(private http: HttpServiceService,
    public authService: AuthService,
    public translate: TranslateService,
    public auth: AuthService) { }

  ngOnInit(): void {
      this.http.get_plans().subscribe((res) => {
        this.landingPlans = res['plans'];
        //  console.log(this.landingPlans);
      });
  }
  navigateAuth(url: any) {
    window.location.replace(`${url}`);
  }
  navigatedownload(url: any) {
    window.location.replace(`${url}`);
  }
  navigateTo(route) {
    window.location.replace(route);
  }
}
