import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-top-header',
  templateUrl: './top-header.component.html',
  styleUrls: ['./top-header.component.scss']
})
export class TopHeaderComponent implements OnInit {
  
  constructor(public auth: AuthService,private router: Router,public modalService: NgbModal) { }

  ngOnInit(): void {

  }
  navigateToBusiness() {
    this.router.navigate(['/user/profile'], { queryParams: { tab: 'network' } });
  }
  openModallogout(modal) {
    this.modalService.open(modal, {
      centered: true,
      windowClass: 'Prompt',
      size: 'md',
    });
  }
}
