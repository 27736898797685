import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appMaximum100]'
})
export class Maximum100Directive {

  constructor(private ref: ElementRef) { }
  @HostListener('keyup', ['$event'])
  onKeyUp(event) {
    if (this.ref.nativeElement.value >= 100 && event.code !== 'Backspace') {
      this.ref.nativeElement.value = JSON.parse(JSON.stringify(Number(100)));
    }

  }
}
