<div class="ngb-modal">   
    <div class="modal-body">
        <div class="modal-header p-0 bb-none">
            <h5 class="modal-title" id="modal-basic-title"><img src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/Cash Icons/Search.svg" class="mx-2 img-25" alt="">Search</h5>
        </div>
        <div class="body">
            <div class="d-flex justify-content-center mx-auto my-2 bg-gray w-70">
                <div class="btn-group filterButtons bg-gray align-items-center w-100" id="myDIV">
                    <button class="btn bcCustom w-50" type="button" (click)="togglesentReceived('All')" [ngClass]="{'selected-button': selectedOption === 'All'}">{{'All' | translate}}</button>
                    <button class="btn bcCustom w-50" type="button" (click)="togglesentReceived('Stockin')" [ngClass]="{'selected-button': selectedOption === 'Stockin'}">{{'Stock in' | translate}}</button>
                    <button class="btn bcCustom w-50" type="button" (click)="togglesentReceived('Stockout')" [ngClass]="{'selected-button': selectedOption === 'Stockout'}">{{'Stock out' | translate}}</button>

                </div>
            </div>
            <div class="d-flex justify-content-center mx-auto w-80">
                <div class="mt-3 d-flex align-items-center w-100">
                    <div class="form-floating w-100">
                      <input class="form-control form-control-sm" id="floatingInput"  [(ngModel)]="filterInvoiceNumber" type="text" placeholder="Search by Invoice Number">
                      <label for="floatingInput" class="float-input-custom text-light"><span>Search (Description, Value)</span></label>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-center mx-auto mt-3 w-80">
                <div class="w-49 mx-auto datepicker py-2">
                    <div class="form-inline custom-datepicker custom-datepicker-dropdown">
                      <div class="form-group m-0">
                        <div class="input-group">
                          <div class="input-group-append rtl" *ngIf="auth.dirWeb === 'rtl'">
                            <button (click)="fvalid.toggle()" class="btn calendar" type="button"><img src="../../../../assets/Accountect/calendar.svg" class="img-18" alt=""></button>
                          </div>
                            <input #fvalid="ngbDatepicker" 
                            class="form-control cursor-pointer border-right-none" 
                            name="dp" 
                            (click)="fvalid.toggle()" 
                            [(ngModel)]="selectedDate" 
                            (ngModelChange)="onDateSelect($event)" 
                            ngbDatepicker 
                            [placeholder]="'From'|translate" 
                            readonly>
                          <div class="input-group-append ltr" *ngIf="auth.dirWeb === 'ltr'">
                            <button (click)="fvalid.toggle()" class="btn calendar border-left-none px-2 pt-1" type="button">
                              <img src="../../../../assets/Accountect/calendar.svg" alt="">
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
  
                <div class="w-49 mx-auto datepicker py-2">
                    <div class="form-inline custom-datepicker custom-datepicker-dropdown">
                      <div class="form-group m-0">
                        <div class="input-group">
                          <div class="input-group-append rtl" *ngIf="auth.dirWeb === 'rtl'">
                            <button (click)="valid.toggle()" class="btn calendar" type="button"><img src="../../../../assets/Accountect/calendar.svg" class="img-18" alt=""></button>
                          </div>
                          <input #valid="ngbDatepicker" class="form-control cursor-pointer border-right-none" name="dp" (click)="valid.toggle()" ngbDatepicker
                            [placeholder]="'To'|translate" readonly>
                          <div class="input-group-append ltr" *ngIf="auth.dirWeb === 'ltr'">
                            <button (click)="valid.toggle()" class="btn calendar px-2 pt-1 border-left-none" type="button">
                              <img src="../../../../assets/Accountect/calendar.svg" alt="">
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                  </div>
            </div>
            <div class="d-flex justify-content-between mx-auto mt-3 w-90">
                <button class="btn bg-white shadow-white text-dark w-25">
                    Reset
                </button>
                <button class="btn bg-invoice w-70">
                    Apply
                </button>
            </div>
        </div>
    </div>
  </div>
