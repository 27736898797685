// import { NgModule } from '@angular/core';
// import { Routes, RouterModule } from '@angular/router';
// import { ContentLayoutComponent } from './shared/components/layout/content-layout/content-layout.component';
// import { FullLayoutComponent } from './shared/components/layout/full-layout/full-layout.component';
// import { PaymentReminderComponent } from './shared/components/payment-reminder/payment-reminder.component';
// import { PolicyComponent } from './shared/components/policy/policy.component';
// import { TermsComponent } from './shared/components/terms/terms.component';
// import { AdminGuard } from './shared/guard/admin.guard';
// import { content } from './shared/routes/content-routes';
// import { full } from './shared/routes/full.routes';
// import { SecureInnerPagesGuard } from './shared/guard/SecureInnerPagesGuard.guard';
// import { LanguageResolverService } from './shared/services/language-resolver.service';
// import { PricingComponent } from './pricing/pricing.component';
// import { AboutusComponent } from './aboutus/aboutus.component';
// import { ContactusComponent } from './contactus/contactus.component';
// import { PaymentSuccessComponent } from './components/user/user-profile/payment-success/payment-success.component';
// import { PaymentErrorComponent } from './components/user/user-profile/payment-error/payment-error.component';
// import { DriveComponent } from './components/drive/drive/drive.component';
// import { DataDeletionPolicyComponent } from './shared/components/data-deletion-policy/data-deletion-policy.component';
// import { LoginWithImageComponent } from './pages/authentication/login/login-with-image.component';
// import { PublicViewComponent } from './components/ledger/public-view/public-view.component';
// // import { SecureInnerPagesGuard } from '../../shared/guard/SecureInnerPagesGuard.guard';

// const routes: Routes = [
//   {
//     path: 'payment-success',
//     component: PaymentSuccessComponent
//   },
//   {
//     path: 'payment-error',
//     component: PaymentErrorComponent
//   },
//   {
//     path: 'public/accounts/room/:roomId',
//     component: PublicViewComponent
//   },
//   {
//     path: '',
//     loadChildren: () => import('../app/pages/authentication/authentication.module').then(m => m.AuthenticationModule),
//     data: { preload: true, delay: false }
//   },
//   {
//     path: '',
//     redirectTo: '',
//     pathMatch: 'full'
//   },
//   {
//     path: '',
//     component: ContentLayoutComponent,
//     canActivate: [AdminGuard],
//     children: content,
//     data: { preload: true, delay: false },
//     resolve: {
//       resolveData: LanguageResolverService,
//     },
//   },
//   {
//     path: '',
//     component: FullLayoutComponent,
//     children: full,
//     data: { preload: false, delay: false },
//   },
//   {
//     path: 'pricing/',
//     component: PricingComponent,
//   },
//   // {
//   //   path: 'privacy/policy',
//   //   component: PolicyComponent,
//   // },
//   // {
//   //   path: 'terms/conditions',
//   //   component: TermsComponent,
//   // },
//   {
//     path: '/contact/us',
//     component: ContactusComponent,
//   },
//   {
//     path: 'contact/us',
//     component: ContactusComponent,
//   },
//   {
//     path: '/about/us',
//     component: AboutusComponent,
//   },
//   {
//     path: 'about/us',
//     component: AboutusComponent,
//   },
//   {
//     path: 'pricing-plans',
//     component: PricingComponent,
//   },
//   {
//     path: 'payment-reminder',
//     component: PaymentReminderComponent,
//   },
//   {
//     path: '/pricing-plans',
//     component: PricingComponent,
//   },
//   {
//     path: '/privacy-policy',
//     component: PolicyComponent,
//   },
//   {
//     path: '/data-deletion-policy',
//     component: DataDeletionPolicyComponent,
//   },
//   {
//     path: '/terms-conditions',
//     component: TermsComponent,
//   },
 
//   { path: '.well-known/assetlinks.json', redirectTo: 'assets/.well-known/assetlinks.json' }

// ];

// @NgModule({
//   imports: [RouterModule.forRoot(routes, {
//      initialNavigation: 'enabled'
// })],
//   exports: [RouterModule]
// })
// export class AppRoutingModule { }




import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContentLayoutComponent } from './shared/components/layout/content-layout/content-layout.component';
import { FullLayoutComponent } from './shared/components/layout/full-layout/full-layout.component';
import { PaymentReminderComponent } from './shared/components/payment-reminder/payment-reminder.component';
import { PolicyComponent } from './shared/components/policy/policy.component';
import { TermsComponent } from './shared/components/terms/terms.component';
import { AdminGuard } from './shared/guard/admin.guard';
import { content } from './shared/routes/content-routes';
import { full } from './shared/routes/full.routes';
import { SecureInnerPagesGuard } from './shared/guard/SecureInnerPagesGuard.guard';
// import { LanguageResolverService } from './shared/services/language-resolver.service';
import { PricingComponent } from './pricing/pricing.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { ContactusComponent } from './contactus/contactus.component';
import { PaymentSuccessComponent } from './components/user/user-profile/payment-success/payment-success.component';
import { PaymentErrorComponent } from './components/user/user-profile/payment-error/payment-error.component';
import { DriveComponent } from './components/drive/drive/drive.component';
import { DataDeletionPolicyComponent } from './shared/components/data-deletion-policy/data-deletion-policy.component';
import { LoginWithImageComponent } from './pages/authentication/login/login-with-image.component';
import { PublicViewComponent } from './components/ledger/public-view/public-view.component';
// import { SecureInnerPagesGuard } from '../../shared/guard/SecureInnerPagesGuard.guard';

const routes: Routes = [
  {
    path: 'payment-success',
    component: PaymentSuccessComponent
  },
  {
    path: 'payment-error',
    component: PaymentErrorComponent
  },
  {
    path: 'public/accounts/room/:roomId',
    component: PublicViewComponent
  },
  {
    path: 'authentication/login',
    component: LoginWithImageComponent
  },
  {
    path: '',
    loadChildren: () => import('../app/pages/authentication/authentication.module').then(m => m.AuthenticationModule),
    data: { preload: true, delay: false }
  },
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full'
  },
  {
    path: '',
    component: ContentLayoutComponent,
    canActivate: [AdminGuard],
    children: content,
    data: { preload: true, delay: false },
    // resolve: {
    //   resolveData: LanguageResolverService,
    // },
  },
  {
    path: '',
    component: FullLayoutComponent,
    children: full,
    data: { preload: false, delay: false },
  },
  // {
  //   path: 'privacy/policy',
  //   component: PolicyComponent,
  // },
  // {
  //   path: 'terms/conditions',
  //   component: TermsComponent,
  // },
  {
    path: 'payment-reminder',
    component: PaymentReminderComponent,
  },
 
  {
    path: 'about/us',
    component: AboutusComponent,
  },
  {
    path: 'contact/us',
    component: ContactusComponent,
  },
  {
    path: 'privacy-policy',
    component: PolicyComponent,
  },
  {
    path: 'data-deletion-policy',
    component: DataDeletionPolicyComponent,
  },
  {
    path: 'terms-conditions',
    component: TermsComponent,
  },
 
  { path: '.well-known/assetlinks.json', redirectTo: 'assets/.well-known/assetlinks.json' },

   { path: '.well-known/apple-app-site-association', redirectTo: '.well-known/apple-app-site-association.json' }
  // {
  //   path: '.well-known/apple-app-site-association',
  //   redirectTo: '.well-known/apple-app-site-association.json',
  //   pathMatch: 'full'
  // },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
     initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }