<div class="d-flex"> 
  <div *ngIf="authService.loginLoader" class="overlay-loader">
    <img src="../../../../assets/Accountect/AC_ayers_2.gif" class="img-50" alt="">
  </div>
  <ng-container  *ngIf="!authService.loginLoader">
    <!-- <div class="bgImage"> </div> -->
    <img class="bgImage" src="../../../../assets/Accountect/Mainpage.png" alt="Background Image" loading="lazy">

    <div class="padddingg">
      <div class="auth-header d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center w-40">
          <!-- <button *ngIf="authService.targetLanguage != 'ur' && authService.targetLanguage != 'ar'" class="btn p-0" (click)="backButton()">
            <i class="fa fa-long-arrow-left text-primary f-24 cursor-pointer"></i>
          </button>
          <img *ngIf="authService?.targetLanguage === 'ur' || authService?.targetLanguage === 'ar'" (click)="backButton()"
                              class="img-30 cursor-pointer" src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/tilt back.svg"> -->
        </div>
        <div class="w-60 cursor-pointer">
          <h4  class="m-0 f-18 mx-2">{{'Log in'|translate}}</h4>

        </div>
        <div class="div-item">
          <!-- <div class="ledge-lang" ngbDropdown placement="bottom-right">
            <h5 id="dropdownBasic1" *ngIf="authService.targetLanguage != 'zh'" ngbDropdownToggle class="text-primary font-weight-bold m-0 cursor-pointer d-flex align-items-center">
              <img class=" mx-2 img-20" src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/updated/globe.svg" alt="">
              {{authService?.targetLanguage.toUpperCase()}}
            </h5>
            <h5 id="dropdownBasic1" *ngIf="authService.targetLanguage === 'zh'" ngbDropdownToggle class="text-primary font-weight-bold m-0 cursor-pointer d-flex align-items-center">
              <img class=" mx-2 img-20" src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/updated/globe.svg" alt="">
              {{'中文'}}
            </h5>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1" >
              <div *ngFor="let lang of authService?.LangDrop">
                <button ngbDropdownItem (click)="authService.changeLanguageLand(lang)">{{lang?.name}}</button>
              </div>

            </div>
          </div> -->
        </div>
      </div>

      <div class="login-wraper position-relative">
        <div class="login-card login-updated px-4">
          <div class="w-80">
            <form class="theme-form up-form" autocomplete="off" [formGroup]="authService?.loginForm">
              <ng-container *ngIf="!authService.QRScreen">
                <div class="w-100 dir-ltr-fix position-relative" *ngIf="authService.tabSelected == 'number' && build_type === 'ciwac'">
                  <ngx-intl-tel-input [enableAutoCountrySelect]="true"   [enablePlaceholder]="true" [searchCountryFlag]="true"
                    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
                    [selectedCountryISO]="authService?.dataIPBased?.country_code" [separateDialCode]="true" name="new_user" required=""
                    formControlName="new_user" class="phoneNgx" [placeholder]="'Phone Number'|translate">
                  </ngx-intl-tel-input>
                  <span (click)="authService.toggle('email')" *ngIf="authService.tabSelected == 'number'" >
                    <i class="fa fa-envelope phoneicon position-absolute text-primary" [ngClass]="{'email-marg-right': authService.dirWeb == 'ltr', 'email-marg-left': authService.dirWeb == 'rtl'}" aria-hidden="true"></i>
                  </span>
                  <p *ngIf="authService?.loginForm.controls.new_user.touched && authService?.loginForm.controls.new_user.errors?.required && authService?.submitted"
                    class="text text-danger px-3 mt-1 mb-0">
                    {{'Phone is required'|translate }}
                  </p>

                </div>
                <div class="w-100 " *ngIf="authService.tabSelected == 'email'">
                  <div class="mb-3 position-relative">
                    <label for="" class="f-14 text-light"><span>{{'Email'|translate}}</span></label>
                    <input type="email" class="form-control form-control-sm" type="email" formControlName="new_user">
                    <!-- <span (click)="authService.toggle('number')" *ngIf="authService.tabSelected == 'email'">
                      <i class="fa fa-mobile position-absolute phoneicon text-primary" [ngClass]="{'email-marg-right': authService.dirWeb == 'ltr', 'email-marg-left': authService.dirWeb == 'rtl'}" aria-hidden="true"></i>
                    </span> -->
                  </div>
                  <p *ngIf="authService?.loginForm.controls.new_user.touched && authService?.loginForm.controls.new_user.errors?.required && authService?.submitted"
                    class="text text-danger px-3 mt-1 mb-0">
                    {{'Email is required'|translate }}
                  </p>
                </div>
                <div class="w-100 mt-3">
                  <div class="position-relative">
                    <div *ngIf="!showPass" class="">
                      <label for="floatingInput2" class="f-14 text-light"><span>{{'Password'|translate}}</span></label>
                      <input class="form-control form-control-sm" id="floatingInput2" type="password" formControlName="password">
                    </div>
                    <div *ngIf="showPass" class="">
                      <label for="floatingInput3" class="f-14 text-light"><span>{{'Password'|translate}}</span></label>
                      <input class="form-control form-control-sm" id="floatingInput3" type="text" formControlName="password">
                    </div>
                    <i *ngIf="!showPass" (click)="show(showPass)"
                      class="fa fa-eye-slash text-light position-absolute pass-eye"></i>
                    <i *ngIf="showPass" (click)="show(showPass)" class="fa fa-eye text-primary position-absolute pass-eye"></i>
                  </div>
                  <!-- <p *ngIf="authService?.loginForm.controls.password.touched && authService?.loginForm.controls.password.errors?.required && authService?.submitted"
                    class="text text-danger px-3 mt-1 mb-0">
                    {{'Password is required'|translate }}
                  </p> -->
                </div>
              </ng-container>
              <!-- <ng-container *ngIf="authService.QRScreen">
                <div class="card">
                  <h5 class="p-2 text-center bg-light">Use on Computer</h5>
                  <ngx-qrcode [elementType]="elementType" [value]="authService?.qrlogin"
                    cssClass="aqrclass w-100 h-100 p-3" errorCorrectionLevel="L">
                  </ngx-qrcode>
                  <div class="px-5">
                    <ol class="px-3">
                      <li>Open <b>Accountect App</b></li>
                      <li>Tap <b>Me</b> and select <b>linked Devices</b></li>
                      <li>Tap on <b>Scan</b></li>
                    </ol>
                  </div>
                </div>
              </ng-container> -->
              
              <div class="w-100 mb-3 mt-1">
                <div class=" d-flex justify-content-end align-items-center">
                  <ng-container class="" *ngIf="!authService.QRScreen">
                    <!-- <ng-container>
                      <div class="d-flex align-items-center">
                        <i class="fa fa-qrcode text-primary" [ngClass]="{'email-marg-right': authService.dirWeb == 'ltr', 'email-marg-left': authService.dirWeb == 'rtl'}" aria-hidden="true"></i>
                        <span *ngIf="build_type === 'ciwac'" (click)="authService?.QRcodee()" class="cursor-pointer">{{'Use QR'|translate}}</span>
                      </div>
                    </ng-container> -->
                    <div class="text-center">
                      <div class="text-center">
                        <label  (click)="forgot()" class="f-14 text-primary font-weight-bold">{{'Forgot Password?'|translate }}</label>
                      </div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="authService.QRScreen">
                    <!-- <ng-container *ngIf="authService.tabSelected == 'email' || authService.QRScreen">
                      <div class="d-flex align-items-center">
                        <i class="fa fa-mobile text-primary" [ngClass]="{'email-marg-right': authService.dirWeb == 'ltr', 'email-marg-left': authService.dirWeb == 'rtl'}" aria-hidden="true"></i>
                        <span *ngIf="build_type === 'ciwac'" (click)="authService.toggle('number')" class="cursor-pointer">{{'Use Phone Number'|translate}}</span>
                      </div>
                    </ng-container> -->
                    <ng-container *ngIf="authService.tabSelected == 'number' || authService.QRScreen">
                      <div class="d-flex align-items-center">
                        <i class="fa fa-envelope text-primary" [ngClass]="{'email-marg-right': authService.dirWeb == 'ltr', 'email-marg-left': authService.dirWeb == 'rtl'}" aria-hidden="true"></i>
                        <span *ngIf="build_type === 'ciwac'" (click)="authService.toggle('email')" class="cursor-pointer">{{'Use Email Address'|translate}}</span>
                      </div>
                    </ng-container>
                  </ng-container>
                  
                </div>
              </div>
              <div class="w-100 mx-0" *ngIf="!authService.QRScreen">
                <button class="btn btn-primary w-100"
                  [disabled]="!authService?.loginForm.valid || authService.showLoader" (click)="authService?.login()" type="submit">
                  <span class="text-white" *ngIf="!authService.showLoader">{{'Log in' | translate }}</span>
                  <div *ngIf="authService.showLoader" class="spinner-border spinner-border-sm text-white" role="status"></div>
                </button>
                <div class="mt-2 text-center">
                  <p class="m-0">
                    <span class="text-dark f-14 px-3">{{'Dont have an account?' | translate}}</span>
                    <b class="text-primary f-14 cursor-pointer text-underline" [routerLink]="['/authentication/register']">{{'Create Free Account' | translate}}</b>
                  </p>
                </div>
              </div>

              <div class="S-date ">
                <hr class="hr-1">
                <div class="text-center text-light f-14 m-0">
                  OR 
                </div>
                <hr class="hr-2">
              </div>
              <div id="buttonDiv"></div>
              <div class="mt-3">
                <div class="login-container d-flex justify-content-center">
                  <div id="g_id_onload"
                      data-client_id="191283059503-0n2eedc1j04kceb4qr93hdio5tfnmh0f.apps.googleusercontent.com"
                      data-callback="handleCredentialResponse"
                      data-auto_prompt="false">
                  </div> 
                </div>
              <div class="mt-3 d-flex justify-content-center">
                <div class="text-center AppleLOgin f-14" (click)="signInWithApple()">
                  <img src="../../../../assets/Accountect/Whiteapple.svg" class="img-20" alt="">
                  <span class="text-white">{{'Sign in with Apple' | translate}}</span>
                </div>
              </div>
              </div>
            </form>
          </div>
          <!-- <div class="row w-100">
            <div class="col-5 d-flex align-items-center">
              <div class="w-100 pl-20">
                <div  *ngIf="build_type === 'ciwac'" class="login-chips my-2"  (click)="signInWithGoogle()">
                  <div class="social-icons">
                    <img src="../../.././../assets/social-icons/google.svg" alt="">
                  </div>
                  <span class="font-weight-600 text-dark-light">{{'Google'|translate}}</span>
                </div>
                <div  *ngIf="build_type === 'ciwac'" class="login-chips my-3" (click)="signInWithFB()">
                  <div class="social-icons mx-1" >
                    <img src="../../.././../assets/social-icons/Facebook.svg" alt="">
                  </div>
                  <span class="font-weight-600 text-dark-light">{{'Facebook'|translate}}</span>
                </div>
                <div  *ngIf="build_type === 'ciwac'" class="login-chips my-3" (click)="linkedInlogin()">
                  <div class="social-icons" >
                    <img src="../../.././../assets/social-icons/Linkedin.svg" alt="">
                  </div>
                  <span class="font-weight-600 text-dark-light">{{'Linkedin'|translate}}</span>
                </div>
                <div *ngIf="build_type === 'ciwac'" class="login-chips my-3" (click)="signInWithApple()">
                  <div class="social-icons" >
                    <img src="../../.././../assets/social-icons/apple.jpeg" class="img-20" alt="">
                  </div>
                  <span class="font-weight-600 text-dark-light">{{'Sign in with Apple'|translate}}</span>
                </div>

              </div>
            </div>
            <div class="or col-1 d-flex justify-content-center position-relative">
              <div class="h-100 brdr"></div>
              <p *ngIf="build_type === 'ciwac'" class="m-0 position-absolute or-text">{{'Or'|translate}}</p>
            </div>
            <div class="col-6">
              <form class="theme-form up-form" autocomplete="off" [formGroup]="authService?.loginForm">
                <ng-container *ngIf="!authService.QRScreen">
                  <div class="w-100 dir-ltr-fix" *ngIf="authService.tabSelected == 'number' && build_type === 'ciwac'">
                    <ngx-intl-tel-input [enableAutoCountrySelect]="true"   [enablePlaceholder]="true" [searchCountryFlag]="true"
                      [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
                      [selectedCountryISO]="authService?.dataIPBased?.country_code" [separateDialCode]="true" name="new_user" required=""
                      formControlName="new_user" class="phoneNgx" [placeholder]="'Phone Number'|translate">
                    </ngx-intl-tel-input>
                    <p *ngIf="authService?.loginForm.controls.new_user.touched && authService?.loginForm.controls.new_user.errors?.required && authService?.submitted"
                      class="text text-danger px-3 mt-1 mb-0">
                      {{'Phone is required'|translate }}
                    </p>

                  </div>
                  <div class="w-100" *ngIf="authService.tabSelected == 'email'">
                    <div class="form-floating mb-3">
                      <input type="email" class="form-control form-control-sm" id="floatingInput" type="email" formControlName="new_user" [placeholder]="'Email'">
                      <label *ngIf="build_type === 'ciwac'"  for="floatingInput" class="float-input-custom text-light"><span>{{'Email'|translate}}</span></label>
                    </div>
                    <p *ngIf="authService?.loginForm.controls.new_user.touched && authService?.loginForm.controls.new_user.errors?.required && authService?.submitted"
                      class="text text-danger px-3 mt-1 mb-0">
                      {{'Email is required'|translate }}
                    </p>
                  </div>
                  <div class="w-100 mt-3">
                    <div class="position-relative">
                      <div *ngIf="!showPass" class="form-floating">
                        <input class="form-control form-control-sm" id="floatingInput2" type="password" formControlName="password" [placeholder]="'password'">
                        <label *ngIf="build_type === 'ciwac'" for="floatingInput2" class="float-input-custom text-light"><span>{{'Password'|translate}}</span></label>
                      </div>
                      <div *ngIf="showPass" class="form-floating">
                        <input class="form-control form-control-sm" id="floatingInput3" type="text" formControlName="password" [placeholder]="'password'">
                        <label for="floatingInput3" class="float-input-custom text-light"><span>{{'Password'|translate}}</span></label>
                      </div>
                      <i *ngIf="!showPass" (click)="show(showPass)"
                        class="fa fa-eye-slash text-light position-absolute pass-eye"></i>
                      <i *ngIf="showPass" (click)="show(showPass)" class="fa fa-eye text-primary position-absolute pass-eye"></i>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="authService.QRScreen">
                  <div class="card">
                    <h5 class="p-2 text-center bg-light">Use on Computer</h5>
                    <ngx-qrcode [elementType]="elementType" [value]="authService?.qrlogin"
                      cssClass="aqrclass w-100 h-100 p-3" errorCorrectionLevel="L">
                    </ngx-qrcode>
                    <div class="px-5">
                      <ol class="px-3">
                        <li>Open <b>Ciwac App</b></li>
                        <li>Tap <b>Me</b> and select <b>linked Devices</b></li>
                        <li>Tap on <b>Scan</b></li>
                      </ol>
                    </div>
                  </div>
                </ng-container>
                <div class="w-100 my-4">
                  <div class=" d-flex justify-content-between align-items-center">
                    <ng-container class="" *ngIf="!authService.QRScreen">
                      <ng-container>
                        <div class="d-flex align-items-center">
                          <i class="fa fa-qrcode text-primary" [ngClass]="{'email-marg-right': authService.dirWeb == 'ltr', 'email-marg-left': authService.dirWeb == 'rtl'}" aria-hidden="true"></i>
                          <span *ngIf="build_type === 'ciwac'" (click)="authService?.QRcodee()" class="cursor-pointer">{{'Use QR'|translate}}</span>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="authService.tabSelected == 'email'">
                        <div class="d-flex align-items-center">
                          <i class="fa fa-mobile text-primary" [ngClass]="{'email-marg-right': authService.dirWeb == 'ltr', 'email-marg-left': authService.dirWeb == 'rtl'}" aria-hidden="true"></i>
                          <span *ngIf="build_type === 'ciwac'" (click)="authService.toggle('number')" class="cursor-pointer">{{'Use Phone Number'|translate}}</span>
                          <span *ngIf="build_type === 'niwac'" (click)="authService.toggle('number')" class="cursor-pointer">{{'使用的电话号码'|translate}}</span>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="authService.tabSelected == 'number'">
                        <div class="d-flex align-items-center">
                          <i class="fa fa-envelope text-primary" [ngClass]="{'email-marg-right': authService.dirWeb == 'ltr', 'email-marg-left': authService.dirWeb == 'rtl'}" aria-hidden="true"></i>
                          <span *ngIf="build_type === 'ciwac'" (click)="authService.toggle('email')" class="cursor-pointer">{{'Use Email Address'|translate}}</span>
                          <span *ngIf="build_type === 'niwac'" (click)="authService.toggle('email')" class="cursor-pointer">{{'使用电子邮件地址'|translate}}</span>
                        </div>
                      </ng-container>
                    </ng-container>

                    <ng-container *ngIf="authService.QRScreen">
                      <ng-container *ngIf="authService.tabSelected == 'email' || authService.QRScreen">
                        <div class="d-flex align-items-center">
                          <i class="fa fa-mobile text-primary" [ngClass]="{'email-marg-right': authService.dirWeb == 'ltr', 'email-marg-left': authService.dirWeb == 'rtl'}" aria-hidden="true"></i>
                          <span *ngIf="build_type === 'ciwac'" (click)="authService.toggle('number')" class="cursor-pointer">{{'Use Phone Number'|translate}}</span>
                          <span *ngIf="build_type === 'niwac'" (click)="authService.toggle('number')" class="cursor-pointer">{{'使用的电话号码'|translate}}</span>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="authService.tabSelected == 'number' || authService.QRScreen">
                        <div class="d-flex align-items-center">
                          <i class="fa fa-envelope text-primary" [ngClass]="{'email-marg-right': authService.dirWeb == 'ltr', 'email-marg-left': authService.dirWeb == 'rtl'}" aria-hidden="true"></i>
                          <span *ngIf="build_type === 'ciwac'" (click)="authService.toggle('email')" class="cursor-pointer">{{'Use Email Address'|translate}}</span>
                          <span *ngIf="build_type === 'niwac'" (click)="authService.toggle('email')" class="cursor-pointer">{{'使用电子邮件地址'|translate}}</span>
                        </div>
                      </ng-container>
                    </ng-container>
                    
                  </div>
                </div>
                <div class="w-100 mx-0" *ngIf="!authService.QRScreen">
                  <button class="btn btn-primary w-100"
                    [disabled]="!authService?.loginForm.valid || authService.showLoader" (click)="authService?.login()" type="submit">
                    <span class="text-white" *ngIf="!authService.showLoader && build_type === 'ciwac'">{{'Log in' | translate }}</span>
                    <span class="text-white" *ngIf="!authService.showLoader && build_type === 'niwac'">{{'登录' | translate }}</span>
                    <div *ngIf="authService.showLoader" class="spinner-border spinner-border-sm text-white" role="status"></div>
                  </button>
                </div>
                <div class="w-100 text-center mt-2">
                  <div class="text-center">
                    <label  *ngIf="build_type === 'ciwac'" (click)="forgot()" class="f-14">{{'Forgot Password?'|translate }}</label>
                  </div>
                </div>
              </form>
            </div>
            <div class="col-12 mt-4 text-center">
                <p class="m-0">
                  <span  *ngIf="build_type === 'ciwac'" class="primary-dark f-14 px-3">{{'Dont have an account?' | translate}}</span>
                  <b  *ngIf="build_type === 'ciwac'" class="text-dark f-14 cursor-pointer text-underline" [routerLink]="['/authentication/register']">{{'Create Free Account' | translate}}</b>
                </p>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </ng-container>
</div>
<ng-template #loginSocials let-modal>
  <div class="social-login-modal">
    <div class="modal-header b-none p-1">
      <div class="w-100">
        <div class="modal-heading py-2">
          <h6 class="my-0 f-17 font-weight-600">{{'Log in With'|translate}}</h6>
        </div>
      </div>
      <div class="header-cross">
        <span aria-hidden="true" (click)="modal.dismiss('Cross click')" class="modal-close-btn cursor-pointer">×</span>
      </div>
    </div>
    <div class="modal-ngb modal-body-social b-none">
      <div class="p-5">
        <div class="">
          <div class="login-chips my-2">
            <div class="social-icons">
              <img src="../../.././../assets/social-icons/google.svg" alt="">
            </div>
            <span class="font-weight-600 text-dark-light">{{'Google'|translate}}</span>
          </div>
          <!-- <div class="login-chips my-3">
            <div class="social-icons mx-1">
              <img src="../../.././../assets/social-icons/Facebook.svg" alt="">
            </div>
            <span class="font-weight-600 text-dark-light">{{'Facebook'|translate}}</span>
          </div> -->
          <div class="login-chips my-3">
            <div class="social-icons">
              <img src="../../.././../assets/social-icons/Linkedin.svg" alt="">
            </div>
            <span class="font-weight-600 text-dark-light">{{'Linkedin'|translate}}</span>
          </div>
          <div id="wechatlogin" class="login-chips my-3">
            <div class="social-icons">
              <img src="../../.././../assets/social-icons/Linkedin.svg" alt="">
            </div>
            <span class="font-weight-600 text-dark-light">{{'We Chat'|translate}}</span>
          </div>
          <div id="login_container">

          </div>
        </div>

        <div class="d-flex align-items-center my-5">
          <div class="email-login w-50 d-flex align-item-center justify-content-center p-2 cursor-pointer">
            <img src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/social-icons/mail.svg" alt="">
            <span class="font-weight-600 mx-2">{{'Email'|translate}}</span>
          </div>
          <div class="phone-login w-50 d-flex align-item-center justify-content-center p-2 cursor-pointer">
            <img src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/social-icons/phone.svg" alt="">
            <span class="font-weight-600 mx-2">{{'Phone'|translate}}</span>
          </div>
        </div>
      </div>
      <div class="footer-login">
        <div class="footer-text">
          <span>{{"Don't have an account?"|translate}} <span class="text-white font-weight-600 cursor-pointer"> {{'Create a Free Account'|translate}}</span></span>
        </div>
        <div class="slope"></div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #loginSocial let-modal>
  <div class="social-login-modal">
    <div class="modal-header b-none p-1">
      <div class="w-100">
        <div class="modal-heading py-2">
          <h6 class="my-0 f-17 font-weight-600">{{'Save Password'|translate}}</h6>
        </div>
      </div>
      <div class="header-cross">
        <span aria-hidden="true" (click)="modal.dismiss('Cross click')" class="modal-close-btn cursor-pointer">×</span>
      </div>
    </div>
    <div class="modal-ngb modal-body-social b-none">
      <div class="p-5">
        <div class="position-relative">
          <div *ngIf="!showPass" class="form-floating">
            <input class="form-control form-control-sm" id="floatingInput2" type="password"
              [placeholder]="'password'">
            <label for="floatingInput2" class="float-input-custom text-light"><span>{{'Password'|translate}}</span></label>
          </div>
          <div *ngIf="showPass" class="form-floating">
            <input class="form-control form-control-sm" id="floatingInput3" type="text"
              [placeholder]="'Password'">
            <label for="floatingInput3" class="float-input-custom text-light"><span>{{'Password'|translate}}</span></label>
          </div>
          <i *ngIf="!showPass" (click)="show(showPass)"
            class="fa fa-eye-slash text-light position-absolute pass-eye"></i>
          <i *ngIf="showPass" (click)="show(showPass)" class="fa fa-eye text-primary position-absolute pass-eye"></i>
        </div>
        <div class="position-relative mt-3">
          <div *ngIf="!showCPass" class="form-floating">
            <input class="form-control form-control-sm" id="floatingInput2" type="password"
               [placeholder]="'Confirm Password'">
            <label for="floatingInput2" class="float-input-custom text-light"><span>{{'Confirm Password'|translate}}</span></label>
          </div>
          <div *ngIf="showCPass" class="form-floating">
            <input class="form-control form-control-sm" id="floatingInput3" type="text"
               [placeholder]="'Confirm Password'">
            <label for="floatingInput3" class="float-input-custom text-light"><span>{{'Confirm Password'|translate}}</span></label>
          </div>
          <i *ngIf="!showCPass" (click)="showc(showCPass)"
            class="fa fa-eye-slash text-light position-absolute pass-eye"></i>
          <i *ngIf="showCPass" (click)="showc(showCPass)"
            class="fa fa-eye text-primary position-absolute pass-eye"></i>
        </div>
        <div class="my-3">
          <button class="btn btn-primary w-100">
            <span class="text-white"><i class="fa fa-check f-22"></i></span>
          </button>
        </div>
        <div class="p-4">
          <img src="" alt="">
        </div>
      </div>
    </div>
  </div>
