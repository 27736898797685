import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';


@Component({
  selector: 'app-full-layout',
  templateUrl: './full-layout.component.html',
  styleUrls: ['./full-layout.component.scss'],
  host : {
    '[style.overflow-anchor]' : "'none'"
}
})
export class FullLayoutComponent implements OnInit {

  constructor(public router: Router) {
  }

  ngOnInit() {
    //  console.log("Full LayOut");
    
  }

}
