import { Injectable } from '@angular/core';
import { HttpServiceService } from './http/http-service.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { BillBookervice } from './billBook.service';
// import { LedgerService } from './ledger.service';

@Injectable({
  providedIn: 'root'
})
export class MarketService {
  processedPages: Set<number> = new Set();
  totalpage:any = 1;
  public baseModel: any;
  public loader: boolean;
  addProduct: FormGroup;
  ledger_currencies: any
  invImg: any
  fileData: any;
  imgload: boolean = false;
  previewUrl: any = [];
  uploadFile: boolean = false;
  transLoad: boolean = false;
  marketlist:any = [];
  connectionlist: any;
  newreff: boolean = false
  pendingOrder: any = [];
  public Reqdiscountform:FormGroup = new FormGroup({});
  counterValue:any;
  cartitem: any;
  public selectedProd: any;
  public loadPdf: boolean = false;
  invproduct: any = ''
  deleteItem: any;
  itemId: any;
  Addedtoledger: any;
  cartcount: any;
  cartquantity: any;
  selectedProdq: any;
  pendingOrderRecord: any;
  listingRecord: any;
  public selectedMarketItem: any;
  selectedInvoice: any;
  businessId: any;
  public billboardSubTotalTypes: any;
  public isEditInvoice: boolean = false;
  reqdiscountpost: any;
  ConnectbusinessId: any;
  selectedBusinessId: any;
  InventoryPro: any;
  marketCartInfo: any = null;
  page: any = 0;
  listingLoader: boolean = false;
  pendingLoader: boolean = false;
  finishLoader: boolean = false;
  DetailPage: boolean;
  counters: any[];
  connectionDetail: any;
  totalCalculation: any;
  currencyy: any;
  loaderDetail: boolean;
  productList: any;
  bundleType: boolean;
  commentData: any;
  filteredMarketList: any;
  vendorRecords: any;
  isMarketComment = false;

  
  constructor(
    private http: HttpServiceService,
    public fb: FormBuilder,
    public toast: ToastrService,
    public translate: TranslateService,
    public modalService: NgbModal,
    // public bookService:BillBookervice,
    // public ledgerService: LedgerService,
    public auth: AuthService,
    public router: Router
  ) {
    this.initializeBaseModel();
    this.getBillBoardTypes();
    this.addProduct = this.fb.group({
      unit_price: ['', Validators.required],
      product_name: ['', Validators.required],
      reference_no: ['', Validators.required],
      quantity: ['', Validators.required],
      currency: ['', Validators.required],
      product_file: [],
      uom: ['', Validators.required],
      information: [''],
      created_at: [''],
      market_visibility:[true],
      selling_price_per_unit: ['',Validators.required],
      service_status:[false],
    });
    this.Reqdiscountform = this.fb.group({
      id:[''],
      requested_discount_percent:[null, [Validators.required]]
    })
   }
   postComments(id,body)
   {
     this.http.postCommentFromMarket(id,body).subscribe(
       (res: any) => {
         console.log(res);
         this.getComments(body);        
       });
   }
   getComments(id)
   {
     this.loader=true;
     this.http.getCommentofMarket(id).subscribe(
       (res: any) => {
         console.log(res);
         this.commentData=res['comments'];
         this.loader=false;
         
       });
   }
   getBillBoardTypes() {
    this.http.getBillBoardTypes().subscribe((res: any) => {
      this.billboardSubTotalTypes = res['billboard_sub_total_types'];
    });
  }


   initializeBaseModel() {
    this.baseModel = {
      id: null,
      with_Business_information: null,
      reference_no: null,
      invoice_date: this.formatDate(new Date()),
      payment_method: null,
      remarks: null,
      currency: null,
      sub_total: null,
      discount_percent: null,
      discount_price: null,
      total_price: null,
      item_and_description: [],
      shipping_price:null,
      shipping_Business_information: {
        full_phone_number: null,
        business_image: null,
        street_address: null,
        email: null,
      },
      tax_details: {
      },
      sub_total_type: 1,
      estimate_time_of_delivery: this.formatDate(new Date()),
      reasons_of_return: null,
      tax_percent: null,
      tax_price: null,
    };
  }
  getPDFInvoice() {
    this.loadPdf = true;
    this.http.getpdfmarket(this.selectedProd?.id, true).subscribe(
      (res: ArrayBuffer) => {
        let blob = new Blob([res], { type: 'application/pdf' });
        var downloadURL = URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = this.selectedProd?.order_id + '.pdf';
        link.click();
        this.loadPdf = false;
        // this.toast.success('PDF download successfully');
      },
      (error) => {
        this.loadPdf = false;
      }
    );
  }
  searchInvPro(event) {
    // this.baseModel.order_id = it
    this.invproduct = event.target.value
    this.getMarketlist(0,'All', 1)
  }
  formatDate(date) {
    if (date) {
      var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;
      return [year, month, day].join('-');
    } else {
      return '';
    }
  }
   CurrenciesList() {
    this.http.ledgerCurrencies().subscribe(res => {
      this.ledger_currencies = res['data'];
      this.addProduct.patchValue({
        currency: this.ledger_currencies[0].id
      })
    }, (error) => {

    });
  }
  
  selectInvFile(event) {
    this.imgload = true
    this.fileData = event.target.files;
    if (this.fileData.length > 5 || this.previewUrl.length + this.fileData.length > 5) {
      this.toast.warning('You can upload upto 5 files');
      this.imgload = false
      return;
    }
    this.uploadFile = true;
    const formData = new FormData();
    for (let item of this.fileData) {
      formData.append('fileToUpload', item);
    }
    this.http.adduploadInvFiles(formData).subscribe(res => {
      // if (res?.plan_expired || res?.limit_exceeded) {
      //   this.sendClickEvent();
      // }
      this.toast.success(res['detail']);
      this.imgload = false
      for (let item of res['data']) {
        this.previewUrl.push(item);
      }

      let array = [];
      for (let item of this.previewUrl) {
        array.push(item.id)
      }
      this.addProduct.patchValue({
        file: array
      });
      this.uploadFile = false;
    }, (error) => {
      this.previewUrl = [];
      this.uploadFile = false;
      this.imgload = false
      this.toast.error(error.error['msg']);
    })






    // this.imgload = true
    // this.fileData = event.target.files;
    // if (this.fileData.length > 5 || this.previewUrl.length + this.fileData.length > 5) {
    //   this.toast.warning('You can upload upto 5 files');
    //   this.imgload = false
    //   return;
    // }
    // this.uploadFile = true;
    // const formData = new FormData();
    // for (let item of this.fileData) {
    //   formData.append('fileToUpload', item);
    // }
    // this.http.adduploadInvFiles(formData).subscribe(res => {
    //   if (res?.plan_expired || res?.limit_exceeded) {
    //     this.sendClickEvent();
    //     this.imgload = false
    //     this.uploadFile = false;
    //     return
    //   }
    //   this.toast.success(res['detail']);
    //   this.invImg = res['data']
    //   this.inventloader = false
    //   for (let item of res['data']) {
    //     this.previewUrl.push(item);
    //   }

    //   let array = [];
    //   for (let item of this.previewUrl) {
    //     array.push(item.id)
    //   }
    //   this.addInventory.patchValue({
    //     product_file: this.invImg.id
    //   });
    //   this.uploadFile = false;
    //   this.imgload = false
    // }, (error) => {
    //   this.previewUrl = [];
    //   this.uploadFile = false;
    //   this.imgload = false
    //   this.toast.error(error.error['msg']);
    // })
  }
  resetShippingBillBook() {
    this.baseModel.shipping_price = null;
  }
  // getMarketlist(id,type, page: any = 1, searchQuery: any = '') {
  //   // debugger;
  //   // if (this.page != page) {
      
  //     if (page == 1) {
  //       this.marketlist = [];
  //     }
  //     this.bundleType=null
  //     this.http.getMarketListingBundles(id,searchQuery,type, page,this.bundleType, null).subscribe(res => {
  //       this.marketCartInfo = res['cart_listing'];
  //       console.log(res);
  //       this.productList = res['market_listing'],
  //       this.filteredMarketList=res['market_listing'],
  //       this.marketlist = this.marketlist.concat(res['market_listing'])
  //         this.marketlist = this.marketlist
  //         ?.map(a => {
  //           return {...a, counterValue: a?.cart_info?.quantity_in_cart}
  //          });
           
  //          this.cartcount=res['total_cart_items']
  //          this.cartquantity=res['cart_listing']
  //          this.listingRecord=res['totalRecords'];
  //          this.page = res['page'];
  //          this.totalpage = res['totalPages'];
  //          this.listingLoader = false;

  //     }, (error: any) => {
  //       this.listingLoader = false;
  //     })
  //   // }
    
  // }

  getMarketlist(id, type, page: any = 1, searchQuery: any = '',visibility: any = '') {
    this.bundleType = null;
    this.listingLoader = true;
  
    if (page === 1) {
      this.marketlist = [];
    }
    
    this.http.getMarketListingBundles(id, searchQuery, type, page, this.bundleType, visibility).subscribe(res => {
      debugger
      this.marketCartInfo = res['cart_listing'];
      console.log(res);
  
      if (page === 1) {
        this.marketlist = res['market_listing'];
      } else {
        this.marketlist = res['market_listing'];
        // this.marketlist = this.marketlist.concat(res['market_listing']);
      }
  
      this.marketlist = this.marketlist.map(a => {
        return { ...a, counterValue: a?.cart_info?.quantity_in_cart };
      });
  
      this.cartcount = res['total_cart_items'];
      this.cartquantity = res['cart_listing'];
      this.listingRecord = res['totalRecords'];
      this.page = res['page'];
      this.totalpage = res['totalPages'];
      this.listingLoader = false;
    }, (error: any) => {
      this.listingLoader = false;
    });
  }
  
  getAcceptRejectmakeinvoice(id,make_invoice){
    this.http.getAcceptRejectandmakeinvoice(id,make_invoice).subscribe(res => {
      if (make_invoice == true) {
        
      }
    })
  }
  getConnection(query) {
    this.http.getmarketconnection(query).subscribe(res => {
      this.connectionlist=res['result'];
      this.vendorRecords=res['totalRecords']
    })
  }
  getpendingorderlist(query,type,othertype, page: any = 1) {
    // if (this.page != page) {
      this.listingLoader = true;
      this.http.getpendingorder(query,type,othertype, page).subscribe(res => {
        if (page === 1) {
          this.pendingOrder = res['pending_and_finished_order_listing']
        } else {
          this.pendingOrder = res['pending_and_finished_order_listing']
        }
        // this.pendingOrder = res['pending_and_finished_order_listing']
        this.pendingOrderRecord=res['totalRecords'];
        this.page = res['page'];
        this.totalpage = res['totalPages'];
        this.listingLoader = false;

      }, (error: any) => {
        this.listingLoader = false;
      })
    // }
  }
  
  changeMarketVisibilityProduct(body, modal) {
    this.http.changeMarketVisibility(body).subscribe(res => {
      this.page = 0;
      modal?modal.dismiss(): null;
      this.getMarketlist(this.auth?.userData?.emp_info?.Business?.id,'All', 1);
      this.toast.success('Visibility Changed');
    }, (error: any) => {
     
    })
  }
  CartDetail(){
    this.http.getcartitems().subscribe(res => {
      this.cartitem=res['cart_list'];
      this.currencyy=res['currency'];
    }
    )
  }
  connectiondetailFunction(id,query,type,page: any = 1){
    this.DetailPage=true;
    this.selectedBusinessId = id;
    this.counters = [];
     this.http.getmarketlisting(id,query,type,page).subscribe(res => {
      this.marketCartInfo = res['cart_listing'];
      if (page === 1) {
        this.connectionDetail = res['market_listing']
      } else {
        this.connectionDetail = res['market_listing']
      }
        // this.connectionDetail = res['market_listing']
        // ?.map(a => { 
        //   return {...a, counterValue: a?.cart_info?.quantity_in_cart}
        //  });
         this.cartcount=res['total_cart_items']
         this.cartquantity=res['cart_listing']
         this.listingRecord=res['totalRecords'] 
         this.page = res['page'];
        this.totalpage = res['totalPages'];
    });
  }
  connectiondetailByBusinessId(id,query,type,page: any = 1){
    this.DetailPage=true;
    this.counters = [];
     this.http.getmarketlisting(id, query,type,page).subscribe(res => {
      if (page === 1) {
        this.connectionDetail = res['market_listing']
      } else {
        this.connectionDetail = res['market_listing']
      }
      this.marketCartInfo = res['cart_listing'];
      // this.connectionDetail = res['market_listing']
      // ?.map(a => { 
      //   return {...a, counterValue: a?.cart_info?.quantity_in_cart}
      //  });
       this.cartcount=res['total_cart_items']
       this.cartquantity=res['cart_listing']
       this.listingRecord=res['totalRecords']
    });
  }
  deletecartitem(id){

    let title, text, cancel , confirm;
      this.translate.get('Do you want to remove from cart?').subscribe((data: any) => {
        title = data;
      }),
        this.translate.get('Cancel').subscribe((data: any) => {
          cancel = data;
        }),
        this.translate.get('Delete').subscribe((data: any) => {
          confirm = data;
        }),
    Swal.fire({
          imageUrl: '../../../../assets/delete.png',
          imageHeight:'50',
          title: title,
          text: text,
          showCancelButton: true,
          cancelButtonColor: '#6e7d88',
          confirmButtonColor: '#dc3545',
          cancelButtonText: cancel,
          confirmButtonText: confirm
    }).then((result) => {
      if (result.value) {
        this.http.deletecartitem(id).subscribe(
          (res: any) => {
            this.loader = false;
            this.toast.success(res?.details);
            this.modalService.dismissAll();
            this.CartDetail();
            this.getMarketlist(0,'All',1);
            this.getMarketlist(this.businessId,'All', 1);
            this.connectiondetailFunction(this.selectedBusinessId,'','All')
          },
          (error) => {
            this.loader = false;
          }
        );
        this.CartDetail();
      }
    });
    


  }

  deleteitemfromlist(id) {
    this.http.deletecartitem(id).subscribe(
      (res: any) => {
        this.loader = false;
        this.toast.success(res?.details);
        this.modalService.dismissAll();
        this.CartDetail();
        this.getMarketlist(0, 'All', 1);
        this.getMarketlist(this.businessId, 'All', 1);
        this.connectiondetailFunction(this.selectedBusinessId, '', 'All');
      },
      (error) => {
        this.loader = false;
      }
    );
    this.CartDetail();
  }
  
  deletecartitemCartScreen(id){

    let title, text, cancel , confirm;
      this.translate.get('Do you want to remove from cart?').subscribe((data: any) => {
        title = data;
      }),
        this.translate.get('Cancel').subscribe((data: any) => {
          cancel = data;
        }),
        this.translate.get('Delete').subscribe((data: any) => {
          confirm = data;
        }),
    Swal.fire({
          imageUrl: '../../../../assets/delete.png',
          imageHeight:'50',
          title: title,
          text: text,
          showCancelButton: true,
          cancelButtonColor: '#6e7d88',
          confirmButtonColor: '#dc3545',
          cancelButtonText: cancel,
          confirmButtonText: confirm
    }).then((result) => {
      if (result.value) {
        this.http.deletecartitem(id).subscribe(
          (res: any) => {
            this.loader = false;
            this.toast.success(res?.details);
            this.modalService.dismissAll();
            this.CartDetail();
          },
          (error) => {
            this.loader = false;
          }
        );
      }
    });
  }
  deletecartitemBusinessScreen(id, businessId: any){
    let title, text, cancel , confirm;
      this.translate.get('Do you want to remove from cart?').subscribe((data: any) => {
        title = data;
      }),
        this.translate.get('Cancel').subscribe((data: any) => {
          cancel = data;
        }),
        this.translate.get('Delete').subscribe((data: any) => {
          confirm = data;
        }),
    Swal.fire({
          imageUrl: '../../../../assets/delete.png',
          imageHeight:'50',
          title: title,
          text: text,
          showCancelButton: true,
          cancelButtonColor: '#6e7d88',
          confirmButtonColor: '#dc3545',
          cancelButtonText: cancel,
          confirmButtonText: confirm
    }).then((result) => {
      if (result.value) {
        this.http.deletecartitem(id).subscribe(
          (res: any) => {
            this.loader = false;
            this.toast.success(res?.details);
            this.modalService.dismissAll();
            this.connectiondetailByBusinessId(businessId,'','All');
            this.page = 0;
            this.getMarketlist(0,'All',1);
          },
          (error) => {
            this.loader = false;
          }
        );
      }
    });
    


  }
  // newEdited(data){
  //   this.http.newEdit(data).subscribe(
  //     (res:any)=>{
  //       this.VISIBILITY = res['data']
  //   });
  // }
  // deletePendingorder(id){
  //   this.http.deletePendingitem(id).subscribe(
  //     (res: any) => {
  //       this.deleteItem = res['detail'];
  //     },
  //   );
  // }
  deletePendingorder(id) {
    let title, text, cancel , confirm;
      this.translate.get('Do you want to delete?').subscribe((data: any) => {
        title = data;
      }),
        this.translate.get('Cancel').subscribe((data: any) => {
          cancel = data;
        }),
        this.translate.get('Delete').subscribe((data: any) => {
          confirm = data;
        }),
    Swal.fire({
          imageUrl: '../../../../assets/delete.png',
          imageHeight:'50',
          title: title,
          text: text,
          showCancelButton: true,
          cancelButtonColor: '#6e7d88',
          confirmButtonColor: '#dc3545',
          cancelButtonText: cancel,
          confirmButtonText: confirm
    }).then((result) => {
      if (result.value) {
        this.http.deletePendingitem(id).subscribe(
          (res: any) => {
            this.loader = false;
            this.toast.success(res?.details);
            this.modalService.dismissAll();
            this.getpendingorderlist('','pending','sent');
          },
          (error) => {
            this.loader = false;
          }
        );
      }
    });
  }
  deleteProduct(id) {
    let title, text, cancel , confirm;
      this.translate.get('Do you want to delete?').subscribe((data: any) => {
        title = data;
      }),
        this.translate.get('Cancel').subscribe((data: any) => {
          cancel = data;
        }),
        this.translate.get('Delete').subscribe((data: any) => {
          confirm = data;
        }),
    Swal.fire({
          imageUrl: '../../../../assets/delete.png',
          imageHeight:'50',
          title: title,
          text: text,
          showCancelButton: true,
          cancelButtonColor: '#6e7d88',
          confirmButtonColor: '#dc3545',
          cancelButtonText: cancel,
          confirmButtonText: confirm
    }).then((result) => {
      if (result.value) {
        this.http.deleteProduct(id).subscribe(
          (res: any) => {
            this.loader = false;
            this.toast.success(res?.details);
            this.modalService.dismissAll();
            
          },
          (error) => {
            this.loader = false;
          }
        );
      }
    });
    this.getMarketlist(this.businessId,'All', 1)
  }
  EmptyCart() {
    let title, cancel, confirm;
    this.translate.get('Do you want to delete?').subscribe((data: any) => {
      title = data;
    });
    this.translate.get('Cancel').subscribe((data: any) => {
      cancel = data;
    });
    this.translate.get('Delete').subscribe((data: any) => {
      confirm = data;
    });

    Swal.fire({
      imageUrl: '../../../../assets/delete.png',
      imageHeight: '50',
      title: title,
      showCancelButton: true,
      cancelButtonColor: '#6e7d88',
      confirmButtonColor: '#dc3545',
      cancelButtonText: cancel,
      confirmButtonText: confirm
    }).then((result) => {
      if (result.value) {
        this.http.EmptyCart().subscribe(
          (res: any) => {
            this.loader = false;
            this.getMarketlist(0, 'All', 1); // Reset cartcount immediately
            this.toast.success(res?.details);
            this.modalService.dismissAll();
            this.router.navigate(['/market/dashboard'], {
              queryParams: {
                tab: 'Listing'
              },
            });
          },
          (error) => {
            this.loader = false;
          }
        );
      }
    });
  }
  
  
  // EditMarketProd() {
  //   this.response = false
  //   ////  console.log('this is the transaction', this.addLedgerForm.value);
  //   this.transLoad = true;

  //     this.http.EditMarketProduct(this..value).subscribe(res => {
  //       this.toast.success(res['details']);
  //       this.transLoad = false;
  //     }, (error) => {
  //       this.transLoad = false;
  //       this.toast.error(error.error['msg']);
  //     })
  // }
  getOrderDetail(id) {
    this.loader=true
    this.http.getPendingorderDetail(id).subscribe(
      (res: any) => {
        this.loader=false
        this.selectedProd = res['pending_and_finished_order_detail'];
        this.selectedMarketItem = this.selectedProd;
        console.log('product detail', this.selectedProd);
        
        this.itemId=this.selectedProd.id
      },
    );
  }
  RequestDiscount() {
    const requestForm = {
      id: this.itemId,
      requested_discount_percent: this.Reqdiscountform.get('requested_discount_percent').value
    };
  
    this.http.ReqDiscount(requestForm).subscribe(
      (res: any) => {
        this.reqdiscountpost = res['detail'];
        this.getOrderDetail(this.itemId)
      }
    );
  }
  getSingleInvoiceBookInfo(itemId) {
    this.loader = true;
    this.http.getSingleInvoiceBookInfo(itemId).subscribe(
      (res: any) => {
        this.selectedInvoice = res['result'];
        this.loader = false;
      },
      (error: any) => {
        this.loader = false;
      }
    );
  }
  // getProductDetail(id) {
  //   this.http.getproductServiceDetail(id).subscribe(
  //     (res: any) => {
  //       this.selectedProdq = res['market_product_or_service_detail'];
  //       // this.itemId=this.selectedProdq.id
  //       console.log(this.selectedProdq);
  //       // this.ledgerService.previewUrl=this.selectedProd?.product_file;
  //       debugger;
  //       console.log("Product business id : " , this.selectedProdq.creator_Business.id)
  //     },
  //   );
  // }
  getProductDetail(id) {
    this.http.getproductServiceDetail(id).subscribe(
      (res: any) => {
        this.selectedProdq = res['market_product_or_service_detail'];
        // this.itemId=this.selectedProdq.id
        console.log(this.selectedProdq);
        console.log("Product business id : " , this.selectedProdq.creator_Business.id)
      },
    );
  }
 AddtoLedgerM(id, modal) {
    this.loaderDetail = true;
    this.http.AddtoLedgerMarket(id).subscribe(
      (res: any) => {
        this.loaderDetail = false;
        this.modalService.open(modal, {
          centered: true,
          windowClass: 'ledger-debit',
          size: 'lg',
        });
        this.Addedtoledger = res[''];
        
      }, (error: any) => {
        this.loaderDetail = false;
      }
    );
  }

  returnCurrency(id: any = 0) {
    try {
      let currency = this.auth?.ledger_currencies?.find((x) => x.id == id);
      return currency?.code ? currency?.code : '';
    } catch (e) {
      return '';
    }
  }
  // addToCart(id){
  //   this.http.addToCart(id,this.counterValue).subscribe(res => {
  //       this.toast.success(res['detail']);
  //   })
  // }
  getnventoryPro() {
    this.loader = true
    this.http.getnventoryPro(this.invproduct, 1).subscribe(res => {
      this.InventoryPro = res;
      this.loader = false

    },
      (error) => {
        this.toast.error(error.error['msg']);
        this.loader = false
      }
    );
  }
}
