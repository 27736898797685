export const environment = {
    production: true,
    dev_socket: "ws://127.0.0.1:8000/",
    apidevUrl:'https://127.0.0.1:8000/api/',
    dev_url: "http://192.168.18.64:8000/api/chat/",
    build_type: 'ciwac',
    // build_type: 'niwac',
    // development
    // prod_url: "https://apidev.hiwac.com/api/chat/",
    // prod_socket: "wss://apidev.hiwac.com/",
    // apiUrl:'https://apidev.hiwac.com/api/',

    // production
    prod_url: "https://apis.accountect.com/chat/",
    // prod_url: "https://api.ciwac.com/chat/",
    prod_socket: "wss://apis.accountect.com/",
    // prod_socket_niwac: "wss://api.niwac.cn/",
    apiUrl:'https://test.apis.accountect.com/api/',
    // apiUrl:'https://api.ciwac.com/api/',
    firebase: {
        apiKey: "AIzaSyD3WCSkSx10T0ssAkDZdjbFZ2j8v0KuJm4",
        authDomain: "hailufcm.firebaseapp.com",
        databaseURL: "https://hailufcm.firebaseio.com",
        projectId: "hailufcm",
        storageBucket: "hailufcm.appspot.com",
        messagingSenderId: "318738681526",
        appId: "1:318738681526:web:cc75c2de79f159a7a66017",
        measurementId: "G-E5JYV4ZK91"
    },

    googleMaps: 'AIzaSyCPoQCNPKT58pRjQfBKGfNiN-ENNqmQQqg',
    linkedInCredentials : {
      clientId: "78vkfxn5lfoskw",
      redirectUrl: "https://web.accountect.com/authentication/linkedin/callback",
      scope: "r_liteprofile%20r_emailaddress" // To read basic user profile data and email
    },

};
