import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterLedger'
})
export class FilterLedgerPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if(!value)return null;
    if(!args)return value;
    args = args.toLowerCase();
    return value.filter(function(item){
        return JSON.stringify(item.with_business.business_name).toLowerCase().includes(args) || JSON.stringify(item.with_business.email).toLowerCase().includes(args)
        || JSON.stringify(item.latest_transaction.amount_balance).toLowerCase().includes(args) || JSON.stringify(item.latest_transaction.currency.code).toLowerCase().includes(args)
        || JSON.stringify(item.with_business.full_phone_number).toLowerCase().includes(args);
    });
}
}
