import { Injectable } from '@angular/core';
import { finalize, tap } from 'rxjs/operators';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpServiceService } from '../http/http-service.service';
import { AuthService } from '../auth.service';

@Injectable()
export class HttpInterceptors implements HttpInterceptor {
  constructor(private http: HttpServiceService, private auth: AuthService) {
  }


  // function which will be called for all http calls
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // how to update the request Parameters
//  //  console.log('Intercepted request: ' + request.url);
    var localToken = localStorage.getItem('user') || null
    if (localToken) {
      // // alert("call")
      this.http.isloading = true;
      localToken = JSON.parse(localToken);
      const token = localToken['token'];
      let updatedRequest: any;
      this.http.isloading = true;
      // tslint:disable-next-line: max-line-length
      if (!(request.url.endsWith('user/signup-post/') || request.url.endsWith('user/verify-phone-otp/')
        || request.url.endsWith('user/new-password/') || request.url.endsWith('user/forget-password-otp/')
        || request.url.endsWith('user/verify-forget-password-otp/') || request.url.endsWith('user/new-password/')
        || request.url.endsWith('user/login/') || request.url.endsWith('user/resend-activation-link/'))) {
          updatedRequest = request.clone({
            setHeaders: {
              Authorization: `JWT ${token}`,
              'Accept-Language': this.auth.targetLanguage
            }
          });

      }
       else {
        updatedRequest = request.clone({
          setHeaders: {
            'Content-Type': 'application/json',
            'Accept-Language': this.auth.targetLanguage,
          }
        });
      }
      // //  console.log(request.url.includes('www.linkedin.com/oauth/v2/accessToken'))


      return next.handle(updatedRequest).pipe(
        tap(
          event => {

            // logging the http response to browser's console in case of a success
            if (event instanceof HttpResponse) {
            }
          },
          error => {
            // logging the http response to browser's console in case of a failuer
            if (event instanceof HttpResponse) {
            }
          }
        ),

        finalize(() => {
          this.http.isloading = false;
        })
      );
    } else {
      if (request.url.includes('www.linkedin.com/oauth/v2/accessToken')){
        updatedRequest = request.clone({
          setHeaders: {
            'Content-Type': 'application/x-www-form-urlencoded',
          }
        });
      }
      else{
        var updatedRequest = request.clone({
          setHeaders: {
            'Content-Type': 'application/json',
            'Accept-Language': this.auth.targetLanguage,
          }
        });
      }

      return next.handle(updatedRequest).pipe(
        tap(
          event => {
            // logging the http response to browser's console in case of a success
            if (event instanceof HttpResponse) {


              // ////  console.log('api call success :', event);
            }
          },
          error => {
            // logging the http response to browser's console in case of a failuer
            if (event instanceof HttpResponse) {


              // ////  console.log('api call error :', event);
            }
          }
        ),

        finalize(() => {
          // ////  console.log('finalize');
        })
      );
    }
    // logging the updated Parameters to browser's console
  }
}
