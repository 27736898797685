// import { environment } from 'src/environments/environment';
// import { Component, ElementRef, Inject, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
// import { FormGroup } from '@angular/forms';
// import { AuthService } from 'src/app/shared/services/auth.service';
// import { Router, ActivatedRoute } from '@angular/router';
// import { HttpServiceService } from './../../../shared/services/http/http-service.service';
// import { isPlatformBrowser, isPlatformServer } from '@angular/common';
// import {
//   SearchCountryField,
//   CountryISO,
// } from 'ngx-intl-tel-input';
// import { ToastrService } from 'ngx-toastr';
// import {
//   SocialAuthService,
//   SocialUser
// } from "angularx-social-login";
// import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";

// import { HttpClient, HttpHeaders } from "@angular/common/http";
// import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// import { Location } from '@angular/common';
// import { TranslateService } from '@ngx-translate/core';
// import { Title } from '@angular/platform-browser';
// declare const google: any;
// declare var $: any;
// @Component({
//   selector: 'app-login-with-image',
//   templateUrl: './login-with-image.component.html',
//   styleUrls: ['./login-with-image.component.scss'],
// })
// export class LoginWithImageComponent implements OnInit {
//   isBottom = false;
//   showLoader = true;
//   model: any = {};
//   wehchat = environment.wechat;
//   elementType = 'url';

//   SearchCountryField = SearchCountryField;
//   CountryISO = CountryISO;
//   preferredCountries: CountryISO[] = [
//     CountryISO.UnitedStates,
//     CountryISO.UnitedKingdom,
//   ];
//   scrollPosition: any;
//   topScroll = 0;
//   public resend: any;
//   public newUser = false;
//   public loginForm: FormGroup;
//   public loginFormEmail: FormGroup;
//   resend_email: any;
//   suggest = false;
//   selectedType = {
//     'key': '',
//     'display': ''
//   };
//   // http://localhost:4200/authentication/linkedinRes
//   // https://ciwac.com/authentication/linkedinRes
//   linkedIn = environment.linkedInCredentials

//   public errorMessage: any;
//   submitted = false;
//   showPass = false;
//   public isBrowser: any;
//   ul: any
//   r = ''
//   a = ''
//   type: any;
//   code: any;
//   referral: any;
//   fromUser: any;
//   apple: any;
//   // social login
//   user: SocialUser;
//   loggedIn: boolean;
//   public build_type = environment.build_type;


//   constructor(
//     private socialAuthService: SocialAuthService,
//     public authService: AuthService,
//     private router: Router,
//     public http: HttpServiceService,
//     public toast: ToastrService,
//     public route: ActivatedRoute,
//     private renderer: Renderer2,
//     private el: ElementRef,
//     public translate: TranslateService,
//     private _location: Location,
//     public modalService: NgbModal,
//     private titleService: Title,
//     @Inject(PLATFORM_ID) private platformId: any) {
//     this.isBrowser = isPlatformBrowser(platformId);

//     this.authService.loginForm.reset()
//     if (isPlatformBrowser(this.platformId)) {
//       $(window).on("scroll", function () {
//         var scrollHeight = $(document).height();
//         this.scrollPosition = $(window).height() + $(window).scrollTop();
//         this.topScroll = this.scrollPosition;
//         if ((scrollHeight - this.scrollPosition) / scrollHeight <= 0) {
//           $('#goBtm').hide();
//           $('#goTop').show();
//         } else {
//           $('#goTop').hide();
//           $('#goBtm').show();
//         }
//       });
//     }

//   }

//   ngOnInit() {
//     debugger;
//     console.log('Rendering Google Sign-In Button');
//     // try {
//     //   google.accounts.id.initialize({
//     //     client_id: '191283059503-0n2eedc1j04kceb4qr93hdio5tfnmh0f.apps.googleusercontent.com',
//     //     callback: (response: any) => this.handleCredentialResponse(response),
//     //     ux_mode: 'popup'
//     //   });

//     //   google.accounts.id.renderButton(
//     //     document.getElementById('buttonDiv'),
//     //     { theme: 'outline', size: 'large' }
//     //   );

//     //   console.log('Google Sign-In Button Rendered');
//     // } catch (error) {
//     //   console.error('Error during Google Sign-In initialization:', error);
//     // };
//     // google.accounts.id.prompt();

//     this.authService.getIPData();
//     const html: HTMLElement = this.el.nativeElement.ownerDocument.documentElement;
//     this.renderer.setAttribute(html, 'lang', this.authService.targetLanguage);

//     this.titleService.setTitle(this.build_type == 'ciwac' ? 'Login | Accountect' : '登录 | 西瓦克');
//     if (this.build_type === 'ciwac') {
//       if (localStorage.getItem('languageLanding')) {
//         let lang = localStorage.getItem('languageLanding');
//         this.authService.translate.use(lang)
//         this.authService.targetLanguage = lang
//         this.authService.LangDir()
//       }
//       else {
//         this.authService.translate.use('en')
//       }
//       this.authService.AllLanguages()
//       // this.router.navigate(['public', 'accounts', 'room', 'M769nA']);
//     }
//     // else{
//     //   let lang = localStorage.getItem('language');
//     //   if(lang === 'en'){
//     //     this.authService.targetLanguage = 'en'
//     //     this.authService.translate.use(lang);
//     //   }
//     //   else{
//     //     this.authService.targetLanguage = 'zh-Hans'
//     //     this.authService.translate.use(lang);
//     //   }
//     //   this.authService.AllLanguages()
//     // }
//     this.route.queryParams.subscribe(params => {

//       this.ul = params['ul'] || 'false';
//       this.r = params['r'] || '';
//       this.a = params['a'] || '';
//       this.code = params['code'] || ''; //for wechat login
//       this.type = params['type'] || '';
//       this.referral = params['referral'] || '';
//       this.fromUser = params['from_username'] || '';
//       this.apple = params['apple'] || ''; //for wechat login
//       if (this.code) {
//         // this.authService.socialLoginWechat(this.code)
//       }
//       else if (this.apple) {
//         this.authService.socialLoginApple(this.apple)
//       }
//     });
//     this.socialAuthService.authState.subscribe((user) => {
//       this.user = user;
//       this.loggedIn = (user != null);
//       //  console.log(this.user);
//     });
//     this.topScroll = 0;
//     // //  console.log('returnurl', this.route.snapshot.queryParams['returnUrl'] || '/')
//     this.submitted = false;
//     // this.route.fragment.subscribe((fragment: string) => {
//     //   this.authService.returnUrl = fragment;
//     //   // //  console.log(fragment);// OUTPUT ?productid=1543&color=red
//     // })
//     this.route.queryParamMap.subscribe(params => {
//       this.authService.returnUrl = params.get('returnUrl') || null;
//     });
//     // this.authService.addSlop();
//     // var r1 = document.getElementById("addSlope");
//     // if (this.router.url.includes('/authentication/login') || this.router.url.includes('forgetpassword') || this.router.url.includes('register')) {
//     //   r1.classList.add('slope');
//     // } else {
//     //   r1.classList.remove('slope');
//     // }
//   }
//   // signInWithGoogle(): void {
//   //   console.log('Triggering Google Sign-In prompt');
//   //   try {
//   //     google.accounts.id.prompt((notification: any) => {
//   //       console.log('Prompt Notification:', notification);
//   //     });
//   //   } catch (error) {
//   //     console.error('Error during Google Sign-In prompt:', error);
//   //   }
//   // }

//   handleCredentialResponse(response: any): void {
//     // alert("call");
//     console.log('Credential Response:', response);

//     const token = response.credential;
//     // this.authService.socialLogin(token, this.ul, this.r, this.a, this.type, this.referral, this.fromUser)
//     //   .subscribe(response => {
//     //     // Handle successful authentication
//     //     console.log('Authentication successful', response);
//     //   }, error => {
//     //     // Handle error
//     //     console.error('Authentication error', error);
//     //   });
//   }
//   QQchatlogin() {
//     // this.authService.signinstatus === true
//     window.location.href = `https://graph.qq.com/oauth2.0/authorize?response_type=code&state=200&client_id=101978803&redirect_uri=https://www.niwac.cn/authentication/register`;
//   }

//   // Resend_Activation_Email() {
//   //   if (this.loginFormEmail.value.username === '') {
//   //     this.tost.error('Please Enter Email');
//   //   } else if (this.loginFormEmail.controls.username.errors) {
//   //     this.tost.error('Please Enter Valid Email');
//   //   } else {
//   //     this.resend_email = this.loginFormEmail.value.username;
//   //     this.http.resend_Verification_Email(this.resend_email).subscribe(res => {
//   //       // ////  console.log(res);
//   //       this.tost.success(res['detail']);
//   //     });
//   //   }
//   // }
//   checkClick() {
//     let stringURL = 'https://www.hiwac.com/view-broadcast/236';
//     let url = stringURL.replace(/(https:|)(^|\/\/)(.*?\/)/g, 'https://www.hiwac.cn/');
//     //  console.log('this is the given url', stringURL);
//     //  console.log('this is the modified url', url);
//     window.open(url);
//   }
//   forgot() {
//     this.router.navigate(['/authentication/forgetpassword']);
//   }
//   openModalLogin(loginModal) {
//     this.modalService.open(loginModal, { centered: true, windowClass: 'social-login', size: 'md' });
//   }
//   show(val) {
//     this.showPass = !val;
//   }

//   scrollBottom(top) {
//     this.topScroll = this.topScroll += top;
//     window.scroll({
//       top: this.topScroll,
//       left: 0,
//       behavior: 'smooth'
//     });
//   }
//   public scrollTop() {
//     this.topScroll = 0;
//     window.scroll({
//       top: 0,
//       left: 0,
//       behavior: 'smooth'
//     });
//   }
//   scrollSection() {
//     var listItem = $("li")
//     listItem.on("click", function () {
//       var scrollingElement = $('#login-wrapper');
//       var targetElement = $(this.dataset.link);

//       scrollingElement.stop();
//       scrollingElement.animate({
//         scrollTop: targetElement.offset().top -
//           scrollingElement.offset().top +
//           scrollingElement.scrollTop() -
//           parseFloat(targetElement.css("margin-top"))
//       }, 200);
//     })
//   }

//   signInWithGoogle(): void {
//     debugger;
//     this.authService.signinstatus === true
//     this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then((user) =>{
//       this.authService.socialLogin(user, this.ul, this.r, this.a, this.type, this.referral, this.fromUser);

//     });
//   }

// signInWithGoogle(): void {
//   this.socialAuthService.initState.subscribe((initialized) => {
//     if (initialized) {
//       this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then((user) => {
//         if (user) {
//           this.authService.socialLogin(user, this.ul, this.r, this.a, this.type, this.referral, this.fromUser);
//         }
//       }).catch((err) => {
//         console.error(err);
//       });
//     } else {
//       console.error('Login providers not initialized');
//     }
//   });
// }

//   // async signInWithGoogle(): Promise<void> {
//   //   this.authService.signinstatus === true;

//   //   try {
//   //     const user = await this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
//   //     this.authService.socialLogin(user, this.ul, this.r, this.a, this.type, this.referral, this.fromUser);
//   //   } catch (error) {
//   //     if (error && error['error'] === 'popup_closed_by_user') {
//   //       console.log('Google sign-in popup closed by user');
//   //     } else {
//   //       console.error('Error during Google sign-in:', error);
//   //     }
//   //   }
//   // }



//   refreshToken(): void {
//     this.socialAuthService.refreshAuthToken(GoogleLoginProvider.PROVIDER_ID);
//   }

//   // signInWithFB(): void {
//   //   this.authService.signinstatus === true
//   //   this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then((user) =>{
//   //     this.authService.socialLogin(user, this.ul, this.r, this.a, this.type, this.referral, this.fromUser);
//   //     // this.user = user;
//   //     //  console.log(this.user);
//   //   });
//   // }

//   socialSignOut(): void {
//     this.socialAuthService.signOut();
//   }
//   linkedInlogin() {
//     this.authService.signinstatus === true
//     window.location.href = `https://www.linkedin.com/uas/oauth2/authorization?response_type=code&client_id=${this.linkedIn.clientId}&redirect_uri=${this.linkedIn.redirectUrl}&scope=${this.linkedIn.scope}`;
//   }

//   wechatlogin() {
//     this.authService.signinstatus === true
//     window.location.href = `https://open.weixin.qq.com/connect/qrconnect?appid=${this.wehchat.appid}&redirect_uri=${this.wehchat.redirect_uri}&response_type=code&scope=${this.wehchat.scope}&state=STATE#wechat_redirect`;
//   }
//   place() {
//     let abc = document.getElementById("country-search-box")['placeholder']
//     let toast
//     this.translate.get(abc).subscribe((data: any) => {
//       toast = data;
//     });
//     abc = toast;
//     document.getElementById("country-search-box")['placeholder'] = abc
//     //  console.log("Placeholder Value", abc);
//   }

//   signInWithApple() {
//     if (this.build_type === 'niwac') {
//       const CLIENT_ID = "com.niwacsocial.bundle.backend"
//       const REDIRECT_API_URL = "https://api.niwac.cn/api/user/social_login_apple/"
//       window.location.href =
//         `https://appleid.apple.com/auth/authorize?client_id=${CLIENT_ID}&redirect_uri=${encodeURIComponent(REDIRECT_API_URL)}&response_type=code id_token&scope=name%20email&response_mode=form_post&nonce=''`
//         ;
//       window.addEventListener('message', async event => {
//         //  console.log(event.data)
//         // // alert(event.data)
//       });
//     }
//     else {
//       const CLIENT_ID = "com.ciwacsocial.bundle.backend"
//       const REDIRECT_API_URL = "https://apis.ciwac.com/api/user/social_login_apple/"
//       window.location.href =
//         `https://appleid.apple.com/auth/authorize?client_id=${CLIENT_ID}&redirect_uri=${encodeURIComponent(REDIRECT_API_URL)}&response_type=code id_token&scope=name%20email&response_mode=form_post&nonce=''`
//         ;
//       window.addEventListener('message', async event => {
//         //  console.log(event.data)
//         // // alert(event.data)
//       });
//     }

//   };


//   backButton() {
//     // this._location.back();
//     window.location.replace('');
//   }
// }

declare global {
  interface Window {
    google: typeof google & {
      accounts: {
        id: {
          initialize: (config: { client_id: string; callback: (response: any) => void; }) => void;
          renderButton: (container: HTMLElement, options: { theme: string; size: string; }) => void;
          prompt: (callback?: (response: any) => void) => void;
        }
      }
    }
  }
}
import { environment } from 'src/environments/environment';
import { Component, ElementRef, Inject, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpServiceService } from './../../../shared/services/http/http-service.service';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import {
  SearchCountryField,
  CountryISO,
} from 'ngx-intl-tel-input';
import { ToastrService } from 'ngx-toastr';
//social
// import { 
//   SocialAuthService,
//    SocialUser } from "angularx-social-login";
// import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';

declare var $: any;
@Component({
  selector: 'app-login-with-image',
  templateUrl: './login-with-image.component.html',
  styleUrls: ['./login-with-image.component.scss'],
})
// @Component({
//   selector: 'app-login-with-image',
//   template: `
//   <div class="login-container">
//     <h2>Login</h2>
//     <div id="g_id_onload"
//          data-client_id="191283059503-0n2eedc1j04kceb4qr93hdio5tfnmh0f.apps.googleusercontent.com"
//          data-callback="handleCredentialResponse"
//          data-auto_prompt="false">
//     </div>
//   </div>
// `,
//   styleUrls: ['./login-with-image.component.scss'],
// })
export class LoginWithImageComponent implements OnInit {
  isBottom = false;
  showLoader = true;
  model: any = {};
  wehchat = environment.wechat;
  elementType = 'url';

  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];
  scrollPosition: any;
  topScroll = 0;
  public resend: any;
  public newUser = false;
  public loginForm: FormGroup;
  public loginFormEmail: FormGroup;
  resend_email: any;
  suggest = false;
  selectedType = {
    'key': '',
    'display': ''
  };
  // http://localhost:4200/authentication/linkedinRes
  // https://ciwac.com/authentication/linkedinRes
  linkedIn = environment.linkedInCredentials

  public errorMessage: any;
  submitted = false;
  showPass = false;
  public isBrowser: any;
  ul: any
  r = ''
  a = ''
  type: any;
  code: any;
  referral: any;
  fromUser: any;
  apple: any;
  //social 
  //  user: SocialUser;
  loggedIn: boolean;
  public build_type = environment.build_type;


  constructor(
    //social
    //  private socialAuthService: SocialAuthService,
    public authService: AuthService,
    private router: Router,
    public http: HttpServiceService,
    public toast: ToastrService,
    public route: ActivatedRoute,
    private renderer: Renderer2,
    private el: ElementRef,
    public translate: TranslateService,
    private _location: Location,
    public modalService: NgbModal,
    private titleService: Title,
    @Inject(PLATFORM_ID) private platformId: any) {
    this.isBrowser = isPlatformBrowser(platformId);

    this.authService.loginForm.reset()
    if (isPlatformBrowser(this.platformId)) {
      $(window).on("scroll", function () {
        var scrollHeight = $(document).height();
        this.scrollPosition = $(window).height() + $(window).scrollTop();
        this.topScroll = this.scrollPosition;
        if ((scrollHeight - this.scrollPosition) / scrollHeight <= 0) {
          $('#goBtm').hide();
          $('#goTop').show();
        } else {
          $('#goTop').hide();
          $('#goBtm').show();
        }
      });
    }

  }

  ngOnInit() {
    // this.authService.getIPData();
    const html: HTMLElement = this.el.nativeElement.ownerDocument.documentElement;
    this.renderer.setAttribute(html, 'lang', this.authService.targetLanguage);

    this.titleService.setTitle('Login | Accountect');
    if (this.build_type === 'ciwac') {
      if (localStorage.getItem('languageLanding')) {
        let lang = localStorage.getItem('languageLanding');
        this.authService.translate.use(lang)
        this.authService.targetLanguage = lang
        this.authService.LangDir()
      }
      else {
        this.authService.translate.use('en')
      }
      // this.authService.AllLanguages()
      // this.router.navigate(['public', 'accounts', 'room', 'M769nA']);
    }
    this.loadGoogleSignInScript();
    // else{
    //   let lang = localStorage.getItem('language');
    //   if(lang === 'en'){
    //     this.authService.targetLanguage = 'en'
    //     this.authService.translate.use(lang);
    //   }
    //   else{
    //     this.authService.targetLanguage = 'zh-Hans'
    //     this.authService.translate.use(lang);
    //   }
    //   this.authService.AllLanguages()
    // }

    this.route.queryParams.subscribe(params => {

      this.ul = params['ul'] || 'false';
      this.r = params['r'] || '';
      this.a = params['a'] || '';
      this.code = params['code'] || ''; //for wechat login
      this.type = params['type'] || '';
      this.referral = params['referral'] || '';
      this.fromUser = params['from_username'] || '';
      this.apple = params['apple'] || ''; //for wechat login
      if (this.code) {
        // this.authService.socialLoginWechat(this.code)
      }
      else if (this.apple) {
        this.authService.socialLoginApple(this.apple)
      }
    });
    //social
    // this.socialAuthService.authState.subscribe((user) => {
    //   alert("login")
    //   this.user = user;
    //   this.loggedIn = (user != null);
    //   //  console.log(this.user);
    // });
    this.topScroll = 0;
    // //  console.log('returnurl', this.route.snapshot.queryParams['returnUrl'] || '/')
    this.submitted = false;
    // this.route.fragment.subscribe((fragment: string) => {
    //   this.authService.returnUrl = fragment;
    //   // //  console.log(fragment);// OUTPUT ?productid=1543&color=red
    // })
    this.route.queryParamMap.subscribe(params => {
      this.authService.returnUrl = params.get('returnUrl') || null;
    });
    // this.authService.addSlop();
    // var r1 = document.getElementById("addSlope");
    // if (this.router.url.includes('/authentication/login') || this.router.url.includes('forgetpassword') || this.router.url.includes('register')) {
    //   r1.classList.add('slope');
    // } else {
    //   r1.classList.remove('slope');
    // }
    this.authService.loginLoader=false;
  }


  loadGoogleSignInScript() {
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.onload = () => {
      this.initializeGoogleSignIn();
    };
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
   
  }
  initializeGoogleSignIn() {
    window['handleCredentialResponse'] = this.handleCredentialResponse.bind(this);
    window['google'].accounts.id.initialize({
      client_id: '191283059503-0n2eedc1j04kceb4qr93hdio5tfnmh0f.apps.googleusercontent.com',
      callback: window['handleCredentialResponse']
    });
    window['google'].accounts.id.renderButton(
      document.getElementById('g_id_onload'),
      { theme: "outline", size: "large" }  // Customize button appearance
    );
  }

  onSignIn(googleUser) {
    var profile = googleUser.getBasicProfile();
    console.log('ID: ' + profile.getId());
    console.log('Name: ' + profile.getName());
    console.log('Image URL: ' + profile.getImageUrl());
    console.log('Email: ' + profile.getEmail());
  }
  //  signInWithGoogle (): void {
  //     this.authService.signinstatus === true;
  //     this.socialAuthService
  //       .signIn(GoogleLoginProvider.PROVIDER_ID)
  //       .then((user) => {
  //         this.authService.socialLogin(
  //           user,
  //           this.ul,
  //           this.r,
  //           this.a,
  //           this.type,
  //           this.referral,
  //           this.fromUser
  //         );
  //         // this.user = user;
  //         // //  console.log(this.user);
  //       });
  //   }
  QQchatlogin() {
    this.authService.signinstatus === true
    window.location.href = `https://graph.qq.com/oauth2.0/authorize?response_type=code&state=200&client_id=101978803&redirect_uri=https://www.niwac.cn/authentication/register`;
  }

  // Resend_Activation_Email() {
  //   if (this.loginFormEmail.value.username === '') {
  //     this.tost.error('Please Enter Email');
  //   } else if (this.loginFormEmail.controls.username.errors) {
  //     this.tost.error('Please Enter Valid Email');
  //   } else {
  //     this.resend_email = this.loginFormEmail.value.username;
  //     this.http.resend_Verification_Email(this.resend_email).subscribe(res => {
  //       // ////  console.log(res);
  //       this.tost.success(res['detail']);
  //     });
  //   }
  // }
  checkClick() {
    let stringURL = 'https://www.hiwac.com/view-broadcast/236';
    let url = stringURL.replace(/(https:|)(^|\/\/)(.*?\/)/g, 'https://www.hiwac.cn/');
    //  console.log('this is the given url', stringURL);
    //  console.log('this is the modified url', url);
    window.open(url);
  }
  forgot() {
    this.router.navigate(['/authentication/forgetpassword']);
  }
  openModalLogin(loginModal) {
    this.modalService.open(loginModal, { centered: true, windowClass: 'social-login', size: 'md' });
  }
  show(val) {
    this.showPass = !val;
  }

  scrollBottom(top) {
    this.topScroll = this.topScroll += top;
    window.scroll({
      top: this.topScroll,
      left: 0,
      behavior: 'smooth'
    });
  }
  public scrollTop() {
    this.topScroll = 0;
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
  scrollSection() {
    var listItem = $("li")
    listItem.on("click", function () {
      var scrollingElement = $('#login-wrapper');
      var targetElement = $(this.dataset.link);

      scrollingElement.stop();
      scrollingElement.animate({
        scrollTop: targetElement.offset().top -
          scrollingElement.offset().top +
          scrollingElement.scrollTop() -
          parseFloat(targetElement.css("margin-top"))
      }, 200);
    })
  }

  //social
  // signInWithGoogle(): void {
  //   this.authService.signinstatus === true
  //   this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then((user) =>{
  //     this.authService.socialLogin(user, this.ul, this.r, this.a, this.type, this.referral, this.fromUser);

  //   });
  // }
  handleCredentialResponse(response) {
    console.log('Encoded JWT ID token: ' + response.credential);
    // Optionally decode JWT to extract user details here using a library
    // Update UI or local session state as needed
    this.authService.googleLogin(
      response.credential
    );
  }
  // async signInWithGoogle(): Promise<void> {
  //   this.authService.signinstatus === true;

  //   try {
  //     const user = await this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
  //     this.authService.socialLogin(user, this.ul, this.r, this.a, this.type, this.referral, this.fromUser);
  //   } catch (error) {
  //     if (error && error['error'] === 'popup_closed_by_user') {
  //       console.log('Google sign-in popup closed by user');
  //     } else {
  //       console.error('Error during Google sign-in:', error);
  //     }
  //   }
  // }


  //social
  // refreshToken(): void {
  //   this.socialAuthService.refreshAuthToken(GoogleLoginProvider.PROVIDER_ID);
  // }

  // signInWithFB(): void {
  //   this.authService.signinstatus === true
  //   this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then((user) =>{
  //     this.authService.socialLogin(user, this.ul, this.r, this.a, this.type, this.referral, this.fromUser);
  //     // this.user = user;
  //     //  console.log(this.user);
  //   });
  // }

  // socialSignOut(): void {
  //   this.socialAuthService.signOut();
  // }

  linkedInlogin() {
    this.authService.signinstatus === true
    window.location.href = `https://www.linkedin.com/uas/oauth2/authorization?response_type=code&client_id=${this.linkedIn.clientId}&redirect_uri=${this.linkedIn.redirectUrl}&scope=${this.linkedIn.scope}`;
  }

  wechatlogin() {
    this.authService.signinstatus === true
    window.location.href = `https://open.weixin.qq.com/connect/qrconnect?appid=${this.wehchat.appid}&redirect_uri=${this.wehchat.redirect_uri}&response_type=code&scope=${this.wehchat.scope}&state=STATE#wechat_redirect`;
  }
  place() {
    let abc = document.getElementById("country-search-box")['placeholder']
    let toast
    this.translate.get(abc).subscribe((data: any) => {
      toast = data;
    });
    abc = toast;
    document.getElementById("country-search-box")['placeholder'] = abc
    //  console.log("Placeholder Value", abc);
  }

  signInWithApple() {
    if (this.build_type === 'niwac') {
      const CLIENT_ID = "com.niwacsocial.bundle.backend"
      const REDIRECT_API_URL = "https://api.niwac.cn/api/user/social_login_apple/"
      window.location.href =
        `https://appleid.apple.com/auth/authorize?client_id=${CLIENT_ID}&redirect_uri=${encodeURIComponent(REDIRECT_API_URL)}&response_type=code id_token&scope=name%20email&response_mode=form_post&nonce=''`
        ;
      window.addEventListener('message', async event => {
        //  console.log(event.data)
        // // alert(event.data)
      });
    }
    else {
      const CLIENT_ID = "com.accountect.web"
      const REDIRECT_API_URL = "https://test.apis.accountect.com/api/user/social_login_apple/"
      window.location.href= 
          `https://appleid.apple.com/auth/authorize?client_id=${CLIENT_ID}&redirect_uri=${encodeURIComponent(REDIRECT_API_URL)}&response_type=code id_token&scope=name%20email&response_mode=form_post&nonce=''`
      ;
      window.addEventListener('message', async event => {
          console.log(event.data)
      });
      // const CLIENT_ID = "com.accountect.web";
      // const REDIRECT_API_URL = "https://test.apis.accountect.com//api/user/social_login_apple/";
      // window.location.href =
      //   `https://appleid.apple.com/auth/authorize?client_id=${CLIENT_ID}&redirect_uri=${encodeURIComponent(REDIRECT_API_URL)}&response_type=code id_token&scope=name%20email&response_mode=query&nonce=''`;

      // window.addEventListener('message', async event => {
      //   console.log(event.data);
      //   alert(event.data);
      // });

    }

  };


  backButton() {
    // this._location.back();
    window.location.replace('');
  }
}