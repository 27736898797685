<div class="page-wrapper mb-4">
    <div class="main-wrapper bg-white mx-0 my-3 py-2 position-relative b-r-20" >
        <div class="business-wraper">
          <div class="w-100 d-flex align-items-center justify-content-between">
              <div class="d-flex w-45 justify-content-between">
                <div [routerLink]="['/']" class="cursor-pointer me-4">
                  <img class="LOGO" src="../../../../assets/Accountect/topbarlogo.svg">
                </div>
                <div class="align-self-center">
                  <ul class="navbar-nav flex-row">
                    <li class="nav-item">
                      <a class="nav-link" aria-current="page" [routerLink]="['/']">{{'Home' | translate}}</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link active" [routerLink]="['pricing-plans']">{{'Pricing' | translate}}</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" (click)="navigateTo(auth.targetLanguage + '/download/exefile')">{{'Download' | translate}}</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="https://blog.ciwac.com/">{{'Blog' | translate}}</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="d-flex justify-content-end">
                  <div>
                      <a class="btn btn-outline-primary text-primary btn-logins" (click)="navigateAuth('/authentication/login')">
                        <span >{{ 'Log in'|translate }}</span>
                      </a>

                    </div>
                    <div class="mx-3">
                      <a class="btn btn-primary btn-logins text-white" (click)="navigateAuth('/authentication/register')">
                        <span >{{ 'Sign Up'|translate }}</span>
                      </a>
                    </div>
                    <div class=" header-right">
                      <div class="div-item">
                        <div class="ledge-lang" ngbDropdown placement="bottom-right">
                          <h5 id="dropdownBasic1" *ngIf="authService?.targetLanguage != 'zh-Hans'" ngbDropdownToggle class="text-primary font-weight-bold m-0 cursor-pointer d-flex align-items-center">
                            <img class=" mx-2 img-20" src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/updated/globe.svg" alt="">
                            {{authService?.targetLanguage.toUpperCase()}}
                          </h5>
                          <h5 id="dropdownBasic1" *ngIf="authService?.targetLanguage === 'zh-Hans'" ngbDropdownToggle class="text-primary font-weight-bold m-0 cursor-pointer d-flex align-items-center">
                            <img class=" mx-2 img-20" src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/updated/globe.svg" alt="">
                            {{'中文'}}
                          </h5>
                          <div ngbDropdownMenu aria-labelledby="dropdownBasic1" >
                            <div *ngFor="let lang of authService?.LangDrop">
                              <button ngbDropdownItem [ngClass]="{'bg-primary':authService.targetLanguage === lang?.language_value }" (click)="authService.changeLanguageLand(lang)">{{lang?.name}}</button>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
              </div>
            </div>

            <div class="container mx-width1000">    
                <div class="row PLAN">
                    <div class="col-sm-3" *ngFor = "let item of landingPlans; let i=index">
                      <div class="b-r-20 box-shad">
                        <div>
                            <span><hr [ngClass]="{'Free':i === 0,'Silver':i === 1,'Gold':i === 2,'Platinum':i === 3}"></span>
                            <h6 class="text-center p-2 m-0 position-relative"><span class="mr-4"> {{item?.name}}</span> <span class="c-plan" *ngIf="item?.name === currentSubscription"><img src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/Greentick.svg" alt=""></span></h6>
                            <div class="p-2 pRicE d-flex justify-content-center" [ngClass]="{'Free':i === 0,'Silver':i === 1,'Gold':i === 2,'Platinum':i === 3}">
                                <h4 class="text-white m-0 text-center">{{item?.currency}}{{item?.per_month_price}}/</h4><h5 class="text-white m-0 align-self-center text-center">{{'Month' | translate}}</h5>
                            </div>
                            <div class="p-2 px-4 h-500pxx">
                                <div class="bg-light text-center p-2 px-3 mb-1">
                                    <p [ngClass]="{'Free-text':i === 0,'Silver-text':i === 1,'Gold-text':i === 2,'Platinum-text':i === 3}">{{item?.plan_stats?.connected_ledger}}</p>
                                    <p>{{'Connected Ledger' | translate}}</p>
                                </div>
                                <div class="bg-light text-center p-2 px-3 mb-1">
                                    <p [ngClass]="{'Free-text':i === 0,'Silver-text':i === 1,'Gold-text':i === 2,'Platinum-text':i === 3}">{{item?.plan_stats?.one_way_ledger}}</p>
                                    <p>{{'One-Way Ledger' | translate}}</p>
                                </div>
                                <div class="bg-light text-center p-2 px-3 mb-1">
                                    <p [ngClass]="{'Free-text':i === 0,'Silver-text':i === 1,'Gold-text':i === 2,'Platinum-text':i === 3}">{{item?.plan_stats?.invoice_book}}</p>
                                    <p>{{'Invoice Book' | translate}}</p>
                                </div>
                                <div class="bg-light text-center p-2 px-3 mb-1">
                                    <p [ngClass]="{'Free-text':i === 0,'Silver-text':i === 1,'Gold-text':i === 2,'Platinum-text':i === 3}">{{item?.plan_stats?.member_account}}</p>
                                    <p>{{'User Accounts' | translate}}</p>
                                </div>
                                <div class="d-flex bg-light text-center w-100 mb-1 p-2">
                                    <p><i class="fa fa-check"></i></p>
                                    <p>{{'Cash Book' |  translate}}</p>
                                </div>
                                <div class="d-flex bg-light text-center w-100 mb-1 p-2">
                                    <p><i class="fa fa-check"></i></p>
                                    <p>{{'Expense Book' | translate}}</p>
                                </div>
                                <div class="d-flex bg-light text-center w-100 mb-1 p-2">
                                    <p><i class="fa fa-check"></i></p>
                                    <p>{{'Sale Book' | translate}}</p>
                                </div>
                                <div class="d-flex bg-light text-center w-100 mb-1 p-2">
                                    <p><i class="fa fa-check"></i></p>
                                    <p>{{'Purchase Book' | translate}}</p>
                                </div>
                                <div class="bg-light text-center w-100 mb-1 p-2">
                                        <p [ngClass]="{'Free-text':i === 0,'Silver-text':i === 1,'Gold-text':i === 2,'Platinum-text':i === 3}">{{item?.plan_stats?.bank_account}}</p>
                                        <p>{{'Bank Book' | translate}}</p>                                        
                                </div>
                                <div class="bg-light text-center mb-1 p-2">
                                    <p [ngClass]="{'Free-text':i === 0,'Silver-text':i === 1,'Gold-text':i === 2,'Platinum-text':i === 3}">{{item?.plan_stats?.inventory_product}}</p>
                                    <p>{{'Inventory(Products)' | translate}}</p>
                                </div>
                                <div class="bg-light text-center mb-1 p-2">
                                    <p [ngClass]="{'Free-text':i === 0,'Silver-text':i === 1,'Gold-text':i === 2,'Platinum-text':i === 3}">{{item?.plan_stats?.salary_book}}</p>
                                    <p>{{'Salary Book (Employees)' | translate}}</p>
                                </div>
                                <div class="bg-light text-center mb-1 p-2">
                                    <p [ngClass]="{'Free-text':i === 0,'Silver-text':i === 1,'Gold-text':i === 2,'Platinum-text':i === 3}">{{item?.plan_stats?.drive}} GB</p>
                                    <p>{{'Drive(GB)' | translate}}</p>
                                </div>
                                
                            </div>
                            <div class="Batan p-2" *ngIf="item?.id !== 1 && item?.name !== currentSubscription">
                                <button class="btn" [ngClass]="{'Free':i === 0,'Silver':i === 1,'Gold':i === 2,'Platinum':i === 3}"
                                (click)="navigateAuth('/authentication/register')">{{'Buy Now' | translate}}</button>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<hr>
<div class="footer-comp container">
    <div class="d-flex justify-content-between">
      <div>
        <div class="d-flex Social">
          <li>
            <a class="nav-link" href="https://www.facebook.com/accountect" target="_blank"><img src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/facebook.svg" alt="{{'facebook' | translate}}"></a>
          </li>
          <li>
            <a class="nav-link" href="https://www.instagram.com/accountect" target="_blank"><img src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/instagram.svg" alt="{{'instagram' | translate}}"></a>
          </li>
          <li>
            <a class="nav-link" href="https://www.linkedin.com/company/accountect" target="_blank"><img src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/linkedin.svg" alt="{{'linkedin' | translate}}"></a>
          </li>
          <li>
            <a class="nav-link" href="https://www.pinterest.com/accountect" target="_blank"><img src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/pinterest.svg" alt="{{'pinterest' | translate}}"></a>
          </li>
          <li>
            <a class="nav-link" href="https://www.twitter.com/accountect" target="_blank"><img src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/twitter.svg" alt="{{'twitter' | translate}}"></a>
          </li>
          <li>
            <a class="nav-link" href="https://youtube.com/@accountect" target="_blank"><img src="../../assets/youtube.svg" alt="{{'youtube' | translate}}"></a>
          </li>
        </div>
          <div class="px-3 mt-4 privacy1">
            <p (click)="navigateTo(auth.targetLanguage + '/contact/us')">{{'Contact Us' | translate}}</p>
            <p (click)="navigateTo(auth.targetLanguage + '/about/us')">{{'About Us' | translate}}</p>
            <p (click)="navigateTo(auth.targetLanguage + '/privacy-policy')">{{'Privacy Policy' | translate}}</p>
            <p (click)="navigateTo(auth.targetLanguage + '/terms-conditions')">{{'Terms and Conditions' | translate}}</p>
          </div>
            <div class="px-3 mt-5r direction-ltr">
            <p><b>Copyrights © Ciwac Connected Accounting Inc.</b> </p>
            </div>
        </div>
        <div class="direction-ltr">
            <div class="px-07" [routerLink]="['/']">
            <img width="150px" src="../../../../assets/Accountect/topbarlogo.svg">
            </div>
            <div class="d-flex mt-2">
                <a href="https://play.google.com/store/apps/details?id=com.tech.ciwac&hl=en&gl=US"  target="_blank"><img src="../../assets/Playstore en.svg" width="140px" alt="ciwac android app"></a>
                <a href="https://apps.apple.com/us/app/ciwac-connected-accounting/id1581386756" target="_blank"><img src="../../assets/Apple en.svg" width="140px" alt="ciwac ios app"></a> 
              </div>
            <div class="w-100 d-flex align-items-center justify-content-center">
            <div class="p-2">
                <div class="text-center"><span>{{'Android'}}</span></div>
                <div>
                    <ngx-qrcode [elementType]="elementType" [value]="qr_value_ad"
                        cssClass="aclass w-100 h-100 p-3 bg-light" errorCorrectionLevel="L">
                    </ngx-qrcode>
                </div>
            </div>
            <div class="p-2">
                <div class="text-center"><span>{{'IOS'}}</span></div>
                <div>
                    <ngx-qrcode [elementType]="elementType" [value]="qr_value_ios"
                        cssClass="aclass w-100 h-100 p-3 bg-light" errorCorrectionLevel="L">
                    </ngx-qrcode>
                </div>
            </div>
            </div>
        </div>
    </div>
  </div>