import { Component, HostListener, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-public-view',
  templateUrl: './public-view.component.html',
  styleUrls: ['./public-view.component.scss']
})
export class PublicViewComponent implements OnInit {
  public build_type = environment.build_type;
  private baseUrl = 'https://test.apis.accountect.com/api/';
  public id :string;  // The ID you provided
  uniqueId: string;
  Balance: any;
  CURRENCY: any;
  Transactions: any;
  BusinessName: any;
  loadPdf: boolean;
  AccountDataSearch:boolean = false
  ledgerView: boolean = true;
  bookView:boolean = false
  roomId: string;
  screenWidth: number;
  TransactionsR: any;
  groupedTransactions: any;


  constructor(private http: HttpClient, public route: ActivatedRoute,private toast: ToastrService) { }

  ngOnInit(): void {

    this.screenWidth = window.innerWidth;
    this.setViewBasedOnWidth();  
    const currentUrl = window.location.href;
    const urlParts = currentUrl.split('/');
    this.id = urlParts[urlParts.length - 1];
    console.log('Current Browser URL:', currentUrl);
    this.getPublicView(this.id);
    // this.getPDFPublic(this.id)
    this.route.paramMap.subscribe(params => {
      this.roomId = params.get('roomId');
      if (this.roomId) {
        // Perform actions with the roomId
        console.log('Room ID:', this.roomId);
      }
    });
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.screenWidth = window.innerWidth;
    this.setViewBasedOnWidth();
  }

  setViewBasedOnWidth(): void {
    if (this.screenWidth <= 992) {
      this.ledgerView = false;
    } else {
      this.ledgerView = true;
    }
  }

  getPublicView(id: any, pdfBoolean: boolean = true) {
    this.loadPdf=true;
    this.http.post(
      `${this.baseUrl}ledger/room/${id}/transactions/`,
      {},
    ).subscribe(
      (response) => {
        console.log('PDF fetched successfully:', response);
        this.BusinessName = response['business_name']
                this.Balance = response['balance'];
                this.CURRENCY = response['currency']
                this.Transactions = response['transactions']
                this.groupTransactionsByDate();
                this.loadPdf=false;
      },
      (error) => {
        console.error('Error fetching PDF:', error);
      }
    );
  }
  
  getPublicViewR(id: any, pdfBoolean: boolean = true) {
    this.loadPdf=true;
    this.http.post(
      `${this.baseUrl}ledger/room/${id}/transactions/`,
      {},
    ).subscribe(
      (response) => {
        console.log('PDF fetched successfully:', response);
        this.BusinessName = response['business_name']
                this.Balance = response['balance'];
                this.CURRENCY = response['currency']
                this.TransactionsR = response['transactions']
                this.TransactionsR.reverse();
                this.loadPdf=false;
      },
      (error) => {
        console.error('Error fetching PDF:', error);
      }
    );
  }

  
  groupTransactionsByDate() {
    this.groupedTransactions = this.Transactions.reduce((groups, transaction) => {
      const date = transaction.created_at.split('T')[0];
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(transaction);
      return groups;
    }, {});
  }
  getPDFPublic(id: any, pdfBoolean: boolean = true) {
    this.loadPdf = true;
    const httpOptions = {
      responseType: 'blob' as 'json'
    };
    this.http.post(
      `${this.baseUrl}ledger/room/${id}/transactions/?is_pdf=${pdfBoolean}`,
      {},
      httpOptions
    ).subscribe(
      (res: Blob) => {
        let blob = new Blob([res], { type: 'application/pdf' });
        var downloadURL = URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = 'Account Summary';
        link.click();
        this.loadPdf = false;
        this.toast.success('PDF downloaded successfully');
      },
      (error) => {
        console.error('Error downloading PDF:', error);
        this.loadPdf = false;
        this.toast.error('Error downloading PDF');
      }
    );
  }
  toggleView(){
    this.ledgerView = !this.ledgerView;
  }
  
  backButton() {
    window.location.replace('');
  }
}

