<div class="h-100 w-100 d-flex align-items-center justify-content-center">
  <div class="modal-ngb">
      <div class="modal-header p-1">
        <div class="w-100 p-3  d-flex align-items-center justify-content-center">
          <div class="texxt-align-center">
            <h6 class="text-dark font-weight-600 m-0 d-flex align-items-center">
              {{'Payment Reminder'|translate}}
            </h6>
          </div>
        </div>
      </div>
      <div class="modal-body ledger-debit-modal">
        <div class="w-100">
          
          <div class="bg-imglimit">
            <div class="Absolute-data">
              <div>
                  <h2 class="text-white f-14 my-2 ">Payment Reminder</h2>
              </div>
              <div class="bg-light p-1">
                  <div class="border-5px bg-white d-flex justify-content-between">
                      <div>
                          <p class="m-0 font-weight-bold f-14 credit-color">
                            {{data?.latest_transaction?.current_balance | number:'1.2-2'}} 
                            <span class="text-light">{{data?.latest_transaction?.currency?.code}}</span>
                          </p>
                      </div>
                  </div>
              </div>
              <div>
                  <p class="text-white mt-2 f-12 m-0">Sent by</p>
                  <h2 class="text-white f-12 m-0 text-uppercase">{{data?.latest_transaction?.creator?.Business?.business_name}}</h2>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
</div>