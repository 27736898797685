import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpServiceService } from '../shared/services/http/http-service.service';
import { AuthService } from '../shared/services/auth.service';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss']
})
export class ContactusComponent implements OnInit {
  elementType = 'url';
  qr_value_ad = 'https://play.google.com/store/search?q=ciwac&c=apps';
  qr_value_ios='https://apps.apple.com/lk/app/ciwac/id1581386756';
  constructor(private http: HttpServiceService,
    public authService: AuthService,
    public translate: TranslateService) { }

  ngOnInit(): void {
  }
  navigateAuth(url: any) {
    window.location.replace(`${url}`);
    //  console.log('this is the location of windows', window.location);
  }
  backButton() {
    // this._location.back();
    window.location.replace('');
}
}
