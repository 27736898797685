<!-- Page Header Start-->
<div class="page-main-header" id="page-main-header" *ngIf="Checkreq?.is_buissness_exist === true || authService?.userData?.emp_info?.is_buissness_exist === true">
  <div class="header-wrapper">
    <div class="h-100">
      <div class="h-85 header-right">
        <div class="div-item d-flex h-95">
          <div class="header-ledger cursor-pointer  position-relative"  *ngIf="authService?.userData?.emp_info?.is_buissness_exist === false" [routerLink]="['ledger/join/create']">
            <img class="" *ngIf="authService?.headertab === 'Summary'" src="../../../../assets/Accountect/Leftbar Web/Overview active.svg" width="22px" height="22px" alt="">
            <img class="" *ngIf="authService?.headertab != 'Summary'"  src="../../../../assets/Accountect/Leftbar Web/Overview_inactive.svg" width="22px" height="22px" alt="">
            <span class="text-white font-weight-500 " [ngStyle]="{'font-weight':authService?.headertab === 'Summary' ? '600' : '500' }" >{{'Overview'|translate}}</span>
            <div class="select-ledger" *ngIf="authService?.headertab === 'Summary'"
              [ngClass]="{'select-ledger-left': authService.dirWeb == 'ltr', 'select-ledger-right': authService.dirWeb == 'rtl'}">
            </div>
          </div>
          <div class="header-ledger cursor-pointer  position-relative"  *ngIf="authService?.userData?.emp_info?.is_buissness_exist === true" (click)="authService.headertoggle('Summary')" [routerLink]="['ledger/account-business-summary']">
            <img class="" *ngIf="authService?.headertab === 'Summary'" src="../../../../assets/Accountect/Leftbar Web/Overview active.svg" width="22px" height="22px" alt="">
            <img class="" *ngIf="authService?.headertab != 'Summary'"  src="../../../../assets/Accountect/Leftbar Web/Overview_inactive.svg" width="22px" height="22px" alt="">
            <span class="text-white font-weight-500 " [ngStyle]="{'font-weight':authService?.headertab === 'Summary' ? '600' : '500' }" >{{'Overview'|translate}}</span>
            <div class="select-ledger" *ngIf="authService?.headertab === 'Summary'"
              [ngClass]="{'select-ledger-left': authService.dirWeb == 'ltr', 'select-ledger-right': authService.dirWeb == 'rtl'}">
            </div>
          </div>
          <div class="header-ledger cursor-pointer  position-relative"  *ngIf="authService?.userData?.emp_info?.is_buissness_exist === false"  [routerLink]="['ledger/join/create']">
            <img class=""  *ngIf="authService?.headertab === 'Ledger'" src="../../../../assets/Accountect/Leftbar Web/Ledger Active.svg" width="25px" height="25px" alt="">
            <img class=""  *ngIf="authService?.headertab != 'Ledger'"  src="../../../../assets/Accountect/Leftbar Web/Ledger inActive.svg" width="25px" height="25px" alt="">
            <span class="text-white font-weight-500 "  [ngStyle]="{'font-weight':authService?.headertab === 'Ledger' ? '600' : '500' }">{{'Accounts'|translate}}</span>
            <div class="select-ledger" *ngIf="authService?.headertab === 'Ledger'"
                    [ngClass]="{'select-ledger-left': authService.dirWeb == 'ltr', 'select-ledger-right': authService.dirWeb == 'rtl'}">
            </div>
          </div>
          <div class="header-ledger cursor-pointer  position-relative"  *ngIf="authService?.userData?.emp_info?.is_buissness_exist === true" (click)="onAccountsClick() " [routerLink]="['/ledger/default']"  [queryParams]="{id: ledgerService.urlUser?ledgerService.urlUser: null}">
            <img class=""  *ngIf="authService?.headertab === 'Ledger'" src="../../../../assets/Accountect/Leftbar Web/Ledger Active.svg" width="25px" height="25px" alt="">
            <img class=""  *ngIf="authService?.headertab != 'Ledger'"  src="../../../../assets/Accountect/Leftbar Web/Ledger inActive.svg" width="25px" height="25px" alt="">
            <span class="text-white font-weight-500 " [ngStyle]="{'font-weight':authService?.headertab === 'Ledger' ? '600' : '500' }" >{{'Accounts'|translate}}</span>
            <div class="select-ledger" *ngIf="authService?.headertab === 'Ledger'"
                    [ngClass]="{'select-ledger-left': authService.dirWeb == 'ltr', 'select-ledger-right': authService.dirWeb == 'rtl'}">
            </div>
            <div  *ngIf="authService?.showNotifyLedger" class="ledgerNotify">
              <i class="fa fa-circle text-danger" aria-hidden="true"></i>
            </div>
          </div>

          <div class="header-ledger cursor-pointer  position-relative"
          (click)="authService.headertoggle('Sale'); bookService?.SummaryViewCheck('false'); bookService?.alertsViewCheck(false)" [routerLink]="['/sale/view']">          
            <div class="position-relative">
              <img class="" *ngIf="authService?.headertab === 'Sale'"  src="../../../../assets/Accountect/Leftbar Web/Sale Active.svg" width="25px" height="25px" alt="">
              <img class="" *ngIf="authService?.headertab != 'Sale'" src="../../../../assets/Accountect/Leftbar Web/Sale inactive.svg" width="25px" height="25px" alt="">
            </div>
            <span class="text-white font-weight-500 " [ngStyle]="{'font-weight':authService?.headertab === 'Sale' ? '600' : '500' }">{{'Sale'|translate}}</span>
            <div class="select-ledger" *ngIf="authService?.headertab === 'Sale'"
              [ngClass]="{'select-ledger-left': authService.dirWeb == 'ltr', 'select-ledger-right': authService.dirWeb == 'rtl'}">
            </div>
          </div>
          <!-- <div *ngIf="authService?.userData?.emp_info?.is_buissness_exist === true" class="header-ledger cursor-pointer  position-relative" 
            (click)="authService.headertoggle('Cplus'); authService.showNotifyPlus = false" [routerLink]="['/Accounting-Suite/default']">
            
            <div class="position-relative">
              <img class="" *ngIf="authService?.headertab === 'Cplus'"  src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/Icons/Cplus Active.svg" width="25px" height="25px" alt="">
              <img class="" *ngIf="authService?.headertab != 'Cplus'" src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/Icons/Cplus Inactive.svg" width="25px" height="25px" alt="">
              <div *ngIf="authService?.showNotifyPlus" class="show-Notify">
                <i class="fa fa-circle text-danger" aria-hidden="true"></i>
              </div>
            </div>
            <span class="text-white font-weight-500 " [ngStyle]="{'font-weight':authService?.headertab === 'Cplus' ? '600' : '500' }">{{'Plus'|translate}}</span>
            <div class="select-ledger" *ngIf="authService?.headertab === 'Cplus'"
                    [ngClass]="{'select-ledger-left': authService.dirWeb == 'ltr', 'select-ledger-right': authService.dirWeb == 'rtl'}">
            </div>
          </div> -->
          <div class="header-ledger cursor-pointer  position-relative" 
          (click)="authService.headertoggle('Purchase'); bookService?.SummaryViewCheck('false');bookService?.alertsViewCheck(false)"   [routerLink]="['/purchase/view']">          
            <div class="position-relative">
              <img class="" *ngIf="authService?.headertab === 'Purchase'"  src="../../../../assets/Accountect/Leftbar Web/Purchase Active.svg" width="25px" height="25px" alt="">
              <img class="" *ngIf="authService?.headertab != 'Purchase'" src="../../../../assets/Accountect/Leftbar Web/Purchase Inactive.svg" width="25px" height="25px" alt="">
            </div>
            <span class="text-white font-weight-500 " [ngStyle]="{'font-weight':authService?.headertab === 'Purchase' ? '600' : '500' }">{{'Purchase'|translate}}</span>
            <div class="select-ledger" *ngIf="authService?.headertab === 'Purchase'"
              [ngClass]="{'select-ledger-left': authService.dirWeb == 'ltr', 'select-ledger-right': authService.dirWeb == 'rtl'}">
            </div>
          </div>
          <div class="header-ledger cursor-pointer  position-relative"
          (click)="authService.headertoggle('Expense'); bookService?.SummaryViewCheck('false'); bookService?.alertsViewCheck(false)" [routerLink]="['/expense/view']">          
            <div class="position-relative">
              <img class="" *ngIf="authService?.headertab === 'Expense'"  src="../../../../assets/Accountect/Leftbar Web/Expense Active.svg" width="25px" height="25px" alt="">
              <img class="" *ngIf="authService?.headertab != 'Expense'" src="../../../../assets/Accountect/Leftbar Web/Expense Inactive.svg" width="25px" height="25px" alt="">
            </div>
            <span class="text-white font-weight-500 " [ngStyle]="{'font-weight':authService?.headertab === 'Expense' ? '600' : '500' }">{{'Expense'|translate}}</span>
            <div class="select-ledger" *ngIf="authService?.headertab === 'Expense'"
              [ngClass]="{'select-ledger-left': authService.dirWeb == 'ltr', 'select-ledger-right': authService.dirWeb == 'rtl'}">
            </div>
          </div>
          
          <!-- <div class="header-ledger cursor-pointer  position-relative" 
            (click)="authService.headertoggle('Invoice')"  [routerLink]="['/bill-book/dashboard']">          
            <div class="position-relative">
              <img class="" *ngIf="authService?.headertab === 'Invoice'"  src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/Icons/invoicebook Active.svg" width="25px" height="25px" alt="">
              <img class="" *ngIf="authService?.headertab != 'Invoice'" src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/Icons/InvoiceBook Inactive.svg" width="25px" height="25px" alt="">
            </div>
            <span class="text-white font-weight-500 " [ngStyle]="{'font-weight':authService?.headertab === 'Invoice' ? '600' : '500' }">{{'Invoice'|translate}}</span>
            <div class="select-ledger" *ngIf="authService?.headertab === 'Invoice'"
              [ngClass]="{'select-ledger-left': authService.dirWeb == 'ltr', 'select-ledger-right': authService.dirWeb == 'rtl'}">
            </div>
          </div> -->
          <div class="header-ledger cursor-pointer  position-relative" 
          (click)="authService.headertoggle('CASH');  bookService?.alertsViewCheck(false)" [routerLink]="['/cashbank/view']">          
            <div class="position-relative">
              <img class="" *ngIf="authService?.headertab === 'CASH'"  src="../../../../assets/Accountect/Leftbar Web/CashBank Active.svg" width="25px" height="25px" alt="">
              <img class="" *ngIf="authService?.headertab != 'CASH'" src="../../../../assets/Accountect/Leftbar Web/CashBank Inactive.svg" width="25px" height="25px" alt="">
            </div>
            <span class="text-white font-weight-500 " [ngStyle]="{'font-weight':authService?.headertab === 'CASH' ? '600' : '500' }">{{'CASH & BANK'|translate}}</span>
            <div class="select-ledger" *ngIf="authService?.headertab === 'CASH'"
              [ngClass]="{'select-ledger-left': authService.dirWeb == 'ltr', 'select-ledger-right': authService.dirWeb == 'rtl'}">
            </div>
          </div>
          <div class="header-ledger cursor-pointer  position-relative" 
          (click)="authService.headertoggle('Items'); ledgerService?.itemMarkerCheck('item')" [routerLink]="['/items/view']">          
            <div class="position-relative">
              <img class="" *ngIf="authService?.headertab === 'Items'"  src="../../../../assets/Accountect/Leftbar Web/Items Active.svg" width="25px" height="25px" alt="">
              <img class="" *ngIf="authService?.headertab != 'Items'" src="../../../../assets/Accountect/Leftbar Web/Items Inactive.svg" width="25px" height="25px" alt="">
            </div>
            <span class="text-white font-weight-500 " [ngStyle]="{'font-weight':authService?.headertab === 'Items' ? '600' : '500' }">{{'Items'|translate}}</span>
            <div class="select-ledger" *ngIf="authService?.headertab === 'Items'"
              [ngClass]="{'select-ledger-left': authService.dirWeb == 'ltr', 'select-ledger-right': authService.dirWeb == 'rtl'}">
            </div>
          </div>
          <!-- <div *ngIf="authService?.userData?.emp_info?.is_buissness_exist === false" class="header-ledger cursor-pointer  position-relative" 
            (click)="authService.headertoggle('Cplus'); authService.showNotifyPlus = false" [routerLink]="['ledger/join/create']">
            
            <div class="position-relative">
              <img class="" *ngIf="authService?.headertab === 'Cplus'"  src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/Icons/Cplus Active.svg" width="25px" height="25px" alt="">
              <img class="" *ngIf="authService?.headertab != 'Cplus'" src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/Icons/Cplus Inactive.svg" width="25px" height="25px" alt="">
              <div *ngIf="authService?.showNotifyPlus" class="show-Notify">
                <i class="fa fa-circle text-danger" aria-hidden="true"></i>
              </div>
            </div>
            <span class="text-white font-weight-500 " [ngStyle]="{'font-weight':authService?.headertab === 'Cplus' ? '600' : '500' }">{{'Plus'|translate}}</span>
          </div> -->
          <div class="header-ledger cursor-pointer  position-relative" 
            (click)="authService.headertoggle('Market'); ledgerService?.itemMarkerCheck('market') "  [routerLink]="['/market/dashboard']" [queryParams]="{tab: 'Listing'}">          
            <div class="position-relative">
              <img class="" *ngIf="authService?.headertab === 'Market'"  src="../../../../assets/Accountect/Leftbar Web/Market Active.svg" width="25px" height="25px" alt="">
              <img class="" *ngIf="authService?.headertab != 'Market'" src="../../../../assets/Accountect/Leftbar Web/Market Inactive.svg" width="25px" height="25px" alt="">
            </div>
            <span class="text-white font-weight-500" [ngStyle]="{'font-weight':authService?.headertab === 'Market' ? '600' : '500' }">{{'Market'|translate}}</span>
            <div class="select-ledger" *ngIf="authService?.headertab === 'Market'"
              [ngClass]="{'select-ledger-left': authService.dirWeb == 'ltr', 'select-ledger-right': authService.dirWeb == 'rtl'}">
            </div>
          </div>
          <!-- <div class="header-ledger cursor-pointer  position-relative" [routerLink]="['/payroll/view']">          
            <div class="position-relative">
              <img class="" *ngIf="authService?.headertab === 'Payroll'"  src="../../../../assets/Accountect/Leftbar Web/HR Active.svg" width="25px" height="25px" alt="">
              <img class="" *ngIf="authService?.headertab != 'Payroll'" src="../../../../assets/Accountect/Leftbar Web/HR Inactive.svg" width="25px" height="25px" alt="">
            </div>
            <span class="text-white font-weight-500 " [ngStyle]="{'font-weight':authService?.headertab === 'Payroll' ? '600' : '500' }">{{'HR & PAYROLL'|translate}}</span>
          </div> -->
          <div class="header-ledger cursor-pointer  position-relative" 
          (click)="authService.headertoggle('Drive')" [routerLink]="['/drive/view']">          
            <div class="position-relative">
              <img class="" *ngIf="authService?.headertab === 'Drive'"  src="../../../../assets/Accountect/Leftbar Web/Drive Active.svg" width="25px" height="25px" alt="">
              <img class="" *ngIf="authService?.headertab != 'Drive'" src="../../../../assets/Accountect/Leftbar Web/Drive Inactive.svg" width="25px" height="25px" alt="">
            </div>
            <span class="text-white font-weight-500 " [ngStyle]="{'font-weight':authService?.headertab === 'More' ? '600' : '500' }">{{'Drive'|translate}}</span>
            <div class="select-ledger" *ngIf="authService?.headertab === 'Drive'" [ngClass]="{'select-ledger-left': authService.dirWeb == 'ltr', 'select-ledger-right': authService.dirWeb == 'rtl'}">
            </div>
          </div>
 
              <!-- <div class="header-ledger cursor-pointer  position-relative" (click)="authService.headertoggle('Me')" [routerLink]="['/user/profile']">
                <img class="img-35 b-r-50" *ngIf="authService?.userData?.emp_info?.user_profile_image" [src]="authService?.userData?.emp_info?.user_profile_image?authService?.userData?.emp_info?.user_profile_image:'http://accountect.assets.s3.amazonaws.com/ciwacAssets/Icons/Me Inactive.svg'" alt="">
                <img class=""  *ngIf="!authService?.userData?.emp_info?.user_profile_image && authService?.headertab != 'Me'"  src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/Icons/Me Inactive.svg" width="25px" height="25px" alt="">
                <img class=""  *ngIf="!authService?.userData?.emp_info?.user_profile_image && authService?.headertab === 'Me'"  src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/Icons/Me Active.svg" width="25px" height="25px" alt="">
                <span class=" text-white font-weight-500 " [ngStyle]="{'font-weight':authService?.headertab === 'Me' ? '600' : '500' }">{{'Me'|translate}}</span>
              </div> -->

        </div>
      </div>
      
      <div class="MeTab" ngbDropdown placement="top-end" [routerLink]="['/user/profile']">
        <div class="nav-item cursor-pointer" ngbDropdownToggle>
          <img class="img-25" src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/Icons/Me Inactive.svg" alt="">
          <span class="text-white font-weight-500 Me">{{'Me'|translate}}</span>
          <!-- <div class="my-auto">
            <div class="ledge-langs cursor-pointer" >
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                  <button ngbDropdownItem [routerLink]="['/user/profile']"><img src="../../../../assets/Accountect/me_seetings/Group 40484.svg" class="mr-2" alt=""> {{'Settings'|translate}}</button>
                  <button ngbDropdownItem (click)="openModallogout(logout)"><img src="../../../../assets/Accountect/me_seetings/Group 40483.svg" class="mr-2" alt=""> {{'Logout'|translate}}</button>
                </div>
              </div>
            </div> -->
        </div>
      </div>
      <!-- <div class="header-right">
        <div class="div-item langDropp">
          <div class="ledge-langh" ngbDropdown placement="bottom-right">
            <h5 id="dropdownBasic1" *ngIf="authService.targetLanguage != 'zh-Hans'" ngbDropdownToggle class="text-white font-weight-bold  cursor-pointer d-flex align-items-center">
              <img class=" mx-2 img-20" src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/updated/globe.svg" alt="">
              {{authService?.targetLanguage?.toUpperCase()}}
            </h5>
            <h5 id="dropdownBasic1" *ngIf="authService.targetLanguage === 'zh-Hans'" ngbDropdownToggle class="text-white font-weight-bold  cursor-pointer d-flex align-items-center">
              <img class=" mx-2 img-20" src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/updated/globe.svg" alt="">
              {{'中文'}}
            </h5>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1" >
              <div *ngFor="let lang of authService?.LangDrop" >
                <button ngbDropdownItem class="bg-primary" (click)="authService.changeLanguage(lang)" [ngClass]="{'bg-primary':authService?.targetLanguage === lang.language_value }">{{lang?.name}}</button>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      
    </div>
  </div>
</div>
<ng-template #logout let-modal>
  <div class="modal-ngb">
      <div class="modal-header p-1">
          <div class="w-95">
              <h4 class="text-light font-weight-500 m-0 text-center">
                  {{'Logout'|translate}}
              </h4>
          </div>
          <div class="w-5 p-0">
              <span aria-hidden="true" (click)="modal.dismiss('Cross click')" class="modal-close-btn p-0">×</span>
          </div>
      </div>
      <div class="modal-body ledger-debit-modal py-2">
          <div class="px-2">
              <div class="mt-2 text-center p-2">
                  <span>{{'Do you want to logout'|translate}}?</span>
              </div>
          </div>
      </div>
      <div class="d-flex justify-content-center w-100">
          <!-- <div class="w-20 mx-0">
          <button (click)="ledgerService.acceptRejectRooms(item?.id, false)" class="btn btn-outline-danger text-primary w-100">
              <img class="img-20" src="../../../../assets/Accountect/Me/Extras/Delete.svg">
          </button>
        </div> -->
          <div class="w-80 mx-2 p-4 text-center">
              <button (click)="modal.dismiss('Cross click')"
                  class="btn btn-outline-light w-40 mx-2 f-18 font-weight-bold">
                  {{'No'|translate}}
              </button>
              <button (click)="auth?.SignOut()"
                  class="btn btn-primary w-40 mx-2 text-white f-18 font-weight-bold">
                  {{'Yes'|translate}}
              </button>
          </div>
      </div>
  </div>
</ng-template>
<!-- Page Header Ends -->
