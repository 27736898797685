<div class="w-100 upload-img">
  <div class="camera-div">
    <div class="w-100 h-100 d-flex align-items-center justify-content-center position-relative">
      <img src="../../../../assets/Accountect/audio/Group 19899.svg" class="upl-img" alt="" *ngIf="!audioRecordingService?.isRecording && !blobUrl" (click)="startRecording()">
      <img src="../../../../assets/Accountect/audio/Group 19927.svg" class="upl-img" alt="" *ngIf="audioRecordingService?.isRecording && !blobUrl" (click)="stopRecording()">
      <img src="../../../../assets/Accountect/audio/Group 19926.svg" class="upl-img" alt="" *ngIf="!audioRecordingService?.isRecording && blobUrl">
      <!-- <button type="button" class="btn-close verifyButton " *ngIf="audioRecordingService?.isRecording && !blobUrl" (click)="stopRecording()" ></button> -->
    </div>
  </div>
  <div class="d-flex w-80">
    <div class="w-100 text-center" *ngIf="!audioRecordingService?.isRecording && !blobUrl"><span class="text-light">( {{'Press and Hold To Record'|translate}} )</span></div>
    <div class="w-100 text-center" *ngIf="audioRecordingService?.isRecording && !blobUrl"><img src="../../../../assets/Accountect/audio/seek.gif" alt=""> {{recordedTime}} </div>
    <div id="waveform" *ngIf="!audioRecordingService?.isRecording && blobUrl">
      <audio *ngIf="!audioRecordingService?.isRecording && blobUrl" controls>
        <img src="../../../../assets/Accountect/audio/Group 19929.svg" alt="">
        <source [src]="blobUrl" type="audio/webm">
      </audio>
    </div>
    <div class="w-20" *ngIf="!audioRecordingService?.isRecording && blobUrl">
      <img class="w-100 audiowav" src="../../../../assets/Accountect/audio/Group 19929.svg" alt="">
    </div>               
    <i *ngIf="!audioRecordingService?.isRecording && blobUrl" (click)="clearRecordedData(); IsEmployee?employeeService.popAudioFile(fileArray, form) : ledgerService.popAudioFile(fileArray, form)"  class="fa fa-minus-circle cursor-pointer text-danger"
          aria-hidden="true"></i>
  </div>
</div>
