import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/services/auth.service';
import { EmployeeService } from 'src/app/shared/services/employee.service';
import { HttpServiceService } from 'src/app/shared/services/http/http-service.service';
import { LedgerService } from 'src/app/shared/services/ledger.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-add-participent',
  templateUrl: './add-participent.component.html',
  styleUrls: ['./add-participent.component.scss']
})
export class AddParticipentComponent implements OnInit {
@Input() ParticipentType
public build_type = environment.build_type;
@Input() oldLedger
contactsArray: any = [];
InactiveEmail: any;
syncForm: FormGroup;
syncContact: any = [];
inactiveEmpId: any;
type: any;
shareLink: any;
  constructor(public auth:AuthService, public ledgerService: LedgerService,public modalService: NgbModal, public translate: TranslateService,
    private http: HttpServiceService,public toast: ToastrService, private fb: FormBuilder,
    public employeeService: EmployeeService) { 
      this.syncForm = fb.group({
        email: [null],
      });
    }

  ngOnInit(): void {
    //  console.log("creator", this.ParticipentType);
    
  }
  addParticipants(modal) {
    this.modalService.dismissAll();
    this.ledgerService.getAllMembers('', this.ParticipentType);
    this.modalService.open(modal, {
      centered: true,
      windowClass: 'ledger-participant',
      size: 'lg',
    });
  }
  openModalInvite(invite) {
    this.modalService.dismissAll();
    this.modalService.open(invite, {
      centered: true,
      windowClass: 'invite-modal',
      size: 'md',
    });
  }
  addContactList(item, type) {
    // 
    // this.contactsArray = []
    if (type === 'Active') {
      if (this.contactsArray.includes(item.email)) {
        let index = this.contactsArray.indexOf(item.email);
        this.contactsArray.splice(index, 1);
      } else {
        this.contactsArray.push(item.email);
      }
    }
    if (type === 'Inactive') {
      if (this.contactsArray.includes(item.email)) {
        let index = this.contactsArray.indexOf(item.email);
        this.contactsArray.splice(index, 1);
      } else {
        this.contactsArray = [];
        this.contactsArray.push(item.email);
        this.InactiveEmail = item.email;
      }
    }
  }
  addEmail() {
    this.http.manuallyAddEmail(this.syncForm.value.email).subscribe(
      (res) => {
        //  console.log('this is the ledger list', res);
        this.toast.success(res['details']);
        this.syncContactList();
        // this.syncContactList()
        // this.syncContact = res
      },
      (error) => {
        this.toast.error(error['error']['msg']);
      }
    );
  }
  openModalNewUser(modal) {
    this.modalService.open(modal, {
      centered: true,
      windowClass: 'planModal',
      size: 'lg',
    });
  }
  AddMemberlink(array, type) {
    if (type === 'Active') {
      this.http.addMemberlink(array).subscribe(
        (res) => {
          if (res?.plan_expired || res?.limit_exceeded) {
            this.ledgerService?.sendClickEvent();
          }
          this.toast.success(res['details']);
          this.modalService.dismissAll();
          // this.syncContact = res
        },
        (error) => {
          this.toast.error(error['error']['msg']);
        }
      );
    }
    if (type === 'Inactive') {
      this.http
        .sendInactveMemberlink(this.InactiveEmail, this.inactiveEmpId)
        .subscribe(
          (res) => {
            this.toast.success(res['details']);
            // this.syncContact = res
            this.modalService.dismissAll();
          },
          (error) => {
            this.toast.error(error['error']['msg']);
          }
        );
    }
  }
  
  openModalAddMember(Add_New_User, value, empId) {
    this.contactsArray = [];
    this.type = value;
    if (this.type === 'Inactive') {
      this.getSharelink(empId);
      this.inactiveEmpId = empId;
    }
    this.syncContactList();
    this.modalService.open(Add_New_User, {
      centered: true,
      windowClass: 'group-popup',
      size: 'md',
    });
  }
  getSharelink(id) {
    this.http.getshareLink(id).subscribe(
      (res) => {
        this.shareLink = res['link'];
      },
      (error) => {
        this.toast.error(error['error']['msg']);
      }
    );
  }
  syncContactList() {
    this.http.getContactList().subscribe(
      (res) => {
        //  console.log('this is the ledger list', res);
        this.syncContact = res['contacts_list'];
      },
      (error) => {
        this.toast.error(error['error']['msg']);
      }
    );
  }
  addnewParticipant(item) {
    //  console.log();
    if(this.ParticipentType === 'salary'){
      this.http.addParticipantsSalary(item.emp_id).subscribe(res => {
        this.toast.success(res['details']);
        this.modalService.dismissAll();
        this.employeeService.getEmployeeList()
      }, (error) => {
        this.toast.error(error['error']['msg']);
      })
    }
    if(this.ParticipentType === 'cash'){
      this.http.addParticipantsCash(item.emp_id).subscribe(res => {
        this.toast.success(res['details']);
        this.modalService.dismissAll();
        this.ledgerService.getCashTran()
      }, (error) => {
        this.toast.error(error['error']['msg']);
      })
    }
    if(this.ParticipentType === 'sale'){
      this.http.addParticipantsSale(item.emp_id).subscribe(res => {
        this.toast.success(res['details']);
        this.modalService.dismissAll();
        this.ledgerService.salecurrentPage = 1
        this.ledgerService.getSaleTran()
      }, (error) => {
        this.toast.error(error['error']['msg']);
      })
    }
    if(this.ParticipentType === 'purchase'){
      this.http.addParticipantsPurchase( item.emp_id).subscribe(res => {
        this.toast.success(res['details']);
        this.modalService.dismissAll();
        this.ledgerService.getPurchaseTran()
      }, (error) => {
        this.toast.error(error['error']['msg']);
      })
    }
    if(this.ParticipentType === 'bank'){
      this.http.addBankroomMember( this.ledgerService.bankRoomID,item.emp_id).subscribe(res => {
        this.toast.success(res['details']);
        this.modalService.dismissAll();
        this.ledgerService.getPurchaseTran()
      }, (error) => {
        this.toast.error(error['error']['msg']);
      })
    }
    if(this.ParticipentType === 'expense'){
      this.http.addParticipantsExpense(item.emp_id).subscribe(res => {
        this.toast.success(res['details']);
        this.modalService.dismissAll();
        this.ledgerService.expensecurrentPage = 1
        this.ledgerService.getExpenseTran()
      }, (error) => {
        this.toast.error(error['error']['msg']);
      })
    }
    
  }
  dismiss(){
    this.modalService.dismissAll()
  }
  removeParticipant(item) {
    //
    if(this.ParticipentType === 'salary'){
      this.http.removeParticipantsSalary(item).subscribe(res => {
        this.toast.success(res['details']);
        this.modalService.dismissAll();
        this.employeeService.getEmployeeList()
      }, (error) => {
        this.toast.error(error['error']['msg']);
      })
    }
    if(this.ParticipentType === 'cash'){
      this.http.removeParticipantsCash(item).subscribe(res => {
        this.toast.success(res['details']);
        this.modalService.dismissAll();
        this.ledgerService.getCashTran()
      }, (error) => {
        this.toast.error(error['error']['msg']);
      })
    }
    if(this.ParticipentType === 'sale'){
      this.http.removeParticipantsSale(item).subscribe(res => {
        this.toast.success(res['details']);
        this.modalService.dismissAll();
        this.ledgerService.salecurrentPage = 1
        this.ledgerService.getSaleTran()
      }, (error) => {
        this.toast.error(error['error']['msg']);
      })
    }
    if(this.ParticipentType === 'purchase'){
      this.http.removeParticipantsPurchase( item).subscribe(res => {
        this.toast.success(res['details']);
        this.modalService.dismissAll();
        this.ledgerService.getPurchaseTran()
      }, (error) => {
        this.toast.error(error['error']['msg']);
      })
    }
    if(this.ParticipentType === 'expense'){
      this.http.removeParticipantsExpense(item).subscribe(res => {
        this.toast.success(res['details']);
        this.modalService.dismissAll();
        this.ledgerService.expensecurrentPage = 1
        this.ledgerService.getExpenseTran()
      }, (error) => {
        this.toast.error(error['error']['msg']);
      })
    }
    if(this.ParticipentType === 'inventory'){
      this.http.removeParticipantsInventory(item).subscribe(res => {
        this.toast.success(res['details']);
        this.modalService.dismissAll();
        this.ledgerService.expensecurrentPage = 1
        this.ledgerService.getExpenseTran()
      }, (error) => {
        this.toast.error(error['error']['msg']);
      })
    }
    if(this.ParticipentType === 'inv'){
      this.http.removeParticipantsExpense(item).subscribe(res => {
        this.toast.success(res['details']);
        this.modalService.dismissAll();
        this.ledgerService.expensecurrentPage = 1
        this.ledgerService.getExpenseTran()
      }, (error) => {
        this.toast.error(error['error']['msg']);
      })
    }
  }
  copySharedNetworkLink(inputElement) {
    //  console.log(inputElement);

    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = inputElement;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    // inputElement.setSelectionRange(0, 0);
    let toast;
    this.translate.get('Link copied to clipboard').subscribe((data: any) => {
      toast = data;
    });
    this.toast.success(toast);
    document.body.removeChild(selBox);
  }
}
