import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/services/auth.service';
import { EmployeeService } from 'src/app/shared/services/employee.service';
import { HttpServiceService } from 'src/app/shared/services/http/http-service.service';
import { LedgerService } from 'src/app/shared/services/ledger.service';
import { environment } from 'src/environments/environment.prod';
import { saveAs } from 'file-saver';
@Component({
  selector: 'app-pdfrooms',
  templateUrl: './pdfrooms.component.html',
  styleUrls: ['./pdfrooms.component.scss']
})
export class PdfroomsComponent implements OnInit {
  public build_type = environment.build_type;
  memberToggle:any  = 'pdf'
  startDate = null;
  endDate = null;
  from_date: any;
  notalowed:boolean = false
  newFromDate: any
  newToDate:any
  to_date: any
  customDate = false;
  endDateAll= ''
  startDateAll= ''
  link: any
  Alltime: boolean = true
  isLoading = false;
  shareLink:any
  @Input() PDFtype
  @Input() linkType
  constructor(public modalService: NgbModal,private http: HttpServiceService, public auth: AuthService, public ledgerService: LedgerService,public toast: ToastrService, public employeeService: EmployeeService, public translate: TranslateService, public datepipe: DatePipe,) { }

  ngOnInit(): void {
    //  console.log("type", this.linkType);
    
  }

  DateRangeSelection(value) {
    // this.isloading = true;
    this.notalowed = false
    this.startDate = '';
    this.endDate = '';
    if (value === 'today') {
      this.Alltime = false;
      this.from_date = '';
      this.to_date = '';

      // Today
      let today = new Date();
      this.startDate = this.datepipe.transform(today, 'dd-MM-yyyy');
      this.endDate = this.datepipe.transform(today, 'dd-MM-yyyy');
      this.customDate = false;
    } else if (value == 'week') {
      this.from_date = ''
      this.to_date = ''
      this.Alltime = false
      // Week
      let endDate = new Date();
      let firstDate = new Date();

      var curr = new Date(); // get current date
      var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
      var last = first + 6; // last day is the first day + 6
      
      var firstday = new Date(curr.setDate(first)).toUTCString().split(" ", 4);
      var lastday = new Date(curr.setDate(last)).toUTCString().split(" ", 4);
      firstday.pop[0]
      lastday.pop[0]
      this.startDate = this.datepipe.transform(firstday, 'yyyy-MM-dd')
      this.endDate = this.datepipe.transform(lastday, 'yyyy-MM-dd')
      this.customDate = false;
    } else if (value == 'month') {
      this.Alltime = false
      this.from_date = ''
      this.to_date = ''
      //Month
      var date = new Date();
      var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      this.startDate = this.datepipe.transform(firstDay, 'dd-MM-yyyy')
      this.endDate = this.datepipe.transform(lastDay, 'dd-MM-yyyy')
      this.customDate = false;
    } else if (value == 'year') {
      this.from_date = ''
      this.to_date = ''
      this.Alltime = false
      //Year
      var d = new Date();
      var n = d.getFullYear();
      this.startDate = `${n}-01-01`;
      this.endDate = `${n}-12-31`;

      this.customDate = false;
    } else if (value == 'allTime') {
      this.Alltime = true
      this.from_date = ''
      this.to_date = ''
      this.startDateAll = "";
      this.endDateAll = "";
      this.startDate = "all";
      this.endDate = "all";
      this.customDate = false;
    } else if (value == 'fromTo') {
        this.customDate = true;
        this.Alltime = false
        this.notalowed = true
    }
  }
  
  setFromToDate(value) {
    if (value === 'from') {
      let formattedFromDay = this.from_date.day < 10 ? '0' + this.from_date.day : this.from_date.day;
      let formattedFromMonth = this.from_date.month < 10 ? '0' + this.from_date.month : this.from_date.month;
      this.newFromDate = formattedFromMonth + '-' + formattedFromDay + '-' + this.from_date.year;
      this.startDate = this.newFromDate;
      this.to_date = '';
    } else {
      let formattedToDay = this.to_date.day < 10 ? '0' + this.to_date.day : this.to_date.day;
      let formattedToMonth = this.to_date.month < 10 ? '0' + this.to_date.month : this.to_date.month;
      this.newToDate = formattedToMonth + '-' + formattedToDay + '-' + this.to_date.year;
  
      if (this.newFromDate > this.newToDate) {
        let toast;
        this.translate.get('From date cannot be greater than To Date').subscribe((data: any) => {
          toast = data;
        });
        this.toast.error(toast);
        this.notalowed = true;
      } else {
        this.endDate = this.newToDate;
        this.notalowed = false;
      }
    }
  }
  
  
  downloadPDFNow() {
    this.link = 'False'
    if(this.Alltime === false){
      if(this.PDFtype === 'cash'){
        this.isLoading = true;
        this.http
          .downloadCashRoomPDF(
            this.startDate,
            this.endDate,
            this.link
          )
          .subscribe((data: ArrayBuffer) => {
            let blob = new Blob([data], { type: 'application/pdf' });
            var downloadURL = URL.createObjectURL(blob);
            var link = document.createElement('a');
            link.href = downloadURL;
            let item 
            this.translate.get('Cash').subscribe((data: any) => {
              item =  data;
            });
            link.download = item + '_' + this.auth.userData.emp_info.Business.business_name;
            link.click();
            this.isLoading = false;
          },
          (err) => {
            let toast;
            this.translate.get('No Transaction Found').subscribe((data: any) => {
                toast =  data;
              });
            this.toast.warning(toast)
            let blob = new Blob([err], { type: 'application/pdf' });
            //  console.log("Error is", blob, err);
            
            this.isLoading = false;
          });
      }
      if(this.PDFtype === 'sale'){
        this.isLoading = true;
        this.http
          .downloadSaleRoomPDF(
            this.startDate,
            this.endDate,
            this.link
          )
          .subscribe((data: ArrayBuffer) => {
            let blob = new Blob([data], { type: 'application/pdf' });
            var downloadURL = URL.createObjectURL(blob);
            var link = document.createElement('a');
            link.href = downloadURL;
            let item 
            this.translate.get('Sale').subscribe((data: any) => {
              item =  data;
            });
            link.download = item + '_' + this.auth.userData.emp_info.Business.business_name;
            link.click();
            this.isLoading = false;
          },
          (error) => {
            let toast;
            this.translate.get('No Transaction Found').subscribe((data: any) => {
                toast =  data;
              });
            this.toast.warning(toast)
            this.isLoading = false;
          });
      }
      if(this.PDFtype === 'bank'){
        this.isLoading = true;
        this.http
          .downloadBankRoomPDF(
            this.ledgerService.bankRoomID,
            this.startDate,
            this.endDate,
            this.link
          )
          .subscribe((data: ArrayBuffer) => {
            let blob = new Blob([data], { type: 'application/pdf' });
            var downloadURL = URL.createObjectURL(blob);
            var link = document.createElement('a');
            link.href = downloadURL;
            let item 
            this.translate.get('Bank').subscribe((data: any) => {
              item =  data;
            });
            link.download = item + '_' + this.auth.userData.emp_info.Business.business_name;
            link.click();
            this.isLoading = false;
          },
          (error) => {
            let toast;
            this.translate.get('No Transaction Found').subscribe((data: any) => {
                toast =  data;
              });
            this.toast.warning(toast)
            this.isLoading = false;
          });
      }
      
      if(this.PDFtype === 'purchase'){
        this.isLoading = true;
        this.http
          .downloadPurchaseRoomPDF(
            this.startDate,
            this.endDate,
            this.link
          )
          .subscribe((data: ArrayBuffer) => {
            let blob = new Blob([data], { type: 'application/pdf' });
            var downloadURL = URL.createObjectURL(blob);
            var link = document.createElement('a');
            link.href = downloadURL;
            let item 
            this.translate.get('Purchase').subscribe((data: any) => {
              item =  data;
            });
            link.download = item + '_' + this.auth.userData.emp_info.Business.business_name;
            link.click();
            this.isLoading = false;
          },
          (error) => {
            let toast;
            this.translate.get('No Transaction Found').subscribe((data: any) => {
                toast =  data;
              });
            this.toast.warning(toast)
            this.isLoading = false;
          });
      }
      if(this.PDFtype === 'expense'){
        this.isLoading = true;
        this.http
          .downloadExpenseRoomPDF(
            this.startDate,
            this.endDate,
            this.link
          )
          .subscribe((data: ArrayBuffer) => {
            let blob = new Blob([data], { type: 'application/pdf' });
            var downloadURL = URL.createObjectURL(blob);
            var link = document.createElement('a');
            link.href = downloadURL;
            let item 
            this.translate.get('Expense').subscribe((data: any) => {
              item =  data;
            });
            link.download = item + '_' + this.auth.userData.emp_info.Business.business_name;
            link.click();
            this.isLoading = false;
          },
          (error) => {
            let toast;
            this.translate.get('No Transaction Found').subscribe((data: any) => {
                toast =  data;
              });
            this.toast.warning(toast)
            this.isLoading = false;
          });
      }
      if(this.PDFtype === 'invPro'){
        this.isLoading = true;
        this.http
          .downloadInvSinProPDF(
            this.startDate,
            this.endDate, 
            this.ledgerService.ProDataByRef.reference_room_data.stock_in_id,
            this.link
          )
          .subscribe((data: ArrayBuffer) => {
            let blob = new Blob([data], { type: 'application/pdf' });
            var downloadURL = URL.createObjectURL(blob);
            var link = document.createElement('a');
            link.href = downloadURL;
            let item 
            this.translate.get('Cash').subscribe((data: any) => {
              item =  data;
            });
            link.download = item + '_' + this.ledgerService.productStock.product_data.product_name;
            link.click();
            this.isLoading = false;
          },
          (error) => {
            let toast;
            this.translate.get('No Transaction Found').subscribe((data: any) => {
                toast =  data;
              });
            this.toast.warning(toast)
            this.isLoading = false;
          });
      }
      if(this.PDFtype === 'invProStock'){
        this.isLoading = true;
        this.http
          .downloadInvProRoomPDF(
            this.startDate,
            this.endDate,
            this.ledgerService.productStock.product_data.id,
            this.link
          )
          .subscribe((data: ArrayBuffer) => {
            let blob = new Blob([data], { type: 'application/pdf' });
            var downloadURL = URL.createObjectURL(blob);
            var link = document.createElement('a');
            link.href = downloadURL;
            let item 
            this.translate.get('Inventory').subscribe((data: any) => {
              item =  data;
            });
            link.download = item + '_' +   this.ledgerService.selectedref;
            link.click();
            this.isLoading = false;
          },
          (error) => {
            let toast;
            this.translate.get('No Transaction Found').subscribe((data: any) => {
                toast =  data;
              });
            this.toast.warning(toast)
            this.isLoading = false;
          });
      }
    }
    if(this.Alltime === true){
      if(this.PDFtype === 'sale'){
        this.isLoading = true;
        this.http
          .downloadSaleRoomPDF(
            this.startDateAll,
            this.endDateAll,
            this.link
          )
          .subscribe((data: ArrayBuffer) => {
            let blob = new Blob([data], { type: 'application/pdf' });
            var downloadURL = URL.createObjectURL(blob);
            var link = document.createElement('a');
            link.href = downloadURL;
            let item 
            this.translate.get('Sale').subscribe((data: any) => {
              item =  data;
            });
            link.download = 'Sale Summary';
            link.click();
            this.isLoading = false;
          },
          (error) => {
            let toast;
            this.translate.get('No Transaction Found').subscribe((data: any) => {
                toast =  data;
              });
            this.toast.warning(toast)
            this.isLoading = false;
          });
      }
    
      if(this.PDFtype === 'purchase'){
        this.isLoading = true;
        this.http
          .downloadPurchaseRoomPDF(
            this.startDateAll,
            this.endDateAll,
            this.link
          )
          .subscribe((data: ArrayBuffer) => {
            let blob = new Blob([data], { type: 'application/pdf' });
            var downloadURL = URL.createObjectURL(blob);
            var link = document.createElement('a');
            link.href = downloadURL;
            let item 
            this.translate.get('Purchase').subscribe((data: any) => {
              item =  data;
            });
            link.download = 'Purchase Summary';
            link.click();
            this.isLoading = false;
          },
          (error) => {
            let toast;
            this.translate.get('No Transaction Found').subscribe((data: any) => {
                toast =  data;
              });
            this.toast.warning(toast)
            this.isLoading = false;
          });
      }

      if(this.PDFtype === 'expense'){
        this.isLoading = true;
        this.http
          .downloadExpenseRoomPDF(
            this.startDateAll,
            this.endDateAll,
            this.link
          )
          .subscribe((data: ArrayBuffer) => {
            let blob = new Blob([data], { type: 'application/pdf' });
            var downloadURL = URL.createObjectURL(blob);
            var link = document.createElement('a');
            link.href = downloadURL;
            let item 
              this.translate.get('Expense').subscribe((data: any) => {
                item =  data;
              });
              link.download = 'Expense Summary';
            link.click();
            this.isLoading = false;
          },
          (error) => {
            let toast;
            this.translate.get('No Transaction Found').subscribe((data: any) => {
                toast =  data;
              });
            this.toast.warning(toast)
            this.isLoading = false;
          });
      }

      if(this.PDFtype === 'invPro'){
        this.isLoading = true;
        this.http
          .downloadInvSinProPDF(
            this.startDate,
            this.endDate,
            this.ledgerService.ProDataByRef.reference_room_data.stock_in_id,
            this.link
          )
          .subscribe((data: ArrayBuffer) => {
            let blob = new Blob([data], { type: 'application/pdf' });
            var downloadURL = URL.createObjectURL(blob);
            var link = document.createElement('a');
            link.href = downloadURL;
            let item 
            this.translate.get('Inventory').subscribe((data: any) => {
              item =  data;
            });
            link.download = item + '_' +  this.ledgerService.productStock.product_data.product_name;
            // link.download = 'Inventory_Report_Prroduct-' + this.ledgerService.productStock.product_data.product_name;
            link.click();
            this.isLoading = false;
          },
          (error) => {
            let toast;
            this.translate.get('No Transaction Found').subscribe((data: any) => {
                toast =  data;
              });
            this.toast.warning(toast)
            this.isLoading = false;
          });
      }
      
      if(this.PDFtype === 'invProStock'){
        this.isLoading = true;
        this.http
          .downloadInvProRoomPDF(
            this.startDate,
            this.endDate,
            this.ledgerService.productStock.product_data.id,
            this.link
          )
          .subscribe((data: ArrayBuffer) => {
            let blob = new Blob([data], { type: 'application/pdf' });
            var downloadURL = URL.createObjectURL(blob);
            var link = document.createElement('a');
            link.href = downloadURL;
            let item 
            this.translate.get('Inventory').subscribe((data: any) => {
              item =  data;
            });
            link.download = item + '_' +   this.ledgerService.selectedref;
            link.click();
            this.isLoading = false;
          },
          (error) => {
            let toast;
              this.translate.get('No Transaction Found').subscribe((data: any) => {
                  toast =  data;
                });
              this.toast.warning(toast)
            this.isLoading = false;
          });
      }
    } 
  }
}
