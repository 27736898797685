// import { Component, Input, OnInit } from '@angular/core';
// import { NgbCarouselConfig, NgbModal,NgbCarousel } from '@ng-bootstrap/ng-bootstrap';
// import { AuthService } from '../../services/auth.service';
// import { HttpServiceService } from '../../services/http/http-service.service';

// @Component({
//   selector: 'app-tour-generic-model',
//   templateUrl: './tour-generic-model.component.html',
//   styleUrls: ['./tour-generic-model.component.scss']
// })
// export class TourGenericModelComponent implements OnInit {
//   @Input() arrayInput: any;
//   @Input() type: any;
//   @Input() modal: any;
//   constructor(private modalService: NgbModal,
//     public auth: AuthService,
//     private http: HttpServiceService,
//     config: NgbCarouselConfig) {
//       config.showNavigationArrows = true;
//       config.showNavigationIndicators = true;
//       config.interval = 1500;
//      }

//   ngOnInit(): void {
//   }
//   tourSkip(skip: any = '') {
//     localStorage.setItem(skip, 'true');
// }
// }

// import { Component, Input, OnInit } from '@angular/core';
// import { NgbCarouselConfig, NgbModal, NgbCarousel } from '@ng-bootstrap/ng-bootstrap';
// import { AuthService } from '../../services/auth.service';
// import { HttpServiceService } from '../../services/http/http-service.service';

// @Component({
//   selector: 'app-tour-generic-model',
//   templateUrl: './tour-generic-model.component.html',
//   styleUrls: ['./tour-generic-model.component.scss']
// })
// export class TourGenericModelComponent implements OnInit {
//   @Input() arrayInput: any;
//   @Input() type: any;
//   @Input() modal: any;
//   carousel: NgbCarousel; // Declare carousel property
//   constructor(private modalService: NgbModal,
//     public auth: AuthService,
//     private http: HttpServiceService,
//     config: NgbCarouselConfig) {
//       config.showNavigationArrows = true;
//       config.interval = 4000;
//   }

//   ngOnInit(): void {
//   }

//   tourSkip(skip: any = '') {
//     localStorage.setItem(skip, 'true');
//   }
// }

import { Component, Input, OnInit, Inject } from '@angular/core';
import { NgbCarouselConfig, NgbModal, NgbCarousel } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../services/auth.service';
import { HttpServiceService } from '../../services/http/http-service.service';
import { DOCUMENT } from '@angular/common'; // Import DOCUMENT token

@Component({
  selector: 'app-tour-generic-model',
  templateUrl: './tour-generic-model.component.html',
  styleUrls: ['./tour-generic-model.component.scss']
})
export class TourGenericModelComponent implements OnInit {
  @Input() arrayInput: any;
  @Input() type: any;
  @Input() modal: any;
  carousel: NgbCarousel;

  constructor(private modalService: NgbModal,
    public auth: AuthService,
    private http: HttpServiceService,
    config: NgbCarouselConfig,
    @Inject(DOCUMENT) private document: Document) { // Inject DOCUMENT token
      config.showNavigationArrows = true;
      config.interval = 4000;
  }

  ngOnInit(): void {
    this.document.addEventListener('visibilitychange', this.handleVisibilityChange.bind(this));
  }

  ngOnDestroy(): void {
    this.document.removeEventListener('visibilitychange', this.handleVisibilityChange.bind(this));
  }

  handleVisibilityChange() {
    if (this.document.hidden) {
      this.carousel.pause();
    } else {
      this.carousel.cycle();
    }
  }

  tourSkip(skip: any = '') {
    localStorage.setItem(skip, 'true');
  }
}
