import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/services/auth.service';
import { EmployeeService } from 'src/app/shared/services/employee.service';
import { HttpServiceService } from 'src/app/shared/services/http/http-service.service';
import { LedgerService } from 'src/app/shared/services/ledger.service';
import { environment } from 'src/environments/environment.prod';
import { saveAs } from 'file-saver';


@Component({
  selector: 'app-allpdf-rooms',
  templateUrl: './allpdf-rooms.component.html',
  styleUrls: ['./allpdf-rooms.component.scss']
})
export class AllpdfRoomsComponent implements OnInit {
  public build_type = environment.build_type;
  memberToggle:any  = 'pdf'
  startDate = null;
  endDate = null;
  from_date: any;
  notalowed:boolean = false
  newFromDate: any
  newToDate:any
  to_date: any
  customDate = false;
  endDateAll= ''
  startDateAll= ''
  link: any
  Alltime: boolean = true
  isLoading = false;
  shareLink:any
  @Input() PDFtype
  @Input() linkType
  constructor(public modalService: NgbModal,private http: HttpServiceService, public auth: AuthService, public ledgerService: LedgerService,public toast: ToastrService, public employeeService: EmployeeService, public translate: TranslateService, public datepipe: DatePipe,) { }

  ngOnInit(): void {
    //  console.log("type", this.linkType);
    
  }
  MemberToggle(value){
    this.customDate = false;
    this.memberToggle = value
    this.from_date = ''
    this.to_date = ''
  }
  copyClipBoard() {
    const selBox = document.createElement('textarea');
    selBox.value = this.shareLink;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    let toast;
    this.translate.get('Link copied to clipboard').subscribe((data: any) => {
      toast = data;
    });
    this.toast.success(toast);
  }
  DateRangeSelection(value) {
    // this.isloading = true;
    this.notalowed = false
    this.startDate = '';
    this.endDate = '';

    if (value == 'week') {
      this.from_date = ''
      this.to_date = ''
      this.Alltime = false
      // Week
      let endDate = new Date();
      let firstDate = new Date();

      var curr = new Date(); // get current date
      var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
      var last = first + 6; // last day is the first day + 6
      
      var firstday = new Date(curr.setDate(first)).toUTCString().split(" ", 4);
      var lastday = new Date(curr.setDate(last)).toUTCString().split(" ", 4);
      firstday.pop[0]
      lastday.pop[0]
      this.startDate = this.datepipe.transform(firstday, 'yyyy-MM-dd')
      this.endDate = this.datepipe.transform(lastday, 'yyyy-MM-dd')
      //  console.log("date",this.startDate, this.endDate);





      // firstDate.setDate(firstDate.getDate() - 6);

      // this.startDate = firstDate.toISOString().slice(0, 10);
      // this.endDate = endDate.toISOString().slice(0, 10);

      //  console.log('start and end date', this.startDate, this.endDate);
      this.customDate = false;
    } else if (value == 'month') {
      this.Alltime = false
      this.from_date = ''
      this.to_date = ''
      //Month
      var date = new Date();
      var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      // var startMonth = firstDay.split()
      //  console.log("montj", this.datepipe.transform(firstDay, 'yyyy-MM-dd'),lastDay);
      this.startDate = this.datepipe.transform(firstDay, 'yyyy-MM-dd')
      this.endDate = this.datepipe.transform(lastDay, 'yyyy-MM-dd')



      // let date = new Date();

      // let endDate = new Date(date.getFullYear(), date.getMonth(), 1);
      // let firstDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);

      // this.startDate = firstDate;
      // this.endDate = endDate;

      // this.startDate = endDate.toISOString().slice(0, 10);
      // this.endDate = firstDate.toISOString().slice(0, 10);

      //  console.log('start and end date', this.startDate, this.endDate);

      this.customDate = false;
    } else if (value == 'year') {
      this.from_date = ''
      this.to_date = ''
      this.Alltime = false
      //Year
      var d = new Date();
      var n = d.getFullYear();
      this.startDate = `${n}-01-01`;
      this.endDate = `${n}-12-31`;
      //  console.log('start and end date', this.startDate, this.endDate);

      this.customDate = false;
    } else if (value == 'allTime') {
      this.Alltime = true
      this.from_date = ''
      this.to_date = ''
      // All
      this.startDateAll = "";
      this.endDateAll = "";
      this.startDate = "all";
      this.endDate = "all";
      this.customDate = false;
      //  console.log('start and end date', this.startDate, this.endDate);
    } else if (value == 'fromTo') {
        this.customDate = true;
        this.Alltime = false
        this.notalowed = true
    }
  }
  
  setFromToDate(value) {
    
    if(value === 'from'){
      let Fromdate = this.from_date.year + '-' +  this.from_date.month + '-' +  this.from_date.day
      if(this.from_date.month < 10 && this.from_date.day < 10){
        this.newFromDate = this.from_date.year + '-' + '0' + this.from_date.month + '-' +  '0' + this.from_date.day

      }
      else if(this.from_date.month < 10 && this.from_date.day > 9){
          this.newFromDate = this.from_date.year + '-' + '0' + this.from_date.month + '-' + this.from_date.day
      }
      else if(this.from_date.month > 9 && this.from_date.day < 10){

        this.newFromDate = this.from_date.year + '-' + this.from_date.month + '-' + '0' + this.from_date.day
      }
      else{
        this.newFromDate = this.from_date.year + '-' + this.from_date.month + '-' + this.from_date.day
      }
      this.startDate = this.newFromDate;
      this.to_date = ''
    }
    else{
      
      if(this.to_date.month < 10 && this.to_date.day < 10){
        this.newToDate = this.to_date.year + '-' + '0' + this.to_date.month + '-' +  '0' + this.to_date.day

      }
      else if(this.to_date.month < 10 && this.to_date.day > 9){
          this.newToDate = this.to_date.year + '-' + '0' + this.to_date.month + '-' + this.to_date.day
      }
      else if(this.to_date.month > 9 && this.to_date.day < 10){

        this.newToDate = this.to_date.year + '-' + this.to_date.month + '-' + '0' + this.to_date.day
      }
      else{
        this.newToDate = this.to_date.year + '-' + this.to_date.month + '-' + this.to_date.day
      }
      let tomdate = this.to_date.year + '-' +  this.to_date.month + '-' +  this.to_date.day
      if(this.newFromDate > this.newToDate){
        let toast;
      this.translate.get('From date cannot be grater than To Date').subscribe((data: any) => {
          toast =  data;
        });
      this.toast.error(toast)
        this.notalowed = true
      }
      else{
        this.endDate = this.newToDate;
        this.notalowed = false
      }
    }
    

    // if (value == 'fromTo') {
    //   if( this.startDate > this.endDate){
    //     this.toast.error('From date cannot be grater than To Date');
    //   }else {
    //     this.customDate = true;
    //   }
    // }
    //  console.log('start and end date', this.startDate, this.endDate);
  }
  downloadPDFNow() {
    this.link = 'False'
    if(this.Alltime === false){
      if(this.PDFtype === 'employee'){
        this.isLoading = true;
        this.http
          .downloadEmployeeTransectionRoomPDF(
            this.employeeService.EmployeePaySlip.emp_details.id,
            this.startDate,
            this.endDate,
            this.link
          )
          .subscribe((data: ArrayBuffer) => {
            let blob = new Blob([data], { type: 'application/pdf' });
            var downloadURL = URL.createObjectURL(blob);
            var link = document.createElement('a');
            link.href = downloadURL;
            let item 
            this.translate.get('Employee Ledger').subscribe((data: any) => {
              item =  data;
            });
            link.download = item + '_' + this.auth.userData.emp_info.Business.business_name;
            link.click();
            this.isLoading = false;
          },
          (error) => {
            let toast;
            this.translate.get('No Transaction Found').subscribe((data: any) => {
                toast =  data;
              });
            this.toast.warning(toast)
            this.isLoading = false;
          });
      }
      if(this.PDFtype === 'cash'){
        this.isLoading = true;
        this.http
          .downloadCashRoomPDF(
            this.startDate,
            this.endDate,
            this.link
          )
          .subscribe((data: ArrayBuffer) => {
            let blob = new Blob([data], { type: 'application/pdf' });
            var downloadURL = URL.createObjectURL(blob);
            var link = document.createElement('a');
            link.href = downloadURL;
            let item 
            this.translate.get('Cash').subscribe((data: any) => {
              item =  data;
            });
            link.download = item + '_' + this.auth.userData.emp_info.Business.business_name;
            link.click();
            this.isLoading = false;
          },
          (err) => {
            let toast;
            this.translate.get('No Transaction Found').subscribe((data: any) => {
                toast =  data;
              });
            this.toast.warning(toast)
            let blob = new Blob([err], { type: 'application/pdf' });
            
            this.isLoading = false;
          });
      }
      if(this.PDFtype === 'sale'){
        this.isLoading = true;
        this.http
          .downloadSaleRoomPDF(
            this.startDateAll,
            this.endDateAll,
            this.link
          )
          .subscribe((data: ArrayBuffer) => {
            let blob = new Blob([data], { type: 'application/pdf' });
            var downloadURL = URL.createObjectURL(blob);
            var link = document.createElement('a');
            link.href = downloadURL;
            let item 
            this.translate.get('Sale').subscribe((data: any) => {
              item =  data;
            });
            link.download = item + '_' + this.auth.userData.emp_info.Business.business_name;
            link.click();
            this.isLoading = false;
          },
          (error) => {
            let toast;
            this.translate.get('No Transaction Found').subscribe((data: any) => {
                toast =  data;
              });
            this.toast.warning(toast)
            this.isLoading = false;
          });
      }
      if(this.PDFtype === 'bank'){
        this.isLoading = true;
        this.http
          .downloadBankRoomPDF(
            this.ledgerService.bankRoomID,
            this.startDate,
            this.endDate,
            this.link
          )
          .subscribe((data: ArrayBuffer) => {
            let blob = new Blob([data], { type: 'application/pdf' });
            var downloadURL = URL.createObjectURL(blob);
            var link = document.createElement('a');
            link.href = downloadURL;
            let item 
            this.translate.get('Bank').subscribe((data: any) => {
              item =  data;
            });
            link.download = item + '_' + this.auth.userData.emp_info.Business.business_name;
            link.click();
            this.isLoading = false;
          },
          (error) => {
            let toast;
            this.translate.get('No Transaction Found').subscribe((data: any) => {
                toast =  data;
              });
            this.toast.warning(toast)
            this.isLoading = false;
          });
      }
      
      if(this.PDFtype === 'purchase'){
        this.isLoading = true;
        this.http
          .downloadPurchaseRoomPDF(
            this.startDate,
            this.endDate,
            this.link
          )
          .subscribe((data: ArrayBuffer) => {
            let blob = new Blob([data], { type: 'application/pdf' });
            var downloadURL = URL.createObjectURL(blob);
            var link = document.createElement('a');
            link.href = downloadURL;
            let item 
            this.translate.get('Purchase').subscribe((data: any) => {
              item =  data;
            });
            link.download = item + '_' + this.auth.userData.emp_info.Business.business_name;
            link.click();
            this.isLoading = false;
          },
          (error) => {
            let toast;
            this.translate.get('No Transaction Found').subscribe((data: any) => {
                toast =  data;
              });
            this.toast.warning(toast)
            this.isLoading = false;
          });
      }
      if(this.PDFtype === 'expense'){
        this.isLoading = true;
        this.http
          .downloadExpenseRoomPDF(
            this.startDate,
            this.endDate,
            this.link
          )
          .subscribe((data: ArrayBuffer) => {
            let blob = new Blob([data], { type: 'application/pdf' });
            var downloadURL = URL.createObjectURL(blob);
            var link = document.createElement('a');
            link.href = downloadURL;
            let item 
            this.translate.get('Expense').subscribe((data: any) => {
              item =  data;
            });
            link.download = item + '_' + this.auth.userData.emp_info.Business.business_name;
            link.click();
            this.isLoading = false;
          },
          (error) => {
            let toast;
            this.translate.get('No Transaction Found').subscribe((data: any) => {
                toast =  data;
              });
            this.toast.warning(toast)
            this.isLoading = false;
          });
      }
      if(this.PDFtype === 'invPro'){
        this.isLoading = true;
        this.http
          .downloadInvSinProPDF(
            this.startDate,
            this.endDate, 
            this.ledgerService.ProDataByRef.reference_room_data.stock_in_id,
            this.link
          )
          .subscribe((data: ArrayBuffer) => {
            let blob = new Blob([data], { type: 'application/pdf' });
            var downloadURL = URL.createObjectURL(blob);
            var link = document.createElement('a');
            link.href = downloadURL;
            let item 
            this.translate.get('Cash').subscribe((data: any) => {
              item =  data;
            });
            link.download = item + '_' + this.ledgerService.productStock.product_data.product_name;
            link.click();
            this.isLoading = false;
          },
          (error) => {
            let toast;
            this.translate.get('No Transaction Found').subscribe((data: any) => {
                toast =  data;
              });
            this.toast.warning(toast)
            this.isLoading = false;
          });
      }
      if(this.PDFtype === 'invProStock'){
        this.isLoading = true;
        this.http
          .downloadInvProRoomPDF(
            this.startDate,
            this.endDate,
            this.ledgerService.productStock.product_data.id,
            this.link
          )
          .subscribe((data: ArrayBuffer) => {
            let blob = new Blob([data], { type: 'application/pdf' });
            var downloadURL = URL.createObjectURL(blob);
            var link = document.createElement('a');
            link.href = downloadURL;
            let item 
            this.translate.get('Inventory').subscribe((data: any) => {
              item =  data;
            });
            link.download = item + '_' +   this.ledgerService.selectedref;
            link.click();
            this.isLoading = false;
          },
          (error) => {
            let toast;
            this.translate.get('No Transaction Found').subscribe((data: any) => {
                toast =  data;
              });
            this.toast.warning(toast)
            this.isLoading = false;
          });
      }
    }
    if(this.Alltime === true){
      if(this.PDFtype === 'employee'){
        this.isLoading = true;
        this.http
          .downloadEmployeeTransectionRoomPDF(
            this.employeeService.EmployeePaySlip.emp_details.id,
            this.startDate,
            this.endDate,
            this.link
          )
          .subscribe((data: ArrayBuffer) => {
            let blob = new Blob([data], { type: 'application/pdf' });
            var downloadURL = URL.createObjectURL(blob);
            var link = document.createElement('a');
            link.href = downloadURL;
            let item 
            this.translate.get('Employee Ledger').subscribe((data: any) => {
              item =  data;
            });
            link.download = item + '_' + this.auth.userData.emp_info.Business.business_name;
            link.click();
            this.isLoading = false;
          },
          (error) => {
            let toast;
            this.translate.get('No Transaction Found').subscribe((data: any) => {
                toast =  data;
              });
            this.toast.warning(toast)
            this.isLoading = false;
          });
      }
      if(this.PDFtype === 'cash'){
      this.isLoading = true;
      this.http
        .downloadCashRoomPDF(
          this.startDateAll,
          this.endDateAll,
          this.link
        )
        .subscribe((data: ArrayBuffer) => {
          let blob = new Blob([data], { type: 'application/pdf' });
          var downloadURL = URL.createObjectURL(blob);
          var link = document.createElement('a');
          link.href = downloadURL;
          let item 
          this.translate.get('Cash').subscribe((data: any) => {
            item =  data;
          });
          link.download = item + '_' + this.auth.userData.emp_info.Business.business_name;
          link.click();
          this.isLoading = false;
        },
        (error) => {
          let toast;
            this.translate.get('No Transaction Found').subscribe((data: any) => {
                toast =  data;
              });
            this.toast.warning(toast)
          this.isLoading = false;
        });
    }
    if(this.PDFtype === 'bank'){
      this.isLoading = true;
      this.http
        .downloadBankRoomPDF(
          this.ledgerService.bankRoomID,
          this.startDateAll,
          this.endDateAll,
          this.link
        )
        .subscribe((data: ArrayBuffer) => {
          let blob = new Blob([data], { type: 'application/pdf' });
          var downloadURL = URL.createObjectURL(blob);
          var link = document.createElement('a');
          link.href = downloadURL;
          let item 
          this.translate.get('Bank').subscribe((data: any) => {
            item =  data;
          });
          link.download = item + '_' + this.auth.userData.emp_info.Business.business_name;
          link.click();
          this.isLoading = false;
        },
        (error) => {
          let toast;
          this.translate.get('No Transaction Found').subscribe((data: any) => {
              toast =  data;
            });
          this.toast.warning(toast)
          this.isLoading = false;
        });
    }
    if(this.PDFtype === 'sale'){
      this.isLoading = true;
      this.http
        .downloadSaleRoomPDF(
          this.startDateAll,
          this.endDateAll,
          this.link
        )
        .subscribe((data: ArrayBuffer) => {
          let blob = new Blob([data], { type: 'application/pdf' });
          var downloadURL = URL.createObjectURL(blob);
          var link = document.createElement('a');
          link.href = downloadURL;
          let item 
          this.translate.get('Sale').subscribe((data: any) => {
            item =  data;
          });
          link.download = item + '_' + this.auth.userData.emp_info.Business.business_name;
          link.click();
          this.isLoading = false;
        },
        (error) => {
          let toast;
          this.translate.get('No Transaction Found').subscribe((data: any) => {
              toast =  data;
            });
          this.toast.warning(toast)
          this.isLoading = false;
        });
    }
    
    if(this.PDFtype === 'purchase'){
      this.isLoading = true;
      this.http
        .downloadPurchaseRoomPDF(
          this.startDateAll,
          this.endDateAll,
          this.link
        )
        .subscribe((data: ArrayBuffer) => {
          let blob = new Blob([data], { type: 'application/pdf' });
          var downloadURL = URL.createObjectURL(blob);
          var link = document.createElement('a');
          link.href = downloadURL;
          let item 
          this.translate.get('Purchase').subscribe((data: any) => {
            item =  data;
          });
          link.download = item + '_' + this.auth.userData.emp_info.Business.business_name;
          link.click();
          this.isLoading = false;
        },
        (error) => {
          let toast;
          this.translate.get('No Transaction Found').subscribe((data: any) => {
              toast =  data;
            });
          this.toast.warning(toast)
          this.isLoading = false;
        });
    }
    if(this.PDFtype === 'expense'){
      this.isLoading = true;
      this.http
        .downloadExpenseRoomPDF(
          this.startDateAll,
          this.endDateAll,
          this.link
        )
        .subscribe((data: ArrayBuffer) => {
          let blob = new Blob([data], { type: 'application/pdf' });
          var downloadURL = URL.createObjectURL(blob);
          var link = document.createElement('a');
          link.href = downloadURL;
          let item 
            this.translate.get('Expense').subscribe((data: any) => {
              item =  data;
            });
            link.download = item + '_' + this.auth.userData.emp_info.Business.business_name;
          link.click();
          this.isLoading = false;
        },
        (error) => {
          let toast;
          this.translate.get('No Transaction Found').subscribe((data: any) => {
              toast =  data;
            });
          this.toast.warning(toast)
          this.isLoading = false;
        });
    }
    if(this.PDFtype === 'invPro'){
      this.isLoading = true;
      this.http
        .downloadInvSinProPDF(
          this.startDate,
          this.endDate,
          this.ledgerService.ProDataByRef.reference_room_data.stock_in_id,
          this.link
        )
        .subscribe((data: ArrayBuffer) => {
          let blob = new Blob([data], { type: 'application/pdf' });
          var downloadURL = URL.createObjectURL(blob);
          var link = document.createElement('a');
          link.href = downloadURL;
          let item 
          this.translate.get('Inventory').subscribe((data: any) => {
            item =  data;
          });
          link.download = item + '_' +  this.ledgerService.productStock.product_data.product_name;
          // link.download = 'Inventory_Report_Prroduct-' + this.ledgerService.productStock.product_data.product_name;
          link.click();
          this.isLoading = false;
        },
        (error) => {
          let toast;
          this.translate.get('No Transaction Found').subscribe((data: any) => {
              toast =  data;
            });
          this.toast.warning(toast)
          this.isLoading = false;
        });
    }
    if(this.PDFtype === 'invProStock'){
      this.isLoading = true;
      this.http
        .downloadInvProRoomPDF(
          this.startDate,
          this.endDate,
          this.ledgerService.productStock.product_data.id,
          this.link
        )
        .subscribe((data: ArrayBuffer) => {
          let blob = new Blob([data], { type: 'application/pdf' });
          var downloadURL = URL.createObjectURL(blob);
          var link = document.createElement('a');
          link.href = downloadURL;
          let item 
          this.translate.get('Inventory').subscribe((data: any) => {
            item =  data;
          });
          link.download = item + '_' +   this.ledgerService.selectedref;
          link.click();
          this.isLoading = false;
        },
        (error) => {
          let toast;
            this.translate.get('No Transaction Found').subscribe((data: any) => {
                toast =  data;
              });
            this.toast.warning(toast)
          this.isLoading = false;
        });
    }
  }
    
    
  }
  SharePDFNow(modal) {
    this.link = 'True'
    if(this.Alltime === false){
      if(this.PDFtype === 'employee'){
        this.isLoading = true;
        this.http
          .shareEmployeeTransectionRoomPDF(
            this.employeeService.EmployeePaySlip.emp_details.id,
            this.startDate,
            this.endDate,
            this.link
          )
          .subscribe((data) => {
            this.shareLink = data['pdf_link']
            //  console.log("Link is", this.shareLink);
            
            this.isLoading = false;
            this.modalService.open(modal, {
              centered: true,
              windowClass: 'ledger-participant',
              size: 'lg',
            });
          },
          (error) => {
            this.toast.error(error.error['msg']);
            this.isLoading = false;
          });
      }
      if(this.PDFtype === 'cash'){
        this.isLoading = true;
        this.http
          .shareCashRoomPDF(
            this.startDate,
            this.endDate,
            this.link
          )
          .subscribe((data) => {
            this.shareLink = data['pdf_link']
            this.isLoading = false;
            this.modalService.open(modal, {
              centered: true,
              windowClass: 'ledger-participant',
              size: 'lg',
            });
          },
          (error) => {
            this.toast.error(error.error['msg']);
            this.isLoading = false;
          });
      }
      if(this.PDFtype === 'sale'){
        this.isLoading = true;
        this.http
          .shareSaleRoomPDF(
            this.startDate,
            this.endDate,
            this.link
          )
          .subscribe((data) => {
            this.shareLink = data['pdf_link']
            this.isLoading = false;
            this.modalService.open(modal, {
              centered: true,
              windowClass: 'ledger-participant',
              size: 'lg',
            });
          },
          (error) => {
            this.toast.error(error.error['msg']);
            this.isLoading = false;
          });
      }
      if(this.PDFtype === 'bank'){
        this.isLoading = true;
        this.http
          .shareBankRoomPDF(
            this.ledgerService.bankRoomID,
            this.startDate,
            this.endDate,
            this.link
          )
          .subscribe((data) => {
            this.shareLink = data['pdf_link']
            this.isLoading = false;
            this.modalService.open(modal, {
              centered: true,
              windowClass: 'ledger-participant',
              size: 'lg',
            });
          },
          (error) => {
            this.toast.error(error.error['msg']);
            this.isLoading = false;
          });
      }
      if(this.PDFtype === 'purchase'){
        this.isLoading = true;
        this.http
          .sharePurchaseRoomPDF(
            this.startDate,
            this.endDate,
            this.link
          )
          .subscribe((data) => {
            this.shareLink = data['pdf_link']
            this.isLoading = false;
            this.modalService.open(modal, {
              centered: true,
              windowClass: 'ledger-participant',
              size: 'lg',
            });
          },
          (error) => {
            this.toast.error(error.error['msg']);
            this.isLoading = false;
          });
      }
      if(this.PDFtype === 'expense'){
        this.isLoading = true;
        this.http
          .shareExpenseRoomPDF(
            this.startDate,
            this.endDate,
            this.link
          )
          .subscribe((data) => {
            this.shareLink = data['pdf_link']
            this.isLoading = false;
            this.modalService.open(modal, {
              centered: true,
              windowClass: 'ledger-participant',
              size: 'lg',
            });
          },
          (error) => {
            this.toast.error(error.error['msg']);
            this.isLoading = false;
          });
      }
      if(this.PDFtype === 'invProStock'){
        this.isLoading = true;
        this.http
          .shareInvProRoomPDF(
            this.startDate,
            this.endDate, 
            this.ledgerService.productStock.product_data.id,
            this.link
          )
          .subscribe((data) => {
            this.shareLink = data['pdf_link']
            //  console.log("Share Link is", this.shareLink);
            
            this.isLoading = false;
            this.modalService.open(modal, {
              centered: true,
              windowClass: 'ledger-participant',
              size: 'lg',
            });
          },
          (error) => {
            this.toast.error(error.error['msg']);
            this.isLoading = false;
          });
      }
      if(this.PDFtype === 'invPro'){
        this.isLoading = true;
        this.http
          .shareInvSinProRoomPDF(
            this.startDate,
            this.endDate, 
            this.ledgerService.ProDataByRef.reference_room_data.stock_in_id,
            this.link
          )
          .subscribe((data) => {
            this.shareLink = data['pdf_link']
            //  console.log("Share Link is", this.shareLink);
            
            this.isLoading = false;
            this.modalService.open(modal, {
              centered: true,
              windowClass: 'ledger-participant',
              size: 'lg',
            });
          },
          (error) => {
            this.toast.error(error.error['msg']);
            this.isLoading = false;
          });
      }
    }
    if(this.Alltime === true){
      if(this.PDFtype === 'employee'){
        this.isLoading = true;
        this.http
          .shareEmployeeTransectionRoomPDF(
            this.employeeService.EmployeePaySlip.emp_details.id,
            this.startDate,
            this.endDate,
            this.link
          )
          .subscribe((data) => {
            this.shareLink = data['pdf_link']
            this.isLoading = false;
            this.modalService.open(modal, {
              centered: true,
              windowClass: 'ledger-participant',
              size: 'lg',
            });
          },
          (error) => {
            this.toast.error(error.error['msg']);
            this.isLoading = false;
          });
      }
      if(this.PDFtype === 'cash'){
      this.isLoading = true;
      this.http
        .shareCashRoomPDF(
          this.startDateAll,
          this.endDateAll,
          this.link
        )
        .subscribe((data) => {
          this.shareLink = data['pdf_link']
          this.isLoading = false;
          this.modalService.open(modal, {
            centered: true,
            windowClass: 'ledger-participant',
            size: 'lg',
          });
        },
        (error) => {
          this.toast.error(error.error['msg']);
          this.isLoading = false;
        });
    }
    if(this.PDFtype === 'bank'){
      this.isLoading = true;
      this.http
        .shareBankRoomPDF(
          this.ledgerService.bankRoomID,
          this.startDateAll,
          this.endDateAll,
          this.link
        )
        .subscribe((data) => {
          this.shareLink = data['pdf_link']
          this.isLoading = false;
          this.modalService.open(modal, {
            centered: true,
            windowClass: 'ledger-participant',
            size: 'lg',
          });
        },
        (error) => {
          this.toast.error(error.error['msg']);
          this.isLoading = false;
        });
    }
    if(this.PDFtype === 'sale'){
      this.isLoading = true;
      this.http
        .shareSaleRoomPDF(
          this.startDateAll,
          this.endDateAll,
          this.link
        )
        .subscribe((data) => {
          this.shareLink = data['pdf_link']
          this.isLoading = false;
          this.modalService.open(modal, {
            centered: true,
            windowClass: 'ledger-participant',
            size: 'lg',
          });
        },
        (error) => {
          this.toast.error(error.error['msg']);
          this.isLoading = false;
        });
    }
    
    if(this.PDFtype === 'purchase'){
      this.isLoading = true;
      this.http
        .sharePurchaseRoomPDF(
          this.startDateAll,
          this.endDateAll,
          this.link
        )
        .subscribe((data) => {
          this.shareLink = data['pdf_link']
          this.isLoading = false;
          this.modalService.open(modal, {
            centered: true,
            windowClass: 'ledger-participant',
            size: 'lg',
          });
        },
        (error) => {
          this.toast.error(error.error['msg']);
          this.isLoading = false;
        });
    }
    if(this.PDFtype === 'expense'){
      this.isLoading = true;
      this.http
        .shareExpenseRoomPDF(
          this.startDateAll,
          this.endDateAll,
          this.link
        )
        .subscribe((data) => {
          this.shareLink = data['pdf_link']
          this.isLoading = false;
          this.modalService.open(modal, {
            centered: true,
            windowClass: 'ledger-participant',
            size: 'lg',
          });
        },
        (error) => {
          this.toast.error(error.error['msg']);
          this.isLoading = false;
        });
    }
    if(this.PDFtype === 'invProStock'){
      this.isLoading = true;
      this.http
        .shareInvProRoomPDF(
          this.startDate,
          this.endDate,
          this.ledgerService.productStock.product_data.id,
          this.link
        )
        .subscribe((data) => {
          
          this.shareLink = data['pdf_link']
          //  console.log("Share Link is", this.shareLink);
          
          this.isLoading = false;
          this.modalService.open(modal, {
            centered: true,
            windowClass: 'ledger-participant',
            size: 'lg',
          });
        },
        (error) => {
          this.toast.error(error.error['msg']);
          this.isLoading = false;
        });
    }
    if(this.PDFtype === 'invPro'){
      this.isLoading = true;
      this.http
        .shareInvSinProRoomPDF(
          this.startDate,
          this.endDate,
          this.ledgerService.ProDataByRef.reference_room_data.stock_in_id,
          this.link
        )
        .subscribe((data) => {
          
          this.shareLink = data['pdf_link']
          //  console.log("Share Link is", this.shareLink);
          
          this.isLoading = false;
          this.modalService.open(modal, {
            centered: true,
            windowClass: 'ledger-participant',
            size: 'lg',
          });
        },
        (error) => {
          this.toast.error(error.error['msg']);
          this.isLoading = false;
        });
    }
  }
    
    
  }
  downloadCsvNow() {
    if(this.Alltime === false){
      if(this.PDFtype === 'employee'){
        this.isLoading = true;
        this.http
        .downloadEmployeeLedgerCSV(
          this.employeeService.EmployeePaySlip.emp_details.id,
          this.startDate,
          this.endDate,
        )
          .subscribe((data: ArrayBuffer) => {
            let blob = new Blob([data], { type: 'application/octet-stream' });
            var downloadURL = URL.createObjectURL(blob);
            var link = document.createElement('a');
            link.href = downloadURL;
            let item 
            this.translate.get('Employee Ledger').subscribe((data: any) => {
              item =  data;
            });
            link.download = item + '_' + this.auth.userData.emp_info.Business.business_name + '.xlsx';
            link.click();
            this.isLoading = false;
          },
          (error) => {
            let toast;
            this.translate.get('No Transaction Found').subscribe((data: any) => {
                toast =  data;
              });
            this.toast.warning(toast)
            this.isLoading = false;
          });
      }
      if(this.PDFtype === 'cash'){
        this.isLoading = true;
        this.http
        .downloadCashCSV(
          this.startDate,
          this.endDate
        )
          .subscribe((data: ArrayBuffer) => {
            let blob = new Blob([data], { type: 'application/octet-stream' });
            var downloadURL = URL.createObjectURL(blob);
            var link = document.createElement('a');
            link.href = downloadURL;
            let item 
            this.translate.get('Cash').subscribe((data: any) => {
              item =  data;
            });
            link.download = item + '_' + this.auth.userData.emp_info.Business.business_name + '.xlsx';
            link.click();
            this.isLoading = false;
          },
          (err) => {
            let toast;
            this.translate.get('No Transaction Found').subscribe((data: any) => {
                toast =  data;
              });
            this.toast.warning(toast)
            let blob = new Blob([err], { type: 'application/xlsx' });
            //  console.log("Error is", blob, err);
            
            this.isLoading = false;
          });
      }
      if(this.PDFtype === 'sale'){
        this.isLoading = true;
        this.http
        .downloadSaleCSV(
          this.startDate,
          this.endDate
        )
          .subscribe((data: ArrayBuffer) => {
            let blob = new Blob([data], { type: 'application/octet-stream' });
            var downloadURL = URL.createObjectURL(blob);
            var link = document.createElement('a');
            link.href = downloadURL;
            let item 
            this.translate.get('Sale').subscribe((data: any) => {
              item =  data;
            });
            link.download = item + '_' + this.auth.userData.emp_info.Business.business_name + '.xlsx';
            link.click();
            this.isLoading = false;
          },
          (error) => {
            let toast;
            this.translate.get('No Transaction Found').subscribe((data: any) => {
                toast =  data;
              });
            this.toast.warning(toast)
            this.isLoading = false;
          });
      }
      if(this.PDFtype === 'bank'){
        this.isLoading = true;
        this.http
          .downloadBankCSV(
            this.ledgerService.bankRoomID,
            this.startDate,
            this.endDate
          )
          .subscribe((data: ArrayBuffer) => {
            let blob = new Blob([data], { type: 'application/octet-stream' });
            var downloadURL = URL.createObjectURL(blob);
            var link = document.createElement('a');
            link.href = downloadURL;
            let item 
            this.translate.get('Bank').subscribe((data: any) => {
              item =  data;
            });
            link.download = item + '_' + this.auth.userData.emp_info.Business.business_name + '.xlsx';
            link.click();
            this.isLoading = false;
          },
          (error) => {
            let toast;
            this.translate.get('No Transaction Found').subscribe((data: any) => {
                toast =  data;
              });
            this.toast.warning(toast)
            this.isLoading = false;
          });
      }
      
      if(this.PDFtype === 'purchase'){
        this.isLoading = true;
        this.http
        .downloadPurchaseCSV(
          this.startDate,
          this.endDate
        )
          .subscribe((data: ArrayBuffer) => {
            let blob = new Blob([data], { type: 'application/octet-stream' });
            var downloadURL = URL.createObjectURL(blob);
            var link = document.createElement('a');
            link.href = downloadURL;
            let item 
            this.translate.get('Purchase').subscribe((data: any) => {
              item =  data;
            });
            link.download = item + '_' + this.auth.userData.emp_info.Business.business_name + '.xlsx';
            link.click();
            this.isLoading = false;
          },
          (error) => {
            let toast;
            this.translate.get('No Transaction Found').subscribe((data: any) => {
                toast =  data;
              });
            this.toast.warning(toast)
            this.isLoading = false;
          });
      }
      if(this.PDFtype === 'expense'){
        this.isLoading = true;
        this.http
        .downloadExpenseCSV(
          this.startDate,
          this.endDate
        )
          .subscribe((data: ArrayBuffer) => {
            let blob = new Blob([data], { type: 'application/octet-stream' });
            var downloadURL = URL.createObjectURL(blob);
            var link = document.createElement('a');
            link.href = downloadURL;
            let item 
            this.translate.get('Expense').subscribe((data: any) => {
              item =  data;
            });
            link.download = item + '_' + this.auth.userData.emp_info.Business.business_name + '.xlsx';
            link.click();
            this.isLoading = false;
          },
          (error) => {
            let toast;
            this.translate.get('No Transaction Found').subscribe((data: any) => {
                toast =  data;
              });
            this.toast.warning(toast)
            this.isLoading = false;
          });
      }
      if(this.PDFtype === 'invProStock'){
        this.isLoading = true;
        this.http
          .downloadInvenotyCSV(
            this.ledgerService.productStock.product_data.id,
            this.startDate,
            this.endDate, 
           
          )
          .subscribe((data: ArrayBuffer) => {
            let blob = new Blob([data], { type: 'application/octet-stream' });
            var downloadURL = URL.createObjectURL(blob);
            var link = document.createElement('a');
            link.href = downloadURL;
            let item 
            this.translate.get('Inventory').subscribe((data: any) => {
              item =  data;
            });
            link.download = item + '_' + this.ledgerService.selectedref  + '.xlsx';
            link.click();
            this.isLoading = false;
          },
          (error) => {
            let toast;
            this.translate.get('No Transaction Found').subscribe((data: any) => {
                toast =  data;
              });
            this.toast.warning(toast)
            this.isLoading = false;
          });
      }
      if(this.PDFtype === 'invPro'){
        this.isLoading = true;
        this.http
          .downloadInvenotysinCSV(
            this.ledgerService.ProDataByRef.reference_room_data.stock_in_id,
            this.startDate,
            this.endDate, 
           
          )
          .subscribe((data: ArrayBuffer) => {
            let blob = new Blob([data], { type: 'application/octet-stream' });
            var downloadURL = URL.createObjectURL(blob);
            var link = document.createElement('a');
            link.href = downloadURL;
            let item 
            this.translate.get('Inventory').subscribe((data: any) => {
              item =  data;
            });
            link.download = item + '_' + this.ledgerService.productStock.product_data.product_name + '.xlsx';
            link.click();
            this.isLoading = false;
          },
          (error) => {
            let toast;
            this.translate.get('No Transaction Found').subscribe((data: any) => {
                toast =  data;
              });
            this.toast.warning(toast)
            this.isLoading = false;
          });
      }
    }
    if(this.Alltime === true){
      if(this.PDFtype === 'employee'){
        this.isLoading = true;
        this.http
        .downloadEmployeeLedgerCSV(
          this.employeeService.EmployeePaySlip.emp_details.id,
          this.startDate = '',
          this.endDate = '',
        )
          .subscribe((data: ArrayBuffer) => {
            let blob = new Blob([data], { type: 'application/octet-stream' });
            var downloadURL = URL.createObjectURL(blob);
            var link = document.createElement('a');
            link.href = downloadURL;
            let item 
            this.translate.get('Employee Ledger').subscribe((data: any) => {
              item =  data;
            });
            link.download = item + '_' + this.auth.userData.emp_info.Business.business_name + '.xlsx';
            link.click();
            this.isLoading = false;
          },
          (error) => {
            let toast;
            this.translate.get('No Transaction Found').subscribe((data: any) => {
                toast =  data;
              });
            this.toast.warning(toast)
            this.isLoading = false;
          });
      }
      if(this.PDFtype === 'cash'){
      this.isLoading = true;
      this.http
      .downloadCashCSV(
        this.startDate = 'all',
        this.endDate = 'all',
      )
        .subscribe((data: ArrayBuffer) => {
          let blob = new Blob([data], { type: 'application/octet-stream' });
          var downloadURL = URL.createObjectURL(blob);
          var link = document.createElement('a');
          link.href = downloadURL;
          let item 
          this.translate.get('Cash').subscribe((data: any) => {
            item =  data;
          });
          link.download = item + '_' + this.auth.userData.emp_info.Business.business_name + '.xlsx';
          link.click();
          this.isLoading = false;
        },
        (error) => {
          let toast;
            this.translate.get('No Transaction Found').subscribe((data: any) => {
                toast =  data;
              });
            this.toast.warning(toast)
          this.isLoading = false;
        });
    }
    if(this.PDFtype === 'bank'){
      this.isLoading = true;
      this.http
      .downloadBankCSV(
        this.ledgerService.bankRoomID,
        this.startDate = 'all',
        this.endDate = 'all',
      )
        .subscribe((data: ArrayBuffer) => {
          let blob = new Blob([data], { type: 'application/octet-stream' });
          var downloadURL = URL.createObjectURL(blob);
          var link = document.createElement('a');
          link.href = downloadURL;
          let item 
            this.translate.get('Bank').subscribe((data: any) => {
              item =  data;
            });
            link.download = item + '_' + this.auth.userData.emp_info.Business.business_name + '.xlsx';
          link.click();
          this.isLoading = false;
        },
        (error) => {
          let toast;
            this.translate.get('No Transaction Found').subscribe((data: any) => {
                toast =  data;
              });
            this.toast.warning(toast)
          this.isLoading = false;
        });
    }
    if(this.PDFtype === 'sale'){
      this.isLoading = true;
      this.http
      .downloadSaleCSV(
        this.startDate = 'all',
        this.endDate = 'all',
      )
        .subscribe((data: ArrayBuffer) => {
          let blob = new Blob([data], { type: 'application/octet-stream' });
          var downloadURL = URL.createObjectURL(blob);
          var link = document.createElement('a');
          link.href = downloadURL;
          let item 
          this.translate.get('Sale').subscribe((data: any) => {
            item =  data;
          });
          link.download = item + '_' + this.auth.userData.emp_info.Business.business_name + '.xlsx';
          link.click();
          this.isLoading = false;
        },
        (error) => {
          let toast;
            this.translate.get('No Transaction Found').subscribe((data: any) => {
                toast =  data;
              });
            this.toast.warning(toast)
          this.isLoading = false;
        });
    }
    
    if(this.PDFtype === 'purchase'){
      this.isLoading = true;
      this.http
      .downloadPurchaseCSV(
        this.startDate = 'all',
        this.endDate = 'all',
      )
        .subscribe((data: ArrayBuffer) => {
          let blob = new Blob([data], { type: 'application/octet-stream' });
          var downloadURL = URL.createObjectURL(blob);
          var link = document.createElement('a');
          link.href = downloadURL;
          let item 
          this.translate.get('Purchase').subscribe((data: any) => {
            item =  data;
          });
          link.download = item + '_' + this.auth.userData.emp_info.Business.business_name + '.xlsx';
          link.click();
          this.isLoading = false;
        },
        (error) => {
          let toast;
          this.translate.get('No Transaction Found').subscribe((data: any) => {
              toast =  data;
            });
          this.toast.warning(toast)
          this.isLoading = false;
        });
    }
    if(this.PDFtype === 'expense'){
      this.isLoading = true;
      this.http
      .downloadExpenseCSV(
      this.startDate = 'all',
          this.endDate = 'all',
      )
        .subscribe((data: ArrayBuffer) => {
          let blob = new Blob([data], { type: 'application/octet-stream' });
          var downloadURL = URL.createObjectURL(blob);
          var link = document.createElement('a');
          link.href = downloadURL;
          let item 
          this.translate.get('Expense').subscribe((data: any) => {
            item =  data;
          });
          link.download = item + '_' + this.auth.userData.emp_info.Business.business_name + '.xlsx';
          link.click();
          this.isLoading = false;
        },
        (error) => {
          let toast;
          this.translate.get('No Transaction Found').subscribe((data: any) => {
              toast =  data;
            });
          this.toast.warning(toast)
          this.isLoading = false;
        });
    }
    if(this.PDFtype === 'invProStock'){
      this.isLoading = true;
      this.http
        .downloadInvenotyCSV(
          this.ledgerService.productStock.product_data.id,
          this.startDate = 'all',
          this.endDate = 'all',
        )
        .subscribe((data: ArrayBuffer) => {
          let blob = new Blob([data], { type: 'application/octet-stream' });
          var downloadURL = URL.createObjectURL(blob);
          var link = document.createElement('a');
          link.href = downloadURL;
          let item 
          this.translate.get('Inventory').subscribe((data: any) => {
            item =  data;
          });
          link.download = item + '_' + this.ledgerService.selectedref + '.xlsx';
          link.click();
          this.isLoading = false;
        },
        (error) => {
          let toast;
          this.translate.get('No Transaction Found').subscribe((data: any) => {
              toast =  data;
            });
          this.toast.warning(toast)
          this.isLoading = false;
        });
    }
    if(this.PDFtype === 'invPro'){
      this.isLoading = true;
      this.http
        .downloadInvenotysinCSV(
          this.ledgerService.ProDataByRef.reference_room_data.stock_in_id,
          this.startDate = 'all',
          this.endDate = 'all',
        )
        .subscribe((data: ArrayBuffer) => {
          let blob = new Blob([data], { type: 'application/octet-stream' });
          var downloadURL = URL.createObjectURL(blob);
          var link = document.createElement('a');
          link.href = downloadURL;
          let item 
          this.translate.get('Inventory').subscribe((data: any) => {
            item =  data;
          });
          link.download = item + '_' + this.ledgerService.productStock.product_data.product_name + '.xlsx';
          link.click();
          this.isLoading = false;
        },
        (error) => {
          let toast;
          this.translate.get('No Transaction Found').subscribe((data: any) => {
              toast =  data;
            });
          this.toast.warning(toast)
          this.isLoading = false;
        });
    }
  }

    
  //   if(this.Alltime === true){
  //     if(this.PDFtype === 'cash'){
  //       this.isLoading = true;
  //       this.http
  //         .downloadCashCSV(
  //           this.startDate,
  //           this.endDate
  //         )
  //         .subscribe((data:any) => {
  //           const blob = new Blob([data], { type: 'application/octet-stream' });
  //           // const url= window.URL.createObjectURL(blob);
  //           var downloadURL = URL.createObjectURL(blob);
    
  //           var link = document.createElement('a');
  //           link.href = downloadURL;
  //           link.download = 'Cash-.xlsx';
  //           link.click();
  //           this.isLoading = false;
  //         });
  //     }
  //   if(this.PDFtype === 'sale'){
  //     this.isLoading = true;
  //     this.http
  //       .downloadSaleRoomPDF(
  //         this.startDateAll,
  //         this.endDateAll
  //       )
  //       .subscribe((data: ArrayBuffer) => {
  //         let blob = new Blob([data], { type: 'application/pdf' });
  //         var downloadURL = URL.createObjectURL(blob);
  //         var link = document.createElement('a');
  //         link.href = downloadURL;
  //         link.download = 'CashRoom-.pdf';
  //         link.click();
  //         this.isLoading = false;
  //       });
  //   }
    
  //   if(this.PDFtype === 'purchase'){
  //     this.isLoading = true;
  //     this.http
  //       .downloadPurchaseRoomPDF(
  //         this.startDateAll,
  //         this.endDateAll
  //       )
  //       .subscribe((data: ArrayBuffer) => {
  //         let blob = new Blob([data], { type: 'application/pdf' });
  //         var downloadURL = URL.createObjectURL(blob);
  //         var link = document.createElement('a');
  //         link.href = downloadURL;
  //         link.download = 'CashRoom-.pdf';
  //         link.click();
  //         this.isLoading = false;
  //       });
  //   }
  //   if(this.PDFtype === 'expense'){
  //     this.isLoading = true;
  //     this.http
  //       .downloadExpenseRoomPDF(
  //         this.startDateAll,
  //         this.endDateAll
  //       )
  //       .subscribe((data: ArrayBuffer) => {
  //         let blob = new Blob([data], { type: 'application/pdf' });
  //         var downloadURL = URL.createObjectURL(blob);
  //         var link = document.createElement('a');
  //         link.href = downloadURL;
  //         link.download = 'CashRoom-.pdf';
  //         link.click();
  //         this.isLoading = false;
  //       });
  //   }
  // }
    
    
  }
}
