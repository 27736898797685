import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbCalendar, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LedgerService } from '../../services/ledger.service';
import { HttpServiceService } from '../../services/http/http-service.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { BillBookervice } from '../../services/billBook.service';
import { MarketService } from '../../services/market.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-globalitem',
  templateUrl: './globalitem.component.html',
  styleUrls: ['./globalitem.component.scss']
})
export class GlobalitemComponent implements OnInit {
  @ViewChild('addinvPro', { static: true })
  addInvModel: ElementRef;

  @ViewChild('addService', { static: true })
  addServiceModel: ElementRef;

  @ViewChild('AddCategoryname', { static: true })
  AddCategorynameModal: ElementRef;

  min = 1;
  defaultUomValue: 'piece';
  selectedUom: any; 
  marketVisibility: boolean = false;
  today = this.calendar.getToday();
  busCurrency: any;
  categoryName: any;
  uomtype: any;
  dateFormat: any;
  serId: any;
  isEditMode: boolean = false;
  currentlyEditingItemId: any;





  constructor(public router: Router, public auth: AuthService, public translate: TranslateService, private calendar: NgbCalendar, public bookService: BillBookervice,
    public datepipe: DatePipe, public ledgerService: LedgerService, public modalService: NgbModal, public marketService: MarketService,
    public toast: ToastrService, private http: HttpServiceService) { }

  ngOnInit(): void {
    this.busCurrency=this.auth.userData.emp_info.Business?.currency?.code;
    this.ledgerService.getnventoryPro();
    this.ledgerService.addInventory.patchValue({
     product_category: 1
   })
   this.categoryName='Finished Goods'
    this.busCurrency=this.auth.userData.emp_info.Business?.currency?.code;
    this.ledgerService.invnCategeory()
    this.ledgerService.stockcurrentPage = 1
    this.UOM();
    console.log("currency",this.auth.userData.emp_info.Business?.currency?.id )
    this.ledgerService.addInventory.patchValue({
      currency: this.auth.userData.emp_info.Business?.currency?.id,
    });
    if (this.auth.userData.emp_info.is_buissness_exist) {
      this.DateFormatepipe();
    }
  }

  DateFormatepipe() {
    if (this.auth.userData.emp_info.Business.default_date_format.id === 1) {
      this.dateFormat = 'dd-MM-yyyy';
    } else if (
      this.auth.userData.emp_info.Business.default_date_format.id === 2
    ) {
      this.dateFormat = 'MM-dd-yyyy';
    } else {
      this.dateFormat = 'yyyy-MM-dd';
    }
  }
 
  UOM() {
    this.http.getuomTypes().subscribe(res => {
      this.uomtype = res['data'];
      console.log(this.uomtype);
    },
      (error) => { 
        this.toast.error(error.error['msg']);
      }
    );
  }
  AddCategory(modal) {
    this.isEditMode = false;
    this.ledgerService.addProductCat.reset();
    this.modalService.open(modal, {
      centered: true,
      windowClass: 'shadedbG',
      size: 'sm',
    });
  }
//   openEditCategoryModal(item: any) {
//     this.isEditMode = true;
//     this.ledgerService.addProductCat.setValue({ title: item.title });
//     this.modalService.open(this.AddCategorynameModal, {
//         centered: true,
//         windowClass: 'shadedbG',
//         size: 'sm'
//     });
// }
openEditCategoryModal(item: any) {
  this.currentlyEditingItemId = item.id;
  this.isEditMode = true;
  this.ledgerService.addProductCat.setValue({ title: item.title });
  this.modalService.open(this.AddCategorynameModal, {
    centered: true,
    windowClass: 'shadedbG',
    size: 'sm'
  });
}

editCategory(modal) {
  if (this.ledgerService.addProductCat.valid) {
    this.http.editProductCat(this.ledgerService.addProductCat.value, this.currentlyEditingItemId).subscribe(
      res => {
        this.toast.success(res['detail'] || 'Category updated successfully!');
        console.log('Edit successful:', res);
        this.ledgerService.invnCategeory()
        modal.dismiss(); // Close the modal on success
      },
      error => {
        console.error('Failed to edit category:', error);
        this.toast.error('Failed to edit category: ' + (error.error['msg'] || 'Unknown error'));
      }
    );
  } else {
    this.toast.error('Please fill in all required fields correctly.');
  }
}

  categoryAddToExpenseForm(item, modal) {
    console.log(item);
    this.categoryName = item.title;
    this.ledgerService.addInventory.patchValue({
      product_category: item.id
    })
    this.ledgerService.addBundle.patchValue({
      product_category: item.id
    })
    modal.dismiss();
  }
  openInvGlobal() {
    debugger;
    this.selectedUom = 2;
    this.ledgerService.Edititem = false
    this.ledgerService.addInventory.patchValue({
      product_category: 1
    })
    this.categoryName='Finished Goods'
    if (this.auth.userData.emp_info.employee_permissions.edit_inventory === true) {
      this.ledgerService.addInventory.reset();
      this.ledgerService.addService.reset();
      this.ledgerService.previewUrl=[];
      this.ledgerService.invImg = ''
      this.ledgerService.addInventory.value.product_file = []
      this.ledgerService.addInventory.value.product_name = []
      this.ledgerService.addInventory.value.currency = this.auth.userData.emp_info.Business?.currency?.code;
      this.ledgerService.addInventory.patchValue({
        currency: this.auth.userData.emp_info.Business?.currency?.code,
        minimum_stock_level: 0
      });
      this.ledgerService.addService.patchValue({
        currency:  this.auth.userData.emp_info.Business?.currency?.code,
      });
      this.ledgerService.addService.patchValue({
        market_visibility: true,
      });
      this.ledgerService.addInventory.patchValue({
        product_category: 1
      });
      if (this.today.month < 10 && this.today.day < 10) {
        this.ledgerService.addInventory.patchValue({
          created_at: this.today.year + '-' + '0' + this.today.month + '-' + '0' + this.today.day
        });
      }
      else if (this.today.month < 10 && this.today.day > 9) {
        this.ledgerService.addInventory.patchValue({
          created_at: this.today.year + '-' + '0' + this.today.month + '-' + this.today.day
        });
      }
      else if (this.today.month > 9 && this.today.day < 10) {
        this.ledgerService.addInventory.patchValue({
          created_at: this.today.year + '-' + this.today.month + '-' + '0' + this.today.day
        });
      }
      else {
        this.ledgerService.addInventory.patchValue({
          created_at: this.today.year + '-' + this.today.month + '-' + this.today.day
        });
      }
      debugger
      // alert(this.ledgerService.ServiceSelected)
       if(this.ledgerService.ServiceSelected == false){
        // alert('product')
        this.modalService.open(this.addInvModel, {
          centered: true,
          windowClass: 'ledger-debit',
          size: 'lg',
        });
      }
      else if(this.ledgerService.ServiceSelected == true){
        // alert('service')
        this.modalService.open(this.addServiceModel, {
          centered: true,
          windowClass: 'ledger-debit',
          size: 'lg',
        });
      }
      
    }
    else {
      let toast;
      this.translate.get('You have no permission').subscribe((data: any) => {
        toast = data;
      });
      this.toast.error(toast)
    }
  }

  Namelimit(event:any){
    const maxLength = 48;
      const currentValue = event.target.value;
      
      if (currentValue.length == maxLength) {
          this.toast.error('Maximum allowed limit is 48');
          event.target.value = currentValue.slice(0, maxLength);
      }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    if (charCode === 48) {
      if (this.min < 1) {
        return false;
      }
    }
    return true;
  }

  updateMarketVisibility(value: string) {
    this.marketVisibility = value !== null && value !== '';
  }

  onChangeUom(id) {

    this.ledgerService.addInventory.patchValue({
      uom: id,
    })

  }
  EditPro() {
    this.ledgerService.Edititem = true;
    this.modalService.open(this.addInvModel, {
      centered: true,
      windowClass: 'ledger-debit',
      size: 'lg',
    });
  }

  EditServiceM() {
    this.ledgerService.Edititem = true;
    let fileArray = [];
     if (this.marketService.selectedProdq.product_file.length) {
      for (let item of this.marketService.selectedProdq.product_file) {
        fileArray.push(item.id)
      }
    }
    if (this.marketService.selectedProdq.product_file.length) {
      this.ledgerService.previewUrl = [];
      for (let item of this.marketService.selectedProdq.product_file) {
        this.ledgerService.previewUrl.push(item)
      }
    }
    // this.ledgerService.addService.patchValue(this.marketService.selectedProdq.id);
    this.ledgerService.addService.patchValue({
      unit_price: this.marketService.selectedProdq.unit_price,
      product_name: this.marketService.selectedProdq.product_name,
      reference_no: this.marketService.selectedProdq.reference_no,
      quantity: this.marketService.selectedProdq.quantity,
      currency: this.marketService.selectedProdq.currency.code,
      product_file: fileArray,
      sales_price:this.marketService.selectedProdq.sales_price,
      uom: this.marketService.selectedProdq.uom?.id,
      information: this.marketService.selectedProdq.information,
      market_visibility:this.marketService.selectedProdq.market_visibility,
      selling_price_per_unit: this.marketService.selectedProdq.selling_price_per_unit,
      service_status:this.marketService.selectedProdq.service_status,
      id: this.marketService.selectedProdq.id,
      })
   
    this.modalService.open(this.addServiceModel, {
      centered: true,
      windowClass: 'ledger-debit',
      size: 'lg',
    });
  }
  EditService() {

    this.ledgerService.Edititem = true;
    this.ledgerService.addService.patchValue(this.serId);
    this.ledgerService.addService.patchValue({
      currency: this.serId.currency.code,
      // product_room_id: data.id,
    })
   
    this.modalService.open(this.addServiceModel, {
      centered: true,
      windowClass: 'ledger-debit',
      size: 'lg',
    });
  }
  openaddnew(modal) {
    this.modalService.open(modal, {
      centered: true,
      windowClass: 'ledger-debit',
      size: 'md',
    });
  }

}
