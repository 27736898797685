import { Component, OnInit,EventEmitter, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BillBookervice } from 'src/app/shared/services/billBook.service';



@Component({
  selector: 'app-gallery-viewer',
  templateUrl: './gallery-viewer.component.html',
  styleUrls: ['./gallery-viewer.component.scss']
})
export class GalleryViewerComponent implements OnInit {
  imagePopupURL: any = null;
  @Output() closeModalEvent = new EventEmitter<void>();
  constructor(public bookService: BillBookervice,public modalService: NgbModal) { }

  ngOnInit(): void {
    
  }
  currentIndex = 0;
  onBackButtonClick() {
    this.closeModalEvent.emit();
  }
  OpenimageModal(modal, item) {
    this.imagePopupURL = item
    if (item?.file_type == 'file' || item?.file_type == 'video' ) {
      window.open(item?.file_path, '_blank').focus();
      return;
    }
    this.modalService.open(modal, { centered: true, windowClass: 'Image_viewer', size: 'lg', });
  }
}
