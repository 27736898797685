<nav class="navbar navbar-expand-lg bg-body-tertiary">
    <div class="container-fluid">
      <a class="navbar-brand">
        <img src="../../../../assets/Accountect/topbarlogo.svg" width="125px" alt="">
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" [ngClass]="{'hidden': !auth.userData.emp_info.is_buissness_exist, 'show': auth.userData.emp_info.is_buissness_exist}">
        <span class="navbar-toggler-icon"></span>
      </button>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent1" aria-controls="navbarSupportedContent1" aria-expanded="false" aria-label="Toggle navigation" [ngClass]="{'hidden': auth.userData.emp_info.is_buissness_exist, 'show': !auth.userData.emp_info.is_buissness_exist}">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent" [ngClass]="{'hidden': !auth.userData.emp_info.is_buissness_exist, 'show': auth.userData.emp_info.is_buissness_exist}">
        <div class="ml-auto">
            <ul class="navbar-nav align-items-center justify-content-end">
              <li class="nav-item d-flex align-items-center cursor-pointer" (click)="navigateToBusiness()">
                <div>
                  <img src="../../../../assets/Accountect/settingsweb/Group 40400.svg" width="30px" height="30px" class="mr-2" alt="">
                </div>
                 <span class="fixbusi-length text-capitalize">{{auth?.userData.emp_info?.Business?.business_name}}</span>
               </li>
               <!-- {{auth.userData.has_unseen_pending_notifications}}azz -->
              <li class="nav-item cursor-pointer position-relative d-flex" [routerLink]="['/user/pendingalerts']">
                <div class="d-flex p-1 bg-white">
                  <img src="../../../../assets/Accountect/Pending_Transactions.svg" class="mr-2" width="25px" height="25px" alt="">
                  Pending Transactions
                </div>
                <span class="un-read">
                  <i class="fa fa-circle text-danger" aria-hidden="true"></i>
                </span>
              </li>
              <li class="nav-item cursor-pointer position-relative" [routerLink]="['/user/alerts']">
                  <img src="../../../../assets/Accountect/Alert (2).svg" width="30px" height="30px" alt="">
                  <span class="un-read">
                    <i class="fa fa-circle circle1 text-danger" aria-hidden="true"></i>
                  </span> 
              </li>
                
            </ul>
        </div>
      </div>
      <div class="collapse navbar-collapse" id="navbarSupportedContent1" [ngClass]="{'hidden': auth.userData.emp_info.is_buissness_exist, 'show': !auth.userData.emp_info.is_buissness_exist}">
        <div class="ml-auto">
            <ul class="navbar-nav align-items-center justify-content-end">
              <li class="nav-item d-flex align-items-center cursor-pointer" (click)="openModallogout(logout)">
                <button class="btn btn-primary text-white">logout</button>
               </li>  
            </ul>
        </div>
      </div>
    </div>
  </nav>
  <ng-template #logout let-modal>
    <div class="modal-body">
        <div class="text-center">
            <img src="../../../../assets/Accountect/AC_connected.svg" class="img-50" alt="">
        </div>
        <div class="text-center">
            <p class="m-0 text-center font-weight-bold">Log out</p>
            <p class="m-0">Are you sure you want to log out of Accountect?</p>
        </div>
    </div>
    <div class="modal-footer bg-light border-btm-rad justify-content-center">
        <button type="button" class="btn p-0" (click)="modal.dismiss('Cross click')"><img src="../../../../assets/Accountect/AcceptReject/Reject.svg" alt=""></button>
        <button type="button" class="btn p-0" (click)="auth?.SignOut()"><img src="../../../../assets/Accountect/AcceptReject/Accept.svg" alt=""></button>
    </div>    
</ng-template>