import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbCalendar, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BillBookervice } from '../../services/billBook.service';
import { DatePipe } from '@angular/common';
import { LedgerService } from '../../services/ledger.service';
import { HttpServiceService } from '../../services/http/http-service.service';
import { Observable } from 'rxjs';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { EventService } from 'src/app/shared/sevices/event.service';
@Component({
  selector: 'app-search-filters',
  templateUrl: './search-filters.component.html',
  styleUrls: ['./search-filters.component.scss']
})
export class SearchFiltersComponent implements OnInit {
  selectedOption: string = 'sent';
  invoiceType:any
  selectedOptionAR:string = 'All'
  querySearchValuesCust: any;
  querySearchValues: string[] = [];
  CustloadData:boolean;
  loadData: boolean;
  categoryName: any;
  checkVal:any;
  listing$: Observable<any>;
  TimeValue = 'all';
  dateArray1: any = []
  dateArray2: any = []
  selectedOptionfilter: string = 'all';
  timeFilter: string = 'all';
  item1: string;
  modal: any;
  filterInvoiceNumber: any;
  selectedDate: NgbDateStruct; // Variable to store the selected date in NgbDateStruct format
  formattedDate: string;
  bundletype: boolean;
  selectedProdSrv: string = 'product';
  selectedOptionProSer: string = 'product';
  categoryListingLoader: boolean;
  categoryListing: any;
  selectedDateTo: NgbDateStruct;
  Currrentpage: any=1;
  TotalPages: any;
  selectedOptions: string;




  constructor(public router: Router,public auth: AuthService,public translate: TranslateService,private calendar: NgbCalendar,public bookService: BillBookervice,
    public datepipe: DatePipe,public ledgerService: LedgerService,private http: HttpServiceService,public modalService: NgbModal,private ngbDateParserFormatter: NgbDateParserFormatter,private eventService: EventService) { }

  ngOnInit(): void {
    this.bookService.resetBillBookAllFilters();
    // // alert("reset")
  }
  TimeFIlter(value) {
    if (this.ledgerService.loader === false) {
      // this.ledgerService.loader = true,
      this.TimeValue = value;
      this.timeFilter=value;
      // this.ledgerService.search = false
      // this.ledgerService.applied = false
        if (value === 'week') {
           
          var curr = new Date(); // get current date
          var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
          var last = first + 6; // last day is the first day + 6

          var startDate = this.datepipe.transform(first, 'yyyy-MM-dd')
          var endDate = this.datepipe.transform(last, 'yyyy-MM-dd')
          var firstday = new Date(curr.setDate(first)).toUTCString().split(" ", 4);
          var lastday = new Date(curr.setDate(last)).toUTCString().split(" ", 4);
          firstday.pop[0]
          lastday.pop[0]
          this.dateArray1 = this.datepipe.transform(firstday, 'yyyy-MM-dd')
          this.dateArray2 = this.datepipe.transform(lastday, 'yyyy-MM-dd')
          //  console.log("Array//", this.dateArray1);
          //  console.log("cur//", first, last);
          // this.bookService.billBookAllFilters.patchValue({
          //   start_date: this.dateArray1,
          //   end_date: this.dateArray2
          // })
          this.bookService.billBookAllFilters.start_date = this.dateArray1;
          this.bookService.billBookAllFilters.end_date = this.dateArray2;

          console.log(this.dateArray1);
          console.log(this.dateArray2);
          //  console.log("date", this.ledgerService.SaleFilters.value.start_date);
        }
        else if (value === 'year') {
          var currentTime = new Date()

          var month = currentTime.getMonth() + 1

          var day = currentTime.getDate()

          var year = currentTime.getFullYear()
          // this.ledgerService.SaleFilters.patchValue({
          //   start_date: + year + '-' + '01' + '-' + '01',
          //   end_date: year + '-' + '12' + '-' + '31'
          // })
          this.bookService.billBookAllFilters.start_date = year + '-' + '01' + '-' + '01';
          this.bookService.billBookAllFilters.end_date = year + '-' + '12' + '-' + '31';
        }
        else if (value === 'month') {
          var date = new Date();
          var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
          var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
          // var startMonth = firstDay.split()
          //  console.log("montj", this.datepipe.transform(firstDay, 'yyyy-MM-dd'), lastDay);
          var StartMont = this.datepipe.transform(firstDay, 'yyyy-MM-dd')
          var EndMont = this.datepipe.transform(lastDay, 'yyyy-MM-dd')
          // this.ledgerService.SaleFilters.patchValue({
          //   start_date: StartMont,
          //   end_date: EndMont
          // })
          this.bookService.billBookAllFilters.start_date = StartMont;
          this.bookService.billBookAllFilters.end_date = EndMont

        }
        else if (value === 'today') {
          var date = new Date();
          var firstDay = date
          var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
          // var startMonth = firstDay.split()
          //  console.log("montj", date);
          var StartMont = this.datepipe.transform(firstDay, 'yyyy-MM-dd')
          var EndMont = this.datepipe.transform(lastDay, 'yyyy-MM-dd')
          // this.ledgerService.SaleFilters.patchValue({
          //   start_date: StartMont,
          //   end_date: StartMont
          // })
          this.bookService.billBookAllFilters.start_date = StartMont;
          this.bookService.billBookAllFilters.end_date = StartMont;

        }
        else {
          this.ledgerService.SaleFilters.patchValue({
            start_date: "",
            end_date: ""
          })
        }
        // this.ledgerService.salecurrentPage = 1
        // this.ledgerService.getSaleTran()
    }
  }
  onScrollCat(event: any) {
    const marginOfError = 5; // Adjust this value if needed
    const target = event.target;
  
    if ((target.scrollHeight - target.scrollTop - target.clientHeight) <= marginOfError) {
      debugger;
      if (this.Currrentpage< this.TotalPages) {
        this.ledgerService.loader = true;
         this.Currrentpage = this.Currrentpage + 1;
        this.getExpenseCategories();
      }
    }
  }
  callFunctionInFirstComponent(val: any) {
    this.eventService.callFunction(val);
  }
  activeunc() {
    var header = document.getElementById("myDIV");
    var btns = header.getElementsByClassName("btn");
    for (var i = 0; i < btns.length; i++) {
      btns[i].addEventListener("click", function () {
        var current = document.getElementsByClassName("active");
        current[0].className = current[0].className.replace(" active", "");
        this.className += " active";
      });
    }

  }
  onDateSelect(date: NgbDateStruct): void {
    // Format the date to yyyy-mm-dd
    debugger;
    const formattedDate = this.ngbDateParserFormatter.format(date);
    console.log(formattedDate); // Log the date to the console
    this.bookService.billBookAllFilters.start_date=formattedDate;
  }
  onDateSelectTo(date: NgbDateStruct): void {
    // Format the date to yyyy-mm-dd
    console.log('onDateSelectTo called with date:', date);
    debugger;
    const formattedDate = this.ngbDateParserFormatter.format(date);
    console.log(formattedDate); // Log the date to the console
    this.bookService.billBookAllFilters.end_date=formattedDate;
  }
  togglesentReceived(option:string){
    this.selectedOption = option;
    this.bookService.billBookAllFilters.invoice_status_type=option;
    // this.bookService.billBookFilters = {
    //   ...this.bookService.billBookFilters,
    //   bill_board_transaction_type: this.invoiceType,
    //   invoice_status_type:type
    // };
    // this.bookService.getFilterInvoiceBook();
  }
  toggleProductService(option:string,check){
    this.selectedOptionProSer = option;
    // alert(this.bookService.invoiceType);
    this.bookService.billBookAllFilters.product_invoice=check;
  }
  toggleFilter(option:any)
  {
    this.selectedOptionfilter = option;
    if(option==true)
    {
      this.selectedOptionfilter='paid'
    }
    else if(option==false)
    {
      this.selectedOptionfilter='unpaid'
    }
    this.bookService.billBookAllFilters.paid_status=option;
  }
  toggleAcceptReject(option:string){
    this.selectedOptionAR = option;
    this.selectedOption=option;
    this.bookService.billBookAllFilters.accept_or_reject_status=option;
    // this.bookService.billBookFilters = {
    //   ...this.bookService.billBookFilters,
    //   bill_board_transaction_type: this.invoiceType,
    //   invoice_status_type:type
    // };
    // this.bookService.getFilterInvoiceBook();
  }
  searchCustomer(){
    this.bookService.custVendQuery=this.querySearchValuesCust;
    if(this.querySearchValuesCust=='')
    {
      this.CustloadData=false;
    }
    else
    {
      this.CustloadData=true;
      this.bookService?.getBusinessListFilter();
    }
  }
  getCustVendVal(item){
     
    console.log(item?.with_business?.business_name)
    this.querySearchValuesCust=item?.with_business?.business_name;
    this.CustloadData=false;
  }

  formatDate() {
    if (this.selectedDate) {
      const year = this.selectedDate.year;
      const month = this.selectedDate.month < 10 ? `0${this.selectedDate.month}` : this.selectedDate.month;
      const day = this.selectedDate.day < 10 ? `0${this.selectedDate.day}` : this.selectedDate.day;
  
      this.formattedDate = `${year}-${month}-${day}`;
    }
    else if(this.selectedDateTo)
      {
        const year = this.selectedDateTo.year;
        const month = this.selectedDateTo.month < 10 ? `0${this.selectedDateTo.month}` : this.selectedDateTo.month;
        const day = this.selectedDateTo.day < 10 ? `0${this.selectedDateTo.day}` : this.selectedDateTo.day;
    
        this.formattedDate = `${year}-${month}-${day}`;
      }
    else {
      this.formattedDate = ''; // Handle the case where no date is selected
    }
    console.log(this.formattedDate);
  }
  
  selectBusiness(item: any) {
    // this.bookService.baseModel.with_Business_information = item?.with_business?.id;
    this.bookService.billBookAllFilters.customer_vendor_list.push(item?.with_business?.id);
    // this.bookService.baseModel.shipping_Business_information =
    //   item?.with_business?.id;
    //   this.bookService.baseModel.shipping_Business_information = {
    //     ...this.bookService.baseModel.shipping_Business_information,
    //     ...item?.with_business, full_phone_number: item?.with_business?.phone_number || item?.with_business?.full_phone_number 
    //   };
  }
  // searchBundles(res: any = '33', page: any = 1) {
  //   if (res) {
  //     this.ledgerService.loadBusi = true;
  //     console.log(this.ledgerService.querySearch);
  //     // // alert("searchBundle");
  //     this.loadData = false
  //     this.http.getMarketListingBundles(4248, this.ledgerService.querySearch, 'all', page).subscribe(data => {
  //       this.ledgerService.searchList = data;
  //       this.ledgerService.loadBusi = false;
  //       // let listing=data['market_listing'];
  //       let listing = data['market_listing'];
  //       if (Array.isArray(listing)) {
  //         // If 'market_listing' is an array, use it directly
  //         this.ledgerService.searchList = listing;
  //       } else {
  //         // If 'market_listing' is an object, convert it to an array
  //         this.ledgerService.searchList = Object.values(listing);
  //       }
  //       console.log(this.ledgerService.searchList);
  //       console.log(typeof this.ledgerService.searchList);
  //       this.ledgerService.loadBusi = false;

  //       console.log(listing);
  //       console.log(typeof listing);
  //       this.loadData = true;
  //       ////  console.log('this is the search query result', data);
  //     }, (error) => {
  //       this.ledgerService.loadBusi = false;
  //     });
  //   } else {
  //     this.ledgerService.selectedBuss = [];
  //     this.ledgerService.searchList = '';
  //   }
  // }


  openaddnewEx(modal) {
    this.getExpenseCategories();
    this.modalService.open(modal, {
      centered: true,
      windowClass: 'addnew',
      size: 'md',
    });
  }
  getExpenseCategories(query: any = '') {
    this.categoryListingLoader = true;
    this.http.getExpenseCategory(query, this.Currrentpage).subscribe(res => {
      this.Currrentpage=res['page'];
      this.TotalPages= res['totalPages'];
      if (this.Currrentpage==1)
        {
          this.categoryListing = res?.result;
        }
      if (this.Currrentpage>1)
        {
          this.categoryListing = [...this.categoryListing, ...res?.result];
        }
      this.categoryListingLoader = false;
      console.log('category listing', this.categoryListing);
    }, (error: any) => {
      this.categoryListingLoader = false;
    })
  }

  reset() {
    this.filterInvoiceNumber = '';
    this.selectedOptionProSer = '';
    this.selectedOption = '';
    this.selectedOptionAR = '';
    this.querySearchValuesCust = '';
    // Reset other fields as needed
  
    // Reset datepicker fields
    this.selectedDate = null;
    this.selectedDateTo = null;
  }
  ategoryAddToExpenseForm(item, modal) {
    // debugger;
    console.log(item);
    this.categoryName = item.title;
    this.bookService.categoryName = item.title;
    this.bookService.billBookAllFilters.expense_category=item.id;
    // this.bookService.expbaseModal.patchValue({
    //   expense_category: item.id
    // })
    this.bookService.expbaseModal.expense_category = item.id,
      this.bookService.baseModel.expense_category = item.id;
    modal.dismiss();
  }

  searchBundles(modal, query, loopItem, res: any = '33', page: any = 1) {
    if (res) {
      // //  
      this.ledgerService.loadBusi = true;
      this.checkVal=query;
      let Squery=this.querySearchValues[query];
      this.bundletype=false;
      this.listing$ = this.http.getMarketListingBundles(this.auth.userData.emp_info.Business.id, Squery, 'all', page,this.bundletype ,null);
      this.listing$.subscribe(data => {
        if (Squery=='')
        {
          this.ledgerService.searchList = '';
          this.loadData = false;
        }
        else
        {
          this.ledgerService.searchList = data['market_listing'];
          console.log("Searchlist", this.ledgerService.searchList)
          console.log("length", this.ledgerService.searchList.length);
          this.ledgerService.loadBusi = false;
          this.loadData = true;
        }
      }, (error) => {
        this.ledgerService.loadBusi = false;
      });
    } else {
      this.ledgerService.selectedBuss = [];
      this.ledgerService.searchList = '';
    }
  }

  searchFilters(modal, query, loopItem, res: any = '33', page: any = 1) {
    if (res) {
      // //  
      this.ledgerService.loadBusi = true;
      this.checkVal=query;
      let Squery=this.querySearchValues[query];
      this.bundletype=false;
      this.listing$ = this.http.getMarketListingBundles(this.auth.userData.emp_info.Business.id, Squery, 'all', page,this.bundletype, this.bookService.billBookAllFilters.category_id);
      this.listing$.subscribe(data => {
        if (Squery=='')
        {
          this.ledgerService.searchList = '';
          this.loadData = false;
        }
        else
        {
          this.ledgerService.searchList = data['market_listing'];
          console.log("Searchlist", this.ledgerService.searchList)
          console.log("length", this.ledgerService.searchList.length);
          this.ledgerService.loadBusi = false;
          this.loadData = true;
        }
      }, (error) => {
        this.ledgerService.loadBusi = false;
      });
    } else {
      this.ledgerService.selectedBuss = [];
      this.ledgerService.searchList = '';
    }
  }
  getItemVal(item)
  {
    this.item1='item1';
    this.querySearchValues=item?.product_name;
    if (this.querySearchValues) {
      this.bookService.billBookAllFilters.item_list.push(item?.id);
    }
    this.loadData = false;
  }

  AppyFilters()
  {
    // debugger;
    console.log(this.bookService.billBookAllFilters);
    this.bookService.billBookAllFilters.bill_board_transaction_type=this.bookService.invoiceType;
    this.bookService.billBookAllFilters.search_query=this.filterInvoiceNumber;
    this.callFunctionInFirstComponent(this.selectedOption);
    this.bookService.getAllFiterInvoices();
    this.modalService.dismissAll();
  }
  openaddnew(modal) {
    // this.getExpenseCategories();
    this.modalService.open(modal, {
      centered: true,
      windowClass: 'ledger-debit',
      size: 'md',
    });
  }
  categoryAddToExpenseForm(item, modal) {
    console.log(item);
    this.bookService.billBookAllFilters.category_id=item.id;
    this.categoryName = item.title;
    modal.dismiss();
  }
}
