import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbCalendar, NgbDateParserFormatter, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BillBookervice } from '../../services/billBook.service';
import { HttpServiceService } from '../../services/http/http-service.service';
import { LedgerService } from '../../services/ledger.service';
import { DatePipe } from '@angular/common';
import { Observable } from 'rxjs';
import { time } from 'console';

@Component({
  selector: 'app-download-filters',
  templateUrl: './download-filters.component.html',
  styleUrls: ['./download-filters.component.scss']
})
export class DownloadFiltersComponent implements OnInit {
  loadData: boolean;
  querySearchValuesCust: any;
  querySearchValues: string[] = [];
  item1: string;
  CustloadData:boolean;
  categoryName: any;
  dynamicData: any[] = [];
  userId: any;
  checkVal:any;
  listing$: Observable<any>;
  bundletype: boolean;
  selectedTransactionType: number;
  startDate: string;
  endDate: string;
  productCategoryId: number;
  itemName: string;
  isLink: boolean;
  itemsList: string[];
  filterId: any;
  catId: any;categoryPostLoader: boolean = false;
  categoryDeleteLoader: boolean = false;
  categoryListingLoader: boolean = false;
  categoryListing: any;
  selectedDate: NgbDateStruct; 
  selectedDateTo:NgbDateStruct;
  fromDate: any;
  toDate: any;
  formattedDate: string;
  dateFormat: any;
  unitQuantity: any[] = [];
  unitPrice: string[] = [];
  // item1: any = '';
  item2: any = '';
  item3: any = '';
  invoiceType:any;
  Currrentpage: any=1;
  TotalPages: any;

  constructor(public router: Router,public auth: AuthService,public translate: TranslateService,private calendar: NgbCalendar,public bookService: BillBookervice,
    public datepipe: DatePipe,public ledgerService: LedgerService,private http: HttpServiceService,public modalService: NgbModal,private ngbDateParserFormatter: NgbDateParserFormatter) { }

  ngOnInit(): void {
    if (this.auth.userData.emp_info.is_buissness_exist) {
      this.DateFormatepipe();
    }
    if(this.auth.userData.emp_info.is_buissness_exist) {
      this.DateFormatepipe();
    }
  }
  DateFormatepipe() {
    if (this.auth.userData.emp_info.Business.default_date_format.id === 1) {
      this.dateFormat = 'dd-MM-yyyy';
    } else if (
      this.auth.userData.emp_info.Business.default_date_format.id === 2
    ) {
      this.dateFormat = 'MM-dd-yyyy';
    } else {
      this.dateFormat = 'yyyy-MM-dd';
    }
  }
  getItemVal(item)
  {
    this.item1='item1';
    this.querySearchValues=item?.product_name;
    if (this.querySearchValues) {
      this.bookService.billBookAllFilters.item_list.push(item?.id);
    }
    this.filterId=item.id; 
    this.loadData = false;
  }
  searchFilters(modal, query, loopItem, res: any = '33', page: any = 1) {
    if (res) {
      // //  
      this.ledgerService.loadBusi = true;
      this.checkVal=query;
      let Squery=this.querySearchValues[query];
      this.bundletype=false;
      this.listing$ = this.http.getMarketListingBundles(this.auth.userData.emp_info.Business.id, Squery, 'all', page,this.bundletype, this.bookService.billBookAllFilters.category_id);
      this.listing$.subscribe(data => {
        if (Squery=='')
        {
          this.ledgerService.searchList = '';
          this.loadData = false;
        }
        else
        {
          this.ledgerService.searchList = data['market_listing'];
          console.log("Searchlist", this.ledgerService.searchList)
          console.log("length", this.ledgerService.searchList.length);
          this.ledgerService.loadBusi = false;
          this.loadData = true;
        }
      }, (error) => {
        this.ledgerService.loadBusi = false;
      });
    } else {
      this.ledgerService.selectedBuss = [];
      this.ledgerService.searchList = '';
    }
  }
  downloadPDF(){

    console.log(this.userId, this.filterId, this.catId, this.fromDate, this.toDate);
    this.bookService.filterDownload(this.fromDate,this.toDate, this.catId, this.userId, this.filterId)
  }
  getExpenseCategories(query: any = '') {
    this.categoryListingLoader = true;
    this.http.getExpenseCategory(query, this.Currrentpage).subscribe(res => {
      this.Currrentpage=res['page'];
      this.TotalPages= res['totalPages'];
      if (this.Currrentpage==1)
        {
          this.categoryListing = res?.result;
        }
      if (this.Currrentpage>1)
        {
          this.categoryListing = [...this.categoryListing, ...res?.result];
        }
      this.categoryListingLoader = false;
      console.log('category listing', this.categoryListing);
    }, (error: any) => {
      this.categoryListingLoader = false;
    })
  }
  onScrollCat(event: any) {
    const marginOfError = 5; // Adjust this value if needed
    const target = event.target;
  
    if ((target.scrollHeight - target.scrollTop - target.clientHeight) <= marginOfError) {
      debugger;
      if (this.Currrentpage< this.TotalPages) {
        this.ledgerService.loader = true;
         this.Currrentpage = this.Currrentpage + 1;
        this.getExpenseCategories();
      }
    }
  }
  openaddnewEx(modal) {
    this.getExpenseCategories();
    this.modalService.open(modal, {
      centered: true,
      windowClass: 'addnew',
      size: 'md',
    });
  }
  Closeitem(val) {
    // Assuming this.querySearchValues is an array
    if (Array.isArray(this.querySearchValues)) {
        this.querySearchValues[val] = null;
    } else {
        // If it's not an array, handle it accordingly
        this.querySearchValues = null; // Or whatever logic you want here
    }

    this.bookService.baseModel.item_and_description.splice(val, 1);
    this.unitQuantity[val] = null;
    this.unitPrice[val] = null;

    if (val == 0) {
        this.item1 = '';
    } else if (val == 1) {
        this.item2 = '';
    } else if (val == 2) {
        this.item3 = '';
    }
}

  searchCustomer(){
    this.bookService.custVendQuery=this.querySearchValuesCust;
    if(this.querySearchValuesCust=='')
    {
      this.CustloadData=false;
    }
    else
    {
      this.CustloadData=true;
      this.bookService?.getBusinessListFilter();
    }
  }
  // Closeitem(val) {
  //   this.querySearchValues[val] = null
  //   this.bookService.baseModel.item_and_description.splice(val, 1);
  //   this.unitQuantity[val] = null;
  //   this.unitPrice[val] = null;
  //   if (val == 0) {
  //     this.item1 = ''
  //   }
  //   if (val == 1) {
  //     this.item2 = ''
  //   }
  //   if (val == 2) {
  //     this.item3 = ''
  //   }
  // }
  getPDFRoomsData() {
    this.http.getPDFRooms(this.selectedTransactionType,true,this.startDate,this.endDate,this.productCategoryId,this.userId,this.itemName,this.isLink,this.itemsList).subscribe(
      (res: ArrayBuffer) => {
        let blob = new Blob([res], { type: 'application/pdf' });
        var downloadURL = URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = 'rooms.pdf';
        link.click();
      }
    );
  }
  getCustVendVal(item){
    console.log(item?.with_business?.business_name)
    this.querySearchValuesCust=item?.with_business?.business_name;
    this.userId=item?.with_business?.id;
    this.CustloadData=false;
  }
  openaddnew(modal) {
    this.modalService.open(modal, {
      centered: true,
      windowClass: 'ledger-debit',
      size: 'md',
    });
  }
  categoryAddToExpenseForm(item, modal) {
    console.log(item);
    this.bookService.billBookAllFilters.category_id=item.id;
    this.catId=item.id;
    this.categoryName = item.title;
    modal.dismiss();
  }
  formatDate() {
    if (this.selectedDate) {
      const year = this.selectedDate.year;
      const month = this.selectedDate.month < 10 ? `0${this.selectedDate.month}` : this.selectedDate.month;
      const day = this.selectedDate.day < 10 ? `0${this.selectedDate.day}` : this.selectedDate.day;
  
      this.formattedDate = `${year}-${month}-${day}`;
    }
    else if (this.selectedDateTo) {
      const year = this.selectedDateTo.year;
      const month = this.selectedDateTo.month < 10 ? `0${this.selectedDateTo.month}` : this.selectedDateTo.month;
      const day = this.selectedDateTo.day < 10 ? `0${this.selectedDateTo.day}` : this.selectedDateTo.day;
  
      this.formattedDate = `${year}-${month}-${day}`;
    }
    else {
      this.formattedDate = ''; // Handle the case where no date is selected
    }
    console.log(this.formattedDate);
  }
  onDateSelect(date: NgbDateStruct): void {
    debugger
    // Format the date to yyyy-mm-dd
    const formattedDate = this.ngbDateParserFormatter.format(date);
    console.log(formattedDate); // Log the date to the console
    this.fromDate= formattedDate;
  }
  onDateSelectTo(date: NgbDateStruct): void {
    debugger
    // Format the date to yyyy-mm-dd
    const formattedDate = this.ngbDateParserFormatter.format(date);
    console.log(formattedDate); // Log the date to the console
    this.toDate=formattedDate;
  }
}
