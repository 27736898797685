import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { homedir, type } from 'os';

@Injectable({
  providedIn: 'root',
})
export class HttpServiceService {
  post(arg0: string, arg1: { token: string; ul: any; r: any; a: any; type: any; referral: any; fromUser: any; }) {
    throw new Error('Method not implemented.');
  }
  public build_type = environment.build_type;
  baseUrl;
  testUrl = 'http://127.0.0.1:9000/api/';
  linkedIn = environment.linkedInCredentials;
  public userState = new BehaviorSubject({});
  public userData: any;
  public isloading = false;
  countryByIP: any;
  constructor(private http: HttpClient) {
    localStorage.setItem('BuildVersion', this.build_type);
    // localStorage.setItem('tourChatRight', this.build_type);
    if (this.build_type === 'ciwac') {
      this.baseUrl = environment.apiUrl;
    }

    this.userState.subscribe((user) => {
      if (user) {
        this.userData = user;
      }
    });
    this.userState.next(JSON.parse(localStorage.getItem('user')) || {});
  }
  chargePayment(data) {
    return this.http.post(this.baseUrl + 'payments/charge_payment/', data);
  }
  

  unsubscribestripe() {
    return this.http.get(this.baseUrl + 'payments/unsubscribe_plan/');
  }

  getNotifications(page = 1) {
    return this.http.get(
      this.baseUrl + 'notification/user_all_notifications?page=' + page 
    );
  }

  getFilterNotifications(page = 1, filter) {
    return this.http.get(
      this.baseUrl + 'notification/user_all_notifications?page=' + page + '&is_comment=' +filter
    );
  }

  notificationSeen(id) {
    return this.http.post(
      this.baseUrl + 'notification/notification_seen_individual/',
      {
        notification_id: id,
      }
    );
  }
  pendingalert(page = 1){
    return this.http.get(
      this.baseUrl + 'notification/pending_notifications?page=' + page,
    );
  }
  muteNotification(id, type) {
    return this.http.post(
      this.baseUrl + 'notification/mute_or_unmute_notification/',
      {
        notification_id: id,
        type: type,
      }
    );
  }

  public logOutUser() {
    return this.http.post(this.baseUrl + 'user/logout/', {});
  }
  detectDevice() {
    return this.http.get(this.baseUrl + 'user/detect_user_agent/');
  }
  signin(data): Observable<any> {
    return this.http.post(this.baseUrl + 'user/login/', data);
  }
  signup(
    data,
    ul = false,
    key = '',
    user = '',
    type = '',
    fromUser = '',
    referral = ''
  ): Observable<any> {
    let form = {
      username: data.username,
      password: data.password,
    };
    return this.http.post(
      this.baseUrl +
        'user/user_signup/?' +
        ul +
        '&r=' +
        key +
        '&a=' +
        user +
        '&type=' +
        type +
        '&from_username=' +
        fromUser +
        '&referral=' +
        referral,
      form
    );
  }
  verifySignupOTP(data): Observable<any> {
    return this.http.post(this.baseUrl + 'user/verify_signup_username/', data);
  }
  verifyforgetOTP(data): Observable<any> {
    return this.http.post(
      this.baseUrl + 'user/verify_forget_password_otp/',
      data
    );
  }
  verifyEmailOTP(data): Observable<any> {
    return this.http.post(
      this.baseUrl + 'user/verify_forget_password_otp/',
      data
    );
  }
  updatePassword(new_password, old_password): Observable<any> {
    return this.http.put(this.baseUrl + 'user/update_password/', {
      new_password: new_password,
      old_password: old_password,
    });
  }
  updatePasswordsocial(new_password): Observable<any> {
    return this.http.put(this.baseUrl + 'user/update_social_login_password/', {
      new_password: new_password,
    });
  }
  getsettingAlerts(): Observable<any> {
    return this.http.get(
      this.baseUrl + 'notification/get_update_allow_alert_detail/'
    );
  }
  public getTourData(lang) {
    return this.http.get(this.baseUrl + 'business/tutorial_detail/');
  }
  getAlllang(app): Observable<any> {
    return this.http.get(
      this.baseUrl + 'user/get_all_languages/?application=' + app
    );
  }
  AdminDelMember(id): Observable<any> {
    return this.http.delete(
      this.baseUrl + 'business/admin_removing_member_from_business/' + id + '/'
    );
  }
  deleteProductCategory(id): Observable<any> {
    return this.http.delete(
      this.baseUrl + 'inventory/delete_product_category/' + id + '/'
    );
  }
  // editProductCategory(data: any = null, id: any = null): Observable<any> {
  //   return this.http.put(
  //     this.baseUrl + 'inventory/edit_product_category/'+id+'/',
  //     {
  //       "title": data
  //     }
  //   );
  // }
  editProductCat(categoryData: any, categoryId: number): Observable<any> {
    return this.http.put(
        this.baseUrl + `inventory/edit_product_category/${categoryId}/`,
        { title: categoryData.title }
    );
}
  DelOneWayBusiness(id): Observable<any> {
    return this.http.delete(
      this.baseUrl + 'ledger/delete_one_way_ledger/' + id + '/'
    );
  }
  Delaccount(id): Observable<any> {
    return this.http.delete(
      this.baseUrl + 'business/delete_account/' + id + '/'
    );
  }
  deleteExpenseTrasnection(id): Observable<any> {
    return this.http.delete(
      this.baseUrl + 'expense/delete_expense_transaction/' + id + '/'
    );
  }
  deleteEmployeeLedgerTrasnection(id): Observable<any> {
    return this.http.delete(
      this.baseUrl +
        'employee_ledger/delete_transaction_employee_ledger/' +
        id +
        '/'
    );
  }
  deleteCashTrasnection(id): Observable<any> {
    return this.http.delete(
      this.baseUrl + 'cash/delete_cash_transaction/' + id + '/'
    );
  }
  deleteSaleTrasnection(id): Observable<any> {
    return this.http.delete(
      this.baseUrl + 'sale/delete_sale_transaction/' + id + '/'
    );
  }
  deletePurchaseTrasnection(id): Observable<any> {
    return this.http.delete(
      this.baseUrl + 'purchase/delete_purchase_transaction/' + id + '/'
    );
  }
  deleteBankTrasnection(id): Observable<any> {
    return this.http.delete(
      this.baseUrl + 'bank/delete_bank_transaction/' + id + '/'
    );
  }
  putsettingAlerts(data): Observable<any> {
    return this.http.put(
      this.baseUrl + 'notification/get_update_allow_alert_detail/',
      data
    );
  }
  sendforgetOTP(user): Observable<any> {
    return this.http.post(this.baseUrl + 'user/forget_password_otp/', user);
  }

  
  
  resendSignupOTP(user) {
    return this.http.post(this.baseUrl + 'user/resend_otp_signup/', user);
  }
  forgetPasswordUpdate(data): Observable<any> {
    return this.http.put(this.baseUrl + 'user/new_password/', data);
  }
  createbusiness(data): Observable<any> {
    return this.http.post(this.baseUrl + 'business/create_business/', data);
  }
  getbusinessmembers(id): Observable<any> {
    return this.http.post(this.baseUrl + 'business/get_business_member_info/', {
      business_id: id,
    });
  }
  addproductcategory(title): Observable<any> {
    debugger;
    return this.http.post(this.baseUrl + 'inventory/add_product_category/', {
      "title":title
    });
  }
  sendAdminrequest(id): Observable<any> {
    return this.http.post(
      this.baseUrl + 'business/send_request_to_shift_admin_right/',
      {
        next_admin_emp_id: id,
      }
    );
  }
  adminrequestReceived(): Observable<any> {
    return this.http.get(
      this.baseUrl + 'business/to_be_admin_requests_received/'
    );
  }
  getBatchlist(id): Observable<any> {
    return this.http.get(
      this.baseUrl + 'inventory/product_available_stock_detail/' + id
    );
  }
  getBatchlistBundle(id, type: string = 'available'): Observable<any> {
    return this.http.post(
      this.baseUrl + 'market/bundle_available_and_finish_stock_detail/' + id+'/',
      {
        "type": type
      }
    );
   
  }

  // getBatchlistBundle(id, type: string = 'available'): Observable<any> {
  //   return this.http.post(
  //     this.baseUrl + 'market/bundle_available_and_finish_stock_detail/' + id+'/',
  //     {
  //       "type": type
  //     },
  //     { observe: 'response' }
  //   ).pipe(
  //     map(response => {
  //       console.log(response);
  //       const headers: HttpHeaders = response.headers;
  //       const myHeader: string | null = headers.get('my-header-name');
        
  //       // Here you can access the header value or perform other operations
        
  //       return response.body; // Return the response body
  //     })
  //   );
  // }

  getsingleTranDetail(obj_id, transaction_type_id): Observable<any> {
    return this.http.get(
      this.baseUrl +
        'inventory/single_stock_detail/' +
        obj_id +
        '/' +
        transaction_type_id
    );
  }
  

  StockDetailpdf(id, pdf){
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    return this.http.get(
      `${this.baseUrl}inventory/stock/${id}/${pdf}`, 
      httpOptions
    );
  }


  minimumStock(id,pdf) {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };
    return this.http.get(
      `${this.baseUrl}inventory/stock/minimum/${id}/${pdf}`,
      httpOptions
    );
  }
  minimumStockbundle(id,pdf) {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };
    return this.http.get(
      `${this.baseUrl}market/stock/minimum/${id}/${pdf}`,
      httpOptions
    );
  }
  getsingleTranBundleDetail(obj_id, transaction_type_id): Observable<any> {
    return this.http.get(
      this.baseUrl +
        'market/get_single_stock_bundle/' +
        obj_id +
        '/' +
        transaction_type_id
    );
  }
  sendJoiningrequest(id): Observable<any> {
    return this.http.post(
      this.baseUrl + 'business/send_business_joining_request/',
      {
        Business_id: id,
      }
    );
  }
  cancelJoinRequest(): Observable<any> {
    return this.http.get(
      this.baseUrl + 'business/cancel_business_joining_request'
    );
  }
  getReffralcodestatus(): Observable<any> {
    return this.http.get(this.baseUrl + 'contacts/get_referral_code_status/');
  }
  AccptJoiningrequest(id): Observable<any> {
    return this.http.post(
      this.baseUrl +
        'business/business_joining_request_approve_by_admin/' +
        id +
        '/',
      {}
    );
  }
  RejectJoiningrequest(id): Observable<any> {
    return this.http.post(
      this.baseUrl +
        'business/business_joining_request_reject_by_admin/' +
        id +
        '/',
      {}
    );
  }
  pendingJoiningrequest(): Observable<any> {
    return this.http.get(
      this.baseUrl + 'business/business_joining_request_list_pending'
    );
  }
  customerPendingRequest(): Observable<any> {
    return this.http.get(
      this.baseUrl + 'ledger/received_ledger_listing/?account_type=Customer'
    );
  }

  vendorPendingRequest(): Observable<any> {
    return this.http.get(
      this.baseUrl + 'ledger/received_ledger_listing/?account_type=Vendor'
    );
  }

  // customerPendingRequest() Observable<any>
  // {
    
    
  // }
  getMemberList(): Observable<any> {
    return this.http.get(this.baseUrl + 'business/business_joining_request_list_pending');
  }

  updateBusiness(data): Observable<any> {
    return this.http.put(this.baseUrl + 'business/update_business/', data);
  }
  uploadBusinessPic(data): Observable<any> {
    return this.http.post(
      this.baseUrl + 'business/upload_image_for_buisness_profile/',
      data
    );
  }
  updateBusinessPic(data): Observable<any> {
    return this.http.post(
      this.baseUrl + 'business/update_buisness_profile_image/',
      data
    );
  }
  updateProfileBill(data): Observable<any> {
    return this.http.post(
      this.baseUrl + 'business/update_buisness_profile_image/',
      data
    );
  }
  getLedgerListBusiness(page: any = 1, query: any = '') {
    return this.http.post(
      this.baseUrl + 'bill_board/get_ledger_listing/?page='+ page,  {
        search_query: query,
        // one_way_ledger: check,
        // account_type:accType,
        // purchase_account:purAccount,
        // expense_account:expAccount
      }
    );
  }
  getLedgerListBusinessFilter(page: any = 1, query: any = '',accType: '',purAccount:boolean,expAccount:boolean) {
    return this.http.post(
      this.baseUrl + 'bill_board/get_ledger_listing/?page='+ page,  {
        search_query: query,
        // one_way_ledger: check,
        account_type:accType,
        purchase_account:purAccount,
        expense_account:expAccount
      }
    );
  }
  updateBusinessSignStamp(data): Observable<any> {
    return this.http.post(
      this.baseUrl + 'bill_board/upload_sign_stamp_images/',
      data
    );
  }
  uploadUsersPic(data): Observable<any> {
    return this.http.post(
      this.baseUrl + 'business/update_employee_profile_image/',
      data
    );
  }
  uploadEmployeePic(data): Observable<any> {
    return this.http.post(
      this.baseUrl + 'business/upload_single_file_general/',
      data
    );
  }
  updateUser(data): Observable<any> {
    return this.http.put(this.baseUrl + 'user/profile_update/', data);
  }
  SearchBusiness(query): Observable<any> {
    return this.http.post(this.baseUrl + 'business/search_business/', {
      query: query,
    });
  }
  SearchBusinessLedger(query, page): Observable<any> {
    return this.http.post(
      this.baseUrl + 'business/search_business_for_ledger_room/?page=' + page,
      {
        query: query,
      }
    );
  }
  activeInactiveBillParticipant(id, status): Observable<any> {
    return this.http.post(
      this.baseUrl + 'business/search_business_members_with_edit_permission/',
      {
        "emp_id": id,
        "participant_status": status
      }
    );
  }
  searchMemberLedger(query, type): Observable<any> {
    return this.http.post(
      this.baseUrl + 'business/search_business_members_with_edit_permission/',
      {
        query: query,
        type: type,
      }
    );
  }
  createLedger(data): Observable<any> {
    return this.http.post(this.baseUrl + 'ledger/create_ledger/', {
      business_ids: [data],
    });
  }
  createLedgerwithOpening(data, isOpening: any = false): Observable<any> {
    return this.http.post(
      this.baseUrl + 'ledger/create_ledger_room_with_opening_balance/',
      data
    );
  }
  addTransactionFile(data): Observable<any> {
    return this.http.post(
      this.baseUrl + 'ledger/upload_transaction_file/',
      data
    );
  }
  cancelReqOneWayLed(id): Observable<any> {
    return this.http.post(
      this.baseUrl + 'ledger/cancel_one_way_ledger_request/',
      {
        ledger_room_id: id,
      }
    );
  }
  getSingleTransDetail(id): Observable<any> {
    return this.http.get(
      this.baseUrl + 'ledger/get_single_transaction_detail/' + id + '/'
    );
  }
  getTransTypes(): Observable<any> {
    return this.http.get(this.baseUrl + 'ledger/transaction_type_list/');
  }
  getCashsTypes(): Observable<any> {
    return this.http.get(this.baseUrl + 'cash/cash_transaction_type_list/');
  }
  getuomTypes(): Observable<any> {
    return this.http.get(this.baseUrl + 'inventory/product_uom_list/');
  }
  connectBusinessInvite(bus_id, room_id): Observable<any> {
    return this.http.post(
      this.baseUrl + 'ledger/connect_business_with_one_way_ledger/',
      {
        business_id: bus_id,
        ledger_room_id: room_id,
      }
    );
  }
  sendInviteOneWay(id,email): Observable<any> {
    return this.http.post(
      this.baseUrl + 'ledger/send_invite_in_one_way_ledger/',
      {
        ledger_room_id: id,
        email: email,
      }
    );
  }
  sendinviteMember(email): Observable<any> {
    return this.http.post(
      this.baseUrl + 'business/send_member_invitation/',
      {
        email: email,
      }
    );
  }
  SendConnectionRequest(email): Observable<any> {
    return this.http.post(
      this.baseUrl + 'business/send_connection_request_invitation/',
      {
        email: email,
      }
    );
  }
  ShareDocument(email,id): Observable<any> {
    return this.http.post(
      this.baseUrl + 'bill_board/send_pdf_document/',
      {
        id:id,
        email: email,
      }
    );
  }
  cancelInviteOneWay(id): Observable<any> {
    return this.http.post(
      this.baseUrl + 'ledger/cancel_one_way_ledger_request/',
      {
        ledger_room_id: id,
      }
    );
  }
  editPermissions(data): Observable<any> {
    return this.http.put(
      this.baseUrl + 'business/edit_member_permissions/',
      data
    );
  }
  editSinglePermissions(data): Observable<any> {
    return this.http.put(
      this.baseUrl + 'ledger/edit_single_ledger_employee_permission/',
      data
    );
  }
  setLedgerLimit(data): Observable<any> {
    return this.http.post(this.baseUrl + 'ledger/add_ledger_limit/', data);
  }
  businessvisibility(value): Observable<any> {
    return this.http.put(
      this.baseUrl + 'business/update_business_visibility/',
      {
        visibility: value,
      }
    );
  }
  languageChange(lang): Observable<any> {
    return this.http.get(
      this.baseUrl + 'user/language_update/?language=' + lang
    );
  }
  allBank(cur_id, page): Observable<any> {
    return this.http.get(
      this.baseUrl + 'bank/bank_room_data/?page=' + page + '&currency' + cur_id
    );
  }
  addTransaction(data): Observable<any> {
    return this.http.post(this.baseUrl + 'ledger/add_transaction/', data);
  }
  addCashBook(data): Observable<any> {
    return this.http.post(
      this.baseUrl + 'cash/add_cash_book_transaction/',
      data
    );
  }
  addEditCashBook(data): Observable<any> {
    return this.http.put(
      this.baseUrl + 'cash/edit_cash_book_transaction/',
      data
    );
  }
  addPurchaseBook(data): Observable<any> {
    return this.http.post(
      this.baseUrl + 'purchase/add_purchase_book_transaction/',
      data
    );
  }
  editPurchaseBook(data): Observable<any> {
    return this.http.put(
      this.baseUrl + 'purchase/edit_purchase_book_transaction/',
      data
    );
  }
  addSaleBook(data): Observable<any> {
    return this.http.post(
      this.baseUrl + 'sale/add_sale_book_transaction/',
      data
    );
  }

  postEditOrder(data): Observable<any> {
    return this.http.put(
      this.baseUrl + 'market/edit_pending_order_by_seller/',
      data
    );
  }
  // newEdit(id): Observable<any> {
  //   return this.http.put(
  //     this.baseUrl + 'market/new_edit_market_listing_product_or_service/',
  //     id:id
  //   );
  // }
  addEditSaleBook(data): Observable<any> {
    return this.http.put(
      this.baseUrl + 'sale/edit_sale_book_transaction/',
      data
    );
  }
  addExpense(data): Observable<any> {
    return this.http.post(
      this.baseUrl + 'expense/add_expense_book_transaction/',
      data
    );
  }
  editExpense(data): Observable<any> {
    return this.http.put(
      this.baseUrl + 'expense/edit_expense_book_transaction/',
      data
    );
  }
  addStockIn(data): Observable<any> {
    return this.http.post(
      this.baseUrl + 'inventory/add_stock_in_product/',
      data
    );
  }

  bundleStockIn(data): Observable<any> {
    return this.http.post(
      this.baseUrl + 'market/add_stock_in_bundle/',
      data
    );
  }
  bundleStockout(data): Observable<any>{
    return this.http.post(
      this.baseUrl + 'market/stock_out_in_bundle/',
      data
    );
  }
  addStockInByRef(data): Observable<any> {
    return this.http.post(
      this.baseUrl + 'inventory/stock_add_in_reference_no/',
      data
    );
  }
  
  editBundle(data,id):Observable<any>
  {
    return this.http.put(this.baseUrl + 'market/edit_bundle_in_market/'+ id +'/', data);
  }
  editStockIn(data): Observable<any> {
    return this.http.put(this.baseUrl + 'inventory/edit_stock_in/', data);
  }
  editStockOut(data): Observable<any>
  {
    return this.http.put(this.baseUrl + 'inventory/stock_out_edit/', data);
  }
  deletStockInTran(o_id, trn_id): Observable<any> {
    return this.http.delete(
      this.baseUrl + 'inventory/delete_single_stock/' + o_id + '/' + trn_id
    );
  }
  addBankrans(data): Observable<any> {
    return this.http.post(this.baseUrl + 'bank/add_bank_transaction/', data);
  }
  addCreditCard(data): Observable<any> {
    return this.http.post(this.baseUrl + 'credit_card/add_credit_card_with_opening_balance/', data);
  }
  addCreditCardDetail(data): Observable<any>{
    return this.http.post(this.baseUrl + 'credit_card/add_credit_card_transaction/', data)
  }
  editCreditCardDetails(data): Observable<any>
  {
    return this.http.put(this.baseUrl + 'credit_card/edit_credit_card_transaction/', data)
  }
  changeCardTitle(data): Observable<any>
  {
    return this.http.put(this.baseUrl + 'credit_card/edit_credit_card_room_title/', data)
  }
  getCreditCardDetails(): Observable<any> {
    return this.http.get(this.baseUrl + 'credit_card/credit_card_room_data/',);
  }
  editBankrans(data): Observable<any> {
    return this.http.put(this.baseUrl + 'bank/edit_bank_transaction/', data);
  }
  editCreditTrans(data): Observable<any> {
    return this.http.put(this.baseUrl + 'credit_card/edit_credit_card_transaction/', data);
  }
  addBank(data): Observable<any> {
    return this.http.post(
      this.baseUrl + 'bank/add_bank_with_opening_balance/',
      data
    );
  }
  editBankName(data): Observable<any> {
    return this.http.put(this.baseUrl + 'bank/edit_bank_title/', data);
  }
  deleteBank(id): Observable<any> {
    return this.http.delete(this.baseUrl + 'bank/delete_bank/' + id + '/');
  }
  addBankroomMember(b_id, e_id): Observable<any> {
    return this.http.post(this.baseUrl + 'bank/add_bank_room_member/', {
      emp_id: e_id,
      bank_room_id: b_id,
    });
  }
  addInventeryProduct(data): Observable<any> {
    return this.http.post(
      this.baseUrl + 'inventory/add_product_with_stock_detail/',
      data
    );
  }
  getProductCatListing(): Observable <any>{
    return this.http.post(
      this.baseUrl + 'inventory/get_product_category_listing/',
      {
        search_query: "",
      }
    );
  }
  getpaymentLink(user, id): Observable<any> {
    return this.http.get(
      this.baseUrl +
        'ledger/share_payment_reminder/?email=' +
        user +
        '&ledger=' +
        id
    );
  }


  getTransaction(data, page, filters, order): Observable<any> {
    return this.http.post(
      this.baseUrl +
        'ledger/get_transaction/' +
        data +
        '/?page=' +
        page +
        '&transaction_design=' +
        order,
      filters
    );
  }

  
  getCashTransaction(type, query, start, end, page): Observable<any> {
    return this.http.post(
      this.baseUrl + 'cash/cash_transaction_list/?page=' + page,
      {
        search_query: query,
        transaction_type: type,
        start_date: start,
        end_date: end,
      }
    );
  }
  getnventoryPro(query, page): Observable<any> {
    return this.http.post(
      this.baseUrl + 'inventory/get_products_data/?page=' + page,
      {
        search_query: query,

      }
    );
  }
  getnventoryProAccptReject(query, page,uom,bundle): Observable<any> {
    return this.http.post(
      this.baseUrl + 'inventory/get_products_data/?page=' + page,
      {
        search_query: query,
        uom:uom,
        bundle: bundle
      }
    );
  }
  getnventoryProFilter(query,type, page): Observable<any> {
    return this.http.post(
      this.baseUrl + 'inventory/get_products_data/?page=' + page,
      {
        search_query: query,
        product_category:type,
      }
    );
  }



  getinventoryProductStockData(
    id,
    start,
    end,
    type,
    query,
    page
  ): Observable<any> {
    return this.http.post(
      this.baseUrl + 'inventory/get_product_stock_data/?page=' + page,
      {
        search_query: query,
        product_id: id,
        start_date: start,
        end_date: end,
        stock_type: type,
      }
    );
  }

  getinventoryBundleStockData(
    id,
    start,
    end,
    type,
    query,
    page
  ): Observable<any> {
    return this.http.post(
      this.baseUrl + 'market/get_bundle_room_stock_listing/?page=' + page,
      {
        search_query: query,
        bundle_id: id,
        start_date: start,
        end_date: end,
        stock_type: type,
      }
    );
  }
  postStockOut(data) {
    return this.http.post(this.baseUrl + 'inventory/stock_out/', data);
  }
  postStockOutedit(data) {
    return this.http.put(this.baseUrl + 'inventory/stock_out_edit/', data);
  }
  getPurchaseTransaction(type, query, start, end, page): Observable<any> {
    return this.http.post(
      this.baseUrl + 'purchase/purchase_transaction_list/?page=' + page,
      {
        search_query: query,
        transaction_type: type,
        start_date: start,
        end_date: end,
      }
    );
  }
  getstockbyRef(pro_id, ref_no, start, end, type, querey): Observable<any> {
    return this.http.post(
      this.baseUrl +
        'inventory/get_product_stock_data_based_on_referance_no/?page=1',
      {
        search_query: querey,
        product_id: pro_id,
        reference_no: ref_no,
        start_date: start,
        end_date: end,
        stock_type: type,
      }
    );
  }
  getstockbyRefBundle(ref_no, start, end, type, querey): Observable<any> {
    return this.http.post(
      this.baseUrl +
        'market/get_bundle_room_stock_listing_with_reference_id/',
      {
        search_query: querey,
        reference_id: ref_no,
        start_date: start,
        end_date: end,
        stock_type: type,
      }
    );
  }
  getExpenseTransaction(query, start, end, page,type): Observable<any> {
    return this.http.post(
      this.baseUrl + 'expense/expense_transaction_list/?page=' + page,
      {
        search_query: query,
        transaction_type: type,
        start_date: start,
        end_date: end,
      }
    );
  }
  getSaleTransaction(query, start, end, type, page): Observable<any> {
    return this.http.post(
      this.baseUrl + 'sale/sale_transaction_list/?page=' + page,
      {
        search_query: query,
        transaction_type: type,
        start_date: start,
        end_date: end,
      }
    );
  }
  getBankTransaction(id,type, query, start, end, page): Observable<any> {
    return this.http.post(
      this.baseUrl + 'bank/bank_transaction_data/?page=' + page,
      {
        bank_room_id: id,
        search_query: query,
        transaction_type: type,
        start_date: start,
        end_date: end
      }
    );
  }

  getCreditTransaction(id,page): Observable<any> {
    return this.http.get(
      this.baseUrl + 'credit_card/single_credit_card_transaction/' + id +'/?page=' + page,
    );
  }
  getSingleCreditTransaction(filters,page): Observable<any>
  {
     return this.http.post(
      this.baseUrl + 'credit_card/credit_card_transaction_data/?page=' + page,
      filters
    );
  }
  deleteCreditCard(id): Observable<any> {
    return this.http.delete(this.baseUrl + 'credit_card/delete_credit_card_room/' + id + '/');
  }
  deleteCreditCardTransaction(id): Observable<any> {
    return this.http.delete(this.baseUrl + 'credit_card/delete_credit_card_transaction/' + id + '/');
  }
  getTransactionFilters(page, filters): Observable<any> {
    return this.http.post(
      this.baseUrl + 'ledger/search_transaction/?page=' + page,
      filters
    );
  }
  getLedgersRequests(): Observable<any> {
    return this.http.get(this.baseUrl + 'ledger/received_ledger_listing/');
  }
  openingBalance(data): Observable<any> {
    return this.http.post(this.baseUrl + 'ledger/add_opening_balance/', data);
  }
  acceptRejectRoom(id, status): Observable<any> {
    return this.http.post(
      this.baseUrl + 'ledger/accept_or_reject_ledger_room/' + id + '/',
      {
        status: status,
      }
    );
  }
  acceptRejectRoomVendor(id, status,purchase,expense): Observable<any> {
    return this.http.post(
      this.baseUrl + 'ledger/accept_or_reject_ledger_room/' + id + '/',
      {
        status: status,
        purchase_account: purchase,
        expense_account: expense
      }
    );
  }
  emailExist(email): Observable<any> {
    return this.http.post(this.baseUrl + 'user/check_user_email_exist/', {
      email: email,
    });
  }
  numberExist(number): Observable<any> {
    return this.http.post(this.baseUrl + 'user/check_user_phonenumber_exist/', {
      phone_number: number,
    });
  }
  resendRoomRequest(id): Observable<any> {
    return this.http.get(
      this.baseUrl + 'ledger/resend_ledger_room_request/' + id + '/'
    );
  }
  get_plans(): Observable<any> {
    return this.http.get(this.baseUrl + 'payments/plan_details');
  }
  getadminRequesttoJoin(): Observable<any> {
    return this.http.get(
      this.baseUrl + 'business/business_pending_request_to_join/'
    );
  }
  accpetjoiningRequest(id): Observable<any> {
    return this.http.post(
      this.baseUrl + 'business/approve_business_joining_request/',
      {
        request_id: id,
      }
    );
  }
  rejectjoiningRequest(id): Observable<any> {
    return this.http.post(
      this.baseUrl + 'business/reject_business_joining_request/',
      {
        request_id: id,
      }
    );
  }
  resendTransRequest(id): Observable<any> {
    return this.http.get(
      this.baseUrl + 'ledger/resend_transaction_request/' + id + '/'
    );
  }
  acceptTransaction(id, boolean,acountype:string='', bankid: any=null, cardid: any= null  ): Observable<any> {
    return this.http.put(
      this.baseUrl + 'ledger/update_transaction/' + id + '/',
      {
        status: boolean,
        record_type:acountype ,
        credit_card_room_id: cardid,
        bank_id: bankid,
      }
    );
  }
  syncUserEmailList(type) {
    return this.http.get(this.baseUrl + 'contacts/get_link/' + type + '/');
  }
  retrieveUserEmailList(type, code) {
    return this.http.get(
      this.baseUrl + 'contacts/get_contacts_list/' + type + '/?code=' + code
    );
  }
  sendrefreallink(data): Observable<any> {
    return this.http.post(this.baseUrl + 'contacts/send_referral_link_email/', {
      contacts_ids_list: data,
    });
  }
  addMemberlink(emails): Observable<any> {
    return this.http.post(
      this.baseUrl + 'contacts/send_join_business_request/',
      {
        emails,
      }
    );
  }
  sendInactveMemberlink(email, id): Observable<any> {
    return this.http.post(
      this.baseUrl + 'business/send_invite_pending_profile_assignment/',
      {
        email: email,
        inactive_emp_id: id,
      }
    );
  }
  ledgerCurrencies(): Observable<any> {
    return this.http.get(this.baseUrl + 'ledger/currency_list/');
  }
  transactionTypes(): Observable<any> {
    return this.http.get(this.baseUrl + 'ledger/transaction_type_list/');
  }
  getSummery(query, currency, order, type, pdf, page): Observable<any> {
    return this.http.get(
      // this.baseUrl + 'ledger/business_summary/?currency_id=5&search_query=One&order_by=asc&ledger_type=1&pdf_file=True'
      this.baseUrl +
        'ledger/business_summary/?currency_id=' +
        currency +
        '&search_query=' +
        query +
        '&order_by=' +
        order +
        '&ledger_type=' +
        type +
        '&pdf_file=' +
        pdf +
        '&page=' +
        page
    );
  }
  transactionViewStatus(id): Observable<any> {
    return this.http.get(
      this.baseUrl + 'ledger/transaction_view_status/' + id + '/'
    );
  }
  getContactList() {
    return this.http.get(this.baseUrl + 'contacts/synced_contacts_list/');
  }
  getPaymentPlans() {
    return this.http.get(this.baseUrl + 'payments/get_current_plan/');
  }
  getCSummaryCurrency() {
    return this.http.get(this.baseUrl + 'ledger/ledger_currency_list/');
  }
  getIpbaseCurrency() {
    return this.http.get(
      this.baseUrl + 'user/requested_ip_country_name_and_currency/'
    );
  }
  AccpRejAdminRec(id, value): Observable<any> {
    return this.http.post(
      this.baseUrl + 'business/accept_or_reject_admin_rights/',
      {
        request_id: id,
        response: value,
      }
    );
  }
  getalsumarymodules(id, start, end): Observable<any> {
    return this.http.post(this.baseUrl + 'ledger/all_modules_summary/', {
      currency_id: id,
      end_date: end,
      start_date: start,
    });
  }
  manuallyAddEmail(email): Observable<any> {
    return this.http.post(this.baseUrl + 'contacts/save_email_manually/', {
      email: email,
    });
  }
  addOneWaybussi(data): Observable<any> {
    return this.http.post(
      this.baseUrl + 'business/add_business_for_one_way_ledger/',
      data
    );
  }
  editOneWaybussi(data): Observable<any> {
    return this.http.put(
      this.baseUrl + 'ledger/edit_one_way_ledger_information_new/',
      data
    );
  }
  editInvPro(data): Observable<any> {
    return this.http.put(
      this.baseUrl + 'inventory/edit_product_information/',
      data
    );
  }
  editEmployeeLedgerTran(data): Observable<any> {
    return this.http.put(
      this.baseUrl + 'employee_ledger/edit_transaction_in_employee_ledger/',
      data
    );
  }
  deleteTransaction(id): Observable<any> {
    return this.http.delete(
      this.baseUrl + 'ledger/delete_transaction/' + id + '/'
    );
  }
  deleteInvPro(id): Observable<any> {
    return this.http.delete(
      this.baseUrl + 'inventory/delete_product_in_inventory/' + id + '/'
    );
  }
  deleteUserPic(): Observable<any> {
    return this.http.delete(this.baseUrl + 'user/remove_profile_image/');
  }
  leaveBusiness(): Observable<any> {
    return this.http.delete(this.baseUrl + 'business/leave_business/');
  }
  deleteBusinessPic(): Observable<any> {
    return this.http.delete(this.baseUrl + 'user/remove_business_image/');
  }
  editTransaction(form): Observable<any> {
    return this.http.put(this.baseUrl + 'ledger/edit_transaction/', form);
  }
  editOneWay_Ledger(form): Observable<any> {
    return this.http.put(
      this.baseUrl + 'ledger/edit_one_way_ledger_info/',
      form
    );
  }
  editCurrencyRoom(room_id, currency): Observable<any> {
    return this.http.put(this.baseUrl + 'ledger/edit_curreny_ledger_room/', {
      ledger_room_id: room_id,
      currency: currency,
    });
  }

  getshareLink(id): Observable<any> {
    return this.http.post(
      this.baseUrl + 'business/get_share_link_for_pending_profile/',
      {
        inactive_emp_id: id,
      }
    );
  }
  publicPermission(id,check): Observable<any> {
    return this.http.post(
      this.baseUrl + `ledger/room/${id}/public_permission/`,
      {
        is_public: check,
      }
    );
  }
  publicTransactions(id): Observable<any> {
    return this.http.post(
      this.baseUrl + `ledger/room/${id}/transactions/`,
      {}
    );
  }
  
  GetUserData(): Observable<any> {
    return this.http.get(
      this.baseUrl + 'business/employee_profile_limited_info/'
    );
  }
  getLedgerList(data, cur_id) {
    return this.http.post(
      this.baseUrl + 'ledger/search_ledger/?page=1&currency=' + cur_id,
      data
    );
  }
  removeParticipant(emp_id, room_id) {
    return this.http.post(this.baseUrl + 'ledger/remove_ledger_member/', {
      ledger_room_id: room_id,
      emp_id: emp_id,
    });
  }
  addParticipants(room_id, id) {
    return this.http.post(this.baseUrl + 'ledger/add_ledger_member/', {
      ledger_room_id: room_id,
      emp_id: id,
    });
  }
  addParticipantsCash(id) {
    return this.http.post(this.baseUrl + 'cash/add_cash_room_member/', {
      emp_id: id,
    });
  }
  addParticipantsSalary(id) {
    return this.http.post(this.baseUrl + 'manage/add_salary_room_member/', {
      emp_id: id,
    });
  }
  addParticipantsExpense(id) {
    return this.http.post(this.baseUrl + 'expense/add_expense_room_member/', {
      emp_id: id,
    });
  }
  addParticipantsSale(id) {
    return this.http.post(this.baseUrl + 'sale/add_sale_room_member/', {
      emp_id: id,
    });
  }
  addParticipantsPurchase(id) {
    return this.http.post(this.baseUrl + 'purchase/add_purchase_room_member/', {
      emp_id: id,
    });
  }
  removeParticipantsCash(id) {
    return this.http.post(this.baseUrl + 'cash/remove_cash_room_member/', {
      emp_id: id,
    });
  }
  removeParticipantsBank(id) {
    return this.http.post(this.baseUrl + 'bank/remove_bank_room_member/', {
      emp_id: id,
    });
  }
  removeParticipantsSalary(id) {
    return this.http.post(this.baseUrl + 'manage/remove_salary_room_member/', {
      emp_id: id,
    });
  }
  removeParticipantsExpense(id) {
    return this.http.post(
      this.baseUrl + 'expense/remove_expense_room_member/',
      {
        emp_id: id,
      }
    );
  }
  removeParticipantsInventory(id) {
    return this.http.post(
      this.baseUrl + 'invenotry/remove_inventory_room_member/',
      {
        emp_id: id,
      }
    );
  }
  removeParticipantsSale(id) {
    return this.http.post(this.baseUrl + 'sale/remove_sale_room_member/', {
      emp_id: id,
    });
  }
  removeParticipantsPurchase(id) {
    return this.http.post(
      this.baseUrl + 'purchase/remove_purchase_room_member/',
      {
        emp_id: id,
      }
    );
  }
  postSocialLogin(
    data,
    ul = false,
    key = '',
    user = '',
    type = '',
    referral = '',
    fromUser = ''
  ): Observable<any> {
    return this.http.post(
      this.baseUrl +
        'user/social_login/?ul=' +
        ul +
        '&r=' +
        key +
        '&a=' +
        user +
        '&type=' +
        type +
        '&referral=' +
        referral +
        '&from_username=' +
        fromUser,
      data
    );
  }
  postgoogleLogin(
    data,
  ): Observable<any> {
    return this.http.post(
      this.baseUrl +
        'user/social_login/', data
    );
  }
  postSocialLoginWechat(data): Observable<any> {
    return this.http.post(this.baseUrl + 'user/social_login/', data);
  }
  postSocialLoginApple(data): Observable<any> {
    return this.http.post(this.baseUrl + 'user/login_with_code/', data);
  }
  chargePaymentMarkting(data) {
    return this.http.post(this.baseUrl + 'payments/add_compaigns/', data);
  }
  campaigns() {
    return this.http.get(this.baseUrl + 'payments/all_active_compaigns/');
  }
  backuphistory() {
    return this.http.get(this.baseUrl + 'ledger/get_back_up_history/');
  }
  getfinishedPro(id) {
    return this.http.get(
      this.baseUrl + 'inventory/product_finished_stock_detail/' + id
    );
  }
  getfinishedBundle(id) {
    return this.http.get(
      this.baseUrl + 'inventory/product_finished_stock_detail/' + id
    );
  }
  postCodeToLinkedin(linkedInToken, redirectUrl) {
    // let data = {
    //   code: linkedInToken,
    //   redirect_uri: redirectUrl, // The same redirect_url used in step 2.1 (in login.component.ts)
    //   client_id: '78vkfxn5lfoskw', // Follow step 1.2
    //   client_secret: 'CbQ3GPqboVFABaKo', // Follow step 1.2
    //   grant_type: 'authorization_code', // value of this field should always be: 'authorization_code'

    // };
    let body =
      'code=' +
      linkedInToken +
      '&redirect_uri=' +
      redirectUrl +
      '&client_secret=CbQ3GPqboVFABaKo&client_id=78vkfxn5lfoskw&grant_type=authorization_code';
    return this.http.post(
      'https://www.linkedin.com/oauth/v2/accessToken',
      body
    );
  }
  addTransactionFiles(data): Observable<any> {
    return this.http.post(
      this.baseUrl + 'ledger/upload_multiple_transaction_file/',
      data
    );
  }
  addCashFiles(data): Observable<any> {
    return this.http.post(
      this.baseUrl + 'cash/upload_multiple_cash_files/',
      data
    );
  }
  adduploadInvFiles(data): Observable<any> {
    return this.http.post(
      this.baseUrl + 'inventory/upload_multiple_product_file/',
      data
    );
  }
  addInvFiles(data): Observable<any> {
    return this.http.post(
      this.baseUrl + 'inventory/upload_product_file/',
      data
    );
  }
  addSaleFiles(data): Observable<any> {
    return this.http.post(
      this.baseUrl + 'sale/upload_multiple_sale_files/',
      data
    );
  }
  addPurchaseFiles(data): Observable<any> {
    return this.http.post(
      this.baseUrl + 'purchase/upload_multiple_purchase_files/',
      data
    );
  }
  addExpensehFiles(data): Observable<any> {
    return this.http.post(
      this.baseUrl + 'expense/upload_multiple_expense_files/',
      data
    );
  }
  addBankFiles(data): Observable<any> {
    return this.http.post(
      this.baseUrl + 'bank/upload_multiple_bank_transaction_files/',
      data
    );
  }
  deleteBankTransaction(id): Observable<any> {
    return this.http.delete(
      this.baseUrl + 'bank/delete_bank_transaction/' + id + '/'
    );
  }
  downloadCsvPDF(id, start_date = 'all', end_date = 'all') {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    return this.http.get(
      this.baseUrl +
        'ledger/transaction_excel/?ledger_room_id=' +
        id +
        '&start_date=' +
        start_date +
        '&end_date=' +
        end_date +
        '&pdf_link=' +
        'False',
      httpOptions
      // { responseType : 'application/octet-stream' as 'json'}
    );
  }
  downloadFilterPDF(id, start_date = 'all', end_date = 'all') {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    // const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get(
      this.baseUrl +
        'ledger/transaction_pdf/?ledger_room_id=' +
        id +
        '&start_date=' +
        start_date +
        '&end_date=' +
        end_date +
        '&pdf_link=' +
        'False',
      httpOptions
    );
  }
  downloadBackUp() {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    return this.http.get(this.baseUrl + 'ledger/back_up_excel/', httpOptions);
  }



  // downloadCashRoomPDF(start_date = 'all', end_date = 'all', link) {
  //   const httpOptions = {
  //     responseType: 'blob' as 'json',
  //   };
  //   // const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  //   return this.http.post(
  //     this.baseUrl + 'cash/cash_transaction_pdf/',
  //     {
  //       start_date: start_date,
  //       end_date: end_date,
  //       transaction_type: 'all',
  //       pdf_link: link,
  //     },
  //     httpOptions
  //   );
  // }

  // downloadCashRoomPDF(start_date = 'all', end_date = 'all', link) {
  //   const httpOptions = {
  //     responseType: 'blob' as 'json',
  //   };

  //   return this.http.post(
  //     this.baseUrl + 'cash/cash_transaction_pdf/',
  //     {
  //       start_date: start_date,
  //       end_date: end_date,
  //       transaction_type: 'all',
  //       pdf_link: link,
  //     },
  //     { ...httpOptions, observe: 'response' }
  //   ).pipe(
  //     map(response => {
  //       debugger
  //       console.log(response);
  //       const headers: HttpHeaders = response.headers;
  //       const myHeader: string | null = headers.get('my-header-name');

  //       // Here you can access the header value or perform other operations

  //       return response.body; // Return the response body
  //     })
  //   );
  // }


  // downloadCashRoomPDF(start_date = 'all', end_date = 'all', link) {
  //   const httpOptions = {
  //     responseType: 'blob' as 'json',
  //   };
  //   return this.http.post(
  //     this.baseUrl + 'cash/cash_transaction_pdf/',
  //     {
  //       start_date: start_date,
  //       end_date: end_date,
  //       transaction_type: 'all',
  //       pdf_link: link,
  //     },
  //     { ...httpOptions, observe: 'response' }
  //   ).pipe(
  //     map(response => {
  //       debugger;
  //       const contentDispositionHeader = response.headers.get('Content-Disposition');
  //       if (contentDispositionHeader) {
  //         const filenameRegex = /filename[^;=\n]=((['"]).?\2|[^;\n]*)/;
  //         const matches = filenameRegex.exec(contentDispositionHeader);
  //         if (matches != null && matches[1]) {
  //           const filenameWithCharset = matches[1];
  //           const charsetIndex = filenameWithCharset.indexOf("''");
  //          if (charsetIndex !== -1) {
  //             return filenameWithCharset.slice(charsetIndex + 2);
  //           } else {
  //             return filenameWithCharset;
  //           }
  //         }
  //       }




  //       const ww = contentDispositionHeader.split(';')[1].split('filename')[1].split('=')[1].trim();
  //       console.log(ww);
  //       const name = response.headers.get('name');
  //       console.log(name);
  //       const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  //       const matches = filenameRegex.exec(contentDispositionHeader);
  //       let filename = null;
  //       if (matches != null && matches[1]) {
  //         filename = matches[1].replace(/['"]/g, '');
  //       }

  //       console.log('Filename:', filename);

  //       return response.body;
  //     })
  //   );
  // }


  downloadCashRoomPDF(start_date = 'all', end_date = 'all', link) {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    return this.http.post(
      this.baseUrl + 'cash/cash_transaction_pdf/',
      {
        start_date: start_date,
        end_date: end_date,
        transaction_type: 'all',
        pdf_link: link,
      },
      httpOptions
    );
  }

  downloadEmployeeTransectionRoomPDF(
    id,
    start_date = 'all',
    end_date = 'all',
    link
  ) {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    // const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(
      this.baseUrl + 'employee_ledger/employee_ledger_transactions_pdf/',
      {
        emp_detail_id: id,
        start_date: start_date,
        end_date: end_date,
        transaction_type: 'all',
        pdf_link: link,
      },
      httpOptions
    );
  }
  shareEmployeeTransectionRoomPDF(
    id,
    start_date = 'all',
    end_date = 'all',
    link
  ) {
    // const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(
      this.baseUrl + 'employee_ledger/employee_ledger_transactions_pdf/',
      {
        emp_detail_id: id,
        start_date: start_date,
        end_date: end_date,
        transaction_type: 'all',
        pdf_link: link,
      }
    );
  }
  shareCashRoomPDF(start_date = 'all', end_date = 'all', link) {
    // const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.baseUrl + 'cash/cash_transaction_pdf/', {
      start_date: start_date,
      end_date: end_date,
      transaction_type: 'all',
      pdf_link: link,
    });
  }
  shareInvProRoomPDF(start_date = 'all', end_date = 'all', id, link) {
    // const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.baseUrl + 'inventory/get_product_stock_pdf/', {
      product_id: id,
      start_date: start_date,
      end_date: end_date,
      stock_type: 'all',
      pdf_link: link,
    });
  }
  shareInvSinProRoomPDF(start_date = 'all', end_date = 'all', id, link) {
    // const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.baseUrl + 'inventory/get_single_stockin_pdf/', {
      stock_in_id: id,
      start_date: start_date,
      end_date: end_date,
      stock_type: 'all',
      pdf_link: link,
    });
  }
  downloadInvProRoomPDF(start_date = 'all', end_date = 'all', id, link) {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    // const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(
      this.baseUrl + 'inventory/get_product_stock_pdf/',
      {
        product_id: id,
        start_date: start_date,
        end_date: end_date,
        stock_type: 'all',
        pdf_link: link,
      },
      httpOptions
    );
  }
  downloadBundleRoomPDF(start_date: string, end_date: string, id: number, link: string) {
    const httpOptions = {
        responseType: 'blob' as 'json',
    };

    // Construct the API URL with is_pdf=true
    const apiUrl = `${this.baseUrl}market/get_bundle_room_stock_listing/?is_pdf=True`;

    // Construct the payload
    const payload = {
        bundle_id: id,
        start_date: "",
        end_date: "",
        stock_type: 'all',
        search_query: ""
    };

    return this.http.post(apiUrl, payload, httpOptions);
}



  downloadInvSinProPDF(start_date = 'all', end_date = 'all', id, link) {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    // const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(
      this.baseUrl + 'inventory/get_single_stockin_pdf/',
      {
        stock_in_id: id,
        start_date: start_date,
        end_date: end_date,
        stock_type: 'all',
        pdf_link: link,
      },
      httpOptions
    );
  }
  downloadExpenseRoomPDF(start_date = 'all', end_date = 'all', categoryId = 'all') {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    return this.http.post(
      `${this.baseUrl}expense/expense_transaction_pdf/`,
      {
        start_date: start_date,
        end_date: end_date,
        transaction_type: 'all',
        category_id: categoryId,
      },
      httpOptions
    );
  }
  // downloadExpenseRoomPDF(start_date = 'all', end_date = 'all', link) {
  //   const httpOptions = {
  //     responseType: 'blob' as 'json',
  //   };
  //   return this.http.post(
  //     this.baseUrl + 'expense/expense_transaction_pdf/',
  //     {
  //       start_date: start_date,
  //       end_date: end_date,
  //       transaction_type: 'all',
  //       pdf_link: link,
  //     },
  //     httpOptions
  //   );
  // }
  downloadPurchaseRoomPDF(start_date = 'all', end_date = 'all', link) {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    // const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(
      this.baseUrl + 'purchase/purchase_transaction_pdf/',
      {
        start_date: start_date,
        end_date: end_date,
        transaction_type: 'all',
        pdf_link: link,
      },
      httpOptions
    );
  }
  downloadBankRoomPDF(b_id, start_date = 'all', end_date = 'all', link) {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    // const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(
      this.baseUrl + 'bank/bank_transaction_pdf/',
      {
        start_date: start_date,
        end_date: end_date,
        search_query: '',
        transaction_type: 'all',
        bank_room_id: b_id,
        pdf_link: link,
      },
      httpOptions
    );
  }

  downloadCardRoomPDF(cc_id, start_date = 'all', end_date = 'all', link) {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    // const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(
      this.baseUrl + 'credit_card/credit_card_transaction_pdf/',
      {
        start_date: start_date,
        end_date: end_date,
        search_query: '',
        transaction_type: 'all',
        credit_card_room_id: cc_id,
        pdf_link: link,
      },
      httpOptions
    );
  }
  downloadSaleRoomPDF(start_date = 'all', end_date = 'all', link) {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    // const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(
      this.baseUrl + 'sale/sale_transaction_pdf/',
      {
        start_date: start_date,
        end_date: end_date,
        transaction_type: 'all',
        pdf_link: link,
      },
      httpOptions
    );
  }
  shareExpenseRoomPDF(start_date = 'all', end_date = 'all', link) {
    // const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.baseUrl + 'expense/expense_transaction_pdf/', {
      start_date: start_date,
      end_date: end_date,
      transaction_type: 'all',
      pdf_link: link,
    });
  }
  sharePurchaseRoomPDF(start_date = 'all', end_date = 'all', link) {
    // const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.baseUrl + 'purchase/purchase_transaction_pdf/', {
      start_date: start_date,
      end_date: end_date,
      transaction_type: 'all',
      pdf_link: link,
    });
  }
  shareBankRoomPDF(b_id, start_date = 'all', end_date = 'all', link) {
    // const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.baseUrl + 'bank/bank_transaction_pdf/', {
      start_date: start_date,
      end_date: end_date,
      search_query: '',
      transaction_type: 'all',
      bank_room_id: b_id,
      pdf_link: link,
    });
  }
  shareSaleRoomPDF(start_date = 'all', end_date = 'all', link) {
    // const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.baseUrl + 'sale/sale_transaction_pdf/', {
      start_date: start_date,
      end_date: end_date,
      transaction_type: 'all',
      pdf_link: 'True',
    });
  }
  downloadActiveInactivePDF() {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    // const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get(
      this.baseUrl + 'employee_ledger/active_inactive_employees_pdfs_zip/',
      httpOptions
    );
  }
  downloadActiveInactiveEcel() {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    // const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get(
      this.baseUrl + 'employee_ledger/active_inactive_employees_excel_file/',
      httpOptions
    );
  }
  attendance_sheet_pdfByDatePDF(month, year) {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    // const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(
      this.baseUrl + 'employee_ledger/attendance_sheet_pdf/',
      {
        month: month,
        year: year,
      },
      httpOptions
    );
  }
  attendance_sheet_excelByDate(month, year) {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    // const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(
      this.baseUrl + 'employee_ledger/attendance_sheet_excel/',
      {
        month: month,
        year: year,
      },
      httpOptions
    );
  }
  attendance_sheet_pdfByEmployeePDF(month, year, id) {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    // const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(
      this.baseUrl + 'employee_ledger/attendance_sheet_pdf/',
      {
        month: month,
        year: year,
        emp_detail_id: id,
      },
      httpOptions
    );
  }
  attendance_sheet_excelByEmployee(month, year, id) {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    // const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(
      this.baseUrl + 'employee_ledger/attendance_sheet_excel/',
      {
        month: month,
        year: year,
        emp_detail_id: id,
      },
      httpOptions
    );
  }
  downloadSummaryPDF(query, currency, order, type, pdf, page) {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    // const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get(
      this.baseUrl +
        'ledger/business_summary/?currency_id=' +
        currency +
        '&search_query=' +
        query +
        '&order_by=' +
        order +
        '&ledger_type=' +
        type +
        '&pdf_file=' +
        pdf +
        '&page=' +
        page,
      httpOptions
    );
  }
  downloadPaySlipPDF(id) {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    // const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get(
      this.baseUrl + 'manage/employee_salary_pdfs/' + id + '/',
      httpOptions
    );
  }
  sharePaySlipPDF(id, pdf) {
    // const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get(
      this.baseUrl + 'manage/employee_salary_pdfs/' + id + '/?file_id=True'
    );
  }
  downloadCashPDF(id, pdf) {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    // const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get(
      this.baseUrl +
        'cash/get_cash_single_transaction_pdf/' +
        id +
        '/?pdf_link=' +
        pdf,
      httpOptions
    );
  }
  downloadCreditPDF(id, pdf) {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    return this.http.get(
      this.baseUrl +
        'credit_card/get_credit_card_single_transaction_pdf/' +
        id +
        '/?pdf_link=' +
        pdf,
      httpOptions
    );
  }
  downloadInvSingleProPDF(o_id, trn_type, pdf) {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    // const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get(
      this.baseUrl +
        'inventory/pdf_or_link_single_stock/' +
        o_id +
        '/' +
        trn_type +
        '/?pdf_link=' +
        pdf,
      httpOptions
    );
  }
  downloadInvSingleProlink(o_id, trn_type, pdf) {
    // const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get(
      this.baseUrl +
        'inventory/pdf_or_link_single_stock/' +
        o_id +
        '/' +
        trn_type +
        '/?pdf_link=' +
        pdf
    );
  }
  downloadCashCSV(start, end) {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    // const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get(
      this.baseUrl +
        'cash/cash_transactions_excel/?start_date=' +
        start +
        '&end_date=' +
        end,
      httpOptions
    );
  }
  downloadEmployeeLedgerCSV(id, start, end) {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    // const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(
      this.baseUrl + 'employee_ledger/employee_ledger_transactions_excel/',
      {
        emp_detail_id: id,
        start_date: start,
        end_date: end,
      },
      httpOptions
    );
  }
  downloadSaleCSV(start, end) {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    // const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get(
      this.baseUrl +
        'sale/sale_transactions_excel/?start_date=' +
        start +
        '&end_date=' +
        end,
      httpOptions
    );
  }
  downloadPurchaseCSV(start, end) {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    // const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get(
      this.baseUrl +
        'purchase/purchase_transactions_excel/?start_date=' +
        start +
        '&end_date=' +
        end,
      httpOptions
    );
  }
  downloadExpenseCSV(start, end) {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    // const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get(
      this.baseUrl +
        'expense/expense_transactions_excel/?start_date=' +
        start +
        '&end_date=' +
        end,
      httpOptions
    );
  }
  downloadBankCSV(b_id, start, end) {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    // const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get(
      this.baseUrl +
        'bank/bank_transactions_excel/?bank_room_id=' +
        b_id +
        '&start_date=' +
        start +
        '&end_date=' +
        end,
      httpOptions
    );
  }
  downloadInvenotyCSV(p_id, start, end) {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    // const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get(
      this.baseUrl +
        'inventory/inventory_product_stock_excel/?product_id=' +
        p_id +
        '&start_date=' +
        start +
        '&end_date=' +
        end,
      httpOptions
    );
  }
  downloadInvenotysinCSV(p_id, start, end) {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    // const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get(
      this.baseUrl +
        'inventory/inventory_product_single_stock_in_excel/?stock_in_id=' +
        p_id +
        '&start_date=' +
        start +
        '&end_date=' +
        end,
      httpOptions
    );
  }
  downloadAllModuleSummary(link: boolean, pdf: boolean) {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    return this.http.post(
      this.baseUrl + 'ledger/all_modules_summary/',
      {
        pdf_link: link,
        is_pdf: pdf
      },
      httpOptions
    );
  }
  
  downloadBankPDF(id, pdf) {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    // const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get(
      this.baseUrl +
        'bank/get_bank_single_transaction_pdf/' +
        id +
        '/?pdf_link=' +
        pdf,
      httpOptions
    );
  }
  downloadSalePDF(id, pdf) {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    // const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get(
      this.baseUrl +
        'sale/get_sale_single_transaction_pdf/' +
        id +
        '/?pdf_link=' +
        pdf,
      httpOptions
    );
  }
  downloadExpensePDF(id, pdf) {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    // const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get(
      this.baseUrl +
        'expense/get_expense_single_transaction_pdf/' +
        id +
        '/?pdf_link=' +
        pdf,
      httpOptions
    );
  }
  downloadPurchasePDF(id, pdf) {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    // const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get(
      this.baseUrl +
        'purchase/get_purchase_single_transaction_pdf/' +
        id +
        '/?pdf_link=' +
        pdf,
      httpOptions
    );
  }
  downloadcashyPDF(id, pdf) {
    // const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get(
      this.baseUrl +
        'cash/get_cash_single_transaction_pdf/' +
        id +
        '/?pdf_link=' +
        pdf
    );
  }
  downloadsalePDF(id, pdf) {
    // const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get(
      this.baseUrl +
        'sale/get_sale_single_transaction_pdf/' +
        id +
        '/?pdf_link=' +
        pdf
    );
  }
  downloadexpensePDF(id, pdf) {
    // const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get(
      this.baseUrl +
        'expense/get_expense_single_transaction_pdf/' +
        id +
        '/?pdf_link=' +
        pdf
    );
  }
  downloadbankPDF(id, pdf) {
    return this.http.get(
      this.baseUrl +
        'bank/get_bank_single_transaction_pdf/' +
        id +
        '/?pdf_link=' +
        pdf
    );
  }
  downloadpurchasePDF(id, pdf) {
    // const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get(
      this.baseUrl +
        'purchase/get_purchase_single_transaction_pdf/' +
        id +
        '/?pdf_link=' +
        pdf
    );
  }
  shareFilterPDF(id, start_date = 'all', end_date = 'all') {
    return this.http.get(
      this.baseUrl +
        'ledger/transaction_pdf/?ledger_room_id=' +
        id +
        '&start_date=' +
        start_date +
        '&end_date=' +
        end_date +
        '&pdf_link=' +
        'True'
    );
  }

  downloadTransaction(id) {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    return this.http.get(
      this.baseUrl +
        'ledger/get_single_transaction_pdf/' +
        id +
        '/?pdf_link=' +
        'False',
      httpOptions
    );
  }

  shareTransaction(id) {
    return this.http.get(
      this.baseUrl +
        'ledger/get_single_transaction_pdf/' +
        id +
        '/?pdf_link=' +
        'True'
    );
  }

  getWeChatAccess(app, seceret, code) {
    return this.http.get(
      'https://api.weixin.qq.com/sns/oauth2/access_token?appid=' +
        app +
        '&secret=' +
        seceret +
        '&code=' +
        code +
        '&grant_type=authorization_code'
    );
  }

  postLinkedInToken(linkedInToken): Observable<any> {
    let body = {
      redirectUrl: this.linkedIn.redirectUrl,
      token: linkedInToken,
      c_seceret: 'CbQ3GPqboVFABaKo',
      c_id: '78vkfxn5lfoskw',
    };
    return this.http.post(this.baseUrl + 'user/linkedIn_access_token/', body);
  }

  // getLinkedinUserData(token): Observable<any> {
  //   var header = {
  //     headers: new HttpHeaders()
  //       .set('Authorization',  `Bearer ${token}`)
  //   }
  //   return this.http.get(
  //     'https://api.linkedin.com/v2/me',
  //     header
  //   );
  // }
  addEmployee(data) {
    return this.http.post(this.baseUrl + 'manage/employee/', data);
  }
  editEmployee(data) {
    return this.http.put(this.baseUrl + 'manage/employee/', data);
  }
  getEmployee(data) {
    return this.http.get(this.baseUrl + data);
  }
  qrLogin(){
    return this.http.get(this.baseUrl + 'qr_code/generate_qr_code_request/');
  }
  getEmployeeSaleryDetail(id) {
    return this.http.get(this.baseUrl + 'manage/employee_salary_details/' + id);
  }
  movetoledger(id) {
    return this.http.post(
      this.baseUrl + 'employee_ledger/add_pay_slip_to_ledger/',
      {
        invoice_id: id,
      }
    );
  }
  getEmployeeLedger(start = '', end = '', search = '', type = '', id, page) {
    return this.http.post(
      this.baseUrl +
        'employee_ledger/get_employee_ledger_transactions_list/?page=' +
        page,
      {
        emp_detail_id: id,
        end_date: end,
        search_query: search,
        start_date: start,
        transaction_type: type,
      }
    );
  }
  addEmployeeLedgerTran(data) {
    return this.http.post(
      this.baseUrl + 'employee_ledger/add_transaction_to_employee_ledger/',
      data
    );
  }
  addSlipToLedger(id, data) {
    return this.http.post(
      this.baseUrl + 'manage/employee_salary_addition/' + id + '/',
      {
        additions: data,
      }
    );
  }
  deletededution(id) {
    return this.http.delete(
      this.baseUrl + 'manage/employee_salary_addition_update/' + id + '/'
    );
  }
  editeDeductions(id, data) {
    return this.http.put(
      this.baseUrl + 'manage/employee_salary_addition_update/' + id + '/',
      data
    );
  }
  addCashBankDuplicate(data) {
    return this.http.post(
      this.baseUrl + 'employee_ledger/duplicate_transaction_in_cash_bank/',
      data
    );
  }
  getEmpAttendanceList(url) {
    return this.http.get(this.baseUrl + url);
  }
  getEmpAttendanceDetails(id, date) {
    return this.http.get(
      this.baseUrl +
        'manage/employee_attandance_details/' +
        id +
        '/' +
        date +
        '/'
    );
  }
  addExpenseCategory(data: any = null): Observable<any> {
    return this.http.post(
      this.baseUrl + 'expense/add_expense_category/',
      {
        "title": data
      }
    );
  }
  editExpenseCategory(data: any = null, id: any = null): Observable<any> {
    return this.http.put(
      this.baseUrl + 'expense/edit_expense_category/'+id+'/',
      {
        "title": data
      }
    );
  }
  deleteExpenseCategory(id: any = null): Observable<any> {
    return this.http.delete(
      this.baseUrl + 'expense/edit_expense_category/'+id+'/'
    );
  }
  getExpenseCategory(query: any = null, page: any = 1): Observable<any> {
    return this.http.post(
      this.baseUrl + 'expense/get_expense_category_listing/?page='+page,
      {
        "search_query": query
      }
    );
  }
  employeeInactive(id, date) {
    return this.http.put(this.baseUrl + 'manage/employee_inactive/', {
      deactivate_date: date,

      id: id,
    });
  }
  markPublicHoliday(date, holiday) {
    return this.http.post(
      this.baseUrl + 'manage/public_holiday_all/' + date + '/',
      {
        public_holiday: holiday,
      }
    );
  }
  markPresentAbsent(id, date, data) {
    return this.http.post(
      this.baseUrl + 'manage/employee_attandance/' + id + '/' + date + '/',
      data
    );
  }
  addExtraHours(id, date, data) {
    return this.http.post(
      this.baseUrl + 'manage/extra_hours_fix/' + id + '/' + date + '/',
      data
    );
  }
  getpaySlipDetail(emp_id, slip_id) {
    return this.http.get(
      this.baseUrl +
        'manage/employee_salary_breakdown/' +
        emp_id +
        '/' +
        slip_id +
        '/'
    );
  }
  getfilesByCatInvoices(type, query, start, end, page: any = 1,id: any=0): Observable<any> {
    return this.http.post(
      this.baseUrl + 'drive/get_all_folder_files/?page=' + page,
      {
        search_query: query,
        type: 'invoice',
        end_date: end,
        start_date: start,
        bill_board_invoice_id:id,
      }
    );
  }
  getfilesByCat(type, query, start, end, page: any = 1,id: any=0): Observable<any> {
    return this.http.post(
      this.baseUrl + 'drive/get_all_folder_files/?page=' + page,
      {
        search_query: query,
        type: type,
        end_date: end,
        start_date: start,
        room_id:id,
      }
    );
  }
  getBankFiles(query, start, end, page: any = 1): Observable<any> {
    return this.http.post(
      this.baseUrl + 'drive/get_bank_rooms_for_drive/?page=' + page,
      {
        search_query: query,
        end_date: end,
        start_date: start,
      }
    );
  }
  getCreditCardFiles(query, start, end, page: any = 1): Observable<any> {
    return this.http.post(
      this.baseUrl + 'drive/get_credit_card_rooms_for_drive/?page=' + page,
      {
        search_query: query,
        end_date: end,
        start_date: start,
      }
    );
  }
  getsalaryemployeelist(page = 1){
    return this.http.get(
      this.baseUrl + 'drive/all_salary_employees_for_drive/?page=' + page
    );
  }
  getsalaryfiles(page=1,id,query, start, end,){
    return this.http.post(
      this.baseUrl + 'drive/employee_salary_room_files/?page=' + page,
      {
        emp_id:id,
        search_query: query,
        end_date: end,
        start_date: start,
      }
    )
  }
  ledgerroom(query) {
    return this.http.post(
      this.baseUrl + 'drive/all_ledger_rooms_for_drive/?page=1',
      {
        account_type: query,
      }
    );
  }
  getledgerroomfile(
    id: any,
    query: any = '',
    startDate: any = '',
    endDate: any = '',
    page: any = 1
  ): Observable<any> {
    return this.http.post(
      this.baseUrl + 'drive/ledger_room_files/?page=' + page,
      {
        search_query: query,
        ledger_room_id: id,
        start_date: startDate,
        end_date: endDate,
        // type: 'ledger',
      }
    );
  }
  getinvoicefolders( type, query: any = '', startDate: any = '', endDate: any = '', page: any = 1): Observable<any> {
    return this.http.post(
      this.baseUrl + 'drive/get_invoices_for_drive/?page=' + page,
      {
        bill_board_transaction_type: type,
        search_query: query,
        start_date: startDate,
        end_date: endDate,
      }
    );
  }
  // getpaymentsession(id: string) {
  //   return this.http.get(
  //     this.baseUrl + 'payments/payment-checkout-session/' + 2 + '/'
  //   );
  // }
  getpaymentsession(id: string) {
    return this.http.get(
      this.baseUrl + 'payments/one-time-payment-checkout-session/' + id + '/'
    );
  }
  getShareInvoice(id: number, pdfBoolean: any = 'False') {
    return this.http.get(
      this.baseUrl +
        'bill_board/invoice_book_pdf/' +
        id +
        '/?pdf_link=' +
        pdfBoolean
    );
  }
  getWeChatSession(body: object) {
    return this.http.post(
      'https://api.mch.weixin.qq.com/v3/pay/transactions/jsapi',
      body
    );
  }

  /* Bill Board Module Api's */
  getBillListing(page: number = 1, query: string = '') {
    return this.http.post(
      this.baseUrl + 'bill_board/get_ledger_listing/?page=' + page,
      {
        search_query: query,
      }
    );
  }
  postItemBillBoard(body: any) {
    return this.http.post(
      this.baseUrl + 'bill_board/add_item_or_service/',
      body
    );
  }
  postAddTax(body: any) {
    return this.http.post(
      this.baseUrl + 'bill_board/add_tax_and_percentage/',
      body
    );
  }
  ReqDiscount(body: any){
    return this.http.post (
      this.baseUrl + 'market/send_discount_request/',
      body
      );
  }

  changeMarketVisibility(body: any){
    return this.http.put (
      this.baseUrl + 'market/new_edit_market_listing_product_or_service/',
      {
        "id": body?.id,
        "market_visibility": body.visibility
      }
      );
  }

  getTaxlisting(query) {
    return this.http.post(
      this.baseUrl + 'bill_board/get_tax_and_percentage_listing/',
      {
        search_query: query,
      }
    );
  }
  
  deletetax(id: number) {
    return this.http.delete(
      this.baseUrl +
        'bill_board/delete_tax_and_percentage/' +
        id +
        '/'
    );
  }
  editItemBillBoard(body: any, id: any) {
    return this.http.put(
      this.baseUrl + 'bill_board/edit_item_or_service/' + id + '/',
      body
    );
  }
  editTaxes(body: any, id: any) {
    return this.http.put(
      this.baseUrl + 'bill_board/edit_tax_and_percentage/' + id + '/',
      body
    );
  }

  getComment(id: any)
  {
    return this.http.get(
      this.baseUrl + 'bill_board/'+id+'/comments/');
  }

  postComment(body: any, id: any) {
    return this.http.post(
      this.baseUrl +  'bill_board/'+id+'/comments/',
      {
        description: body
      }
    );
  }
  postCommentFromMarket(body: any, id: any) {
    return this.http.post(
      this.baseUrl +  'market/orders/'+id+'/comments/',
      {
        description: body
      }
    );
  }
  getCommentofMarket(id: any)
  {
    return this.http.get(
      this.baseUrl + 'market/orders/'+id+'/comments/');
  }
  editBusinessInfo(body: any) {
    return this.http.put(
      this.baseUrl + 'bill_board/get_edit_your_business_information/',
      body
    );
  }
  
  updateBusinessInfoPic(data): Observable<any> {
    return this.http.post(
      this.baseUrl + 'business/upload_sign_stamp_images/',
      data
    );
  }
  deleteItemBillBoard(id: any) {
    return this.http.delete(
      this.baseUrl + 'bill_board/delete_item_or_service/' + id + '/'
    );
  }
  getItemAndServiceListing(page: number = 1, query: string = '') {
    return this.http.post(
      this.baseUrl + 'bill_board/get_item_and_service_listing/?page=' + page,
      {
        search_query: query,
      }
    );
  }
  getInvoicesNumber(type:any){
    return this.http.post(
      this.baseUrl + 'bill_board/generate_next_invoice_series/',
      {
        transaction_type_id: type,
      });
  }
  postInvoiceSeries(type,status:boolean=true,Numbr,Check:boolean=true,PrefixText:string){
    return this.http.post(
      this.baseUrl + 'bill_board/add_invoice_series/',
      {
        bill_board_transaction_type: type,
        manual_assign:Numbr,
        prefix_status:status,
        numeric_series:Check,
        prefix_text:PrefixText
      });
  }

  getDiveRecentFile(querey: any='',start_date:any='', end_date: any='', page: number=1){
    return this.http.post(
      this.baseUrl + 'drive/recent_files/?page='+ page,
      {
        search_query: '',
        start_date: start_date,
        end_date: end_date
      });
  }



  // getDiveRecentFile(querey: any='',start_date:any='', end_date: any='', page: number=1){
  //   return this.http.post (
  //     this.baseUrl + 'api/drive/recent_files/?'+ page,
  //   {
  //     {
  //       search_query: querey,
  //       // start_date: 
  //       // end_date: 
  //   }
  //   }
  //     );
  // }
  getinvoiceSeries() {
    return this.http.get(this.baseUrl + 'bill_board/get_invoice_series/');
  }
  getReferenceBill() {
    return this.http.get(this.baseUrl + 'bill_board/generate_reference_no/');
  }
  getAllPaymentTerms(query: any = '') {
    return this.http.post(this.baseUrl + 'bill_board/get_payment_term_listing/', { search_query: query});
  }
  postPaymentTerms(body: any = {}) {
    return this.http.post(this.baseUrl + 'bill_board/add_payment_term/', body);
  }
  editPaymentTerms(body: any = {}) {
    return this.http.put(this.baseUrl + 'bill_board/edit_payment_term/'+ body?.id +'/', body);
  }
  deletePaymentTerms(id: any = '') {
    return this.http.delete(this.baseUrl + 'bill_board/edit_payment_term/'+ id +'/');
  }
  getBillBoardTypes() {
    return this.http.get(this.baseUrl + 'bill_board/get_bill_board_types/');
  }
  getBusinessInfo() {
    return this.http.get(
      this.baseUrl + 'bill_board/get_edit_your_business_information/'
    );
  }
  getEditYourBusinessInfo() {
    return this.http.get(
      this.baseUrl + 'bill_board/get_edit_your_business_information/'
    );
  }
  editYourBusinessInfo(body: any) {
    return this.http.get(
      this.baseUrl + 'bill_board/get_edit_your_business_information/',
      body
    );
  }
  uploadSignStampBillBoard(file: any) {
    return this.http.post(
      this.baseUrl + 'bill_board/upload_sign_stamp_images/',
      {
        fileToUpload: file,
      }
    );
  }
  Aceceptrejectbills(id,body:any){
    return this.http.post(
      this.baseUrl + 'bill_board/accept_or_reject_invoice_by_receiver/',
      {
        "id": id,
        "accept_or_reject_status": body
      }
    );
  }
  getSingleInvoiceBookInfo(id: number) {
    return this.http.get(
      this.baseUrl +
        'bill_board/get_single_invoice_book_information/' +
        id +
        '/'
    );
  }
  getTransactionSingleInvoice(id: number) {
    return this.http.get(
      this.baseUrl +
        'bill_board/get_single_bill_board_summary/' +
        id +
        '/'
    );
  }
  getBillBookfiles(id: number) {
    return this.http.get(
      this.baseUrl +
        'bill_board/files/' +
        id +
        '/'
    );
  }
  getSingleSaleTransaction(id: number) {
    return this.http.get(
      this.baseUrl +
        'sale/sale_single_transaction/' +
        id +
        '/'
    );
  }
  getSinglePurchaseTransaction(id: number) {
    return this.http.get(
      this.baseUrl +
        'purchase/purchase_single_transaction/' +
        id +
        '/'
    );
  }
  getSingleExpenseTransaction(id: number) {
    return this.http.get(
      this.baseUrl +
        'expense/expense_single_transaction/' +
        id +
        '/'
    );
  }
  getSingleTransInvoice(id: number) {
    return this.http.get(
      this.baseUrl +
        'bill_board/get_single_invoice_summary/' +
        id +
        '/'
    );
  }
  getPDFInvoice(id: number, pdfBoolean: boolean = false) {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    return this.http.get(
      this.baseUrl +
        'bill_board/invoice_book_pdf/' +
        id +
        '/?pdf_link=' +
        pdfBoolean,
      httpOptions
    );
  }
  getPDFSingleInvoice(id: number, pdfBoolean: boolean = true) {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    return this.http.get(
      this.baseUrl +
        'bill_board/get_single_bill_board_summary/' +
        id +
        '/?is_pdf=' +
        pdfBoolean,
      httpOptions
    );
  }
  getPDFRooms(
    billBoardTransactionType: number,
    isPdf: boolean = true,
    startDate?: string,
    endDate?: string,
    productCategoryId?: number,
    userId?: number,
    itemName?: string,
    isLink?: boolean,
    itemsList?: string[],
  ) {
    let url = this.baseUrl + 'bill_board/rooms/?is_pdf=' + isPdf
      if (startDate) {
      url += '&start_date=' + startDate;
    }
    if (endDate) {
      url += '&end_date=' + endDate;
    }
    if (productCategoryId) {
      url += '&product_category_id=' + productCategoryId;
    }
    if (userId) {
      url += '&user_id=' + userId;
    }
    if (itemName) {
      url += '&item_name=' + itemName;
    }
    if (isLink !== undefined) {
      url += '&is_link=' + isLink;
    }
    if (itemsList && itemsList.length > 0) {
      url += '&items_list=' + itemsList.join(',');
    }
  
    // Append required parameter
    url += '&bill_board_transaction_type=' + billBoardTransactionType;
  
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
  
    return this.http.get(url, httpOptions);
  }

  getInventoryStockFilter(
    filterType: string = 'category',
    productCategoryId: string ,
    isPdf: boolean = true,
    isLink: boolean = false
  ) {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    return this.http.get(
      this.baseUrl +
        'inventory/stock/filter/?' +
        'filter_type=' +
        filterType +
        '&product_category_id=' +
        productCategoryId +
        '&is_pdf=' +
        isPdf,
      httpOptions
    );
  }

  
  getPDFPublic(id: any, pdfBoolean: boolean = true) {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    return this.http.post(
      this.baseUrl +
        `ledger/room/${id}/transactions/?is_pdf=${pdfBoolean}`,
      httpOptions
    );
  }
  

  getpdfmarket(id: number, pdfBoolean: boolean = false){
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    return this.http.get(
      this.baseUrl +
        'market/order_pdf/' +
        id +
        '/?pdf_link=' +
        pdfBoolean,
      httpOptions
    );
  }
  
  deleteSingleInvoiceBookInfo(id: number) {
    return this.http.delete(
      this.baseUrl + 'bill_board/delete_invoice_book_information/' + id + '/'
    );
  }
  postDuplicateInvoice(body: any) {
    return this.http.post(
      this.baseUrl + 'bill_board/duplicate_invoice_data/',
      body
    );
  }
  editBillBoard(body: any) {
    return this.http.put(this.baseUrl + 'bill_board/edit_bill_board/', body);
  }
  postacceptreject(body: any)
  {
    return this.http.post(this.baseUrl + 'bill_board/accept_or_reject_sale_invoice_by_receiver/', body);
  }
  postPurchaseAccept(body: any)
  {
    return this.http.post(this.baseUrl + 'bill_board/accept_or_reject_purchase_bill_by_receiver/', body);
  }
  postExpenseAccept(body: any)
  {
    return this.http.post(this.baseUrl + 'bill_board/accept_or_reject_expense_return_by_receiver/', body);
  }
  postBillBoard(body: any) {
    return this.http.post(this.baseUrl + 'bill_board/add_bill_board/', body);
  }

  getFilterInvoiceBook(page: number = 1, body) {
    return this.http.post(
      this.baseUrl + 'bill_board/get_filter_invoice_book_listing/?page=' + page,
      body
    );
  }

  /* new addons api's for slary module */

  getListOfAdones(type: string) {
    return this.http.get(
      this.baseUrl + 'manage/list_self_adons/' + type + '/'
    );
  }
  editAdones(body: any, id: any) {
    return this.http.put(
      this.baseUrl + 'manage/edit_self_adons/'+id+'/', body
    );
  }
  deleteSingleAdon(id: number) {
    return this.http.delete(
      this.baseUrl + 'manage/delete_self_adons/' + id + '/'
    );
  }
  postAdones(body: any, id: any) {
    return this.http.post(
      this.baseUrl + 'manage/employee_salary_addition/'+id+'/', {
        additions: body,
      }
    );
  }
  editAdonesEmployee(body: any, id: any) {
    return this.http.put(
      this.baseUrl + 'manage/employee_salary_addition_update/'+id+'/', {
        additions: [body],
      }
    );
  }


  addProductMarket(data): Observable<any>{
    return this.http.post(
      this.baseUrl + 'market/add_product_or_service_in_market/', 
      data
    );
  }

  addBundlePost(data): Observable<any>{
    return this.http.post(
      this.baseUrl + 'market/add_bundle_in_market/', 
      data
    );
  }

  getmarketlisting(id, query,type, page: any = 1) {
    return this.http.post(
      this.baseUrl + 'market/get_market_listing/?page='+page, {
        business_id:id,
        search_query: query,
        type: type,
      }
    );
  }
  getBundleLsiting(query, page: any=1)
  {
    return this.http.post(
      this.baseUrl + 'market/get_bundle_listing_in_market/?page='+page, {
        search_query: query,
      }
    );
  }
  getMarketListingBundles(id, query, type, page: any = 1, bundletype, visibility: any ='') {
    return this.http.post(
      this.baseUrl + 'market/get_market_listing/?page=' + page, {
        business_id: id,
        search_query: query,
        type: type,
        bundle: bundletype,
        category_id: 0,
        market_visibility: visibility,
      }
    );
  }
  getpendingorder(query,type,othertype, page: any = 1) {
    return this.http.post(
      this.baseUrl + 'market/pending_and_finished_order_listing/?page='+page, {
        search_query: query,
        type: type,
        order_type:othertype
      }
    );
  }
  getmarketconnection(query) {
    return this.http.post(
      this.baseUrl + 'market/get_connected_business_list/', {
        search_query: query,
      }
    );
  }
  addToCart(id,quantity) {
    return this.http.post(
      this.baseUrl + 'market/add_to_cart_product_or_service/', {
        market_product: id,
        quantity_ordered:quantity
      }
    );
  }
  getcartitems() {
    return this.http.get(
      this.baseUrl + 'market/get_cart_items_detail/' 
    );
  }
  ConfirmOrder(id,items) {
    return this.http.post(
      this.baseUrl + 'market/confirm_cart_order/', {
        cart_id: id,
        items:items
      }
    );
  }
  getPendingorderDetail(id: number) {
    return this.http.get(
      this.baseUrl +
        'market/pending_or_finish_order_detail/' +
        id +
        '/'
    );
  }
  getAcceptRejectandmakeinvoice(id: number,make_invoice:boolean) {
    return this.http.post(
      this.baseUrl +
        'market/make_invoice_or_reject_order/' +
        id +
        '/',{
          make_invoice:make_invoice,
        }
    );
  }
  getproductServiceDetail(id: number) {
    return this.http.get(
      this.baseUrl +
        'market/get_product_or_service_detail/' +
        id +
        '/'
    );
  }
  deletecartitem(id: number) {
    return this.http.delete(
      this.baseUrl +
        'market/delete_cart_item/' +
        id +
        '/'
    );
  }

  deletePendingitem(id: number) {
    return this.http.delete(
      this.baseUrl +
        'market/delete_pending_order/' +
        id +
        '/'
    );
  }
  deleteProduct(id: number) {
    return this.http.delete(
      this.baseUrl +
        'market/delete_product_or_service_in_market/' +
        id +
        '/'
    );
  }
  EmptyCart() {
    return this.http.delete(
      this.baseUrl +
        'market/empty_cart/'
    );
  }
  AddtoLedgerMarket(id: number) {
    return this.http.get(
      this.baseUrl +
        'market/add_to_ledger/' +
        id +
        '/'
    );
  }
  addParticipantsMarket(id) {
    return this.http.post(this.baseUrl + 'market/add_market_room_member/', {
      emp_id: id,
    });
  }

  addinvoiceRecord(id,ctype,type){
    return this.http.post(this.baseUrl + 'ledger/add_invoice_record_in_cplus_module/',{
      invoice_id:id,
      cplus_type:ctype,
      transaction_type:type
    });
  }
  addinvoiceRecordLedger(id, ledgerId , type, childType){
    return this.http.post(this.baseUrl + 'ledger/add_invoice_record_in_ledger/',{
      invoice_id: id,
      ledger_id: ledgerId,
      transaction_type: type,
      transaction_sub_type: childType
    });
  }
  EditMarketProduct(data): Observable<any> {
    return this.http.put(
      this.baseUrl + 'market/new_edit_market_listing_product_or_service/',
      data
    );
  }
}
