
  <div class="footer-comp container">
    <div class="d-flex justify-content-between">
      <div>
        <div class="d-flex Social">
          <li>
            <a class="nav-link" href="https://www.facebook.com/accountect" target="_blank"><img src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/facebook.svg" alt=""></a>
          </li>
          <li>
            <a class="nav-link" href="https://www.instagram.com/accountect" target="_blank"><img src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/instagram.svg" alt=""></a>
          </li>
          <li>
            <a class="nav-link" href="https://www.linkedin.com/company/accountect" target="_blank"><img src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/linkedin.svg" alt=""></a>
          </li>
          <li>
            <a class="nav-link" href="https://www.pinterest.com/accountect" target="_blank"><img src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/pinterest.svg" alt=""></a>
          </li>
          <li>
            <a class="nav-link" href="https://www.twitter.com/accountect" target="_blank"><img src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/twitter.svg" alt=""></a>
          </li>
          <li>
            <a class="nav-link" href="https://youtube.com/@accountect" target="_blank"><img src="../../assets/youtube.svg" alt="{{'youtube' | translate}}"></a>
          </li>
        </div>
        <div class="px-3 mt-4 privacy1">
          <p><a href="https://www.accountect.com/about-us/" target="_blank" rel="">{{'About Us' | translate}}</a></p>
          <p><a href="https://www.accountect.com/contact-us/" target="_blank" rel="">{{'Contact Us' | translate}}</a></p>
          
          <p (click)="navigateTo(auth.targetLanguage + '/privacy-policy')">{{'Privacy Policy' | translate}}</p>
          <p (click)="navigateTo(auth.targetLanguage + '/terms-conditions')">{{'Terms and Conditions' | translate}}</p>
        </div>
        <div class="px-3 mt-5r">
          <p class="direction-ltr"><b>Copyrights © Accountect Technologies Inc.</b> </p>
        </div>
      </div>
      <div class="direction-ltr">
        <div class="px-07" [routerLink]="['/']">
          <img width="150px" src="../../../../assets/Accountect/topbarlogo.svg">
        </div>
        <div class="d-flex mt-2">
          <a href="https://play.google.com/store/apps/details?id=com.hailu.accountect&hl=en"  target="_blank"><img src="../../assets/Playstore en.svg" width="140px" alt="ciwac android app"></a>
          <a href="https://apps.apple.com/app/accountect-accounting/id6474992652" target="_blank"><img src="../../assets/Apple en.svg" width="140px" alt="ciwac ios app"></a> 
         </div>
        <div class="w-100 d-flex align-items-center justify-content-center">
          <div class="p-2">
            <div class="text-center"><span>{{'Android'}}</span></div>
            <div>
                <ngx-qrcode [elementType]="elementType" [value]="qr_value_ad"
                    cssClass="aclass w-100 h-100 p-3 bg-light" errorCorrectionLevel="L">
                </ngx-qrcode>
            </div>
          </div>
          <div class="p-2">
            <div class="text-center"><span>{{'IOS'}}</span></div>
            <div>
                <ngx-qrcode [elementType]="elementType" [value]="qr_value_ios"
                    cssClass="aclass w-100 h-100 p-3 bg-light" errorCorrectionLevel="L">
                </ngx-qrcode>
            </div>
          </div>
          <div class="p-2 d-none">
            <div class="text-center"><span>{{'Youtube'}}</span></div>
            <div>
                <ngx-qrcode [elementType]="elementType" [value]="qr_value_youtube"
                    cssClass="aclass w-100 h-100 p-3 bg-light" errorCorrectionLevel="L">
                </ngx-qrcode>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>