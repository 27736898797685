import { Routes } from '@angular/router';

export const content: Routes = [
  {
    path: 'market',
    loadChildren: () => import('../../components/Market/market/market.module').then(m => m.MarketModule)
  },
  {
    path: 'ledger',
    loadChildren: () => import('../../components/ledger/ledger.module').then(m => m.LedgerModule),
    data: { preload: true, delay: false }
  },
  {
    path: 'user',
    loadChildren: () => import('../../components/user/user.module').then(m => m.UserModule),
    data: { preload: true, delay: false }
  },
  {
    path: 'purchase',
    loadChildren: () => import('../../components/purchase/purchaseParent.module').then(m => m.PurchaseParentModule)
  },
  {
    path: 'expense',
    loadChildren: () => import('../../components/expense/expenseParent.module').then(m => m.ExpenseParentModule)
  },
  {
    path: 'drive',
    loadChildren: () => import('../../components/drive/driveParent.module').then(m => m.DriveParentModule)
  },
  {
    path: 'cashbank',
    loadChildren: () => import('../../components/cash-and-bank/cashAndBankParent.module').then(m => m.CashAndBankParentModule)
  },
  {
    path: 'sale',
    loadChildren: () => import('../../components/sales/salesParent.module').then(m => m.SalesParentModule)
  },
  {
    path: 'items',
    loadChildren: () => import('../../components/items/itemsParent.module').then(m => m.ItemsParentModule)
  },
];
