// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  dev_socket: "ws://127.0.0.1:8000/",
  apidevUrl:'http://127.0.0.1:9000/api/',
  dev_url: "http://192.168.18.64:8000/api/chat/",
  build_type: 'ciwac',
  // build_type: 'niwac',
  // development
  // prod_url: "https://apidev.hiwac.com/api/chat/",
  // prod_socket: "wss://apidev.hiwac.com/",
  // apiUrl:'https://apidev.hiwac.com/api/',

  // production
  prod_url: "https://apis.accountect.com/chat/",
  prod_socket: "wss://apis.ciwac.com/",
  prod_socket_niwac: "wss://api.niwac.cn/",
  apiUrl:'https://test.apis.accountect.com/api/',
  // apiUrl:'https://api.ciwac.com/api/',

  firebase: {
    apiKey: "AIzaSyCB7S0h1AxBfmkg1Hf4CAKu1wZkYZMl1vI",
    authDomain: "hiwac-d5af4.firebaseapp.com",
    projectId: "hiwac-d5af4",
    storageBucket: "hiwac-d5af4.appspot.com",
    messagingSenderId: "302454221112",
    appId: "1:302454221112:web:e8dc28d180af3a2d6a39cd",
    measurementId: "G-21DL9TFENC"
  },

  googleMaps: 'AIzaSyCPoQCNPKT58pRjQfBKGfNiN-ENNqmQQqg',
  linkedInCredentials :{
    clientId: "78vkfxn5lfoskw",
    redirectUrl: "https://web.accountect.com/authentication/linkedin/callback",
    scope: "r_liteprofile%20r_emailaddress" // To read basic user profile data and email
    // scope: "r_liteprofile%20r_emailaddress%20w_member_social" // To read basic user profile data and email

  },
  wechat:{
    self_redirect:true,
    id:"login_container",
    appid: "wxa82825151ba0db3b",
    scope: "snsapi_login",
    redirect_uri: "https://www.niwac.cn/authentication/login",
    seceret: '9493d59c3a3829045e6ea4ba0609dd44'
  }
  ,
    qq:{
      self_redirect:true,
      id:"login_container",
      appid: "101978803",
      redirect_uri: "https://www.niwac.cn/authentication/register",
      seceret: '7aa906bebb9db52bfb42006306ed72f7'
    }
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
