<div class="modal-ngb">
  <div *ngIf="filterType === 'drive'" class="modal-body filter-modal">
    <form action="submit" [formGroup]="ledgerService?.DriveFilters">
    <div class="p-2">
      <div class="my-4 d-flex align-items-center">
        <div class="form-floating w-100">
          <input type="text" class="form-control form-control-sm" id="floatingInput" formControlName="search_query" [placeholder]="'Information'|translate">
          <label for="floatingInput" class="float-input-custom text-light"><span>{{'Search'|translate}}</span></label>
        </div>
      </div>
    </div>
    </form>
  </div>
  
  <div *ngIf="filterType === 'cash'" class="modal-body filter-modal">
    <form action="submit" [formGroup]="ledgerService?.CashFilters">
    <div class="p-2">
      
      <div class="d-flex align-items-center justify-content-between">
        <div [ngClass]="{'trans-type-selected': ledgerService?.CashFilters.value.transaction_type === 'all'}" class="trans-type" (click)="typeFiltersexp('all')">
          <span class="f-14">{{'All'|translate}}</span>
        </div>
        <div [ngClass]="{'trans-type-selected': ledgerService?.CashFilters.value.transaction_type === '1'}" class="trans-type" (click)="typeFiltersexp('1')">
          <span class="f-14">{{'Cash In'|translate}}</span>
        </div>
        <div [ngClass]="{'trans-type-selected': ledgerService?.CashFilters.value.transaction_type === '2'}" class="trans-type" (click)="typeFiltersexp('2')">
          <span class="f-14">{{"Cash Out"|translate}}</span>
        </div>
      </div>


      <div class="my-4 d-flex align-items-center">
        <div class="form-floating w-100">
          <input type="text" class="form-control form-control-sm" id="floatingInput" formControlName="search_query" [placeholder]="'Information'|translate">
          <label for="floatingInput" class="float-input-custom text-light"><span>{{'Search (Description, Value)'|translate}}</span></label>
        </div>
      </div>

      
      <div class="mt-1 d-flex align-items-center">
        <div class="w-50 mx-auto datepicker py-2">
          <div class="form-inline custom-datepicker custom-datepicker-dropdown">
            <div class="form-group m-0">
              <div class="input-group">
                <div class="input-group-append rtl" *ngIf="auth.dirWeb === 'rtl'">
                  <button (click)="fvalid.toggle()" class="btn calendar" type="button"><img src="../../../../assets/Accountect/calendar.svg" class="img-18" alt=""></button>
                </div>
                <input #fvalid="ngbDatepicker" [value]="ledgerService.CashFilters.value.start_date| date: dateFormat " class="form-control cursor-pointer"
                  (dateSelect)="selectFiltersDatexp($event, 'start')" name="dp" (click)="fvalid.toggle()" ngbDatepicker
                  [placeholder]="'From'|translate" readonly>
                <div class="input-group-append ltr" *ngIf="auth.dirWeb === 'ltr'">
                  <button (click)="fvalid.toggle()" class="btn calendar" type="button">
                    <img src="../../../../assets/Accountect/calendar.svg" alt="">
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="p-1"></div>
        <div class="w-50 mx-auto datepicker py-2">
          <div class="form-inline custom-datepicker custom-datepicker-dropdown">
            <div class="form-group m-0">
              <div class="input-group" (click)="datefor()">
                <div class="input-group-append rtl" *ngIf="auth.dirWeb === 'rtl'">
                  <button (click)="valid.toggle()" class="btn calendar" type="button"><img src="../../../../assets/Accountect/calendar.svg" class="img-18" alt=""></button>
                </div>
                <input #valid="ngbDatepicker" [value]="ledgerService.CashFilters.value.end_date| date: dateFormat " class="form-control cursor-pointer"
                  (dateSelect)="selectFiltersDatexp($event, 'end')" [minDate]="{year: transDate.year, month: transDate.month, day: transDate.day}" name="dp" (click)="valid.toggle()" ngbDatepicker
                  [placeholder]="'To'|translate" readonly>
                <div class="input-group-append ltr" *ngIf="auth.dirWeb === 'ltr'">
                  <button (click)="valid.toggle()" class="btn calendar" type="button">
                    <img src="../../../../assets/Accountect/calendar.svg" alt="">
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
    </form>
  </div>

  <div *ngIf="filterType === 'bank'" class="modal-body filter-modal">
    <form action="submit" [formGroup]="ledgerService?.bankFilters">
    <div class="p-2">
      
      <div class="d-flex align-items-center justify-content-between">
        <div [ngClass]="{'trans-type-selected': ledgerService?.bankFilters.value.transaction_type === 'all'}" class="trans-type" (click)="typeFiltersexp('all')">
          <span class="f-14">{{'All'|translate}}</span>
        </div>
        <div [ngClass]="{'trans-type-selected': ledgerService?.bankFilters.value.transaction_type === '1'}" class="trans-type" (click)="typeFiltersexp('1')">
          <span class="f-14">{{'Payment In'|translate}}</span>
        </div>
        <div [ngClass]="{'trans-type-selected': ledgerService?.bankFilters.value.transaction_type === '2'}" class="trans-type" (click)="typeFiltersexp('2')">
          <span class="f-14">{{"Payment Out"|translate}}</span>
        </div>
      </div>

      <div class="my-4 d-flex align-items-center">
        <div class="form-floating w-100">
          <input type="text" class="form-control form-control-sm" id="floatingInput" formControlName="search_query" [placeholder]="'Information'|translate">
          <label for="floatingInput" class="float-input-custom text-light"><span>{{'Search (Description, Value)'|translate}}</span></label>
        </div>
      </div>
      
      <div class="d-flex align-items-center">
        <div class="w-50 mx-auto datepicker py-2">
          <div class="form-inline custom-datepicker custom-datepicker-dropdown">
            <div class="form-group m-0">
              <div class="input-group">
                <div class="input-group-append rtl" *ngIf="auth.dirWeb === 'rtl'">
                  <button (click)="fvalid.toggle()" class="btn calendar" type="button"><img src="../../../../assets/Accountect/calendar.svg" class="img-18" alt=""></button>
                </div>
                <input #fvalid="ngbDatepicker" [value]="ledgerService.bankFilters.value.start_date| date: dateFormat " class="form-control  cursor-pointer"
                  (dateSelect)="selectFiltersDatexp($event, 'start')" name="dp" (click)="fvalid.toggle()" ngbDatepicker
                  [placeholder]="'From'|translate" readonly>
                <div class="input-group-append ltr" *ngIf="auth.dirWeb === 'ltr'">
                  <button (click)="fvalid.toggle()" class="btn calendar" type="button">
                    <img src="../../../../assets/Accountect/calendar.svg" alt="">
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="p-1"></div>
        <div class="w-50 mx-auto datepicker py-2">
          <div class="form-inline custom-datepicker custom-datepicker-dropdown">
            <div class="form-group m-0">
              <div class="input-group">
                <div class="input-group-append rtl" *ngIf="auth.dirWeb === 'rtl'">
                  <button (click)="valid.toggle()" class="btn calendar" type="button"><img src="../../../../assets/Accountect/calendar.svg" class="img-18" alt=""></button>
                </div>
                <input #valid="ngbDatepicker" [value]="ledgerService.bankFilters.value.end_date| date: dateFormat " class="form-control cursor-pointer"
                  (dateSelect)="selectFiltersDatexp($event, 'end')" [minDate]="{year: transDate.year, month: transDate.month, day: transDate.day}" name="dp" (click)="valid.toggle()" ngbDatepicker
                  [placeholder]="'To'|translate" readonly>
                <div class="input-group-append ltr" *ngIf="auth.dirWeb === 'ltr'">
                  <button (click)="valid.toggle()" class="btn calendar" type="button">
                    <img src="../../../../assets/Accountect/calendar.svg" alt="">
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- <input class="form-control" placeholder="yyyy-mm-dd" type="date" name="dp"> -->
        </div>
      </div>
    </div>
    </form>
  </div>
  
  <div *ngIf="filterType === 'credit_card'" class="modal-body filter-modal">
    <form action="submit" [formGroup]="ledgerService?.cardFilters">
      <div class="p-2">
        <div class="d-flex align-items-center justify-content-between">
          <div [ngClass]="{'trans-type-selected': ledgerService?.cardFilters.value.transaction_type === 'all'}" class="trans-type" (click)="typeFiltersexp('all')">
            <span class="f-14">{{'All'|translate}}</span>
          </div>
          <div [ngClass]="{'trans-type-selected': ledgerService?.cardFilters.value.transaction_type === '2'}" class="trans-type" (click)="typeFiltersexp('2')">
            <span class="f-14">{{'Charge'|translate}}</span>
          </div>
          <div [ngClass]="{'trans-type-selected': ledgerService?.cardFilters.value.transaction_type === '1'}" class="trans-type" (click)="typeFiltersexp('1')">
            <span class="f-14">{{"Payment"|translate}}</span>
          </div>
        </div>
        
        <div class="my-4 d-flex align-items-center">
          <div class="form-floating w-100">
            <input type="text" class="form-control form-control-sm" id="floatingInput" formControlName="search_query" [placeholder]="'Information'|translate">
            <label for="floatingInput" class="float-input-custom text-light"><span>{{'Search (Description, Value)'|translate}}</span></label>
          </div>
        </div>

        <div class="d-flex align-items-center">
          <div class="w-50 mx-auto datepicker py-2">
            <div class="form-inline custom-datepicker custom-datepicker-dropdown">
              <div class="form-group m-0">
                <div class="input-group">
                  <div class="input-group-append rtl" *ngIf="auth.dirWeb === 'rtl'">
                    <button (click)="fvalid.toggle()" class="btn calendar" type="button"><img src="../../../../assets/Accountect/calendar.svg" class="img-18" alt=""></button>
                  </div>
                  <input #fvalid="ngbDatepicker" [value]="ledgerService.cardFilters.value.start_date| date: dateFormat " class="form-control  cursor-pointer"
                    (dateSelect)="selectFiltersDatexp($event, 'start')" name="dp" (click)="fvalid.toggle()" ngbDatepicker
                    [placeholder]="'From'|translate" readonly>
                  <div class="input-group-append ltr" *ngIf="auth.dirWeb === 'ltr'">
                    <button (click)="fvalid.toggle()" class="btn calendar" type="button">
                      <img src="../../../../assets/Accountect/calendar.svg" alt="">
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="p-1"></div>
          <div class="w-50 mx-auto datepicker py-2">
            <div class="form-inline custom-datepicker custom-datepicker-dropdown">
              <div class="form-group m-0">
                <div class="input-group">
                  <div class="input-group-append rtl" *ngIf="auth.dirWeb === 'rtl'">
                    <button (click)="valid.toggle()" class="btn calendar" type="button"><img src="../../../../assets/Accountect/calendar.svg" class="img-18" alt=""></button>
                  </div>
                  <input #valid="ngbDatepicker" [value]="ledgerService.cardFilters.value.end_date| date: dateFormat " class="form-control cursor-pointer"
                    (dateSelect)="selectFiltersDatexp($event, 'end')" [minDate]="{year: transDate.year, month: transDate.month, day: transDate.day}" name="dp" (click)="valid.toggle()" ngbDatepicker
                    [placeholder]="'To'|translate" readonly>
                  <div class="input-group-append ltr" *ngIf="auth.dirWeb === 'ltr'">
                    <button (click)="valid.toggle()" class="btn calendar" type="button">
                      <img src="../../../../assets/Accountect/calendar.svg" alt="">
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div *ngIf="filterType === 'sale'" class="modal-body filter-modal">
    <form action="submit" [formGroup]="ledgerService?.SaleFilters">
      <div class="p-2">
        <div class="d-flex align-items-center justify-content-between">
          <div [ngClass]="{'trans-type-selected': ledgerService?.SaleFilters.value.transaction_type === 'all'}" class="trans-type" (click)="typeFiltersexp('all')">
            <span class="f-14">{{'All'|translate}}</span>
          </div>
          <div [ngClass]="{'trans-type-selected': ledgerService?.SaleFilters.value.transaction_type === '2'}" class="trans-type" (click)="typeFiltersexp('2')">
            <span class="f-14">{{"Return"|translate}}</span>
          </div>
          <div [ngClass]="{'trans-type-selected': ledgerService?.SaleFilters.value.transaction_type === '1'}" class="trans-type" (click)="typeFiltersexp('1')">
            <span class="f-14">{{'Sale'|translate}}</span>
          </div>
          
        </div>
        
        <div class="my-4 d-flex align-items-center">
          <div class="form-floating w-100">
            <input type="text" class="form-control form-control-sm" id="floatingInput" formControlName="search_query" [placeholder]="'Information'|translate">
            <label for="floatingInput" class="float-input-custom text-light"><span>{{'Search (Description, Value)'|translate}}</span></label>
          </div>
        </div>

        <div class="d-flex align-items-center">
          <div class="w-50 mx-auto datepicker py-2">
            <div class="form-inline custom-datepicker custom-datepicker-dropdown">
              <div class="form-group m-0">
                <div class="input-group">
                  <div class="input-group-append rtl" *ngIf="auth.dirWeb === 'rtl'">
                    <button (click)="fvalid.toggle()" class="btn calendar" type="button"><img src="../../../../assets/Accountect/calendar.svg" class="img-18" alt=""></button>
                  </div>
                  <input #fvalid="ngbDatepicker" [value]="ledgerService.SaleFilters.value.start_date| date: dateFormat " class="form-control  cursor-pointer"
                    (dateSelect)="selectFiltersDatexp($event, 'start')" name="dp" (click)="fvalid.toggle()" ngbDatepicker
                    [placeholder]="'From'|translate" readonly>
                  <div class="input-group-append ltr" *ngIf="auth.dirWeb === 'ltr'">
                    <button (click)="fvalid.toggle()" class="btn calendar" type="button">
                      <img src="../../../../assets/Accountect/calendar.svg" alt="">
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="p-1"></div>
          <div class="w-50 mx-auto datepicker py-2">
            <div class="form-inline custom-datepicker custom-datepicker-dropdown">
              <div class="form-group m-0">
                <div class="input-group">
                  <div class="input-group-append rtl" *ngIf="auth.dirWeb === 'rtl'">
                    <button (click)="valid.toggle()" class="btn calendar" type="button"><img src="../../../../assets/Accountect/calendar.svg" class="img-18" alt=""></button>
                  </div>
                  <input #valid="ngbDatepicker" [value]="ledgerService.SaleFilters.value.end_date| date: dateFormat " class="form-control cursor-pointer"
                    (dateSelect)="selectFiltersDatexp($event, 'end')" [minDate]="{year: transDate.year, month: transDate.month, day: transDate.day}" name="dp" (click)="valid.toggle()" ngbDatepicker
                    [placeholder]="'To'|translate" readonly>
                  <div class="input-group-append ltr" *ngIf="auth.dirWeb === 'ltr'">
                    <button (click)="valid.toggle()" class="btn calendar" type="button">
                      <img src="../../../../assets/Accountect/calendar.svg" alt="">
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div *ngIf="filterType === 'purchase'" class="modal-body filter-modal">
    <form action="submit" [formGroup]="ledgerService?.PurchaseFilters">
      <div class="p-2">
        <div class="d-flex align-items-center justify-content-between">
          <div [ngClass]="{'trans-type-selected': ledgerService?.PurchaseFilters.value.transaction_type === 'all'}" class="trans-type" (click)="typeFiltersexp('all')">
            <span class="f-14">{{'All'|translate}}</span>
          </div>
          <div [ngClass]="{'trans-type-selected': ledgerService?.PurchaseFilters.value.transaction_type === '1'}" class="trans-type" (click)="typeFiltersexp('1')">
            <span class="f-14">{{'Purchase'|translate}}</span>
          </div>
          <div [ngClass]="{'trans-type-selected': ledgerService?.PurchaseFilters.value.transaction_type === '2'}" class="trans-type" (click)="typeFiltersexp('2')">
            <span class="f-14">{{"Return"|translate}}</span>
          </div>
        </div>
        
        <div class="my-4 d-flex align-items-center">
          <div class="form-floating w-100">
            <input type="text" class="form-control form-control-sm" id="floatingInput" formControlName="search_query" [placeholder]="'Information'|translate">
            <label for="floatingInput" class="float-input-custom text-light"><span>{{'Search (Description, Value)'|translate}}</span></label>
          </div>
        </div>

        <div class="d-flex align-items-center">
          <div class="w-50 mx-auto datepicker py-2">
            <div class="form-inline custom-datepicker custom-datepicker-dropdown">
              <div class="form-group m-0">
                <div class="input-group">
                  <div class="input-group-append rtl" *ngIf="auth.dirWeb === 'rtl'">
                    <button (click)="fvalid.toggle()" class="btn calendar" type="button"><img src="../../../../assets/Accountect/calendar.svg" class="img-18" alt=""></button>
                  </div>
                  <input #fvalid="ngbDatepicker" [value]="ledgerService.PurchaseFilters.value.start_date| date: dateFormat " class="form-control  cursor-pointer"
                    (dateSelect)="selectFiltersDatexp($event, 'start')" name="dp" (click)="fvalid.toggle()" ngbDatepicker
                    [placeholder]="'From'|translate" readonly>
                  <div class="input-group-append ltr" *ngIf="auth.dirWeb === 'ltr'">
                    <button (click)="fvalid.toggle()" class="btn calendar" type="button">
                      <img src="../../../../assets/Accountect/calendar.svg" alt="">
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="p-1"></div>
          <div class="w-50 mx-auto datepicker py-2">
            <div class="form-inline custom-datepicker custom-datepicker-dropdown">
              <div class="form-group m-0">
                <div class="input-group">
                  <div class="input-group-append rtl" *ngIf="auth.dirWeb === 'rtl'">
                    <button (click)="valid.toggle()" class="btn calendar" type="button"><img src="../../../../assets/Accountect/calendar.svg" class="img-18" alt=""></button>
                  </div>
                  <input #valid="ngbDatepicker" [value]="ledgerService.PurchaseFilters.value.end_date| date: dateFormat " class="form-control cursor-pointer"
                    (dateSelect)="selectFiltersDatexp($event, 'end')" [minDate]="{year: transDate.year, month: transDate.month, day: transDate.day}" name="dp" (click)="valid.toggle()" ngbDatepicker
                    [placeholder]="'To'|translate" readonly>
                  <div class="input-group-append ltr" *ngIf="auth.dirWeb === 'ltr'">
                    <button (click)="valid.toggle()" class="btn calendar" type="button">
                      <img src="../../../../assets/Accountect/calendar.svg" alt="">
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div *ngIf="filterType === 'expense'" class="modal-body filter-modal">
    <form action="submit" [formGroup]="ledgerService?.ExpenseFilters">
      <div class="p-2">
        <div class="d-flex align-items-center justify-content-between">
          <div [ngClass]="{'trans-type-selected': ledgerService?.ExpenseFilters.value.transaction_type === 'all'}" class="trans-type" (click)="typeFiltersexp('all')">
            <span class="f-14">{{'All'|translate}}</span>
          </div>
          <div [ngClass]="{'trans-type-selected': ledgerService?.ExpenseFilters.value.transaction_type === '1'}" class="trans-type" (click)="typeFiltersexp('1')">
            <span class="f-14">{{'Expense'|translate}}</span>
          </div>
          <div [ngClass]="{'trans-type-selected': ledgerService?.ExpenseFilters.value.transaction_type === '2'}" class="trans-type" (click)="typeFiltersexp('2')">
            <span class="f-14">{{"Return"|translate}}</span>
          </div>
        </div>
        
        <div class="my-4 d-flex align-items-center">
          <div class="form-floating w-100">
            <input type="text" class="form-control form-control-sm" id="floatingInput" formControlName="search_query" [placeholder]="'Information'|translate">
            <label for="floatingInput" class="float-input-custom text-light"><span>{{'Search (Description, Value)'|translate}}</span></label>
          </div>
        </div>

        <div class="d-flex align-items-center">
          <div class="w-50 mx-auto datepicker py-2">
            <div class="form-inline custom-datepicker custom-datepicker-dropdown">
              <div class="form-group m-0">
                <div class="input-group">
                  <div class="input-group-append rtl" *ngIf="auth.dirWeb === 'rtl'">
                    <button (click)="fvalid.toggle()" class="btn calendar" type="button"><img src="../../../../assets/Accountect/calendar.svg" class="img-18" alt=""></button>
                  </div>
                  <input #fvalid="ngbDatepicker" [value]="ledgerService.ExpenseFilters.value.start_date| date: dateFormat " class="form-control  cursor-pointer"
                    (dateSelect)="selectFiltersDatexp($event, 'start')" name="dp" (click)="fvalid.toggle()" ngbDatepicker
                    [placeholder]="'From'|translate" readonly>
                  <div class="input-group-append ltr" *ngIf="auth.dirWeb === 'ltr'">
                    <button (click)="fvalid.toggle()" class="btn calendar" type="button">
                      <img src="../../../../assets/Accountect/calendar.svg" alt="">
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="p-1"></div>
          <div class="w-50 mx-auto datepicker py-2">
            <div class="form-inline custom-datepicker custom-datepicker-dropdown">
              <div class="form-group m-0">
                <div class="input-group">
                  <div class="input-group-append rtl" *ngIf="auth.dirWeb === 'rtl'">
                    <button (click)="valid.toggle()" class="btn calendar" type="button"><img src="../../../../assets/Accountect/calendar.svg" class="img-18" alt=""></button>
                  </div>
                  <input #valid="ngbDatepicker" [value]="ledgerService.ExpenseFilters.value.end_date| date: dateFormat " class="form-control cursor-pointer"
                    (dateSelect)="selectFiltersDatexp($event, 'end')" [minDate]="{year: transDate.year, month: transDate.month, day: transDate.day}" name="dp" (click)="valid.toggle()" ngbDatepicker
                    [placeholder]="'To'|translate" readonly>
                  <div class="input-group-append ltr" *ngIf="auth.dirWeb === 'ltr'">
                    <button (click)="valid.toggle()" class="btn calendar" type="button">
                      <img src="../../../../assets/Accountect/calendar.svg" alt="">
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div *ngIf="filterType === 'inventory'" class="modal-body filter-modal">
    <form action="submit" [formGroup]="ledgerService?.InventoryFilters">
      <div class="p-2">
        <div class="d-flex align-items-center justify-content-between" *ngIf="filterType === 'inventory'">
          <div [ngClass]="{'trans-type-selected': ledgerService?.InventoryFilters.value.transaction_type === 'all'}" class="trans-type" (click)="typeFiltersexp('all')">
            <span class="f-14">{{'All'|translate}}</span>
          </div>
          <div [ngClass]="{'trans-type-selected': ledgerService?.InventoryFilters.value.transaction_type === '1'}" class="trans-type" (click)="typeFiltersexp('1')">
            <span class="f-14">{{'Stock In'|translate}}</span>
          </div>
          <div [ngClass]="{'trans-type-selected': ledgerService?.InventoryFilters.value.transaction_type === '2'}" class="trans-type" (click)="typeFiltersexp('2')">
            <span class="f-14">{{"Stock Out"|translate}}</span>
          </div>
        </div>
        
        <div class="my-4 d-flex align-items-center">
          <div class="form-floating w-100">
            <input type="text" class="form-control form-control-sm" id="floatingInput" formControlName="search_query" [placeholder]="'Information'|translate">
            <label for="floatingInput" class="float-input-custom text-light"><span>{{'Search (Description, Value)'|translate}}</span></label>
          </div>
        </div>
        
        <div class="d-flex align-items-center">
          <div class="w-50 mx-auto datepicker py-2">
            <div class="form-inline custom-datepicker custom-datepicker-dropdown">
              <div class="form-group m-0">
                <div class="input-group">
                  <div class="input-group-append rtl" *ngIf="auth.dirWeb === 'rtl'">
                    <button (click)="fvalid.toggle()" class="btn calendar" type="button"><img src="../../../../assets/Accountect/calendar.svg" class="img-18" alt=""></button>
                  </div>
                  <input #fvalid="ngbDatepicker" [value]="ledgerService.InventoryFilters.value.start_date| date: dateFormat " class="form-control  cursor-pointer"
                    (dateSelect)="selectFiltersDatexp($event, 'start')" name="dp" (click)="fvalid.toggle()" ngbDatepicker
                    [placeholder]="'From'|translate" readonly>
                  <div class="input-group-append ltr" *ngIf="auth.dirWeb === 'ltr'">
                    <button (click)="fvalid.toggle()" class="btn calendar" type="button">
                      <img src="../../../../assets/Accountect/calendar.svg" alt="">
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="p-1"></div>
          <div class="w-50 mx-auto datepicker py-2">
            <div class="form-inline custom-datepicker custom-datepicker-dropdown">
              <div class="form-group m-0">
                <div class="input-group">
                  <div class="input-group-append rtl" *ngIf="auth.dirWeb === 'rtl'">
                    <button (click)="valid.toggle()" class="btn calendar" type="button"><img src="../../../../assets/Accountect/calendar.svg" class="img-18" alt=""></button>
                  </div>
                  <input #valid="ngbDatepicker" [value]="ledgerService.InventoryFilters.value.end_date| date: dateFormat " class="form-control  cursor-pointer"
                    (dateSelect)="selectFiltersDatexp($event, 'end')" [minDate]="{year: transDate.year, month: transDate.month, day: transDate.day}" name="dp" (click)="valid.toggle()" ngbDatepicker
                    [placeholder]="'To'|translate" readonly>
                  <div class="input-group-append ltr" *ngIf="auth.dirWeb === 'ltr'">
                    <button (click)="valid.toggle()" class="btn calendar" type="button">
                      <img src="../../../../assets/Accountect/calendar.svg" alt="">
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div *ngIf="filterType === 'bundle'" class="modal-body filter-modal">
    bundle
    <form action="submit" [formGroup]="ledgerService?.InventoryFilters">
      <div class="p-2">
        <div class="d-flex align-items-center justify-content-between" *ngIf="filterType === 'bundle'">
          <div [ngClass]="{'trans-type-selected': ledgerService?.InventoryFilters.value.transaction_type === 'all'}" class="trans-type" (click)="typeFiltersexp('all')">
            <span class="f-14">{{'All'|translate}}</span>
          </div>
          <div [ngClass]="{'trans-type-selected': ledgerService?.InventoryFilters.value.transaction_type === '1'}" class="trans-type" (click)="typeFiltersexp('1')">
            <span class="f-14">{{'Stock In'|translate}}</span>
          </div>
          <div [ngClass]="{'trans-type-selected': ledgerService?.InventoryFilters.value.transaction_type === '2'}" class="trans-type" (click)="typeFiltersexp('2')">
            <span class="f-14">{{"Stock Out"|translate}}</span>
          </div>
        </div>
        
        <div class="my-4 d-flex align-items-center">
          <div class="form-floating w-100">
            <input type="text" class="form-control form-control-sm" id="floatingInput" formControlName="search_query" [placeholder]="'Information'|translate">
            <label for="floatingInput" class="float-input-custom text-light"><span>{{'Search (Description, Value)'|translate}}</span></label>
          </div>
        </div>
        
        <div class="d-flex align-items-center">
          <div class="w-50 mx-auto datepicker py-2">
            <div class="form-inline custom-datepicker custom-datepicker-dropdown">
              <div class="form-group m-0">
                <div class="input-group">
                  <div class="input-group-append rtl" *ngIf="auth.dirWeb === 'rtl'">
                    <button (click)="fvalid.toggle()" class="btn calendar" type="button"><img src="../../../../assets/Accountect/calendar.svg" class="img-18" alt=""></button>
                  </div>
                  <input #fvalid="ngbDatepicker" [value]="ledgerService.InventoryFilters.value.start_date| date: dateFormat " class="form-control  cursor-pointer"
                    (dateSelect)="selectFiltersDatexp($event, 'start')" name="dp" (click)="fvalid.toggle()" ngbDatepicker
                    [placeholder]="'From'|translate" readonly>
                  <div class="input-group-append ltr" *ngIf="auth.dirWeb === 'ltr'">
                    <button (click)="fvalid.toggle()" class="btn calendar" type="button">
                      <img src="../../../../assets/Accountect/calendar.svg" alt="">
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="p-1"></div>
          <div class="w-50 mx-auto datepicker py-2">
            <div class="form-inline custom-datepicker custom-datepicker-dropdown">
              <div class="form-group m-0">
                <div class="input-group">
                  <div class="input-group-append rtl" *ngIf="auth.dirWeb === 'rtl'">
                    <button (click)="valid.toggle()" class="btn calendar" type="button"><img src="../../../../assets/Accountect/calendar.svg" class="img-18" alt=""></button>
                  </div>
                  <input #valid="ngbDatepicker" [value]="ledgerService.InventoryFilters.value.end_date| date: dateFormat " class="form-control  cursor-pointer"
                    (dateSelect)="selectFiltersDatexp($event, 'end')" [minDate]="{year: transDate.year, month: transDate.month, day: transDate.day}" name="dp" (click)="valid.toggle()" ngbDatepicker
                    [placeholder]="'To'|translate" readonly>
                  <div class="input-group-append ltr" *ngIf="auth.dirWeb === 'ltr'">
                    <button (click)="valid.toggle()" class="btn calendar" type="button">
                      <img src="../../../../assets/Accountect/calendar.svg" alt="">
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div *ngIf="filterType === 'inventorybyRef'" class="modal-body filter-modal">
    <form action="submit" [formGroup]="ledgerService?.InventoryFilters">
    <div class="p-2">
      <div class="d-flex align-items-center justify-content-between" *ngIf="filterType === 'inventory' || filterType === 'inventorybyRef'">
        <div [ngClass]="{'trans-type-selected': ledgerService?.InventoryFilters.value.transaction_type === 'all'}" class="trans-type" (click)="typeFiltersexp('all')">
          <span class="f-14">{{'All'|translate}}</span>
        </div>
        <div [ngClass]="{'trans-type-selected': ledgerService?.InventoryFilters.value.transaction_type === '1'}" class="trans-type" (click)="typeFiltersexp('1')">
          <span class="f-14">{{'Stock In'|translate}}</span>
        </div>
        <div [ngClass]="{'trans-type-selected': ledgerService?.InventoryFilters.value.transaction_type === '2'}" class="trans-type" (click)="typeFiltersexp('2')">
          <span class="f-14">{{"Stock Out"|translate}}</span>
        </div>
      </div>
      
      <div class="my-4 d-flex align-items-center">
        <div class="form-floating w-100">
          <input type="text" class="form-control form-control-sm" id="floatingInput" formControlName="search_query" [placeholder]="'Information'|translate">
          <label for="floatingInput" class="float-input-custom text-light"><span>{{'Search (Description, Value)'|translate}}</span></label>
        </div>
      </div>     

      <div class=" d-flex align-items-center">
        <div class="w-50 mx-auto datepicker py-2">
          <div class="form-inline custom-datepicker custom-datepicker-dropdown">
            <div class="form-group m-0">
              <div class="input-group">
                <div class="input-group-append rtl" *ngIf="auth.dirWeb === 'rtl'">
                  <button (click)="fvalid.toggle()" class="btn calendar" type="button"><img src="../../../../assets/Accountect/calendar.svg" class="img-18" alt=""></button>
                </div>
                <input #fvalid="ngbDatepicker" [value]="ledgerService.InventoryFilters.value.start_date| date: dateFormat " class="form-control  cursor-pointer"
                  (dateSelect)="selectFiltersDatexp($event, 'start')" name="dp" (click)="fvalid.toggle()" ngbDatepicker
                  [placeholder]="'To'|translate" readonly>
                <div class="input-group-append ltr" *ngIf="auth.dirWeb === 'ltr'">
                  <button (click)="fvalid.toggle()" class="btn calendar" type="button">
                    <img src="../../../../assets/Accountect/calendar.svg" alt="">
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="p-1"></div>
        <div class="w-50 mx-auto datepicker py-2">
          <div class="form-inline custom-datepicker custom-datepicker-dropdown">
            <div class="form-group m-0">
              <div class="input-group">
                <div class="input-group-append rtl" *ngIf="auth.dirWeb === 'rtl'">
                  <button (click)="valid.toggle()" class="btn calendar" type="button"><img src="../../../../assets/Accountect/calendar.svg" class="img-18" alt=""></button>
                </div>
                <input #valid="ngbDatepicker" [value]="ledgerService.InventoryFilters.value.end_date| date: dateFormat " class="form-control  cursor-pointer"
                  (dateSelect)="selectFiltersDatexp($event, 'end')" [minDate]="{year: transDate.year, month: transDate.month, day: transDate.day}" name="dp" (click)="valid.toggle()" ngbDatepicker
                  [placeholder]="'From'|translate" readonly>
                <div class="input-group-append ltr" *ngIf="auth.dirWeb === 'ltr'">
                  <button (click)="valid.toggle()" class="btn calendar" type="button">
                    <img src="../../../../assets/Accountect/calendar.svg" alt="">
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </form>
  </div>
  <div *ngIf="filterType === 'bundlebyRef'" class="modal-body filter-modal">
    <form action="submit" [formGroup]="ledgerService?.InventoryFilters">
    <div class="p-2">
      <div class="d-flex align-items-center justify-content-between" *ngIf="filterType === 'inventory' || filterType === 'inventorybyRef' || filterType === 'bundlebyRef'">
        <div [ngClass]="{'trans-type-selected': ledgerService?.InventoryFilters.value.transaction_type === 'all'}" class="trans-type" (click)="typeFiltersexp('all')">
          <span class="f-14">{{'All'|translate}}</span>
        </div>
        <div [ngClass]="{'trans-type-selected': ledgerService?.InventoryFilters.value.transaction_type === '1'}" class="trans-type" (click)="typeFiltersexp('1')">
          <span class="f-14">{{'Stock In'|translate}}</span>
        </div>
        <div [ngClass]="{'trans-type-selected': ledgerService?.InventoryFilters.value.transaction_type === '2'}" class="trans-type" (click)="typeFiltersexp('2')">
          <span class="f-14">{{"Stock Out"|translate}}</span>
        </div>
      </div>
      
      <div class="my-4 d-flex align-items-center">
        <div class="form-floating w-100">
          <input type="text" class="form-control form-control-sm" id="floatingInput" formControlName="search_query" [placeholder]="'Information'|translate">
          <label for="floatingInput" class="float-input-custom text-light"><span>{{'Search (Description, Value)'|translate}}</span></label>
        </div>
      </div>     

      <div class=" d-flex align-items-center">
        <div class="w-50 mx-auto datepicker py-2">
          <div class="form-inline custom-datepicker custom-datepicker-dropdown">
            <div class="form-group m-0">
              <div class="input-group">
                <div class="input-group-append rtl" *ngIf="auth.dirWeb === 'rtl'">
                  <button (click)="fvalid.toggle()" class="btn calendar" type="button"><img src="../../../../assets/Accountect/calendar.svg" class="img-18" alt=""></button>
                </div>
                <input #fvalid="ngbDatepicker" [value]="ledgerService.InventoryFilters.value.start_date| date: dateFormat " class="form-control  cursor-pointer"
                  (dateSelect)="selectFiltersDatexp($event, 'start')" name="dp" (click)="fvalid.toggle()" ngbDatepicker
                  [placeholder]="'To'|translate" readonly>
                <div class="input-group-append ltr" *ngIf="auth.dirWeb === 'ltr'">
                  <button (click)="fvalid.toggle()" class="btn calendar" type="button">
                    <img src="../../../../assets/Accountect/calendar.svg" alt="">
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="p-1"></div>
        <div class="w-50 mx-auto datepicker py-2">
          <div class="form-inline custom-datepicker custom-datepicker-dropdown">
            <div class="form-group m-0">
              <div class="input-group">
                <div class="input-group-append rtl" *ngIf="auth.dirWeb === 'rtl'">
                  <button (click)="valid.toggle()" class="btn calendar" type="button"><img src="../../../../assets/Accountect/calendar.svg" class="img-18" alt=""></button>
                </div>
                <input #valid="ngbDatepicker" [value]="ledgerService.InventoryFilters.value.end_date| date: dateFormat " class="form-control  cursor-pointer"
                  (dateSelect)="selectFiltersDatexp($event, 'end')" [minDate]="{year: transDate.year, month: transDate.month, day: transDate.day}" name="dp" (click)="valid.toggle()" ngbDatepicker
                  [placeholder]="'From'|translate" readonly>
                <div class="input-group-append ltr" *ngIf="auth.dirWeb === 'ltr'">
                  <button (click)="valid.toggle()" class="btn calendar" type="button">
                    <img src="../../../../assets/Accountect/calendar.svg" alt="">
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </form>
  </div>

  <div class="d-flex justify-content-center align-items-center border-top filter-rounded">
    <div class="w-30 bg-light d-flex justify-content-center py-3 cursor-pointer br-l-20" (click)="resetFilter()">
      <span class="text-dark f-15 font-weight-600">{{'Reset'|translate}}</span>
    </div>
    <div [class.disabled]="dateissue === true" (click)="filterApplyexp()" class="w-70 bg-primary d-flex justify-content-center p-3 cursor-pointer br-b-20">
      <span class="text-white f-15 font-weight-600">{{'Apply'|translate}}</span>
    </div>
  </div>
</div>