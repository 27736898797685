
        <div *ngIf = "linkType=== 'True'">
            <div>
              <div class="px-4">
                <div class="w-100 d-flex align-items-center justify-content-between b-b-light py-2">
                  <div class="d-flex align-items-center">
                    <img class="img-15" src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/ledger/pdf.svg" class="img-20">
                    <span class="f-14 mx-3">{{'All'|translate}}</span>
                  </div>
        
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="date" checked="checked" id="allTime" (change)="DateRangeSelection('allTime')">
                    <label class="form-check-label" for="allTime">
                    </label>
                  </div>
                </div>
                <div class="w-100 d-flex align-items-center justify-content-between b-b-light py-2">
                  <div class="d-flex align-items-center">
                    <img class="img-15" src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/ledger/pdf.svg" class="img-20">
                    <span class="f-14 mx-3">{{'Year'|translate}}</span>
                  </div>
        
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="date" id="year" (change)="DateRangeSelection('year')">
                    <label class="form-check-label" for="year">
                    </label>
                  </div>
                </div>
                <div class="w-100 d-flex align-items-center justify-content-between b-b-light py-2" >
                  <div class="d-flex align-items-center">
                    <img class="img-15" src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/ledger/pdf.svg" class="img-20">
                    <span class="f-14 mx-3">{{'Month'|translate}}</span>
                  </div>
        
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="date" id="month" (change)="DateRangeSelection('month')">
                    <label class="form-check-label" for="month">
                    </label>
                  </div>
                </div>
        
                <div class="w-100 d-flex align-items-center justify-content-between b-b-light py-2">
                  <div class="d-flex align-items-center">
                    <img class="img-15" src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/ledger/pdf.svg" class="img-20">
                    <span class="f-14 mx-3">{{'Week'|translate}}</span>
                  </div>
        
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="date" id="week" (change)="DateRangeSelection('week')">
                    <label class="form-check-label" for="week">
                    </label>
                  </div>
                </div>
        
                <div class="w-100 d-flex align-items-center justify-content-between py-2">
                  <div class="d-flex align-items-center">
                    <img class="img-15" src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/ledger/pdf.svg" class="img-20">
                    <span class="f-14 mx-3">{{'From'|translate}}</span>
                  </div>
        
                  <div>
                    <span class="f-14">{{'To'|translate}}</span>
                  </div>
        
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="date" id="fromTo" (change)="DateRangeSelection('fromTo')">
                    <label class="form-check-label" for="fromTo">
                    </label>
                  </div>
                </div>
        
                <div class="py-2 d-flex align-items-center" [class.disabled]="!customDate">
                  <!-- <div class="px-2 w-50">
                    <input type="text"
                    placeholder="mm/dd/yy"
                    class="form-control"
                    type="date"
                     [(ngModel)]="from_date"
                     (change)="setFromToDate('from')" >
                  </div>
        
                  <div class="px-2 w-50">
                    <input type="text"
                    placeholder="mm/dd/yy"
                    class="form-control"
                    type="date"
                    [(ngModel)]="to_date"
                    (change)="setFromToDate('to')">
                  </div> -->
                  <div class="w-50 mx-auto datepicker p-2 d-flex align-item-center">
                    <div class="form-inline custom-datepicker custom-datepicker-dropdown">
                      <div class="form-group m-0">
                        <div class="input-group">
                          <div class="input-group-append rtl" *ngIf="auth.dirWeb === 'rtl'">
                            <button (click)="fvalid.toggle()" class="btn calendar" type="button"><img src="../../../../assets/Accountect/calendar.svg" class="img-18" alt=""></button>
    
                          </div>
                          <input #fvalid="ngbDatepicker" [(ngModel)]="from_date" class="form-control"
                            (dateSelect)="setFromToDate('from')" [minDate]="{year: 1900, month: 1, day: 1}" name="dp" (click)="fvalid.toggle()" ngbDatepicker
                            [placeholder]="'yyyy-mm-dd' | translate" readonly>
                          <div class="input-group-append ltr" *ngIf="auth.dirWeb === 'ltr'">
                            <button (click)="fvalid.toggle()" class="btn calendar" type="button"><img src="../../../../assets/Accountect/calendar.svg" class="img-18" alt=""></button>
    
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <input class="form-control" placeholder="yyyy-mm-dd" type="date" name="dp"> -->
                  </div>
                  <div class="w-50 mx-auto datepicker p-2 d-flex align-item-center">
                    <div class="form-inline custom-datepicker custom-datepicker-dropdown">
                      <div class="form-group m-0">
                        <div class="input-group">
                          <div class="input-group-append rtl" *ngIf="auth.dirWeb === 'rtl'">
                            <button (click)="fvalid.toggle()" class="btn calendar" type="button"><img src="../../../../assets/Accountect/calendar.svg" class="img-18" alt=""></button>
    
                          </div>
                          <input #tvalid="ngbDatepicker" [(ngModel)]="to_date" class="form-control"
                            (dateSelect)="setFromToDate('To')" [minDate]="{year: 1900, month: 1, day: 1}" name="dp" (click)="tvalid.toggle()" ngbDatepicker
                            [placeholder]="'yyyy-mm-dd' | translate" readonly>
                          <div class="input-group-append ltr" *ngIf="auth.dirWeb === 'ltr'">
                            <button (click)="fvalid.toggle()" class="btn calendar" type="button"><img src="../../../../assets/Accountect/calendar.svg" class="img-18" alt=""></button>
    
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <input class="form-control" placeholder="yyyy-mm-dd" type="date" name="dp"> -->
                  </div>
                </div>
                </div>
                <div class="d-flex justify-content-center bg-primary cursor-pointer round-bottom">
                  <button class="btn btn-primary b-r-12 w-100"  [disabled]="notalowed" (click)="SharePDFNow(copyLink)">
                    <i class="fa fa-share-alt f-30 text-white" aria-hidden="true"></i>
                  </button>
                </div>
        
                <div class="loader-alerts" *ngIf="isLoading">
                  <img  style="width: 50px;" src="../../../../assets/Accountect/AC_ayers_2.gif">
                </div>
            </div>
          </div>
          <div *ngIf = "linkType=== 'False'">
        
          <div>
            <div class="px-4">
              <div class="w-100 d-flex align-items-center justify-content-between b-b-light py-2">
                <div class="d-flex align-items-center">
                  <img class="img-15" src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/ledger/pdf.svg" class="img-20">
                  <span class="f-14 mx-3">{{'All'|translate}}</span>
                </div>
      
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="date" id="allTime" checked="checked" (change)="DateRangeSelection('allTime')">
                  <label class="form-check-label" for="allTime">
                  </label>
                </div>
              </div>
              <div class="w-100 d-flex align-items-center justify-content-between b-b-light py-2">
                <div class="d-flex align-items-center">
                  <img class="img-15" src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/ledger/pdf.svg" class="img-20">
                  <span class="f-14 mx-3">{{'Year'|translate}}</span>
                </div>
      
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="date" id="year" (change)="DateRangeSelection('year')">
                  <label class="form-check-label" for="year">
                  </label>
                </div>
              </div>
              <div class="w-100 d-flex align-items-center justify-content-between b-b-light py-2" >
                <div class="d-flex align-items-center">
                  <img class="img-15" src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/ledger/pdf.svg" class="img-20">
                  <span class="f-14 mx-3">{{'Month'|translate}}</span>
                </div>
      
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="date" id="month" (change)="DateRangeSelection('month')">
                  <label class="form-check-label" for="month">
                  </label>
                </div>
              </div>
      
              <div class="w-100 d-flex align-items-center justify-content-between b-b-light py-2">
                <div class="d-flex align-items-center">
                  <img class="img-15" src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/ledger/pdf.svg" class="img-20">
                  <span class="f-14 mx-3">{{'Week'|translate}}</span>
                </div>
      
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="date" id="week" (change)="DateRangeSelection('week')">
                  <label class="form-check-label" for="week">
                  </label>
                </div>
              </div>
      
              <div class="w-100 d-flex align-items-center justify-content-between py-2">
                <div class="d-flex align-items-center">
                  <img class="img-15" src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/ledger/pdf.svg" class="img-20">
                  <span class="f-14 mx-3">{{'From'|translate}}</span>
                </div>
      
                <div>
                  <span class="f-14">{{'To'|translate}}</span>
                </div>
      
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="date" id="fromTo" (change)="DateRangeSelection('fromTo')">
                  <label class="form-check-label" for="fromTo">
                  </label>
                </div>
              </div>
      
              <div class="py-2 d-flex align-items-center" [class.disabled]="!customDate">
                <!-- <div class="px-2 w-50">
                  <input type="text"
                  placeholder="'From'|translate"
                  class="form-control"
                  type="date"
                   [(ngModel)]="from_date"
                   (change)="setFromToDate('from')" >
                </div> -->
                <div class="w-50 mx-auto datepicker p-2 d-flex align-item-center">
                  <div class="form-inline custom-datepicker custom-datepicker-dropdown">
                    <div class="form-group m-0">
                      <div class="input-group">
                        <div class="input-group-append rtl" *ngIf="auth.dirWeb === 'rtl'">
                          <button (click)="fvalid.toggle()" class="btn calendar" type="button"><img src="../../../../assets/Accountect/calendar.svg" class="img-18" alt=""></button>
    
                        </div>
                        <input #fvalid="ngbDatepicker" [(ngModel)]="from_date" class="form-control"
                          (dateSelect)="setFromToDate('from')" [minDate]="{year: 1900, month: 1, day: 1}" name="dp" (click)="fvalid.toggle()" ngbDatepicker
                          [placeholder]="'yyyy-mm-dd' | translate" readonly>
                        <div class="input-group-append ltr" *ngIf="auth.dirWeb === 'ltr'">
                          <button (click)="fvalid.toggle()" class="btn calendar" type="button"><img src="../../../../assets/Accountect/calendar.svg" class="img-18" alt=""></button>
    
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <input class="form-control" placeholder="yyyy-mm-dd" type="date" name="dp"> -->
                </div>
                <div class="w-50 mx-auto datepicker p-2 d-flex align-item-center">
                  <div class="form-inline custom-datepicker custom-datepicker-dropdown">
                    <div class="form-group m-0">
                      <div class="input-group">
                        <div class="input-group-append rtl" *ngIf="auth.dirWeb === 'rtl'">
                          <button (click)="fvalid.toggle()" class="btn calendar" type="button"><img src="../../../../assets/Accountect/calendar.svg" class="img-18" alt=""></button>
    
                        </div>
                        <input #tvalid="ngbDatepicker" [(ngModel)]="to_date" class="form-control"
                          (dateSelect)="setFromToDate('To')" [minDate]="{year: 1900, month: 1, day: 1}" name="dp" (click)="tvalid.toggle()" ngbDatepicker
                          [placeholder]="'yyyy-mm-dd' | translate" readonly>
                        <div class="input-group-append ltr" *ngIf="auth.dirWeb === 'ltr'">
                          <button (click)="fvalid.toggle()" class="btn calendar" type="button"><img src="../../../../assets/Accountect/calendar.svg" class="img-18" alt=""></button>
    
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <input class="form-control" placeholder="yyyy-mm-dd" type="date" name="dp"> -->
                </div>
                <!-- <div class="px-2 w-50">
                  <input type="text"
                  [placeholder]="auth.userData.emp_info.Business.default_date_format.display_date_format"
                  class="form-control"
                  type="date"
                  [(ngModel)]="to_date"
                  (change)="setFromToDate('To')">
                </div> -->
      
              </div>
              </div>
              <div class="d-flex justify-content-center bg-primary cursor-pointer round-bottom">
                <button class="btn btn-primary b-r-12 w-100" [disabled]="notalowed" (click)="downloadPDFNow()">
                  <i class="fa fa-download f-30 text-white" aria-hidden="true"></i>
                </button>
              </div>
      
              <div class="loader-alerts" *ngIf="isLoading">
                <img  style="width: 50px;" src="../../../../assets/Accountect/AC_ayers_2.gif">
              </div>
          </div>
          </div>
          
    
    
    
        <!-- </div>
      </div> -->
    
      <ng-template #copyLink let-modal>
        <div class="modal-ngb">
          <div class="modal-header p-1">
            <div class="w-100 px-3">
              <h6 class="text-dark font-weight-600 m-0 text-center">
                {{'Copy Link to Share'|translate}}
              </h6>
            </div>
            <div>
              <span aria-hidden="true" (click)="modal.dismiss('Cross click')" class="modal-close-btn">×</span>
            </div>
          </div>
          <div class="modal-body p-0 position-relative">
            <div class="my-4 p-4">
      
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text h-100 br-none bg-white">
                    <img src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/ledger/pdf.svg" class="img-20">
                  </span>
                </div>
                <input id="clipBoard" type="text" class="form-control bl-none " [value]="shareLink" disabled>
                <div class="input-group-append cursor-pointer" (click)="copyClipBoard()">
                  <span class="input-group-text h-100 bl-none">
                    <i class="fa fa-clone" aria-hidden="true"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="loader-alerts" *ngIf="isLoading">
            <img  style="width: 50px;" src="../../../../assets/Accountect/AC_ayers_2.gif">
          </div>
        </div>
      </ng-template>