// src/app/shared/services/event.service.ts
import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  public invokeFunction = new EventEmitter<any>();

  constructor() {}

  public callFunction(param: any) {
    debugger
    this.invokeFunction.emit(param);
  }
}
