<ng-template #addinvPro let-modal>
    <div class="modal-ngb">
      <div class="modal-header p-1">
        <div class="w-100">
          <div class="modal-heading py-2 d-flex justify-content-between align-item-center">
            <h5 class="modal-title" id="modal-basic-title" *ngIf="!ledgerService?.Edititem"><img src="../../../../assets/Accountect/Add Button.svg"
                class=" img-25 mr-2"> {{'Add Product' | translate}}</h5>
            <h5 class="modal-title" id="modal-basic-title" *ngIf="ledgerService?.Edititem"><img src="../../../../assets/Accountect/Add Button.svg"
                  class=" img-25 mr-2"> {{'Edit Product' | translate}}</h5>
            <button type="button" (click)="modal.dismiss()" class="btn-close mr-3" data-bs-dismiss="modal"
              aria-label="Close"></button>
          </div>
        </div>
      </div>
      <div class="modal-body ledger-debit-modal">
        <form [formGroup]="ledgerService?.addInventory">
          <div class="Height-adjust">
            <div class="px-2">
              <div class="d-flex justify-content-end px-2">
                <div class="w-50 datepicker p-2">
                  <div *ngIf="!ledgerService?.Edititem" class="form-inline custom-datepicker custom-datepicker-dropdown">
                    <div class="form-group m-0">
                      <div class="input-group" >
                        <div class="input-group-append rtl" *ngIf="auth.dirWeb === 'rtl'">
                          <button (click)="fvalid.toggle()" class="btn calendar" type="button"><img src="../../../../assets/Accountect/calendar.svg" class="img-18" alt=""></button>
  
                        </div>
                        <input #fvalid="ngbDatepicker"
                          [value]="ledgerService.addInventory.value.created_at | date: dateFormat " class="form-control"
                          (dateSelect)="ledgerService.selectRefDate($event)" [minDate]="{year: 1900, month: 1, day: 1}"
                          name="dp" (click)="fvalid.toggle()" ngbDatepicker placeholder="mm-dd-yyyy" readonly disabled>
                        <div class="input-group-append ltr" *ngIf="auth.dirWeb === 'ltr'">
                          <button (click)="fvalid.toggle()" class="btn calendar" type="button"><img src="../../../../assets/Accountect/calendar.svg" class="img-18" alt=""></button>
  
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex align-items-center px-2">
                <div class="w-100">
                  <label for="floatingInput" class="f-14 text-light"><span>{{'Product Name'|translate}}</span></label>
                  <input class="form-control form-control-sm" id="floatingInput" type="text" maxlength="48" (input)="Namelimit($event)" 
                  formControlName="product_name">
                </div>
              </div>
              <div class="my-3  mx-2" *ngIf="!ledgerService?.Edititem">
                <label class="f-14 text-light"><span>{{'Product Categories'|translate}}</span></label>
                <div class="cursor-pointer m-0 py-1 border b-r-5">
                  <div class="d-flex justify-content-between px-2" (click)="openaddnew(addnew)">
                    <p *ngIf="!categoryName" class="f-14 m-0 text-light opacityhalf">{{'Choose Category' | translate}}</p>
                    <p *ngIf="categoryName" class="f-14 m-0 text-dark">{{categoryName}}</p>
                    <p class=" m-0"><i class="fa fa-angle-down f-20 text-light"></i></p>
                  </div>
                </div>
              </div>
              <div class="my-3 px-2" *ngIf="!ledgerService?.Edititem">
                <div class=" d-flex align-items-center justify-content-between">
                  <div class="w-49">
                    <label for="floatingInput" class="f-14 text-light"><span>{{'Cost per Unit'|translate}}</span></label>
                    <input class="form-control form-control-sm" id="floatingInput" type="number" formControlName="unit_price">
                  </div>
                  <div class="w-49">
                    <label for="floatingInput"
                      class="f-14 text-light"><span>{{'Currency'|translate}}</span></label>
                    <input class="form-control text-primary font-weight-bold form-control-sm" disabled id="floatingInput" type="text" [(ngModel)]="busCurrency" [ngModelOptions]="{standalone: true}">
                  </div>
                </div>
                <span class="f-13 mb-1 text-primary font-weight-bold">{{'Used in Purchase and Items'|translate}}</span>
              </div>
              <div class="my-3 px-2" *ngIf="!ledgerService?.Edititem">
                <div class="d-flex align-items-center justify-content-between">
                  <div class="w-49">
                    <label for="floatingInput" class="f-14 text-light"><span>{{'Opening Stock'|translate}}</span></label>
                    <input class="form-control form-control-sm" id="floatingInput" type="number" min="1" step="1"
                      formControlName="quantity">
                  </div>
                  <!-- <div class="w-49">
                        <label for="floatingInput"
                      class="f-14 text-light"><span>{{'UOM'|translate}}</span></label>
                    <select class="form-select" formControlName="uom" [(ngModel)]="selectedUom" (change)="onChangeUom($event.target.value)" filledValue>
                      <ng-container *ngFor="let item of uomtype">
                            <option [selected]="item?.id === 16" [value]="item?.id">{{item?.display_unit_name | translate}}</option>
                          </ng-container>
                        </select>
                  </div> -->
                  <div class="SelectCustomStyle w-49">
                    <label for="floatingInput" class="f-14 text-light"><span>{{'UOM'|translate}}</span></label>
                    <select class="form-select form-select-sm font-weight-bold text-primary" formControlName="uom" [(ngModel)]="selectedUom" (change)="onChangeUom($event.target.value)" filledValue>
                      <option [value]="defaultUomValue">Select UOM</option>
                      <ng-container *ngFor="let item of uomtype">
                        <option [value]="item?.id">{{item?.display_unit_name | translate}}</option>
                      </ng-container>
                    </select>
                  </div>                
                </div>
                <span class="f-13 mb-1 text-primary font-weight-bold">{{'This is your current stock level'|translate}}</span>
  
                <!-- <div class="w-49">
                      <label for="floatingInput"
                    class="f-14 text-light"><span>{{'UOM'|translate}}</span></label>
                  <select class="form-select" formControlName="uom" [(ngModel)]="selectedUom" (change)="onChangeUom($event.target.value)" filledValue>
                    <option [value]="defaultUomValue" disabled selected >Select UOM</option>
                    <ng-container *ngFor="let item of uomtype">
                          <option [selected]="item?.id === 16" [value]="item?.id">{{item?.display_unit_name | translate}}</option>
                        </ng-container>
                      </select>
                </div> -->
                <!-- <div class="w-49">
                  <label for="floatingInput" class="f-14 text-light"><span>{{'UOM'|translate}}</span></label>
                  <select class="form-select" formControlName="uom" [(ngModel)]="selectedUom" (change)="onChangeUom($event.target.value)" filledValue>
                    <ng-container *ngFor="let item of uomtype">
                      <option [value]="item?.id">{{item?.display_unit_name | translate}}</option>
                    </ng-container>
                  </select>
                </div> -->
                
              </div>
              <div class="my-3 px-2">
                <div class=" d-flex align-items-center justify-content-between">
                  <div class="w-49">
                    <label for="floatingInput" class="f-14 text-light"><span>{{'Selling price per Unit'|translate}}</span></label>
                    <input class="form-control form-control-sm" id="floatingInput" type="number" formControlName="selling_price_per_unit" (input)="updateMarketVisibility($event.target.value)">
                  </div>
                  <div class="w-49">
                    <label for="floatingInput"
                      class="f-14 text-light"><span>{{'Currency'|translate}}</span></label>
                    <input class="form-control form-control-sm f-14 font-weight-bold text-primary" disabled id="floatingInput" type="text" [(ngModel)]="busCurrency" [ngModelOptions]="{standalone: true}">
                  </div>
                </div>
                <span class="f-13 mb-1 text-primary font-weight-bold">{{'Used in Invoice, Market, Estimate'|translate}}</span>
              </div>
              <div class="mx-2">
                <div>
                  <h6 class="text-light datae">({{'Optional'|translate}})</h6>
                </div>
                <hr class="hri-2">
              </div>
              
              <div class="my-3 d-flex align-items-center px-2">
                <div class="w-100">
                  <label for="floatingInput" class="f-14 text-light"><span>{{'Minimum Stock Level'|translate}}</span></label>
                  <input class="form-control form-control-sm" id="floatingInput" type="number" formControlName="minimum_stock_level">
                </div>
              </div>
              <div class="my-3 d-flex align-items-center px-2">
                <div class="w-100">
                  <label class="f-14 text-light"><span>{{'Description'|translate}}</span></label>
                  <textarea class="form-control" id="floatingTextarea"></textarea>
                </div>
              </div>
              <div class="px-2 mt-2">
                <div class="w-100 upload-img">
                  <div class="camera-div">
                    <input (change)="ledgerService.selectInvnFile($event)" type="file" multiple="multiple"
                      class="form-control-file" id="exampleFormControlFile1">
                    <div class="w-100 position-relative">
                      <img *ngIf="!ledgerService?.uploadFile" class="upl-img"
                        src="../../../../assets/Accountect/asset.svg" alt="">
                      <div *ngIf="ledgerService?.uploadFile" class="spinner-border text-primary" role="status">
                      </div>
                    </div>
                  </div>
                  <ng-container *ngFor="let item of ledgerService?.previewUrl">
                    <div *ngIf="item?.file_type === 'image'" class="camera-div mx-1 z-index position-relative">
                      <i (click)="ledgerService.deleteInvFile(item.id)"
                        class="fa fa-minus-circle cursor-pointer text-danger" aria-hidden="true"></i>
                      <img [src]="item?.file_path?item?.file_path: ''" class="upl-imges" alt="">
                    </div>
                    <div [ngbTooltip]="item?.file_name" *ngIf="item?.file_type !== 'image'"
                      class="camera-div z-index mx-1 position-relative">
                      <i (click)="ledgerService.deleteInvFile(item.id)"
                        class="fa fa-minus-circle cursor-pointer text-danger" aria-hidden="true"></i>
                      <a [href]="item?.file_path" target="_blank" [download]="item?.file_name">
                        <img *ngIf="item?.file_name?.includes('svg') " class="upl-imges obj-fit-contain"
                          src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/files-icons/pdf.png">
                        <img *ngIf="item?.file_name?.includes('pdf') " class="upl-imges obj-fit-contain"
                          src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/files-icons/pdf.png">
                        <img *ngIf="item?.file_name?.includes('txt') " class="upl-imges obj-fit-contain"
                          src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/files-icons/txt.png">
                        <img *ngIf="item?.file_name?.includes('saad-jugar') " class="upl-imges obj-fit-contain"
                          src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/files-icons/word.png">
                        <img *ngIf="item?.file_name?.includes('docx')" class="upl-imges obj-fit-contain"
                          src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/files-icons/word.png">
                        <img *ngIf="item?.file_name?.includes('pptx')" class="upl-imges obj-fit-contain"
                          src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/files-icons//ppt.png">
                        <img *ngIf="item?.file_name?.includes('xls') " class="upl-imges obj-fit-contain"
                          src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/files-icons/excel.png">
                      </a>
                    </div>
                  </ng-container>
                  <div *ngIf="ledgerService?.previewUrl?.length<1"
                    class="d-flex align-items-center justify-content-center w-80">
                    <span class="text-light">( {{'You can upload upto 5 files'|translate}} )</span>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-between b-b py-3">
                <div class="mx-3 my-auto text-light f-14">
                  <div>{{'Market Visibility'|translate}}</div>
                </div>
                <div>
                  <label class="switch">
                    <input type="checkbox" formControlName="market_visibility" [checked]="marketVisibility">
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>
  
            </div>
          </div>
        </form>
      </div>
      <ng-container *ngIf="!ledgerService?.Edititem">
        <div *ngIf="!ledgerService?.transLoad && !stockIn" [class.disabled]="!ledgerService.addInventory.valid"
          class="d-flex justify-content-center cursor-pointer bg-primary p-3 round-bottom border-top"
          (click)="ledgerService?.AdditemProduct(); modal.dismiss()">
          <button class="btn-tranparent cursor-pointer" [disabled]="!ledgerService.addInventory.valid">
            <img class="img-30" src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/White tick.svg" />
          </button>
        </div>
        <div *ngIf="!ledgerService?.transLoad && stockIn" [class.disabled]="!ledgerService.addInventory.valid"
          class="d-flex justify-content-center cursor-pointer bg-primary p-3 round-bottom border-top"
          (click)="ledgerService?.AddInventoryStockIn()">
          <button class="btn-tranparent cursor-pointer" [disabled]="!ledgerService.addInventory.valid">
            <img class="img-30" src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/White tick.svg" />
          </button>
        </div>
      </ng-container>
    
      <ng-container *ngIf="ledgerService?.Edititem && !ledgerService?.transLoad">
        <ng-container *ngIf="ledgerService.itemMarketVal=='item'">
          <div 
          class="d-flex justify-content-center cursor-pointer bg-primary p-3 round-bottom border-top"  
          (click)="ledgerService?.EditProduct();modal.dismiss()" [class.disabled]="!(ledgerService.addInventory.value.selling_price_per_unit && ledgerService.addInventory.value.product_name)" >
          <button class="btn-tranparent cursor-pointer">
            <img class="img-30" src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/White tick.svg" />
          </button>
        </div>
        </ng-container>
        
        <ng-container *ngIf="ledgerService.itemMarketVal=='market'">
          <div 
          class="d-flex justify-content-center cursor-pointer bg-primary p-3 round-bottom border-top"  
          (click)="ledgerService?.EditProductMarket();modal.dismiss()" >
          <button class="btn-tranparent cursor-pointer">
            <img class="img-30" src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/White tick.svg" />
          </button>
        </div>
        </ng-container>
        
      </ng-container>
      <div *ngIf="ledgerService?.transLoad " [class.disabled]="true" 
        class="d-flex justify-content-center p-3 bg-primary round-bottom border-top">
        <button class="btn-tranparent cursor-pointer" [disabled]="true">
          <img src="../../../../assets/Accountect/AC_ayers_2.gif" class="img-50" alt="">
        </button>
      </div>
    </div>
</ng-template>

<ng-template #addService let-modal>
  <div class="modal-ngb">
    <div class="modal-header p-1">
      <div class="w-100">
        <div class="modal-heading py-2 d-flex justify-content-between align-item-center">
          <h5 class="modal-title" id="modal-basic-title" *ngIf="!EditProduct"><img
              src="../../../../assets/Accountect/Add Button.svg" class=" img-25 mr-2"> {{'Add Service' | translate}}
          </h5>
          <h5 class="modal-title" id="modal-basic-title" *ngIf="EditProduct"><img
              src="../../../../assets/Accountect/Add Button.svg" class=" img-25 mr-2"> {{'Edit Service' | translate}}
          </h5>
          <button type="button" (click)="modal.dismiss()" class="btn-close mr-3" data-bs-dismiss="modal"
            aria-label="Close"></button>
        </div>
      </div>
    </div>
    <div class="modal-body ledger-debit-modal">
      <div class="Height-adjust">
        <form [formGroup]="ledgerService?.addService">
          <div class="py-2 px-2">
            <div class="mt-2 d-flex align-items-center px-2">
              <div class="w-100">
                <label for="floatingInput" class="f-14 text-light"><span>{{'Service Name'|translate}}</span></label>
                <input class="form-control form-control-sm" id="floatingInput" type="text" maxlength="48"
                  (input)="Namelimit($event)" formControlName="product_name">
              </div>
            </div>
            <div class="my-3 px-2">
              <div class=" d-flex align-items-center justify-content-between">
                <div class="w-49">
                  <label for="floatingInput" class="f-14 text-light"><span>{{'Unit Cost'|translate}}</span></label>
                  <input class="form-control form-control-sm" id="floatingInput" type="number"
                    formControlName="sales_price">
                </div>
                <div class="w-49">
                  <label for="floatingInput" class="f-14 text-light"><span>{{'Currency'|translate}}</span></label>
                  <input class="form-control form-control-sm f-14 font-weight-bold text-primary" disabled
                    id="floatingInput" type="text" formControlName="currency">
                </div>
              </div>
              <span class="f-13 mb-1 text-primary font-weight-bold">{{'Used in Invoice, Market,
                Estimate'|translate}}</span>
            </div>
            <div class="my-3 d-flex align-items-center justify-content-between px-2">
              <div class="w-100">
                <label for="floatingInput" class="f-14 text-light"><span>{{'Selling Price per
                    Unit'|translate}}</span></label>
                <input class="form-control form-control-sm" id="floatingInput" type="number"
                  formControlName="selling_price_per_unit">
              </div>
            </div>
            <div class="mx-2">
              <div>
                <h6 class="text-light datae">({{'Optional'|translate}})</h6>
              </div>
              <hr class="hri-2">
            </div>
            <div class="mt-2 px-2 w-100">
              <label for="floatingInput" class="f-14 text-light"><span>{{'Description'|translate}}</span></label>
              <textarea class="form-control form-control-sm w-100" formControlName="information"></textarea>
            </div>
            <div class="px-2 mt-2">
              <div class="w-100 upload-img">
                <div class="camera-div">
                  <input (change)="ledgerService?.selectServiceFile($event)" type="file" multiple="multiple"
                    class="form-control-file" id="exampleFormControlFile1">
                  <div class="w-100 position-relative">
                    <img *ngIf="!ledgerService?.uploadFile" class="upl-img"
                      src="../../../../assets/Accountect/asset.svg" alt="">
                    <div *ngIf="ledgerService?.uploadFile" class="spinner-border text-primary" role="status">
                    </div>
                  </div>
                </div>
                <ng-container *ngFor="let item of ledgerService.previewUrl">
                  <div *ngIf="item?.file_type === 'image'" class="camera-div mx-1 z-index position-relative">
                    <i (click)="ledgerService.deleteMarketServiceOrProductFile(item.id)"
                      class="fa fa-minus-circle cursor-pointer text-danger" aria-hidden="true"></i>
                    <img [src]="item?.file_path?item?.file_path: ''" class="upl-imges" alt="">
                  </div>
                  <div [ngbTooltip]="item?.file_name" *ngIf="item?.file_type !== 'image'"
                    class="camera-div z-index mx-1 position-relative">
                    <i (click)="ledgerService.deleteMarketServiceOrProductFile(item.id)"
                      class="fa fa-minus-circle cursor-pointer text-danger" aria-hidden="true"></i>
                    <a [href]="item?.file_path" target="_blank" [download]="item?.file_name">
                      <img *ngIf="item?.file_name?.includes('svg') " class="upl-imges obj-fit-contain"
                        src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/files-icons/pdf.png">
                      <img *ngIf="item?.file_name?.includes('pdf') " class="upl-imges obj-fit-contain"
                        src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/files-icons/pdf.png">
                      <img *ngIf="item?.file_name?.includes('txt') " class="upl-imges obj-fit-contain"
                        src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/files-icons/txt.png">
                      <img *ngIf="item?.file_name?.includes('saad-jugar') " class="upl-imges obj-fit-contain"
                        src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/files-icons/word.png">
                      <img *ngIf="item?.file_name?.includes('docx')" class="upl-imges obj-fit-contain"
                        src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/files-icons/word.png">
                      <img *ngIf="item?.file_name?.includes('pptx')" class="upl-imges obj-fit-contain"
                        src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/files-icons//ppt.png">
                      <img *ngIf="item?.file_name?.includes('xls') " class="upl-imges obj-fit-contain"
                        src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/files-icons/excel.png">
                    </a>
                  </div>
                </ng-container>
                <div *ngIf="ledgerService.previewUrl?.length<1"
                  class="d-flex align-items-center justify-content-center w-80">
                  <span class="text-light">( {{'You can upload upto 5 files'|translate}} )</span>
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-between b-b py-3">
              <div class="mx-3 my-auto f-14">
                <div>{{'Market Visibility'|translate}}</div>
              </div>
              <div>
                <label class="switch">
                  <input type="checkbox" formControlName="market_visibility" #checkbox>
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div *ngIf="!ledgerService?.marketServiceLoad && !ledgerService?.Edititem" [class.disabled]="!ledgerService.addService.valid"
      class="d-flex justify-content-center cursor-pointer bg-primary p-3 round-bottom border-top"
      (click)="ledgerService.AddMarketService();modal.dismiss()">
      <button class="btn-tranparent cursor-pointer">
        <img class="img-30" src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/White tick.svg" />
      </button>
    </div>
    <div *ngIf="ledgerService?.Edititem && !ledgerService?.transLoad" [class.disabled]="!ledgerService.addService.valid"
      class="d-flex justify-content-center cursor-pointer bg-primary p-3 round-bottom border-top"
      (click)="ledgerService.EditService();modal.dimiss()">
      <button class="btn-tranparent cursor-pointer">
        <img class="img-30" src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/White tick.svg" />
      </button>
    </div>
    <div *ngIf="ledgerService?.marketServiceLoad" [class.disabled]="true" class="d-flex justify-content-center p-3 bg-primary round-bottom border-top">
      <button class="btn-tranparent cursor-pointer" [disabled]="true">
        <div class="spinner-border text-white" role="status"></div>
      </button>
    </div>

  </div>
</ng-template>

<ng-template #addnew let-modal>
    <div class="modal-header">
      <h5 class="modal-title" id="modal-basic-title">
        <img src="../../../../assets/Accountect/Add Button.svg" alt="">
          {{'Add Category' | translate}}
      </h5>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
      <div class="h-330">
        <div class="mx-2">
          <div *ngIf="categoryListingLoader" class="overlay-loader">
            <div class="spinner-border spinner-border-sm text-primary m-auto" role="status">
            </div>
          </div>
          <ng-container *ngIf="!categoryListingLoader">
            <div *ngFor="let item of ledgerService?.invnCat" class="ShadowPayment bg-light payment d-flex justify-content-between mt-2">
              <div class="cross-ico d-flex align-items-center" ngbDropdown placement="bottom-left" [class.disabled]="item.creator_Business == null">
                <div class=" cursor-pointer" ngbDropdownToggle>
                 <div> <i class="fa fa-ellipsis-v p-2" aria-hidden="true"></i> </div>
                </div>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                  <div ngbDropdownItem (click)="openEditCategoryModal(item)"
                    class="d-flex align-items-center justify-content-start cursor-pointer">
                      <div>
                        <span class="mx-3">{{'Edit' | translate}}</span>
                      </div>
                  </div>
                  <div ngbDropdownItem (click)="ledgerService?.DeleteProductCat(item)"
                    class="d-flex align-items-center justify-content-start cursor-pointer">
                      <div>
                        <span class="mx-3">{{'Delete' | translate}}</span>
                      </div>
                  </div>
                  
                </div>
              </div>
              <div (click)="categoryAddToExpenseForm(item, modal)"
                class="d-flex justify-content-between w-100 py-2 border-btm">
                <div class="d-flex">

                  <div class="edit">
                    {{item?.title}}
                  </div>
                </div>
              </div>
              <div class="w-5 align-self-center py-2 text-end">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z"/>
                </svg>
              </div>
  
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="modal-footer justify-content-center">
      <button type="button" class="btn" (click)="AddCategory(AddCategoryname)"><img
          src="../../../../assets/Accountect/Add Button.svg" class="mx-1" alt="">{{'Add New' | translate}}</button>
    </div>
  </ng-template>

  <!-- <ng-template #AddCategoryname let-modal>
    <div class="ngb-modal">
      <div class="modal-header p-1">
        <div class="w-100">
          <div class="modal-heading py-2 d-flex justify-content-between align-item-center">
            <h5 class="modal-title f-16" id="modal-basic-title"><img src="../../../../assets/Accountect/Add Button.svg"
                class=" img-20 mr-2"> {{'Add Category' | translate}}</h5>
            <button type="button" (click)="modal.dismiss()" class="btn-close mr-3" data-bs-dismiss="modal"
              aria-label="Close"></button>
          </div>
        </div>
      </div>
      <div class="modal-body p-2">
        <form [formGroup]="ledgerService?.addProductCat">
          <div class="mb-3 d-flex align-items-center">
            <div class="w-100">
              <label class="f-14 text-light"><span>{{'Title'|translate}}</span></label>
              <input class="form-control" formControlName="title" type="text">
            </div>
          </div>
        </form>
        <div *ngIf="!ledgerService?.transLoad" [class.disabled]="!ledgerService.addProductCat.valid"
        class="d-flex justify-content-center cursor-pointer bg-primary" (click)="ledgerService?.addprodcat(modal)">
        <button class="btn-tranparent cursor-pointer" [disabled]="!ledgerService.addProductCat.valid">
          <img class="img-30" src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/White tick.svg" />
        </button>
      </div>
      </div>
    </div>
  </ng-template> -->



  <ng-template #AddCategoryname let-modal="modal">
    <div class="ngb-modal">
      <div class="modal-header p-1">
        <div class="w-100">
          <div class="modal-heading py-2 d-flex justify-content-between align-item-center">
            <h5 class="modal-title f-16" id="modal-basic-title"><img src="../../../../assets/Accountect/Add Button.svg"
                class=" img-20 mr-2"> {{'Add Category' | translate}}</h5>
            <button type="button" (click)="modal.dismiss()" class="btn-close mr-3" data-bs-dismiss="modal"
              aria-label="Close"></button>
          </div>
        </div>
      </div>
      <div class="modal-body p-2">
        <form [formGroup]="ledgerService?.addProductCat">
          <div class="mb-3 d-flex align-items-center">
            <div class="w-100">
              <label class="f-14 text-light"><span>{{'Title'|translate}}</span></label>
              <input class="form-control" formControlName="title" type="text">
            </div>
          </div>
        </form>
        <div *ngIf="!ledgerService?.transLoad" [class.disabled]="!ledgerService.addProductCat.valid"
          class="d-flex justify-content-center cursor-pointer bg-primary" >
          <button class="btn-tranparent cursor-pointer" *ngIf="!isEditMode" [disabled]="!ledgerService.addProductCat.valid" (click)="ledgerService?.addprodcat(modal); modal.dismiss()">
            <img class="img-30" src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/White tick.svg" />
          </button>
          <button class="btn-tranparent cursor-pointer" *ngIf="isEditMode" [disabled]="!ledgerService.addProductCat.valid" (click)="editCategory(AddCategoryname);" >
            <img class="img-30" src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/White tick.svg" />
          </button>
        </div>
        <div *ngIf="ledgerService?.transLoad" class="d-flex justify-content-center cursor-pointer bg-primary">
          <button class="btn-tranparent cursor-pointer">
            <div class="spinner-border text-white" role="status"></div>
          </button>
        </div>
      </div>
    </div>
  </ng-template>
  