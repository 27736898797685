import { Injectable } from '@angular/core';
import { AbstractControl, Form, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbCalendar, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from 'express';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from './auth.service';
import { HttpServiceService } from './http/http-service.service';
import Swal from 'sweetalert2';
import { find } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { AudioRecordingService } from 'src/app/audio-recording.service';
import { Subject } from 'rxjs';
declare var $: any;
@Injectable({
  providedIn: 'root'
})
export class EmployeeService {
  public subject1 = new Subject<any>();
  public addEmployeeForm: FormGroup;
  public attendanceForm: FormGroup;
  public employeeledgerList: FormGroup;
  addEmpTransection: FormGroup
  addCashBankForm: FormGroup
  MonthSelect: FormGroup
  transType: any
  additionchargesForm: FormGroup
  editadditionchargesForm: FormGroup
  employeesData: any;
  SlipDetails: any
  listCall:boolean
  Slip: any
  attendanceData:any
  allempoyee: any = [];
  deductions: any = []
  imgLoader: boolean = false;
  form_Data = new FormData();
  todayDate = this.calendar.getToday();
  empLoader: boolean = false;
  activeloader: boolean = false
  salaryview: boolean = false
  salaryLoader: boolean = false
  moveLoader: boolean = false
  addTransectionData: any
  employeedetail: any
  empledgercurrentpage = 1
  empledgertotalpage: any
  EmployeePaySlip: any
  employeeLedger: any
  employeeLedgerTransData:any
  step1Submit: boolean = false;
  step2Submit: boolean = false;
  activeEmployee: any = '';
  salarySlip: any
  allyears: any =[]
  ledgerListsearch: any
  selectedEmployee: any
  employeeFilter: any = 'all';
  salaryDates: any = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
  salaryDays: any = [{ id: 0, day: 'Monday' }, { id: 1, day: 'Tuesday' }, { id: 2, day: 'Wednesday' }, { id: 3, day: 'Thursday' }, { id: 4, day: 'Friday' }, { id: 5, day: 'Saturday' }, { id: 6, day: 'Sunday' }];
  salaryUnit: any = [{ id: 4, name: 'Month', value: 'month' }, { id: 3, name: 'Week', value: 'week' }, { id: 2, name: 'Day', value: 'day' }];
  rateUnit: any = [{ id: 2, name: 'Day', value: 'day' }, { id: 1, name: 'Hours', value: 'hours' }]
  employeeAttendance: any;
  dateFilter: any = new Date();
  selectedEmp: any;
  attendLoader: boolean = false;
  submitAttend = false;
  inActiveDate: any;
  extraHours: any = {
    extra_hours: 0,
    overtime_rate: 0
  };
  netSalary: any
  employeeledgerForm: FormGroup
  empAttDetail: any;
  attendEmpLoader: boolean = false;
  attendanceSingle: FormGroup;
  selectEmpDetail: any;
  loadSubmit: boolean;
  dateFilterSingle: any = new Date();
  detailView: boolean = false;
  longYear: any = new Date().getFullYear()
  longMonth: any = new Date().toLocaleString('en-us', { month: 'long' })
  viewdetail: boolean = false;
  editProfile: boolean = false;
  previewUrl: any = [];
  uploadFile: boolean = false;
  fileData: any;
  employeeFilters = {
    startDate: '',
    endDate: '',
    active: '',
    query: '',
    salary: 'all',
    page: 1
  }
  attendanceFilters = {
    query: '',
    page: 1
  }
  allMonth: any =[
        { 'id': 1, 'name': 'January' },
        { 'id': 2, 'name': 'February' },
        { 'id': 3, 'name': 'March' },
        { 'id': 4, 'name': 'April' },
        { 'id': 5, 'name': 'May' },
        { 'id': 6, 'name': 'June' },
        { 'id': 7, 'name': 'July' },
        { 'id': 8, 'name': 'August' },
        { 'id': 9, 'name': 'September' },
        { 'id': 10, 'name': 'October' },
        { 'id':11, 'name': 'November' },
        { 'id': 12, 'name': 'December' }
  ]
  page: number = 1;
  allAttendanceList: any = [];
  public listAddons: any;
  public selectedSlipId: any = null;
  constructor(private http: HttpServiceService, public auth: AuthService,
    public toast: ToastrService, public translate: TranslateService,public audioService: AudioRecordingService,
    public fb: FormBuilder, private calendar: NgbCalendar, public modalService: NgbModal) {
    this.initializeEmployeeForm();
    this.initializeAttendanceForm();
    this.initializeAttendanceFormSingle();
    this.employeeledgerList = this.fb.group({
      emp_detail_id: [''],
      search_query: [''],
      transaction_type: [''],
      start_date: [''],
      end_date: [''],
    })
    this.additionchargesForm = this.fb.group({
      id: null,
      amount: ['', Validators.required],
      type: ['', Validators.required],
      details: [''],
      amount_in: ['', Validators.required]
    })
    this.editadditionchargesForm = this.fb.group({
      amount: ['', Validators.required],
      type: [''],
      details: [''],
      amount_in: ''
    })
    this.MonthSelect = this.fb.group({
      month: [null, Validators.required],
      year: [null,Validators.required],
      emp_detail_id: [null]
    })
    this.addEmpTransection = this.fb.group({
      transaction_type: ['', Validators.required],
      amount: ['', Validators.required],
      starting_date: ['', Validators.required],
      transaction_sub_type: [''],
      is_advanced_balance: [''],
      // ledger_room_id: ['', Validators.required],
      id: [''],
      details: [''],
      files: [],
      emp_detail_id: null,
      // transaction_sub_type: ['', Validators.required]
    });
    this.addCashBankForm = this.fb.group({
      record_type: [''],
      trans_id: [''],
      bank_id: [''],

    })
    this.yearsArray()
  }
  returnSalaryUnit() {
    try {
      let find = this.salaryUnit.find(x => x.id.toString() === this.addEmployeeForm?.get('salary').get('salary_unit').value);
      return find?.name;
    } catch (e) {
      return this.salaryUnit[0].name
    }
  }
  returnSalaryDayById(day) {
    try {
      let find = this.salaryDays.find(x => x.id.toString() === day?.toString());
      //  console.log("date ya dy", find?.day);
      
      return find?.day;
    } catch (e) {}
  }
  initializeAttendanceForm() {
    this.attendanceForm = this.fb.group({
      deductions: 0,
      is_absent: false,
      is_approved_holiday: false,
      is_present: false,
      is_public_holiday: false,
      is_sick_holiday: false,
      no_of_pieces: 0,
      rate: 0,
      sales: 0,
      commission: 0,
      hours: 0,
      is_deduction: false,
      extra_hours: 0,
      overtime_rate: 0,
      fix_hours: 0
    })
  }
  initializeAttendanceFormSingle() {
    this.attendanceSingle = this.fb.group({
      deductions: false,
      is_absent: false,
      is_approved_holiday: false,
      is_present: false,
      is_public_holiday: false,
      is_sick_holiday: false,
      no_of_pieces: 0,
      rate: 0,
      sales: 0,
      commission: 0,
      hours: 0,
      is_deduction: false,
      extra_hours: null,
      overtime_rate: null,
      fix_hours: null
    })
  }
  initializeEmployeeForm() {
    this.addEmployeeForm = this.fb.group({
      id: null,
      first_name: ['', Validators.required],
      last_name: [''],
      address: [''],
      id_number: [''],
      phone: [''],
      email: ['', [
      this.addValidationOnControlValue,
       Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
      ]],
      job_title: [''],
      joining_date: ['', Validators.required],
      contact_person_name: [''],
      contact_number: [''],
      contact_email: [''],
      image_path: [''],
      dob: [''],
      file: [],
      salary: this.fb.group({
        salary_type: ['', Validators.required],
        salary_date: [''],
        salary_unit: [''],
        base_salary: [''],
        required_hours: ['', Validators.required],
        rate: [''],
        rate_unit: [''],
        commision: ['']
      }),
    });
  }
  addValidationOnControlValue(formControl: AbstractControl) {
    if (!formControl.parent) {
      return null;
    }
    if (formControl.parent.get('email').value) {
      return Validators.required(formControl); 
    }
    return null;
  }
  editEmployee(model) {
    let data = this.selectEmpDetail;
    //  console.log('this is data of edit profile', data);
    this.resetAllForm();
    this.addEmployeeForm.patchValue({
      first_name: data?.first_name,
      last_name: data?.last_name,
      address: data?.address,
      id_number: data?.id_number,
      phone: data?.phone,
      email: data?.email,
      job_title: data?.job_title,
      // joining_date: this.ngbDateFormat(data?.joining_date),
      joining_date: data?.joining_date,
      dob: data?.dob,
      // dob: this.ngbDateFormat(data?.dob),
      contact_person_name: data?.emergency_contact?.contact_person_name,
      contact_number: data?.emergency_contact?.contact_number,
      contact_email: data?.contact_email,
      image_path: data?.image_path,
      id: data?.id
    });
    try {
     if (data['files']?.length) {
      let array = [];
      for (let item of data['files']) {
        this.previewUrl.push(item);
        array.push(item.id)
      }
      this.addEmployeeForm.patchValue({
        file: array
      });
     }
    } catch (e) { }
    this.addEmployeeForm.get('salary').patchValue({
        salary_type: data?.salary_info?.salary_type?.id,
        salary_date: data?.salary_info?.salary_date,
        salary_unit: data?.salary_info?.salary_unit?.id
    });
    if (this.addEmployeeForm.get('salary').get('salary_type')?.value?.toString() == '1') {
      this.requiredFullTime();
      setTimeout(() => {
        $('#fullTime').prop("checked", true);
        $('#fullTime').show('slow');
      }, 1000);
      this.addEmployeeForm.get('salary').patchValue({
        base_salary: data?.salary_info?.fixsalary?.base_salary,
        required_hours: data?.salary_info?.fixsalary?.required_hours
    });
    } else if (this.addEmployeeForm.get('salary').get('salary_type')?.value?.toString() == '2') {
      this.requiredPartTime();
      setTimeout(() => {
        $('#partTime').prop("checked", true);
        $('#partTime').show('slow');
      }, 1000);
      this.addEmployeeForm.get('salary').patchValue({
        rate: data?.salary_info?.contractsalary?.rate,
        rate_unit: data?.salary_info?.contractsalary?.rate_unit?.id
    });
    } else if (this.addEmployeeForm.get('salary').get('salary_type')?.value?.toString() == '3') {
      this.requiredFactory();
      setTimeout(() => {
        $('#factoryEmployee').prop("checked", true);
        $('#factoryEmployee').show('slow');
      }, 1000);
      this.addEmployeeForm.get('salary').patchValue({
        base_salary: data?.salary_info?.piece_salary?.base_salary,
        rate: data?.salary_info?.piece_salary?.rate,
        rate_unit: data?.salary_info?.piece_salary?.rate_unit?.id
    });
    } else {
      this.requiredStaff();
      setTimeout(() => {
        $('#salesStaff').prop("checked", true);
        $('#salesStaff').show('slow');
      }, 1000);
      this.addEmployeeForm.get('salary').patchValue({
        base_salary: data?.salary_info?.commission_salary?.base_salary,
        rate_unit: data?.salary_info?.commission_salary?.rate_unit?.id,
        commision: data?.salary_info?.commission_salary?.commision
    });
    }
    this.editProfile = true;
    //  console.log('this is the form after values patched', this.addEmployeeForm.value.dob);
    
    this.modalService.open(model, { centered: true, windowClass: 'Add-Employee', size: 'md' });
  }
  returnSalaryDay(day: any = '', type: any = 'week') {
    try {
      if (type == 'week') {
        return this.salaryDays.find((x) => x.day.toLowerCase() == day.toLowerCase());
      }
      this.salaryDates.find((x) => x.day.toLowerCase() == day.toLowerCase());
    } catch (e) {}
  }
  ngbDateFormat(date) {
    //  console.log("Date of Birth is",date);
    try {
      if (date) {
        let transDate = { year: 0, month: 0, day: 0 };
        var dateObj = new Date(date);
        transDate.year = dateObj.getUTCFullYear();
        transDate.month = dateObj.getUTCMonth() + 1;
        transDate.day = dateObj.getUTCDate();
       
        
        return transDate;
      }
    } catch (e) {
      return null;
    }
  }
  resetAttendanceFormValidation() {
    this.submitAttend = false;
    this.attendanceForm.reset();
    this.attendanceForm.get('hours').setValidators(null);
    this.attendanceForm.get('commission').setValidators(null);
    this.attendanceForm.get('rate').setValidators(null);
    this.attendanceForm.get('sales').setValidators(null);
    this.attendanceForm.get('no_of_pieces').setValidators(null);
    this.attendanceForm.get('deductions').setValidators(null);

    this.attendanceForm.get('hours').setErrors(null);
    this.attendanceForm.get('commission').setErrors(null);
    this.attendanceForm.get('rate').setErrors(null);
    this.attendanceForm.get('sales').setErrors(null);
    this.attendanceForm.get('no_of_pieces').setErrors(null);
    this.attendanceForm.get('deductions').setErrors(null);
    this.attendanceForm.updateValueAndValidity();
  }
  resetAttendanceFormValidationSingle() {
    this.submitAttend = false;
    this.attendanceSingle.reset();
    this.attendanceSingle.get('hours').setValidators(null);
    this.attendanceSingle.get('commission').setValidators(null);
    this.attendanceSingle.get('rate').setValidators(null);
    this.attendanceSingle.get('sales').setValidators(null);
    this.attendanceSingle.get('no_of_pieces').setValidators(null);
    this.attendanceSingle.get('deductions').setValidators(null);

    this.attendanceSingle.get('hours').setErrors(null);
    this.attendanceSingle.get('commission').setErrors(null);
    this.attendanceSingle.get('rate').setErrors(null);
    this.attendanceSingle.get('sales').setErrors(null);
    this.attendanceSingle.get('no_of_pieces').setErrors(null);
    this.attendanceSingle.get('deductions').setErrors(null);
    this.attendanceSingle.updateValueAndValidity();
  }
  requiredFixAttend(form) {
    this[form].get('rate').setValidators([Validators.required]);
    this[form].get('hours').setValidators([Validators.required]);
    this[form].get('rate').setErrors({ 'required': true });
    this[form].get('hours').setErrors({ 'required': true });
    this[form].updateValueAndValidity();
  }
  requiredContractAttend(form) {
    this[form].get('rate').setValidators([Validators.required]);
    this[form].get('hours').setValidators([Validators.required]);
    this[form].get('rate').setErrors({ 'required': true });
    this[form].get('hours').setErrors({ 'required': true });
    this[form].updateValueAndValidity();
  }
  requiredPieceAttend(form) {
    this[form].get('rate').setValidators([Validators.required]);
    this[form].get('no_of_pieces').setValidators([Validators.required]);
    this[form].get('rate').setErrors({ 'required': true });
    this[form].get('no_of_pieces').setErrors({ 'required': true });
    this[form].updateValueAndValidity();
  }
  requiredCommissionAttend(form) {
    this[form].get('commission').setValidators([Validators.required]);
    this[form].get('sales').setValidators([Validators.required]);
    this[form].get('commission').setErrors({ 'required': true });
    this[form].get('sales').setErrors({ 'required': true });
    this[form].updateValueAndValidity();
  }
  patchValueAttendance() {
    this.resetAttendanceFormValidation();
    this.initializeAttendanceForm();
    let type = this.selectedEmp.salary_info?.salary_type?.id;
    this.attendanceForm.get('is_present').setValue(true);
    if (type?.toString() == '1') {
      // this.requiredFixAttend();
      this.attendanceForm.get('extra_hours').setValue(this.selectedEmp?.perday_calculation?.fix_extra_hours?.extra_hours);
      this.attendanceForm.get('overtime_rate').setValue(this.selectedEmp?.perday_calculation?.fix_extra_hours?.overtime_rate);
      this.attendanceForm.get('fix_hours').setValue(this.selectedEmp?.perday_calculation?.fix_extra_hours?.fix_hours>0?this.selectedEmp?.perday_calculation?.fix_extra_hours?.fix_hours: this.selectedEmp?.salary_info?.fixsalary?.required_hours);
    } else if (type?.toString() == '2') {
      this.requiredContractAttend('attendanceForm');
      if (this.selectedEmp.salary_info?.contractsalary?.rate_unit?.id?.toString() === '2') {
        this.attendanceForm.get('hours').setValue(1);
      } else {
        this.attendanceForm.get('hours').setValue(this.selectedEmp?.perday_calculation?.contracts_salary_invoice?.hours);
      }
      this.attendanceForm.get('rate').setValue(this.selectedEmp?.perday_calculation?.contracts_salary_invoice?.rate>0?this.selectedEmp?.perday_calculation?.contracts_salary_invoice?.rate: this.selectedEmp?.salary_info?.contractsalary?.rate);
    } else if (type?.toString() == '3') {
      this.requiredPieceAttend('attendanceForm');
      this.attendanceForm.get('rate').setValue(this.selectedEmp?.perday_calculation?.piece_salary_invoice?.rate>0?this.selectedEmp?.perday_calculation?.piece_salary_invoice?.rate: this.selectedEmp?.salary_info?.piece_salary?.rate);
      this.attendanceForm.get('no_of_pieces').setValue(this.selectedEmp?.perday_calculation?.piece_salary_invoice?.no_of_pieces);
    } else if (type?.toString() == '4') {
      this.requiredCommissionAttend('attendanceForm');
      this.attendanceForm.get('sales').setValue(this.selectedEmp?.perday_calculation?.commission_salary_invoice?.sales);
      this.attendanceForm.get('commission').setValue(this.selectedEmp?.perday_calculation?.commission_salary_invoice?.commission>0?this.selectedEmp?.perday_calculation?.commission_salary_invoice?.commission: this.selectedEmp?.salary_info?.commission_salary?.commision);
    }
  }
  patchValueAttendanceSingle(item) {
    this.resetAttendanceFormValidationSingle();
    this.initializeAttendanceFormSingle();
    let type = this.empAttDetail?.employee.salary_info?.salary_type?.id;
    this.attendanceSingle.get('is_present').setValue(true);
    if (type?.toString() == '1') {
      // this.requiredFixAttend();
      this.attendanceSingle.get('extra_hours').setValue(item?.fix_extra_hours?.extra_hours);
      this.attendanceSingle.get('overtime_rate').setValue(item?.fix_extra_hours?.overtime_rate);
      this.attendanceSingle.get('fix_hours').setValue(item?.fix_extra_hours?.fix_hours>0?item?.fix_extra_hours?.fix_hours: this.empAttDetail?.employee.salary_info?.fixsalary?.required_hours);
    } else if (type?.toString() == '2') {
      this.requiredContractAttend('attendanceSingle');
      if (this.empAttDetail?.employee.salary_info?.contractsalary?.rate_unit?.id?.toString() === '2') {
        this.attendanceSingle.get('hours').setValue(1);
      } else {
        this.attendanceSingle.get('hours').setValue(item?.contracts_salary_invoice?.hours);
      }
      this.attendanceSingle.get('rate').setValue(item?.contracts_salary_invoice?.rate>0?item?.contracts_salary_invoice?.rate: this.empAttDetail?.employee.salary_info?.contractsalary?.rate);
    } else if (type?.toString() == '3') {
      this.requiredPieceAttend('attendanceSingle');
      this.attendanceSingle.get('rate').setValue(item?.piece_salary_invoice?.rate>0?item?.piece_salary_invoice?.rate: this.empAttDetail?.employee.salary_info?.piece_salary?.rate);
      this.attendanceSingle.get('no_of_pieces').setValue(item?.piece_salary_invoice?.no_of_pieces);
    } else if (type?.toString() == '4') {
      this.requiredCommissionAttend('attendanceSingle');
      this.attendanceSingle.get('sales').setValue(item?.commission_salary_invoice?.sales);
      this.attendanceSingle.get('commission').setValue(item?.commission_salary_invoice?.commission>0?item?.commission_salary_invoice?.commission: this.empAttDetail?.employee.salary_info?.commission_salary?.commision);
    }
    
  }
  patchValueAbsentSingle(item) {
    this.resetAttendanceFormValidationSingle();
    this.initializeAttendanceFormSingle();
    let type = this.empAttDetail?.employee.salary_info?.salary_type?.id;
    { {  } }
    this.attendanceSingle.get('is_absent').setValue(true);
    if (type?.toString() == '1') {
      this.attendanceSingle.get('is_sick_holiday').setValue(item?.is_sick_holiday);
      this.attendanceSingle.get('is_approved_holiday').setValue(item?.is_approved_holiday);
      this.attendanceSingle.get('deductions').setValue(item?.deductions);
      this.attendanceSingle.get('is_deduction').setValue(item?.deductions > 0 ? true : false);
      // this.requiredFixAttend();
      // this.attendanceForm.get('rate').setValue(this.selectedEmp?.salary_info?.fixsalary?.required_hours);
      // this.attendanceForm.get('hours').setValue(this.selectedEmp?.salary_info?.fixsalary?.required_hours);
    } else if (type?.toString() == '2') {
      this.attendanceSingle.get('is_deduction').setValue(item?.deductions > 0 ? true : false);
      this.attendanceSingle.get('deductions').setValue(item?.deductions);
      // this.requiredContractAttend();
      // this.attendanceForm.get('rate').setValue(this.selectedEmp?.salary_info?.contractsalary?.rate);
    } else if (type?.toString() == '3') {
      this.attendanceSingle.get('is_deduction').setValue(item?.deductions > 0 ? true : false);
      this.attendanceSingle.get('deductions').setValue(item?.deductions);
      // this.requiredPieceAttend();
      // this.attendanceForm.get('rate').setValue(this.selectedEmp?.salary_info?.piece_salary?.rate);
    } else if (type?.toString() == '4') {
      this.attendanceSingle.get('is_deduction').setValue(item?.deductions > 0 ? true : false);
      this.attendanceSingle.get('deductions').setValue(item?.deductions);
      // this.requiredCommissionAttend();
      // this.attendanceForm.get('commission').setValue(this.selectedEmp?.salary_info?.commission_salary?.commision);
    }
  }
  patchValueAbsent() {
    this.resetAttendanceFormValidation();
    this.initializeAttendanceForm();
    let type = this.selectedEmp.salary_info?.salary_type?.id;
    this.attendanceForm.get('is_absent').setValue(true);
    if (type?.toString() == '1') {
      this.attendanceForm.get('is_sick_holiday').setValue(this.selectedEmp?.perday_calculation?.is_sick_holiday);
      this.attendanceForm.get('is_approved_holiday').setValue(this.selectedEmp?.perday_calculation?.is_approved_holiday);
      this.attendanceForm.get('deductions').setValue(this.selectedEmp?.perday_calculation?.deductions);
      this.attendanceForm.get('is_deduction').setValue(this.selectedEmp?.perday_calculation?.deductions > 0 ? true : false);
      // this.requiredFixAttend();
      // this.attendanceForm.get('rate').setValue(this.selectedEmp?.salary_info?.fixsalary?.required_hours);
      // this.attendanceForm.get('hours').setValue(this.selectedEmp?.salary_info?.fixsalary?.required_hours);
    } else if (type?.toString() == '2') {
      this.attendanceForm.get('is_deduction').setValue(this.selectedEmp?.perday_calculation?.deductions > 0 ? true : false);
      this.attendanceForm.get('deductions').setValue(this.selectedEmp?.perday_calculation?.deductions);
      // this.requiredContractAttend();
      // this.attendanceForm.get('rate').setValue(this.selectedEmp?.salary_info?.contractsalary?.rate);
    } else if (type?.toString() == '3') {
      this.attendanceForm.get('is_deduction').setValue(this.selectedEmp?.perday_calculation?.deductions > 0 ? true : false);
      this.attendanceForm.get('deductions').setValue(this.selectedEmp?.perday_calculation?.deductions);
      // this.requiredPieceAttend();
      // this.attendanceForm.get('rate').setValue(this.selectedEmp?.salary_info?.piece_salary?.rate);
    } else if (type?.toString() == '4') {
      this.attendanceForm.get('is_deduction').setValue(this.selectedEmp?.perday_calculation?.deductions > 0 ? true : false);
      this.attendanceForm.get('deductions').setValue(this.selectedEmp?.perday_calculation?.deductions);
      // this.requiredCommissionAttend();
      // this.attendanceForm.get('commission').setValue(this.selectedEmp?.salary_info?.commission_salary?.commision);
    }
  }
  toggleAttendance(type) {
    if (type == 'present') {
      this.patchValueAttendance();
    } else {
      this.patchValueAbsent();
    }
  }
  toggleAttendanceSingle(type) {
    if (type == 'present') {
      this.patchValueAttendanceSingle(this.selectEmpDetail?.attendance);
    } else {
      this.patchValueAbsentSingle(this.selectEmpDetail?.attendance);
    }
  }
  toggleLeaveType(event) {
    this.attendanceForm.get('is_approved_holiday').setValue(false);
    this.attendanceForm.get('is_sick_holiday').setValue(false);
    this.attendanceForm.get('deductions').setValue(0);
    this.attendanceForm.get('is_deduction').setValue(false);
    if (event.target.value == 'paidLeave') {
      this.attendanceForm.get('is_approved_holiday').setValue(true);
    } else if (event.target.value == 'sickLeave') {
      this.attendanceForm.get('is_sick_holiday').setValue(true);
    } else {
      this.attendanceForm.get('is_deduction').setValue(true);
      this.attendanceForm.get('deductions').setValue(0);
    }
  }
  selectEmployeeFile(event) {
    this.fileData = event.target.files;
    if (this.fileData.length>5 || this.previewUrl.length+this.fileData.length > 5) {
      this.toast.warning('You can upload upto 5 files');
      return;
    }
    this.uploadFile = true;
    const formData = new FormData();
    for (let item of this.fileData) {
      formData.append('fileToUpload', item);
    }
    this.http.addPurchaseFiles(formData).subscribe(res => {
      this.toast.success(res['detail']);
      for (let item of res['data']) {
        this.previewUrl.push(item);
      }
      let array = [];
      for (let item of this.previewUrl) {
        array.push(item.id)
      }
      this.addEmployeeForm.patchValue({
        file: array
      });
      this.uploadFile = false;
      //  console.log('this is the form',  this.addEmployeeForm.value);
      
    }, (error) => {
      this.previewUrl = [];
      this.uploadFile = false;
      this.toast.error(error.error['msg']);
    })
  }
  deleteEmployeeFile(id) {
    this.previewUrl = this.previewUrl.filter(x => x.id !== id);
    let array = [];
    for (let item of this.previewUrl) {
      array.push(item.id)
    }
    this.addEmployeeForm.patchValue({
      file: array
    });
  }
  toggleLeaveTypeSingle(event) {
    this.attendanceSingle.get('is_approved_holiday').setValue(false);
    this.attendanceSingle.get('is_sick_holiday').setValue(false);
    this.attendanceSingle.get('deductions').setValue(0);
    this.attendanceSingle.get('is_deduction').setValue(false);
    if (event.target.value == 'paidLeave') {
      this.attendanceSingle.get('is_approved_holiday').setValue(true);
    } else if (event.target.value == 'sickLeave') {
      this.attendanceSingle.get('is_sick_holiday').setValue(true);
    } else {
      this.attendanceSingle.get('is_deduction').setValue(true);
      this.attendanceSingle.get('deductions').setValue(0);
    }
  }
  resetDeduction() {
    this.attendanceForm.get('deductions').setValue(0);
  }
  submitAttendance() {
    this.submitAttend = true;
    this.attendLoader=true
    this.attendEmpLoader = true;
    if (this.attendanceForm.valid) {
      this.loadSubmit = true;
      let date = this.formatDate(this.dateFilter);
      this.http.markPresentAbsent(this.selectedEmp?.id, date, this.attendanceForm.value).subscribe(res => {
        this.addExtraHours();
        this.loadSubmit = false;
        this.attendLoader=false
        this.attendEmpLoader = false;
        this.submitAttend = false;
        this.toast.success(res['msg']);
        this.modalService.dismissAll();
        this.attendanceForm.reset();
        this.attendanceData = res['data'];
        this.getEmployeeAttendanceList();
        for(let item of this.allAttendanceList){
          // let result = item.filter(o1 => this.attendanceData.some(o2 => o1.id === o2.id));
          if(item.id === this.attendanceData.id){
            let index = this.allAttendanceList.findIndex(x => x.id === item.id)
            //  console.log("Attendence Obj", index ,this.attendanceData ,this.allAttendanceList);
            this.allAttendanceList.splice(index, 1, this.attendanceData);
          }
        }
        
        
        
        
        // this.getEmployeeAttendanceList();
      }, (error) => {
        this.loadSubmit = false;
        this.attendEmpLoader = false;
        this.submitAttend = false;
        this.attendLoader=false
        this.toast.error(error.error['msg']);
      }
      );
    }
  }
  submitAttendanceSingle() {
    this.submitAttend = true;
    this.attendEmpLoader = true;
    if (this.attendanceSingle.valid) {
      this.loadSubmit = true;
      let date = this.formatDate(this.dateFilter);
      this.http.markPresentAbsent(this.selectEmpDetail?.id, this.selectEmpDetail?.attendance?.date?.attendanec_date, this.attendanceSingle.value).subscribe(res => {
        this.addExtraHoursSingle();
        this.submitAttend = false;
        this.loadSubmit = false;
        this.toast.success(res['msg']);
        this.attendEmpLoader = false;
        this.modalService.dismissAll();
        this.attendanceSingle.reset();
        this.getAttendanceDetail(this.selectEmpDetail?.id);
        this.getEmployeeAttendanceList();
      }, (error) => {
        this.submitAttend = false;
        this.loadSubmit = false;
        this.attendEmpLoader = false;
        this.toast.error(error.error['msg']);
      }
      );
    }
  }
  EmployeeLedgerReset(){
    this.employeeledgerList.patchValue({
      emp_detail_id: this.selectedEmployee.id,
      search_query: '',
      transaction_type: '',
      start_date: '',
      end_date: '',
    });
    this.getEmployeeLedgerList(this.selectedEmployee.id);
  }
  yearsArray(){
    for(let x=1900; x<=3000; x++){
      this.allyears.push(x)
    }
    // //  console.log("Years Array", this.allyears);
    
  }
  AddPaySliptoLedger(slipId) {
    // this.empLoader = true;
    // this.http.addSlipToLedger(slipId, this.deductions).subscribe(res => {
    //   this.empLoader = false;
    //   this.toast.success(res['msg'])
    //   this.deductions = []
    //   this.getSlipDetail(this.EmployeePaySlip.emp_details.id, slipId)
    //   this.PaySlipLedger(this.selectedEmployee.id)
    //   const slip= document.getElementById("nav-PaySlip-tab");
    //   slip.click();
    // }, (error) => {
    //   this.empLoader = false;
    //   this.toast.error(error.error['msg']);
    // }
    // );
  }
  editDedutction(id, slipId){
    this.empLoader = true;
    this.http.editeDeductions(id, this.editadditionchargesForm.value).subscribe(res => {
      this.empLoader = false;
      this.toast.success(res['msg'])
      this.deductions = []
      this.getSlipDetail(this.EmployeePaySlip.emp_details.id, slipId)
      this.PaySlipLedger(this.selectedEmployee.id)
      // this.moveToledger(slipId)
      // this.modalService.dismissAll()
    }, (error) => {
      this.empLoader = false;
      this.toast.error(error.error['msg']);
    }
    );
  }
  DeleteDedcution(id, slipId){
    this.http.deletededution(id).subscribe(res => {
      this.empLoader = false;
      this.toast.success(res['msg'])
      this.deductions = []
      this.AddPaySliptoLedger(slipId)
      this.PaySlipLedger(this.selectedEmployee.id)
      this.getSlipDetail(this.EmployeePaySlip.emp_details.id, slipId)
      // this.moveToledger(slipId)
      // this.modalService.dismissAll()
    }, (error) => {
      this.empLoader = false;
      this.toast.error(error.error['msg']);
    }
    );
  }
  addExtraHours() {
    if (this.attendanceForm.value?.fix_hours && this.attendanceForm.value?.extra_hours && this.attendanceForm.value?.overtime_rate && this.selectedEmp.salary_info?.salary_type?.id?.toString() === '1') {
      let date = this.formatDate(this.dateFilter);
      this.http.addExtraHours(this.selectedEmp?.id, date, {
        'extra_hours': this.attendanceForm.value?.extra_hours,
        'overtime_rate': this.attendanceForm.value?.overtime_rate,
        'fix_hours': this.attendanceForm.value?.fix_hours
      }).subscribe(res => {
      }, (error) => {
        this.submitAttend = false;
        this.toast.error(error.error['msg']);
      }
      );
    }
  }
  addExtraHoursSingle() {
    if (this.attendanceSingle.value?.fix_hours && this.attendanceSingle.value?.extra_hours && this.attendanceSingle.value?.overtime_rate && this.selectEmpDetail.salary_info?.salary_type?.id?.toString() === '1') {
      this.http.addExtraHours(this.selectEmpDetail?.id, this.selectEmpDetail?.attendance?.date?.attendanec_date, {
        'extra_hours': this.attendanceSingle.value?.extra_hours,
        'overtime_rate': this.attendanceSingle.value?.overtime_rate,
        'fix_hours': this.attendanceSingle.value?.fix_hours
      }).subscribe(res => {
      }, (error) => {
        this.submitAttend = false;
        this.toast.error(error.error['msg']);
      }
      );
    }
  }
  submitAbsent() {
    this.submitAttend = true;
    if (this.attendanceForm.valid) {
      let date = this.formatDate(this.dateFilter);
      this.http.markPresentAbsent(this.selectedEmp?.id, date, this.attendanceForm.value).subscribe(res => {
        this.submitAttend = false;
        this.toast.success(res['msg']);
        this.modalService.dismissAll();
        this.attendanceForm.reset();
        this.getEmployeeAttendanceList();
        
      }, (error) => {
        this.submitAttend = false;
        this.toast.error(error.error['msg']);
      }
      );
    }
  }
  resetEmplyeeListPage() {
    this.employeeFilters.page = 1;
    this.allempoyee = [];
  }
  postEmployeeInformation() {
    this.step2Submit = true;
    if (this.addEmployeeForm.valid) {
      this.empLoader = true;
      let payload =this.addEmployeeForm.value;
      if (!this.editProfile) {
        if(this.addEmployeeForm.value.last_name === '' || this.addEmployeeForm.value.last_name === null){
          this.addEmployeeForm.patchValue({
            last_name: ''
          })
          payload = { ...payload, last_name: ''};
        }
        this.http.addEmployee(payload).subscribe((res: any) => {
          this.empLoader = false;
          if (res?.plan_expired || res?.limit_exceeded) {
            this.sendClickEvent();
            // this.toast.error('Plan expired please upgrade your plan');
            return;
          }
          
          this.toast.success(res['detail']);
          this.modalService.dismissAll();
          this.resetSalaryFormValidation();
          this.addEmployeeForm.reset();
          this.employeeFilters.page = 1
          this.attendanceFilters.page = 1
          this.getEmployeeList();
          this.getEmployeeAttendanceList();
        }, (error) => {
          this.empLoader = false;
          this.toast.error(error.error['msg']);
        });
      } else {
        this.http.editEmployee(payload).subscribe(res => {
          this.empLoader = false;
          this.toast.success(res['detail']);
          this.modalService.dismissAll();
          this.selectEmpDetail = res['data']
          this.resetSalaryFormValidation();
          this.addEmployeeForm.reset();
          this.getAttendanceDetail(payload?.id)
          this.resetEmplyeeListPage();
          this.getEmployeeList();
        }, (error) => {
          this.empLoader = false;
          this.toast.error(error.error['msg']);
        });
      }
      
    }
  }
  sendClickEvent() {
    this.subject1.next();
  }
  postAdonesSingle(id, modal) {
    if (this.additionchargesForm?.value?.id) {
      this.http.editAdones(this.additionchargesForm?.value, this.additionchargesForm?.value?.id).subscribe(res => {
        modal.dismiss();
        this.toast.success(res['msg']);
        this.getListOfAdones(this.additionchargesForm?.value?.type);
      }, (error) => {
  
      })
    } else {
      this.http.postAdones([this.additionchargesForm?.value], id).subscribe(res => {
        this.getSlipDetail(this.EmployeePaySlip.emp_details.id, this.selectedSlipId);
        this.getListOfAdones(this.additionchargesForm?.value?.type);
        this.toast.success(res['msg']);
        modal.dismiss();
      }, (error) => {
  
      })
    }
  }
  postAddOnes(body, id, modal) {
    this.http.postAdones(body, id).subscribe(res => {
      modal.dismiss();
      this.toast.success(res['msg']);
      this.getSlipDetail(this.EmployeePaySlip.emp_details.id, this.selectedSlipId);
    }, (error) => {

    })
  }
  getSlipDetail(empId, slipId) {
    this.salaryLoader = true;
    this.http.getpaySlipDetail(empId, slipId).subscribe(res => {
      this.salaryLoader = false;
      this.SlipDetails = res;
      this.deductions = res['salary'].salary_addition
      //  console.log("array salary is", this.deductions);

    }, (error) => {
      this.salaryLoader = false;
      this.toast.error(error.error['msg']);
    }
    );
  }

  searchLedgerList(event) {
    this.allempoyee = [];
    this.empLoader = true;
    this.employeeFilters.query = event.target.value;
    this.getEmployeeList();
  }
  applyfilterEmployee() {
    
    this.allempoyee = [];
    this.modalService.dismissAll();
    this.getEmployeeList();
  }
  ResetfilterEmployee() {
    this.employeeFilters = {
      startDate: '',
      endDate: '',
      active: '',
      query: '',
      salary: 'all',
      page: 1
    }
    this.allempoyee = [];
    this.modalService.dismissAll();
    this.getEmployeeList();
  }
  applyfilterAttendance() {
    this.allAttendanceList = [];
    this.modalService.dismissAll();
    this.attendanceFilters.page = 1
    this.getEmployeeAttendanceList();
  }
  filterActiveInActive(filter) {
    this.allempoyee = this.employeesData.data.filter((x) => x.is_active == filter);
  }
  getEmployeeList() {
    this.empLoader = true;
    let url = `manage/employee/?page=${this.employeeFilters.page}&salary=${this.employeeFilters.salary}&active=${this.employeeFilters.active}&name=${this.employeeFilters.query}&start_date=${this.employeeFilters.startDate}&end_date=${this.employeeFilters.endDate}`;
    this.http.getEmployee(url).subscribe(res => {
      this.empLoader = false;
      this.allempoyee = this.allempoyee.concat(res['data']);
      this.allempoyee = this.removeDuplicate(this.allempoyee);
      this.employeesData = res;
      //  console.log("Data all Employees", this.allempoyee);
      
    }, (error) => {
      this.empLoader = false;
      this.toast.error(error.error['msg']);
    }
    );
  }
  removeDuplicate(array) {
    const unique = [];
    array.map(x => unique.filter(a => a.id == x.id).length > 0 ? null : unique.push(x));
    return unique;
  }
  getPreviousDate() {
    var d = new Date(this.dateFilter)
    this.dateFilter = d.setDate(d.getDate() - 1);
    this.attendLoader = true;
    let url = `manage/employee_attandance_list/${this.formatDate(this.dateFilter)}/?page=${this.attendanceFilters.page}&name=${this.attendanceFilters.query}`;
    this.http.getEmpAttendanceList(url).subscribe(res => {
      this.employeeAttendance = res;
      this.allAttendanceList = res['data'];
      this.attendLoader = false;
    }, (error) => {
      this.attendLoader = false;
      this.toast.error(error.error['msg']);
    }
    );
  }
  getNextDate() {
    var today = new Date();
    let checkDate = new Date(new Date(this.dateFilter).setDate(new Date(this.dateFilter).getDate() + 1))
    if (checkDate < today) {
      var d = new Date(this.dateFilter);
      this.dateFilter = d.setDate(d.getDate() + 1);
      this.attendLoader = true;
      let url = `manage/employee_attandance_list/${this.formatDate(this.dateFilter)}/?page=${this.attendanceFilters.page}&name=${this.attendanceFilters.query}`;
      this.http.getEmpAttendanceList(url).subscribe(res => {
        this.attendLoader = false;
        this.allAttendanceList = res['data'];
        this.employeeAttendance = res;
      }, (error) => {
        this.attendLoader = false;
        this.toast.error(error.error['msg']);
      }
      );
    }
  }
  getPreviousDateSingle() {
    var d = new Date(this.dateFilterSingle);
    this.dateFilterSingle = this.formatDate(new Date(d.setMonth(d.getMonth() - 1)));
    this.longMonth = d.toLocaleString('en-us', { month: 'long' })
    this.longYear = new Date().getFullYear()
    // //  console.log("DateMont", longMonth, new Date().getFullYear());
    
    this.attendEmpLoader = true;
    this.http.getEmpAttendanceDetails(this.selectEmpDetail?.id, this.dateFilterSingle).subscribe(res => {
      this.empAttDetail = res['data'];
      this.attendEmpLoader = false;
    }, (error) => {
      this.attendEmpLoader = false;
      this.toast.error(error.error['msg']);
    }
    );
  }
  DownloadByMonth(value, type){
    this.empLoader = true
    if(type === 'pdf'){
      if(value === true){
        this.http
        .attendance_sheet_pdfByDatePDF(this.MonthSelect.value.month, this.MonthSelect.value.year)
        .subscribe((data: ArrayBuffer) => {
          let blob = new Blob([data], { type: 'application/pdf' });
          var downloadURL = URL.createObjectURL(blob);
          var link = document.createElement('a');
          link.href = downloadURL;
          let item 
          this.translate.get('Attendance').subscribe((data: any) => {
            item =  data;
          });
          link.download = item + '_' + this.auth.userData.emp_info.Business.business_name ;
          link.click();
          this.empLoader = false
          this.modalService.dismissAll()
        },
        (error) => {
          let toast;
          this.translate.get('No Data found').subscribe((data: any) => {
              toast =  data;
            });
          this.toast.warning(toast)
          this.empLoader = false

        });
      }
      else{
        this.http
      .attendance_sheet_pdfByEmployeePDF(this.MonthSelect.value.month, this.MonthSelect.value.year, this.MonthSelect.value.emp_detail_id)
      .subscribe((data: ArrayBuffer) => {
        let blob = new Blob([data], { type: 'application/pdf' });
        var downloadURL = URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = downloadURL;
        let item 
        this.translate.get('Attendance').subscribe((data: any) => {
          item =  data;
        });
        link.download = item + '_' + this.auth.userData.emp_info.Business.business_name ;
        link.click();
        this.empLoader = false
        this.modalService.dismissAll()
      },
      (error) => {
        let toast;
        this.translate.get('No Data found').subscribe((data: any) => {
            toast =  data;
          });
        this.toast.warning(toast)
        this.empLoader = false
      });
      }
    }
    else{
      if(value === true){
        this.http
        .attendance_sheet_excelByDate(this.MonthSelect.value.month, this.MonthSelect.value.year)
        .subscribe((data: ArrayBuffer) => {
          let blob = new Blob([data], { type: 'application/xlsx' });
          var downloadURL = URL.createObjectURL(blob);
          var link = document.createElement('a');
          link.href = downloadURL;
          let item 
          this.translate.get('Attendance').subscribe((data: any) => {
            item =  data;
          });
          link.download = item + '_' + this.auth.userData.emp_info.Business.business_name + '.xlsx';
          link.click();
          this.empLoader = false
          this.modalService.dismissAll()
        },
        (error) => {
          this.toast.error(error.error['msg']);
          this.empLoader = false
        });
      }
      else{
        this.http
      .attendance_sheet_excelByEmployee(this.MonthSelect.value.month, this.MonthSelect.value.year, this.MonthSelect.value.emp_detail_id)
      .subscribe((data: ArrayBuffer) => {
        let blob = new Blob([data], { type: 'application/xlsx' });
        var downloadURL = URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = downloadURL;
        let item 
        this.translate.get('Attendance').subscribe((data: any) => {
          item =  data;
        });
        link.download = item + '_' + this.auth.userData.emp_info.Business.business_name + '.xlsx';
        link.click();
        this.empLoader = false
        this.modalService.dismissAll()
      },
      (error) => {
        this.toast.error(error.error['msg']);
        this.empLoader = false
      });
      }
    }

    
  }
  getNextDateSingle() {
    var today = new Date();
    let checkDate = new Date(new Date(this.dateFilterSingle).setMonth(new Date(this.dateFilterSingle).getMonth() + 1))
    if (checkDate <= today) {
      var d = new Date(this.dateFilterSingle);
      this.dateFilterSingle = this.formatDate(new Date(d.setMonth(d.getMonth() + 1)));
      this.longMonth = d.toLocaleString('en-us', { month: 'long' })
      this.longYear = new Date().getFullYear()
      this.attendEmpLoader = true;
      this.http.getEmpAttendanceDetails(this.selectEmpDetail?.id, this.dateFilterSingle).subscribe(res => {
        this.empAttDetail = res['data'];
        this.attendEmpLoader = false;
      }, (error) => {
        this.attendEmpLoader = false;
        this.toast.error(error.error['msg']);
      }
      );
    }
  }
  formatDate(date) {
    if (date) {
      var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;
      return [year, month, day].join('-');
    } else {
      return ''
    }
  }
  EditEmployeeLeger(){
    this.empLoader = true;
    this.patchAudioFile('addEmpTransection').then((res: any) => {
      this.http.editEmployeeLedgerTran(this.addEmpTransection.value).subscribe(res => {
        this.empLoader = false;
        this.addTransectionData = res['data']
        this.toast.success(res['details']);
        this.modalService.dismissAll()
        // if(this.listCall != false){
          this.getEmployeeLedgerList(this.employeeledgerList.value.emp_detail_id)
        // }
      }, (error) => {
        this.empLoader = false;
        this.toast.error(error.error['msg']);
      }
      );
    })

  }

   // global function for patch audio message in all modules//
   patchAudioFile(form: any) {
    return new Promise((resolve, reject) => {
      if ( this.audioService.recordedAudio) {
        const formData = new FormData();
        formData.append('fileToUpload', this.audioService.recordedAudio);
        this.http.addCashFiles(formData).subscribe(res => {
          let array = [];
          for (let item of this[form].value.files) {
            array.push(item);
          }
          array.push(res.file_ids[0]);
          this[form].patchValue({
            files: array
          });
          resolve(true);
        }, (error) => {
          reject(false);
        })
       } else {
        resolve(true);
       }
    })
  }
  //Ends here//

  addEmployeeTran(modal: any = '', type) {
    this.empLoader = true;


    this.patchAudioFile('addEmpTransection').then((res: any) => {
    this.http.addEmployeeLedgerTran(this.addEmpTransection.value).subscribe(res => {
      this.empLoader = false;
      
      this.addTransectionData = res['data']
      this.toast.success(res['detail']);
      this.modalService.dismissAll()
      this.audioService.resetRecordedAudio();
      if(this.listCall != false && this.transType != 'attendeance'){
        this.getEmployeeLedgerList(this.employeeledgerList.value.emp_detail_id)
      }
      if (type === false && this.addEmpTransection.value.transaction_type.toString() === '2') {
        this.sahreModal(modal)
      }

    }, (error) => {
      this.empLoader = false;
      this.toast.error(error.error['msg']);
    }
    )}
    
    )}


  sahreModal(modal) {

    try {
      setTimeout(() => {
        this.modalService.open(modal, {
          centered: true,
          windowClass: 'ledger-debit',
          size: 'lg',
        });
      }, 3000)
    } catch (e) { }
    // this.shareafter= !this.shareafter 
  }
  moveToledger(id, value) {
    this.moveLoader = true
    this.http.movetoledger(id).subscribe(res => {
      this.toast.success(res['detail']);
      this.moveLoader = false
      // this.modalService.dismissAll()
      
      if(value === false){
        // this.getSlipDetail(this.selectedEmployee.id, this.Slip.id)
        this.PaySlipLedger(this.selectedEmployee.id)
        const slip= document.getElementById("nav-EmployeeLedger-tab");
      slip.click();
      this.modalService.dismissAll()
      }
      if(value === true){
        this.PaySlipLedger(this.selectedEmployee.id)
        this.getSlipDetail(this.selectedEmployee.id, this.Slip.id)
      }
    }, (error) => {
      this.empLoader = false;
      this.moveLoader = false
      this.toast.error(error.error['msg']);
    }
    );
  }
  downloadAcitve(){
    this.activeloader=true
    this.http
    .downloadActiveInactivePDF(
    )
    .subscribe((data: ArrayBuffer) => {
      let blob = new Blob([data], { type: 'application/pdf' });
      var downloadURL = URL.createObjectURL(blob);
      var link = document.createElement('a');
      link.href = downloadURL;
      let item 
      this.translate.get('Active').subscribe((data: any) => {
        item =  data;
      });
      link.download = item + '_' + this.auth.userData.emp_info.Business.business_name;
      link.click();
      this.activeloader=false
      this.modalService.dismissAll()
    },
    (error) => {
      let toast;
      this.translate.get('No Employee found!').subscribe((data: any) => {
          toast =  data;
        });
      this.toast.warning(toast)
      this.activeloader=false
    });
  }
  downloadAcitveExcel(){
    this.activeloader=true
    this.http
    .downloadActiveInactiveEcel(
    )
    .subscribe((data: ArrayBuffer) => {
      let blob = new Blob([data], { type: 'application/pdf' });
      var downloadURL = URL.createObjectURL(blob);
      var link = document.createElement('a');
      link.href = downloadURL;
      this.activeloader=false
      let item 
      this.translate.get('Active').subscribe((data: any) => {
        item =  data;
      });
      link.download = item + '_' + this.auth.userData.emp_info.Business.business_name + '.xlsx';

      link.click();
      this.modalService.dismissAll()
    },
    (error) => {
      let toast;
            this.translate.get('No Employee found!').subscribe((data: any) => {
                toast =  data;
              });
            this.toast.warning(toast)
      this.activeloader=false
    });
  }
  activeFilter(filter) {
    this.employeeFilters.active = filter;
    this.employeeFilters.page = 1;
    this.empLoader = true;
    this.allempoyee = [];
    this.getEmployeeList();
  }
  salaryTypeFilter(filter) {
    if (!this.empLoader) {
      this.empLoader = true;
      this.employeeFilters.salary = filter;
      this.employeeFilters.page = 1;
      this.empLoader = true;
      this.allempoyee = [];
      this.getEmployeeList();
    }
  }
  timeFilter(type) {
    this.employeeFilter = type;
    this.employeeFilters.startDate = this.filterByTime(type).startDate;
    this.employeeFilters.endDate = this.filterByTime(type).endDate;
    this.employeeFilters.page = 1;
    this.empLoader = true;
    this.allempoyee = [];
   this.getEmployeeList();
  }
  filterByTime(filter: any = '') {
    var first: any;
    var last: any;
    if (filter == 'today') {
      first = this.formatDate(new Date());
      last = this.formatDate(new Date());
    } else if (filter == 'week') {
      first = (new Date().getDate() - new Date().getDay()) + 1;
      var last = first + 6;
      first = new Date(new Date().setDate(first)).toUTCString();
      last = new Date(new Date().setDate(last)).toUTCString();
    } else if (filter == 'month') {
      first = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
      last = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
    } else if (filter == 'year') {
      first = new Date(new Date().getFullYear(), 0, 1);
      last = new Date(new Date().getFullYear(), 11, 31);
    } else {
      first = '';
      last = '';
    }
    return {startDate: this.formatDate(first), endDate: this.formatDate(last)}
  }
  PaySlipLedger(id) {
    this.empLoader = true;
    this.http.getEmployeeSaleryDetail(id).subscribe(res => {
      this.empLoader = false;
      this.EmployeePaySlip = res;
      
    }, (error) => {
      this.empLoader = false;
      this.toast.error(error.error['msg']);
    }
    );
  }
  salaryDateFormat(type, date) {
    if (type == 'month') {
      return date.day;
    } else if (type == 'week') {
      const d = new Date(this.dateFormatString(date));
      return d.getDay() - 1;
    } else {
      return 0
    }
  }
  dateFormatString(date) {
    if(date?.month < 10 && date?.day < 10){
      let fromat = date?.year + '-' + 0 + date?.month + '-' + 0 + date?.day;
      return fromat;
    }
    else if(date?.month < 10 && date?.day > 9){
      let fromat = date?.year + '-' + 0  + date?.month + '-' + date?.day;
      return fromat;
    }
    else if(date?.month > 9 && date?.day < 10){
      let fromat = date?.year + '-' + date?.month + '-' + 0  + date?.day;
      return fromat;
    }
    else{
      let fromat = date?.year + '-' + date?.month + '-' + date?.day;
      return fromat;
    }
    
  }
  changeSalaryUnit(event) {
    if (event.target.value == '3') {
      this.addEmployeeForm.get('salary').get('salary_date').setValue(this.salaryDays[0].id);
    } else if (event.target.value == '4') {
      this.addEmployeeForm.get('salary').get('salary_date').setValue(this.todayDate.day);
    } else {
      this.addEmployeeForm.get('salary').get('salary_date').setValue(0);
    }
  }
  showMessageWarning() {
    let toast;
    this.translate.get('Please fill basic information').subscribe((data: any) => {
        toast =  data;
      });
    this.toast.warning(toast);
  }
  patchDateGeneric(event) {
    let value = event.year + '-' + event.month + '-' + event.day;
    this.addEmployeeForm.get('salary').get('salary_date').setValue(value);
  }
  getEmployeeLedgerList(id) {
    this.empLoader=true
    this.employeeledgerList.patchValue({
      emp_detail_id: id
    })
    if(this.employeeledgerList.value.start_date === null){
      this.employeeledgerList.value.start_date = ''
    }
    if(this.employeeledgerList.value.end_date === null){
      this.employeeledgerList.value.end_date = ''
    }
    if(this.employeeledgerList.value.search_query === null){
      this.employeeledgerList.value.search_query = ''
    }
    if(this.employeeledgerList.value.transaction_type === null){
      this.employeeledgerList.value.transaction_type = ''
    }
    this.http.getEmployeeLedger(this.employeeledgerList.value.start_date,this.employeeledgerList.value.end_date,this.employeeledgerList.value.search_query,this.employeeledgerList.value.transaction_type,this.employeeledgerList.value.emp_detail_id, this.empledgercurrentpage).subscribe(res => {
      this.empLoader = false;
      this.employeeLedger = res





      if(this.empledgercurrentpage === 1){
        this.employeeLedgerTransData = res['employee_ledger_transactions_data'];
      }
      if(this.empledgercurrentpage > 1){
        for(let item of res['employee_ledger_transactions_data'])
        this.employeeLedgerTransData.push(item);
      }
      ////  console.log("cashDat",this.purchaseTransData);
      this.empledgercurrentpage = res['page']
      this.empledgertotalpage = res['totalPages']
    }, (error) => {
      this.empLoader = false;
      this.toast.error(error.error['msg']);
    }
    );
  }
  getEmployeeAttendanceList() {
    let date = this.formatDate(this.dateFilter);
    this.attendLoader = true;
    let url = `manage/employee_attandance_list/${date}/?page=${this.attendanceFilters.page}&name=${this.attendanceFilters.query}`;
    this.http.getEmpAttendanceList(url).subscribe(res => {
      this.employeeAttendance = res;
      if (this.attendanceFilters.page>1) {
        for (let item of res['data']) {
          this.allAttendanceList.push(item);
        }
      } else {
        this.allAttendanceList = res['data'];
      }
      // this.allAttendanceList = this.allAttendanceList.concat(res['data']);
      // this.allAttendanceList = this.removeDuplicate(this.allAttendanceList);
      this.attendLoader = false;
    }, (error) => {
      this.attendLoader = false;
      this.toast.error(error.error['msg']);
    }
    );
  }
  markPublicHoliday(response) {
    this.attendLoader = true;
    let date = this.formatDate(this.dateFilter);
    this.http.markPublicHoliday(date, response).subscribe(res => {
      this.toast.success(res['msg']);
      this.attendLoader = false;
      this.getEmployeeAttendanceList();
    }, (error) => {
      this.attendLoader = false;
      this.toast.error(error.error['msg']);
    }
    );
  }
  getAttendanceDetail(id) {
    let date = this.formatDate(this.dateFilterSingle);
    this.attendEmpLoader = true;
    this.http.getEmpAttendanceDetails(id, date).subscribe(res => {
      this.empAttDetail = res['data'];
      this.attendEmpLoader = false;
    }, (error) => {
      this.attendEmpLoader = false;
      this.toast.error(error.error['msg']);
    }
    );
  }

  inactiveEmployeeById() {
    let title, text, confirm, cancel;
    this.translate.get('Terminate Employee').subscribe((data: any) => {
      title =  data;
    }),
    this.translate.get('You will not be able to revert this action').subscribe((data: any) => {
      text =  data;
    }),
    this.translate.get('Yes').subscribe((data: any) => {
      confirm =  data;
    }),
    this.translate.get('Cancel').subscribe((data: any) => {
      cancel =  data;
    }),
    Swal.fire({
      title: title,
      text: text,
      showCancelButton: true,
      confirmButtonColor: '#2f619c',
      cancelButtonColor: '#2f619c',
      cancelButtonText: cancel,
      confirmButtonText: confirm
    }).then((result) => {
      if (result.value) {
        this.empLoader = true;
        if (this.inActiveDate) {
          let date = this.inActiveDate?.year + '-' + this.inActiveDate?.month + '-' + this.inActiveDate?.day;
          this.http.employeeInactive(this.selectEmpDetail?.id, date).subscribe(res => {
            this.toast.success(res['msg']);
            this.viewdetail = false;
            this.allempoyee = [];
            this.getEmployeeList();
            this.modalService.dismissAll();
            this.empLoader = false;
          }, (error) => {
            this.empLoader = false;
            this.toast.error(error.error['msg']);
          }
          );
        }
      }
    });
  }
  resetAllForm() {
    this.step1Submit = false;
    this.step2Submit = false;
    this.addEmployeeForm.reset();
    this.previewUrl = [];
    this.uploadFile = false;
    this.addEmployeeForm.get('file').setValue([]);
    this.resetSalaryFormValidation();
  }
  resetSalaryFormValidation() {
    this.step2Submit = false;
    this.addEmployeeForm.get('salary').reset();
    this.addEmployeeForm.get('salary').get('salary_type').setValidators(null);
    this.addEmployeeForm.get('salary').get('salary_date').setValidators(null);
    this.addEmployeeForm.get('salary').get('salary_unit').setValidators(null);
    this.addEmployeeForm.get('salary').get('base_salary').setValidators(null);
    this.addEmployeeForm.get('salary').get('required_hours').setValidators(null);
    this.addEmployeeForm.get('salary').get('rate').setValidators(null);
    this.addEmployeeForm.get('salary').get('rate_unit').setValidators(null);
    this.addEmployeeForm.get('salary').get('commision').setValidators(null);
    this.addEmployeeForm.get('salary').get('salary_type').setErrors(null);
    this.addEmployeeForm.get('salary').get('salary_date').setErrors(null);
    this.addEmployeeForm.get('salary').get('salary_unit').setErrors(null);
    this.addEmployeeForm.get('salary').get('base_salary').setErrors(null);
    this.addEmployeeForm.get('salary').get('required_hours').setErrors(null);
    this.addEmployeeForm.get('salary').get('rate').setErrors(null);
    this.addEmployeeForm.get('salary').get('rate_unit').setErrors(null);
    this.addEmployeeForm.get('salary').get('commision').setErrors(null);
    this.addEmployeeForm.updateValueAndValidity();
  }
  checkSalaryType(event, type) {
    if (event.target.checked) {
      this.resetSalaryFormValidation();
      this.hideAllTypes();
      this.genericValidation();
      $('#' + event.currentTarget.id).prop("checked", true);
      $('#' + type).show('slow');
      if (type == 'fullTime') {
        this.requiredFullTime();
        this.addEmployeeForm.get('salary').patchValue({
          salary_type: 1,
          salary_unit: this.salaryUnit[0]?.id,
          salary_date: this.todayDate.day
        });
      } else if (type == 'partTime') {
        this.requiredPartTime();
        this.addEmployeeForm.get('salary').patchValue({
          salary_type: 2,
          salary_unit: this.salaryUnit[0]?.id,
          rate_unit: this.rateUnit[0]?.id,
          salary_date: this.todayDate.day
        });
      } else if (type == 'factoryEmployee') {
        this.requiredFactory();
        this.addEmployeeForm.get('salary').patchValue({
          salary_type: 3,
          salary_unit: this.salaryUnit[0]?.id,
          rate_unit: this.rateUnit[0]?.id,
          salary_date: this.todayDate.day
        });
      } else {
        this.requiredStaff();
        this.addEmployeeForm.get('salary').patchValue({
          salary_type: 4,
          salary_unit: this.salaryUnit[0]?.id,
          rate_unit: this.rateUnit[0]?.id,
          salary_date: this.todayDate.day
        });
      }
    } else {
      $('#' + type).hide('slow');
    }
    //  console.log('this is the salary unit value', event);
    //  console.log('this is the form value', this.addEmployeeForm.get('salary').value);
    //  console.log('this is the salary unit',this.addEmployeeForm?.get('salary').get('salary_unit').value);
    
  }
  moveNextTab() {
    this.step1Submit = true;
    if (!this.addEmployeeForm.valid) {
      let toast;
      this.translate.get('Please fill basic information').subscribe((data: any) => {
          toast =  data;
        });
      this.toast.warning(toast);
    } else {
      if (!this?.editProfile) {
        this.hideAllTypes();
        this.addEmployeeForm.get('salary').get('salary_type').setValidators([Validators.required]);
        this.addEmployeeForm.get('salary').get('salary_type').setErrors({ 'required': true });
        this.addEmployeeForm.updateValueAndValidity();
      }
      document.getElementById('nav-profile-tab').click();
    }
  }
  hideAllTypes() {
    $('input:checkbox').prop("checked", false);
    $('#fullTime').hide('slow');
    $('#partTime').hide('slow');
    $('#factoryEmployee').hide('slow');
    $('#salesStaff').hide('slow');
  }
  genericValidation() {
    this.addEmployeeForm.get('salary').get('salary_type').setValidators([Validators.required]);
    this.addEmployeeForm.get('salary').get('salary_type').setErrors({ 'required': true });

    this.addEmployeeForm.get('salary').get('salary_date').setValidators([Validators.required]);
    this.addEmployeeForm.get('salary').get('salary_date').setErrors({ 'required': true });

    this.addEmployeeForm.get('salary').get('salary_unit').setValidators([Validators.required]);
    this.addEmployeeForm.get('salary').get('salary_unit').setErrors({ 'required': true });

    this.addEmployeeForm.updateValueAndValidity();
  }
  requiredFullTime() {
    this.addEmployeeForm.get('salary').get('base_salary').setValidators([Validators.required]);
    this.addEmployeeForm.get('salary').get('base_salary').setErrors({ 'required': true });

    this.addEmployeeForm.get('salary').get('required_hours').setValidators([Validators.required]);
    this.addEmployeeForm.get('salary').get('required_hours').setErrors({ 'required': true });

    this.addEmployeeForm.updateValueAndValidity();
  }
  requiredPartTime() {
    this.addEmployeeForm.get('salary').get('rate').setValidators([Validators.required]);
    this.addEmployeeForm.get('salary').get('rate').setErrors({ 'required': true });

    this.addEmployeeForm.get('salary').get('rate_unit').setValidators([Validators.required]);
    this.addEmployeeForm.get('salary').get('rate_unit').setErrors({ 'required': true });

    this.addEmployeeForm.updateValueAndValidity();
  }
  requiredFactory() {
    this.addEmployeeForm.get('salary').get('rate').setValidators([Validators.required]);
    this.addEmployeeForm.get('salary').get('rate').setErrors({ 'required': true });

    this.addEmployeeForm.get('salary').get('rate_unit').setValidators([Validators.required]);
    this.addEmployeeForm.get('salary').get('rate_unit').setErrors({ 'required': true });

    this.addEmployeeForm.get('salary').get('base_salary').setValidators([Validators.required]);
    this.addEmployeeForm.get('salary').get('base_salary').setErrors({ 'required': true });

    this.addEmployeeForm.updateValueAndValidity();
  }
  requiredStaff() {

    this.addEmployeeForm.get('salary').get('base_salary').setValidators([Validators.required]);
    this.addEmployeeForm.get('salary').get('base_salary').setErrors({ 'required': true });

    this.addEmployeeForm.get('salary').get('commision').setValidators([Validators.required]);
    this.addEmployeeForm.get('salary').get('commision').setErrors({ 'required': true });

    this.addEmployeeForm.updateValueAndValidity();
  }
  uploadImage(event) {
    this.imgLoader = true;
    this.form_Data.append('fileToUpload', event.target.files[0]);
    this.http.uploadEmployeePic(this.form_Data).subscribe(res => {
      this.imgLoader = false;
      this.toast.success(res['detail']);
      this.addEmployeeForm.patchValue({
        image_path: res['file_url']
      });
    }, (error) => {
      this.imgLoader = false;
    });
  }
  patchRateUnit(event) {
    this.addEmployeeForm.get('salary').patchValue({
      rate_unit: event.target.value
    });
  }
  postCashBank() {
    //  console.log('this is the transaction', this.addCashBankForm.value);
    this.http.addCashBankDuplicate(this.addCashBankForm.value).subscribe(res => {
      this.toast.success(res['detail']);
      if(this.transType === 'ledger'){
        this.getEmployeeLedgerList(this.employeeledgerList.value.emp_detail_id)
      }
      
    }, (error) => {
      this.toast.error(error.error['msg']);
    })
  }
  popAudioFile(fileArray: any, form) {
    
    if (fileArray?.length && form) {
      let findAudio = fileArray.find(x => x.file_type == 'audio');
      let arrayIds = this[form].get('files').value;
      arrayIds.splice(arrayIds.indexOf(findAudio.id), 1);
      this[form].get('files').setValue(arrayIds);
    }
  }
  getListOfAdones(type: string = '') {
    this.http.getListOfAdones(type).subscribe((res: any) => {
      this.listAddons = res?.data;
      
    }, (error) => {

    })
  }
  deleteSingleAdon(id: any, type) {
    this.http.deleteSingleAdon(id).subscribe((res: any) => {
      this.toast.success('Adon deleted successfully');
      this.getListOfAdones(type);
    }, (error) => {

    })
  }
  editSingleAdon(item: any, modal) {
    this.additionchargesForm.reset();
    this.additionchargesForm.patchValue(
      { ...item},
      { emitEvent: false, onlySelf: true }
    );
    this.additionchargesForm.updateValueAndValidity({
      emitEvent: false,
      onlySelf: true,
    });
    this.additionchargesForm.markAsTouched({ onlySelf: true });
    //  console.log('this is the item', item);
    //  console.log('this is the form',  this.additionchargesForm?.value);
    this.modalService.open(modal, {
      centered: true,
      windowClass: 'ledger-modal',
      size: 'lg',
    });
  }
}