import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbDatepickerI18n, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/services/auth.service';
import { EmployeeService } from 'src/app/shared/services/employee.service';
import { LedgerService } from 'src/app/shared/services/ledger.service';
import { LocalizeDatepickerService } from 'src/app/shared/services/localize-datepicker.service';

@Component({
  selector: 'app-cashfilters',
  templateUrl: './cashfilters.component.html',
  styleUrls: ['./cashfilters.component.scss'],
  providers: [{ provide: NgbDatepickerI18n, useClass: LocalizeDatepickerService }]
})
export class CashfiltersComponent implements OnInit {
  @Input() filterType;
  @Input() childFilter: any = 'ledger';
  id
  dateFormat: any
  dateissue: boolean = false
  transDate = { year: 2010, month: 1, day: 1 };
  constructor(public ledgerService: LedgerService, public auth: AuthService, public modalService: NgbModal, public route: ActivatedRoute, public employeeService: EmployeeService, public translate: TranslateService, public toast: ToastrService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.id = params['bank_id'];
    });
    if (this.auth.userData.emp_info.is_buissness_exist) {
      if (this.auth.userData.emp_info.Business.default_date_format.id === 1) {
        this.dateFormat = 'dd-MM-yyyy'
      }
      else if (this.auth.userData.emp_info.Business.default_date_format.id === 2) {
        this.dateFormat = 'MM-dd-yyyy'
      }
      else {
        this.dateFormat = 'yyyy-MM-dd'
      }
    }
  }
  // datefor(){
  //   
  //   if(this.ledgerService.CashFilters.value.start_date){
  //     let tempDate = this.ledgerService.datesetter(this.ledgerService.CashFilters.value.start_date)
  //     this.transDate.year = tempDate[0]
  //     this.transDate.month = tempDate[1]
  //     this.transDate.day = tempDate[2]
  //     //  console.log("Date after split", this.transDate)
  //   }
  // }
  selectFiltersDatexp(event, type) {
    this.transDate.year = event.year
    this.transDate.month = event.month
    this.transDate.day = event.day

    if (this.filterType == 'employeeledger') {

      if (event.month < 10 && event.day < 10) {
        if (type == 'start') {
          this.employeeService.employeeledgerList.patchValue({
            start_date: event.year + '-' + '0' + event.month + '-' + '0' + event.day,
            end_date: ""
          })
        } else {
          this.employeeService.employeeledgerList.patchValue({
            end_date: event.year + '-' + '0' + event.month + '-' + '0' + event.day
          })
        }
      }
      else if (event.month < 10 && event.day > 9) {
        if (type == 'start') {
          this.employeeService.employeeledgerList.patchValue({
            start_date: event.year + '-' + '0' + event.month + '-' + event.day,
            end_date: ""
          })
        } else {
          this.employeeService.employeeledgerList.patchValue({
            end_date: event.year + '-' + '0' + event.month + '-' + event.day
          })
        }
      }
      else if (event.month > 9 && event.day < 10) {
        if (type == 'start') {
          this.employeeService.employeeledgerList.patchValue({
            start_date: event.year + '-' + event.month + '-' + '0' + event.day,
            end_date: ""
          })
        } else {
          this.employeeService.employeeledgerList.patchValue({
            end_date: event.year + '-' + event.month + '-' + '0' + event.day
          })
        }
      }
      else {
        if (type == 'start') {
          this.employeeService.employeeledgerList.patchValue({
            start_date: event.year + '-' + event.month + '-' + event.day,
            end_date: ""
          })
        } else {
          this.employeeService.employeeledgerList.patchValue({
            end_date: event.year + '-' + event.month + '-' + event.day
          })
        }
      }
    }
    if (this.filterType == 'cash') {

      if (event.month < 10 && event.day < 10) {
        if (type == 'start') {
          this.ledgerService.CashFilters.patchValue({
            start_date: event.year + '-' + '0' + event.month + '-' + '0' + event.day,
            end_date: ""
          })
        } else {

          this.ledgerService.CashFilters.patchValue({
            end_date: event.year + '-' + '0' + event.month + '-' + '0' + event.day
          })
          if (this.ledgerService.CashFilters.value.start_date > this.ledgerService.CashFilters.value.end_date) {
            let toast
            this.translate.get('From Date cannot be greater than To Date').subscribe((data: any) => {
              toast = data;
            });
            this.toast.error(toast);
            this.ledgerService.CashFilters.patchValue({
              start_date: "",
              end_date: ""
            })
            this.dateissue = true
          }
          else {
            this.dateissue = false
          }
        }
      }
      else if (event.month < 10 && event.day > 9) {
        if (type == 'start') {
          this.ledgerService.CashFilters.patchValue({
            start_date: event.year + '-' + '0' + event.month + '-' + event.day,
            end_date: ""
          })
        } else {

          this.ledgerService.CashFilters.patchValue({
            end_date: event.year + '-' + '0' + event.month + '-' + event.day
          })
          if (this.ledgerService.CashFilters.value.start_date > this.ledgerService.CashFilters.value.end_date) {
            let toast
            this.translate.get('From Date cannot be greater than To Date').subscribe((data: any) => {
              toast = data;
            });
            this.toast.error(toast);
            this.ledgerService.CashFilters.patchValue({
              start_date: "",
              end_date: ""
            })
            this.dateissue = true
          }
          else {
            this.dateissue = false
          }
        }
      }
      else if (event.month > 9 && event.day < 10) {
        if (type == 'start') {
          this.ledgerService.CashFilters.patchValue({
            start_date: event.year + '-' + event.month + '-' + '0' + event.day,
            end_date: ""
          })
        } else {

          this.ledgerService.CashFilters.patchValue({
            end_date: event.year + '-' + event.month + '-' + '0' + event.day
          })
          if (this.ledgerService.CashFilters.value.start_date > this.ledgerService.CashFilters.value.end_date) {
            let toast
            this.translate.get('From Date cannot be greater than To Date').subscribe((data: any) => {
              toast = data;
            });
            this.toast.error(toast);
            this.ledgerService.CashFilters.patchValue({
              start_date: "",
              end_date: ""
            })
            this.dateissue = true
          }
          else {
            this.dateissue = false
          }
        }
      }
      else {
        if (type == 'start') {
          this.ledgerService.CashFilters.patchValue({
            start_date: event.year + '-' + event.month + '-' + event.day,
            end_date: ""
          })
        } else {

          this.ledgerService.CashFilters.patchValue({
            end_date: event.year + '-' + event.month + '-' + event.day
          })
          if (this.ledgerService.CashFilters.value.start_date > this.ledgerService.CashFilters.value.end_date) {
            let toast
            this.translate.get('From Date cannot be greater than To Date').subscribe((data: any) => {
              toast = data;
            });
            this.toast.error(toast);
            this.ledgerService.CashFilters.patchValue({
              start_date: "",
              end_date: ""
            })
            this.dateissue = true
          }
          else {
            this.dateissue = false
          }
        }
      }
    }
    if (this.filterType == 'inventory' || this.filterType == 'inventorybyRef') {
      if (event.month < 10 && event.day < 10) {
        if (type == 'start') {
          this.ledgerService.InventoryFilters.patchValue({
            start_date: event.year + '-' + '0' + event.month + '-' + '0' + event.day,
            end_date: ""
          })
        } else {
          this.ledgerService.InventoryFilters.patchValue({
            end_date: event.year + '-' + '0' + event.month + '-' + '0' + event.day
          })
          if (this.ledgerService.InventoryFilters.value.start_date > this.ledgerService.InventoryFilters.value.end_date) {
            let toast
            this.translate.get('From Date cannot be greater than To Date').subscribe((data: any) => {
              toast = data;
            });
            this.toast.error(toast);
            this.ledgerService.InventoryFilters.patchValue({
              start_date: "",
              end_date: ""
            })
            this.dateissue = true
          }
          else {
            this.dateissue = false
          }
        }
      }
      else if (event.month < 10 && event.day > 9) {
        if (type == 'start') {
          this.ledgerService.InventoryFilters.patchValue({
            start_date: event.year + '-' + '0' + event.month + '-' + event.day,
            end_date: ""
          })
        } else {
          this.ledgerService.InventoryFilters.patchValue({
            end_date: event.year + '-' + '0' + event.month + '-' + event.day
          })
          if (this.ledgerService.InventoryFilters.value.start_date > this.ledgerService.InventoryFilters.value.end_date) {
            let toast
            this.translate.get('From Date cannot be greater than To Date').subscribe((data: any) => {
              toast = data;
            });
            this.toast.error(toast);
            this.ledgerService.InventoryFilters.patchValue({
              start_date: "",
              end_date: ""
            })
            this.dateissue = true
          }
          else {
            this.dateissue = false
          }
        }
      }
      else if (event.month > 9 && event.day < 10) {
        if (type == 'start') {
          this.ledgerService.InventoryFilters.patchValue({
            start_date: event.year + '-' + event.month + '-' + '0' + event.day,
            end_date: ""
          })
        } else {
          this.ledgerService.InventoryFilters.patchValue({
            end_date: event.year + '-' + event.month + '-' + '0' + event.day
          })
          if (this.ledgerService.InventoryFilters.value.start_date > this.ledgerService.InventoryFilters.value.end_date) {
            let toast
            this.translate.get('From Date cannot be greater than To Date').subscribe((data: any) => {
              toast = data;
            });
            this.toast.error(toast);
            this.ledgerService.InventoryFilters.patchValue({
              start_date: "",
              end_date: ""
            })
            this.dateissue = true
          }
          else {
            this.dateissue = false
          }
        }
      }
      else {
        if (type == 'start') {
          this.ledgerService.InventoryFilters.patchValue({
            start_date: event.year + '-' + event.month + '-' + event.day,
            end_date: ""
          })
        } else {
          this.ledgerService.InventoryFilters.patchValue({
            end_date: event.year + '-' + event.month + '-' + event.day
          })
          if (this.ledgerService.InventoryFilters.value.start_date > this.ledgerService.InventoryFilters.value.end_date) {
            let toast
            this.translate.get('From Date cannot be greater than To Date').subscribe((data: any) => {
              toast = data;
            });
            this.toast.error(toast);
            this.ledgerService.InventoryFilters.patchValue({
              start_date: "",
              end_date: ""
            })
            this.dateissue = true
          }
          else {
            this.dateissue = false
          }
        }
      }
    }
    if (this.filterType == 'credit_card') {
      if (event.month < 10 && event.day < 10) {
        if (type == 'start') {
          this.ledgerService.cardFilters.patchValue({
            start_date: event.year + '-' + '0' + event.month + '-' + '0' + event.day,
            end_date: ""
          })
        } else {
          this.ledgerService.cardFilters.patchValue({
            end_date: event.year + '-' + '0' + event.month + '-' + '0' + event.day
          })
          if (this.ledgerService.cardFilters.value.start_date > this.ledgerService.cardFilters.value.end_date) {
            let toast
            this.translate.get('From Date cannot be greater than To Date').subscribe((data: any) => {
              toast = data;
            });
            this.toast.error(toast);
            this.ledgerService.cardFilters.patchValue({
              start_date: "",
              end_date: ""
            })
            this.dateissue = true
          }
          else {
            this.dateissue = false
          }
        }
      }
      else if (event.month < 10 && event.day > 9) {
        if (type == 'start') {
          this.ledgerService.cardFilters.patchValue({
            start_date: event.year + '-' + '0' + event.month + '-' + event.day,
            end_date: ""
          })
        } else {
          this.ledgerService.cardFilters.patchValue({
            end_date: event.year + '-' + '0' + event.month + '-' + event.day
          })
          if (this.ledgerService.cardFilters.value.start_date > this.ledgerService.cardFilters.value.end_date) {
            let toast
            this.translate.get('From Date cannot be greater than To Date').subscribe((data: any) => {
              toast = data;
            });
            this.toast.error(toast);
            this.ledgerService.bankFilters.patchValue({
              start_date: "",
              end_date: ""
            })
            this.dateissue = true
          }
          else {
            this.dateissue = false
          }
        }
      }
      else if (event.month > 9 && event.day < 10) {
        if (type == 'start') {
          this.ledgerService.cardFilters.patchValue({
            start_date: event.year + '-' + event.month + '-' + '0' + event.day,
            end_date: ""
          })
        } else {
          this.ledgerService.cardFilters.patchValue({
            end_date: event.year + '-' + event.month + '-' + '0' + event.day
          })
          if (this.ledgerService.cardFilters.value.start_date > this.ledgerService.bankFilters.value.end_date) {
            let toast
            this.translate.get('From Date cannot be greater than To Date').subscribe((data: any) => {
              toast = data;
            });
            this.toast.error(toast);
            this.ledgerService.cardFilters.patchValue({
              start_date: "",
              end_date: ""
            })
            this.dateissue = true
          }
          else {
            this.dateissue = false
          }
        }
      }
      else {
        if (type == 'start') {
          this.ledgerService.cardFilters.patchValue({
            start_date: event.year + '-' + event.month + '-' + event.day,
            end_date: ""
          })
        } else {
          this.ledgerService.cardFilters.patchValue({
            end_date: event.year + '-' + event.month + '-' + event.day
          })
          if (this.ledgerService.cardFilters.value.start_date > this.ledgerService.cardFilters.value.end_date) {
            let toast
            this.translate.get('From Date cannot be greater than To Date').subscribe((data: any) => {
              toast = data;
            });
            this.toast.error(toast);
            this.ledgerService.cardFilters.patchValue({
              start_date: "",
              end_date: ""
            })
            this.dateissue = true
          }
          else {
            this.dateissue = false
          }
        }
      }
    }
    if (this.filterType == 'bank') {
      if (event.month < 10 && event.day < 10) {
        if (type == 'start') {
          this.ledgerService.bankFilters.patchValue({
            start_date: event.year + '-' + '0' + event.month + '-' + '0' + event.day,
            end_date: ""
          })
        } else {
          this.ledgerService.bankFilters.patchValue({
            end_date: event.year + '-' + '0' + event.month + '-' + '0' + event.day
          })
          if (this.ledgerService.bankFilters.value.start_date > this.ledgerService.bankFilters.value.end_date) {
            let toast
            this.translate.get('From Date cannot be greater than To Date').subscribe((data: any) => {
              toast = data;
            });
            this.toast.error(toast);
            this.ledgerService.bankFilters.patchValue({
              start_date: "",
              end_date: ""
            })
            this.dateissue = true
          }
          else {
            this.dateissue = false
          }
        }
      }
      else if (event.month < 10 && event.day > 9) {
        if (type == 'start') {
          this.ledgerService.bankFilters.patchValue({
            start_date: event.year + '-' + '0' + event.month + '-' + event.day,
            end_date: ""
          })
        } else {
          this.ledgerService.bankFilters.patchValue({
            end_date: event.year + '-' + '0' + event.month + '-' + event.day
          })
          if (this.ledgerService.bankFilters.value.start_date > this.ledgerService.bankFilters.value.end_date) {
            let toast
            this.translate.get('From Date cannot be greater than To Date').subscribe((data: any) => {
              toast = data;
            });
            this.toast.error(toast);
            this.ledgerService.bankFilters.patchValue({
              start_date: "",
              end_date: ""
            })
            this.dateissue = true
          }
          else {
            this.dateissue = false
          }
        }
      }
      else if (event.month > 9 && event.day < 10) {
        if (type == 'start') {
          this.ledgerService.bankFilters.patchValue({
            start_date: event.year + '-' + event.month + '-' + '0' + event.day,
            end_date: ""
          })
        } else {
          this.ledgerService.bankFilters.patchValue({
            end_date: event.year + '-' + event.month + '-' + '0' + event.day
          })
          if (this.ledgerService.bankFilters.value.start_date > this.ledgerService.bankFilters.value.end_date) {
            let toast
            this.translate.get('From Date cannot be greater than To Date').subscribe((data: any) => {
              toast = data;
            });
            this.toast.error(toast);
            this.ledgerService.bankFilters.patchValue({
              start_date: "",
              end_date: ""
            })
            this.dateissue = true
          }
          else {
            this.dateissue = false
          }
        }
      }
      else {
        if (type == 'start') {
          this.ledgerService.bankFilters.patchValue({
            start_date: event.year + '-' + event.month + '-' + event.day,
            end_date: ""
          })
        } else {
          this.ledgerService.bankFilters.patchValue({
            end_date: event.year + '-' + event.month + '-' + event.day
          })
          if (this.ledgerService.bankFilters.value.start_date > this.ledgerService.bankFilters.value.end_date) {
            let toast
            this.translate.get('From Date cannot be greater than To Date').subscribe((data: any) => {
              toast = data;
            });
            this.toast.error(toast);
            this.ledgerService.bankFilters.patchValue({
              start_date: "",
              end_date: ""
            })
            this.dateissue = true
          }
          else {
            this.dateissue = false
          }
        }
      }
    }
    if (this.filterType == 'sale') {
      if (event.month < 10 && event.day < 10) {
        if (type == 'start') {
          this.ledgerService.SaleFilters.patchValue({
            start_date: event.year + '-' + '0' + event.month + '-' + '0' + event.day,
            end_date: ""
          })
        } else {
          this.ledgerService.SaleFilters.patchValue({
            end_date: event.year + '-' + '0' + event.month + '-' + '0' + event.day
          })
          if (this.ledgerService.SaleFilters.value.start_date > this.ledgerService.SaleFilters.value.end_date) {
            let toast
            this.translate.get('From Date cannot be greater than To Date').subscribe((data: any) => {
              toast = data;
            });
            this.toast.error(toast);
            this.ledgerService.SaleFilters.patchValue({
              start_date: "",
              end_date: ""
            })
            this.dateissue = true
          }
          else {
            this.dateissue = false
          }
        }
      }
      else if (event.month < 10 && event.day > 9) {
        if (type == 'start') {
          this.ledgerService.SaleFilters.patchValue({
            start_date: event.year + '-' + '0' + event.month + '-' + event.day,
            end_date: ""
          })
        } else {
          this.ledgerService.SaleFilters.patchValue({
            end_date: event.year + '-' + '0' + event.month + '-' + event.day
          })
          if (this.ledgerService.SaleFilters.value.start_date > this.ledgerService.SaleFilters.value.end_date) {
            let toast
            this.translate.get('From Date cannot be greater than To Date').subscribe((data: any) => {
              toast = data;
            });
            this.toast.error(toast);
            this.ledgerService.SaleFilters.patchValue({
              start_date: "",
              end_date: ""
            })
            this.dateissue = true
          }
          else {
            this.dateissue = false
          }
        }
      }
      else if (event.month > 9 && event.day < 10) {
        if (type == 'start') {
          this.ledgerService.SaleFilters.patchValue({
            start_date: event.year + '-' + event.month + '-' + '0' + event.day,
            end_date: ""
          })
        } else {
          this.ledgerService.SaleFilters.patchValue({
            end_date: event.year + '-' + event.month + '-' + '0' + event.day
          })
          if (this.ledgerService.SaleFilters.value.start_date > this.ledgerService.SaleFilters.value.end_date) {
            let toast
            this.translate.get('From Date cannot be greater than To Date').subscribe((data: any) => {
              toast = data;
            });
            this.toast.error(toast);
            this.ledgerService.SaleFilters.patchValue({
              start_date: "",
              end_date: ""
            })
            this.dateissue = true
          }
          else {
            this.dateissue = false
          }
        }
      }
      else {
        if (type == 'start') {
          this.ledgerService.SaleFilters.patchValue({
            start_date: event.year + '-' + event.month + '-' + event.day,
            end_date: ""
          })
        } else {
          this.ledgerService.SaleFilters.patchValue({
            end_date: event.year + '-' + event.month + '-' + event.day
          })
          if (this.ledgerService.SaleFilters.value.start_date > this.ledgerService.SaleFilters.value.end_date) {
            let toast
            this.translate.get('From Date cannot be greater than To Date').subscribe((data: any) => {
              toast = data;
            });
            this.toast.error(toast);
            this.ledgerService.SaleFilters.patchValue({
              start_date: "",
              end_date: ""
            })
            this.dateissue = true
          }
          else {
            this.dateissue = false
          }
        }
      }
    }
    if (this.filterType == 'purchase') {
      if (event.month < 10 && event.day < 10) {
        if (type == 'start') {
          this.ledgerService.PurchaseFilters.patchValue({
            start_date: event.year + '-' + '0' + event.month + '-' + '0' + event.day,
            end_date: ""
          })
        } else {
          this.ledgerService.PurchaseFilters.patchValue({
            end_date: event.year + '-' + '0' + event.month + '-' + '0' + event.day
          })
          if (this.ledgerService.PurchaseFilters.value.start_date > this.ledgerService.PurchaseFilters.value.end_date) {
            let toast
            this.translate.get('From Date cannot be greater than To Date').subscribe((data: any) => {
              toast = data;
            });
            this.toast.error(toast);
            this.ledgerService.PurchaseFilters.patchValue({
              start_date: "",
              end_date: ""
            })
            this.dateissue = true
          }
          else {
            this.dateissue = false
          }
        }
      }
      else if (event.month < 10 && event.day > 9) {
        if (type == 'start') {
          this.ledgerService.PurchaseFilters.patchValue({
            start_date: event.year + '-' + '0' + event.month + '-' + event.day,
            end_date: ""
          })
        } else {
          this.ledgerService.PurchaseFilters.patchValue({
            end_date: event.year + '-' + '0' + event.month + '-' + event.day
          })
          if (this.ledgerService.PurchaseFilters.value.start_date > this.ledgerService.PurchaseFilters.value.end_date) {
            let toast
            this.translate.get('From Date cannot be greater than To Date').subscribe((data: any) => {
              toast = data;
            });
            this.toast.error(toast);
            this.ledgerService.PurchaseFilters.patchValue({
              start_date: "",
              end_date: ""
            })
            this.dateissue = true
          }
          else {
            this.dateissue = false
          }
        }
      }
      else if (event.month > 9 && event.day < 10) {
        if (type == 'start') {
          this.ledgerService.PurchaseFilters.patchValue({
            start_date: event.year + '-' + event.month + '-' + '0' + event.day,
            end_date: ""
          })
        } else {
          this.ledgerService.PurchaseFilters.patchValue({
            end_date: event.year + '-' + event.month + '-' + '0' + event.day
          })
          if (this.ledgerService.PurchaseFilters.value.start_date > this.ledgerService.PurchaseFilters.value.end_date) {
            let toast
            this.translate.get('From Date cannot be greater than To Date').subscribe((data: any) => {
              toast = data;
            });
            this.toast.error(toast);
            this.ledgerService.PurchaseFilters.patchValue({
              start_date: "",
              end_date: ""
            })
            this.dateissue = true
          }
          else {
            this.dateissue = false
          }
        }
      }
      else {
        if (type == 'start') {
          this.ledgerService.PurchaseFilters.patchValue({
            start_date: event.year + '-' + event.month + '-' + event.day,
            end_date: ""
          })
        } else {
          this.ledgerService.PurchaseFilters.patchValue({
            end_date: event.year + '-' + event.month + '-' + event.day
          })
          if (this.ledgerService.PurchaseFilters.value.start_date > this.ledgerService.PurchaseFilters.value.end_date) {
            let toast
            this.translate.get('From Date cannot be greater than To Date').subscribe((data: any) => {
              toast = data;
            });
            this.toast.error(toast);
            this.ledgerService.PurchaseFilters.patchValue({
              start_date: "",
              end_date: ""
            })
            this.dateissue = true
          }
          else {
            this.dateissue = false
          }
        }
      }
    }
    if (this.filterType == 'expense') {
      if (event.month < 10 && event.day < 10) {
        if (type == 'start') {
          this.ledgerService.ExpenseFilters.patchValue({
            start_date: event.year + '-' + '0' + event.month + '-' + '0' + event.day,
            end_date: ""
          })
        } else {
          this.ledgerService.ExpenseFilters.patchValue({
            end_date: event.year + '-' + '0' + event.month + '-' + '0' + event.day
          })
          if (this.ledgerService.ExpenseFilters.value.start_date > this.ledgerService.ExpenseFilters.value.end_date) {
            let toast
            this.translate.get('From Date cannot be greater than To Date').subscribe((data: any) => {
              toast = data;
            });
            this.toast.error(toast);
            this.ledgerService.ExpenseFilters.patchValue({
              start_date: "",
              end_date: ""
            })
            this.dateissue = true
          }
          else {
            this.dateissue = false
          }
        }
      }
      else if (event.month < 10 && event.day > 9) {
        if (type == 'start') {
          this.ledgerService.ExpenseFilters.patchValue({
            start_date: event.year + '-' + '0' + event.month + '-' + event.day,
            end_date: ""
          })
        } else {
          this.ledgerService.ExpenseFilters.patchValue({
            end_date: event.year + '-' + '0' + event.month + '-' + event.day
          })
        }
        if (this.ledgerService.ExpenseFilters.value.start_date > this.ledgerService.ExpenseFilters.value.end_date) {
          let toast
          this.translate.get('From Date cannot be greater than To Date').subscribe((data: any) => {
            toast = data;
          });
          this.toast.error(toast);
          this.ledgerService.ExpenseFilters.patchValue({
            start_date: "",
            end_date: ""
          })
          this.dateissue = true
        }
        else {
          this.dateissue = false
        }
      }
      else if (event.month > 9 && event.day < 10) {
        if (type == 'start') {
          this.ledgerService.ExpenseFilters.patchValue({
            start_date: event.year + '-' + event.month + '-' + '0' + event.day,
            end_date: ""
          })
        } else {
          this.ledgerService.ExpenseFilters.patchValue({
            end_date: event.year + '-' + event.month + '-' + '0' + event.day
          })
          if (this.ledgerService.ExpenseFilters.value.start_date > this.ledgerService.ExpenseFilters.value.end_date) {
            let toast
            this.translate.get('From Date cannot be greater than To Date').subscribe((data: any) => {
              toast = data;
            });
            this.toast.error(toast);
            this.ledgerService.ExpenseFilters.patchValue({
              start_date: "",
              end_date: ""
            })
            this.dateissue = true
          }
          else {
            this.dateissue = false
          }
        }
      }
      else {
        if (type == 'start') {
          this.ledgerService.ExpenseFilters.patchValue({
            start_date: event.year + '-' + event.month + '-' + event.day,
            end_date: ""
          })
        } else {
          this.ledgerService.ExpenseFilters.patchValue({
            end_date: event.year + '-' + event.month + '-' + event.day
          })
          if (this.ledgerService.ExpenseFilters.value.start_date > this.ledgerService.ExpenseFilters.value.end_date) {
            let toast
            this.translate.get('From Date cannot be greater than To Date').subscribe((data: any) => {
              toast = data;
            });
            this.toast.error(toast);
            this.ledgerService.ExpenseFilters.patchValue({
              start_date: "",
              end_date: ""
            })
            this.dateissue = true
          }
          else {
            this.dateissue = false
          }
        }
      }
    }
    if (this.filterType == 'drive') {
      if (event.month < 10 && event.day < 10) {
        if (type == 'start') {
          this.ledgerService.DriveFilters.patchValue({
            start_date: event.year + '-' + '0' + event.month + '-' + '0' + event.day,
            end_date: ""
          })
        } else {
          this.ledgerService.DriveFilters.patchValue({
            end_date: event.year + '-' + '0' + event.month + '-' + '0' + event.day
          })
          if (this.ledgerService.DriveFilters.value.start_date > this.ledgerService.DriveFilters.value.end_date) {
            let toast
            this.translate.get('From Date cannot be greater than To Date').subscribe((data: any) => {
              toast = data;
            });
            this.toast.error(toast);
            this.ledgerService.DriveFilters.patchValue({
              start_date: "",
              end_date: ""
            })
            this.dateissue = true
          }
          else {
            this.dateissue = false
          }
        }
      }
      else if (event.month < 10 && event.day > 9) {
        if (type == 'start') {
          this.ledgerService.DriveFilters.patchValue({
            start_date: event.year + '-' + '0' + event.month + '-' + event.day,
            end_date: ""
          })
        } else {
          this.ledgerService.DriveFilters.patchValue({
            end_date: event.year + '-' + '0' + event.month + '-' + event.day
          })
        }
        if (this.ledgerService.DriveFilters.value.start_date > this.ledgerService.DriveFilters.value.end_date) {
          let toast
          this.translate.get('From Date cannot be greater than To Date').subscribe((data: any) => {
            toast = data;
          });
          this.toast.error(toast);
          this.ledgerService.DriveFilters.patchValue({
            start_date: "",
            end_date: ""
          })
          this.dateissue = true
        }
        else {
          this.dateissue = false
        }
      }
      else if (event.month > 9 && event.day < 10) {
        if (type == 'start') {
          this.ledgerService.DriveFilters.patchValue({
            start_date: event.year + '-' + event.month + '-' + '0' + event.day,
            end_date: ""
          })
        } else {
          this.ledgerService.DriveFilters.patchValue({
            end_date: event.year + '-' + event.month + '-' + '0' + event.day
          })
          if (this.ledgerService.DriveFilters.value.start_date > this.ledgerService.DriveFilters.value.end_date) {
            let toast
            this.translate.get('From Date cannot be greater than To Date').subscribe((data: any) => {
              toast = data;
            });
            this.toast.error(toast);
            this.ledgerService.DriveFilters.patchValue({
              start_date: "",
              end_date: ""
            })
            this.dateissue = true
          }
          else {
            this.dateissue = false
          }
        }
      }
      else {
        if (type == 'start') {
          this.ledgerService.DriveFilters.patchValue({
            start_date: event.year + '-' + event.month + '-' + event.day,
            end_date: ""
          })
        } else {
          this.ledgerService.DriveFilters.patchValue({
            end_date: event.year + '-' + event.month + '-' + event.day
          })
          if (this.ledgerService.DriveFilters.value.start_date > this.ledgerService.DriveFilters.value.end_date) {
            let toast
            this.translate.get('From Date cannot be greater than To Date').subscribe((data: any) => {
              toast = data;
            });
            this.toast.error(toast);
            this.ledgerService.DriveFilters.patchValue({
              start_date: "",
              end_date: ""
            })
            this.dateissue = true
          }
          else {
            this.dateissue = false
          }
        }
      }
    }


  }
  typeFiltersexp(type) {
    if (this.filterType == 'cash') {
      this.ledgerService.CashFilters.patchValue({
        transaction_type: type
      })
    }
    if (this.filterType == 'bank') {
      this.ledgerService.bankFilters.patchValue({
        transaction_type: type
      })
    }
    if (this.filterType == 'credit_card') {
      this.ledgerService.cardFilters.patchValue({
        transaction_type: type
      })
    }
    if (this.filterType == 'sale') {
      this.ledgerService.SaleFilters.patchValue({
        transaction_type: type
      })
    }
    if (this.filterType == 'purchase') {
      this.ledgerService.PurchaseFilters.patchValue({
        transaction_type: type
      })
    }
    if (this.filterType == 'expense') {
      this.ledgerService.ExpenseFilters.patchValue({
        transaction_type: type
      })
    }
    if (this.filterType == 'inventory' || this.filterType == 'inventorybyRef' || this.filterType == 'bundle'  || this.filterType == 'bundlebyRef') {
      this.ledgerService.InventoryFilters.patchValue({
        transaction_type: type
      })
    }
  }
  filterApplyexp() {
    //  console.log('this is the form value', this.ledgerService?.DriveFilters.value);
     
    // Check if the element with id 'textInput' exists
    const textInput = document.getElementById('textInput');

    if (textInput) {
      // Element exists, set its class name
      textInput.className = "show";
    } else {
      // Element does not exist, handle the case accordingly
      console.log("Element with id 'textInput' not found");
    }

    // Continue with the rest of your code
    this.ledgerService.applied = true;
    this.modalService.dismissAll();

    this.ledgerService.applied = true
    this.modalService.dismissAll();
    if (this.filterType == 'cash') {
      if(this.ledgerService.ledgerView = true){
        this.ledgerService.search = !this.ledgerService.search
        this.ledgerService.cashcurrentPage = 1
        this.ledgerService.ReverseCashTran();
      }
      else if(this.ledgerService.ledgerView = false){
        this.ledgerService.search = !this.ledgerService.search
        this.ledgerService.cashcurrentPage = 1
        this.ledgerService.getCashTran();
      }
    }
    if (this.filterType == 'bank') {
      if(this.ledgerService.ledgerView = true){
        this.ledgerService.search = !this.ledgerService.search
        this.ledgerService.banktransPage = 1
        this.ledgerService.getBankTranReverse(this.id);
      }
      else if(this.ledgerService.ledgerView = false){
        this.ledgerService.search = !this.ledgerService.search
        this.ledgerService.banktransPage = 1
        this.ledgerService.getBankTran(this.id);
      }
    }
    if (this.filterType == 'credit_card') {
      if(this.ledgerService.ledgerView = true){
        this.ledgerService.search = !this.ledgerService.search
        this.ledgerService.banktransPage = 1
        this.ledgerService.ReversegetSingleCreditDetails(this.ledgerService.deleteCard);
      }
      else if(this.ledgerService.ledgerView = false){
        this.ledgerService.search = !this.ledgerService.search
        this.ledgerService.banktransPage = 1
        this.ledgerService.getSingleCreditDetails(this.ledgerService.deleteCard);
      }
    }
    if (this.filterType == 'sale') {
      this.ledgerService.search = !this.ledgerService.search
      this.ledgerService.salecurrentPage = 1
      this.ledgerService.getSaleTran();
    }
    if (this.filterType == 'purchase') {
      this.ledgerService.search = !this.ledgerService.search
      this.ledgerService.purchasecurrentPage = 1
      this.ledgerService.getPurchaseTran()
    }
    if (this.filterType == 'expense') {
      this.ledgerService.search = !this.ledgerService.search
      this.ledgerService.expensecurrentPage = 1
      this.ledgerService.getExpenseTran();
    }
    if (this.filterType == 'drive') {
      this.ledgerService.search = !this.ledgerService.search
      this.ledgerService.drivecurrentPage = 1
      this.childFilter=this.ledgerService.currentType;
      this.ledgerService.getledgerroomFile();
    }
    if (this.filterType == 'inventory') {
      this.ledgerService.search = !this.ledgerService.search
      this.ledgerService.stockcurrentPage = 1
      this.ledgerService.getProductStock(this.ledgerService.StockProduct.id)
    }
    if (this.filterType == 'bundle') {
      this.ledgerService.search = !this.ledgerService.search
      this.ledgerService.stockcurrentPage = 1
      this.ledgerService.getBundleStock(this.ledgerService.StockProduct.id)
    }
    if (this.filterType == 'inventorybyRef') {
      this.ledgerService.search = !this.ledgerService.search
      this.ledgerService.getStockProByRef(this.ledgerService.idByref, this.ledgerService.selectedref)
    }
    if (this.filterType == 'bundlebyRef') {
      this.ledgerService.search = !this.ledgerService.search
      this.ledgerService.getStockBundleByRef(this.ledgerService.idByref, this.ledgerService.selectedref)
    }

  }
  ngbDateFormat(date) {
    try {
      if (date) {
        this.transDate = { year: 0, month: 0, day: 0 };
        var dateObj = new Date(date);
        this.transDate.year = dateObj.getUTCFullYear();
        this.transDate.month = dateObj.getUTCMonth() + 1;
        this.transDate.day = dateObj.getUTCDate();
        //  console.log("daye isssss", this.transDate);

        return this.transDate;
      }
    } catch (e) {
      return null;
    }
  }
  resetFilter() {
    this.ledgerService.applied = false
    this.ledgerService.search = false
    document.getElementById('textInput').className = "hide";
    if (this.filterType == 'cash') {
      this.ledgerService.resetFilterCash()
    }
    if (this.filterType == 'bank') {
      this.ledgerService.resetFilterBank()
    }
    if (this.filterType == 'credit_card') {
      this.ledgerService.resetFilterCard()
    }
    if (this.filterType == 'sale') {
      this.ledgerService.resetFilterSale()
    }
    if (this.filterType == 'purchase') {
      this.ledgerService.resetFilterPurchase()
    }
    if (this.filterType == 'expense') {
      this.ledgerService.resetFilterExp()
    }
    if (this.filterType == 'employeeledger') {
      this.employeeService.EmployeeLedgerReset()
    }
    if (this.filterType == 'drive') {
      this.ledgerService.resetFilterdrive(this.childFilter);
    }
    if (this.filterType == 'inventory' || this.filterType == 'inventorybyRef') {
      if (this.ledgerService.stockByRef === false) {
        this.ledgerService.InventoryFilters.patchValue({
          transaction_type: '',
          // debit_amount: '',
          // credit_amount: '',
          search_query: '',
          start_date: '',
          end_date: '',
        });
        this.ledgerService.stockcurrentPage = 1
        this.ledgerService.getProductStock(this.ledgerService.StockProduct.id)
      }
      if (this.ledgerService.stockByRef === true) {
        this.ledgerService.InventoryFilters.patchValue({
          transaction_type: '',
          // debit_amount: '',
          // credit_amount: '',
          search_query: '',
          start_date: '',
          end_date: '',
        });
        this.ledgerService.stockByRefcurrentPage = 1
        this.ledgerService.getStockProByRef(this.ledgerService.idByref, this.ledgerService.selectedref)
      }
    }
    this.modalService.dismissAll()
  }
  cross() {
    this.modalService.dismissAll();
  }
}