import * as ROUTES from "../routes/full.routes";

export const MetaTags = [
  {
    url : '',
    title : 'Thya Technology',
    keywords : 'Thya Technology',
    description : 'Thya Technology',
    author : 'Thya Technology',
  },
]
