<div class="modal-header p-0">
  <div class="w-100">
    <div class="modal-heading">
      <!-- <i aria-hidden="true" class="fa fa-tasks text-light"></i> -->
      <img src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/Cash Icons/Search.svg" class="mx-2 img-25" alt="">Search
    </div>
  </div>
  <div>
    <span aria-hidden="true" class="modal-close-btn mx-3 f-28">×</span>
  </div>
</div>
<!-- <div class="modal-header p-0 bb-none">
  <h5 class="modal-title" id="modal-basic-title"><img src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/Cash Icons/Search.svg" class="mx-2 img-25" alt="">Search</h5>
</div> -->
<div class="modal-body">
    <div class="body">
        <div class="d-flex justify-content-center mx-auto w-80">
            <div class="mt-3 d-flex align-items-center w-100">
                <div class="form-floating w-100">
                  <input class="form-control form-control-sm" id="floatingInput" type="text" placeholder="Search">
                  <label for="floatingInput" class="float-input-custom text-light"><span>Search</span></label>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-center mx-auto mt-3 w-80">
            <div class="w-100">                         
                <div class="form-floating w-100">                             
                    <input class="form-control" type="text" id="floatingInput" (keyup)="searchCustomer()" [(ngModel)]="querySearchValuesCust" [ngModelOptions]="{standalone: true}" placeholder="Choose Customer" > 
                    <label for="floatingInput" class="float-input-custom text-light"><span>Choose Customer</span></label>
                </div>  
                <div *ngIf="CustloadData==true" class="shadow-27">                                                 
                    <div class="cursor-pointer" *ngFor="let item of this.bookService?.allBusinessList">                                                      
                        <div class="w-100" (click)="getCustVendVal(item,0);selectBusiness(item)">                                                         
                            {{item?.with_business?.business_name}}                                                      
                        </div>
                    </div>                                              
                </div>     
            </div>              
        </div>
        <div class="d-flex justify-content-center mx-auto mt-3 w-80">
            <div class="cursor-pointer m-0 border borderR-4px w-100">
                <div class="d-flex justify-content-between px-2" (click)="openaddnew(addnew)">
                    <p *ngIf="!categoryName" class="f-16 m-0 text-light">{{'Categories' | translate}}</p>
                    <p *ngIf="categoryName" class="f-16 m-0 text-dark">{{categoryName}}</p>
                    <p class=" m-0"><i class="fa fa-angle-down f-20 text-light"></i></p>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-center mx-auto mt-3 w-80">
            <div class="w-100">                         
                <div class="form-floating w-100">
                    <input class="form-control" type="text" id="floatingInput" (keyup)="searchBundles()" [(ngModel)]="querySearchValues[0]" [ngModelOptions]="{standalone: true}" placeholder="Item Name">              
                    <label for="floatingInput" class="float-input-custom text-light"><span>Item Name</span></label>
                </div>  
                <div *ngIf="loadData==true && checkVal===0" class="shadow-27">                                                 
                    <div  class="bundle-div" >
                        <ng-container *ngIf="listing$ | async as listing ">
                            <ng-container *ngFor="let item of this.ledgerService.searchList">                                                     
                                <div class="border-listing">                                                      
                                    <div class="d-flex justify-content-between p-2">
                                        <div class="f-14" (click)="getItemVal(item,0)">                                                         
                                            {{item?.product_name}}                                                      
                                        </div>
                                        <div class="f-14 text-primary">
                                            {{item?.unit_price}}  
                                        </div>   
                                    </div>                                                   
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>                                        
                </div>
            </div>              
        </div>
        <div class="d-flex justify-content-center mx-auto mt-3 w-80">
            <div class="d-flex justify-content-center bg-gray">
                <div class="btn-group filterButtons bg-gray align-items-center w-100" id="myDIV" (click)="activeunc()">
                    <img class="img-15 cursor-pointer mx-2" src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/Cash Icons/Filter.svg">
                    <button class="btn bcCustom " type="button" [ngClass]="{'bg-btn-primary' :  TimeValue.toString() === 'today'}" (click)="TimeFIlter('today')">Today</button>
                    <button class="btn bcCustom " type="button" [ngClass]="{'bg-btn-primary' :  TimeValue.toString() === 'week'}"  (click)="TimeFIlter('week')">Week</button>
                    <button class="btn bcCustom " type="button" [ngClass]="{'bg-btn-primary' :  TimeValue.toString() === 'month'}" (click)="TimeFIlter('month')">Month</button>
                    <button class="btn bcCustom " type="button" [ngClass]="{'bg-btn-primary' :  TimeValue.toString() === 'year'}" (click)="TimeFIlter('year')">Year</button>
                    <button class="btn bcCustom " type="button" [ngClass]="{'bg-btn-primary' :  TimeValue.toString() === 'all'}" (click)="TimeFIlter('all')">All</button> 
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-center mx-auto mt-3 w-80">
            <div class="w-49 mx-auto datepicker py-2">
                <div class="form-inline custom-datepicker custom-datepicker-dropdown">
                  <div class="form-group m-0">
                    <div class="input-group">
                      <div class="input-group-append rtl" *ngIf="auth.dirWeb === 'rtl'">
                        <button (click)="fvalid.toggle()" class="btn calendar" type="button"><img src="../../../../assets/Accountect/calendar.svg" class="img-18" alt=""></button>

                      </div>
                      <input #fvalid="ngbDatepicker" class="form-control cursor-pointer border-right-none" name="dp" (click)="fvalid.toggle()" ngbDatepicker
                        [placeholder]="'From'|translate" readonly>
                      <div class="input-group-append ltr" *ngIf="auth.dirWeb === 'ltr'">
                        <button (click)="fvalid.toggle()" class="btn calendar border-left-none px-2 pt-1" type="button">
                          <img src="../../../../assets/Accountect/calendar.svg" alt="">
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
            </div>

            <div class="w-49 mx-auto datepicker py-2">
                <div class="form-inline custom-datepicker custom-datepicker-dropdown">
                  <div class="form-group m-0">
                    <div class="input-group">
                      <div class="input-group-append rtl" *ngIf="auth.dirWeb === 'rtl'">
                        <button (click)="fvalid.toggle()" class="btn calendar" type="button"><img src="../../../../assets/Accountect/calendar.svg" class="img-18" alt=""></button>

                      </div>
                      <input #valid="ngbDatepicker" class="form-control cursor-pointer border-right-none" name="dp" (click)="valid.toggle()" ngbDatepicker
                        [placeholder]="'To'|translate" readonly>
                      <div class="input-group-append ltr" *ngIf="auth.dirWeb === 'ltr'">
                        <button (click)="valid.toggle()" class="btn calendar px-2 pt-1 border-left-none" type="button">
                          <img src="../../../../assets/Accountect/calendar.svg" alt="">
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <input class="form-control" placeholder="yyyy-mm-dd" type="date" name="dp"> -->
              </div>
        </div>
        <div class="d-flex justify-content-between mx-auto mt-3 w-90">
            <button class="btn bg-white shadow-white text-dark w-25">
                Reset
            </button>
            <button class="btn bg-invoice w-70">
                Apply
            </button>
        </div>
    </div>
</div>











<ng-template #addnew let-modal>
    <div class="modal-header">
      <h5 class="modal-title" id="modal-basic-title">

      </h5>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">

  
      <div class="mt-3 h-250">
        <div *ngIf="categoryListingLoader" class="overlay-loader">
          <div class="spinner-border spinner-border-sm text-primary m-auto" role="status">
          </div>
        </div>
        <ng-container *ngIf="!categoryListingLoader">
          <div *ngFor="let item of ledgerService?.invnCat"
            class="d-flex align-items-center w-100 px-2 position-relative hover-opacity">
  
            <div (click)="categoryAddToExpenseForm(item, modal)"
              class="d-flex justify-content-between w-100 py-2 border-btm">
              <div class="edit">
                {{item?.title}}
              </div>
            </div>
            <div class="w-5 align-self-center py-2 text-end">
              <i class="fa fa-angle-right text-dark arrow-detail px-2"
                [ngClass]="{'fa-angle-right': auth.dirWeb == 'ltr', 'fa-angle-left': auth.dirWeb == 'rtl'}"
                aria-hidden="true"></i>
            </div>
  
          </div>
        </ng-container>
      </div>
    </div>
    <div class="modal-footer justify-content-center">
      <button type="button" class="btn" (click)="AddCategory(AddCategoryname)"><img
          src="../../../../assets/Accountect/Add Button.svg" class="mx-1" alt="">{{'Add New' | translate}}</button>
    </div>
  </ng-template>