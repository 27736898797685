<div class="dir-ltr-fix">
    <div>
        
        <h2 class="heading">
            <img class="img-30 cursor-pointer" (click)="backButton()" src="../../assets/Back-white.svg">
            About Us</h2>
    </div>
    <div class="d-flex justify-content-center">
        <div class="card cardd">
            <div class="card-body">
                Accountect is  a cloud based connected accounting platform. It connects the business partners in real time for instant reconciliation 
                of their accounts. Designed to be used by users with no understanding of accounting,the platform helps the user to manage their Sales, 
                Purchase, Expense, Bank Accounts, Cash Book,Inventory and Salary Book. 
            </div>
            <a class="card mx-3 mb-2" href="#" target="_blank">
                <div class="card-body">
                 <b> Terms and Condition</b>
                </div>
            </a>
            <a class="card mx-3 mb-2" href="#" target="_blank">
                <div class="card-body">
                <b>  Privacy Policy </b>
                </div>
            </a>
        </div>
        <div class="g-signin2" data-onsuccess="onSignIn"></div>
    </div>
</div>