import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {
  public build_type = environment.build_type;
  constructor() { }

  ngOnInit(): void {
  }
  backButton() {
    window.location.replace('');
  }
}
