import { Component, OnInit } from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';
import {  fadeIn } from 'ng-animate';
import { HttpServiceService } from 'src/app/shared/services/http/http-service.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { LedgerService } from 'src/app/shared/services/ledger.service';
@Component({
    selector: 'app-content-layout',
    templateUrl: './content-layout.component.html',
    styleUrls: ['./content-layout.component.scss'],
    animations: [
      trigger('animateRoute', [transition('* => *', useAnimation(fadeIn, {
          // Set the duration to 5seconds and delay to 2 seconds
          // params: { timing: 3}
      }))])
  ]
})
export class ContentLayoutComponent implements OnInit {
  days: any
    constructor(private http: HttpServiceService, private auth: AuthService, private ledger: LedgerService) {

        }



    ngOnInit() {
      this.ledger.getCurrentPlan()
      //  console.log("Content LayOut");
    }
    public getRouterOutletState(outlet) {
      return outlet.isActivated ? outlet.activatedRoute : '';
  }
}
