import { Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { EmployeeService } from '../../services/employee.service';
import { HttpServiceService } from '../../services/http/http-service.service';
import { LedgerService } from '../../services/ledger.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { loadStripe } from '@stripe/stripe-js';

@Component({
  selector: 'app-plan-expired-popup',
  templateUrl: './plan-expired-popup.component.html',
  styleUrls: ['./plan-expired-popup.component.scss']
})


export class PlanExpiredPopupComponent implements OnInit {
  myPlaN:boolean= false;
  // @ViewChild('planExpireModal', { static: false, read: TemplateRef })
  @ViewChild('dialog', {static: true}) dialogRef !: ElementRef;
  // messageModalRef !: TemplateRef<any>;
  @Output() onClick: EventEmitter<any> = new EventEmitter<any>();
  allPlans: any = [];
  currentPlan: any;
  currentSubscription: any;
  dateFormat: string;
  stripePromise: any = null;
  freePlan: any;
  MonthlyPlan: any;
  YearlyPlan: any;

  constructor(
    public modalService: NgbModal, public ledgerService: LedgerService,public employeeService: EmployeeService,public auth: AuthService,
    private http: HttpServiceService) {
   }

  ngOnInit(): void {

    this.ledgerService.subject1.subscribe(()=>{
      this.open();
      })
      this.employeeService.subject1.subscribe(()=>{
        this.open();
        })
    this.stripePromise = loadStripe('pk_test_51OXjKKHfvbS50V5ZOQSmDwsL2c1MaVvkT0JPMy5OOI2oBK4kRHWmXQ4UMEhxnNvOuXsbCEi76PKOxrhDIre8WIG100pVp0rv7O');

  }
  async checkoutStripe(pkg:any) {
    
    // Call your backend to create the Checkout session.
    // When the customer clicks on the button, redirect them to Checkout.
    const stripe = await this.stripePromise;
    this.http.getpaymentsession(pkg.id).subscribe((res:any) => {

      //format-givenby-laraib
      //api/payments/verify-checkout-payment/ <session_id>/<planId>/<userEmail>/
  
      stripe.redirectToCheckout({
        sessionId: res['session'].id
      });

    })
   
  }
  done(): void{
    this.onClick.emit();
    this.modalService.dismissAll();

  }
  open(): void {
    // 
    // this.modalService.open(this.messageModalRef);
    this.modalService.open(
      this.dialogRef,
      {
        size : 'md',
        centered: true, scrollable: true, windowClass: 'confirm-modal', animation: true,
        ariaLabelledBy: "Confirmation"
      }
    )

  }
  getPlans() {
    this.myPlaN = false
    this.http.get_plans().subscribe((res) => {
      this.allPlans = res['plans'];
      this.freePlan = this.allPlans[0];
      this.MonthlyPlan = this.allPlans[1];
      this.YearlyPlan = this.allPlans[2];
      this.currentPlan = res['current_plan'];
      this.currentSubscription = res['current_plan']['subscription']['name'];
      //  console.log(this.allPlans);
      //  console.log('Saaad Check plan',this.currentSubscription);
    });
  }
  openModaluasge(modal){
    this.modalService.open(modal, { centered: true, windowClass: 'planM', size: 'md',});
  }
  DateFormatepipe() {
    if (this.auth.userData.emp_info.Business.default_date_format.id === 1) {
      this.dateFormat = 'dd-MM-yyyy';
    } else if (
      this.auth.userData.emp_info.Business.default_date_format.id === 2
    ) {
      this.dateFormat = 'MM-dd-yyyy';
    } else {
      this.dateFormat = 'yyyy-MM-dd';
    }
  }
  openModalNewUser(modal) {
    this.DateFormatepipe();
    this.getPlans();
    this.modalService.open(modal, {
      centered: true,
      windowClass: 'planModal',
      size: 'lg',
    });
  }
  modalClose(){
    this.modalService.dismissAll()
  }

}
