// import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { LedgerService } from '../services/ledger.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpServiceService } from '../services/http/http-service.service';
import { NgbCalendar, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BillBookervice } from '../services/billBook.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-journal-filters',
  templateUrl: './journal-filters.component.html',
  styleUrls: ['./journal-filters.component.scss']
})
export class JournalFiltersComponent implements OnInit {
  selectedOption: string = 'sent';
  invoiceType:any
  selectedOptionAR:string = 'accept'
  querySearchValuesCust: any;
  querySearchValues: string[] = [];
  CustloadData:boolean;
  loadData: boolean;
  categoryName: any;
  checkVal:any;
  listing$: Observable<any>;
  TimeValue = 'all';
  dateArray1: any = []
  dateArray2: any = []
  bundleType: boolean;
  
  constructor(public router: Router,public auth: AuthService,public translate: TranslateService,private calendar: NgbCalendar,public bookService: BillBookervice,
    public datepipe: DatePipe,public ledgerService: LedgerService,private http: HttpServiceService,public modalService: NgbModal) { }

  ngOnInit(): void {
  }
  TimeFIlter(value) {
    if (this.ledgerService.loader === false) {
      this.ledgerService.loader = true
      this.TimeValue = value
      this.ledgerService.search = false
      this.ledgerService.applied = false
        if (value === 'week') {
          var curr = new Date(); // get current date
          var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
          var last = first + 6; // last day is the first day + 6

          var startDate = this.datepipe.transform(first, 'yyyy-MM-dd')
          var endDate = this.datepipe.transform(last, 'yyyy-MM-dd')
          var firstday = new Date(curr.setDate(first)).toUTCString().split(" ", 4);
          var lastday = new Date(curr.setDate(last)).toUTCString().split(" ", 4);
          firstday.pop[0]
          lastday.pop[0]
          this.dateArray1 = this.datepipe.transform(firstday, 'yyyy-MM-dd')
          this.dateArray2 = this.datepipe.transform(lastday, 'yyyy-MM-dd')
          //  console.log("Array//", this.dateArray1);
          //  console.log("cur//", first, last);
          this.ledgerService.SaleFilters.patchValue({
            start_date: this.dateArray1,
            end_date: this.dateArray2
          })
          //  console.log("date", this.ledgerService.SaleFilters.value.start_date);
        }
        else if (value === 'year') {
          var currentTime = new Date()

          var month = currentTime.getMonth() + 1

          var day = currentTime.getDate()

          var year = currentTime.getFullYear()
          this.ledgerService.SaleFilters.patchValue({
            start_date: + year + '-' + '01' + '-' + '01',
            end_date: year + '-' + '12' + '-' + '31'
          })
        }
        else if (value === 'month') {
          var date = new Date();
          var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
          var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
          // var startMonth = firstDay.split()
          //  console.log("montj", this.datepipe.transform(firstDay, 'yyyy-MM-dd'), lastDay);
          var StartMont = this.datepipe.transform(firstDay, 'yyyy-MM-dd')
          var EndMont = this.datepipe.transform(lastDay, 'yyyy-MM-dd')
          this.ledgerService.SaleFilters.patchValue({
            start_date: StartMont,
            end_date: EndMont
          })
        }
        else if (value === 'today') {
          var date = new Date();
          var firstDay = date
          var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
          // var startMonth = firstDay.split()
          //  console.log("montj", date);
          var StartMont = this.datepipe.transform(firstDay, 'yyyy-MM-dd')
          var EndMont = this.datepipe.transform(lastDay, 'yyyy-MM-dd')
          this.ledgerService.SaleFilters.patchValue({
            start_date: StartMont,
            end_date: StartMont
          })
        }
        else {
          this.ledgerService.SaleFilters.patchValue({
            start_date: "",
            end_date: ""
          })
        }
        this.ledgerService.salecurrentPage = 1
        this.ledgerService.getSaleTran()
    }
  }
  activeunc() {
    var header = document.getElementById("myDIV");
    var btns = header.getElementsByClassName("btn");
    for (var i = 0; i < btns.length; i++) {
      btns[i].addEventListener("click", function () {
        var current = document.getElementsByClassName("active");
        current[0].className = current[0].className.replace(" active", "");
        this.className += " active";
      });
    }

  }
  togglesentReceived(option:string){
    this.selectedOption = option;
    // this.bookService.billBookFilters = {
    //   ...this.bookService.billBookFilters,
    //   bill_board_transaction_type: this.invoiceType,
    //   invoice_status_type:type
    // };
    // this.bookService.getFilterInvoiceBook();
  }
  toggleAcceptReject(option:string){
    this.selectedOptionAR = option;
    // this.bookService.billBookFilters = {
    //   ...this.bookService.billBookFilters,
    //   bill_board_transaction_type: this.invoiceType,
    //   invoice_status_type:type
    // };
    // this.bookService.getFilterInvoiceBook();
  }
  searchCustomer(){
    this.bookService.custVendQuery=this.querySearchValuesCust;
    // console.log(this.querySearchValuesCust);
    if(this.querySearchValuesCust=='')
    {
      this.CustloadData=false;
    }
    else
    {
      this.CustloadData=true;
      this.bookService?.getBusinessListFilter();
    }
  }
  getCustVendVal(item){
    console.log(item?.with_business?.business_name)
    this.querySearchValuesCust=item?.with_business?.business_name;
    this.CustloadData=false;
  }
  selectBusiness(item: any) {
    this.bookService.baseModel.with_Business_information = item?.with_business?.id;
    this.bookService.baseModel.shipping_Business_information =
      item?.with_business?.id;
      this.bookService.baseModel.shipping_Business_information = {
        ...this.bookService.baseModel.shipping_Business_information,
        ...item?.with_business, full_phone_number: item?.with_business?.phone_number || item?.with_business?.full_phone_number 
      };
  }
  searchBundles(res: any = '33', page: any = 1) {
    if (res) {
      // this.ledgerServices.earchvalue = res
      // const newRow = { field1: '', field2: '', field3: '' };
      // this.ledgerService.rows.push(newRow);
      // this.selectedRowIndex = this.ledgerService.rows.length - 1
      this.ledgerService.loadBusi = true;
      console.log(this.ledgerService.querySearch);
      // // alert("searchBundle");
      this.loadData = false
      this.bundleType=false
      this.http.getMarketListingBundles(4248, this.ledgerService.querySearch, 'all', page,this.bundleType, null).subscribe(data => {
        this.ledgerService.searchList = data;
        this.ledgerService.loadBusi = false;
        // let listing=data['market_listing'];
        let listing = data['market_listing'];
        if (Array.isArray(listing)) {
          // If 'market_listing' is an array, use it directly
          this.ledgerService.searchList = listing;
        } else {
          // If 'market_listing' is an object, convert it to an array
          this.ledgerService.searchList = Object.values(listing);
        }
        console.log(this.ledgerService.searchList);
        console.log(typeof this.ledgerService.searchList);
        this.ledgerService.loadBusi = false;

        console.log(listing);
        console.log(typeof listing);
        this.loadData = true;
        ////  console.log('this is the search query result', data);
      }, (error) => {
        this.ledgerService.loadBusi = false;
      });
    } else {
      this.ledgerService.selectedBuss = [];
      this.ledgerService.searchList = '';
    }
  }
  openaddnew(modal) {
    // this.getExpenseCategories();
    this.modalService.open(modal, {
      centered: true,
      windowClass: 'addnew',
      size: 'md',
    });
  }
  categoryAddToExpenseForm(item, modal) {
    console.log(item);
    this.categoryName = item.title;
    this.ledgerService.addInventory.patchValue({
      product_category: item.id
    })

    modal.dismiss();
  }
}
