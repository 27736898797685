import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.scss']
})
export class PolicyComponent implements OnInit {
  public build_type = environment.build_type;
  constructor() { }

  ngOnInit(): void {
    
  }
  backButton() {
    window.location.replace('');
}
}
