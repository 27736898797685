import { Injectable, HostListener } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// Menu
export interface Menu {
    path?: string;
    title?: string;
    role?: string;
    icon?: string;
    type?: string;
    badgeType?: string;
    badgeValue?: string;
    active?: boolean;
    bookmark?: boolean;
    children?: Menu[];
    svg:string;
    png:string;
    
}

@Injectable({
    providedIn: 'root'
})

export class NavService {
    subSnackbar: boolean;
    constructor() {
        this.onResize();
        if (this.screenWidth < 991) {
            this.collapseSidebar = true;
        }
    }

    public screenWidth: any;
    public collapseSidebar = false;

    // tslint:disable-next-line: member-ordering
    MENUITEMS: Menu[] = [
        {
            path: '/watchlist/default', title: 'Broadcasts', icon: 'https://hiwac-nc.s3.us-east-2.amazonaws.com/images/mainmenu/Broadcasr', type: 'link', bookmark: true, svg: '-a.png', png: '.png'
        },

        {
            path: '/chat/default/reset/-99', title: 'Bubbles', icon: 'https://hiwac-nc.s3.us-east-2.amazonaws.com/images/mainmenu/Bubbles', type: 'link', bookmark: true, svg: '-a.png', png: '.png'
        },
        // {
        // 	path: '/network/network', title: 'Network', icon: 'git-merge', type: 'link', bookmark: true
        // },
        {
            path: '/quotation/listings', title: 'Quotation', icon: 'https://hiwac-nc.s3.us-east-2.amazonaws.com/images/mainmenu/Quotations', type: 'link', bookmark: true, svg: '-a.png', png: '.png'
        },
        {
            path: '/contracts/dashboard', title: 'Contracts', icon: 'https://hiwac-nc.s3.us-east-2.amazonaws.com/images/mainmenu/Contracts', type: 'link', bookmark: true, svg: '-a.png', png: '.png'
        },

        {
            path: '/product-services/result', title: 'Market', icon: 'https://hiwac-nc.s3.us-east-2.amazonaws.com/images/mainmenu/Market', type: 'link', bookmark: true, svg: '-a.png', png: '.png'
        },
        {
            path: '/network/network', title: 'Network', icon: 'https://hiwac-nc.s3.us-east-2.amazonaws.com/images/mainmenu/Network', type: 'link', bookmark: true, svg: '-a.png', png: '.png'
        },
        // {
        // 	path: '/calender', title: 'Calender', icon: 'calendar', type: 'link', bookmark: true
        // },

        // {
        // 	path: '/pricing', title: 'Pricing', icon: 'dollar-sign', type: 'link'
        // },
        // {
        // 	path: '/pricing', title: 'Subscriptions', icon: 'link-2', type: 'link'
        // },
        // {
        // 	path: '/sample-page', title: 'Sample page', icon: 'file', type: 'link'
        // },
        // {
        // 	path: '/search-result', title: 'Search Pages', icon: 'search', type: 'link'
        // },
        // {
        // 	title: 'Error Page', icon: 'alert-octagon', type: 'sub', active: false, children: [
        // 		{ path: 'error/400', title: 'Error 400', type: 'extTabLink' },
        // 		{ path: 'error/401', title: 'Error 401', type: 'extTabLink' },
        // 		{ path: 'error/403', title: 'Error 403', type: 'extTabLink' },
        // 		{ path: 'error/404', title: 'Error 404', type: 'extTabLink' },
        // 		{ path: 'error/500', title: 'Error 500', type: 'extTabLink' },
        // 		{ path: 'error/503', title: 'Error 503', type: 'extTabLink' }
        // 	]
        // },
        // {
        // 	title: 'Authentication', icon: 'unlock', type: 'sub', active: false, children: [
        // 		{ path: 'authentication/login', title: 'Login Simple', type: 'extTabLink' },
        // 		{ path: 'authentication/login/image', title: 'Login with Bg Image', type: 'extTabLink' },
        // 		{ path: 'authentication/login/video', title: 'Login with Bg video', type: 'extTabLink' },
        // 		{ path: 'authentication/register', title: 'Register Simple', type: 'extTabLink' },
        // 		{ path: 'authentication/register/image', title: 'Register with Bg Image', type: 'extTabLink' },
        // 		{ path: 'authentication/register/video', title: 'Register with Bg video', type: 'extTabLink' },
        // 		{ path: 'authentication/unlockuser', title: 'Unlock User', type: 'extTabLink' },
        // 		{ path: 'authentication/forgetpassword', title: 'Forget Password', type: 'extTabLink' },
        // 		{ path: 'authentication/resetpassword', title: 'Reset Password', type: 'extTabLink' }
        // 	]
        // },
        // {
        // 	title: 'Coming Soon', icon: 'briefcase', type: 'sub', active: false, children: [
        // 		{ path: 'comingsoon/page', title: 'Coming Simple', type: 'extTabLink' },
        // 		{ path: 'comingsoon/page/image', title: 'Coming with Bg Image', type: 'extTabLink' },
        // 		{ path: 'comingsoon/page/video', title: 'Coming with Bg video', type: 'extTabLink' }
        // 	]
        // },
        // {
        // 	path: '/maintenance', title: 'Maintenance', icon: 'settings', type: 'link'
        // },
    ];
    // Array
    items = new BehaviorSubject<Menu[]>(this.MENUITEMS);

    // Windows width
    @HostListener('window:resize', ['$event'])
    onResize(event?) {
        this.screenWidth = window.innerWidth;
    }


}
