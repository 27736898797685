import { Routes } from '@angular/router';

export const full: Routes = [
  {
    path: 'error',
    loadChildren:() => import(  '../../pages/error-pages/error-pages.module').then(m => m.ErrorPagesModule),
    data: { preload: false, delay: false },

  },
  {
    path: 'authentication',
    loadChildren:() => import( '../../pages/authentication/authentication.module').then(m => m.AuthenticationModule),
    data: { preload: false, delay: false },

  },
  {
    path: 'auth',
    loadChildren:() => import( '../../pages/authentication/authentication.module').then(m => m.AuthenticationModule),
    data: { preload: false, delay: false },

  },
  {
    path: 'verifybyemail',
    loadChildren:() => import(  '../../components/verify/verify.module').then(m => m.VerifyModule),

    data: { preload: false, delay: false },

  },
];
