import {Injectable} from '@angular/core';
import {NgbDatepickerI18n, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from './auth.service';

const I18N_VALUES = {
  'zh-Hans': {
    weekdays: ['一', '二', '三', '四', '五', '六', '日'],
    months: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
    weekLabel: 'sem'
  },
  'ur': {
    weekdays: ['پیر', 'منگل', 'بدھ', 'جمعرات', 'جمعہ', 'ہفتہ', 'اتوار'],
    months: ['جنوری', 'فروری', 'مارچ', 'اپریل', 'مئی', 'جون', 'جولائی', 'اگست', 'ستمبر', 'اکتوبر', 'نومبر', 'دسمبر'],
    weekLabel: 'sem'
  },
  'en': {
    weekdays: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
    months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    weekLabel: 'sem'
  },
  'ar': {
    weekdays: ['س', 'ح', 'ن', 'ث', 'ر', 'خ', 'ج'],
    months: ['كانون الثاني', 'شُبَاط', 'آذَار', 'نَيْسَان', 'أَيَّار', 'حَزِيرَان', 'تَمُّوز', 'آب', 'أَيْلُول', 'تِشْرِين ٱلْأَوَّل	', 'تِشْرِين ٱلثَّانِي	', 'كَانُون ٱلْأَوَّل	'],
    weekLabel: 'sem'
  },
  'fr': {
    weekdays: ['L', 'M', 'M', 'J', 'V', 'S', 'D'],
    months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    weekLabel: 'sem'
  },
  'tr': {
    weekdays: ['P', 'S', 'Ç', 'P', 'C', 'C', 'P'],
    months: ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'],
    weekLabel: 'sem'
  },
  'es': {
    weekdays: ['L', 'M', 'X', 'J', 'V', 'S', 'D'],
    months: ['enero', 'febrero', 'marzo', 'abril', 'Mayo', 'junio', 'mes de julio', 'agosto', 'septiembre', 'octubre', 'octubre', 'diciembre'],
    weekLabel: 'sem'
  },
  'pt': {
    weekdays: ['S', 'T', 'Q', 'Q', 'S', 'S', 'D'],
    months: ['Janeiro', 'fevereiro', 'Março', 'abril', 'Posso', 'junho', 'julho', 'agosto', 'setembro', 'Outubro', 'novembro', 'dezembro'],
    weekLabel: 'sem'
  },
  'id': {
    weekdays: ['S', 'S', 'R', 'K', 'J', 'S', 'M'],
    months: ['Januari', 'Februari', 'berbaris', 'April', 'Mungkin', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'],
    weekLabel: 'sem'
  },
  'ml': {
    weekdays: ['I', 'S', 'R', 'K', 'J', 'S', 'A'],
    months: ['ജനുവരി', 'ഫെബ്രുവരി', 'മാർച്ച്', 'ഏപ്രിൽ', 'മെയ്', 'ജൂൺ', 'ജൂലൈ', 'ഓഗസ്റ്റ്', 'സെപ്റ്റംബർ', 'ഒക്ടോബർ', 'നവംബർ', 'ഡിസംബർ'],
    weekLabel: 'sem'
  },
  'th': {
    weekdays: ['จั', 'อั', 'พุ', 'พฤ', 'ศุ', 'ส', 'ทิ'],
    months: ['มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'],
    weekLabel: 'sem'
  },
  'ru': {
    weekdays: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
    months: ['январь', 'февраль', 'Март', 'апреля', 'май', 'июнь', 'июль', 'август', 'сентябрь', 'октябрь', 'ноябрь', 'Декабрь'],
    weekLabel: 'sem'
  },
  'ja': {
    weekdays: ['月曜', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日', '日曜日'],
    months: ['一月', '2月', '行進', '4月', '5月', '六月', '7月', '8月', '9月', '10月', '11月', '12月'],
    weekLabel: 'sem'
  },
  'nl': {
    weekdays: ['M', 'D', 'W', 'D', 'V', 'Z', 'Z'],
    months: ['januari', 'Februari', 'maart', 'april', 'Kunnen', 'juni-', 'juli-', 'augustus', 'september', 'oktober', 'november', 'december'],
    weekLabel: 'sem'
  },
  'ko': {
    weekdays: ['월', '화', '수', '목', '금', '토', '일'],
    months: ['1 월', '2 월', '3 월', '4 월', '5 월', '6 월', '칠월', '팔월', '구월', '십월', '십일월', '12 월'],
    weekLabel: 'sem'
  },
  'it': {
    weekdays: ['L', 'M', 'M', 'G', 'V', 'S', 'D'],
    months: ['gennaio', 'febbraio', 'marzo', 'aprile', 'Maggio', 'giugno', 'luglio', 'agosto', 'settembre', 'ottobre', 'novembre', 'dicembre'],
    weekLabel: 'sem'
  },
  'vi': {
    weekdays: ['T2', 'T3', 'T4', 'T5', 'T6', 'T7', 'CN'],
    months: ['tháng Giêng', 'tháng hai', 'bước đều', 'tháng tư', 'Có thể', 'tháng Sáu', 'tháng Bảy', 'tháng Tám', 'Tháng Chín', 'Tháng Mười', 'tháng Mười Một', 'tháng 12'],
    weekLabel: 'sem'
  },
  'hi': {
    weekdays: ['स', 'मं', 'बु', 'गु', 'शु', 'श', 'र'],
    months: ['जनवरी', 'फ़रवरी', 'जुलूस', 'अप्रैल', 'मई', 'जून', 'जुलाई', 'अगस्त', 'सितंबर', 'अक्टूबर', 'नवंबर', 'दिसंबर'],
    weekLabel: 'sem'
  },
  'bn': {
    weekdays: ['সো', 'ম', 'ব', 'বৃ', 'শু', 'শ', 'র'],
    months: ['জানুয়ারী', 'ফেব্রুয়ারী', 'মার্চ', 'এপ্রিল', 'মে', 'জুন', 'জুলাই', 'আগস্ট', 'সেপ্টেম্বর', 'অক্টোবর', 'নভেম্বর', 'ডিসেম্বর'],
    weekLabel: 'sem'
  },
  'fi': {
    weekdays: ['M', 'T', 'K', 'T', 'P', 'L', 'S'],
    months: ['Enero', 'Pebrero', 'Marso', 'Abril', 'May', 'Hunyo', 'Hulyo', 'Agosto', 'september', 'oktubre', 'nobyembre', 'disyembre'],
    weekLabel: 'sem'
  },
  'udm': {
    weekdays: ['P', 'W', 'Ś', 'C', 'P', 'S', 'N'],
    months: ['Styczeń', 'Luty', 'Marsz', 'kwiecień', 'Może', 'czerwiec', 'lipiec', 'sierpień', 'wrzesień', 'październik', 'Listopad', 'grudzień'],
    weekLabel: 'sem'
  },
  'ca': {
    weekdays: ['П', 'В', 'С', 'Ч', 'П', 'С', 'Н'],
    months: ['січня', 'лютий', 'березень', 'квітень', 'Може', 'червень', 'липень', 'серпень', 'Вересень', 'жовтень', 'Листопад', 'Грудень'],
    weekLabel: 'sem'
  },
  'el': {
    weekdays: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
    months: ['enero', 'febrero', 'marzo', 'abril', 'Mayo', 'junio', 'mes de julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
    weekLabel: 'sem'
  },
  'eu': {
    weekdays: ['A', 'A', 'A', 'O', 'O', 'L', 'I'],
    months: ['Janeiro', 'fevereiro', 'Março', 'abril', 'Posso', 'junho', 'julho', 'agosto', 'setembro', 'Outubro', 'novembro', 'dezembro'],
    weekLabel: 'sem'
  },
  'sl': {
    weekdays: ['ස', 'අ', 'බ', 'බ්‍ර', 'සි', 'සෙ', 'ඉ'],
    months: ['ජනවාරි', 'පෙබරවාරි', 'මාර්තු', 'අප්රේල්', 'මැයි', 'ජූනි', 'ජුලි', 'අගෝස්තු', 'සැප්තැම්බර්', 'ඔක්තෝම්බර්', 'නොවැම්බර්', 'දෙසැම්බර්'],
    weekLabel: 'sem'
  },
};
@Injectable()

export class LocalizeDatepickerService extends NgbDatepickerI18n {
  constructor(public auth: AuthService) { super(); }

  getWeekdayShortName(weekday: number): string { return I18N_VALUES[this.auth.targetLanguage].weekdays[weekday - 1]; }
  getWeekLabel(): string { return I18N_VALUES[this.auth.targetLanguage].weekLabel; }
  getMonthShortName(month: number): string { return I18N_VALUES[this.auth.targetLanguage].months[month - 1]; }
  getMonthFullName(month: number): string { return this.getMonthShortName(month); }
  getDayAriaLabel(date: NgbDateStruct): string { return `${date.day}-${date.month}-${date.year}`; }
}
