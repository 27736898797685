<div class="dark-body-only" id="outer-container">
  <!-- page-wrapper Start-->
  <div class="page-wrapper mx-auto">
    <div>
      <app-top-header></app-top-header>
    </div>
    <div class="d-flex">
      <div>
        <app-header></app-header>
      </div>
      <div class="page-body-wrapper">
        <div class="page-body" id="page_body">
          <main [@animateRoute]="getRouterOutletState(o)">
            <router-outlet #o="outlet"></router-outlet>
          </main>
          <!-- <footer class="footer">
            <app-footer></app-footer>
          </footer> -->
        </div>
      </div>
    </div>
    <div>
      <footer class="footer">
        <app-footer></app-footer>
      </footer>
    </div>
    <!-- Page Body End-->
  </div>
  <!-- page-wrapper End-->
</div>
<!--<app-customizer></app-customizer>-->
<app-plan-expired-popup></app-plan-expired-popup>
