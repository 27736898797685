import { Component, OnInit, Output, EventEmitter, Inject, PLATFORM_ID, Renderer2, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment.prod';
import { AuthService } from '../../services/auth.service';
import { HttpServiceService } from '../../services/http/http-service.service';
import { LedgerService } from '../../services/ledger.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BillBookervice } from '../../services/billBook.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public cities: any;
  tabSelected:any
  selectedCity: any;
  colorchange:any;
  Language
  public build_type = environment.build_type;
  Checkreq: any;

  constructor(private renderer: Renderer2,private el: ElementRef,public authService: AuthService,public bookService: BillBookervice,
    public router: Router,public route: ActivatedRoute, public ledgerService: LedgerService,  public modalService: NgbModal,
    private http: HttpServiceService, public toast: ToastrService) {
  }

  ngOnInit() {
    this.http.GetUserData().subscribe(data => {
      this.Checkreq = data['emp_info']
    });
    const html: HTMLElement = this.el.nativeElement.ownerDocument.documentElement;
    this.renderer.setAttribute(html, 'lang', this.authService.targetLanguage);

    //  // alert('loaddd')
    this.ledgerService.MainView = false;
    this.ledgerService.PendingTransactionView = true
    // this.route.queryParamMap.subscribe(params => {

    //   if(params.get('selectedlang') ){
    //     this.authService.targetLanguage = params.get('selectedlang') || 'en';
    //   }
    //   // else if(this.authService.targetLanguage){
    //   //   this.authService.targetLanguage = this.authService.targetLanguage
    //   // } else{
    //   //   this.authService.targetLanguage ='en'
    //   // }
    // });
    this.colorchange=localStorage.getItem('language');
    //  console.log(this.colorchange,'color changeee')
    // this.authService.AllLanguages()
    this.authService.getIPData();
    // this.authService.targetLanguage = this.colorchange
    this.ledgerService.javascriptSocket();
    this.authService.getTourCiwac();
    // //  console.log("language is", this.authService.targetLanguage);

  }
  onAccountsClick() {
    this.authService.headertoggle('Ledger');
    // // alert('loaddd')
    this.ledgerService.addinvoiceCust=false;
    this,this.ledgerService.addinvoiceCust=false;
    this.ledgerService.MainView = false;
    this.ledgerService.PendingTransactionView = true
  }

 
  AccpRejAdminReq(id, value) {
    this.http.AccpRejAdminRec(id, value).subscribe(res => {
      this.toast.success(res['detail'])
      this.authService.AdminRequestRec()
      this.ledgerService.updateUserAuth()
      this.ledgerService.getBusinessMembers()
    }, (error) => {

    });
  }
  openModallogout(modal) {
    this.modalService.open(modal, {
      centered: true,
      windowClass: '',
      size: 'md',
    });
  }
  moveto(tab){
    if(tab === 'ledger'){
      if(this.authService.userData.emp_info.is_buissness_exist === true){
      this.router.navigate(['/ledger/default'],{
        queryParams: {
          id: this.ledgerService.urlUser ||  null
        },
      })
      }
      else{
        this.router.navigate(['ledger/join/create'])
      }
    }
    if(tab === 'summary'){
      if(this.authService.userData.emp_info.is_buissness_exist === true){
        this.router.navigate(['ledger/summery'])
    }
    else{
      this.router.navigate(['ledger/join/create'])
      }

    }
  }
}
