<div class="modal-ngb">
    <div class="modal-header p-1">
      <div class="w-100">
        <div class="modal-heading py-2">
          <img src="../../../../assets/Accountect/missingsettings/Group 40564.svg" alt="">
          <h6 class="my-0 f-17">{{'Participants'|translate}}</h6>
        </div>
      </div>
      <div>
        <span aria-hidden="true" (click)="dismiss()" class="modal-close-btn">×</span>
      </div>
    </div>
    <div class="modal-body p-3 participant-modal">
      <div class="p-2">
        <div class="w-100 border-part">
          <div class="part-tabs p-2 bg-light">
            <div class="w-100 tab px-3">
              <div class="tab-item">
                <img class="shadow-sm" width="20px" [src]="auth?.userData?.emp_info?.Business?.business_image?auth?.userData?.emp_info?.Business?.business_image: '../../../../assets/Accountect/settingsweb/Group 40411.svg'"
                  alt="">
                <span class="text-light-dark px-2">{{auth?.userData?.emp_info?.Business?.business_name}}</span>
              </div>
            </div>
          </div>
          <div class="part-list mt-2 py-2 px-3">
            <ng-container *ngIf="ParticipentType === 'cash'">
              <ng-container *ngFor="let item of ledgerService.cashTrans?.cash_room_member?.members">
                <div *ngIf="item?.employee_role?.toString().toLowerCase() === 'admin'" class="list-item list-admin d-flex justify-content-between">
                  <div class="d-flex align-items-center">
                    <div class="list-img">
                      <img class="shadow-sm img-40"  [src]="item?.user_profile_image?item?.user_profile_image: '../../../../assets/Accountect/settingsweb/Group 40411.svg'" alt="">
                    </div>
                    <div class="list-text">
                      <span class="text-dark">{{item?.first_name}} {{item?.last_name}}</span>
                      <p class="text-light m-0 font-weight-500 f-14">{{ledgerService.selectedTrans?.ledger_member_data?.business?.business_name}}</p>
                    </div>
                  </div>
                  <div class="admin my-auto">
                    <span class="text-primary mx-2 font-weight-500">{{'Admin'|translate}}</span>
                  </div>
                </div>
              </ng-container>
              <div class="overflow-part">
                <ng-container *ngFor="let item of ledgerService.cashTrans?.cash_room_member?.members">
                  <div *ngIf="item?.employee_role?.toString().toLowerCase() !== 'admin' && item?.inactive_account === false" class="list-item p-2 d-flex justify-content-between">
                    <div class="d-flex align-items-center w-100">
                      <div class="list-img">
                        <img class="shadow-sm img-40" [src]="item?.user_profile_image?item?.user_profile_image: '../../../../assets/Accountect/settingsweb/Group 40411.svg'" alt="">
                      </div>
                      <div class="list-text">
                        <span class="text-dark" *ngIf="item?.first_name && item?.last_name; else username">{{item?.first_name}} {{item?.last_name}}</span>
                        <ng-template #username>
                          <span class="text-dark">{{item?.username}}</span>
                        </ng-template>
                        <p class="text-light m-0 font-weight-500 f-14">{{ledgerService.selectedTrans?.ledger_member_data?.business?.business_name}}</p>
                      </div>
                    </div>
                    <div class="list-time my-auto"  *ngIf="auth?.userData?.emp_info?.employee_role?.toString() == 'admin'">
                      <div class="" placement="bottom-left" ngbDropdown>
                        <div ngbDropdownToggle class="d-flex flex-column align-items-center cursor-pointer">
                          <i class="fa fa-ellipsis-v p-2" aria-hidden="true"></i>
                        </div>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="removeee">
                          
                          <button (click)="removeParticipant(item?.emp_id)" ngbDropdownItem>
                            <div class="d-flex align-items-center">
                              <i class="fa fa-trash text-danger" aria-hidden="true"></i>
                              <span class="text-danger mx-2">{{'Remove'|translate}}</span>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="item?.employee_role?.toString().toLowerCase() !== 'admin' && item?.inactive_account === true" class="list-item p-2 d-flex justify-content-between">
                    <div class="d-flex align-items-center w-100">
                      <div class="list-img">
                        <img class="shadow-sm img-40" [src]="item?.inactive_account_data?.user_profile_image?item?.inactive_account_data?.user_profile_image: '../../../../assets/Accountect/settingsweb/Group 40411.svg'" alt="">
                      </div>
                      <div class="list-text">
                        <span class="text-dark" *ngIf="item?.inactive_account_data?.first_name && item?.inactive_account_data?.last_name; else username">{{item?.inactive_account_data?.first_name}} {{item?.inactive_account_data?.last_name}}</span>
                        <ng-template #username>
                          <span class="text-dark">{{item?.inactive_account_data?.username}}</span>
                        </ng-template>
                        <p class="text-light m-0 font-weight-500 f-14">{{ledgerService.selectedTrans?.ledger_member_data?.business?.business_name}}</p>
                      </div>
                    </div>
                    <div class="list-time my-auto"  *ngIf="auth?.userData?.emp_info?.employee_role?.toString() == 'admin'">
                      <div class="" placement="bottom-left" ngbDropdown>
                        <div ngbDropdownToggle class="d-flex flex-column align-items-center cursor-pointer">
                          <i class="fa fa-ellipsis-v p-2" aria-hidden="true"></i>
                        </div>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="removeee">
                          <button (click)="removeParticipant(item?.inactive_account_data?.emp_id)" ngbDropdownItem>
                            <div class="d-flex align-items-center">
                              <i class="fa fa-trash text-danger" aria-hidden="true"></i>
                              <span class="text-danger mx-2">{{'Remove'|translate}}</span>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="ledgerService.cashTrans?.cash_room_member?.members?.length<1">
                  <div class="d-flex align-items-center flex-column text-center justify-content-center w-100 p-5">
                    <img src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/ledger/Invite business in ledger.svg" alt="">
                    <span *ngIf="build_type === 'ciwac'"  class="text-light mt-2">{{'You have no network members on Accountect'|translate}}</span>
                  </div>
                </ng-container>
              </div>
              <div class="d-flex justify-content-between pt-3 b-t-light w-100 mt-4">
                <div class="">
                    <span
                        class="text-primary f-20 font-weight-bold">{{ledgerService.cashTrans?.cash_room_member?.members.length}}
                    </span>
                    <span *ngIf="ledgerService.cashTrans?.cash_room_member?.members.length <= 1"
                        class="text-primary f-18">{{'Member'|translate}}</span>
                    <span *ngIf="ledgerService.cashTrans?.cash_room_member?.members.length > 1"
                        class="text-primary f-18">{{'Members'|translate}}</span>
                </div>
                <div class="add-btn cursor-pointer m-0 d-flex justify-content-between br-light"
                                            *ngIf="auth?.userData?.emp_info?.employee_role === 'admin'"
                                            (click)="openModalAddMember(AddMember, 'Active')">
                                            <div>
                                                <img src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/updated/Add.svg" class="w-100 img-40">
                                            </div>
                                            <div class="my-auto">
                                                <span class="mx-2 text-primary">{{'Add Member'|translate}}</span>
                                            </div>

                                        </div>
            </div>
            </ng-container>
            <ng-container *ngIf="ParticipentType === 'bank'">
              <div *ngIf="ledgerService?.allBanks?.bank_room_data?.length > 0" >
                <ng-container *ngFor="let item of ledgerService.allBanks?.bank_room_member?.members">
                  <div *ngIf="item?.employee_role?.toString().toLowerCase() === 'admin'" class="list-item list-admin d-flex justify-content-between">
                    <div class="d-flex align-items-center ">
                      <div class="list-img">
                        <img class="shadow-sm img-40"  [src]="item?.user_profile_image?item?.user_profile_image: '../../../../assets/Accountect/settingsweb/Group 40411.svg'" alt="">
                      </div>
                      <div class="list-text">
                        <span class="text-dark">{{item?.first_name}} {{item?.last_name}}</span>
                        <p class="text-light m-0 font-weight-500 f-14">{{ledgerService.selectedTrans?.ledger_member_data?.business?.business_name}}</p>
                      </div>
                    </div>
                    <div class="admin my-auto">
                      <span class="text-primary mx-2 font-weight-500">{{'Admin'|translate}}</span>
                    </div>
                  </div>
                </ng-container>
                <div class="overflow-part">
                  <ng-container *ngFor="let item of ledgerService.allBanks?.bank_room_member?.members">
                    <div *ngIf="item?.employee_role?.toString().toLowerCase() !== 'admin' && item?.inactive_account == false" class="list-item p-2 d-flex justify-content-between">
                      <div class="d-flex align-items-center w-100">
                        <div class="list-img">
                          <img class="shadow-sm img-40" [src]="item?.user_profile_image?item?.user_profile_image: '../../../../assets/Accountect/settingsweb/Group 40411.svg'" alt="">
                        </div>
                        <div class="list-text">
                          <span class="text-dark" *ngIf="item?.first_name && item?.last_name; else username">{{item?.first_name}} {{item?.last_name}}</span>
                          <ng-template #username>
                            <span class="text-dark">{{item?.username}}</span>
                          </ng-template>
                          <p class="text-light m-0 font-weight-500 f-14">{{ledgerService.selectedTrans?.ledger_member_data?.business?.business_name}}</p>
                        </div>
                      </div>
                      <div class="list-time my-auto"  *ngIf="auth?.userData?.emp_info?.employee_role?.toString() == 'admin'">
                        <div class="" placement="bottom-left" ngbDropdown>
                          <div ngbDropdownToggle class="d-flex flex-column align-items-center cursor-pointer">
                            <i class="fa fa-ellipsis-v p-2" aria-hidden="true"></i>
                          </div>
                          <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="removeee">
                            <!-- <button *ngIf="auth?.userData?.emp_info?.employee_role?.toString() == 'admin'" ngbDropdownItem (click)="editRightsEmp(editRights, item)">
                              <div class="d-flex align-items-center">
                                <i class="fa fa-check-square text-primary" aria-hidden="true"></i>
                                <span class="text-primary mx-2">{{'Edit'|translate}}</span>
                              </div>
                            </button> -->
                            <button (click)="removeParticipant(item?.emp_id)" ngbDropdownItem>
                              <div class="d-flex align-items-center">
                                <i class="fa fa-trash text-danger" aria-hidden="true"></i>
                                <span class="text-danger mx-2">{{'Remove'|translate}}</span>
                              </div>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="item?.employee_role?.toString().toLowerCase() !== 'admin' && item?.inactive_account == true" class="list-item p-2 d-flex justify-content-between">
                      <div class="d-flex align-items-center w-100">
                        <div class="list-img">
                          <!-- <img src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/header/Me Inactive.svg" alt=""> -->
                          <img class="shadow-sm img-40" [src]="item?.inactive_account_data?.user_profile_image?item?.inactive_account_data?.user_profile_image: '../../../../assets/Accountect/settingsweb/Group 40411.svg'" alt="">
                        </div>
                        <div class="list-text">
                          <span class="text-dark" *ngIf="item?.inactive_account_data?.first_name && item?.inactive_account_data?.last_name; else username">{{item?.inactive_account_data?.first_name}} {{item?.inactive_account_data?.last_name}}</span>
                          <ng-template #username>
                            <span class="text-dark">{{item?.inactive_account_data?.username}}</span>
                          </ng-template>
                          <p class="text-light m-0 font-weight-500 f-14">{{ledgerService.selectedTrans?.ledger_member_data?.business?.business_name}}</p>
                        </div>
                      </div>
                      <div class="list-time my-auto"  *ngIf="auth?.userData?.emp_info?.employee_role?.toString() == 'admin'">
                        <div class="" placement="bottom-left" ngbDropdown>
                          <div ngbDropdownToggle class="d-flex flex-column align-items-center cursor-pointer">
                            <i class="fa fa-ellipsis-v p-2" aria-hidden="true"></i>
                          </div>
                          <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="removeee">
                            <!-- <button *ngIf="auth?.userData?.emp_info?.employee_role?.toString() == 'admin'" ngbDropdownItem (click)="editRightsEmp(editRights, item)">
                              <div class="d-flex align-items-center">
                                <i class="fa fa-check-square text-primary" aria-hidden="true"></i>
                                <span class="text-primary mx-2">{{'Edit'|translate}}</span>
                              </div>
                            </button> -->
                            <button (click)="removeParticipant(item?.inactive_account_data?.emp_id)" ngbDropdownItem>
                              <div class="d-flex align-items-center">
                                <i class="fa fa-trash text-danger" aria-hidden="true"></i>
                                <span class="text-danger mx-2">{{'Remove'|translate}}</span>
                              </div>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="ledgerService.cashTrans?.cash_room_member?.members?.length<1">
                    <div class="d-flex align-items-center flex-column text-center justify-content-center w-100 p-5">
                      <img src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/ledger/Invite business in ledger.svg" alt="">
                      <span *ngIf="build_type === 'ciwac'"  class="text-light mt-2">{{'You have no network members on Accountect'|translate}}</span>
                      <span *ngIf="build_type === 'niwac'"  class="text-light mt-2">{{'You have no network members on Niwac'|translate}}</span>
                    </div>
                  </ng-container>
                </div>
              </div>
             <div class="overflow-part" *ngIf="ledgerService?.allBanks?.bank_room_data?.length === 0"></div>
              <div class="d-flex justify-content-between pt-3 b-t-light w-100 mt-4">
                <div class="" >
                    <span *ngIf="ledgerService?.allBanks?.bank_room_data?.length > 0"
                        class="text-primary f-20 font-weight-bold">{{ledgerService.allBanks?.bank_room_member?.members.length}}
                    </span>
                    <span *ngIf="ledgerService?.allBanks?.bank_room_data?.length > 0 && ledgerService.allBanks?.bank_room_member?.members.length <= 1"
                        class="text-primary f-18">{{'Member'|translate}}</span>
                    <span *ngIf="ledgerService?.allBanks?.bank_room_data?.length > 0 && ledgerService.allBanks?.bank_room_member?.members.length > 1"
                        class="text-primary f-18">{{'Members'|translate}}</span>
                </div>
                <div class="add-btn cursor-pointer m-0 d-flex justify-content-between br-light"
                                            *ngIf="auth?.userData?.emp_info?.employee_role === 'admin'"
                                            (click)="openModalAddMember(AddMember, 'Active')">
                                            <div>
                                                <img src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/updated/Add.svg" class="w-100 img-40">
                                            </div>
                                            <div class="my-auto">
                                                <span class="mx-2 text-primary">{{'Add Member'|translate}}</span>
                                            </div>

                                        </div>
              </div>
            </ng-container>
            <ng-container *ngIf="ParticipentType === 'sale'">
              <ng-container *ngFor="let item of ledgerService.SaleData?.sale_room_member?.members">
                <div *ngIf="item?.employee_role?.toString().toLowerCase() === 'admin'" class="list-item list-admin d-flex justify-content-between">
                  <div class="d-flex align-items-center">
                    <div class="list-img">
                      <img class="shadow-sm img-40"  [src]="item?.user_profile_image?item?.user_profile_image: '../../../../assets/Accountect/settingsweb/Group 40411.svg'" alt="">
                    </div>
                    <div class="list-text">
                      <span class="text-dark">{{item?.first_name}} {{item?.last_name}}</span>
                      <p class="text-light m-0 font-weight-500 f-14">{{ledgerService.selectedTrans?.ledger_member_data?.business?.business_name}}</p>
                    </div>
                  </div>
                  <div class="admin my-auto">
                    <span class="text-primary mx-2 font-weight-500">{{'Admin'|translate}}</span>
                  </div>
                </div>
              </ng-container>
              <div class="overflow-part">
                <ng-container *ngFor="let item of ledgerService.SaleData?.sale_room_member?.members">
                  <div *ngIf="item?.employee_role?.toString().toLowerCase() !== 'admin' && item?.inactive_account == false" class="list-item p-2 d-flex justify-content-between">
                    <div class="d-flex align-items-center w-100">
                      <div class="list-img">
                        <img class="shadow-sm img-40" [src]="item?.user_profile_image?item?.user_profile_image: '../../../../assets/Accountect/settingsweb/Group 40411.svg'" alt="">
                      </div>
                      <div class="list-text">
                        <span class="text-dark" *ngIf="item?.first_name && item?.last_name; else username">{{item?.first_name}} {{item?.last_name}}</span>
                        <ng-template #username>
                          <span class="text-dark">{{item?.username}}</span>
                        </ng-template>
                        <p class="text-light m-0 font-weight-500 f-14">{{ledgerService.selectedTrans?.ledger_member_data?.business?.business_name}}</p>
                      </div>
                    </div>
                    <div class="list-time my-auto"  *ngIf="auth?.userData?.emp_info?.employee_role?.toString() == 'admin'">
                      <div class="" placement="bottom-left" ngbDropdown>
                        <div ngbDropdownToggle class="d-flex flex-column align-items-center cursor-pointer">
                          <i class="fa fa-ellipsis-v p-2" aria-hidden="true"></i>
                        </div>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="removeee">
                          <!-- <button *ngIf="auth?.userData?.emp_info?.employee_role?.toString() == 'admin'" ngbDropdownItem (click)="editRightsEmp(editRights, item)">
                            <div class="d-flex align-items-center">
                              <i class="fa fa-check-square text-primary" aria-hidden="true"></i>
                              <span class="text-primary mx-2">{{'Edit'|translate}}</span>
                            </div>
                          </button> -->
                          <button (click)="removeParticipant(item?.emp_id)" ngbDropdownItem>
                            <div class="d-flex align-items-center">
                              <i class="fa fa-trash text-danger" aria-hidden="true"></i>
                              <span class="text-danger mx-2">{{'Remove'|translate}}</span>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="item?.employee_role?.toString().toLowerCase() !== 'admin' && item?.inactive_account == true" class="list-item p-2 d-flex justify-content-between">
                    <div class="d-flex align-items-center w-100">
                      <div class="list-img">
                        <!-- <img src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/header/Me Inactive.svg" alt=""> -->
                        <img class="shadow-sm img-40" [src]="item?.inactive_account_data?.user_profile_image?item?.inactive_account_data?.user_profile_image: '../../../../assets/Accountect/settingsweb/Group 40411.svg'" alt="">
                      </div>
                      <div class="list-text">
                        <span class="text-dark" *ngIf="item?.inactive_account_data?.first_name && item?.inactive_account_data?.last_name; else username">{{item?.inactive_account_data?.first_name}} {{item?.inactive_account_data?.last_name}}</span>
                        <ng-template #username>
                          <span class="text-dark">{{item?.inactive_account_data?.username}}</span>
                        </ng-template>
                        <p class="text-light m-0 font-weight-500 f-14">{{ledgerService.selectedTrans?.ledger_member_data?.business?.business_name}}</p>
                      </div>
                    </div>
                    <div class="list-time my-auto"  *ngIf="auth?.userData?.emp_info?.employee_role?.toString() == 'admin'">
                      <div class="" placement="bottom-left" ngbDropdown>
                        <div ngbDropdownToggle class="d-flex flex-column align-items-center cursor-pointer">
                          <i class="fa fa-ellipsis-v p-2" aria-hidden="true"></i>
                        </div>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="removeee">
                          <!-- <button *ngIf="auth?.userData?.emp_info?.employee_role?.toString() == 'admin'" ngbDropdownItem (click)="editRightsEmp(editRights, item)">
                            <div class="d-flex align-items-center">
                              <i class="fa fa-check-square text-primary" aria-hidden="true"></i>
                              <span class="text-primary mx-2">{{'Edit'|translate}}</span>
                            </div>
                          </button> -->
                          <button (click)="removeParticipant(item?.inactive_account_data?.emp_id)" ngbDropdownItem>
                            <div class="d-flex align-items-center">
                              <i class="fa fa-trash text-danger" aria-hidden="true"></i>
                              <span class="text-danger mx-2">{{'Remove'|translate}}</span>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="ledgerService.SaleData?.sale_room_member?.members?.length<1">
                  <div class="d-flex align-items-center flex-column text-center justify-content-center w-100 p-5">
                    <img src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/ledger/Invite business in ledger.svg" alt="">
                    <span *ngIf="build_type === 'ciwac'"  class="text-light mt-2">{{'You have no network members on Accountect'|translate}}</span>
                    <span *ngIf="build_type === 'niwac'"  class="text-light mt-2">{{'You have no network members on Niwac'|translate}}</span>
                  </div>
                </ng-container>
              </div>
              <div class="d-flex justify-content-between pt-3 b-t-light w-100 mt-4">
                <div class="">
                    <span
                        class="text-primary f-20 font-weight-bold">{{ledgerService.SaleData?.sale_room_member?.members.length}}
                    </span>
                    <span *ngIf="ledgerService.SaleData?.sale_room_member?.members.length <= 1"
                        class="text-primary f-18">{{'Member'|translate}}</span>
                    <span *ngIf="ledgerService.SaleData?.sale_room_member?.members.length > 1"
                        class="text-primary f-18">{{'Members'|translate}}</span>
                </div>
                <div class="add-btn cursor-pointer m-0 d-flex justify-content-between br-light"
                                            *ngIf="auth?.userData?.emp_info?.employee_role === 'admin'"
                                            (click)="openModalAddMember(AddMember, 'Active')">
                                            <div>
                                                <img src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/updated/Add.svg" class="w-100 img-40">
                                            </div>
                                            <div class="my-auto">
                                                <span class="mx-2 text-primary">{{'Add Member'|translate}}</span>
                                            </div>

                                        </div>
            </div>
            </ng-container>
            <ng-container *ngIf="ParticipentType === 'purchase'">
              <ng-container *ngFor="let item of ledgerService.PurchaseTrans?.purchase_room_member?.members">
                <div *ngIf="item?.employee_role?.toString().toLowerCase() === 'admin'" class="list-item list-admin d-flex justify-content-between">
                  <div class="d-flex align-items-center">
                    <div class="list-img">
                      <img class="shadow-sm img-40"  [src]="item?.user_profile_image?item?.user_profile_image: '../../../../assets/Accountect/settingsweb/Group 40411.svg'" alt="">
                    </div>
                    <div class="list-text">
                      <span class="text-dark">{{item?.first_name}} {{item?.last_name}}</span>
                      <p class="text-light m-0 font-weight-500 f-14">{{ledgerService.selectedTrans?.ledger_member_data?.business?.business_name}}</p>
                    </div>
                  </div>
                  <div class="admin my-auto">
                    <span class="text-primary mx-2 font-weight-500">{{'Admin'|translate}}</span>
                  </div>
                </div>
              </ng-container>
              <div class="overflow-part">
                <ng-container *ngFor="let item of ledgerService.PurchaseTrans?.purchase_room_member?.members">
                  <div *ngIf="item?.employee_role?.toString().toLowerCase() !== 'admin' && item?.inactive_account == false" class="list-item p-2 d-flex justify-content-between">
                    <div class="d-flex align-items-center w-100">
                      <div class="list-img">
                        <img class="shadow-sm img-40" [src]="item?.user_profile_image?item?.user_profile_image: '../../../../assets/Accountect/settingsweb/Group 40411.svg'" alt="">
                      </div>
                      <div class="list-text">
                        <span class="text-dark" *ngIf="item?.first_name && item?.last_name; else username">{{item?.first_name}} {{item?.last_name}}</span>
                        <ng-template #username>
                          <span class="text-dark">{{item?.username}}</span>
                        </ng-template>
                        <p class="text-light m-0 font-weight-500 f-14">{{ledgerService.selectedTrans?.ledger_member_data?.business?.business_name}}</p>
                      </div>
                    </div>
                    <div class="list-time my-auto"  *ngIf="auth?.userData?.emp_info?.employee_role?.toString() == 'admin'">
                      <div class="" placement="bottom-left" ngbDropdown>
                        <div ngbDropdownToggle class="d-flex flex-column align-items-center cursor-pointer">
                          <i class="fa fa-ellipsis-v p-2" aria-hidden="true"></i>
                        </div>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="removeee">
                          <!-- <button *ngIf="auth?.userData?.emp_info?.employee_role?.toString() == 'admin'" ngbDropdownItem (click)="editRightsEmp(editRights, item)">
                            <div class="d-flex align-items-center">
                              <i class="fa fa-check-square text-primary" aria-hidden="true"></i>
                              <span class="text-primary mx-2">{{'Edit'|translate}}</span>
                            </div>
                          </button> -->
                          <button (click)="removeParticipant(item?.emp_id)" ngbDropdownItem>
                            <div class="d-flex align-items-center">
                              <i class="fa fa-trash text-danger" aria-hidden="true"></i>
                              <span class="text-danger mx-2">{{'Remove'|translate}}</span>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="item?.employee_role?.toString().toLowerCase() !== 'admin' && item?.inactive_account == true" class="list-item p-2 d-flex justify-content-between">
                    <div class="d-flex align-items-center w-100">
                      <div class="list-img">
                        <!-- <img src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/header/Me Inactive.svg" alt=""> -->
                        <img class="shadow-sm img-40" [src]="item?.inactive_account_data?.user_profile_image?item?.inactive_account_data?.user_profile_image: '../../../../assets/Accountect/settingsweb/Group 40411.svg'" alt="">
                      </div>
                      <div class="list-text">
                        <span class="text-dark" *ngIf="item?.inactive_account_data?.first_name && item?.inactive_account_data?.last_name; else username">{{item?.inactive_account_data?.first_name}} {{item?.inactive_account_data?.last_name}}</span>
                        <ng-template #username>
                          <span class="text-dark">{{item?.inactive_account_data?.username}}</span>
                        </ng-template>
                        <p class="text-light m-0 font-weight-500 f-14">{{ledgerService.selectedTrans?.ledger_member_data?.business?.business_name}}</p>
                      </div>
                    </div>
                    <div class="list-time my-auto"  *ngIf="auth?.userData?.emp_info?.employee_role?.toString() == 'admin'">
                      <div class="" placement="bottom-left" ngbDropdown>
                        <div ngbDropdownToggle class="d-flex flex-column align-items-center cursor-pointer">
                          <i class="fa fa-ellipsis-v p-2" aria-hidden="true"></i>
                        </div>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="removeee">
                          <!-- <button *ngIf="auth?.userData?.emp_info?.employee_role?.toString() == 'admin'" ngbDropdownItem (click)="editRightsEmp(editRights, item)">
                            <div class="d-flex align-items-center">
                              <i class="fa fa-check-square text-primary" aria-hidden="true"></i>
                              <span class="text-primary mx-2">{{'Edit'|translate}}</span>
                            </div>
                          </button> -->
                          <button (click)="removeParticipant(item?.inactive_account_data?.emp_id)" ngbDropdownItem>
                            <div class="d-flex align-items-center">
                              <i class="fa fa-trash text-danger" aria-hidden="true"></i>
                              <span class="text-danger mx-2">{{'Remove'|translate}}</span>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="ledgerService.PurchaseTrans?.purchase_room_member?.members?.length<1">
                  <div class="d-flex align-items-center flex-column text-center justify-content-center w-100 p-5">
                    <img src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/ledger/Invite business in ledger.svg" alt="">
                    <span *ngIf="build_type === 'ciwac'" class="text-light mt-2">{{'You have no network members on Accountect'|translate}}</span>
                    <span *ngIf="build_type === 'niwac'" class="text-light mt-2">{{'You have no network members on Niwac'|translate}}</span>
                  </div>
                </ng-container>
              </div>
              <div class="d-flex justify-content-between pt-3 b-t-light w-100 mt-4">
                <div class="">
                    <span
                        class="text-primary f-20 font-weight-bold">{{ledgerService.PurchaseTrans?.purchase_room_member?.members.length}}
                    </span>
                    <span *ngIf="ledgerService.PurchaseTrans?.purchase_room_member?.members.length <= 1"
                        class="text-primary f-18">{{'Member'|translate}}</span>
                    <span *ngIf="ledgerService.PurchaseTrans?.purchase_room_member?.members.length > 1"
                        class="text-primary f-18">{{'Members'|translate}}</span>
                </div>
                <div class="add-btn cursor-pointer m-0 d-flex justify-content-between br-light"
                                            *ngIf="auth?.userData?.emp_info?.employee_role === 'admin'"
                                            (click)="openModalAddMember(AddMember, 'Active')">
                                            <div>
                                                <img src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/updated/Add.svg" class="w-100 img-40">
                                            </div>
                                            <div class="my-auto">
                                                <span class="mx-2 text-primary">{{'Add Member'|translate}}</span>
                                            </div>

                                        </div>
            </div>
            </ng-container>
            <ng-container *ngIf="ParticipentType === 'expense'">
              <ng-container *ngFor="let item of ledgerService.Expense?.expense_room_member?.members">
                <div *ngIf="item?.employee_role?.toString().toLowerCase() === 'admin'" class="list-item list-admin d-flex justify-content-between">
                  <div class="d-flex align-items-center">
                    <div class="list-img">
                      <img class="shadow-sm img-40"  [src]="item?.user_profile_image?item?.user_profile_image: '../../../../assets/Accountect/settingsweb/Group 40411.svg'" alt="">
                    </div>
                    <div class="list-text">
                      <span class="text-dark">{{item?.first_name}} {{item?.last_name}}</span>
                      <p class="text-light m-0 font-weight-500 f-14">{{ledgerService.selectedTrans?.ledger_member_data?.business?.business_name}}</p>
                    </div>
                  </div>
                  <div class="admin my-auto">
                    <span class="text-primary mx-2 font-weight-500">{{'Admin'|translate}}</span>
                  </div>
                </div>
              </ng-container>
              <div class="overflow-part">
                <ng-container *ngFor="let item of ledgerService.Expense?.expense_room_member?.members">
                  <div *ngIf="item?.employee_role?.toString().toLowerCase() !== 'admin' && item?.inactive_account == false" class="list-item p-2 d-flex justify-content-between">
                    <div class="d-flex align-items-center w-100">
                      <div class="list-img">
                        <!-- <img src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/header/Me Inactive.svg" alt=""> -->
                        <img class="shadow-sm img-40" [src]="item?.user_profile_image?item?.user_profile_image: '../../../../assets/Accountect/settingsweb/Group 40411.svg'" alt="">
                      </div>
                      <div class="list-text">
                        <span class="text-dark" *ngIf="item?.first_name && item?.last_name; else username">{{item?.first_name}} {{item?.last_name}}</span>
                        <ng-template #username>
                          <span class="text-dark">{{item?.username}}</span>
                        </ng-template>
                        <p class="text-light m-0 font-weight-500 f-14">{{ledgerService.selectedTrans?.ledger_member_data?.business?.business_name}}</p>
                      </div>
                    </div>
                    <div class="list-time my-auto" *ngIf="auth?.userData?.emp_info?.employee_role?.toString() == 'admin'">
                      <div class="" placement="bottom-left" ngbDropdown>
                        <div ngbDropdownToggle class="d-flex flex-column align-items-center cursor-pointer">
                          <i class="fa fa-ellipsis-v p-2" aria-hidden="true"></i>
                        </div>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="removeee">
                          <!-- <button *ngIf="auth?.userData?.emp_info?.employee_role?.toString() == 'admin'" ngbDropdownItem (click)="editRightsEmp(editRights, item)">
                            <div class="d-flex align-items-center">
                              <i class="fa fa-check-square text-primary" aria-hidden="true"></i>
                              <span class="text-primary mx-2">{{'Edit'|translate}}</span>
                            </div>
                          </button> -->
                          <button (click)="removeParticipant(item?.emp_id)" ngbDropdownItem>
                            <div class="d-flex align-items-center">
                              <i class="fa fa-trash text-danger" aria-hidden="true"></i>
                              <span class="text-danger mx-2">{{'Remove'|translate}}</span>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="item?.employee_role?.toString().toLowerCase() !== 'admin' && item?.inactive_account == true" class="list-item p-2 d-flex justify-content-between">
                    <div class="d-flex align-items-center w-100">
                      <div class="list-img">
                        <!-- <img src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/header/Me Inactive.svg" alt=""> -->
                        <img class="shadow-sm img-40" [src]="item?.inactive_account_data?.user_profile_image?item?.inactive_account_data?.user_profile_image: '../../../../assets/Accountect/settingsweb/Group 40411.svg'" alt="">
                      </div>
                      <div class="list-text">
                        <span class="text-dark" *ngIf="item?.inactive_account_data?.first_name && item?.inactive_account_data?.last_name; else username">{{item?.inactive_account_data?.first_name}} {{item?.inactive_account_data?.last_name}}</span>
                        <ng-template #username>
                          <span class="text-dark">{{item?.inactive_account_data?.username}}</span>
                        </ng-template>
                        <p class="text-light m-0 font-weight-500 f-14">{{ledgerService.selectedTrans?.ledger_member_data?.business?.business_name}}</p>
                      </div>
                    </div>
                    <div class="list-time my-auto" *ngIf="auth?.userData?.emp_info?.employee_role?.toString() == 'admin'">
                      <div class="" placement="bottom-left" ngbDropdown>
                        <div ngbDropdownToggle class="d-flex flex-column align-items-center cursor-pointer">
                          <i class="fa fa-ellipsis-v p-2" aria-hidden="true"></i>
                        </div>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="removeee">
                          <!-- <button *ngIf="auth?.userData?.emp_info?.employee_role?.toString() == 'admin'" ngbDropdownItem (click)="editRightsEmp(editRights, item)">
                            <div class="d-flex align-items-center">
                              <i class="fa fa-check-square text-primary" aria-hidden="true"></i>
                              <span class="text-primary mx-2">{{'Edit'|translate}}</span>
                            </div>
                          </button> -->
                          <button (click)="removeParticipant(item?.inactive_account_data?.emp_id)" ngbDropdownItem>
                            <div class="d-flex align-items-center">
                              <i class="fa fa-trash text-danger" aria-hidden="true"></i>
                              <span class="text-danger mx-2">{{'Remove'|translate}}</span>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="ledgerService.Expense?.expense_room_member?.members?.length<1">
                  <div class="d-flex align-items-center flex-column text-center justify-content-center w-100 p-5">
                    <img src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/ledger/Invite business in ledger.svg" alt="">
                    <span  *ngIf="build_type === 'ciwac'" class="text-light mt-2">{{'You have no network members on Accountect'|translate}}</span>
                    <span  *ngIf="build_type === 'niwac'" class="text-light mt-2">{{'You have no network members on Niwac'|translate}}</span>
                  </div>
                </ng-container>
              </div>
              <div class="d-flex justify-content-between pt-3 b-t-light w-100 mt-4">
                <div class="">
                    <span
                        class="text-primary f-20 font-weight-bold">{{ledgerService.Expense?.expense_room_member?.members.length}}
                    </span>
                    <span *ngIf="ledgerService.Expense?.expense_room_member?.members.length <= 1"
                        class="text-primary f-18">{{'Member'|translate}}</span>
                    <span *ngIf="ledgerService.Expense?.expense_room_member?.members.length > 1"
                        class="text-primary f-18">{{'Members'|translate}}</span>
                </div>
                <div class="add-btn cursor-pointer m-0 d-flex justify-content-between br-light"
                                            *ngIf="auth?.userData?.emp_info?.employee_role === 'admin'"
                                            (click)="openModalAddMember(AddMember, 'Active')">
                                            <div>
                                                <img src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/updated/Add.svg" class="w-100 img-40">
                                            </div>
                                            <div class="my-auto">
                                                <span class="mx-2 text-primary">{{'Add Member'|translate}}</span>
                                            </div>

                                        </div>
            </div>
            </ng-container>
            <ng-container *ngIf="ParticipentType === 'inventory'">
              <div *ngIf="ledgerService?.InventoryPro?.products_data?.length > 0">
                <ng-container *ngFor="let item of ledgerService.InventoryPro?.product_room_member?.members">
                  <div *ngIf="item?.employee_role?.toString().toLowerCase() === 'admin'" class="list-item list-admin d-flex justify-content-between">
                    <div class="d-flex align-items-center">
                      <div class="list-img">
                        <img class="shadow-sm img-40"  [src]="item?.user_profile_image?item?.user_profile_image: '../../../../assets/Accountect/settingsweb/Group 40411.svg'" alt="">
                      </div>
                      <div class="list-text">
                        <span class="text-dark">{{item?.first_name}} {{item?.last_name}}</span>
                        <p class="text-light m-0 font-weight-500 f-14">{{ledgerService.selectedTrans?.ledger_member_data?.business?.business_name}}</p>
                      </div>
                    </div>
                    <div class="admin my-auto">
                      <span class="text-primary mx-2 font-weight-500">{{'Admin'|translate}}</span>
                    </div>
                  </div>
                </ng-container>
                <div class="overflow-part">
                  <ng-container *ngFor="let item of ledgerService.InventoryPro?.product_room_member?.members">
                    <div *ngIf="item?.employee_role?.toString().toLowerCase() !== 'admin' && item?.inactive_account == false" class="list-item p-2 d-flex justify-content-between">
                      <div class="d-flex align-items-center w-100">
                        <div class="list-img">
                          <!-- <img src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/header/Me Inactive.svg" alt=""> -->
                          <img class="shadow-sm img-40" [src]="item?.user_profile_image?item?.user_profile_image: '../../../../assets/Accountect/settingsweb/Group 40411.svg'" alt="">
                        </div>
                        <div class="list-text">
                          <span class="text-dark" *ngIf="item?.first_name && item?.last_name; else username">{{item?.first_name}} {{item?.last_name}}</span>
                          <ng-template #username>
                            <span class="text-dark">{{item?.username}}</span>
                          </ng-template>
                          <p class="text-light m-0 font-weight-500 f-14">{{ledgerService.selectedTrans?.ledger_member_data?.business?.business_name}}</p>
                        </div>
                      </div>
                      <div class="list-time my-auto"  *ngIf="auth?.userData?.emp_info?.employee_role?.toString() == 'admin'">
                        <div class="" placement="bottom-left" ngbDropdown>
                          <div ngbDropdownToggle class="d-flex flex-column align-items-center cursor-pointer">
                            <i class="fa fa-ellipsis-v p-2" aria-hidden="true"></i>
                          </div>
                          <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="removeee">
                            <!-- <button *ngIf="auth?.userData?.emp_info?.employee_role?.toString() == 'admin'" ngbDropdownItem (click)="editRightsEmp(editRights, item)">
                              <div class="d-flex align-items-center">
                                <i class="fa fa-check-square text-primary" aria-hidden="true"></i>
                                <span class="text-primary mx-2">{{'Edit'|translate}}</span>
                              </div>
                            </button> -->
                            <button (click)="removeParticipant(item?.emp_id)" ngbDropdownItem>
                              <div class="d-flex align-items-center">
                                <i class="fa fa-trash text-danger" aria-hidden="true"></i>
                                <span class="text-danger mx-2">{{'Remove'|translate}}</span>
                              </div>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="item?.employee_role?.toString().toLowerCase() !== 'admin' && item?.inactive_account == true" class="list-item p-2 d-flex justify-content-between">
                      <div class="d-flex align-items-center w-100">
                        <div class="list-img">
                          <!-- <img src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/header/Me Inactive.svg" alt=""> -->
                          <img class="shadow-sm img-40" [src]="item?.inactive_account_data?.user_profile_image?item?.inactive_account_data?.user_profile_image: '../../../../assets/Accountect/settingsweb/Group 40411.svg'" alt="">
                        </div>
                        <div class="list-text">
                          <span class="text-dark" *ngIf="item?.inactive_account_data?.first_name && item?.inactive_account_data?.last_name; else username">{{item?.inactive_account_data?.first_name}} {{item?.inactive_account_data?.last_name}}</span>
                          <ng-template #username>
                            <span class="text-dark">{{item?.inactive_account_data?.username}}</span>
                          </ng-template>
                          <p class="text-light m-0 font-weight-500 f-14">{{ledgerService.selectedTrans?.ledger_member_data?.business?.business_name}}</p>
                        </div>
                      </div>
                      <div class="list-time my-auto"  *ngIf="auth?.userData?.emp_info?.employee_role?.toString() == 'admin'">
                        <div class="" placement="bottom-left" ngbDropdown>
                          <div ngbDropdownToggle class="d-flex flex-column align-items-center cursor-pointer">
                            <i class="fa fa-ellipsis-v p-2" aria-hidden="true"></i>
                          </div>
                          <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="removeee">
                            <!-- <button *ngIf="auth?.userData?.emp_info?.employee_role?.toString() == 'admin'" ngbDropdownItem (click)="editRightsEmp(editRights, item)">
                              <div class="d-flex align-items-center">
                                <i class="fa fa-check-square text-primary" aria-hidden="true"></i>
                                <span class="text-primary mx-2">{{'Edit'|translate}}</span>
                              </div>
                            </button> -->
                            <button (click)="removeParticipant(item?.inactive_account_data?.emp_id)" ngbDropdownItem>
                              <div class="d-flex align-items-center">
                                <i class="fa fa-trash text-danger" aria-hidden="true"></i>
                                <span class="text-danger mx-2">{{'Remove'|translate}}</span>
                              </div>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="ledgerService.InventoryPro?.product_room_member?.members?.length<1">
                    <div class="d-flex align-items-center flex-column text-center justify-content-center w-100 p-5">
                      <img src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/ledger/Invite business in ledger.svg" alt="">
                      <span *ngIf="build_type === 'ciwac'" class="text-light mt-2">{{'You have no network members on Accountect'|translate}}</span>
                      <span *ngIf="build_type === 'niwac'" class="text-light mt-2">{{'You have no network members on Niwac'|translate}}</span>
                    </div>
                  </ng-container>
                </div>
              </div>
              <div class="overflow-part" *ngIf="ledgerService?.InventoryPro?.products_data?.length === 0"></div>
              <div class="d-flex justify-content-between pt-3 b-t-light w-100 mt-4">
                <div class="">
                    <span *ngIf="ledgerService?.InventoryPro?.products_data?.length > 0"
                        class="text-primary f-20 font-weight-bold">{{ledgerService.InventoryPro?.product_room_member?.members.length}}
                    </span>
                    <span *ngIf="ledgerService?.InventoryPro?.products_data?.length > 0 && ledgerService.InventoryPro?.product_room_member?.members.length <= 1"
                        class="text-primary f-18">{{'Member'|translate}}</span>
                    <span *ngIf="ledgerService?.InventoryPro?.products_data?.length > 0 && ledgerService.productStock?.product_room_member?.members.length > 1"
                        class="text-primary f-18">{{'Members'|translate}}</span>
                </div>
                <div class="add-btn cursor-pointer m-0 d-flex justify-content-between br-light"
                                            *ngIf="auth?.userData?.emp_info?.employee_role === 'admin'"
                                            (click)="openModalAddMember(AddMember, 'Active')">
                                            <div>
                                                <img src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/updated/Add.svg" class="w-100 img-40">
                                            </div>
                                            <div class="my-auto">
                                                <span class="mx-2 text-primary">{{'Add Member'|translate}}</span>
                                            </div>

                                        </div>
            </div>
            </ng-container>
            <ng-container *ngIf="ParticipentType === 'inv'">
              <ng-container *ngFor="let item of ledgerService.ProDataByRef?.product_room_member?.members">
                <div *ngIf="item?.employee_role?.toString().toLowerCase() === 'admin'" class="list-item list-admin d-flex justify-content-between">
                  <div class="d-flex align-items-center">
                    <div class="list-img">
                      <img class="shadow-sm img-40"  [src]="item?.user_profile_image?item?.user_profile_image: '../../../../assets/Accountect/settingsweb/Group 40411.svg'" alt="">
                    </div>
                    <div class="list-text">
                      <span class="text-dark">{{item?.first_name}} {{item?.last_name}}</span>
                      <p class="text-light m-0 font-weight-500 f-14">{{ledgerService.selectedTrans?.ledger_member_data?.business?.business_name}}</p>
                    </div>
                  </div>
                  <div class="admin my-auto">
                    <span class="text-primary mx-2 font-weight-500">{{'Admin'|translate}}</span>
                  </div>
                </div>
              </ng-container>
              <div class="overflow-part">
                <ng-container *ngFor="let item of ledgerService.ProDataByRef?.product_room_member?.members">
                  <div *ngIf="item?.employee_role?.toString().toLowerCase() !== 'admin' && item?.inactive_account == false" class="list-item p-2 d-flex justify-content-between">
                    <div class="d-flex align-items-center w-100">
                      <div class="list-img">
                        <!-- <img src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/header/Me Inactive.svg" alt=""> -->
                        <img class="shadow-sm img-40" [src]="item?.user_profile_image?item?.user_profile_image: '../../../../assets/Accountect/settingsweb/Group 40411.svg'" alt="">
                      </div>
                      <div class="list-text">
                        <span class="text-dark" *ngIf="item?.first_name && item?.last_name; else username">{{item?.first_name}} {{item?.last_name}}</span>
                        <ng-template #username>
                          <span class="text-dark">{{item?.username}}</span>
                        </ng-template>
                        <p class="text-light m-0 font-weight-500 f-14">{{ledgerService.selectedTrans?.ledger_member_data?.business?.business_name}}</p>
                      </div>
                    </div>
                    <div class="list-time my-auto"  *ngIf="auth?.userData?.emp_info?.employee_role?.toString() == 'admin'">
                      <div class="" placement="bottom-left" ngbDropdown>
                        <div ngbDropdownToggle class="d-flex flex-column align-items-center cursor-pointer">
                          <i class="fa fa-ellipsis-v p-2" aria-hidden="true"></i>
                        </div>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="removeee">
                          <!-- <button *ngIf="auth?.userData?.emp_info?.employee_role?.toString() == 'admin'" ngbDropdownItem (click)="editRightsEmp(editRights, item)">
                            <div class="d-flex align-items-center">
                              <i class="fa fa-check-square text-primary" aria-hidden="true"></i>
                              <span class="text-primary mx-2">{{'Edit'|translate}}</span>
                            </div>
                          </button> -->
                          <button (click)="removeParticipant(item?.emp_id)" ngbDropdownItem>
                            <div class="d-flex align-items-center">
                              <i class="fa fa-trash text-danger" aria-hidden="true"></i>
                              <span class="text-danger mx-2">{{'Remove'|translate}}</span>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="item?.employee_role?.toString().toLowerCase() !== 'admin' && item?.inactive_account == true" class="list-item p-2 d-flex justify-content-between">
                    <div class="d-flex align-items-center w-100">
                      <div class="list-img">
                        <!-- <img src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/header/Me Inactive.svg" alt=""> -->
                        <img class="shadow-sm img-40" [src]="item?.inactive_account_data?.user_profile_image?item?.inactive_account_data?.user_profile_image: '../../../../assets/Accountect/settingsweb/Group 40411.svg'" alt="">
                      </div>
                      <div class="list-text">
                        <span class="text-dark" *ngIf="item?.inactive_account_data?.first_name && item?.inactive_account_data?.last_name; else username">{{item?.inactive_account_data?.first_name}} {{item?.inactive_account_data?.last_name}}</span>
                        <ng-template #username>
                          <span class="text-dark">{{item?.inactive_account_data?.username}}</span>
                        </ng-template>
                        <p class="text-light m-0 font-weight-500 f-14">{{ledgerService.selectedTrans?.ledger_member_data?.business?.business_name}}</p>
                      </div>
                    </div>
                    <div class="list-time my-auto"  *ngIf="auth?.userData?.emp_info?.employee_role?.toString() == 'admin'">
                      <div class="" placement="bottom-left" ngbDropdown>
                        <div ngbDropdownToggle class="d-flex flex-column align-items-center cursor-pointer">
                          <i class="fa fa-ellipsis-v p-2" aria-hidden="true"></i>
                        </div>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="removeee">
                          <!-- <button *ngIf="auth?.userData?.emp_info?.employee_role?.toString() == 'admin'" ngbDropdownItem (click)="editRightsEmp(editRights, item)">
                            <div class="d-flex align-items-center">
                              <i class="fa fa-check-square text-primary" aria-hidden="true"></i>
                              <span class="text-primary mx-2">{{'Edit'|translate}}</span>
                            </div>
                          </button> -->
                          <button (click)="removeParticipant(item?.inactive_account_data?.emp_id)" ngbDropdownItem>
                            <div class="d-flex align-items-center">
                              <i class="fa fa-trash text-danger" aria-hidden="true"></i>
                              <span class="text-danger mx-2">{{'Remove'|translate}}</span>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="ledgerService.ProDataByRef?.product_room_member?.members?.length<1">
                  <div class="d-flex align-items-center flex-column text-center justify-content-center w-100 p-5">
                    <img src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/ledger/Invite business in ledger.svg" alt="">
                    <span *ngIf="build_type === 'ciwac'" class="text-light mt-2">{{'You have no network members on Accountect'|translate}}</span>
                    <span *ngIf="build_type === 'niwac'" class="text-light mt-2">{{'You have no network members on Niwac'|translate}}</span>
                  </div>
                </ng-container>
              </div>
              <div class="d-flex justify-content-between pt-3 b-t-light w-100 mt-4">
                <div class="">
                    <span
                        class="text-primary f-20 font-weight-bold">{{ledgerService.ProDataByRef?.product_room_member?.members.length}}
                    </span>
                    <span *ngIf="ledgerService.ProDataByRef?.product_room_member?.members.length <= 1"
                        class="text-primary f-18">{{'Member'|translate}}</span>
                    <span *ngIf="ledgerService.ProDataByRef?.product_room_member?.members.length > 1"
                        class="text-primary f-18">{{'Members'|translate}}</span>
                </div>
                <div class="add-btn cursor-pointer m-0 d-flex justify-content-between br-light"
                                            *ngIf="auth?.userData?.emp_info?.employee_role === 'admin'"
                                            (click)="openModalAddMember(AddMember, 'Active')">
                                            <div>
                                                <img src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/updated/Add.svg" class="w-100 img-40">
                                            </div>
                                            <div class="my-auto">
                                                <span class="mx-2 text-primary">{{'Add Member'|translate}}</span>
                                            </div>

                                        </div>
            </div>
            </ng-container>
            <ng-container *ngIf="ParticipentType === 'salary'">
              <ng-container *ngFor="let item of employeeService?.employeesData?.salary_room_members?.members">
                <div *ngIf="item?.employee_role?.toString().toLowerCase() === 'admin'" class="list-item list-admin d-flex justify-content-between">
                  <div class="d-flex align-items-center">
                    <div class="list-img">
                      <img class="shadow-sm img-40"  [src]="item?.user_profile_image?item?.user_profile_image: '../../../../assets/Accountect/settingsweb/Group 40411.svg'" alt="">
                    </div>
                    <div class="list-text">
                      <span class="text-dark">{{item?.first_name}} {{item?.last_name}}</span>
                      <!-- <p class="text-light m-0 font-weight-500 f-14">{{ledgerService.selectedTrans?.ledger_member_data?.business?.business_name}}</p> -->
                    </div>
                  </div>
                  <div class="admin my-auto">
                    <span class="text-primary mx-2 font-weight-500">{{'Admin'|translate}}</span>
                  </div>
                </div>
              </ng-container>
              <div class="overflow-part">
                <ng-container *ngFor="let item of employeeService?.employeesData?.salary_room_members?.members">
                  <div *ngIf="item?.employee_role?.toString().toLowerCase() !== 'admin' && item?.inactive_account == false" class="list-item p-2 d-flex justify-content-between">
                    <div class="d-flex align-items-center w-100">
                      <div class="list-img">
                        <!-- <img src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/header/Me Inactive.svg" alt=""> -->
                        <img class="shadow-sm img-40" [src]="item?.user_profile_image?item?.user_profile_image: '../../../../assets/Accountect/settingsweb/Group 40411.svg'" alt="">
                      </div>
                      <div class="list-text">
                        <span class="text-dark" *ngIf="item?.first_name && item?.last_name; else username">{{item?.first_name}} {{item?.last_name}}</span>
                        <ng-template #username>
                          <span class="text-dark">{{item?.username}}</span>
                        </ng-template>
                        <!-- <p class="text-light m-0 font-weight-500 f-14">{{ledgerService.selectedTrans?.ledger_member_data?.business?.business_name}}</p> -->
                      </div>
                    </div>
                    <div class="list-time my-auto"  *ngIf="auth?.userData?.emp_info?.employee_role?.toString() == 'admin'">
                      <div class="" placement="bottom-left" ngbDropdown>
                        <div ngbDropdownToggle class="d-flex flex-column align-items-center cursor-pointer">
                          <i class="fa fa-ellipsis-v p-2" aria-hidden="true"></i>
                        </div>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="removeee">
                          <!-- <button *ngIf="auth?.userData?.emp_info?.employee_role?.toString() == 'admin'" ngbDropdownItem (click)="editRightsEmp(editRights, item)">
                            <div class="d-flex align-items-center">
                              <i class="fa fa-check-square text-primary" aria-hidden="true"></i>
                              <span class="text-primary mx-2">{{'Edit'|translate}}</span>
                            </div>
                          </button> -->
                          <button (click)="removeParticipant(item?.emp_id)" ngbDropdownItem>
                            <div class="d-flex align-items-center">
                              <i class="fa fa-trash text-danger" aria-hidden="true"></i>
                              <span class="text-danger mx-2">{{'Remove'|translate}}</span>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="item?.employee_role?.toString().toLowerCase() !== 'admin' && item?.inactive_account == true" class="list-item p-2 d-flex justify-content-between">
                    <div class="d-flex align-items-center w-100">
                      <div class="list-img">
                        <!-- <img src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/header/Me Inactive.svg" alt=""> -->
                        <img class="shadow-sm img-40" [src]="item?.inactive_account_data?.user_profile_image?item?.inactive_account_data?.user_profile_image: '../../../../assets/Accountect/settingsweb/Group 40411.svg'" alt="">
                      </div>
                      <div class="list-text">
                        <span class="text-dark" *ngIf="item?.inactive_account_data?.first_name && item?.inactive_account_data?.last_name; else username">{{item?.inactive_account_data?.first_name}} {{item?.inactive_account_data?.last_name}}</span>
                        <ng-template #username>
                          <span class="text-dark">{{item?.inactive_account_data?.username}}</span>
                        </ng-template>
                        <!-- <p class="text-light m-0 font-weight-500 f-14">{{ledgerService.selectedTrans?.ledger_member_data?.business?.business_name}}</p> -->
                      </div>
                    </div>
                    <div class="list-time my-auto"  *ngIf="auth?.userData?.emp_info?.employee_role?.toString() == 'admin'">
                      <div class="" placement="bottom-left" ngbDropdown>
                        <div ngbDropdownToggle class="d-flex flex-column align-items-center cursor-pointer">
                          <i class="fa fa-ellipsis-v p-2" aria-hidden="true"></i>
                        </div>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="removeee">
                          <!-- <button *ngIf="auth?.userData?.emp_info?.employee_role?.toString() == 'admin'" ngbDropdownItem (click)="editRightsEmp(editRights, item)">
                            <div class="d-flex align-items-center">
                              <i class="fa fa-check-square text-primary" aria-hidden="true"></i>
                              <span class="text-primary mx-2">{{'Edit'|translate}}</span>
                            </div>
                          </button> -->
                          <button (click)="removeParticipant(item?.inactive_account_data?.emp_id)" ngbDropdownItem>
                            <div class="d-flex align-items-center">
                              <i class="fa fa-trash text-danger" aria-hidden="true"></i>
                              <span class="text-danger mx-2">{{'Remove'|translate}}</span>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="employeeService?.employeesData?.salary_room_members?.members?.length<1">
                  <div class="d-flex align-items-center flex-column text-center justify-content-center w-100 p-5">
                    <img src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/ledger/Invite business in ledger.svg" alt="">
                    <span *ngIf="build_type === 'ciwac'" class="text-light mt-2">{{'You have no network members on Accountect'|translate}}</span>
                    <span *ngIf="build_type === 'niwac'" class="text-light mt-2">{{'You have no network members on Niwac'|translate}}</span>
                  </div>
                </ng-container>
              </div>
              <div class="d-flex justify-content-between pt-3 b-t-light w-100 mt-4">
                <div class="">
                    <span
                        class="text-primary f-20 font-weight-bold">{{employeeService?.employeesData?.salary_room_members?.members.length}}
                    </span>
                    <span *ngIf="employeeService?.employeesData?.salary_room_members?.members.length <= 1"
                        class="text-primary f-18">{{'Member'|translate}}</span>
                    <span *ngIf="employeeService?.employeesData?.salary_room_members?.members.length > 1"
                        class="text-primary f-18">{{'Members'|translate}}</span>
                </div>
                <div class="add-btn cursor-pointer m-0 d-flex justify-content-between br-light"
                                            *ngIf="auth?.userData?.emp_info?.employee_role === 'admin'"
                                            (click)="openModalAddMember(AddMember, 'Active')">
                                            <div>
                                                <img src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/updated/Add.svg" class="w-100 img-40">
                                            </div>
                                            <div class="my-auto">
                                                <span class="mx-2 text-primary">{{'Add Member'|translate}}</span>
                                            </div>

                                        </div>
            </div>
            </ng-container>
          </div>

        </div>
      </div>
    </div>
  </div>
  <ng-template #addParticipant let-modal>
    <div class="modal-ngb">
  
      <div class="modal-header p-1">
        <div class="w-100 px-3">
          <h6 class="text-dark font-weight-600 m-0 d-flex align-items-center">
            {{'Add Participant'|translate}}
          </h6>
        </div>
        <div>
          <span aria-hidden="true" (click)="modal.dismiss('Cross click')" class="modal-close-btn">×</span>
        </div>
      </div>
      <div class="modal-body ledger-debit-modal add-part">
        <div class="w-100">
          <form class="theme-form" autocomplete="off">
            <div class="w-100 mx-auto px-3 py-2">
              <div class="form-floating mb-3">
                <input class="form-control form-control-sm" id="floatingInput" type="text"
                  [placeholder]="'Search User'|translate" (keyup)="ledgerService?.searchMember($event, ParticipentType)">
                <label for="floatingInput" class="float-input-custom text-light"><span>{{'Search User'|translate}}</span></label>
              </div>
            </div>
          </form>
          <div class="w-100 mx-auto business-list-overflow px-3">
            <ng-container *ngIf="!ledgerService?.loadBusi">
              <div *ngFor="let item of ledgerService?.memberslist" class="d-flex justify-content-between w-100 mx-auto b-b py-3">
                <div class="d-flex justify-content-start ">
                  <div>
                    <img class="img-50 img-42-border"
                      [src]="item?.user_profile_image?item?.user_profile_image: '../../../../assets/Me/Members.svg'">
                  </div>
                  <div class="mx-3 f-14">
                    <div>{{item.first_name}} {{item.last_name}}</div>
                    <div class="text-primary" *ngIf="item.email; else phone">{{item.email}}</div>
                    <ng-template #phone>
                      <div class="text-primary">{{item.full_phone_number}}</div>
                    </ng-template>
                  </div>
                </div>
                <div (click)="addnewParticipant(item)" class="add-btn cursor-pointer m-0">
                  <i class="fa fa-plus text-primary p-2" aria-hidden="true"></i>
                </div>
              </div>
              <ng-container *ngIf="ledgerService?.memberslist?.length<1">
                <div class="d-flex align-items-center flex-column text-center justify-content-center w-100 p-5">
                  <img class="cursor-pointer"  src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/ledger/Invite business in ledger.svg" alt="">
                  <span *ngIf="build_type === 'niwac'" class="text-light mt-2">{{'You have no network members on Niwac'|translate}}</span>
                  <span *ngIf="build_type === 'ciwac'" class="text-light mt-2">{{'You have no network members on Accountect'|translate}}</span>
                </div>
            </ng-container>
            </ng-container>
            <div *ngIf="ledgerService?.loadBusi" class="trans-loader d-flex align-items-center justify-content-center w-100">
              <div class="spinner-border text-primary" role="status">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #AddMember let-modal>
    <div class="modal-ngb">
        <div class="modal-header p-1">
            <div class="w-100 px-3  d-flex align-items-center justify-content-center">
                <div class="texxt-align-center">
                    <h6 *ngIf="type === 'Active'" class="text-dark font-weight-600 m-0 d-flex align-items-center">
                        {{'Add Member'|translate}}
                    </h6>
                    <h6 *ngIf="type === 'Inactive'" class="text-dark font-weight-600 m-0 d-flex align-items-center">
                        {{'Transfer Inactive Account'|translate}}
                    </h6>
                </div>
            </div>
            <div>
                <span aria-hidden="true" (click)="modal.dismiss('Cross click')" class="modal-close-btn">×</span>
            </div>
        </div>
        <div class="modal-body ledger-debit-modal">
            <div class="w-100">
                <div *ngIf="type === 'Active'  && build_type === 'ciwac'"
                    class="w-100 mx-auto px-3 d-flex align-items-center justify-content-between">
                    <div class=" d-flex align-items-center w-95 copyUrl p-2"
                        (click)="copySharedNetworkLink('https://web.accountect.com/authentication/register?from_username=' + auth.userData?.emp_info?.username)">
                        <h6 class="mb-0 text-dark f-16 text-ellipsis">
                            {{'https://web.accountect.com/authentication/register?from_username=' +
                            auth.userData?.emp_info?.username}}
                        </h6>
                    </div>
                    <div (click)="copySharedNetworkLink('https://web.accountect.com/authentication/register?from_username=' + auth.userData?.emp_info?.username)"
                        class="add-btn-copy cursor-pointer m-0">
                        <img class="img-20" src="../../../../assets/Accountect/copy.svg" />
                    </div>
                </div>
                <div *ngIf="type === 'Active'  && build_type === 'niwac'"
                    class="w-100 mx-auto px-3 d-flex align-items-center justify-content-between">
                    <div class=" d-flex align-items-center w-95 copyUrl p-2"
                        (click)="copySharedNetworkLink('https://www.niwac.cn/authentication/register?from_username=' + auth.userData?.emp_info?.username)">
                        <h6 class="mb-0 text-dark f-16 text-ellipsis">
                            {{'https://www.niwac.cn/authentication/register?from_username=' +
                            auth.userData?.emp_info?.username}}
                        </h6>
                    </div>
                    <div (click)="copySharedNetworkLink('https://www.niwac.cn/authentication/register?from_username=' + auth.userData?.emp_info?.username)"
                        class="add-btn-copy cursor-pointer m-0">
                        <img class="img-20" src="../../../../assets/Accountect/copy.svg" />
                    </div>
                </div>
                <div *ngIf="type === 'Inactive'"
                    class="w-100 mx-auto px-3 d-flex align-items-center justify-content-between">
                    <div class=" d-flex align-items-center w-95 copyUrl p-2" (click)="copySharedNetworkLink(shareLink)">
                        <h6 class="mb-0 text-dark f-16 text-ellipsis">
                            {{shareLink}}
                        </h6>
                    </div>
                    <div (click)="copySharedNetworkLink(shareLink)" class="add-btn-copy cursor-pointer m-0">
                        <img class="img-20" src="../../../../assets/Accountect/copy.svg" />
                    </div>
                </div>
                <!-- <div class="w-100 p-4">
                    <div
                        class="w-100 mx-auto xs-w-100 my-2 p-2 bg-white shadow connections cursor-pointer hover-opacity d-flex align-items-center">
                        <div class="d-flex align-items-center w-100" (click)="openModalInvite(invite)">
                            <div class="w-10 text-center">
                                <img class="img-24" src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/ledger/email.svg" alt="">
                            </div>
                            <span class="text-dark cursor-pointer f-w-bold">{{"Invite"|translate}}
                            </span>
                        </div>
                        <div>
                            <i class="fa fa-angle-right f-20 text-light f-w-500"></i>
                        </div>
                    </div>
                </div> -->
                <!-- <div class="w-100 px-4">
            <div class="w-100 mx-auto xs-w-100 my-2 p-2 bg-white shadow connections cursor-pointer hover-opacity d-flex align-items-center">
              <div class="d-flex align-items-center w-100" (click)="openModalInvite(invite)">
                <div class="w-10 text-center">
                    <img class="img-15"
                        src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/MeProfile/Phone.svg" alt="">
                </div>
                <span class="text-dark cursor-pointer f-w-bold">{{"Invite through Phone"}}
                </span>
              </div>
              <div>
                <i class="fa fa-angle-right f-20 text-light f-w-500"></i>
              </div>
            </div>
          </div> -->

                <!-- <div class="w-100 mx-auto my-4 invite-list-overflow px-3" *ngIf="type === 'Active'">
                    <div class="d-flex justify-content-between w-100 mx-auto b-b p-3"
                        (click)="addContactList(item, 'Active')" *ngFor="let item of syncContact">

                        <div class="d-flex justify-content-start ">
                            <div>
                                <img class="img-20 " src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/ledger/email.svg">
                            </div>
                            <div class="mx-3 f-14">
                                <div class="text-dark my-auto">{{item.email}}</div>
                            </div>
                        </div>
                        <div
                            class="bg-white d-flex align-items-center justify-content-center p-1 cursor-hover cc-connection">
                            <i *ngIf="contactsArray?.includes(item?.email)" class="fa fa-check text-primary f-16"
                                aria-hidden="true"></i>
                        </div>
                    </div>
                </div>
                <div class="w-100 mx-auto my-4 invite-list-overflow px-3" *ngIf="type === 'Inactive'">
                    <div class="d-flex justify-content-between w-100 mx-auto b-b p-3"
                        (click)="addContactList(item, 'Inactive')" *ngFor="let item of syncContact">

                        <div class="d-flex justify-content-start ">
                            <div>
                                <img class="img-20 " src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/ledger/email.svg">
                            </div>
                            <div class="mx-3 f-14">
                                <div class="text-dark my-auto">{{item.email}}</div>
                            </div>
                        </div>
                        <div
                            class="bg-white d-flex align-items-center justify-content-center p-1 cursor-hover cc-connection">
                            <i *ngIf="contactsArray?.includes(item?.email)" class="fa fa-check text-primary f-16"
                                aria-hidden="true"></i>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
        <!-- <div class="mt-3 d-flex cursor-pointer justify-content-center p-2 round-bottom border-top">
            <div class="d-flex flex-column w-80 mx-auto py-2"> </div>
            <div class="invite-btn-chat" *ngIf="type === 'Active'" (click)="AddMemberlink(contactsArray, 'Active')">
                <img src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/ledger/send.svg" class="img-60 b-none cursor-pointer hover-opacity" />
            </div>
            <div class="invite-btn-chat" *ngIf="type === 'Inactive'" (click)="AddMemberlink(contactsArray, 'Inactive')">
                <img src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/ledger/send.svg" class="img-60 b-none cursor-pointer hover-opacity" />
            </div>
        </div> -->
    </div>
</ng-template>
<ng-template #invite let-modal>
  <div class="modal-ngb">
      <div class="modal-header p-1">
          <div class="w-100 px-3  d-flex align-items-center justify-content-center">
              <!-- <div class="px-3">
          <img *ngIf="auth.targetLanguage === 'en'"  class="img-30" src="../../../../assets/Accountect/Back Button.svg">
          <img *ngIf="auth.targetLanguage != 'en'"  class="img-30" src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/tilt back.svg">
        </div> -->
              <div class="texxt-align-center">
                  <h6 class="text-dark font-weight-600 m-0 d-flex align-items-center">
                      {{"Send Connection Request Through Email"|translate}}
                  </h6>
              </div>
          </div>
          <div>
              <span aria-hidden="true" (click)="modal.dismiss('Cross click')" class="modal-close-btn">×</span>
          </div>
      </div>
      <div class="modal-body ledger-debit-modal">
          <div class="w-100 px-4 pt-3">
              <form class="theme-form" autocomplete="off" [formGroup]="syncForm">
                  <div class="">
                      <div class="form-floating mb-3 ">
                          <input type="email" class="form-control form-control-sm"
                              (keyup.enter)="addEmail()" id="floatingInput"
                              type="email" formControlName="email" [placeholder]="'Email'|translate">
                          <label for="floatingInput" class="float-input-custom text-light"><span>{{'Email'|translate}}</span></label>
                      </div>
                  </div>
              </form>
          </div>
          <!-- <div class="w-75 mx-auto d-flex align-items-center">
        <div class="w-20">
            <span class="text-light f-16 font-weight-bold">{{'Contacts'}}</span>
        </div>
        <div class="w-60">
            <hr>
        </div>
        <div class="w-20 text-right" >
            <span class="text-light f-16 font-weight-bold">{{'Sync'}}</span>
            <span class="mx-2">
                <i class="fa fa-refresh cursor-pointer"></i>
            </span>
        </div>
      </div> -->
          <!-- <div class="w-100">
        <div class="row m-0 sync-w-200">
          <div class="col-6 d-flex align-items-center justify-content-center px-0 mb-4">
              <div (click)="syncContactsFun('gmail')" class="w-200px shadow bg-white">
                  <img class="img-80" src="https://hiwac-nc.s3.us-east-2.amazonaws.com/images/gmailLogo.png"
                      alt="">
              </div>
          </div>
          <div class="col-6 d-flex align-items-center justify-content-center px-0 mb-4">
              <div (click)="syncContactsFun('yahoo')" class="w-200px shadow bg-white">
                  <img class="img-80" src="https://hiwac-nc.s3.us-east-2.amazonaws.com/images/yahooLogo.png"
                      alt="">
              </div>
          </div>
          <div class="col-6 d-flex align-items-center justify-content-center px-0">
              <div (click)="syncContactsFun('outlook')" class="w-200px shadow bg-white">
                  <img class="img-80" src="https://hiwac-nc.s3.us-east-2.amazonaws.com/images/outlookLogo.png"
                      alt="">
              </div>
          </div>
      </div>
      </div> -->
      </div>
      <div *ngIf="type === 'Active'" (click)="addEmail();openModalAddMember(AddMember, 'Active') "
          class="mt-3 d-flex cursor-pointer justify-content-center p-2 bg-primary border-top">
          <img class="img-30" src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/White tick.svg" />
      </div>
      <div *ngIf="type === 'Inactive'" (click)="addEmail();openModalAddMember(AddMember, 'Inactive') "
          class="mt-3 d-flex cursor-pointer justify-content-center p-2 bg-primary border-top">
          <img class="img-30" src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/White tick.svg" />
      </div>
      <!-- <div
      class="mt-3 d-flex cursor-pointer justify-content-center p-2 round-bottom border-top">
      <div class="d-flex flex-column w-80 mx-auto py-2">
        <span class="text-dark text-primary f-15 font-weight-bold">{{'Referral'}} <span class="text-primary font-weight-bold ml-1">1</span></span>
        <span class="text-light mt-0">{{'Get Premium Plan by referring 10 people'}} <span class="text-primary font-weight-bold" (click)="openModalReferral(referral)"><a href="javascript:void(0)">{{'See Details'}}</a></span></span>
      </div>
      <div class="invite-btn-chat">
        <img src="https://hiwac-nc.s3.us-east-2.amazonaws.com/images/chat/check-80.png"
          class="img-60 b-none cursor-pointer hover-opacity" />
      </div>
    </div> -->
  </div>
</ng-template>
<ng-template #editRights let-modal>
  <div class="modal-ngb">
    <div class="w-100 d-flex justify-content-center pt-4 pb-3 position-relative">
      <h6 class="m-0 font-weight-600">{{'Edit Rights'|translate}}</h6>
      <div class="trash-employee cursor-pointer" (click)="ledgerService.inActivateMember(ledgerService.editEmployee?.emp_id)"><i class="fa fa-trash text-danger f-23"></i></div>
    </div>
    <div class="modal-body ledger-debit-modal py-2">
      <div class="px-2">
        <div class="d-flex align-items-center">
          <div class="form-check p-0">
            <label id="checkbox" class="form-check-label">
              <div class="d-flex align-items-center">
                <input [checked]="ledgerService.editEmployee?.single_ledger_permission" (change)="ledgerService.toggleRights($event, 'edit')" id="checkbox" value="edit" name="remember" type="checkbox">
              </div>
            </label>
          </div>
          <div class="mx-2"><span>{{'Edit Ledger'|translate}}</span></div>
        </div>
        <!-- <div class="d-flex align-items-center mt-3">

          <div class="form-check p-0">
            <label id="checkbox" class="form-check-label">
              <div class="d-flex align-items-center">
                <input [checked]="ledgerService.editEmployee?.add_ledger" (change)="ledgerService.toggleRights($event, 'create')" id="checkbox" value="create" name="remember" type="checkbox">
              </div>
            </label>
          </div>
          <div class="mx-2"><span>{{'Create Ledger'|translate}}</span></div>
        </div> -->
      </div>
    </div>
    <div (click)="ledgerService.editUserRights(ledgerService.editEmployee?.emp_id, ledgerService.editEmployee?.add_ledger, ledgerService.editEmployee?.single_ledger_permission, modal)" class="mt-3 d-flex justify-content-center p-2 border-top bg-primary round-bottom cursor-pointer">
      <img class="img-30" src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/White tick.svg" />
    </div>
  </div>
</ng-template>