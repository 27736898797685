<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="d-flex justify-content-between align-items-center w-100 px-3">
    <a class="navbar-brand" href="#"><img src="../../../../assets/Accountect/topbarlogo.svg" width="125px" alt="Logo"></a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
      <div class="d-flex me-2">
        <button class="btn btn-primary Signpup text-white" [routerLink]="['/authentication/register']">Sign Up</button>
      </div>
    </div>
  </div>
</nav>

<div class="bg-primary PublicSpace">
  <div class="container">
    <div class="MainDiv">
      <div class="detail-header">
        <div class="d-flex align-items-center w-100">
          <div class="list-img mr-2 position-relative">
            <img class="shadow-sm busi-img" src="../../../../assets/Accountect/customervendor/Group 40026.svg" alt="">
          </div>
          <div class="d-flex flex-column">
            <span class="text-dark d-flex align-items-center cursor-pointer text-capitalize">{{BusinessName}}</span>
          </div>
        </div>
        <div class="w-100 det-ico-view cursor-pointer d-flex align-items-center justify-content-end mt-2 mt-lg-0 toggle-view" (click)="toggleView()">
          <div class="ledge-lang">
            <img id="dropdownBasic1" *ngIf="ledgerView"  src="../../../../assets/Accountect/Ledger View.svg" class="text-primary font-weight-bold m-0 cursor-pointer">
            <img id="dropdownBasic1" *ngIf="!ledgerView"  src="../../../../assets/Accountect/Transaction View.svg" class="text-primary font-weight-bold m-0 cursor-pointer">
          </div>
        </div>
        <div class="w-100 d-flex justify-content-end mt-2 mt-lg-0">
          <!-- <img class="img-30 cursor-pointer mx-2" src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/Cash Icons/Search.svg"> -->
          <div class="cross-ico" ngbDropdown placement="bottom-right">
            <div class="cursor-pointer" ngbDropdownToggle>
              <img class="img-30" src="../../../../assets/Accountect/Burger Menu.svg">
            </div>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <div ngbDropdownItem class="d-flex align-items-center justify-content-start cursor-pointer" (click)="getPDFPublic(this.id)">
                <img class="img-20" src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/ledger/pdf.svg">
                <span class="mx-3">{{'Download'|translate}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-white p-3 py-2 my-3 detail-badge align-items-center cursor-pointer">
        <div class="d-flex align-items-end w-100">
          <div class="d-flex flex-column">
            <span class="m-0 f-15 font-weight-600 text-dark">Balance</span>
          </div>
          <div class="d-flex align-items-center justify-content-end w-100">
            <span class="m-0 font-weight-600 px-2 f-16 text-dark"><b>{{Balance?.amount | number:'1.2-2'}}</b> <span class="text-primary">{{CURRENCY}}</span></span>
          </div>
        </div>
      </div>
      <ng-container *ngIf="ledgerView">
        <div class="ledger-view-only" (window:scroll)="onWindowScroll()">
          <div class="ledgers-list" id="scroll-Table">
            <ng-container>
              <div class="w-100 scroll-Table-public">
                <table class="table ledger-table m-0">
                  <tr class="table-thead bg-light">
                    <th class="w-20 f-w-600 text-center Bgray">{{'Date'|translate}}</th>
                    <th class="w-40 f-w-600 Bgray">{{'Details'|translate}}</th>
                    <th class="w-20 f-w-600 text-center debit-color blUee">{{"Debit"|translate}}</th>
                    <th class="w-20 f-w-600 text-center credit-color Redd">{{'Credit'|translate}}</th>
                    <th class="w-20 f-w-600 text-center black">{{'Balance'|translate}}</th>
                  </tr>
                  <tbody>
                    <ng-container>
                      <tr class="table-row position-relative ledg-balance" *ngFor="let items of Transactions">
                        <td class="w-20 WH-bg text-center"><span>{{items?.created_at}}</span></td>
                        <td class="w-40 gry-bg"><span>{{items?.information}}</span></td>
                        <td class="w-20 debite-bg text-center debit-color dir-ltr-fix">
                          <span class="debit-color" *ngIf="items?.transaction_type == '1'">{{items?.amount_balance | number:'1.2-2'}}</span>
                        </td>
                        <td class="w-20 credit-bg text-center credit-color dir-ltr-fix">
                          <span class="credit-color" *ngIf="items?.transaction_type == '2'">{{items?.amount_balance | number:'1.2-2'}}</span>
                        </td>
                        <td class="w-20 dir-ltr-fix text-center">{{items?.for_calculation_amount | number:'1.2-2'}}</td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!ledgerView">
        <div class="BookView book-view-only d-flex justify-content-center w-100" (window:scroll)="onWindowScroll()">
          <div class="w-100 px-2 px-md-5">
            <div *ngFor="let date of groupedTransactions | keyvalue">
              <div class="S-date">
                <hr class="hr-1">
                <div class="text-center f-14 m-0">
                  <span class="font-weight-bold dir-ltr-fix m-0 p-1 pl-10 bg-light text-dark">{{date.key}}</span>
                </div>
                <hr class="hr-2">
              </div>
              <div class="mb-1" *ngFor="let items of date.value">
                <div class="d-flex justify-content-center w-100">
                  <div class="d-flex shadow-27 w-100">
                    <div class="w-70 d-flex p-2 py-1">
                      <div class="align-self-center">
                        <img *ngIf="items?.transaction_sub_type == 1 || items?.transaction_sub_type == 5" src="../../../../assets/Accountect/Add Transaction/sale and purchase.svg" class="img-40" alt="">
                        <img *ngIf="items?.transaction_sub_type == 3 || items?.transaction_sub_type == 7" src="../../../../assets/Accountect/Add Transaction/sale return purchase return.svg" class="img-40" alt="">
                        <img *ngIf="items?.transaction_sub_type == 6" src="../../../../assets/Accountect/Add Transaction/payment in.svg" class="img-40" alt="">
                        <img *ngIf="items?.transaction_sub_type == 2" src="../../../../assets/Accountect/Add Transaction/payment out.svg" class="img-40" alt="">
                        <img *ngIf="items?.transaction_sub_type == 9" src="../../../../assets/Accountect/Add Transaction/expense return.svg" class="img-40" alt="">
                        <img *ngIf="items?.transaction_sub_type == 10" src="../../../../assets/Accountect/Add Transaction/expense bill.svg" class="img-40" alt="">
                        <img *ngIf="items?.transaction_sub_type == 4 || items?.transaction_sub_type == 8" src="../../../../assets/Accountect/Add Transaction/other.svg" class="img-40" alt="">
                      </div>
                      <div class="w-100 align-self-center">
                        <p class="m-0 f-14">{{items?.information}}</p>
                        <p class="m-0 f-14 font-weight-bold" *ngIf="items?.is_starting_balance">{{'Opening Balance'|translate}}</p>
                        <p class="m-0 f-14 font-weight-bold">{{items?.transaction_sub_type?.display_sub_type}}</p>
                      </div>
                    </div>
                    <div class="w-30 d-flex justify-content-center align-items-center" [ngClass]="{'debite-bg': items?.transaction_type == 1,'credit-bg': items?.transaction_type == 2}">
                      <span class="f-14 font-weight-bold">{{items?.amount_balance | number:'1.2-2'}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <!-- <div class="BookView book-view-only d-flex justify-content-center w-100">
          <div class="w-100 px-2 px-md-5">
            <div *ngFor="let items of Transactions">
              <div class="S-date">
                <hr class="hr-1">
                <div class="text-center f-14 m-0">
                  <span class="font-weight-bold dir-ltr-fix m-0 p-1 pl-10 bg-light text-dark">{{items?.created_at}}</span>
                </div>
                <hr class="hr-2">
              </div>
              <div>
                <div class="d-flex justify-content-center w-100">
                  <div class="d-flex shadow-27 w-100">
                    <div class="w-70 d-flex p-2 py-1">
                      <div class="align-self-center">
                        <img *ngIf="items?.transaction_sub_type == 1 || items?.transaction_sub_type == 5" src="../../../../assets/Accountect/Add Transaction/sale and purchase.svg" class="img-40" alt="">
                        <img *ngIf="items?.transaction_sub_type == 3 || items?.transaction_sub_type == 7" src="../../../../assets/Accountect/Add Transaction/sale return purchase return.svg" class="img-40" alt="">
                        <img *ngIf="items?.transaction_sub_type == 6" src="../../../../assets/Accountect/Add Transaction/payment in.svg" class="img-40" alt="">
                        <img *ngIf="items?.transaction_sub_type == 2" src="../../../../assets/Accountect/Add Transaction/payment out.svg" class="img-40" alt="">
                        <img *ngIf="items?.transaction_sub_type == 9" src="../../../../assets/Accountect/Add Transaction/expense return.svg" class="img-40" alt="">
                        <img *ngIf="items?.transaction_sub_type == 10" src="../../../../assets/Accountect/Add Transaction/expense bill.svg" class="img-40" alt="">
                        <img *ngIf="items?.transaction_sub_type == 4 || items?.transaction_sub_type == 8" src="../../../../assets/Accountect/Add Transaction/other.svg" class="img-40" alt="">
                      </div>
                      <div class="w-100 align-self-center">
                        <p class="m-0 f-14">{{items?.information}}</p>
                        <p class="m-0 f-14 font-weight-bold" *ngIf="items?.is_starting_balance">{{'Opening Balance'|translate}}</p>
                        <p class="m-0 f-14 font-weight-bold">{{items?.transaction_sub_type?.display_sub_type}}</p>
                      </div>
                    </div>
                    <div class="w-30 d-flex justify-content-center align-items-center" [ngClass]="{'debite-bg': items?.transaction_type == 1,'credit-bg': items?.transaction_type == 2}">
                      <span class="f-14 font-weight-bold">{{items?.amount_balance | number:'1.2-2'}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <div *ngIf="this.loadpdf" class="trans-loader d-flex align-items-center justify-content-center">
          <img src="../../../../assets/Accountect/AC_ayers_2.gif" class="img-50" alt="">
        </div>
      </ng-container>
    </div>
  </div>
</div>
