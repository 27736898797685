import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { LoaderComponent } from './components/loader/loader.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';

// services
import { NavService } from './services/nav.service';

// Directives
import { environment } from 'src/environments/environment';
import { ContentLayoutComponent } from './components/layout/content-layout/content-layout.component';
import { FullLayoutComponent } from './components/layout/full-layout/full-layout.component';
import { NgbCarouselConfig, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FilterLedgerPipe } from './pipes/filter-ledger.pipe';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { PolicyComponent } from './components/policy/policy.component';
import { TermsComponent } from './components/terms/terms.component';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { NgwWowModule } from 'ngx-wow';
import { TranslateModule } from '@ngx-translate/core';
import { PaymentReminderComponent } from './components/payment-reminder/payment-reminder.component';
import { TourGenericModelComponent } from './components/tour-generic-model/tour-generic-model.component';
import { MinmaxDirective } from './directives/minmax.directive';
import { AudioFileComponent } from './components/audio-file/audio-file.component';
import { PlanExpiredPopupComponent } from './components/plan-expired-popup/plan-expired-popup.component';
import { Maximum100Directive } from './directives/maximum-100.directive';
import { TopHeaderComponent } from './components/top-header/top-header.component';
import { DataDeletionPolicyComponent } from './components/data-deletion-policy/data-deletion-policy.component';
import { SearchFiltersComponent } from './components/search-filters/search-filters.component';
import { JournalFiltersComponent } from './journal-filters/journal-filters.component';
import { CashfiltersComponent } from './components/cashfilters/cashfilters.component';
import { GalleryViewerComponent } from './components/gallery-viewer/gallery-viewer.component';
import { AddbankComponent } from './components/addbank/addbank.component';
import { GlobalitemComponent } from './components/globalitem/globalitem.component';
import { AllpdfRoomsComponent } from './components/allpdf-rooms/allpdf-rooms.component';
import { AddParticipentComponent } from './components/add-participent/add-participent.component';
import { LackpermissionComponent } from './components/lackpermission/lackpermission.component';
import { ItemFiltersComponent } from './components/item-filters/item-filters.component';
import { CommentsComponent } from './components/comments/comments.component';
import { DownloadFiltersComponent } from './components/download-filters/download-filters.component';
import { PdfroomsComponent } from './components/pdfrooms/pdfrooms.component';




@NgModule({
    declarations: [
        // LoaderComponent,
        // LandingpageComponent,
        HeaderComponent,
        FooterComponent,
        PolicyComponent,
        TermsComponent,
        ContentLayoutComponent,
        FullLayoutComponent,
        FilterLedgerPipe,
        SnackbarComponent,
        PaymentReminderComponent,
        TourGenericModelComponent,
        AudioFileComponent,
        MinmaxDirective,
        AudioFileComponent,
        PlanExpiredPopupComponent,
        Maximum100Directive,
        TopHeaderComponent,
        DataDeletionPolicyComponent,
        SearchFiltersComponent,
        JournalFiltersComponent,
        CashfiltersComponent,
        GalleryViewerComponent,
        AddbankComponent,
        GlobalitemComponent,
        AllpdfRoomsComponent,
        AddParticipentComponent,
        LackpermissionComponent,
        ItemFiltersComponent,
        CommentsComponent,
        DownloadFiltersComponent,
        PdfroomsComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        NgbModule,
        ReactiveFormsModule,
        NgxQRCodeModule,
        NgwWowModule,
        TranslateModule,
    ],
    exports: [
      FormsModule,
      NgbModule,
      ReactiveFormsModule,
      CommonModule,
      FilterLedgerPipe,
      NgxQRCodeModule,
      NgwWowModule,
      TranslateModule,
      TourGenericModelComponent,
      MinmaxDirective,
      AudioFileComponent,
      PlanExpiredPopupComponent,
      SearchFiltersComponent,
      JournalFiltersComponent,
      CashfiltersComponent,
      GalleryViewerComponent,
      AddbankComponent,
      GlobalitemComponent,
      AllpdfRoomsComponent,
      AddParticipentComponent,
      LackpermissionComponent,
      ItemFiltersComponent,
      CommentsComponent,
      DownloadFiltersComponent,
      PdfroomsComponent,
      
    ],
    providers: [
      NavService,NgbCarouselConfig
    ]
})
export class SharedModule {
  
}
