// import { AfterViewInit, ElementRef } from '@angular/core';
// import { Renderer2 } from '@angular/core';
// import { Component } from '@angular/core';
// import { Meta, Title } from '@angular/platform-browser';
// import { ActivatedRoute, Event, NavigationEnd, NavigationStart, Router } from '@angular/router';
// import { environment } from 'src/environments/environment';
// import { AuthService } from './shared/services/auth.service';
// // import { MetaTagsService } from './shared/services/meta-tags.service';
// import { MetaTagsService } from './shared/services/meta-tags.service';

// @Component({
//   selector: 'app-root',
//   templateUrl: './app.component.html',
//   styleUrls: ['./app.component.scss']
// })
// export class AppComponent implements AfterViewInit{
//   title = 'Ledger';
//   public build_type = environment.build_type;
//   showMobile: boolean = false;
//   constructor(private renderer: Renderer2,private el: ElementRef, public authService: AuthService, public metaService: MetaTagsService, private router: Router, private titleService: Title, private route:ActivatedRoute) {
    
//   }
//   ngAfterViewInit() {
//     // let loader = this.renderer.selectRootElement('#mainloaderciwac');
//     // setTimeout(() => {
//     //   this.renderer.setStyle(loader, 'display', 'none');
//     // }, 2000)

//   }
//   returnURLApp() {
//     if (this.authService.isBrowser) {
//       window.location = this.authService.linkMobileRef || null;
//     }
//   }
//   ngOnInit() {
//  // alert("app")
//   // this.router.navigate(['public', 'accounts', 'room', 'M769nA']);
//     const html: HTMLElement = this.el.nativeElement.ownerDocument.documentElement;
//     this.renderer.setAttribute(html, 'lang', this.authService.targetLanguage);

//     if (this.authService.isBrowser) {
      
//       this.showMobile = (window.location.href.includes('privacy-policy') || window.location.href.includes('terms-conditions')) || false;
//       //  console.log('this is the parent URLSssssssssssssssssssssss', this.showMobile);
//     }
//     // this.titleService.setTitle(this.build_type == 'ciwac'?'Ciwac | Accounting app':'昵沃科：连接的账户');
//     this.router.events.subscribe( (event: Event) => {

//       if (event instanceof NavigationStart) {
//           /*Display your spinner*/
//           ////  console.log('loading start');
//           this.authService.mainLoader = true;
          
//       }

//       if (event instanceof NavigationEnd) {
//           /*Hide your spinner*/
//           //  console.log('loading end');
//           this.authService.mainLoader = false;
//       }
//   });
//     // //  console.log("App Componemt");
//     this.authService.detectMobile();
   

//     window.addEventListener('storage', (event) => {
//       if (event.storageArea == localStorage) {
//         let token = localStorage.getItem('user');
//         //  console.log("User is...", token);
        
//         if(token == undefined) { // you can update this as per your key
//             // DO LOGOUT FROM THIS TAB AS WELL
//             this.router.navigate(['/']); // If you are using router
//             // OR
//             // window.location.href = '<home page URL>';
//         }
//       }
//     }, false);

//     this.createLinkForCanonicalURL();
//   }
//   navigateTo(route) {
//     window.location.replace(route);
//   }
//   createLinkForCanonicalURL() {
//     this.metaService.createLinkForCanonicalURL();
//   }
// }




import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart, Event as NavigationEvent } from '@angular/router';
import { AuthService } from './shared/services/auth.service';
import { MetaTagsService } from './shared/services/meta-tags.service';
import { Renderer2, ElementRef } from '@angular/core';
import { environment } from 'src/environments/environment';
declare var gapi: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit, OnInit {
  title = 'Ledger';
  public build_type = environment.build_type;
  showMobile: boolean = false;
  isPublicRoute: boolean = false;

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    public authService: AuthService,
    public metaService: MetaTagsService,
    private router: Router
  ) { }

  ngOnInit() {
    this.authService.loginLoader=true;
    // this.loadGoogleAuthLibrary();
    this.router.events.subscribe((event: NavigationEvent) => {
      if (event instanceof NavigationEnd) {
        console.log(this.isPublicRoute);
        this.isPublicRoute = event.url.includes('public');
      }
    });

    const html: HTMLElement = this.el.nativeElement.ownerDocument.documentElement;
    this.renderer.setAttribute(html, 'lang', this.authService.targetLanguage);

    if (this.authService.isBrowser) {
      this.showMobile = (window.location.href.includes('privacy-policy') || window.location.href.includes('terms-conditions')) || false;
    }

    // Check if it's not a public route before executing the rest of the code
    if (!this.isPublicRoute) {
      this.router.events.subscribe((event: NavigationEvent) => {
        if (event instanceof NavigationStart) {
          this.authService.mainLoader = true;
        }
        if (event instanceof NavigationEnd) {
          this.authService.mainLoader = false;
        }
      });

      this.authService.detectMobile();

      window.addEventListener('storage', (event) => {
        if (event.storageArea == localStorage) {
          let token = localStorage.getItem('user');
          if (token == undefined) {
            this.router.navigate(['/']);
          }
        }
      }, false);

      this.createLinkForCanonicalURL();
    }
  }
  loadGoogleAuthLibrary() {
    alert('helo')
    const script = document.createElement('script');
    script.src = 'https://apis.google.com/js/platform.js';
    script.async = true;
    script.defer = true;
    script.onload = () => this.initGoogleAuth();
    document.body.appendChild(script);
  }

  // initGoogleAuth() {
  //   alert("google");
  
  //   gapi.load('auth2', () => {
  //     if (gapi.auth2.getAuthInstance()) {
  //       console.log('Google Auth already initialized');
  //     } else {
  //       gapi.auth2.init({
  //         client_id: '191283059503-0n2eedc1j04kceb4qr93hdio5tfnmh0f.apps.googleusercontent.com'
  //       }).then(() => {
  //         console.log('Google Auth initialized');
  //       }).catch((error) => {
  //         console.error('Error initializing Google Auth:', error);
  //       });
  //     }
  //   });
  // }
  initGoogleAuth() {
    alert("google");

    gapi.load('auth2', () => {
      let auth2 = gapi.auth2.getAuthInstance();
      if (!auth2) {
        gapi.auth2.init({
          client_id: '191283059503-0n2eedc1j04kceb4qr93hdio5tfnmh0f.apps.googleusercontent.com'
        }).then(() => {
          console.log('Google Auth initialized');
        }).catch((error) => {
          console.error('Error initializing Google Auth:', error);
        });
      } else {
        console.log('Google Auth already initialized');
      }
    });
  }
  ngAfterViewInit() {
    // Code related to view initialization can remain as it is
  }

  returnURLApp() {
    if (this.authService.isBrowser) {
      window.location = this.authService.linkMobileRef || null;
    }
  }

  navigateTo(route) {
    window.location.replace(route);
  }

  createLinkForCanonicalURL() {
    this.metaService.createLinkForCanonicalURL();
  }
}

// import { Component, OnInit, AfterViewInit } from '@angular/core';
// import { Router, NavigationEnd, NavigationStart, Event as NavigationEvent } from '@angular/router';
// import { AuthService } from './shared/services/auth.service';
// import { MetaTagsService } from './shared/services/meta-tags.service';
// import { Renderer2, ElementRef } from '@angular/core';
// import { environment } from 'src/environments/environment';

// declare var gapi: any;

// @Component({
//   selector: 'app-root',
//   templateUrl: './app.component.html',
//   styleUrls: ['./app.component.scss']
// })
// export class AppComponent implements AfterViewInit, OnInit {
//   title = 'Ledger';
//   public build_type = environment.build_type;
//   showMobile: boolean = false;
//   isPublicRoute: boolean = false;
//   showLoader: boolean = true;

//   constructor(
//     private renderer: Renderer2,
//     private el: ElementRef,
//     public authService: AuthService,
//     public metaService: MetaTagsService,
//     private router: Router
//   ) {}

//   ngOnInit() {
//     this.router.events.subscribe((event: NavigationEvent) => {
//       if (event instanceof NavigationStart) {
//         this.showLoader = true;
//       }

//       if (event instanceof NavigationEnd) {
//         this.isPublicRoute = event.url.includes('public');
//         this.showLoader = false;
//       }
//     });

//     const html: HTMLElement = this.el.nativeElement.ownerDocument.documentElement;
//     this.renderer.setAttribute(html, 'lang', this.authService.targetLanguage);

//     if (this.authService.isBrowser) {
//       this.showMobile = (window.location.href.includes('privacy-policy') || window.location.href.includes('terms-conditions')) || false;
//     }

//     if (!this.isPublicRoute) {
//       this.authService.detectMobile();

//       window.addEventListener('storage', (event) => {
//         if (event.storageArea == localStorage) {
//           let token = localStorage.getItem('user');
//           if (token == undefined) {
//             this.router.navigate(['/']);
//           }
//         }
//       }, false);

//       this.createLinkForCanonicalURL();
//     }
//   }

//   loadGoogleAuthLibrary() {
//     const script = document.createElement('script');
//     script.src = 'https://apis.google.com/js/platform.js';
//     script.async = true;
//     script.defer = true;
//     script.onload = () => this.initGoogleAuth();
//     document.body.appendChild(script);
//   }

//   initGoogleAuth() {
//     gapi.load('auth2', () => {
//       let auth2 = gapi.auth2.getAuthInstance();
//       if (!auth2) {
//         gapi.auth2.init({
//           client_id: '191283059503-0n2eedc1j04kceb4qr93hdio5tfnmh0f.apps.googleusercontent.com'
//         }).then(() => {
//           console.log('Google Auth initialized');
//         }).catch((error) => {
//           console.error('Error initializing Google Auth:', error);
//         });
//       } else {
//         console.log('Google Auth already initialized');
//       }
//     });
//   }

//   ngAfterViewInit() {}

//   returnURLApp() {
//     if (this.authService.isBrowser) {
//       window.location = this.authService.linkMobileRef || null;
//     }
//   }

//   navigateTo(route) {
//     window.location.replace(route);
//   }

//   createLinkForCanonicalURL() {
//     this.metaService.createLinkForCanonicalURL();
//   }
// }
