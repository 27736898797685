import {Inject, Injectable} from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import {DOCUMENT} from "@angular/common";

import {MetaTags} from "../classes/metaTags";
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class MetaTagsService {
  public defaultTag = {
    h1: 'Business Accounting',
    author : 'Accountect',
    keywords : 'accounting app, ledger management, salary book, inventory management, Bookkeeping software, connected accounting, Purchase Journal, cash book, bank book, invoice management, Sales Journal',
    title : 'Free Connected Accounting Software & Bookkeeping Platform',
    description : 'Accountect is a cloud-based accounting and bookkeeping software to digitize the accounting workflows of small and medium-sized businesses.',
    url: 'web.accountect.com'
  };
  
  constructor(
    @Inject(DOCUMENT) private document: any,
    private meta: Meta,
    private title: Title,
    private titleService: Title,
    public translate: TranslateService
  ) {
  }

  add(obj: any): void {
    // this.title.setTitle(obj.title || this.defaultTag.title);
    this.meta.addTag({name: 'author', content: obj.author || this.defaultTag.author});
    if (this.isAllowMetaTags()) {
      this.meta.updateTag({name: 'keywords', content: obj.keywords || this.defaultTag.keywords});
      this.meta.updateTag({name: 'description', content: obj.description || this.defaultTag.description});
      this.meta.updateTag({name: 'url', content: obj.url || this.defaultTag.url});
    }
  }

  findTag(obj: any): any {
    if (!obj && !obj.url) {
      return this.defaultTag;
    }
    const url = obj.url, getTag = MetaTags.find((tag: any) => tag.url === url);
    return getTag || this.defaultTag;
  }
  updateTag(tag: { name: string, content: string }) {
    this.meta.updateTag({ name: tag.name, content: tag.content });
  }
  setDefaultTags(): void {
    // this.titleService.setTitle(this.defaultTag.title);
    this.translate.get('Free Connected Accounting Software & Bookkeeping Platform').subscribe((title: any) => {
      this.titleService.setTitle(title);
    });
    this.meta.addTag({name: 'author', content: this.defaultTag.author});
    if (this.isAllowMetaTags()) {
      this.meta.addTag({name: 'keywords', content: this.defaultTag.keywords});
      this.meta.addTag({name: 'description', content: this.defaultTag.description});
    }
  }
  setTitle(title: any = this.defaultTag.title): void {
    this.translate
      .get('Free Connected Accounting Software & Bookkeeping Platform')
      .subscribe((title: any) => {
        this.title.setTitle(this.defaultTag.title);
      });
  }

  setSocialMetaTags(tag: any = {}): void {
    if (tag.title) {
      this.defaultTag.h1 = tag.title;
    }
    if (this.isAllowMetaTags()) {
      const tagList: any[] = [
        { name: 'og:site_name', content: this.defaultTag.title},
        { name: 'twitter:site', content: this.defaultTag.title},
        { name: 'og:title', content: tag.title || this.getPageTitle()},
        { name: 'twitter:title', content: tag.title || this.getPageTitle()},
        { name: 'og:description', content: tag.description || this.defaultTag.description},
        { name: 'twitter:description', content: tag.description || this.defaultTag.description},
      ];
      if (this.document.location.href) {
        tagList.push(
          { name: 'og:url', content: this.document.location.href },
        );
      }
      if (tag.image) {
        tagList.push(
          { name: 'og:image', content: tag.image},
          { name: 'og:image', content: tag.image}
        );
      }
      if (tag.imageAlt) {
        tagList.push(
          { name: 'og:image:alt', content: tag.imageAlt},
          { name: 'twitter:image:alt', content: tag.imageAlt},
        );
      }
      if (tag.card) {
        tagList.push(
          { name: 'twitter:card', content: tag.card},
        );
      }
      if (tag.keywords) {
        this.meta.updateTag({
          name: 'keywords',
          content: tag.keywords
        });
      }
      if (tag.description) {
        this.meta.updateTag({
          name: 'description',
          content: tag.description
        });
      }
      this.meta.addTags(tagList);
    }
  }
  createLinkForCanonicalURL() {
    let link: HTMLLinkElement = this.document.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.document.head.appendChild(link);
    link.setAttribute('href', this.document.URL);
  }
  getPageTitle(): string {
    return this.defaultTag.h1 || this.title.getTitle();
  }

  public isAllowMetaTags(): boolean {
    return true;
  }
}
