import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(
      private router: Router,
      private auth: AuthService) {

    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(
                // retry(1),
                catchError((error: HttpErrorResponse) => {
                    let errorMessage = '';
                    if (error.error instanceof ErrorEvent) {
                        // client-side error
                        errorMessage = `Error: ${error.error.message}`;
                    } else {
                        // server-side error
                        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
                        // ////  console.log(error.error.detail);
                        // ////  console.log(errorMessage);
                        if (error.status === 400) {
                            // ////  console.log('Bad request: 400 code');
                        }
                        if (error.status === 409) {
                            // ////  console.log('Bad request: 409 code');
                            // ////  console.log(error);
                            // ////  console.log(error.error.Error);
                            if (error.error.Error) {
                                // this.toast.error(error.error.Error)
                            }
                            // ////  console.log(error.error.detail);
                        }
                        if (error.status === 500) {
                            // ////  console.log('Bad request: 500 code');
                        }
                        if (error.status === 401) {
                          // this.router.navigate(['/authentication/login']);

                            // this.auth.SignOut();
                            // localStorage.removeItem('rooms');
                            // localStorage.removeItem('user');
                            // localStorage.removeItem('contractActive');
                            // // setTimeout(() => {
                            // //     // window.location.reload();
                            // // }, 300);
                            // this.router.navigate(['/authentication/login']);

                            // ////  console.log('logout');
                            // this.router.navigate(['/login']);
                        }
                        else if (error.status === 403) {
                          // // alert('Session expired. Please login again.')
                          // this.router.navigate(['/authentication/login']);

                          // this.auth.SignOut();
                          // localStorage.removeItem('rooms');
                          // localStorage.removeItem('user');
                          // localStorage.removeItem('contractActive');
                          // localStorage.removeItem('rooms');
                          // this.router.navigate(['/authentication/login']);
                          // this.auth.SignOut();

                      }
                    }
                    // window.// alert(errorMessage);
                    return throwError(error);
                })
            );
    }
}
