<div class="d-flex justify-content-center"> 
    <div class="container privacy-policy">
        <h1>Data Deletion Policy</h1>
        <div>
            <h2>1. Introduction</h2>
            <p>
                Accountect Technologies Inc. ("Accountect") is deeply committed to safeguarding the privacy and data of its users. 
                This Data Deletion Policy meticulously outlines the procedures and guidelines for deleting user accounts and associated data from Accountect's Web and Mobile app. 
                Rest assured, Accountect ensures that user data is securely deleted and not retained after the deletion process, maintaining the highest standards of data security.
            </p>
        </div>
        <div>
            <h2>2. Account Deletion Process</h2>
            <p>
                Users have the right to delete their accounts at any time. Users can initiate the account deletion process within the Web and Mobile app. To carry out the deletion process, these steps need to be followed:
            </p>
            <ul>
                <li>
                    <h4>
                        Visit the URL <a href="https://web.accountect.com/" target="_blank">web.accountect.com</a> and log in to your Accountect account.
                    </h4>
                    <p class="text-center">
                        <img src="../../../../assets/Accountect/dataDeleteion/S01.png" width="100%" alt="">

                    </p>
                </li>
                <br>
                <br>
                <li>
                    <h4>
                        From the Sidebar click on the <b>Me</b> Icon.
                    </h4>
                    <p class="text-center">
                        <img src="../../../../assets/Accountect/dataDeleteion/S1.png" width="100%" alt="">

                    </p>
                </li>
                <br>
                <br>
                <li>
                    <h4>
                        Click on the <b>Settings</b> Tab.
                    </h4>
                    <p class="text-center">
                        <img src="../../../../assets/Accountect/dataDeleteion/S2.png" width="100%"  alt="">

                    </p>
                </li>
                <br>
                <br>
                <li>
                    <h4>
                        Click on <b>Delete Account</b>.
                    </h4>
                    <p class="text-center">
                        <img src="../../../../assets/Accountect/dataDeleteion/S3.png" width="100%"  alt="">

                    </p>
                </li>
                <br>
                <br>
                <li>
                    <h4>
                        Confirm that you wish to continue the process.
                    </h4>
                    <p class="text-center">
                        <img src="../../../../assets/Accountect/dataDeleteion/S4.png" width="100%"  alt="">

                    </p>
                </li>
            </ul>
        </div>
        <div>
            <h2>3. Backup and Restoration</h2>
            <p>
                Accountect does not keep backups of user data after the account has been deleted. Once the data deletion process is finished, the user data cannot be recovered. 
                We advise users to download and save any important information before initiating the account deletion process.
            </p>
        </div>
        <div>
            <h2>4. Exceptional Circumstances</h2>
            <p>
                In exceptional circumstances, Accountect may need to keep some user data even after an account is deleted in order to comply with legal requirements or resolve disputes. 
                In these instances, only the minimum amount of data necessary for these purposes will be kept.
            </p>
        </div>
        <div>
            <h2>5. Communication and Notification</h2>
            <p>
                Accountect will ensure clear communication with users throughout the account deletion process, providing information on the steps involved, data retention period, and permanent deletion of data.
            </p>
        </div>
        <div>
            <h2>6. Contact Information</h2>
            <p>
                If users have any questions or concerns about the data deletion policy, they can reach out to Accountect's customer support at [admin@accountect.com].
            </p>
        </div>
        <div>
            <h2>7. Policy Updates</h2>
            <p>
                Accountect may update this Data Deletion Policy from time to time to reflect changes in its practices or legal requirements. Users will be notified of any significant changes in the policy.
            </p>
        </div>
        <div>
            <p>
                By using Accountect's web and mobile app, users acknowledge that they have read, understood, and agreed to this Data Deletion Policy.
            </p>
            <p>
                <b>Effective Date:</b> [May 2024]
            </p>
            <p>
              <b>Last Updated:</b> [May 2024]
            </p>
        </div>
    </div>
</div>