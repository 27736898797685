    <div *ngIf = "linkType=== 'False'">
      <div>
        <div class="px-4">
          <div class="w-100 d-flex align-items-center justify-content-between b-b-light py-2">
            <div class="d-flex align-items-center">
              <img class="img-15" src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/ledger/pdf.svg" class="img-20">
              <span class="f-14 mx-3">{{'All'|translate}}</span>
            </div>
  
            <div class="form-check">
              <input class="form-check-input" type="radio" name="date" id="allTime" checked="checked" (change)="DateRangeSelection('allTime')">
              <label class="form-check-label" for="allTime">
              </label>
            </div>
          </div>
          <div class="w-100 d-flex align-items-center justify-content-between b-b-light py-2">
            <div class="d-flex align-items-center">
              <img class="img-15" src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/ledger/pdf.svg" class="img-20">
              <span class="f-14 mx-3">{{'Today'|translate}}</span>
            </div>
  
            <div class="form-check">
              <input class="form-check-input" type="radio" name="date" id="today" (change)="DateRangeSelection('today')">
              <label class="form-check-label" for="today">
              </label>
            </div>
          </div>
          <div class="w-100 d-flex align-items-center justify-content-between b-b-light py-2">
            <div class="d-flex align-items-center">
              <img class="img-15" src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/ledger/pdf.svg" class="img-20">
              <span class="f-14 mx-3">{{'Year'|translate}}</span>
            </div>
  
            <div class="form-check">
              <input class="form-check-input" type="radio" name="date" id="year" (change)="DateRangeSelection('year')">
              <label class="form-check-label" for="year">
              </label>
            </div>
          </div>
          <div class="w-100 d-flex align-items-center justify-content-between b-b-light py-2" >
            <div class="d-flex align-items-center">
              <img class="img-15" src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/ledger/pdf.svg" class="img-20">
              <span class="f-14 mx-3">{{'Month'|translate}}</span>
            </div>
  
            <div class="form-check">
              <input class="form-check-input" type="radio" name="date" id="month" (change)="DateRangeSelection('month')">
              <label class="form-check-label" for="month">
              </label>
            </div>
          </div>
  
          <div class="w-100 d-flex align-items-center justify-content-between b-b-light py-2">
            <div class="d-flex align-items-center">
              <img class="img-15" src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/ledger/pdf.svg" class="img-20">
              <span class="f-14 mx-3">{{'Week'|translate}}</span>
            </div>
  
            <div class="form-check">
              <input class="form-check-input" type="radio" name="date" id="week" (change)="DateRangeSelection('week')">
              <label class="form-check-label" for="week">
              </label>
            </div>
          </div>
  
          <div class="w-100 d-flex align-items-center justify-content-between py-2">
            <div class="d-flex align-items-center">
              <img class="img-15" src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/ledger/pdf.svg" class="img-20">
              <span class="f-14 mx-3">{{'From'|translate}}</span>
            </div>
  
            <div>
              <span class="f-14">{{'To'|translate}}</span>
            </div>
  
            <div class="form-check">
              <input class="form-check-input" type="radio" name="date" id="fromTo" (change)="DateRangeSelection('fromTo')">
              <label class="form-check-label" for="fromTo">
              </label>
            </div>
          </div>
  
          <div class="py-2 d-flex align-items-center" [class.disabled]="!customDate">
            <div class="w-50 mx-auto datepicker p-2 d-flex align-item-center">
              <div class="form-inline custom-datepicker custom-datepicker-dropdown">
                <div class="form-group m-0">
                  <div class="input-group">
                    <div class="input-group-append rtl" *ngIf="auth.dirWeb === 'rtl'">
                      <button (click)="fvalid.toggle()" class="btn calendar" type="button"><img src="../../../../assets/Accountect/calendar.svg" class="img-18" alt=""></button>

                    </div>
                    <input #fvalid="ngbDatepicker" [(ngModel)]="from_date" class="form-control"
                      (dateSelect)="setFromToDate('from')" [minDate]="{year: 1900, month: 1, day: 1}" name="dp" (click)="fvalid.toggle()" ngbDatepicker
                      [placeholder]="'mm-dd-yyyy' | translate" readonly>
                    <div class="input-group-append ltr" *ngIf="auth.dirWeb === 'ltr'">
                      <button (click)="fvalid.toggle()" class="btn calendar" type="button"><img src="../../../../assets/Accountect/calendar.svg" class="img-18" alt=""></button>

                    </div>
                  </div>
                </div>
              </div>
              <!-- <input class="form-control" placeholder="mm-dd-yyyy" type="date" name="dp"> -->
            </div>
            <div class="w-50 mx-auto datepicker p-2 d-flex align-item-center">
              <div class="form-inline custom-datepicker custom-datepicker-dropdown">
                <div class="form-group m-0">
                  <div class="input-group">
                    <div class="input-group-append rtl" *ngIf="auth.dirWeb === 'rtl'">
                      <button (click)="fvalid.toggle()" class="btn calendar" type="button"><img src="../../../../assets/Accountect/calendar.svg" class="img-18" alt=""></button>
                    </div>
                    <input #tvalid="ngbDatepicker" [(ngModel)]="to_date" class="form-control"
                      (dateSelect)="setFromToDate('To')" [minDate]="{year: 1900, month: 1, day: 1}" name="dp" (click)="tvalid.toggle()" ngbDatepicker
                      [placeholder]="'mm-dd-yyyy' | translate" readonly>
                    <div class="input-group-append ltr" *ngIf="auth.dirWeb === 'ltr'">
                      <button (click)="fvalid.toggle()" class="btn calendar" type="button"><img src="../../../../assets/Accountect/calendar.svg" class="img-18" alt=""></button>

                    </div>
                  </div>
                </div>
              </div>
              <!-- <input class="form-control" placeholder="mm-dd-yyyy" type="date" name="dp"> -->
            </div>  
          </div>
          </div>
          <div class="d-flex justify-content-center bg-primary cursor-pointer round-bottom">
            <button class="btn btn-primary b-r-12 w-100" [disabled]="notalowed" (click)="downloadPDFNow()">
              <i class="fa fa-download f-30 text-white" aria-hidden="true"></i>
            </button>
          </div>
  
          <div class="loader-alerts" *ngIf="isLoading">
            <img  style="width: 50px;" src="../../../../assets/Accountect/AC_ayers_2.gif">
          </div>
      </div>
    </div>