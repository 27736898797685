import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from './auth.service';
import { HttpServiceService } from './http/http-service.service';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Interface } from 'readline';
import { LedgerService } from './ledger.service';
import { finalize } from 'rxjs/operators';
import { NgZone } from '@angular/core';
import { error } from 'console';
declare var $: any;
@Injectable({
  providedIn: 'root'
})
export class BillBookervice {
  
  public subject = new Subject<any>();
  public baseModel: any;
  public DailybaseModel:any;
  public expbaseModal:any;
  public acceptreject: any;
  public invoiceaccptreject: any;
  public purchaseAcceptInvoic: any;
  public expenseAcceptInvoice: any;
  public expenseAcceptService: any;
  public expenseAcceptServicewithoutDeposit: any;
  public reject: any;
  public accept: any;
  public formGroup: FormGroup = new FormGroup({});
  public allBusinessList: any;
  public subjectBusinessSearch$: Subject<any> = new Subject();
  public subjectServiceSearch$: Subject<any> = new Subject();
  public loader: boolean;
  public invoiceListing: any[]=[];
  public billBoardTypes: any;
  public billboardSubTotalTypes: any;
  public itemServiceList: any;
  public selectedInvoice: any;
  public Images: any;
  public SelectedID: any = [];
  public selectedSingleInvoice: any;
  public isEditInvoice: boolean = false;
  public loadPdf: boolean = false;
  public businessId: any;
  public  payementInOut: boolean=false;
  public  AccountsTypesPaymentInOut: any;
  public PayemetType: any='';
  public paymentDays: any='';
  public bundleStatus: boolean=false;
  public selectedInvoiceItem: any;
  public expedit: boolean=false;
  public Bankselected: boolean=false;
  public Cardselected: boolean=false;
  public Cardname: "";
  public bankName='';
  public categoryName: any;
  public bundletype: boolean=true;
  public tabSelected: string;
  public expenseId: any;
  public shippingId : any;
  public accountView: boolean;
  public isLoading: boolean=false;
  public SearchCheck:boolean=false;
  public purJouranl: boolean=true;
  public journalType: boolean=false;
  dateCheck: any=1;
  dateFormat: any;
  expType:any='Memo';
  loadShareLink: boolean;
  invoiceShareLink: any;
  public alertView: any;
  public billBookAllFilters ={
    bill_board_transaction_type: 1,
    search_query: '',
    start_date: '',
    end_date: '',
    invoice_status_type:'sent',
    accept_or_reject_status: "all",
    paid_status: '',
    // business_id: null,
    overdue_date: "",
    customer_vendor_list: [],
    category_id: '',
    product_invoice: true,
    item_list:[],
    expense_category:''
  }
  invoiceSeriesStatus: any;
  purchaseBillSeriesStatus: any;
  expenseBillSeriesStatus: any;
  expenseReturnSeriesStatus: any;
  purchaseReturnSeriesStatus: any;
  saleReturnSeriesStatus: any;
  quotationSeriesStatus: any;
  packingSlipSeriesStatus: any;
  SaleReceiptSeriesStatus:any
  purchaseOrder: any;
  fileData: any=[];
  previewUrl: any=[];
  uploadFile: boolean;
  purchaseAccept: { accept_or_reject_status: any; };
  selectedDetailData: any;
  selectedDetailExData: any;
  SingleTransaction: any;
  invoiceNumber: any;
  SingleinVoiceDate: void;
  SingleInvoiceTrans: any;
  SaleOrderSeriesStatus: any;
  commentData: any;
  shareDocmail:FormGroup;
  DownloadFilter:FormGroup;
  InoVoicessForReturn: any;
  transactionId: any;
  summryCheck: any;
  page: number=1;
  saleTotalPages: any=1;
  // isLoading: boolean;
  public resetBillBookAllFilters(): void {
    this.billBookAllFilters = {
      bill_board_transaction_type: 1, // Reset to default value
      search_query: '',               // Reset to default value
      start_date: '',                 // Reset to default value
      end_date: '',                   // Reset to default value
      invoice_status_type: 'sent',    // Reset to default value
      accept_or_reject_status: "all", // Reset to default value
      paid_status: '',                // Reset to default value
      //  business_id: '',              // Reset to default value
      overdue_date: "",               // Reset to default value
      customer_vendor_list: [],       // Reset to default value
      category_id: '',
      product_invoice: true,              
      item_list: [],
      expense_category: ''               
    };
  }
  public billBookFilters = {
    bill_board_transaction_type: '',
    search_query: '',
    start_date: '',
    end_date: '',
    invoice_status_type:'all',    
  };
  public billBookFilterInvoicesSaleReturn={
    bill_board_transaction_type: 1,
    invoice_status_type: "sent",
    accept_or_reject_status: "accepted",
    has_packing_slips: false,
    product_invoice:true,
    search_query: '',
    start_date: '',
    end_date: '',
  }
  public billbookFilterPackingSlipSelect = 
{
    bill_board_transaction_type: '1',
    search_query: '',
    start_date: '',
    end_date: '',
    accept_or_reject_status: 'accepted',
    invoice_status_type: "sent",
    // one_way_ledger:true,
    business_id: null,
    has_packing_slips: false,
    service_invoice:false,
    product_invoice:true
  }
  public billBookFiltersSalereturn = {
    bill_board_transaction_type: '1',
    has_returns : false,
    search_query: '',
    start_date: '',
    end_date: '',
    accept_or_reject_status: 'accepted',
    invoice_status_type: "sent",
    one_way_ledger:true,
    business_id: null,
  };
  public billBookFiltersSalereturnAccount = {
    bill_board_transaction_type: '1',
    has_returns : false,
    search_query: '',
    start_date: '',
    end_date: '',
    accept_or_reject_status: 'accepted',
    invoice_status_type: "sent",
    one_way_ledger:true,
    business_id: null,
  }
  public billBookFiltersPackingSlip = {
    bill_board_transaction_type: '7',
    accept_or_reject_status: 'accepted',
    search_query: '',
    start_date: '',
    end_date: '',
    packing_slip_type:'sale'
  };
  public billBookFiltersPurchasereturn = {
    bill_board_transaction_type: '2',
    has_returns : false,
    search_query: '',
    start_date: '',
    end_date: '',
    business_id: null
  };
  public billBookFiltersExpensereturn = {
    bill_board_transaction_type: '8',
    has_returns : false,
    search_query: '',
    start_date: '',
    end_date: '',
    business_id: null
  };
  public billBookFiltersDates = {
    start_date_local: '',
    end_date_local: '',
  };

  public marketInvoice: boolean= false;
  public marketId:any;
  billListingById: any;
  loaderById: boolean = false;
  loadParticipants: boolean;
  memberslist: any;
  loaderBillAdd: boolean = false;
  isBillTypeService: any = false;
  InoVoicess: any[]=[];
  vendorList: any;
  customerList:any;
  TotalRecordInvoices: any;
  check:boolean;
  accType:any;
  custName:'Customer'
  VenName:'Vendor'
  purAccount:boolean;
  expAccount:boolean;
  getInvoice:boolean;
  custVendQuery:any;
  invoiceNumb:any;
  InoVoicesslist: any;
  TotalRecordInvoiceslist: any;
  public invoiceType:any;
  public querySearchValuesCust: any;
  
  invoiceSeries: any;
  purchaseBillSeries: any;
  expenseBillSeries: any;
  expenseReturnSeries: any;
  purchaseReturnSeries: any;
  saleReturnSeries: any;
  quotationSeries: any;
  SaleReceiptSeries:any;
  packingSlipSeries: any;
  Billslist: any;
  billListing: any[];

  
  constructor(
    private http: HttpServiceService,
    public auth: AuthService,
    public toast: ToastrService,
    public translate: TranslateService,
    public router: Router,
    public modalService: NgbModal,
    public fb: FormBuilder,
    private formBuilder: FormBuilder,
    public ledgerService: LedgerService,
    
    private zone: NgZone,
    // private cdr: ChangeDetectorRef P
  ) {
    this.subjectBusinessSearch$
      .pipe(distinctUntilChanged(), debounceTime(400))
      .subscribe((res) => {
        this.getBusinessList(res);
      });
    this.subjectServiceSearch$
      .pipe(distinctUntilChanged(), debounceTime(400))
      .subscribe((res) => {
        this.getItemAndServiceListing(res);
      });
    this.initializeBaseModel();
    this.initializeDailyBaseModel();
    this.initializeExpModal();
    this.initializeInvnAccptReject();
    this.shareDocmail = this.fb.group({
      email: ['', Validators.required],
    });
    this.DownloadFilter = this.fb.group({

    })
    // this.formGroup = this.formBuilder.group({
    //   bill_board_transaction_type: [null, [Validators.required]],
    //   with_Business_information: [null, [Validators.required]],
    //   reference_no: [null, [Validators.required]],
    //   invoice_date: [null, [Validators.required]],
    //   payment_method: null,
    //   remarks: null,
    //   currency: [null, [Validators.required]],
    //   sub_total: [null, [Validators.required]],
    //   discount_percent: [null, [Validators.required]],
    //   discount_price: [null, [Validators.required]],
    //   total_price: [null, [Validators.required]],
    //   item_and_description: [[], [Validators.required]],
    //   shipping_Business_information_id: {},
    //   estimate_time_of_delivery: null,
    //   reasons_of_return: null,
    // });
  }
  
  resetBillBookFilters() {
    this.billBookFilters.search_query = '';
    this.billBookFilters.start_date = '';
    this.billBookFilters.end_date = '';
  }

  resetBillBookBusinessId()
  {
    this.billBookFiltersPurchasereturn.business_id=null;
    this.billBookFiltersExpensereturn.business_id=null;
    this.billBookFiltersSalereturn.business_id=null;
  }
      initializeInvnAccptReject() {
        this.acceptreject = {
          id: null,
          accept_or_reject_status: "accepted",
          bill_board_transaction_type:1,
          advance_payment_status: false,
          advance_payment_type: "",
          bank_room: null,
          credit_card_id: null,
          expense_category: 0,
          adjust_stock: [
            {
              invoice_item_id: null,
              inventory_product_id: null,
              new_product: false
            }
            // Add more adjust_stock items if needed
          ]
        };

        this.reject ={
          id: null,
          accept_or_reject_status: "rejected",
        }
        this.accept ={
          id: null,
          accept_or_reject_status: "accepted",
        }
        this.invoiceaccptreject={
          id: null,
          accept_or_reject_status: "accepted",
          advance_payment_status: true,
          advance_payment_type: "cash",
          bank_room: null,
          credit_card_id: null,
        }
        this.purchaseAcceptInvoic= {
          id: null,
          accept_or_reject_status: "accepted",
          advance_payment_status: "false",
          bank_room: null,
          credit_card_id: null,
          expense_category: 0,
          adjust_stock: [
            {
              invoice_item_id: null,
              inventory_product_id: null,
              new_product: false,
              unit_price: null
            }
            // Add more adjust_stock items if needed
          ]
        }
        
        this.expenseAcceptInvoice={
          id: null,
          accept_or_reject_status: "accepted",
          advance_payment_status: "false",
          expense_category: 1,
          bill_board_transaction_type: 9,
          adjust_stock: [
            {
              invoice_item_id: null,
              inventory_product_id: null,
              new_product: false,
              unit_price: null
            }
            // Add more adjust_stock items if needed
          ]
        }
        this.expenseAcceptService={
          id: null,
          accept_or_reject_status: "accepted",
          advance_payment_status: "false",
          expense_category: 1,
          advance_payment_type: "cash",
          bank_room: null,
          credit_card_id: null,
         // bill_board_transaction_type: 9,
        }
        this.expenseAcceptServicewithoutDeposit={
          id: null,
          accept_or_reject_status: "accepted",
          advance_payment_status: "false",
          expense_category: 1,
         // bill_board_transaction_type: 9,
        }

      }
      
  SummaryViewCheck(val)
  {
    this.summryCheck=val;
    this.accountView=false;
    console.log("summary Check");
  }
  alertsViewCheck(val)
  {
    this.alertView=val;
    console.log("alert Check")
  }
  initializeExpModal()
  {
    this.expbaseModal=
    {
      id:null,
      amount_balance:0,
      expense_transaction_type:1,
      ledger_account_link:0,
      customer_name:'Vendor',
      expense_invoice_reference_link:0,
      expense_return_invoice_number:"exp_ref",
      starting_date:this.formatDate(new Date()),
      information:"",
      expense_category:null,
      file:[],
      expense_own_transaction:true,
      record_type:'cash',
      memo_entry:true,
      bank_id:null,
      credit_card_id:null
    }
  }
  initializeBaseModel() {
    this.baseModel = {
      id: null,
      bill_board_transaction_type: this.baseModel?.bill_board_transaction_type?.toString(),
      with_Business_information: null,
      order_id:null,
      reference_no: null,
      invoice_date: this.formatDate(new Date()),
      payment_method: null,
      remarks: null,
      currency: null,
      sub_total: null,
      discount_percent: null,
      expense_own_transaction: false,
      web: true,
      reference_purchase_order: null,
      discount_price: null,
      total_price: null,
      item_and_description: [],
      attachments:[],
      record_type:'',
      shipping_price: null,
      estimate_invoice: null,
      packaging_slip_reference_no:null,
      shipping_Business_information: {
        business_name:null,
        id: null,
        shipping_zip_or_postal_code: null,
        shipping_state_or_province: null,
        full_phone_number: null,
        business_image: null,
        shipping_address: null,
        shipping_city: null,
        shipping_country: null,
        email: null,
      },
      tax_details: {
      },
      sub_total_type: 1,
      estimate_time_of_delivery: this.formatDate(new Date()),
      reasons_of_return: null,
      tax_percent: null,
      tax_price: null,
      service_invoice: null,
      product_invoice: null,
      advance_payment_price: null,
      advance_payment_percent: null,
      payment_terms: null,
      expense_category: null,
      expense_item_or_service: [],
      overdue_date:  this.formatDate(new Date()),
      user_invoice_number: null,
      advance_payment_status: false,
      advance_payment_type: '',
      customer_name: 'Customer',
      bank_room: null,
      balance_amount: null,
      credit_card_id: null,
      invoice_id: null,
      // accept_or_reject_status: 'sent'
    };
  }


  initializeDailyBaseModel() {
    this.DailybaseModel = {
      attachments:[],
      balance_amount: null,
      bill_board_transaction_type: "11",
      currency: null,
      discount_percent: null,
      discount_price: null,
      invoice_date: this.formatDate(new Date()),
      invoice_type:null,
      item_and_description: [],
      payment_method: null,
      product_invoice: true,
      reference_no: null,
      reference_purchase_order:null,
      remarks: null,
      service_invoice: false,
      shipping_price:null,
      sub_total: null,
      sub_total_type: 1,
      total_price: null,
      tax_details: {},
      record_type:'',
      tax_percent: null,
      tax_price: null,
      advance_payment_price: null,
      advance_payment_percent: null,
      payment_terms: null,
      user_invoice_number: null,
      shipping_address:null,
      customer_name: 'Customer',
      payments:[]
    };
  }

  resetDiscountTax() {
    this.baseModel.discount_percent = null;
    this.baseModel.discount_price = null;
    this.baseModel.tax_price = null;
    this.baseModel.tax_percent = null;
  }
  resetShippingBillBook() {
    this.baseModel.shipping_price = 0;
  }
  getItemAndServiceListing(query: any = '') {
    this.http.getItemAndServiceListing(1, query).subscribe((res: any) => {
      console.log( res);
      this.itemServiceList = res;
    });
  }
  // getItemAndServiceListing(query: any = '') {
  //   this.http.getMarketListingBundles(4248,query,'all').subscribe((res: any) => {
  //     console.log(res);
  //     this.itemServiceList = res;
  //   });
  //}
  
  getSingleInvoiceBookInfo(id) {
    this.loader = true;
    // this.transactionId=id;
    this.http.getSingleInvoiceBookInfo(id).subscribe(
      (res: any) => {
        debugger;
        console.log("done");
        this.selectedInvoice = res['result'];
        this.selectedInvoiceItem=this.selectedInvoice;
        console.log("Invoice", this.selectedInvoice)
        this.purchaseOrder=this.selectedInvoice.reference_purchase_order;
        this.SelectedID = this.selectedInvoice.id
        console.log("Azmat khan", this.SelectedID)
        this.loader = false;
      },
      (error: any) => {
        this.loader = false;
      }
    );
  }

  // filterDownload( startDate: any= null, endDate: any= null, catID: any=null, userId: any=null, itemList: any= [], itemName: any='',  inctype: any=this.invoiceType, ispdf: boolean=true,){
  //   this.loaderBillAdd = true;
  // this.http.getPDFRooms(inctype,ispdf,startDate,endDate,catID,userId,itemName,itemList)
  // .subscribe(
  //   (data: ArrayBuffer) => {      
  //     console.log(inctype);
  //           const blob = new Blob([data], { type: 'application/pdf' });
  //           const downloadURL = URL.createObjectURL(blob);
  //           const link = document.createElement('a');
  //           link.href = downloadURL;
  //           let item;
  //           this.translate.get('Invoice').subscribe((data: any) => {
  //             item = data;
  //           });
  //           link.download = 'Invoice Summary';
  //           link.click();
  //           this.loaderBillAdd = false;
  //   },
  //   (error) => {
  //     let toast;
  //     this.translate.get('No Transaction Found').subscribe((data: any) => {
  //       toast = data;
  //     });
  //     this.toast.warning(toast);
  //     this.loadPdf = false;
  //   }
  // );
  // }

  
  filterDownload(
    startDate: any = null,
    endDate: any = null,
    catID: any = null,
    userId: any = null,
    itemList: any = [],
    itemName: any = '',
    inctype: any = this.invoiceType,
    ispdf: boolean = true, // Set default value to true or change as per your requirement
  ) {
    
    this.loaderBillAdd = true;
    this.http.getPDFRooms(inctype, ispdf, startDate, endDate, catID, userId, itemName, itemList)
      .subscribe(
        (data: ArrayBuffer) => {
          
          
            console.log(inctype);
            const blob = new Blob([data], { type: 'application/pdf' });
            debugger
            if (blob.size < 100) {
              this.toast.error('No Transaction Found');
              this.modalService.dismissAll()
            }
            else{
            const downloadURL = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadURL;
    
            // Set download filename based on invoiceType
            switch (inctype) {
              case 1:
                link.download = 'Invoice Summary';
                break;
              case 2:
                link.download = 'Purchase Bill Summary';
                break;
              case 8:
                link.download = 'Expense Bill Summary';
                break;
              case 10:
                link.download = 'Sale Receipt Summary';
                break;
              case 11:
                link.download = 'Daily Sale Receipt Summary';
                break;
              default:
                link.download = 'Summary';
                break; // Default filename if invoiceType is not recognized
            }
    
            link.click();
            this.loaderBillAdd = false;
          }
        },
        (error) => {
          let toast;
          this.translate.get('No Transaction Found').subscribe((data: any) => {
            toast = data;
          });
          this.toast.warning(toast);
          this.loadPdf = false;
        }
      );
  }
  
  
   

  getTransactionSingleInvoice(id){
    this.loader = true;
    this.http.getTransactionSingleInvoice(id).subscribe(
      (res: any) => {
        this.SingleInvoiceTrans = res
        this.SingleTransaction = res['transactions']
        
        console.log(this.SingleTransaction)
        this.loader = false;
      },
      (error: any) => {
        this.loader = false;
      }
    );
  }

    getbillBookFiles(id){
    this.loader = true;
    this.http.getBillBookfiles(id).subscribe(
      (res: any) => {
        this.Images = res['files'];
        console.log("Invoice", this.Images)
        this.loader = false;
      },
      (error: any) => {
        this.loader = false;
      }
    );
  }
  getSingleSaleDetail(id) {
    this.loader = true;
    this.http.getSingleSaleTransaction(id).subscribe(
      (res: any) => {
         
        this.selectedDetailData = res['sale_transaction_data'];
        console.log("Sale Detail", this.selectedDetailData)
        this.SelectedID = this.selectedDetailData.id
        console.log("Azmat khan Sale", this.SelectedID)
        this.loader = false;
      },
      (error: any) => {
        this.loader = false;
      }
    );
  }
  getSinglePurchaseDetail(id) {
    this.loader = true;
    this.http.getSinglePurchaseTransaction(id).subscribe(
      (res: any) => {
         
        this.selectedDetailData = res['purchase_transaction_data'];
        console.log("Purchase Detail", this.selectedDetailData)
        this.SelectedID = this.selectedDetailData.id
        console.log("Azmat khan Sale", this.SelectedID)
        this.loader = false;
      },
      (error: any) => {
        this.loader = false;
      }
    );
  }
  getSingleExpenseDetail(id) {
    this.loader = true;
    this.http.getSingleExpenseTransaction(id).subscribe(
      (res: any) => {
        this.selectedDetailExData = res['expense_transaction_data'];
         console.log("EXPENSE Detail", this.selectedDetailExData);
        //  this.invoiceType=8;
        // this.bookService.expType = 'Memo';
        // this.bookService.expbaseModal.customer_name=this.bookService.selectedDetailExData.customer_name;
        // this.bookService.expbaseModal.amount_balance=this.bookService.selectedDetailExData.amount_balance;
        // this.SelectedID = this.selectedDetailData.id
        // console.log("Azmat khan EXPENSE", this.SelectedID)
        // this.loader = false;
      },
      (error: any) => {
        this.loader = false;
      }
    );
  }
  getTransagainstSingle(id){
    this.loader = true;
    this.http.getSingleTransInvoice(id).subscribe(
      (res: any) => {
        console.log(res);
        this.selectedSingleInvoice = res;
        console.log("Check Single Trans", this.selectedSingleInvoice)
        this.loader = false;
      },
      (error: any) => {
        this.loader = false;
      }
    );
  }

  getComments(id)
  {
    this.loader=true;
    this.http.getComment(id).subscribe(
      (res: any) => {
        console.log(res);
        this.commentData=res['comments'];
        this.loader=false;
        // this.toast.success(res['detail']);
        
      });
  }

  postComments(id,body)
  {
    this.http.postComment(id,body).subscribe(
      (res: any) => {
        console.log(res);
        this.getComments(body);        
      });
  }
  
  DateFormatepipe() {
    if (this.auth.userData.emp_info.Business.default_date_format.id === 1) {
      this.dateFormat = 'dd-MM-yyyy';
    } else if (
      this.auth.userData.emp_info.Business.default_date_format.id === 2
    ) {
      this.dateFormat = 'MM-dd-yyyy';
    } else {
      this.dateFormat = 'yyyy-MM-dd';
    }
  }
  //get invoice number for all invoices
  getInvoiceNumber(type)
  {
    this.invoiceType=type;
    console.log("invoicetype" , type);
    this.http.getInvoicesNumber(type).subscribe((res:any)=>
    {
      this.invoiceNumb=res.user_invoice_number
      this.baseModel.user_invoice_number=res.user_invoice_number;
      this.DailybaseModel.user_invoice_number=res.user_invoice_number;
      console.log(this.baseModel.user_invoice_number);
      console.log(res);
    })
  }
  deleteFile(id) {
    debugger
    this.previewUrl = this.previewUrl.filter(x => x.id !== id);
    let array = [];
    for (let item of this.previewUrl) {
      array.push(item.id)
    }
    this.expbaseModal.attachments = [];
    this.expbaseModal.file=[];
    this.baseModel.attachments=[];
    this.baseModel.attachments=array;
    this.expbaseModal.attachments=array;
    this.expbaseModal.file=array;
    this.baseModel.patchValue({
      file: array
    });
    this.expbaseModal.patchValue({
      file: array
    })
  }


  // Saad Code

//  getFilterInvoiceBook(filter: any = {}) {
//   // Set the loader to true before making the API call
//   this.ledgerService.InvLoader = true;
//   console.log('Loader set to true before API call');

//   // Clear the invoice lists
//   if (this.page==1)
//   {
//     this.invoiceListing = [];
//     this.InoVoicess = [];
//   }
//   // Format the date
//   this.DateFormatepipe();

//   // Make the API call
//   this.http.getFilterInvoiceBook(this.page, this.billBookFilters).subscribe({
//     next: (res: any) => {
//       // API call succeeded
//       console.log('API response:', res);

//       // Update invoice lists with the response
//       this.invoiceListing = res;
//       this.InoVoicess = res['result'];
//       console.log('Invoice list:', this.InoVoicess);

//       // Update total records
//       this.TotalRecordInvoices = res['totalRecords'];

//       // Set the loader to false after processing the response
//       this.ledgerService.InvLoader = false;
//       console.log('Loader set to false after successful API response');
//       this.page++
//     },
//     error: (error: any) => {
//       // API call failed
//       console.error('API error:', error);

//       // Set the loader to false after handling the error
//       this.ledgerService.InvLoader = false;
//       console.log('Loader set to false after API error');
//     },
//     complete: () => {
//       // Optionally handle completion
//       console.log('API call completed');
//     }
//   });
// }

getFilterInvoiceBook(filter: any = {}) {
  // Set the loader to true before making the API call
  debugger;
  this.ledgerService.loader = true;
  this.ledgerService.loaderSale= true;
  console.log('Loader set to true before API call');

  // Initialize invoice lists if not already initialized
  if (!this.invoiceListing) {
    this.invoiceListing = [];
  }
  if (!this.InoVoicess) {
    this.InoVoicess = [];
  }

  // Clear the invoice lists if page is 1
  if (this.page == 1) {
    this.invoiceListing = [];
    this.InoVoicess = [];
  }
  
  // Format the date
  this.DateFormatepipe();

  // Make the API call
  this.http.getFilterInvoiceBook(this.page, this.billBookFilters).subscribe({
    next: (res: any) => {
      // API call succeeded
      console.log('API response:', res);

      // Update InoVoicess with the response
      this.InoVoicess = res['result'];
      this.saleTotalPages= res['totalPages'];
      // If page is greater than 1, concatenate the existing invoices with the new ones
      // if (this.page > 1) {
      //   this.InoVoicess = [...this.invoiceListing, ...this.InoVoicess];
      // } else {
      //   // Otherwise, just assign the new invoices
      //   this.invoiceListing=this.InoVoicess
      // }
      // console.log('Invoice list:', this.invoiceListing);

      // Update total records
      this.TotalRecordInvoices = res['totalRecords'];

      // Set the loader to false after processing the response
      this.ledgerService.loader = false;
      console.log('Loader set to false after successful API response');
      
      // Increment the page number
      // this.page++;
    },
    error: (error: any) => {
      // API call failed
      console.error('API error:', error);

      // Set the loader to false after handling the error
      this.ledgerService.loader = false;
      this.ledgerService.loaderSale= false;
      console.log('Loader set to false after API error');
    },
    complete: () => {
      // Optionally handle completion
      console.log('API call completed');
    }
  });
}


// getFilterInvoiceBook(filter: any = {}) {
//   this.isLoading = true;
//   this.ledgerService.InvLoader = true;

//   // Clear the invoice lists only if it's the first page
//   if (this.page === 1) {
//     this.invoiceListing = [];
//     this.InoVoicess = [];
//   }

//   this.DateFormatepipe();

//   this.http.getFilterInvoiceBook(this.page, this.billBookFilters).subscribe({
//     next: (res: any) => {
//       console.log('API response:', res);
//       if (res && Array.isArray(res) && res.length > 0) {
//         this.invoiceListing = this.invoiceListing.concat(res);
//         console.log('invoiceListing after concat:', this.invoiceListing);
//       } else {
//         console.warn('API response is not an array or is empty:', res);
//       }
      
//       if (res && res['result']) {
//         this.InoVoicess = this.InoVoicess.concat(res['result']);
//         console.log('InoVoicess after concat:', this.InoVoicess);
//       } else {
//         console.warn('API response does not have result property:', res);
//       }

//       this.TotalRecordInvoices = res && res['totalRecords'] ? res['totalRecords'] : 0;
//       console.log('TotalRecordInvoices:', this.TotalRecordInvoices);

//       this.isLoading = false;
//       this.ledgerService.InvLoader = false;
//       console.log('Loader set to false after successful API response');
//     },
//     error: (error: any) => {
//       console.error('API error:', error);
//       this.isLoading = false;
//       this.ledgerService.InvLoader = false;
//     },
//     complete: () => {
//       console.log('API call completed');
//     }
//   });
// }



// Saad Code end

  //invoices forms 9 forms
  // getFilterInvoiceBook(filter: any = {}) {
  //   // debugger;
  //   this.ledgerService.loader=true;
  //   this.invoiceListing = [];
  //   this.InoVoicess=[];
  //   this.DateFormatepipe();
  //   this.http.getFilterInvoiceBook(1, this.billBookFilters).subscribe(
  //     (res: any) => {
  //        console.log(res);
  //       this.invoiceListing = res;
  //       this.InoVoicess = res['result']
  //       console.log("Invoice list", this.InoVoicess)
  //       this.TotalRecordInvoices = res['totalRecords']
  //       this.ledgerService.loader=false;
  //     },
  //     (error: any) => {
  //       this.ledgerService.loader=false;
  //     }
  //   );
  // }

  // getFilterInvoiceBook(filter: any = {}) {
  //   this.ledgerService.loader = true;
  //   this.invoiceListing = [];
  //   this.InoVoicess = [];
  //   this.DateFormatepipe();
  //   this.http.getFilterInvoiceBook(1, this.billBookFilters)
  //     .pipe(finalize(() => this.ledgerService.loader = false))
  //     .subscribe(
  //       (res: any) => {
  //         console.log(res);
  //         this.invoiceListing = res;
  //         this.InoVoicess = res['result'];
  //         console.log("Invoice list", this.InoVoicess);
  //         this.TotalRecordInvoices = res['totalRecords'];
  //       },
  //       (error: any) => {
  //         // handle error here
  //       }
  //     );
  // }

  // getFilterInvoiceBook(filter: any = {}) {
  //   this.zone.run(() => {
  //     this.ledgerService.loader = true;
  //     console.log('Setting loader to true');
  //     // this.cdr.detectChanges(); // Explicitly trigger change detection after setting loader to true
  //     this.invoiceListing = [];
  //     this.InoVoicess = [];
  //     this.DateFormatepipe();

  //     this.http.getFilterInvoiceBook(1, this.billBookFilters)
  //       .pipe(
  //         finalize(() => {
  //           console.log('Finalize: Setting loader to false');
  //           this.zone.run(() => {
  //             this.ledgerService.loader = false;
  //             // this.cdr.detectChanges(); // Explicitly trigger change detection after setting loader to false
  //           });
  //         })
  //       )
  //       .subscribe(
  //         (res: any) => {
  //           console.log('Response received');
  //           this.zone.run(() => {
  //             console.log(this.ledgerService.loader)
  //             this.invoiceListing = res;
  //             if(this.invoiceType==1)
  //             {
  //               // this.ledgerService.loader= true;
  //               this.InoVoicess = res['result'];
  //               console.log(this.ledgerService.loader);
  //             }
  //             console.log("Invoice list", this.InoVoicess);
  //             this.TotalRecordInvoices = res['totalRecords'];
  //             // this.cdr.detectChanges(); // Explicitly trigger change detection after updating the data
  //           });
  //         },
  //         (error: any) => {
  //           console.log('Error received');
  //           this.zone.run(() => {
  //             this.ledgerService.loader = false;
  //             // this.cdr.detectChanges(); // Explicitly trigger change detection in case of error
  //           });
  //         }
  //       );
  //   });
  // }

  // getFilterInvoiceBook(filter: any = {}) {
  //   this.invoiceListing = [];
  //   this.TotalRecordInvoices=[];
  //   this.zone.run(() => {
  //     this.ledgerService.loader = true;
  //     console.log('Setting loader to true at:', new Date());
  //     this.InoVoicess = [];
  //     this.DateFormatepipe();
  //     console.log(this,this.ledgerService.loader);
  //     this.http.getFilterInvoiceBook(1, this.billBookFilters)
  //       .pipe(
  //         finalize(() => {
  //           console.log('Finalize: Setting loader to false at:', new Date());
  //           this.zone.run(() => {
  //             this.ledgerService.loader = false;
  //             console.log('Loader state after finalize:', this.ledgerService.loader);
  //           });
  //         })
  //       )
  //       .subscribe(
  //         (res: any) => {
  //           // debugger;
  //           this.ledgerService.loader=true;
  //           console.log(this.ledgerService.loader);
  //           console.log('Response received at:', new Date());
  //           this.zone.run(() => {
  //             this.invoiceListing = res;
  //               this.InoVoicess = res['result'];
  //             console.log('Invoice list:', this.InoVoicess);
  //             this.TotalRecordInvoices = res['totalRecords'];
  //             console.log('Loader state after data load:', this.ledgerService.loader);
  //           });
  //         },
  //         (error: any) => {
  //           console.log('Error received at:', new Date());
  //           this.zone.run(() => {
  //             this.ledgerService.loader = false;
  //             console.log('Loader state after error:', this.ledgerService.loader);
  //           });
  //         }
  //       );
  //   });
  // }
  getinvoicesForSaleReuturn()
  {
    this.ledgerService.loader=true;
    this.invoiceListing = [];
    this.DateFormatepipe();
    this.http.getFilterInvoiceBook(1, this.billBookFilterInvoicesSaleReturn).subscribe(
      (res: any) => {
         console.log(res);
        this.invoiceListing = res;
        this.InoVoicessForReturn = res['result']
        console.log("Invoice list", this.InoVoicess)
        this.TotalRecordInvoices = res['totalRecords']
        this.ledgerService.loader=false;
      },
      (error: any) => {
        this.ledgerService.loader=false;
      }
    );
  }
  getAllFiterInvoices()
  {
    // // alert("reload");
    this.ledgerService.loader=true;
    this.invoiceListing = [];
    this.DateFormatepipe();
    this.http.getFilterInvoiceBook(1, this.billBookAllFilters).subscribe(
      (res: any) => {
         console.log(res);
        this.invoiceListing = res;
        this.InoVoicess = res['result']
        console.log("Invoice list", this.InoVoicess)
        this.TotalRecordInvoices = res['totalRecords']
        this.ledgerService.loader=false;
      },
      (error: any) => {
        this.ledgerService.loader=false;
      }
    );
  }
  getFilterInvoiceBooksalereturn(filter: any = {}) {
  
    this.ledgerService.loader=true;
    this.invoiceListing = [];
    this.DateFormatepipe();
    // if (this.billBookFilters.bill_board_transaction_type=="4"){
      this.http.getFilterInvoiceBook(1, this.billBookFiltersSalereturn).subscribe(
        (res: any) => {
           console.log(res);
          this.invoiceListing = res;
          this.InoVoicesslist = res['result'];
          this.InoVoicessForReturn = res['result'];
          console.log("Invoice list", this.InoVoicesslist)
          this.TotalRecordInvoiceslist = res['totalRecords']
          this.ledgerService.loader=false;
          // this.resetBillBookBusinessId();
          // this.getinvoicesForSaleReuturn();
        },
        (error: any) => {
          this.ledgerService.loader=false;
        }
      );
    // }
  }

  getFilterInvoiceBookpackingSlip(filter: any = {}) {
   
   this.ledgerService.loader=true;
   this.invoiceListing = [];
   this.DateFormatepipe();
   // if (this.billBookFilters.bill_board_transaction_type=="4"){
     this.http.getFilterInvoiceBook(1, this.billbookFilterPackingSlipSelect).subscribe(
       (res: any) => {
          console.log(res);
         this.invoiceListing = res;
         this.InoVoicesslist = res['result'];
         this.InoVoicessForReturn = res['result'];
         console.log("Invoice list", this.InoVoicesslist)
         this.TotalRecordInvoiceslist = res['totalRecords']
         this.ledgerService.loader=false;
        //  this.getFilterInvoiceBook();
         // this.resetBillBookBusinessId();
         // this.getinvoicesForSaleReuturn();
       },
       (error: any) => {
         this.ledgerService.loader=false;
       }
     );
   // }
 }
  getFilterInvoiceBooksalereturnAccount(filter: any = {}) {
    // alert("reload");
   this.ledgerService.loader=true;
   this.invoiceListing = [];
   this.DateFormatepipe();
   // if (this.billBookFilters.bill_board_transaction_type=="4"){
     this.http.getFilterInvoiceBook(1, this.billBookFiltersSalereturnAccount).subscribe(
       (res: any) => {
          console.log(res);
         this.invoiceListing = res;
         this.InoVoicessForReturn = res['result']
         console.log("Invoice list", this.InoVoicesslist)
         this.TotalRecordInvoiceslist = res['totalRecords']
         this.ledgerService.loader=false;
         this.resetBillBookBusinessId();
       },
       (error: any) => {
         this.ledgerService.loader=false;
       }
     );
   // }
 }
  // getFilterInvoiceBooksalereturn(filter: any = {}): Promise<any> {
  //   return new Promise((resolve, reject) => {
  //     this.ledgerService.loader = true;
  //     this.invoiceListing = [];
  //     this.DateFormatepipe();
  //     if (this.billBookFilters.bill_board_transaction_type == "4") {
  //       this.http.getFilterInvoiceBook(1, this.billBookFiltersSalereturn).subscribe(
  //         (res: any) => {
  //           console.log(res);
  //           this.invoiceListing = res;
  //           this.InoVoicesslist = res['result'];
  //           console.log("Invoice list", this.InoVoicesslist);
  //           this.TotalRecordInvoiceslist = res['totalRecords'];
  //           this.ledgerService.loader = false;
  //           resolve(res); // Resolve the promise with the response
  //         },
  //         (error: any) => {
  //           this.ledgerService.loader = false;
  //           reject(error); // Reject the promise with the error
  //         }
  //       );
  //     } else {
  //       // If the condition is not met, resolve the promise with an empty result
  //       resolve([]);
  //     }
  //   });
  // }
  
  getFilterInvoiceBookPackingslip(filter: any = {}) {
    // // alert("reload");
    this.ledgerService.loader=true;
    this.invoiceListing = [];
    this.DateFormatepipe();
    // if (this.billBookFiltersPackingSlip.bill_board_transaction_type=="7"){
      this.http.getFilterInvoiceBook(1, this.billBookFiltersPackingSlip).subscribe(
        (res: any) => {
          this.ledgerService.loader=false;
           console.log(res);
          this.invoiceListing = res;
          this.InoVoicesslist = res['result']
          this.InoVoicess=res['result']
          console.log("Invoice list", this.InoVoicesslist)
          this.TotalRecordInvoiceslist = res['totalRecords']
          // this.getinvoicesForSaleReuturn();
        },
        (error: any) => {
          this.ledgerService.loader=false;
        }
      );
   // }
  }
  getFilterInvoiceBookPurchasereturn(filter: any = {}) {
    this.ledgerService.loader=true;
    this.billListing = [];
    this.DateFormatepipe();
    if (this.billBookFilters.bill_board_transaction_type=="5"){
      this.http.getFilterInvoiceBook(1, this.billBookFiltersPurchasereturn).subscribe(
        (res: any) => {
           console.log(res);
            
          this.billListing = res;
          this.Billslist = res['result']
          console.log("Bill list", this.Billslist)
          this.TotalRecordInvoiceslist = res['totalRecords']
          this.ledgerService.loader=false;
          this.resetBillBookBusinessId();
        },
        (error: any) => {
          this.ledgerService.loader=false;
        }
      );
    }
  }
  getFilterInvoiceBookexpensereturn(filter:any={}) {
    this.ledgerService.loader=true;
    this.billListing = [];
    this.DateFormatepipe();
    if (this.billBookFilters.bill_board_transaction_type=="9"){
      // this.billBookFiltersExpensereturn.business_id = this.businessId;
      this.billBookFiltersExpensereturn.bill_board_transaction_type='8'
      this.http.getFilterInvoiceBook(1, this.billBookFiltersExpensereturn).subscribe(
        (res: any) => {
           console.log(res);
            
           this.invoiceListing = res;
          //  this.invoiceListing = res['result']
           this.InoVoicesslist=res['result']
           console.log("Bill list", this.InoVoicesslist)
           this.TotalRecordInvoiceslist = res['totalRecords']
          //  this.InoVoicesslist= res['totalRecords']
           this.ledgerService.loader=false;
           this.resetBillBookBusinessId();
        },
        (error: any) => {
          this.ledgerService.loader=false;
        }
      );
    }
  }
  get appliedFiltersBill() {
    let controls = [];
    Object.keys(this.billBookFilters).forEach((key, index) => {
      if (index > 0 && this.billBookFilters[key]) {
        controls.push({
          key: key,
          value: this.billBookFilters[key],
        });
      }
    });
    return controls;
  }
  removeFilterItem(item) {
    this.billBookFilters[item] = '';
    this.getFilterInvoiceBook();
  }
  getInvoiceShareLink() {
    this.loadShareLink = true;
    this.http.getShareInvoice(this.selectedInvoice?.id, 'True').subscribe(
      (res: any) => {
        this.invoiceShareLink = res?.pdf_link;
        this.loadShareLink = false;
      },
      (error) => {
        this.loadShareLink = false;
      }
    );
  }
  getFilterBillsByIdAsynchronous() {
    return new Promise((resolve, reject) => {
      this.loaderById = true;
      this.billListingById = [];
      let id =
        this.baseModel.bill_board_transaction_type == 5 ||
          this.baseModel.bill_board_transaction_type == 7
          ? 1
          : 2;
      this.http
        .getFilterInvoiceBook(1, { bill_board_transaction_type: id.toString() })
        .subscribe(
          (res: any) => {
            this.billListingById = res;
            this.loaderById = false;
            resolve(this.billListingById);
          },
          (error: any) => {
            reject(false);
            this.loaderById = false;
          }
        );
    })
  }
  getFilterBillsById() {
    this.loaderById = true;
    this.billListingById = [];
    let id =
      this.baseModel.bill_board_transaction_type == 5 ||
        this.baseModel.bill_board_transaction_type == 7
        ? 1
        : 2;
    this.http
      .getFilterInvoiceBook(1, { bill_board_transaction_type: id.toString() })
      .subscribe(
        (res: any) => {
          this.billListingById = res;
          this.loaderById = false;
        },
        (error: any) => {
          this.loaderById = false;
        }
      );
  }

  // getLedgerFilterBusiness(page: any = 1, query: any = '',check: boolean, AccType: any='')
  // {
  //   this.loader = true;
  //   this.http.getLedgerListBusiness(page, query,check, AccType).subscribe(
  //     (res: any) => {
  //       this.allBusinessList = res['result'];
  //        console.log(this.allBusinessList);
  //       ;
  //       this.loader = false;
  //     },
  //     (error) => {
  //       this.loader = false;
  //     }
  //   );

  // }
  getBusinessList(page: any = 1, query: any = ''  ) {
    this.loader = true;
    this.http.getLedgerListBusiness(page, query).subscribe(
      (res: any) => {
        this.allBusinessList = res['result'];
        this.vendorList = this.allBusinessList.filter(item => item.account_type === 'Vendor'); 
        //this.allBusinessList=this.vendorList;
        ;
        this.loader = false;
      },
      (error) => {
        this.loader = false;
      }
    );
  }
  getBusinessListFilter()
  {
    // // alert("getbisiness Filter");
    this.loader = true;
    let transactionType
    
    if(this.invoiceType)
    {
      transactionType=this.invoiceType
    }
    else
    {
      transactionType = this.billBookFilters.bill_board_transaction_type;
    }
    
    //  
    this.http.getInvoicesNumber(this.billBookFilters.bill_board_transaction_type).subscribe((res:any)=>
    {
      console.log(res);
    })
    //  // alert(transactionType);
    //  
    if(transactionType=='1' || transactionType=='7' || transactionType=='12')
    {
      this.accType='Customer'
      this.check=false
      this.purAccount=false
      this.expAccount=false
      this.getInvoice=false;
    }
    if(transactionType=='2')
    {
      this.accType='Vendor'
      this.check=true
      this.purAccount=true
      this.expAccount=false
      this.getInvoice=false;
    }
    if (transactionType=='6')
    {
      this.accType='Vendor'
      this.check=false
      this.purAccount=false
      this.expAccount=false
      this.getInvoice=false;
    }
    if (transactionType=='8')
    {
      this.accType='Vendor'
      this.check=true
      this.purAccount=false
      this.expAccount=true
      this.getInvoice=false;
    }
    if (transactionType=='9')
    {
      this.accType='Vendor'
      this.check=false
      this.purAccount=false
      this.expAccount=true
      this.getInvoice=false;
    }
    if (transactionType=='3')
    {
      this.accType='Customer'
      this.check=false
      this.purAccount=false
      this.expAccount=false
      this.getInvoice=false;
    }
    else
    {
      this.getInvoice=true;
    }
    this.http.getLedgerListBusinessFilter(1, this.custVendQuery,this.accType,this.purAccount,this.expAccount).subscribe(
      (res: any) => {
        //  console.log(this.getInvoice);
        //  // alert(this.getInvoice)
        if(this.getInvoice==true)
        {
          this.allBusinessList = res['result'];
          console.log(this.allBusinessList);
        }
       else if(transactionType=='3')
        {
          this.allBusinessList = res['result'];
          console.log(this.allBusinessList);
        }
        else
        {
          this.allBusinessList =[];
        }
        this.loader = false;
      },
      (error) => {
        this.loader = false;
      }
    );
  }
  // postInvoiceSeries(){
  //   this.http.postInvoiceSeries(type,status,Nmbr,Check,PrefixText)
  // }
  getPDFInvoice() {
    this.loadPdf = true;
    console.log(this.selectedInvoice?.id,'Expense Receipt id')
    this.http.getPDFInvoice(this.selectedInvoice?.id, true)
    .subscribe(
      (res: ArrayBuffer) => {
        let blob = new Blob([res], { type: 'application/pdf' });
        var downloadURL = URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = this.selectedInvoice?.user_invoice_number + '.pdf';
        link.click();
        this.loadPdf = false;
        // this.toast.success('PDF download successfully');
      },
      (error) => {
        this.loadPdf = false;
      }
    );
  }
  getPDFSingleInvoice() {
    this.loadPdf = true;
    this.http.getPDFSingleInvoice(this.selectedInvoice?.id, true).subscribe(
      (res: ArrayBuffer) => {
        let blob = new Blob([res], { type: 'application/pdf' });
        var downloadURL = URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = this.SingleInvoiceTrans.user_invoice_number +' '+ 'Summary';
        link.click();
        this.loadPdf = false;
        // this.toast.success('PDF download successfully');
      },
      (error) => {
        this.loadPdf = false;
      }
    );
  }
  
  
  ShareDocuments(){
    const emailValue = this.shareDocmail.get('email').value;
    const DocId = this.SelectedID
    this.http.ShareDocument(emailValue,DocId).subscribe(res =>{
      this.toast.success(res['detail']);
      this.modalService.dismissAll();
    },
    (error) => {
      this.toast.error(error.error['msg']);
      this.modalService.dismissAll();
    })
  }
  getReferenceBill() {
    this.http.getReferenceBill().subscribe((res: any) => {
      this.baseModel.reference_no = res['reference_no'];
    });
  }
  getinvoiceSeries(){
    this.http.getinvoiceSeries().subscribe((res: any) => {
      this.invoiceSeriesStatus = res['invoice_prefix_status'];
      this.invoiceSeries = res['invoice_prefix_number'];
      this.purchaseBillSeriesStatus = res['purchase_bill_prefix_status'];
      this.purchaseBillSeries = res['purchase_bill_prefix_number'];
      this.expenseBillSeriesStatus = res['expense_bill_prefix_status'];
      this.expenseBillSeries = res['expense_bill_prefix_number'];
      this.expenseReturnSeriesStatus = res['expense_return_prefix_status'];
      this.expenseReturnSeries = res['expense_return_prefix_number'];
      this.purchaseReturnSeriesStatus = res['purchase_return_prefix_status'];
      this.purchaseReturnSeries = res['purchase_return_prefix_number'];
      this.saleReturnSeriesStatus = res['sale_return_prefix_status'];
      this.saleReturnSeries = res['sale_return_prefix_number'];
      this.quotationSeriesStatus = res['quotation_prefix_status'];
      this.SaleOrderSeriesStatus = res['sales_order_prefix_status'];
      this.quotationSeries = res['quotation_prefix_number'];
      this.SaleReceiptSeriesStatus = res['sale_receipt_prefix_status'];
      this.SaleReceiptSeries = res['sale_receipt_prefix_number'];
      this.packingSlipSeriesStatus = res['delivery_receipt_prefix_status'];
      this.packingSlipSeries = res['delivery_receipt_prefix_number'];
      console.log(res)
    });
  }
  formatDate(date) {
    if (date) {
      var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;
      return [year, month, day].join('-');
    } else {
      return '';
    }
  }
  returnCurrency(id: any = 0) {
    try {
      let currency = this.auth?.ledger_currencies?.find((x) => x.id == id);
      return currency?.code ? currency?.code : '';
    } catch (e) {
      return '';
    }
  }
  postItemBillBoard(body, modal) {
    this.loader = true;
    this.http.postItemBillBoard(body).subscribe(
      (res: any) => {
        if (res?.plan_expired || res?.limit_exceeded) {
          this.sendClickEvent();
          // this.toast.error('Plan expired please upgrade your plan');
          return;
        }
        this.loader = false;
        this.toast.success(res?.detail);
        modal.dismiss();
        this.getItemAndServiceListing();
      },
      (error) => {
        this.loader = false;
      }
    );
  }
  editItemBillBoard(body, id, modal) {
    this.loader = true;
    this.http.editItemBillBoard(body, id).subscribe(
      (res: any) => {
        this.loader = false;
        this.toast.success(res?.detail);
        modal.dismiss();
        this.getItemAndServiceListing();
      },
      (error) => {
        this.loader = false;
      }
    );
  }
  deleteItemBillBoard(item: any) {
    let title, text, cancel, confirm;
    this.translate.get('Do you want to delete?').subscribe((data: any) => {
      title = data;
    }),
      this.translate.get('Cancel').subscribe((data: any) => {
        cancel = data;
      }),
      this.translate.get('Delete').subscribe((data: any) => {
        confirm = data;
      }),
      Swal.fire({
        imageUrl: '../../../../assets/delete.png',
        imageHeight: '50',
        title: title,
        text: text,
        showCancelButton: true,
        cancelButtonColor: '#6e7d88',
        confirmButtonColor: '#dc3545',
        cancelButtonText: cancel,
        confirmButtonText: confirm
      }).then((result) => {
        if (result.value) {
          item.loader = true;
          this.http.deleteItemBillBoard(item?.id).subscribe(
            (res: any) => {
              item.loader = false;
              this.toast.success(res?.detail);
              this.getItemAndServiceListing();
            },
            (error) => {
              item.loader = false;
            }
          );
        }
      });
  }
  postDuplicateInvoice(body, modal) {
    this.http.postDuplicateInvoice(body).subscribe(
      (res: any) => {
        this.toast.success(res?.detail);
        this.modalService.dismissAll();
        this.selectedInvoice.status_record_as_data = true;
        { {  } }
        if (modal && this.selectedInvoice?.product_invoice) {
          this.ledgerService.getnventoryPro();
          this.modalService.open(modal, {
            centered: true,
            windowClass: 'ledger-participant',
            size: 'lg',
          });
        }
      },
      (error) => {
        this.toast.error(error?.error?.msg);
      }
    );
  }
  serviceAccept(modal)
  {
    this.loaderBillAdd = true;
    this.http.postacceptreject(this.accept).subscribe(
      (res: any) => {
        this.isBillTypeService = false;
        this.loaderBillAdd = false;
        this.toast.success(res?.detail);
        this.ledgerService.getPurchaseTran();
        this.getFilterInvoiceBook();
        this.modalService.dismissAll();
        return { apiSuccess: true }
      },
      (error) => {
        this.isBillTypeService = false;
        this.toast.error(error?.error?.detail);
        this.loaderBillAdd = false;
      }
    );
  }
  purchaseServiceAccept(modal)
  {
    this.loaderBillAdd = true;
    this.http.postPurchaseAccept(this.accept).subscribe(
      (res: any) => {
        this.isBillTypeService = false;
        this.loaderBillAdd = false;
        this.toast.success(res?.detail);
        modal.dismiss();
        this.ledgerService.getSaleTran()
        this.getFilterInvoiceBook();
        return { apiSuccess: true }
      },
      (error) => {
        this.isBillTypeService = false;
        this.toast.error(error?.error?.detail);
        this.loaderBillAdd = false;
      }
    );
  }
  postAcceptRejectInvoice(modal)
  {
    this.loaderBillAdd = true;
    this.http.postacceptreject(this.acceptreject).subscribe(
      (res: any) => {
        this.isBillTypeService = false;
        this.loaderBillAdd = false;
        this.toast.success(res?.detail);
        // modal.dismiss();
        // this.ledgerService.getSaleTran()
        // this.getFilterInvoiceBook();
        this.modalService.dismissAll();
        this.billBookFilters = {
          ...this.billBookFilters,
          bill_board_transaction_type: this.invoiceType,
          invoice_status_type: 'received',
        };
        this.getFilterInvoiceBook();
      
        return { apiSuccess: true }
      },
      (error) => {
        this.isBillTypeService = false;
        this.toast.error(error?.error?.detail);
        this.loaderBillAdd = false;
      }
    );
  }
  postExpenseAccept(modal)
  {
    this.loaderBillAdd = true;
    this.http.postExpenseAccept(this.expenseAcceptInvoice).subscribe(
      (res: any) => {
        this.isBillTypeService = false;
        this.loaderBillAdd = false;
        this.toast.success(res?.detail);
        this.modalService.dismissAll();
        // this.ledgerService.getSaleTran()
        // this.getFilterInvoiceBook();
        return { apiSuccess: true }
      },
      (error) => {
        this.isBillTypeService = false;
        this.toast.error(error?.error?.detail);
        this.loaderBillAdd = false;
      }
    );
  }
  postServiceAccpt(modal)
  {
    this.loaderBillAdd = true;
    this.http.postacceptreject(this.invoiceaccptreject).subscribe(
      (res: any) => {
        this.isBillTypeService = false;
        this.loaderBillAdd = false;
        this.toast.success(res?.detail);
        modal.dismiss();
        this.modalService.dismissAll();
        this.ledgerService.getSaleTran()
        this.getFilterInvoiceBook();
        return { apiSuccess: true }
      },
      (error) => {
        this.isBillTypeService = false;
        this.toast.error(error?.error?.detail);
        this.loaderBillAdd = false;
      }
    );
  }

  serviceExpEithoutDeposit(modal)
  {
    this.loaderBillAdd = true;
    this.http.postacceptreject(this.acceptreject).subscribe(
      (res: any) => {
        this.isBillTypeService = false;
        this.loaderBillAdd = false;
        this.toast.success(res?.detail);
        modal.dismiss();
        this.ledgerService.getSaleTran()
        this.getFilterInvoiceBook();
        this.modalService.dismissAll();
        return { apiSuccess: true }
      },
      (error) => {
        this.isBillTypeService = false;
        this.toast.error(error?.error?.detail);
        this.loaderBillAdd = false;
      }
    );
  }
  serviceExpenseWdeposit(modal)
  {
    this.loaderBillAdd = true;
    this.http.postacceptreject(this.expenseAcceptServicewithoutDeposit).subscribe(
      (res: any) => {
        this.isBillTypeService = false;
        this.loaderBillAdd = false;
        this.toast.success(res?.detail);
        modal.dismiss();
        this.modalService.dismissAll();
        this.ledgerService.getSaleTran()
        this.getFilterInvoiceBook();
        return { apiSuccess: true }
      },
      (error) => {
        this.isBillTypeService = false;
        this.toast.error(error?.error?.detail);
        this.loaderBillAdd = false;
      }
    );
  }
  postServiceAccptExpense(modal)
  {
    this.loaderBillAdd = true;
    this.http.postExpenseAccept(this.invoiceaccptreject).subscribe(
      (res: any) => {
        this.isBillTypeService = false;
        this.loaderBillAdd = false;
        this.toast.success(res?.detail);
        modal.dismiss();
        this.ledgerService.getSaleTran()
        this.getFilterInvoiceBook();
        return { apiSuccess: true }
      },
      (error) => {
        this.isBillTypeService = false;
        this.toast.error(error?.error?.detail);
        this.loaderBillAdd = false;
      }
    );
  }
  postPurchaseAccept(modal)
  {
    this.loaderBillAdd = true;
    this.http.postPurchaseAccept(this.purchaseAcceptInvoic).subscribe(
      (res: any) => {
        this.isBillTypeService = false;
        this.loaderBillAdd = false;
        this.toast.success(res?.detail);
        this.modalService.dismissAll();
      this.billBookFilters = {
        ...this.billBookFilters,
        bill_board_transaction_type: this.invoiceType,
        invoice_status_type:"received"
      };
      this.getFilterInvoiceBook();
        // this.ledgerService.getSaleTran()
        // this.getFilterInvoiceBook();
        return { apiSuccess: true }
      },
      (error) => {
        this.isBillTypeService = false;
        this.toast.error(error?.error?.detail);
        this.loaderBillAdd = false;
      }
    );
  }
  PostRejectInvoice(modal)
  {
    this.loaderBillAdd = true;
    this.http.postacceptreject(this.reject).subscribe(
      (res: any) => {
        this.isBillTypeService = false;
        this.loaderBillAdd = false;
        this.toast.success(res?.detail);
        modal.dismiss();
        this.ledgerService.getSaleTran()
        this.getFilterInvoiceBook();
        return { apiSuccess: true }
      },
      (error) => {
        this.isBillTypeService = false;
        this.toast.error(error?.error?.detail);
        this.loaderBillAdd = false;
      }
    );

  }
  postBillBoard(modal, type: any = 'Invoice', parentTransType: any = null, childTransType: any = null, sharedtransaction: any = '') {
    this.loaderBillAdd = true;
    
    if (this.isBillTypeService) {
      this.baseModel.service_invoice = true;
      this.baseModel.product_invoice = false;
    } else {
      this.baseModel.service_invoice = false;
      this.baseModel.product_invoice = true;
    }
    this.http.postBillBoard(this.baseModel).subscribe(
      (res: any) => {
        this.isBillTypeService = false;
        this.loaderBillAdd = false;
        if (res?.plan_expired || res?.limit_exceeded) {
          this.ledgerService.sendClickEvent();
          return;
        }
        this.toast.success(res?.detail);
        modal.dismiss();
        this.ledgerService.getSaleTran()
        this.getFilterInvoiceBook();
        if (parentTransType && childTransType) {
          this.cPlusWithInvoiceRecords(res?.bill_board_id, parentTransType, childTransType)
        }

        return { apiSuccess: true }
      },
      (error) => {
        this.isBillTypeService = false;
        this.toast.error(error?.error?.detail);
        this.loaderBillAdd = false;
      }
    );
  }
  postBillBoardCplusSharedTrans(modal, type: any = 'Invoice', parentTransType: any = null, childTransType: any = null, sharedtransaction: any = '') {
    return new Promise((resolve, reject) => {
      this.loaderBillAdd = true;
      if (this.isBillTypeService) {
        this.baseModel.service_invoice = true;
        this.baseModel.product_invoice = false;
      } else {
        this.baseModel.service_invoice = false;
        this.baseModel.product_invoice = true;
      }
      this.http.postBillBoard(this.baseModel).subscribe(
        (res: any) => {
          this.isBillTypeService = false;
          this.loaderBillAdd = false;
          if (res?.plan_expired || res?.limit_exceeded) {
            this.ledgerService.sendClickEvent();
            return;
          }
          this.toast.success(res?.detail);
          modal.dismiss();

          this.getFilterInvoiceBook();
          if (parentTransType && childTransType) {
            this.cPlusWithInvoiceRecords(res?.bill_board_id, parentTransType, childTransType)
          }
          resolve(res);
        },
        (error) => {
          resolve(false);
          this.isBillTypeService = false;
          this.toast.error(error?.error?.detail);
          this.loaderBillAdd = false;
        }
      );
    })

  }
  postBillBoardForLedger(inventoryModal: any, type: any = 'Invoice', parentTransType: any = null, childTransType: any = null, ledgerId: any = null) {
    this.loaderBillAdd = true;
    if (this.isBillTypeService) {
      this.baseModel.service_invoice = true;
      this.baseModel.product_invoice = false;
    } else {
      this.baseModel.service_invoice = false;
      this.baseModel.product_invoice = true;
    }
    this.http.postBillBoard(this.baseModel).subscribe(
      (res: any) => {
        this.isBillTypeService = false;
        this.loaderBillAdd = false;
        if (res?.plan_expired || res?.limit_exceeded) {
          this.ledgerService.sendClickEvent();
          return;
        }
        this.modalService.dismissAll();



        this.toast.success(res?.detail);
        if (parentTransType && childTransType) {
          this.ledgerWithInvoiceRecords(res?.bill_board_id, ledgerId, parentTransType, childTransType)
        }
        this.ledgerService.getnventoryPro();
        if (this.baseModel.product_invoice) {
          if (inventoryModal) {
            this.modalService.open(inventoryModal, {
              centered: true,
              windowClass: 'ledger-participant',
              size: 'lg',
            });
          }
        }
      },
      (error) => {
        this.toast.error(error?.error?.detail);
        this.loaderBillAdd = false;
        this.isBillTypeService = false;
      }
    );
  }

  ledgerWithInvoiceRecords(id, ledg_id, type, childType) {
    this.http.addinvoiceRecordLedger(id, ledg_id, type, childType).subscribe((res: any) => {
      if (childType == '1' || childType == '7') {

        this.ledgerService.postSaleBookAfterInvoice(type, this.baseModel?.total_price, this.baseModel?.invoice_date, res?.data?.id);
      } else {
        let typePurchase = childType == '3' ? 2 : 1;
        this.ledgerService.postPurchaseBookAfterInvoice(typePurchase, this.baseModel?.total_price, this.baseModel?.invoice_date, res?.data?.id);
      }
    })
  }

  selectFile(event) {
    debugger;
    this.fileData = event.target.files;
    if (this.fileData.length > 5 || this.previewUrl.length + this.fileData.length > 5) {
      this.toast.warning('You can upload upto 5 files');
      return;
    }
    this.uploadFile = true;
    // console.log(this.uploadFile);
    const formData = new FormData();
    for (let item of this.fileData) {
      formData.append('fileToUpload', item);
    }
    this.http.addTransactionFiles(formData).subscribe(res => {
      if (res?.plan_expired || res?.limit_exceeded) {
        this.sendClickEvent();
      }
      console.log(res['data'])
      this.toast.success(res['detail']);
      for (let item of res['data']) {
        this.previewUrl.push(item);
      }

      let array = [];
      for (let item of this.previewUrl) {
        array.push(item.id)
      }
      const fileIds = res['data'].map(item => item.id);

      // Push file IDs to the file array of baseModel
      this.baseModel.attachments.push(...fileIds);
      // this.baseModel.patchValue({
      //   file: array
      // });
      this.uploadFile = false;
      // console.log(this.baseModel);
    }, (error) => {
      this.previewUrl = [];
      this.uploadFile = false;
      this.toast.error(error.error['msg']);
    })
  }

  selectFileDailySale(event) {
    this.fileData = event.target.files;
    if (this.fileData.length > 5 || this.previewUrl.length + this.fileData.length > 5) {
      this.toast.warning('You can upload upto 5 files');
      return;
    }
    this.uploadFile = true;
    // console.log(this.uploadFile);
    const formData = new FormData();
    for (let item of this.fileData) {
      formData.append('fileToUpload', item);
    }
    this.http.addTransactionFiles(formData).subscribe(res => {
      if (res?.plan_expired || res?.limit_exceeded) {
        this.sendClickEvent();
      }
      console.log(res['data'])
      this.toast.success(res['detail']);
      for (let item of res['data']) {
        this.previewUrl.push(item);
      }

      let array = [];
      for (let item of this.previewUrl) {
        array.push(item.id)
      }
      const fileIds = res['data'].map(item => item.id);

      // Push file IDs to the file array of baseModel
      this.DailybaseModel.attachments.push(...fileIds);
      // this.baseModel.patchValue({
      //   file: array
      // });
      this.uploadFile = false;
      // console.log(this.baseModel);
    }, (error) => {
      this.previewUrl = [];
      this.uploadFile = false;
      this.toast.error(error.error['msg']);
    })
  }
  selectexrFile(event) {
    this.fileData = event.target.files;
    if (this.fileData.length > 5 || this.previewUrl.length + this.fileData.length > 5) {
      this.toast.warning('You can upload upto 5 files');
      return;
    }
    this.uploadFile = true;
    // console.log(this.uploadFile);
    const formData = new FormData();
    for (let item of this.fileData) {
      formData.append('fileToUpload', item);
    }
    this.http.addTransactionFiles(formData).subscribe(res => {
      if (res?.plan_expired || res?.limit_exceeded) {
        this.sendClickEvent();
      }
      console.log(res['data'])
      this.toast.success(res['detail']);
      for (let item of res['data']) {
        this.previewUrl.push(item);
      }

      let array = [];
      for (let item of this.previewUrl) {
        array.push(item.id)
      }
      const fileIds = res['data'].map(item => item.id);

      // Push file IDs to the file array of baseModel
      this.expbaseModal.file.push(...fileIds);
      // this.baseModel.patchValue({
      //   file: array
      // });
      this.uploadFile = false;
      // console.log(this.baseModel);
      console.log(this.previewUrl);
    }, (error) => {
      this.previewUrl = [];
      this.uploadFile = false;
      this.toast.error(error.error['msg']);
    })
  }

  cPlusWithInvoiceRecords(id, ctype, type) {
    this.http.addinvoiceRecord(id, ctype, type).subscribe((res: any) => {
      this.ledgerService.addCashBookForm.get('sale_trans_id').setValue(res?.data?.id);
      this.ledgerService.addBankTansForm.get('sale_trans_id').setValue(res?.data?.id);
      this.ledgerService.addLedgerForm.get('sale_trans_id').setValue(res?.data?.id);
      this.ledgerService.addCashBookForm.get('purchase_trans_id').setValue(res?.data?.id);
      this.ledgerService.addBankTansForm.get('purchase_trans_id').setValue(res?.data?.id);
      this.ledgerService.addLedgerForm.get('purchase_trans_id').setValue(res?.data?.id);
      this.ledgerService.purchasepostData = { id: res?.data?.id };
      this.ledgerService.SalePostData = { id: res?.data?.id };
      this.ledgerService.PurchaseDetailData = { id: res?.data?.id };
      this.ledgerService.saleDetailData = { id: res?.data?.id };
      this.ledgerService.getSaleTran();
      this.ledgerService.getPurchaseTran();
    })
  }

  sendClickEvent() {
    this.subject.next();
  }
  // editBillBoard(modal, type: any = 'Invoice', sharedtransaction: any = null) {
  //   this.loaderBillAdd = true;
  //   let hasInvalidItemEdit=false;
  //   // this.baseModel = { ...this.baseModel, sub_total_type: this.baseModel?.sub_total_type?.id };
  //   this.baseModel.id=this.baseModel.invoice_id;
  //   this.baseModel.invoice_id='';
  //   this.baseModel.shipping_Business_information={};
  //   console.log(this.baseModel);
  //   if (this.baseModel.expense_item_or_service.length > 0) {
  //     this.baseModel.expense_item_or_service.forEach(item => {
  //       if (item.quantity === 0 || !item.quantity || item.unit_price === 0 || !item.unit_price || !item.item_or_service_title) {
  //         this.toast.warning('One or more items have invalid quantity, unit price, or title.');
  //         // // alert('One or more items have invalid quantity, unit price, or title.');
  //         hasInvalidItemEdit = true
  //       }
  //     });
  //   }
  //   if(hasInvalidItemEdit==false)
  //   {
  //     this.http.editBillBoard(this.baseModel).subscribe(
  //       (res: any) => {
  //         this.loaderBillAdd = false;
  //         this.isEditInvoice = false;
  //         this.toast.success(res?.detail);
  //         modal.dismiss();
  //         this.getFilterInvoiceBook();
  //       },
  //       (error) => {
  //         this.loaderBillAdd = false;
  //       }
  //     );
  //   }
 
  // }


  editBillBoard(modal, type: any = 'Invoice', sharedtransaction: any = null) {
   debugger;
    let hasInvalidItemEdit = false;
    // this.baseModel = { ...this.baseModel, sub_total_type: this.baseModel?.sub_total_type?.id };
    if(this.baseModel.invoice_id!='')
    {
      this.baseModel.id = this.baseModel.invoice_id;
    }
    if(this.invoiceType!=7)
      {
        this.baseModel.shipping_Business_information = {};
      }
      if(this.invoiceType==7)
        {
          this.baseModel.shipping_Business_information.id=this.shippingId;
        
        }
    console.log(this.baseModel);
    if (this.baseModel.expense_item_or_service.length > 0) {
      this.baseModel.expense_item_or_service.forEach(item => {
        if (item.quantity === 0 || !item.quantity || item.unit_price === 0 || !item.unit_price || !item.item_or_service_title) {
          this.toast.warning('One or more items have invalid quantity, unit price, or title.');
          // // alert('One or more items have invalid quantity, unit price, or title.');
          hasInvalidItemEdit = true;
          return; // Exit the forEach loop early
        }
      });
    }
    if (hasInvalidItemEdit == false) {
      this.baseModel.invoice_id = '';
      this.loaderBillAdd = true;
      this.http.editBillBoard(this.baseModel).subscribe(
        (res: any) => {
          this.loaderBillAdd = false;
          this.isEditInvoice = false;
          this.toast.success(res?.detail);
          modal.dismiss();
          this.getFilterInvoiceBook();
        },
        (error) => {
          this.loaderBillAdd = false;
        }
      );
    }
  }
  
  capitalize(s) {
    return s[0].toUpperCase() + s.slice(1);
  }
  deleteSingleInvoiceBookInfo(id) {
    let title, text, cancel, confirm;
    this.translate.get('Do you want to delete?').subscribe((data: any) => {
      title = data;
    }),
      this.translate.get('Cancel').subscribe((data: any) => {
        cancel = data;
      }),
      this.translate.get('Delete').subscribe((data: any) => {
        confirm = data;
      }),
      Swal.fire({
        imageUrl: '../../../../assets/delete.png',
        imageHeight: '50',
        title: title,
        text: text,
        showCancelButton: true,
        cancelButtonColor: '#6e7d88',
        confirmButtonColor: '#dc3545',
        cancelButtonText: cancel,
        confirmButtonText: confirm
      }).then((result) => {
        if (result.value) {
          this.http.deleteSingleInvoiceBookInfo(id).subscribe(
            (res: any) => {
              this.loader = false;
              this.toast.success(res?.details);
              this.modalService.dismissAll();
              this.getFilterInvoiceBook();
            },
            (error) => {
              this.toast.warning(error?.error?.msg)
              this.loader = false;
            }
          );
        }
      });
  }
  // deleteSingleInvoiceBookInfo(){
    
  // }
  getBillBoardTypes() {
    this.http.getBillBoardTypes().subscribe((res: any) => {
      this.billBoardTypes = res['result'];
      this.billboardSubTotalTypes = res['billboard_sub_total_types']
      console.log(this.billboardSubTotalTypes[1].type, 'Check Result')
    });
  }
  getAllMembers(res: any = '', type = 'ledger') {
    this.loadParticipants = true;
    this.http.searchMemberLedger(res, type).subscribe(
      (data) => {
        this.memberslist = data['business_members_info'];
        this.loadParticipants = false;
        ////  console.log('this is the search query result', data);
      },
      (error) => {
        this.loadParticipants = false;
      }
    );
  }
  removeParticipant(item) {
    this.http.activeInactiveBillParticipant(item?.id, item?.action).subscribe(
      (res) => {
        this.toast.success(res['detail']);
        this.getAllMembers();
      },
      (error) => {
        this.toast.error(error.error['msg']);
      }
    );
  }
  AceptReject(id,body){
    this.http.Aceceptrejectbills(id,body).subscribe(
      (res) => {
        this.toast.success(res['detail']);
        this.modalService.dismissAll();
        if(this.selectedInvoice?.bill_board_transaction_type?.id == 7)
        {
          this.billBookFiltersPackingSlip = {
            ...this.billBookFiltersPackingSlip,
            bill_board_transaction_type: this.invoiceType,
            accept_or_reject_status: 'accepted',
            packing_slip_type: 'purchase',
          };
          // this.selectedOption=type;
          this.getFilterInvoiceBookPackingslip();
        }
      },
      (error)=>{
        this.toast.success(error.error['msg']);
      }
    )
  }
  AceptRejectInvoice(id,body){
    this.http.Aceceptrejectbills(id,body).subscribe(
      (res) => {
        this.toast.success(res['detail']);
      },
      (error)=>{
        this.toast.success(error.error['msg']);
      }
    )
  }
  calculateTotalPrice(baseModal: any) {
    baseModal.total_price = 0;
    this.calculateTaxTotalPrice(baseModal);

    if (baseModal.sub_total_type == 1) {
      baseModal.total_price = baseModal.sub_total + baseModal.tax_price + baseModal.shipping_price;
    } else if (baseModal.sub_total_type == 2) {
      baseModal.total_price = baseModal.sub_total + baseModal.shipping_price;
    }
    return baseModal.total_price
      ? baseModal.total_price?.toFixed(2)
      : 0;
  }
  calculateSubTotalPrice(baseModal: any) {

  }
  calculateTaxTotalPrice(baseModal: any, taxArray: any = []) {
    baseModal.tax_price = 0; // Reset tax price before recalculating
    baseModal.tax_details = []; // Clear the existing tax_details array

    for (const item of taxArray) {
      item.tax_value = (baseModal.sub_total * item.tax_percentage) / 100;
      baseModal.tax_price += item.tax_value;
      baseModal.tax_details.push({
        unit_price: item.tax_value,
        tax_and_percentage: item.id
      });
    }
  }

  calculateDiscountTotalPrice() {

  }
  resetValuesOfBaseModal() {

  }
}
