<div class="modal-ngb">
    <div class="d-flex align-items-center justify-content-between w-100 px-4 mt-3">
      <div class="d-flex align-items-center">
        <img (click)="onBackButtonClick()" data-dismiss="modal" class="img-20 cursor-pointer mr-2" src="../../../../assets/Accountect/Back Button.svg">
        <img class="img-25 mr-2" src="../../../../assets/Accountect/comments/comment red.svg" alt="">
        <span class="f-16 f-w-600 ml-1">{{'Comments' | translate}}</span>
      </div>
    </div>
    <div class="modal-body py-0">
        <div class="position-relative Customheight mt-4">
            <ng-container *ngIf="!marketService.isMarketComment">
                <div class="CommentDisplay" *ngIf="bookService?.commentData?.length > 0" >
                    <div class="bg-white p-3 py-2 my-2 detail-badge align-items-center cursor-pointer" *ngFor="let comment of bookService.commentData">
                        <div class="d-flex justify-content-between">
                            <p class="f-12 m-0 font-weight-bold text-primary">{{comment.creator.first_name}}</p>
                            <p class="f-10 m-0 text-light">{{comment.created_at | date:dateFormat}}</p> 
                        </div>
                        <div>
                            <p class="m-0 f-12">
                            {{comment.description}}
                            </p>
                        </div>
                    </div>
                    <div *ngIf="bookService.loader" class="trans-loader d-flex align-items-center justify-content-center">
                        <div class="spinner-border text-primary" role="status"></div>
                    </div>
                    
                </div>
            </ng-container>
            <ng-container *ngIf="marketService.isMarketComment">
                <div class="CommentDisplay" *ngIf="marketService?.commentData?.length > 0" >
                    <div class="bg-white p-3 py-2 my-2 detail-badge align-items-center cursor-pointer" *ngFor="let comment of marketService.commentData">
                        <div class="d-flex justify-content-between">
                            <p class="f-12 m-0 font-weight-bold text-primary">{{comment.creator.first_name}}</p>
                            <p class="f-10 m-0 text-light">{{comment.created_at | date:dateFormat}}</p> 
                        </div>
                        <div>
                            <p class="m-0 f-12">
                            {{comment.description}}
                            </p>
                        </div>
                    </div>
                    <div *ngIf="bookService.loader" class="trans-loader d-flex align-items-center justify-content-center">
                        <div class="spinner-border text-primary" role="status"></div>
                    </div>
                    
                </div>
            </ng-container>
            <!-- placeHolder if No Comments display -->
            <div class="text-center h-90vh"  *ngIf="bookService?.commentData?.length == 0">
                <div>
                    <img src="../../../../assets/Accountect/comments/comment placeholder.svg" alt="">
                    <p class="f-14 m-0 text-light"> No Comments Yet</p>
                </div>
            </div>
            <div class="CommentField">
                <div class="CustomField d-flex">
                    <textarea [(ngModel)]="commentDesc" placeholder="Write Your Comments..."></textarea>
                    <button class="btn" *ngIf="!marketService.isMarketComment" (click)="postComment()"><img src="../../../../assets/Accountect/comments/send icon.svg" alt=""></button>
                    <button class="btn" *ngIf="marketService.isMarketComment" (click)="postCommentfromMarket()"><img src="../../../../assets/Accountect/comments/send icon.svg" alt=""></button>
                </div>
            </div>
        </div>
    </div>
</div>