<div class="d-flex align-items-center m-auto bg-primary w-fit w-100">
    <div class="d-flex justify-content-center align-items-center w-100">
      <div>
      <span  class="px-4 text-white">{{'Your Premium Plan is set to expire in'|translate}} {{ledgerService?.days}}  {{'day'|translate}}. <a class="text-white text-underline" [routerLink]="['user/profile']" [queryParams]="{tab: 'settings', modal: 'true'}">{{"Click Here"|translate}}</a> {{'to renew now'|translate}}</span>
      <!-- <span class="px-4 text-white">{{'Your Premium Plan has expired'}} {{"Click Here"}} {{'to renew now'}}</span> -->
      </div>
      <div>
        <button aria-label="Close" class="close bg-primary b-b-light  px-3 py-2 m-0 text-white b-none" type="button"
            (click)="modalClose()">
            <span aria-hidden="true">×</span>
          </button>
      </div>
    </div>
      
  </div>
  