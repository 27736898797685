import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  elementType = 'url';
  qr_value_ad = 'https://play.google.com/store/apps/details?id=com.hailu.accountect&hl=en';
  qr_value_ios='https://apps.apple.com/app/accountect-accounting/id6474992652';
  qr_value_youtube='https://www.youtube.com/watch?v=hcNUa4Rzb9E'
  public build_type = environment.build_type;
  constructor(public auth: AuthService) { }

  ngOnInit() {
    // (document.querySelector('footer') as HTMLElement).style.position = 'absolute';
  }
  navigateTo(route) {
    window.location.replace(route);
  }
}
