import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbCalendar, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/services/auth.service';
import { LedgerService } from 'src/app/shared/services/ledger.service';
import { Title } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { EmployeeService } from 'src/app/shared/services/employee.service';
import { HttpServiceService } from 'src/app/shared/services/http/http-service.service';
@Component({
  selector: 'app-addbank',
  templateUrl: './addbank.component.html',
  styleUrls: ['./addbank.component.scss']
})
export class AddbankComponent implements OnInit {

  constructor(public router: Router,public auth: AuthService,public translate: TranslateService,private calendar: NgbCalendar,
    public datepipe: DatePipe,public ledgerService: LedgerService, public modalService: NgbModal,private titleService: Title,
    public toast: ToastrService,public employeeService: EmployeeService,private http: HttpServiceService) { }

  ngOnInit(): void {
  }

}
