import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { emit } from 'process';
import { AudioRecordingService } from 'src/app/audio-recording.service';
import { HttpServiceService } from 'src/app/shared/services/http/http-service.service';
import { EmployeeService } from '../../services/employee.service';
import { LedgerService } from '../../services/ledger.service';

@Component({
  selector: 'app-audio-file',
  templateUrl: './audio-file.component.html',
  styleUrls: ['./audio-file.component.scss']
})
export class AudioFileComponent implements OnInit {
  recordedTime;
  @Input() blobUrl: any;
  teste;
  dateFormat: any;
  @Input() IsEmployee: boolean = false;
  @Input() form: FormGroup;
  @Input() fileArray: any = [];

  @Output() returnAudio: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public audioRecordingService: AudioRecordingService,
    private sanitizer: DomSanitizer, 
    public http: HttpServiceService, 
    public toast: ToastrService,
    public ledgerService: LedgerService,
    public employeeService: EmployeeService) {

    this.audioRecordingService
    .recordingFailed()
    .subscribe(() => (this.audioRecordingService.isRecording = false));

    this.audioRecordingService
    .getRecordedTime()
    .subscribe(time => (this.recordedTime = time));
    
    this.audioRecordingService.getRecordedBlob().subscribe(data => {
    this.teste = data;
    this.blobUrl = this.sanitizer.bypassSecurityTrustUrl(
      URL.createObjectURL(data.blob)
    );
  });
   }

  ngOnInit(): void {
  }
  startRecording() {
    if (!this.audioRecordingService.isRecording) {
      this.audioRecordingService.isRecording = true;
      this.audioRecordingService.startRecording();
    }
  }
  abortRecording() {
    if (this.audioRecordingService.isRecording) {
      this.audioRecordingService.isRecording = false;
      this.audioRecordingService.abortRecording();
    }
  }

  stopRecording() {
    if (this.audioRecordingService.isRecording) {
      this.audioRecordingService.stopRecording();
      this.audioRecordingService.isRecording = false;

      // this.audioRecordingService.getRecordedBlob().subscribe( res =>{
      //   this.uploadaudio(res);
      // } )
    }
  }

  clearRecordedData() {
    this.blobUrl = null;
    this.audioRecordingService.recordedAudio = null;
  }

  ngOnDestroy(): void {
    this.abortRecording();
  }

  uploadaudio(item:any){
    const formData = new FormData();  
    formData.append('fileToUpload', item.blob);

    this.http.addCashFiles(formData).subscribe(res => {
      this.toast.success(res['detail']);

      this.returnAudio.emit(res)

    }, (error) => {

    })
  }

 
}