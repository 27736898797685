<div class="modal-ngb">
  <div class="d-flex align-items-center justify-content-between w-100 px-4 mt-3">
    <div class="d-flex align-items-center">
      <img (click)="onBackButtonClick()" data-dismiss="modal" class="img-20 cursor-pointer mr-2" src="../../../../assets/Accountect/Back Button.svg">
      <img class="img-30 mr-2" src="../../../../assets/Accountect/Doc Active.svg" alt="">
      <span class="f-16 f-w-600 ml-1">{{'Attachments' | translate}}</span>
    </div>
  </div>
  <div class="modal-body py-0">
    <div class="row m-0 driverow mt-2">
      <ng-container>
        <div class="card w-20 cursor-pointer" (click)="OpenimageModal(ImAGE, file)" *ngFor="let file of bookService?.Images">
          <div class="card-body p-2 b-shadow">
            <img class="img-50 b-r-50" [ngbTooltip]="file?.file_name" *ngIf="file?.file_type =='image'" [src]="file?.file_path?file?.file_path:'../../../../assets/Drive/photo.svg'">
            <img class="img-50 b-r-50" [ngbTooltip]="file?.file_name" *ngIf="file?.file_type =='file'" src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/Drive/File.svg">
            <img class="img-50 b-r-50" [ngbTooltip]="file?.file_name" *ngIf="file?.file_type =='video'" src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/Drive/File.svg">
            <div class="text-center p-0">
              <p class="m-0 f-14 name-Eclips">{{file.file_name}}</p>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>


<ng-template #ImAGE let-modal>
  <div>
    <div class="d-flex justify-content-center">
      <ng-container *ngIf="imagePopupURL?.file_type?.includes('image')">
        <img class="w-100" [src]="imagePopupURL?.file_path">
      </ng-container>
      <ng-container *ngIf="imagePopupURL?.file_type?.includes('audio')">
        <audio controls>
          <source [src]="imagePopupURL?.file_path" type="audio/mpeg">
        </audio>
      </ng-container>
      <ng-container *ngIf="imagePopupURL?.file_type?.includes('file')">
        <img class="w-100" [src]="imagePopupURL?.file_path">
      </ng-container>
    </div>
  </div>
</ng-template>