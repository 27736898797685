  <!-- <ngb-carousel class="d-flex align-items-center justify-content-center animate__animated animate__fadeIn">
    <ng-template class="d-flex flex-column w-70 align-items-center justify-content-center" ngbSlide *ngFor="let item of arrayInput;let i = index;let isFirst = first">
        <div class="main-div d-flex flex-column w-70 align-items-center justify-content-center mx-auto">
            <div class="heading-div mb-4">
                <h6>{{item?.display_heading}}</h6>
            </div>
            <div class="carousel-div">
                <div class="div-img">
                    <img [src]="item?.web_image" alt="Random first slide">
                </div>
                <div class="div-text">
                    <span>
                        {{item?.display_description}}
                    </span>
                </div>
            </div>
            <div class="ml-auto text-right px-3 skip-btn">
              <span class="font-weight-bold f-16 text-primary" (click)="modal.dismiss(); tourSkip(type)">{{'Skip'|translate}}</span>
            </div>
        </div>
    </ng-template>
  </ngb-carousel> -->


  <ngb-carousel class="d-flex align-items-center justify-content-center animate__animated animate__fadeIn" [interval]="4000">
    <ng-template class="d-flex flex-column w-70 align-items-center justify-content-center" ngbSlide *ngFor="let item of arrayInput;let i = index;let isFirst = first">
      <div class="main-div d-flex flex-column w-70 align-items-center justify-content-center mx-auto">
        <div class="heading-div mb-4">
          <h6>{{item?.display_heading}}</h6>
        </div>
        <div class="carousel-div">
          <div class="div-img">
            <img [src]="item?.web_image" alt="Random first slide">
          </div>
          <div class="div-text">
            <span>{{item?.display_description}}</span>
          </div>
        </div>
        <div class="ml-auto text-right px-3 skip-btn">
          <span class="font-weight-bold f-16 text-primary" (click)="modal.dismiss(); tourSkip(type)">{{'Skip'|translate}}</span>
        </div>
      </div>
    </ng-template>
    <!-- Previous Button -->
    <button class="carousel-control-prev" (click)="carousel.prev()">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </button>
    <!-- Next Button -->
    <button class="carousel-control-next" (click)="carousel.next()">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </button>
  </ngb-carousel>
  