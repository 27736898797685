<div class="ngb-modal">
    <div class="modal-header pb-0 bb-none">
        <h5 class="modal-title" id="modal-basic-title">
            <img src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/ledger/pdf.svg" class="img-20 mr-1"
                alt="">Download
        </h5>
        <button type="button" class="btn-close"  aria-label="Close"></button>
    </div>
    <div class="modal-body pt-0">
        <ng-container *ngIf="this.bookService.invoiceType != '11'">
            <div class="d-flex justify-content-center mx-auto mt-2 w-80" *ngIf="this.bookService.invoiceType != '10'">
                <div class="w-100">
                    <div class=" w-100">
                        <label for="customerName" class="f-14 text-light" *ngIf="this.bookService.invoiceType == 1"><span>Customer Name</span></label>
                        <label for="customerName" *ngIf="this.bookService.invoiceType == '2' || this.bookService.invoiceType == '8'" class="f-14 text-light"><span>Vendor Name</span></label>
                        <input class="form-control form-control-sm" type="text" id="customerName" (keyup)="searchCustomer()"
                            [(ngModel)]="querySearchValuesCust">
                    </div>
                    <div *ngIf="CustloadData" class="shadow-27 p-1">
                        <div class="cursor-pointer" *ngFor="let item of bookService?.allBusinessList">
                            <div class="w-100 f-12" (click)="getCustVendVal(item,0);selectBusiness(item)">
                                {{item?.with_business?.business_name}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mx-auto mt-2 w-80">
                <p class="f-14 m-0 text-light">{{'Categories' | translate}}</p>
                <div class="cursor-pointer m-0 border borderR-4px w-100 form-control-sm">
                    <div class="d-flex justify-content-between" (click)="openaddnew(addnew)" *ngIf="this.bookService.invoiceType != '8'">
                        <p *ngIf="!categoryName" class="f-14 m-0 text-light">{{'Choose Categories' | translate}}</p>
                        <p *ngIf="categoryName" class="f-14 m-0 text-dark">{{categoryName}}</p>
                        <p class="m-0"><i class="fa fa-angle-down f-20 text-light"></i></p>
                    </div>
                    <div class="d-flex justify-content-between" (click)="openaddnewEx(addnewEx)" *ngIf="this.bookService.invoiceType == '8'">
                        <p *ngIf="!categoryName" class="f-14 m-0 text-light">{{'Choose Categories' | translate}}</p>
                        <p *ngIf="categoryName" class="f-14 m-0 text-dark">{{categoryName}}</p>
                        <p class="m-0"><i class="fa fa-angle-down f-20 text-light"></i></p>
                    </div>
                </div>
            </div>

            <div class="d-flex justify-content-center mx-auto mt-2 w-80">
                <div class="w-100">
                    <div class=" w-100" *ngIf="item1!='item1'">
                        <label for="floatingInput" class="f-14 text-light"><span>Item Name</span></label>
                        <input class="form-control form-control-sm" type="text" id="floatingInput"
                            (keyup)="searchFilters($event,0,false)" [(ngModel)]="querySearchValues"
                            [ngModelOptions]="{standalone: true}">
                    </div>
                    <label class="f-14 text-light" *ngIf="item1=='item1'"><span>Item Name</span></label>
                    <div class="p-q1 border b-r-4" *ngIf="item1=='item1'">
                        <div class="w-50">
                            <div
                                class="d-flex justify-content-between align-items-center badge bdgee bg-invoice text-white">
                                <div class="f-14">{{querySearchValues}}</div>
                                <button type="button" class="btn p-0 ml-1 close text-white" (click)="Closeitem(0)"><span
                                        aria-hidden="true">×</span></button>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="loadData==true" class="shadow-27 p-1">
                        <div class="bundle-div">
                            <ng-container *ngIf="listing$ | async as listing ">
                                <ng-container *ngFor="let item of this.ledgerService.searchList">
                                    <div class="border-listing">
                                        <div class="d-flex justify-content-between p-1">
                                            <div class="f-12" (click)="getItemVal(item)">
                                                {{item?.product_name}}
                                            </div>
                                            <!-- <div class="f-14 text-primary">
                                            {{item?.unit_price}}  
                                        </div>    -->
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <div class="d-flex justify-content-center mx-auto mt-2 w-80">
            <div class="w-49 mx-auto datepicker py-2">
                <div class="form-inline custom-datepicker custom-datepicker-dropdown">
                    <div class="form-group m-0">
                        <div class="input-group">
                            <div class="input-group-append rtl" *ngIf="auth.dirWeb === 'rtl'">
                                <button (click)="fvalid.toggle()" class="btn calendar" type="button"><img
                                        src="../../../../assets/Accountect/calendar.svg" class="img-18" alt=""></button>
                            </div>
                            <input #fvalid="ngbDatepicker" (ngModelChange)="onDateSelect($event)"
                                [(ngModel)]="selectedDate" [value]="selectedDate | date: dateFormat"
                                class="form-control form-control-sm cursor-pointer border-right-none" name="dp"
                                (click)="fvalid.toggle()" ngbDatepicker [placeholder]="'From'|translate" readonly>
                            <div class="input-group-append ltr" *ngIf="auth.dirWeb === 'ltr'">
                                <button (click)="fvalid.toggle()" class="btn calendar border-left-none px-2 pt-1"
                                    type="button">
                                    <img src="../../../../assets/Accountect/calendar.svg" alt="">
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-49 mx-auto datepicker py-2">
                <div class="form-inline custom-datepicker custom-datepicker-dropdown">
                    <div class="form-group m-0">
                        <div class="input-group">
                            <div class="input-group-append rtl" *ngIf="auth.dirWeb === 'rtl'">
                                <button (click)="valid.toggle()" class="btn calendar" type="button"><img
                                        src="../../../../assets/Accountect/calendar.svg" class="img-18" alt=""></button>
                            </div>
                            <input #valid="ngbDatepicker" (ngModelChange)="onDateSelectTo($event)"
                                [(ngModel)]="selectedDateTo" [value]="selectedDateTo | date: dateFormat"
                                class="form-control form-control-sm cursor-pointer border-right-none" name="dp"
                                (click)="valid.toggle()" ngbDatepicker [placeholder]="'To'|translate" readonly>
                            <div class="input-group-append ltr" *ngIf="auth.dirWeb === 'ltr'">
                                <button (click)="valid.toggle()" class="btn calendar px-2 pt-1 border-left-none"
                                    type="button">
                                    <img src="../../../../assets/Accountect/calendar.svg" alt="">
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ng-container *ngIf="this.bookService.invoiceType == '11'">
            <div class="d-flex justify-content-center">
                <img src="../../../../assets/Accountect/Search_placeholder.svg" alt="">
            </div>
        </ng-container>

    </div>
    <div class="d-flex justify-content-center bg-primary p-3 cursor-pointer round-bottom border-top">
        <button class="btn-tranparent cursor-pointer" (click)="downloadPDF()">
            <i aria-hidden="true" class="fa fa-download f-24 text-white"></i>
        </button>
    </div>
</div>



<ng-template #addnew let-modal>
    <div class="modal-header">
      <h5 class="modal-title" id="modal-basic-title">
        <img src="../../../../assets/Accountect/Add Button.svg" alt="">
          {{'Add Category' | translate}}
      </h5>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
      <div class="h-330">
        <div class="mx-2">
          <div *ngIf="categoryListingLoader" class="overlay-loader">
            <div class="spinner-border spinner-border-sm text-primary m-auto" role="status">
            </div>
          </div>
          <ng-container *ngIf="!categoryListingLoader">
            <div *ngFor="let item of ledgerService?.invnCat" class="ShadowPayment bg-light payment d-flex justify-content-between mt-2">
  
              <div (click)="categoryAddToExpenseForm(item, modal)"
                class="d-flex justify-content-between w-100 py-2 border-btm">
                <div class="edit">
                  {{item?.title}}
                </div>
              </div>
              <div class="w-5 align-self-center py-2 text-end">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z"/></svg>
              </div>
  
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-template>


  <ng-template #addnewEx let-modal>
    <div class="modal-header">
      <h5 class="modal-title f-16" id="modal-basic-title">
        <img src="../../../../assets/Accountect/Add Button.svg" class="img-25" alt="">
        {{'Choose Category' | translate}}
      </h5>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
      <!-- <div class="px-5">
            <input (keyup.enter)="getExpenseCategories($event?.target?.value)"  type="text" class="form-control bg-light" [placeholder]="'Search' | translate">
        </div> -->
  
      <div class="h-250" (scroll)="onScrollCat($event)">
        <div *ngIf="categoryListingLoader" class="overlay-loader">
          <div class="spinner-border spinner-border-sm text-primary m-auto" role="status">
          </div>
        </div>
        <ng-container *ngIf="!categoryListingLoader">  
          <div *ngFor="let item of categoryListing; let i = index" (click)="categoryAddToExpenseForm(item, modal)"
            class="ShadowPayment mx-2 bg-light payment d-flex justify-content-between mt-2">
            <div class="form-check w-100 position-relative d-flex flex-row-reverse p-0">
              <input class="form-check-input cursor-pointer" type="radio" name="flexRadioDefaultas"
                [id]="'flexRadioDefaultas'+i">
              <label class="form-check-label w-100 cursor-pointer text-capitalize" [for]="'flexRadioDefaultas'+i">
                {{item?.title}}
              </label>
            </div>
          </div>
  
        </ng-container>
      </div>
    </div>
  </ng-template>