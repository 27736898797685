import { Injectable } from '@angular/core';
import * as RecordRTC from "recordrtc";
import * as moment from "moment";
import { Observable, Subject } from "rxjs";

interface RecordedAudioOutput {
  blob: Blob;
  title: string;
}

@Injectable({
  providedIn: 'root'
})

export class AudioRecordingService {
  public  stream;
  public recorder;
  public interval;
  public startTime;
  public _recorded = new Subject<RecordedAudioOutput>();
  public _recordingTime = new Subject<string>();
  public _recordingFailed = new Subject<string>();
  public recordedAudio: any = null;
  isRecording: boolean = false;
  secondsRecord: any = '0';

  public getRecordedBlob(): Observable<RecordedAudioOutput> {
    return this._recorded.asObservable();
  }

  public getRecordedTime(): Observable<string> {
    return this._recordingTime.asObservable();
  }

  public recordingFailed(): Observable<string> {
    return this._recordingFailed.asObservable();
  }

  public startRecording() {
    if (this.recorder) {
      // It means recording is already started or it is already recording something
      return;
    }
    this.isRecording = true;
    this.recordedAudio = null;
    this._recordingTime.next("00:00");
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(s => {
        this.stream = s;
        this.record();
      })
      .catch(error => {
        this._recordingFailed.next();
      });
  }

  public abortRecording() {
    this.stopMedia();
  }

  public record() {
    this.recorder = new RecordRTC.StereoAudioRecorder(this.stream, {
      type: "audio",
      mimeType: 'audio/mp3',
      timeSlice: 1000,
      bufferSize: 256,
      numberOfAudioChannels: 1,
    });
    
    this.recorder.record();
    this.startTime = moment();
    this.interval = setInterval(() => {
      const currentTime = moment();
      const diffTime = moment.duration(currentTime.diff(this.startTime));
      const time =
        this.toString(diffTime.minutes()) +
        ":" +
        this.toString(diffTime.seconds());
        this.secondsRecord = this.toString(diffTime.seconds());
        this._recordingTime.next(time);
    }, 1000);
  }

  public toString(value) {
    let val = value;
    if (!value) val = "00";
    if (value < 10) val = "0" + value;
    return val;
  }

  public stopRecording() {
    if (this.recorder) {
      this.recorder.stop(
        blob => {
          if (this.startTime) {
            const mp3Name = encodeURIComponent(
              "audio_" + new Date().getTime() + ".wav"
            );
            this.stopMedia();
            this._recorded.next({ blob: blob, title: mp3Name });
            this.recordedAudio = blob;
            this.isRecording = false;
          }
        },
        () => {
          this.stopMedia();
          this._recordingFailed.next();
        }
      );
    }
  }
  public resetRecordedAudio() {
    this.recordedAudio = null;
    this.isRecording = false;
  }
  public stopMedia() {
    if (this.recorder) {
      this.recorder = null;
      clearInterval(this.interval);
      this.startTime = null;
      if (this.stream) {
        this.stream.getAudioTracks().forEach(track => track.stop());
        this.stream = null;
      }
    }
  }
  constructor() {
    this.getRecordedTime().subscribe(res => {
      if (this.secondsRecord > 30) {
        this.stopRecording();
      }
    });
   }
}
