import { HttpServiceService } from './http/http-service.service';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
//social
// import { SocialAuthService, SocialUser } from "angularx-social-login";
import { isPlatformBrowser } from '@angular/common';
import * as moment from 'moment';
import { environment } from 'src/environments/environment.prod';
import { debug } from 'console';
import { MetaTagsService } from './meta-tags.service';
import { Title } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
declare var $:any;
export interface User {
    uid: string;
    email: string;
    displayName: string;
    photoURL: string;
    emailVerified: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  loadTour: boolean = false;
  // social login
  public socialLoginForm: FormGroup;
  public build_type = environment.build_type;
  showNotifyLedger: boolean = false;
  //
  subSnackbar: boolean;
  public loginForm: FormGroup;
  public reportForm: FormGroup;
  public userData: any;
  tokenvar: any;
  LangDrop: any;
  public Application = 'ciwac';
  public isMuted = 'False';
  public userState = new BehaviorSubject({});
  public user;
  public headertab;
  public dirWeb = 'ltr';
  signinstatus: boolean = false;
  public showLoader: Boolean = false;
  public loginLoader: Boolean= false;
  public resendActEmail: Boolean = false;
  public resendActMobile: Boolean = false;
  public ipAddress: any;
  public qrlogin:any;
  submitted = false;
  public targetLanguage: any = 'en';
  public LangIs: any = 'en';
  countryByIP: any;
  repfileLoader: boolean = false;
  flagbyIP: any = '';
  tabSelected = 'email';
  returnUrl: any;
  go_Sug: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCPoQCNPKT58pRjQfBKGfNiN-ENNqmQQqg&libraries=places&language=en';
  tourData: any;
  allCountries: any;
  popupWindow: any;
  reportReason = {
    user: '',
    broadcast: '',
    chat: '',
    network: '',
  };
  LangObject: any;
  reportFiles: any = [];
  trans_types: any;
  ledger_currencies: any;
  date_format;
  status: boolean;
  IPcurrency: any;
  adminRequestReceive: any;
  showNotify: boolean = false;
  showNotifyPlus: boolean = false;
  isMobile: any;
  isBrowser: boolean;
  linkMobileRef: any = null;
  mainLoader: boolean = false;
  dataIPBased: any;
  QRScreen: boolean =  false;
  numberEmailScreen:boolean = true;
  public socket: any;
  public timer = 1000;
  countryCode: any;

  constructor(
    //social
    // private socialAuthService: SocialAuthService,
    public router: Router,
    public cookieService: CookieService,
    private http: HttpServiceService,
    public translate: TranslateService,
    private titleService: Title,
    private fb: FormBuilder,
    public toast: ToastrService,
    public metaService: MetaTagsService,
    private https: HttpClient,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
  
    if (this.build_type === 'ciwac') {
      this.targetLanguage = 'en';
      this.Application = 'ciwac';
    } else {
      this.targetLanguage = 'zh-Hans';
      this.Application = 'niwac';
    }
    // this.getDefaultLanguage();
    this.isBrowser = isPlatformBrowser(platformId);
    // this.getIP();
    // social Login form

    this.socialLoginForm = fb.group({
      id: ['', [Validators.required]],
      accessToken: ['', [Validators.required]],
      name: ['', [Validators.required]],
      email: ['', [Validators.required]],
      imageurl: ['', [Validators.required]],
      provider: ['', [Validators.required]],
    });
    this.submitted = false;

    this.loginForm = fb.group({
      new_user: [null, [Validators.required]],
      password: [null, Validators.required],
      username: [null],
    });
    this.reportForm = fb.group({
      reported_profile_id: ['', [Validators.required]],
      category: ['', [Validators.required]],
      report_reason: ['', [Validators.required]],
      description: ['', [Validators.required]],
      files: [''],
    });

   
    // this.userState.subscribe((user) => {
    //   console.log('calling behaviour');
    //   console.log(user, '546g');
    //   debugger;
    //   if (user && Object.keys(user).length > 0) {
    //     this.userData = user;
    //     this.tokenvar = this.userData.token;
    //     console.log(this.userData.has_unseen_notifications);
    //   } else {
    //     // If user data is not found, navigate to the specific route
    //     this.router.navigate(['/en'])
    //     //this.router.navigate(['public', 'accounts', 'room', 'M769nA']);
    //     return; // Exit the subscription callback early
    //   }
    //   console.log('this is user dataaaaaaaaaaaaaaaaaa', this.userData);
    // });

    // // Initialize userState with data from localStorage or an empty object
    // this.userState.next(JSON.parse(localStorage.getItem('user')) || {});
  



    this.userState.subscribe((user) => {
       console.log('calling behaviour');
      console.log(user,'546g');
      if (user) {
        this.userData = user;
        this.tokenvar = this.userData.token;
        console.log(this.userData.has_unseen_notifications);
      }
       console.log('this is user dataaaaaaaaaaaaaaaaaa', this.userData);
    });
    this.userState.next(JSON.parse(localStorage.getItem('user')) || {});
  }
  get isWebUrl(): boolean {
    try {
      // //////  console.log(this.userData.employee_profile[0].company.web_url);
      if (this.userData.employee_profile[0].company.web_url) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      // //////  console.log(e);
      return false;
    }
  }
  public getTourCiwac() {
    this.loadTour = true;
    this.http.getTourData(this.targetLanguage).subscribe(
      (res) => {
        this.tourData = res['detail'];
        this.loadTour = false;
      },
      (error) => {
        this.loadTour = false;
      }
    );
  }
  toggle(selectedtab: string) {
    this.numberEmailScreen = true
    this.QRScreen = false
    this.loginForm.reset();
    this.tabSelected = selectedtab;
    this.router.navigate(['/authentication/login'], {
      queryParams: {
        tb: this.tabSelected,
      },
    });
  }
  QRcodee() {
    this.numberEmailScreen = false
    this.QRScreen = true
    this.http.qrLogin().subscribe((res: any) => {
      // this.qrlogin = res?.data?.code
      this.qrlogin = res?.data?.code;
      this.connectSocketForQR(res?.data?.code);
      console.log("re:",this.qrlogin)
    })
  }

  connectSocketForQR(code: any): void {
    this.socket = new WebSocket('wss://apis.ciwac.com/socket/stream/?code='+ code);
    
    if (this.socket.readyState != 1) {
      this.socket.onopen = (event) => {
        console.log('socket isss connected');
        // receiver:  it will receive all incoming connection messages on soekcet.
      };
    }
    this.socket.onerror = (event) => {
      ////////  console.log(event);
       console.log('error in socket');
    };
    this.socket.onclose = (event) => {
       console.log('Disconnected from socket');
      this.socket = '';
      setTimeout(() => {
        //  console.log('socket isss', this.socket);
        this.connectSocketForQR(this.qrlogin);
      }, this.timer);
      this.timer *= 2;
    };
    this.socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data?.msg_type == 5) {
        let dataLogin = JSON.parse(data?.login_detail);
        dataLogin? this.loginWithQR(dataLogin) : null;
      }
      console.log('this is the event receiving', data);
    };
  }

  login() {
    this.submitted = true;
    if (this.tabSelected === 'email') {
      if (this.loginForm.valid) {
        this.loginForm.patchValue({ username: this.loginForm.value.new_user });
        this.signIn(this.loginForm.value);
      }
    }
    if (this.tabSelected === 'number') {
      if (this.loginForm.valid) {
        this.loginForm.patchValue({
          username: this.loginForm.value.new_user['e164Number'],
        });
        this.loginForm.patchValue({
          new_user: this.loginForm.value.new_user['number'],
        });
        this.signIn(this.loginForm.value);
      }
    }
  }
  public get employeeDetail(): any {
    try {
      return this.userData.employee_profile[0];
    } catch (e) {
      return false;
    }
  }
  public getTargetLang() {
    // this.http.gettargetLang(this.targetLanguage).subscribe(res => {
    // }, (error) => { });
  }
  postReport() {
    let files = [];
    for (let item of this.reportFiles) {
      files.push(item.id);
    }
    this.reportForm.patchValue({
      files: files,
    });

    // this.http.postReport(this.reportForm.value).subscribe(res => {

    // })
  }
  detectMobile() {
    this.http.detectDevice().subscribe((res) => {
      this.isMobile = res['details'];
    });
  }
  // AllLanguages() {
  //   this.http.getAlllang(this.Application).subscribe((res) => {
  //     this.LangDrop = res['languages'];
  //   });
  // }

  headertoggle(tab) {
    this.http.GetUserData().subscribe((data) => {
      this.userData = data.emp_info;
      this.SetUserData(this.userData);
      if (this.userData.emp_info.is_buissness_exist === true) {
        this.headertab = tab;
      } else {
        if (tab === 'Me') {
          this.headertab = tab;
        } else {
          this.router.navigate(['/ledger/join/create']);
        }
      }
    });
  }

  isAuthenticated(): boolean {
    const token = localStorage.getItem('user');
    return !!token; // Returns true if token exists, false otherwise
  }
  postReportFile(file) {
    // this.repfileLoader = true;
    // this.http.postFilesReport(file).subscribe(res => {
    //   this.repfileLoader = false;
    //   this.reportFiles.push(res['data'][0]);
    // }, (error) => {
    //   this.repfileLoader = false;
    // });
  }
  getAllReasonsReport() {
    // this.http.getReportReasons().subscribe(res => {
    //   let reportReasons = res['detail']['reasons'];
    //   this.reportReason.user = reportReasons[0];
    //   this.reportReason.network = reportReasons[1];
    //   this.reportReason.broadcast = reportReasons[2];
    //   this.reportReason.chat = reportReasons[3];
    // })
  }
  ngbDateFormat(date) {
    try {
      let transDate = { year: 0, month: 0, day: 0 };
      var dateObj = new Date(date);
      transDate.year = dateObj.getUTCFullYear();
      transDate.month = dateObj.getUTCMonth() + 1;
      transDate.day = dateObj.getUTCDate();
      return transDate;
    } catch (e) {
      return null;
    }
  }
  returnDateFormat(date, format) {
    if (date) {
      if (date.month === 12) {
        let newDate = date.day + ' ' + date.month + ' ' + date.year;
        //  console.log('NewDat', newDate);

        if (format.toString() === '1') {
          var aaaa = 'DD/MM/YYYY';
          var bbbb = moment(newDate, aaaa);
          // // alert(bbbb.format(aaaa));
          //  console.log('Date is', bbbb.format(aaaa));
          return bbbb.format(aaaa);
        } else if (format.toString() === '2') {
          var aaaa = 'MM/DD/YYYY';
          var bbbb = moment(newDate, aaaa);
          // // alert(bbbb.format(aaaa));
          //  console.log('Date is', bbbb.format(aaaa));
          return bbbb.format(aaaa);
        } else {
          var aaaa = 'YYYY/MM/DD';
          var bbbb = moment(newDate, aaaa);
          // // alert(bbbb.format(aaaa));
          //  console.log('Date is', bbbb.format(aaaa));
          return bbbb.format(aaaa);
        }
      } else {
        if (moment(date).format('DD/MM/YYYY') !== 'Invalid date') {
          if (format.toString() === '1') {
            //  console.log('Date is', date);
            return moment(date).subtract(1, 'months').format('DD/MM/YYYY');
          } else if (format.toString() === '2') {
            //   console.log(
            //   'Date is',
            //   date,
            //   moment(date).subtract(1, 'months').format('MM/DD/YYYY')
            // );
            return moment(date).subtract(1, 'months').format('MM/DD/YYYY');
          } else {
            //  console.log('Date is', date);
            return moment(date).subtract(1, 'months').format('YYYY/MM/DD');
          }
        } else {
          return date;
        }
      }
    } else {
      return date;
    }
  }
  ledgerCurrencies() {
    this.http.ledgerCurrencies().subscribe(
      (res) => {
        this.ledger_currencies = res['data'];
        this.date_format = res['date_formats'];
        this.status = true;
        //  console.log('status', this.status);
      },
      (error) => {}
    );
  }
  transactionTypes() {
    this.http.transactionTypes().subscribe(
      (res) => {
        this.trans_types = res['data'];
      },
      (error) => {}
    );
  }
  public get companyDetails(): any {
    try {
      return this.userData.employee_profile[0].company;
    } catch (e) {
      return false;
    }
  }
  public get emp_Currency(): any {
    try {
      return this.userData.emp_info.Business.currency.id;
    } catch (e) {
      return false;
    }
  }
  public get userDetail(): any {
    try {
      return this.userData.employee_profile[0].user;
    } catch (e) {
      return false;
    }
  }

  public get isAdmin(): boolean {
    if (this.userData.employee_profile[0].employee_role[0]) {
      return (
        this.userData.employee_profile[0].employee_role[0].role_name === 'ADMIN'
      );
    } else {
      return false;
    }
  }

  public get industry(): boolean {
    return this.userData.employee_profile[0].company.company_industry_types[0]
      .industry;
  }

  public get isConnected(): boolean {
    try {
      return (
        this.userData.employee_profile[0].company.name !== '' &&
        this.userData.employee_profile[0].is_approved == true
      );
    } catch (e) {
      return false;
    }
  }
  public get isConnectedBusiness(): boolean {
    try {
      return this.userData.emp_info.Business.is_buissness_exist == true;
    } catch (e) {
      return false;
    }
  }
  public isConnectedUser(user): boolean {
    try {
      return user.company.name !== '' && user.is_approved == true;
    } catch (e) {
      return false;
    }
  }
  public get NotRequested(): boolean {
    try {
      return (
        this.userData.employee_profile[0].is_requested == false &&
        this.userData.employee_profile[0].is_approved == false
      );
    } catch (e) {
      return false;
    }
  }
  public get isRequestedButNotApproved(): boolean {
    try {
      return (
        this.userData.employee_profile[0].is_requested === true &&
        this.userData.employee_profile[0].is_approved === false
      );
    } catch (e) {
      return false;
    }
  }

  public get isConnectedButNotApproved(): boolean {
    try {
      return (
        this.userData.employee_profile[0].company.name !== '' &&
        this.userData.employee_profile[0].is_approved == false
      );
    } catch (e) {
      return false;
    }
  }
  public get connectedNetwork(): string {
    try {
      return this.userData.employee_profile[0].company.name;
    } catch (e) {
      return '';
    }
  }
  public get connectedNetworkAddress(): string {
    return (
      this.userData.employee_profile[0].company.address_line1 +
      this.userData.employee_profile[0].company.address_line2 +
      ', ' +
      this.userData.employee_profile[0].company.city +
      ', ' +
      this.userData.employee_profile[0].company.state_province +
      ', ' +
      this.userData.employee_profile[0].company.country
    );
  }
  public get isContractPermission(): boolean {
    return (
      this.userData.employee_profile[0].permissions.findIndex(
        (obj) => obj.permission_name === 'Contract'
      ) > -1
    );
  }

  public get isProductPermission(): boolean {
    return (
      this.userData.employee_profile[0].permissions.findIndex(
        (obj) => obj.permission_name === 'Market'
      ) > -1
    );
  }

  public get isQuotationPermission(): boolean {
    return (
      this.userData.employee_profile[0].permissions.findIndex(
        (obj) => obj.permission_name === 'Quotation'
      ) > -1
    );
  }

  public get username(): string {
    try {
      return this.userData.emp_info.username;
    } catch (e) {
      return '';
    }
  }
  public get emp_id(): string {
    try {
      return this.userData.emp_info.emp_id;
    } catch (e) {
      return '';
    }
  }
  public get userfullname(): string {
    return (
      this.userData.emp_info.first_name + ' ' + this.userData.emp_info.last_name
    );
  }
  public get userId(): string {
    try {
      return this.userData.emp_info.id;
    } catch (e) {
      return '0';
    }
  }
  getAllCountriesList() {
    // this.http.getAllCountries().subscribe((res) => {
    //   this.allCountries = res['detail'];
    // });
  }
  public stringtonumber(number) {
    return Number(number);
  }
  public get subscription(): any {
    try {
      return this.userData.subscription;
    } catch (e) {
      return null;
    }
  }
  public get empId(): string {
    try {
      return this.userData.employee_profile[0].id;
    } catch (e) {
      return;
    }
  }

  public get isLoggedIn(): boolean {
    try {
      const user = JSON.parse(localStorage.getItem('user'));
      //  return (user != null && user.emailVerified != false) ? true : false;
      return user != null ? true : false;
    } catch (e) {
      return false;
    }
  }

  SetUserDataLanguage(data) {
    //
    if (this.signinstatus === true) {
      localStorage.setItem('user', JSON.stringify(data));
      // window.location.reload();
      this.userState.next(data);
      this.signinstatus = false;
      //  console.log('userObject', localStorage.getItem('user'));
    } else if (this.signinstatus === false) {
      // const localuser = JSON.parse(localStorage.getItem('user'));
      // //  console.log("local staorgae", localuser?.token)
      var user = {
        emp_info: data,
        token: this.tokenvar,
      };
      localStorage.setItem('user', JSON.stringify(user));
      // window.location.reload();
      this.userState.next(user);
    }
  } // Set user
  SetUserData(data) {
    if (this.signinstatus === true) {
      localStorage.setItem('user', JSON.stringify(data));
      this.userState.next(data);
      this.signinstatus = false;
      //  console.log('userObject', localStorage.getItem('user'));
    } else if (this.signinstatus === false) {
      // const localuser = JSON.parse(localStorage.getItem('user'));
      // //  console.log("local staorgae", localuser?.token)
      var user = {
        emp_info: data,
        token: this.tokenvar,
      };
      localStorage.setItem('user', JSON.stringify(user));
      this.userState.next(user);
    }
  }
  // getIP() {
  //     this.http.getIPAddress().subscribe((res) => {
  //         this.http.getLocationBaseOnIp(res['ip']).subscribe((ress) => {
  //             this.countryByIP = ress['country_name'];
  //             this.ipAddress = ress['ip']
  //         });
  //     });
  // }
  //  Sign out
  SignOut() {
    this.http.logOutUser().subscribe(
      (ress) => {
        this.userData = {};
        //social
        // this.socialSignOut();
        localStorage.clear();
        localStorage.setItem('logout', JSON.stringify(true));
        window.location.reload();
        this.showLoader = false;
      },
      (err) => {
        this.userData = {};
        localStorage.clear();
        localStorage.setItem('logout', JSON.stringify(true));
        this.cookieService.deleteAll('user', '/authentication/login');
        window.location.reload();
        this.showLoader = false;
      }
    );
  }
  getIPData() {
    this.http.getIpbaseCurrency().subscribe(
      (res) => {
        this.dataIPBased = res['details'];
      },
      (error) => {}
    );
  }
  IPbaseCurrency() {
    this.http.getIpbaseCurrency().subscribe(
      (res) => {
        this.IPcurrency = res['details']['currency'];
        this.dataIPBased = res['details'];
        this.countryCode = res['details']['country_code'];
        let language = localStorage.getItem('languageLanding')
          ? localStorage.getItem('languageLanding')
          :'en';
        this.targetLanguage = language;
        this.setRoute(language);
        // localStorage.setItem('languageLanding', language);
        this.setLanguageOverAll(language);
      },
      (error) => {}
    );
  }
  setLanguageOverAll(lang: any = 'en') {
    this.translate.use(lang);
    this.setDirectionWeb(lang);
    
    this.translate.get('Free Connected Accounting Software & Bookkeeping Platform').subscribe((title: any) => {
      this.titleService.setTitle(title);
    });
    this.translate
      .get('Ciwac is a cloud-based accounting and bookkeeping software to digitize the accounting workflows of small and medium-sized businesses.')
      .subscribe((description: any) => {
        this.metaService.updateTag({ name: 'description', content: description });
      });
    this.translate
      .get('accounting app, ledger management, salary book, inventory management, Bookkeeping software, connected accounting, Purchase Journal, cash book, bank book, invoice management, Sales Journal')
      .subscribe((keywords: any) => {
        this.metaService.updateTag({ name: 'keywords', content: keywords });
      });
  }
  
  AdminRequestRec() {
    this.http.adminrequestReceived().subscribe(
      (res) => {
        this.adminRequestReceive = res;
      },
      (error) => {}
    );
  }

loginWithQR(data: any) {
  console.log('this is data here', data);
  localStorage.setItem('user', JSON.stringify(data));
  this.userState.next(data);
        this.AdminRequestRec();
        
        //
        if (!this.userData.emp_info.first_name) {
          this.router.navigate(['/user/edit-user']);
        } else if (!this.userData.emp_info.Business) {
          this.router.navigate(['/ledger/join/create']);
        } else {
          this.router.navigate(['ledger/default']);
        }
}

  signIn(data) {
    //
    // this.SignOut()
    this.showLoader = true;
    this.http.signin(data).subscribe(
      (res) => {
        this.showLoader = true;
        this.signinstatus = true;
        // if(this.build_type === 'niwac'){
        //   this.LangObject = 'zh-Hans'
        // }
        //  console.log('L ha', this.LangObject);

        // this.changeLanguage(this.LangObject)
        this.SetUserData(res);
        this.AdminRequestRec();
        
        //
        if (!this.userData.emp_info.first_name) {
          this.router.navigate(['/user/edit-user']);
        } else if (!this.userData.emp_info.Business) {
          this.router.navigate(['/ledger/join/create']);
        } else {
          this.router.navigate(['ledger/default']);
        }

        this.showLoader = false;
      },
      (error) => {
        this.showLoader = false;
        this.toast.error(error['error']['msg']);
        if (this.tabSelected === 'number') {
          this.loginForm.patchValue({
            username: this.loginForm.value.user['e164Number'],
          });
        }
        if (
          error['error'].hasOwnProperty('verified') &&
          !error['error']['verified']
        ) {
          this.router.navigate(['/authentication/otp'], {
            queryParams: {
              resendOTP: 'true',
              user: this.loginForm.value.username,
              // pwd: this.loginForm.value.password
            },
          });
        }
      }
    );
  }
  setDirectionWeb(language: any = 'en') {
    if (language === 'ar' || language === 'ur') {
      this.dirWeb = 'rtl';
      $('body').removeClass('direction-ltl').addClass('direction-rtl');
    } else {
      this.dirWeb = 'ltr';
      $('body').removeClass('direction-rtl').addClass('direction-ltr');
      $('body').removeClass('rtl-lang-font').addClass('ltr-lang-font');
    }
    if (language === 'ur') {
      $('body').removeClass('ltr-lang-font').addClass('rtl-lang-font');
    } else {
      $('body').removeClass('rtl-lang-font').addClass('ltr-lang-font');
    }
  }
  getDefaultLanguage() {
    if (this.isBrowser) {
      this.targetLanguage = JSON.parse(localStorage.getItem('language'));
      this.LangIs = JSON.parse(localStorage.getItem('short_lang'));
      this.translate.use(this.targetLanguage);
      if (this.targetLanguage === 'ar' || this.targetLanguage === 'ur') {
        this.dirWeb = 'rtl';
        $('body').removeClass('direction-ltl').addClass('direction-rtl');
      } else {
        this.dirWeb = 'ltr';
        $('body').removeClass('direction-rtl').addClass('direction-ltr');
        $('body').removeClass('rtl-lang-font').addClass('ltr-lang-font');
      }
      if (this.targetLanguage === 'ur') {
        $('body').removeClass('ltr-lang-font').addClass('rtl-lang-font');
      } else {
        $('body').removeClass('rtl-lang-font').addClass('ltr-lang-font');
      }
    }
  }
  LandingLang(lang) {
    this.http.languageChange(lang).subscribe(
      (res) => {
        this.toast.success(res['detail']);
      },
      (err) => {
        // this.toast.error(err['error']['msg'])
      }
    );
  }
  changeLanguageLand(lang) {
    this.LangObject = lang;
    localStorage.setItem('languageLanding', lang.language_value);
    this.setRoute(lang.language_value);
    this.http.languageChange(lang.language_value).subscribe(
      (res) => {
        this.toast.success(res['detail']);
      },
      (err) => {
        // this.toast.error(err['error']['msg'])
      }
    );
    this.translate.use(lang.language_value);
    this.targetLanguage = lang.language_value;
    if (this.targetLanguage === 'ar' || this.targetLanguage === 'ur') {
      this.dirWeb = 'rtl';
      $('body').removeClass('direction-ltl').addClass('direction-rtl');
    } else {
      this.dirWeb = 'ltr';
      $('body').removeClass('direction-rtl').addClass('direction-ltr');
      $('body').removeClass('rtl-lang-font').addClass('ltr-lang-font');
    }
    if (this.targetLanguage === 'ur') {
      $('body').removeClass('ltr-lang-font').addClass('rtl-lang-font');
    } else {
      $('body').removeClass('rtl-lang-font').addClass('ltr-lang-font');
    }

    this.replaceMetaTags();
    window.location.reload();
  }
  replaceMetaTags() {
    this.translate
      .get('Ciwac is a cloud-based accounting and bookkeeping software to digitize the accounting workflows of small and medium-sized businesses.')
      
      .subscribe((data: any) => {
        this.metaService.add({
          url: 'https://web.accountect.com',
          author: data,
          keywords: data,
          description: data,
        });
      });
    // if (this.router.url.includes('/') || this.router.url.includes('authentication')) {
    //   this.translate.get("Free Connected Accounting Software & Bookkeeping Platform").subscribe((data: any) => {
    //     this.metaService.add({url: 'https://web.accountect.com', author: data, keywords: data, description: data});
    //   });
    // } else if (this.router.url.includes('ledger')) {
    //   this.translate.get("One Way & Connected Ledgers").subscribe((data: any) => {
    //     this.metaService.add({url: 'https://web.accountect.com', author: data, keywords: data, description: data});
    //   });
    // } else if (this.router.url.includes('account-business-summary')) {
    //   this.translate.get("Business Accounts Summary").subscribe((data: any) => {
    //     this.metaService.add({url: 'https://web.accountect.com', author: data, keywords: data, description: data});
    //   });
    // } else if (this.router.url.includes('Accounting-Suite')) {
    //   this.translate.get("Accounting Suite: C-Plus").subscribe((data: any) => {
    //     this.metaService.add({url: 'https://web.accountect.com', author: data, keywords: data, description: data});
    //   });
    // } else if (this.router.url.includes('Cash-Book')) {
    //   this.translate.get("Cash Book").subscribe((data: any) => {
    //     this.metaService.add({url: 'https://web.accountect.com', author: data, keywords: data, description: data});
    //   });
    // } else if (this.router.url.includes('bank-book')) {
    //   this.translate.get("Bank Book").subscribe((data: any) => {
    //     this.metaService.add({url: 'https://web.accountect.com', author: data, keywords: data, description: data});
    //   });
    // } else if (this.router.url.includes('expense-book')) {
    //   this.translate.get("Expense Book").subscribe((data: any) => {
    //     this.metaService.add({url: 'https://web.accountect.com', author: data, keywords: data, description: data});
    //   });
    // } else if (this.router.url.includes('Sale-Journal')) {
    //   this.translate.get("Sales Journal").subscribe((data: any) => {
    //     this.metaService.add({url: 'https://web.accountect.com', author: data, keywords: data, description: data});
    //   });
    // } else if (this.router.url.includes('purchase-book')) {
    //   this.translate.get("Purchase Journal").subscribe((data: any) => {
    //     this.metaService.add({url: 'https://web.accountect.com', author: data, keywords: data, description: data});
    //   });
    // } else if (this.router.url.includes('inventory-management')) {
    //   this.translate.get("Inventory Management").subscribe((data: any) => {
    //     this.metaService.add({url: 'https://web.accountect.com', author: data, keywords: data, description: data});
    //   });
    // } else if (this.router.url.includes('inventory-management')) {
    //   this.translate.get("Stock Management").subscribe((data: any) => {
    //     this.metaService.add({url: 'https://web.accountect.com', author: data, keywords: data, description: data});
    //   });
    // } else if (this.router.url.includes('Salary-Book')) {
    //   this.translate.get("Salary Book").subscribe((data: any) => {
    //     this.metaService.add({url: 'https://web.accountect.com', author: data, keywords: data, description: data});
    //   });
    // } else if (this.router.url.includes('Employee-Attendance-Management')) {
    //   this.translate.get("Employee Attendance Management").subscribe((data: any) => {
    //     this.metaService.add({url: 'https://web.accountect.com', author: data, keywords: data, description: data});
    //   });
    // } else {
    //   this.translate.get("Employee Ledger").subscribe((data: any) => {
    //     this.metaService.add({url: 'https://web.accountect.com', author: data, keywords: data, description: data});
    //   });
    // }
  }

  LangDir() {
    if (this.targetLanguage === 'ar' || this.targetLanguage === 'ur') {
      this.dirWeb = 'rtl';
      $('body').removeClass('direction-ltl').addClass('direction-rtl');
    } else {
      this.dirWeb = 'ltr';
      $('body').removeClass('direction-rtl').addClass('direction-ltr');
      $('body').removeClass('rtl-lang-font').addClass('ltr-lang-font');
    }
    if (this.targetLanguage === 'ur') {
      $('body').removeClass('ltr-lang-font').addClass('rtl-lang-font');
    } else {
      $('body').removeClass('rtl-lang-font').addClass('ltr-lang-font');
    }
  }
  // public lang_id
  public changeLanguage(lang) {
    if (lang) {
      this.http.languageChange(lang.language_value).subscribe(
        (res) => {
          this.translate.use(lang.language_value);
          this.targetLanguage = lang.language_value;
          this.isBrowser?localStorage.setItem('language', lang.language_value): null;
          this.isBrowser?window.location.reload(): null;
        },
        (err) => {
          this.translate.use(lang.language_value);
          this.targetLanguage = lang.language_value;
          this.isBrowser?localStorage.setItem('language', lang.language_value): null;
          this.isBrowser?window.location.reload(): null;
        }
      );
    }
    // // this.lang_id=lang.id
    // if (this.build_type === 'ciwac') {
      
    // } else {
    //   if (this.isBrowser) {
    //     if (lang) {
    //       this.http.languageChange(lang.language_value).subscribe(
    //         (res) => {},
    //         (err) => {}
    //       );
    //       window.location.reload();
    //     }
    //   }
    //   // else{
    //   //   localStorage.setItem('language', 'zh-Hans');
    //   //   this.http.languageChange('zh-Hans').subscribe(
    //   //     (res) => {
    //   //     }, err => {
    //   //     })
    //   //   window.location.reload();
    //   // }
    //   // this.translate.use(lang.language_value);
    //   // this.targetLanguage = lang.language_value
    // }
  }

  socialLoginApple(code) {
    let formData = new FormData();
    formData.append('code', code);
    let data = {
      code: code,
    };

    this.http.postSocialLoginApple(data).subscribe(
      (res) => {
        this.signinstatus = true;
        this.SetUserData(res);
        localStorage.removeItem('logout');
        if (!this.userData?.emp_info?.first_name) {
          this.router.navigate(['/user/edit-user']);
        } else if (!this.userData?.emp_info?.Business?.business_name) {
          this.router.navigate(['/ledger/join/create']);
        } else {
          this.router.navigate(['ledger/default']);
        }
        this.showLoader = false;
      },
      (error) => {
        this.toast.error('Something went wrong');
      }
    );
  }

  // socialLogin(token: string, ul: any, r: any, a: any, type: any, referral: any, fromUser: any): Observable<any> {
  //   return this.https.post('/api/auth/google', { token, ul, r, a, type, referral, fromUser });
  // }

socialLogin(user, ul, r, a, type, referral, fromuser) {
    this.socialLoginForm.patchValue({
      id: user.id,
      accessToken: user.authToken,
      name: user.name,
      email: user.email,
      imageurl: user.photoUrl,
      provider: user.provider,
    });
    this.showLoader = true;
    //  console.log('this.socialLoginForm.value', this.socialLoginForm.value);
    this.http
      .postSocialLogin(
        this.socialLoginForm.value,
        ul,
        r,
        a,
        type,
        referral,
        fromuser
      )
      .subscribe(
        (res) => {
          this.signinstatus = true;
          this.SetUserData(res);
          localStorage.removeItem('logout');
          if (!this.userData?.emp_info?.first_name) {
            this.router.navigate(['/user/edit-user']);
          } else if (!this.userData?.emp_info?.Business?.business_name) {
            this.router.navigate(['/ledger/join/create']);
          } else {
            this.router.navigate(['ledger/default']);
          }
          this.showLoader = false;
        },
        (error) => {
          this.toast.error('Something went wrong');
        }
      );
  }

  googleLogin(data) {
    // this.socialLoginForm.patchValue({
    //   id: user.id,
    //   accessToken: user.authToken,
    //   name: user.name,
    //   email: user.email,
    //   imageurl: user.photoUrl,
    //   provider: user.provider,
    // });
    let dataa = {
      'jwt_token': data,
      'provider': 'google'
    }
    this.showLoader = true;
    //  console.log('this.socialLoginForm.value', this.socialLoginForm.value);
    this.http
      .postgoogleLogin(
       dataa
      )
      .subscribe(
        (res) => {
          // console.log(res);
          console.log(res)
          this.signinstatus = true;
          this.SetUserData(res);
          localStorage.removeItem('logout');
          if (!this.userData?.emp_info?.first_name) {
            this.router.navigate(['/user/edit-user']);
          } else if (!this.userData?.emp_info?.Business?.business_name) {
            this.router.navigate(['/ledger/join/create']);
          } else {
            this.router.navigate(['ledger/default']);
          }
          this.showLoader = false;
        },
        (error) => {
          this.toast.error('Something went wrong');
        }
      );
  }

  linkedInLogin(user, profile) {
    this.socialLoginForm.patchValue({
      id: profile.id,
      accessToken: user.access_token,
      name: profile.localizedFirstName + ' ' + profile.localizedLastName,
      email: '',
      imageurl: profile.profilePicture.displayImage,
      provider: 'LinkedIn',
    });
    this.showLoader = true;
    //  console.log('this.socialLoginForm.value', this.socialLoginForm.value);
    this.http.postSocialLogin(this.socialLoginForm.value).subscribe(
      (res) => {
        this.signinstatus = true;
        this.SetUserData(res);
        localStorage.removeItem('logout');
        if (!this.userData?.emp_info?.first_name) {
          this.router.navigate(['/user/edit-user']);
        } else if (!this.userData?.emp_info?.Business?.business_name) {
          this.router.navigate(['/ledger/join/create']);
        } else {
          this.router.navigate(['ledger/default']);
        }
        this.showLoader = false;
      },
      (error) => {
        this.toast.error('Something went wrong');
      }
    );
  }
  //social
  // socialSignOut(): void {
  //   this.socialAuthService.signOut();
  // }
  addSlop() {
    var r1 = document.getElementById('#addSlope');
    if (
      this.router.url.includes('/authentication/forgetpassword') ||
      this.router.url.includes('/authentication/register')
    ) {
      r1.classList.add('slope');
    } else {
      r1.classList.remove('slope');
    }
  }
  setRoute(lang) {
    
    this.router.navigate(['/' + lang]);
  }
}
