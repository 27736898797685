import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl, Validators } from '@angular/forms';

@Directive({
  selector: '[appMinmax]'
})
export class MinmaxDirective {
  @Input()
  public min: number;

  @Input()
  public max: number;

  constructor(private ref: ElementRef, private control: NgControl) { }
  @HostListener('input', [ '$event' ])
  public onInput(a_Event: InputEvent): void {
    const abstractControl = this.control.control;
    if (abstractControl.value > this.max) {
      abstractControl.setValue(this.max);
    }
    if (abstractControl.value < this.min) {
      abstractControl.setValue(this.min);
    }
  }
  
}
