
<router-outlet></router-outlet>


<!-- <div *ngIf="authService?.isMobile?.is_mobile && !showMobile" class="mobile-banner top-0 left-0 bg-white w-100 h-100">
  <ng-container *ngIf="build_type === 'ciwac'">
    <div class="d-flex align-items-center justify-content-center w-100 h-100 position-relative">
      <div class="position-absolute right-0 top-0 p-4">
        <img alt="logo" loading="lazy" class="img-50 h-auto " src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/updated/logo.svg">
      </div>
      <div class="row mx-auto top-0 left-0 container">
        <ng-container>
          <div *ngIf="authService?.isMobile?.os_family === 'Android'"
            class="d-flex justify-content-center w-100 flex-wrap app-stores">
            <a class="text-deco-none" [href]="authService.linkMobileRef?authService.linkMobileRef: 'https://play.google.com/store/apps/details?id=com.tech.ciwac&hl=en&gl=US'">
              <button class="btn p-2 hiwacAPK">
                <img class="" src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/Playstore en.svg" alt="">
              </button>
            </a>
          </div>
          <div *ngIf="authService?.isMobile?.os_family === 'iOS'"
            class="d-flex justify-content-center w-100 flex-wrap app-stores">
            <a class="text-deco-none"  [href]="authService.linkMobileRef?authService.linkMobileRef: 'https://apps.apple.com/us/app/%E6%98%B5%E6%B2%83%E7%A7%91/id1581386756'">
              <button class="btn p-2 hiwacAPK">
                <img class="" src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/Apple en.svg" alt="">
              </button>
            </a>
          </div>
        </ng-container>
  
      </div>
  
    </div>
  </ng-container>
</div> -->

<!-- <div *ngIf="authService?.isMobile?.is_mobile && !showMobile" class="mobile-banner top-0 left-0 bg-white w-100 h-100">
  <nav class="navbar navbar-expand-lg bg-body-tertiary" data-bs-theme="dark">
    <div class="container-fluid justify-content-between">
      <a class="navbar-brand">
        <img src="../assets/Accountect/topbarlogo.svg" alt="Bootstrap" width="150" height="50">
      </a>
      <div *ngIf="authService?.isMobile?.os_family === 'Android'" class="">
        <a class="text-deco-none" [href]="authService.linkMobileRef?authService.linkMobileRef: 'https://play.google.com/store/apps/details?id=com.tech.ciwac&hl=en&gl=US'">
          <button class="btn bg-slides text-white p-2 hiwacAPK">
            Download App
          </button>
        </a>
      </div>
      <div *ngIf="authService?.isMobile?.os_family === 'iOS'" class="">
        <a class="text-deco-none"  [href]="authService.linkMobileRef?authService.linkMobileRef: 'https://apps.apple.com/us/app/%E6%98%B5%E6%B2%83%E7%A7%91/id1581386756'">
          <button class="btn bg-slides text-white p-2 hiwacAPK">
            Download App
          </button>
        </a>
      </div>
    </div>
  </nav>
  <div>
    <div id="carouselExampleIndicators" class="carousel slide bg-slides" data-bs-ride="carousel">
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img src="../assets/Accountect/Appslides/1 – 15.svg" class="d-block w-100" alt="...">
        </div>
        <div class="carousel-item">
          <img src="../assets/Accountect/Appslides/2 – 15.svg" class="d-block w-100" alt="...">
        </div>
        <div class="carousel-item">
          <img src="../assets/Accountect/Appslides/3 – 17.svg" class="d-block w-100" alt="...">
        </div>
        <div class="carousel-item">
          <img src="../assets/Accountect/Appslides/4 – 15.svg" class="d-block w-100" alt="...">
        </div>
        <div class="carousel-item">
          <img src="../assets/Accountect/Appslides/5 – 40.svg" class="d-block w-100" alt="...">
        </div>
        <div class="carousel-item">
          <img src="../assets/Accountect/Appslides/6 – 41.svg" class="d-block w-100" alt="...">
        </div>
        <div class="carousel-item">
          <img src="../assets/Accountect/Appslides/6 – 42.svg" class="d-block w-100" alt="...">
        </div>
        <div class="carousel-item">
          <img src="../assets/Accountect/Appslides/6 – 43.svg" class="d-block w-100" alt="...">
        </div>
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
    
  </div>
</div> -->



<div *ngIf="!isPublicRoute">
  <div *ngIf="authService?.isMobile?.is_mobile && !showMobile" class="mobile-banner top-0 left-0 bg-white w-100 h-100">
    <nav class="navbar navbar-expand-lg bg-body-tertiary" data-bs-theme="dark">
      <div class="container-fluid justify-content-between">
        <a class="navbar-brand">
          <img src="../assets/Accountect/topbarlogo.svg" alt="Bootstrap" width="150" height="50">
        </a>
        <div *ngIf="authService?.isMobile?.os_family === 'Android'" class="">
          <a class="text-deco-none" [href]="authService.linkMobileRef ? authService.linkMobileRef : 'https://play.google.com/store/apps/details?id=com.hailu.accountect&hl=en'">
            <button class="btn bg-slides text-white p-2 hiwacAPK">
              Download App
            </button>
          </a>
        </div>
        <div *ngIf="authService?.isMobile?.os_family === 'iOS'" class="">
          <a class="text-deco-none" [href]="authService.linkMobileRef ? authService.linkMobileRef : 'https://apps.apple.com/app/accountect-accounting/id6474992652'">
            <button class="btn bg-slides text-white p-2 hiwacAPK">
              Download App
            </button>
          </a>
        </div>
      </div>
    </nav>
    <div *ngIf="authService?.isMobile?.os_family === 'Android'">
      <div id="carouselExampleIndicators" class="carousel slide bg-slides" data-bs-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src="../assets/Accountect/Appslides/1 – 15.svg" class="d-block w-100" alt="...">
          </div>
          <div class="carousel-item">
            <img src="../assets/Accountect/Appslides/2 – 15.svg" class="d-block w-100" alt="...">
          </div>
          <div class="carousel-item">
            <img src="../assets/Accountect/Appslides/3 – 17.svg" class="d-block w-100" alt="...">
          </div>
          <div class="carousel-item">
            <img src="../assets/Accountect/Appslides/4 – 15.svg" class="d-block w-100" alt="...">
          </div>
          <div class="carousel-item">
            <img src="../assets/Accountect/Appslides/5 – 40.svg" class="d-block w-100" alt="...">
          </div>
          <div class="carousel-item">
            <img src="../assets/Accountect/Appslides/6 – 41.svg" class="d-block w-100" alt="...">
          </div>
          <div class="carousel-item">
            <img src="../assets/Accountect/Appslides/6 – 42.svg" class="d-block w-100" alt="...">
          </div>
          <div class="carousel-item">
            <img src="../assets/Accountect/Appslides/6 – 43.svg" class="d-block w-100" alt="...">
          </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
    <div *ngIf="authService?.isMobile?.os_family === 'iOS'" class="ios">
      <div id="carouselExampleIndicators" class="carousel slide bg-slides" data-bs-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src="../assets/Accountect/AppSlidesIOS/1 – 17.png" class="d-block w-100" alt="...">
          </div>
          <div class="carousel-item">
            <img src="../assets/Accountect/AppSlidesIOS/2 – 17.png" class="d-block w-100" alt="...">
          </div>
          <div class="carousel-item">
            <img src="../assets/Accountect/AppSlidesIOS/3 – 19.png" class="d-block w-100" alt="...">
          </div>
          <div class="carousel-item">
            <img src="../assets/Accountect/AppSlidesIOS/4 – 17.png" class="d-block w-100" alt="...">
          </div>
          <div class="carousel-item">
            <img src="../assets/Accountect/AppSlidesIOS/5 – 42.png" class="d-block w-100" alt="...">
          </div>
          <div class="carousel-item">
            <img src="../assets/Accountect/AppSlidesIOS/6 – 47.png" class="d-block w-100" alt="...">
          </div>
          <div class="carousel-item">
            <img src="../assets/Accountect/AppSlidesIOS/6 – 48.png" class="d-block w-100" alt="...">
          </div>
          <div class="carousel-item">
            <img src="../assets/Accountect/AppSlidesIOS/6 – 49.png" class="d-block w-100" alt="...">
          </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  </div>
</div>

