<ng-template #dialog let-modal>
    <div class="modal-body text-center px-0">
        <div class="position-absolute CloSe">
            <button type="button" class="btn-close" aria-label="Close" (click)="modalClose()"></button>
        </div>
        <p class="timeheading">{{'Upgrade Your Subscription' | translate}}</p>
        <img src="../../../../assets/Accountect/planUpgrade.svg"  alt="">
        <p class="px-2">
            {{'Are you enjoying Using Accountect? if so, Please consider upgrade your plan' | translate}}
        </p>
        <button type="button" class="btn upgrade" (click)="openModalNewUser(Add_New_User)" >{{'Upgrade' | translate}}</button>

    </div>
</ng-template>

<ng-template #Add_New_User let-modal>
    <div class="modal-ngb">
        <div class="modal-body ledger-debit-modal p-3">
            <div>
                <div class="w-100 d-flex justify-content-between">
                    <div class="text-center w-98">
                        <h6 class="text-dark font-weight-600 m-0">
                            {{'Payment Plan'|translate}}
                        </h6>
                    </div>
                    <div class="text-center">
                        <button type="button" class="btn-close f-12" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
                    </div>
                </div>
                <div class="bg-primary usage p-2 box-shad mt-2 detail-badge align-items-center cursor-pointer" (click)="openModaluasge(usagedeTail)">
                    <div class="d-flex justify-content-between">
                        <div class="d-flex flex-column">
                            <span class="m-0 f-13">{{'Usage Detail'|translate}}</span>
                        </div>
                        <div>
                            <i class="fa fa-angle-right text-white arrow-detail px-2"
                                [ngClass]="{'fa-angle-right': auth.dirWeb == 'ltr', 'fa-angle-left': auth.dirWeb == 'rtl'}"
                                aria-hidden="true"></i>
                        </div>
                    </div>
                </div>

                <div class="SPECIAL_toggle my-3">
                    <span class="mr-2 text-primary font-weight-bold">Monthly</span>
                    <label class="switch mr-2 ">
                        <input type="checkbox" [(ngModel)]="isYearly">
                        <span class="slider round"></span>
                    </label>
                    <span class="text-primary font-weight-bold">Yearly</span>  &nbsp; (<span class="text-primary">Save Upto $40 annually</span>)
                </div>
                <div class="d-flex justify-content-between">
                    <div class="w-49">
                        <div class="b-r-20 box-shad Plantab_height">
                            <div class="modal-header p-2 px-3 flex-column align-items-start">
                                <h6 class=" m-0 position-relative">
                                    <span class="mr-2 text-primary font-weight-bold">{{freePlan?.name}}</span> 
                                    <span class="">
                                        <img src="../../../../assets/Accountect/bluetick.svg" width="20px" alt="">
                                    </span>
                                </h6>
                                <h6> <span class="f-18 font-weight-bold">{{freePlan?.currency}} {{freePlan.actual_price}}</span>&nbsp;<span class="text-light f-16">{{freePlan.price_type}}</span></h6>
                            </div>
                            <div class="modal-body p-1 px-3">
                                    <div class="plan-list">
                                        <div class="p-1 border-btm-plan">
                                            <p class="m-0 f-12"><span class="text-primary">{{freePlan.plan_stats.member_account}}</span> Users </p>
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                            <p class="m-0 f-12"><span class="text-primary">{{freePlan.plan_stats.customer_ledger}}</span> Connected & One-Way <span class="text-primary">Customer Accounts </span> </p>
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                            <p class="m-0 f-12"><span class="text-primary">{{freePlan.plan_stats.vendor_ledger}}</span> Connected & One-Way <span class="text-primary">Vendor Accounts </span> </p> 
                                        </div>
                                        <div class="py-2 p-1">
                                            <p class="module_s"> Sale</p>
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                            <p class="f-12 m-0"><i class="fa fa-check text-primary" aria-hidden="true"></i>  One-way & Connected <span class="text-primary font-weight-bold"> Invoices</span> (Upto {{freePlan.plan_stats.invoice}} )</p> 
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                            <p class="f-12 m-0"><i class="fa fa-check text-primary" aria-hidden="true"></i> <span class="text-primary font-weight-bold"> Sales Receipts  & Daily Sales Receipts</span> (Upto {{freePlan.plan_stats.both_sale_daily_receipts}} )</p> 
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                        <p class="f-12 m-0"><i class="fa fa-check text-primary" aria-hidden="true"></i> One-way & Connected <span class="text-primary font-weight-bold"> Estimates</span> (Upto {{freePlan.plan_stats.quotation}} )</p> 
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                            <p class="f-12 m-0"><i class="fa fa-check text-primary" aria-hidden="true"></i> One-way & Connected <span class="text-primary font-weight-bold"> Sales Return</span> (Upto {{freePlan.plan_stats.sale_return}} )</p> 
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                            <p class="f-12 m-0"><i class="fa fa-check text-primary" aria-hidden="true"></i> One-way & Connected <span class="text-primary font-weight-bold"> Packing Slip</span> (Upto {{freePlan.plan_stats.packing_slip}} )</p> 
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                            <p class="f-12 m-0"><i class="fa fa-check text-primary" aria-hidden="true"></i> One-way & Connected <span class="text-primary font-weight-bold">Sales Order</span> (Upto {{freePlan.plan_stats.sales_order}} )</p> 
                                        </div>
                                        <div class="py-2 p-1">
                                            <p class="module_s">Purchase</p>
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                            <p class="f-12 m-0"><i class="fa fa-check text-primary" aria-hidden="true"></i> One-way & Connected <span class="text-primary font-weight-bold"> Purchase Bill</span> (Upto {{freePlan.plan_stats.purchase_bill}} )</p> 
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                            <p class="f-12 m-0"><i class="fa fa-check text-primary" aria-hidden="true"></i> One-way & Connected <span class="text-primary font-weight-bold"> Purchase Order</span> (Upto {{freePlan.plan_stats.purchase_order}} )</p> 
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                            <p class="f-12 m-0"><i class="fa fa-check text-primary" aria-hidden="true"></i> One-way & Connected <span class="text-primary font-weight-bold"> Purchase Return</span> (Upto {{freePlan.plan_stats.purchase_return}} )</p> 
                                        </div>
                                        <div class="py-2 p-1">
                                            <p class="module_s">Expense</p>
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                            <p class="f-12 m-0"><i class="fa fa-check text-primary" aria-hidden="true"></i> One-way & Connected <span class="text-primary font-weight-bold"> Expense Bill</span> (Upto {{freePlan.plan_stats.expense_bill}} )</p> 
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                            <p class="f-12 m-0"><i class="fa fa-check text-primary" aria-hidden="true"></i> <span class="text-primary font-weight-bold"> Expense Receipt</span> (Upto {{freePlan.plan_stats.expense_receipt}} )</p> 
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                            <p class="f-12 m-0"><i class="fa fa-check text-primary" aria-hidden="true"></i> One-way & Connected <span class="text-primary font-weight-bold"> Expense Return</span> (Upto {{freePlan.plan_stats.expense_return}} )</p> 
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                            <p class="f-12 m-0"><i class="fa fa-check text-primary" aria-hidden="true"></i> <span class="text-primary font-weight-bold">Items,</span> Stock Level Tracking, & <span class="text-primary font-weight-bold">Bundles </span> (Upto {{freePlan.plan_stats.inventory_product}} )</p> 
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                            <p class="f-12 m-0"><i class="fa fa-check text-primary" aria-hidden="true"></i> Online Marketplace  (Upto {{freePlan.plan_stats.online_marketplace}} )</p> 
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                            <p class="f-12 m-0">Cash Book</p> 
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                            <p class="f-12 m-0"><span class="text-primary font-weight-bold"> 3</span> Bank</p> 
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                            <p class="f-12 m-0"><span class="text-primary font-weight-bold"> 3</span> Credit Card</p> 
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                            <p class="f-12 m-0"><span class="text-primary font-weight-bold"> 1 GB</span> Cloud Drive</p>
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                            <p class="f-12 m-0"><i class="fa fa-check text-primary" aria-hidden="true"></i> Reports</p>
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                            <p class="f-12 m-0"><i class="fa fa-check text-primary" aria-hidden="true"></i> Predefined User Roles</p>
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                            <p class="f-12 m-0"><i class="fa fa-check text-primary" aria-hidden="true"></i> Business Documents Number Series</p>
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                            <p class="f-12 m-0"><i class="fa fa-check text-primary" aria-hidden="true"></i> In-APP and Email Alerts</p>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-center mt-2">
                                        <button class="btn btn-white-border"> <img src="../../../../assets/Accountect/bluetick.svg" alt=""> Current</button>
                                    </div>
                            </div>
                        </div>
                    </div>
                    <div class="w-49" *ngIf="!isYearly">
                        <div class="b-r-20 box-shad Plantab_height">
                            <div class="modal-header p-2 px-3 flex-column align-items-start">
                                <h6 class=" m-0 position-relative">
                                    <span class="mr-2 text-primary font-weight-bold">{{MonthlyPlan.name}}</span> 
                                    <span class="">
                                        <img src="../../../../assets/Accountect/bluetick.svg" width="20px" alt="">
                                    </span>
                                </h6>
                                <h6> <span class="f-18 font-weight-bold">{{MonthlyPlan?.currency}} {{MonthlyPlan.actual_price}}</span>&nbsp;<span class="text-light f-16">{{MonthlyPlan.price_type}}</span></h6>
                            </div>
                            <div class="modal-body p-0">
                                    <div class="plan-list proPlan p-1 px-3">
                                        <div class="p-1 border-btm-plan">
                                            <p class="m-0 f-12"><span class="text-white">{{MonthlyPlan.plan_stats.member_account}}</span> Users </p>
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                        <p class="m-0 f-12"><span class="text-white"><i class="fa fa-check text-white" aria-hidden="true"></i> {{MonthlyPlan.plan_stats.customer_ledger}}</span> Connected & One-Way <span class="text-white">Customer Accounts </span> </p>
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                            <p class="m-0 f-12"><span class="text-white"><i class="fa fa-check text-white" aria-hidden="true"></i> {{MonthlyPlan.plan_stats.vendor_ledger}}</span> Connected & One-Way <span class="text-white">Vendor Accounts </span> </p> 
                                        </div>
                                        <div class="py-2 p-1">
                                            <p class="module_sd"> Sale</p>
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                            <p class="f-12 m-0"><i class="fa fa-check text-white" aria-hidden="true"></i> One-way & Connected <span class="text-white font-weight-bold"> Invoices</span> ({{MonthlyPlan.plan_stats.invoice}})</p> 
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                            <p class="f-12 m-0"><i class="fa fa-check text-white" aria-hidden="true"></i> <span class="text-white font-weight-bold">Sales Receipts  & Daily Sales Receipts</span> ({{MonthlyPlan.plan_stats.both_sale_daily_receipts}})</p> 
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                        <p class="f-12 m-0"><i class="fa fa-check text-white" aria-hidden="true"></i> One-way & Connected <span class="text-white font-weight-bold"> Estimates</span> ({{MonthlyPlan.plan_stats.quotation}})</p> 
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                            <p class="f-12 m-0"><i class="fa fa-check text-white" aria-hidden="true"></i> One-way & Connected <span class="text-white font-weight-bold"> Sales Return</span> ({{MonthlyPlan.plan_stats.sale_return}})</p> 
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                            <p class="f-12 m-0"><i class="fa fa-check text-white" aria-hidden="true"></i> One-way & Connected <span class="text-white font-weight-bold"> Packing Slip</span> ({{MonthlyPlan.plan_stats.packing_slip}})</p> 
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                            <p class="f-12 m-0"><i class="fa fa-check text-white" aria-hidden="true"></i> One-way & Connected <span class="text-white font-weight-bold">Sales Order</span> ({{MonthlyPlan.plan_stats.sales_order}})</p> 
                                        </div>
                                        <div class="py-2 p-1">
                                            <p class="module_sd">Purchase</p>
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                            <p class="f-12 m-0"><i class="fa fa-check text-white" aria-hidden="true"></i> One-way & Connected <span class="text-white font-weight-bold"> Purchase Bill</span> ({{MonthlyPlan.plan_stats.purchase_bill}})</p> 
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                            <p class="f-12 m-0"><i class="fa fa-check text-white" aria-hidden="true"></i> One-way & Connected <span class="text-white font-weight-bold"> Purchase Order</span> ({{MonthlyPlan.plan_stats.purchase_order}})</p> 
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                            <p class="f-12 m-0"><i class="fa fa-check text-white" aria-hidden="true"></i> One-way & Connected <span class="text-white font-weight-bold"> Purchase Return</span> ({{MonthlyPlan.plan_stats.purchase_return}})</p> 
                                        </div>
                                        <div class="py-2 p-1">
                                            <p class="module_sd">Expense</p>
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                            <p class="f-12 m-0"><i class="fa fa-check text-white" aria-hidden="true"></i> One-way & Connected <span class="text-white font-weight-bold"> Expense Bill</span> ({{MonthlyPlan.plan_stats.expense_bill}})</p> 
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                            <p class="f-12 m-0"><i class="fa fa-check text-white" aria-hidden="true"></i> <span class="text-white font-weight-bold"> Expense Receipt</span> ({{MonthlyPlan.plan_stats.expense_receipt}})</p> 
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                            <p class="f-12 m-0"><i class="fa fa-check text-white" aria-hidden="true"></i> One-way & Connected <span class="text-white font-weight-bold"> Expense Return</span> ({{MonthlyPlan.plan_stats.expense_return}})</p> 
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                            <p class="f-12 m-0"><i class="fa fa-check text-white" aria-hidden="true"></i> <span class="text-white font-weight-bold">Items,</span> Stock Level Tracking, & <span class="text-white font-weight-bold">Bundles </span> ({{MonthlyPlan.plan_stats.inventory_product}})</p> 
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                            <p class="f-12 m-0"><i class="fa fa-check text-white" aria-hidden="true"></i> Online Marketplace  ({{MonthlyPlan.plan_stats.online_marketplace}})</p> 
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                            <p class="f-12 m-0"><i class="fa fa-check text-white" aria-hidden="true"></i> Cash Book</p> 
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                            <p class="f-12 m-0"><i class="fa fa-check text-white" aria-hidden="true"></i> Bank</p> 
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                            <p class="f-12 m-0"><i class="fa fa-check text-white" aria-hidden="true"></i> Credit Card</p> 
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                            <p class="f-12 m-0"><span class="text-white font-weight-bold">{{MonthlyPlan.plan_stats.drive}} GB</span> Cloud Drive</p>
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                            <p class="f-12 m-0"><i class="fa fa-check text-white" aria-hidden="true"></i> Reports</p>
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                            <p class="f-12 m-0"><i class="fa fa-check text-white" aria-hidden="true"></i> Predefined User Roles</p>
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                            <p class="f-12 m-0"><i class="fa fa-check text-white" aria-hidden="true"></i> Business Documents Number Series</p>
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                            <p class="f-12 m-0"><i class="fa fa-check text-white" aria-hidden="true"></i> In-APP and Email Alerts</p>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-center mt-2">
                                        <button class="btn btn-white-border"> Upgrade</button>
                                    </div>
                            </div>
                        </div>
                    </div>
                    <div class="w-49" *ngIf="isYearly">
                        <div class="b-r-20 box-shad Plantab_height">
                            <div class="modal-header p-2 px-3 flex-column align-items-start">
                                <h6 class=" m-0 position-relative">
                                    <span class="mr-2 text-primary font-weight-bold">{{YearlyPlan.name}}</span> 
                                    <span class="">
                                        <img src="../../../../assets/Accountect/bluetick.svg" width="20px" alt="">
                                    </span>
                                </h6>
                                <h6> <span class="f-18 font-weight-bold">{{YearlyPlan?.currency}} {{YearlyPlan.actual_price}}</span>&nbsp;<span class="text-light f-16">{{YearlyPlan.price_type}}</span></h6>
                            </div>
                            <div class="modal-body p-0">
                                    <div class="plan-list proPlan p-1 px-3">
                                        <div class="p-1 border-btm-plan">
                                            <p class="m-0 f-12"><span class="text-white">{{YearlyPlan.plan_stats.member_account}}</span> Users </p>
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                        <p class="m-0 f-12"><span class="text-white"><i class="fa fa-check text-white" aria-hidden="true"></i> {{YearlyPlan.plan_stats.customer_ledger}}</span> Connected & One-Way <span class="text-white">Customer Accounts </span> </p>
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                            <p class="m-0 f-12"><span class="text-white"><i class="fa fa-check text-white" aria-hidden="true"></i> {{YearlyPlan.plan_stats.vendor_ledger}}</span> Connected & One-Way <span class="text-white">Vendor Accounts </span> </p> 
                                        </div>
                                        <div class="py-2 p-1">
                                            <p class="module_sd"> Sale</p>
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                            <p class="f-12 m-0"><i class="fa fa-check text-white" aria-hidden="true"></i> One-way & Connected <span class="text-white font-weight-bold"> Invoices</span> ({{YearlyPlan.plan_stats.invoice}})</p> 
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                            <p class="f-12 m-0"><i class="fa fa-check text-white" aria-hidden="true"></i> <span class="text-white font-weight-bold">Sales Receipts  & Daily Sales Receipts</span> ({{YearlyPlan.plan_stats.both_sale_daily_receipts}})</p> 
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                        <p class="f-12 m-0"><i class="fa fa-check text-white" aria-hidden="true"></i> One-way & Connected <span class="text-white font-weight-bold"> Estimates</span> ({{YearlyPlan.plan_stats.quotation}})</p> 
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                            <p class="f-12 m-0"><i class="fa fa-check text-white" aria-hidden="true"></i> One-way & Connected <span class="text-white font-weight-bold"> Sales Return</span> ({{YearlyPlan.plan_stats.sale_return}})</p> 
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                            <p class="f-12 m-0"><i class="fa fa-check text-white" aria-hidden="true"></i> One-way & Connected <span class="text-white font-weight-bold"> Packing Slip</span> ({{YearlyPlan.plan_stats.packing_slip}})</p> 
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                            <p class="f-12 m-0"><i class="fa fa-check text-white" aria-hidden="true"></i> One-way & Connected <span class="text-white font-weight-bold">Sales Order</span> ({{YearlyPlan.plan_stats.sales_order}})</p> 
                                        </div>
                                        <div class="py-2 p-1">
                                            <p class="module_sd">Purchase</p>
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                            <p class="f-12 m-0"><i class="fa fa-check text-white" aria-hidden="true"></i> One-way & Connected <span class="text-white font-weight-bold"> Purchase Bill</span> ({{YearlyPlan.plan_stats.purchase_bill}})</p> 
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                            <p class="f-12 m-0"><i class="fa fa-check text-white" aria-hidden="true"></i> One-way & Connected <span class="text-white font-weight-bold"> Purchase Order</span> ({{YearlyPlan.plan_stats.purchase_order}})</p> 
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                            <p class="f-12 m-0"><i class="fa fa-check text-white" aria-hidden="true"></i> One-way & Connected <span class="text-white font-weight-bold"> Purchase Return</span> ({{YearlyPlan.plan_stats.purchase_return}})</p> 
                                        </div>
                                        <div class="py-2 p-1">
                                            <p class="module_sd">Expense</p>
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                            <p class="f-12 m-0"><i class="fa fa-check text-white" aria-hidden="true"></i> One-way & Connected <span class="text-white font-weight-bold"> Expense Bill</span> ({{YearlyPlan.plan_stats.expense_bill}})</p> 
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                            <p class="f-12 m-0"><i class="fa fa-check text-white" aria-hidden="true"></i> <span class="text-white font-weight-bold"> Expense Receipt</span> ({{YearlyPlan.plan_stats.expense_receipt}})</p> 
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                            <p class="f-12 m-0"><i class="fa fa-check text-white" aria-hidden="true"></i> One-way & Connected <span class="text-white font-weight-bold"> Expense Return</span> ({{YearlyPlan.plan_stats.expense_return}})</p> 
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                            <p class="f-12 m-0"><i class="fa fa-check text-white" aria-hidden="true"></i> <span class="text-white font-weight-bold">Items,</span> Stock Level Tracking, & <span class="text-white font-weight-bold">Bundles </span> ({{YearlyPlan.plan_stats.inventory_product}})</p> 
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                            <p class="f-12 m-0"><i class="fa fa-check text-white" aria-hidden="true"></i> Online Marketplace  ({{YearlyPlan.plan_stats.online_marketplace}})</p> 
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                            <p class="f-12 m-0"><i class="fa fa-check text-white" aria-hidden="true"></i> Cash Book</p> 
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                            <p class="f-12 m-0"><i class="fa fa-check text-white" aria-hidden="true"></i> Bank</p> 
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                            <p class="f-12 m-0"><i class="fa fa-check text-white" aria-hidden="true"></i> Credit Card</p> 
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                            <p class="f-12 m-0"><span class="text-white font-weight-bold">{{YearlyPlan.plan_stats.drive}} GB</span> Cloud Drive</p>
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                            <p class="f-12 m-0"><i class="fa fa-check text-white" aria-hidden="true"></i> Reports</p>
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                            <p class="f-12 m-0"><i class="fa fa-check text-white" aria-hidden="true"></i> Predefined User Roles</p>
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                            <p class="f-12 m-0"><i class="fa fa-check text-white" aria-hidden="true"></i> Business Documents Number Series</p>
                                        </div>
                                        <div class="p-1 border-btm-plan">
                                            <p class="f-12 m-0"><i class="fa fa-check text-white" aria-hidden="true"></i> In-APP and Email Alerts</p>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-center mt-2">
                                        <button class="btn btn-white-border" (click)="checkoutStripe(YearlyPlan)"> Upgrade</button>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #usagedeTail let-modal>
    <div class="b-r-20">
        <div class="modal-body">
            <div class="w-100 d-flex justify-content-between mb-2">
                <div class=" w-98">
                    <h6 class="text-dark font-weight-600 m-0">
                        {{'Usage Detail'|translate}}
                    </h6>
                </div>
                <div class="text-center">
                    <button type="button" class="btn-close f-12" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
                </div>
            </div>
            <div class="UsageTable">
                <table class="table mb-0">
                    <thead class="bg-primary">
                      <tr>
                        <th scope="col">{{currentPlan?.subscription?.name}}</th>
                        <th scope="col" class="text-center">Allowed</th>
                        <th scope="col" class="text-center">Used</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="proPlan"><b>No of Users</b></td>
                        <td class="text-center">{{currentPlan?.member_account}}</td>
                        <td class="text-center text-primary">{{currentPlan?.remaining_member_account}}</td>
                      </tr>
                      <tr>
                        <td class="proPlan"> Connected & One-Way <b class="text-white">Customer Accounts</b></td>
                        <td class="text-center">{{currentPlan?.customer_ledger}}</td>
                        <td class="text-center text-primary">{{currentPlan?.remaining_customer_ledger}}</td>
                      </tr>
                      <tr>
                        <td class="proPlan"> Connected & One-Way <b class="text-white">Vendor Accounts</b></td>
                        <td class="text-center">{{currentPlan?.vendor_ledger}}</td>
                        <td class="text-center text-primary">{{currentPlan?.remaining_vendor_ledger}}</td>
                      </tr>
                      <tr>
                        <td class="proPlan b-b-none">
                            <div>
                                <p class="module_sd"> Sale</p>
                            </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="proPlan">                                             
                            <p class="f-12 m-0"> One-way & Connected <span class="text-white font-weight-bold">Invoices</span></p> 
                        </td>
                        <td class="text-center">{{currentPlan?.invoice}}</td>
                        <td class="text-center text-primary">{{currentPlan?.remaining_invoice}}</td>
                      </tr>
                      <tr>
                        <td class="proPlan">                            
                            <b>Sales Receipt & Daily Sales Receipt</b>
                        </td>
                        <td class="text-center">{{currentPlan?.both_sale_daily_receipts}}</td>
                        <td class="text-center text-primary">{{currentPlan?.remaining_both_sale_daily_receipts}}</td>
                      </tr>
                      <tr>
                        <td class="proPlan">                            
                            <p class="f-12 m-0"> One-way & Connected <span class="text-white font-weight-bold">Estimates</span></p> 
                        </td>
                        <td class="text-center">{{currentPlan?.quotation}}</td>
                        <td class="text-center text-primary">{{currentPlan?.remaining_quotation}}</td>
                      </tr>
                      <tr>
                        <td class="proPlan">                            
                            <p class="f-12 m-0"> One-way & Connected <span class="text-white font-weight-bold"> Sales Return</span></p> 
                        </td>
                        <td class="text-center">{{currentPlan?.sale_return}}</td>
                        <td class="text-center text-primary">{{currentPlan?.remaining_sale_return}}</td>
                      </tr>
                      <tr>
                        <td class="proPlan">                            
                            <p class="f-12 m-0"> One-way & Connected <span class="text-white font-weight-bold"> Sales Order</span></p> 
                        </td>
                        <td class="text-center">{{currentPlan?.sales_order}}</td>
                        <td class="text-center text-primary">{{currentPlan?.remaining_sales_order}}</td>
                      </tr>
                      <tr>
                        <td class="proPlan">                            
                            <p class="f-12 m-0"> One-way & Connected <span class="text-white font-weight-bold">Packing Slip</span></p> 
                        </td>
                        <td class="text-center">{{currentPlan?.packing_slip}}</td>
                        <td class="text-center text-primary">{{currentPlan?.remaining_packing_slip}}</td>
                      </tr>
                      <tr>
                        <td class="proPlan b-b-none">
                            <div>
                                <p class="module_sd">Purchase</p>
                            </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="proPlan">                                             
                            <p class="f-12 m-0"> One-way & Connected <span class="text-white font-weight-bold"> Purchase Bill</span></p> 
                        </td>
                        <td class="text-center">{{currentPlan?.purchase_bill}}</td>
                        <td class="text-center text-primary">{{currentPlan?.remaining_purchase_bill}}</td>
                      </tr>
                      <tr>
                        <td class="proPlan">                                             
                            <p class="f-12 m-0"> One-way & Connected <span class="text-white font-weight-bold"> Purchase Order</span></p> 
                        </td>
                        <td class="text-center">{{currentPlan?.purchase_order}}</td>
                        <td class="text-center text-primary">{{currentPlan?.remaining_purchase_order}}</td>
                      </tr>
                      <tr>
                        <td class="proPlan">                                             
                            <p class="f-12 m-0"> One-way & Connected <span class="text-white font-weight-bold"> Purchase Return</span></p> 
                        </td>
                        <td class="text-center">{{currentPlan?.purchase_return}}</td>
                        <td class="text-center text-primary">{{currentPlan?.remaining_purchase_return}}</td>
                      </tr>
                      <tr>
                        <td class="proPlan b-b-none">
                            <div>
                                <p class="module_sd">Expense</p>
                            </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="proPlan">                                             
                            <p class="f-12 m-0"> One-way & Connected <span class="text-white font-weight-bold"> Expense Bill</span></p> 
                        </td>
                        <td class="text-center">{{currentPlan?.expense_bill}}</td>
                        <td class="text-center text-primary">{{currentPlan?.remaining_expense_bill}}</td>
                      </tr>
                      <tr>
                        <td class="proPlan">                                             
                            <p class="f-12 m-0"> One-way & Connected <span class="text-white font-weight-bold"> Expense Return</span></p> 
                        </td>
                        <td class="text-center">{{currentPlan?.expense_return}}</td>
                        <td class="text-center text-primary">{{currentPlan?.remaining_expense_return}}</td>
                      </tr>
                      <tr>
                        <td class="proPlan">                                             
                            <p class="f-12 m-0"><span class="text-white font-weight-bold"> Items</span>, Stock Level Tracking & <span class="text-white font-weight-bold"> Bundles</span></p> 
                        </td>
                        <td class="text-center">{{currentPlan?.inventory_product}}</td>
                        <td class="text-center text-primary">{{currentPlan?.remaining_inventory_product}}</td>
                      </tr>
                      <tr>
                        <td class="proPlan">                                             
                            <p class="f-12 m-0"><span class="text-white font-weight-bold"> Online Marketplace</span></p> 
                        </td>
                        <td class="text-center">{{currentPlan?.online_marketplace}}</td>
                        <td class="text-center text-primary">{{currentPlan?.remaining_online_marketplace}}</td>
                      </tr>
                      <tr>
                        <td class="proPlan">                                             
                            <p class="f-12 m-0"><span class="text-white font-weight-bold"> Cash Book</span></p> 
                        </td>
                        <td class="text-center"><i class="fa fa-check text-light f-w-500"></i></td>
                        <td class="text-center text-primary"><i class="fa fa-check text-primary f-w-500"></i></td>
                      </tr>
                      <tr>
                        <td class="proPlan">                                             
                            <p class="f-12 m-0"><span class="text-white font-weight-bold">Bank</span></p> 
                        </td>
                        <td class="text-center">{{currentPlan?.bank_account}}</td>
                        <td class="text-center text-primary">{{currentPlan?.remaining_bank_account}}</td>
                      </tr>
                      <tr>
                        <td class="proPlan">                                             
                            <p class="f-12 m-0"><span class="text-white font-weight-bold">Credit Card</span></p> 
                        </td>
                        <td class="text-center">{{currentPlan?.credit_card}}</td>
                        <td class="text-center text-primary">{{currentPlan?.remaining_credit_card}}</td>
                      </tr>
                      <tr>
                        <td class="proPlan">                                             
                            <p class="f-12 m-0"><span class="text-white font-weight-bold">Cloud Storage</span></p> 
                        </td>
                        <td class="text-center">{{currentPlan?.bank_account}}</td>
                        <td class="text-center text-primary">{{currentPlan?.remaining_bank_account}}</td>
                      </tr>
                      <tr>
                        <td class="proPlan">                                             
                            <p class="f-12 m-0"><span class="text-white font-weight-bold">Reports</span></p> 
                        </td>
                        <td class="text-center"><i class="fa fa-check text-light f-w-500"></i></td>
                        <td class="text-center text-primary"><i class="fa fa-check text-primary f-w-500"></i></td>
                      </tr>
                      <tr>
                        <td class="proPlan">                                             
                            <p class="f-12 m-0"><span class="text-white font-weight-bold">Predefined User Roles</span></p> 
                        </td>
                        <td class="text-center"><i class="fa fa-check text-light f-w-500"></i></td>
                        <td class="text-center text-primary"><i class="fa fa-check text-primary f-w-500"></i></td>
                      </tr>
                      <tr>
                        <td class="proPlan">                                             
                            <p class="f-12 m-0"><span class="text-white font-weight-bold">Business Documents Number Series</span></p> 
                        </td>
                        <td class="text-center"><i class="fa fa-check text-light f-w-500"></i></td>
                        <td class="text-center text-primary"><i class="fa fa-check text-primary f-w-500"></i></td>
                      </tr>
                      <tr>
                        <td class="proPlan">                                             
                            <p class="f-12 m-0"><span class="text-white font-weight-bold">In-App and Emails Alerts</span></p> 
                        </td>
                        <td class="text-center"><i class="fa fa-check text-light f-w-500"></i></td>
                        <td class="text-center text-primary"><i class="fa fa-check text-primary f-w-500"></i></td>
                      </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</ng-template>