<div class="ngb-modal">   
  <div class="modal-body">
      <div class="modal-header p-0 bb-none">
          <h5 class="modal-title" id="modal-basic-title"><img src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/Cash Icons/Search.svg" class="mx-2 img-25" alt="">Search</h5>
      </div>
      <div class="body">
        <div *ngIf="this.bookService.invoiceType!=11">
          <div class="d-flex justify-content-center mx-auto my-2 bg-gray w-70" *ngIf="this.bookService.invoiceType!=8 && this.bookService.invoiceType!=9 " >
            <div class="btn-group filterButtons bg-gray align-items-center w-100" id="myDIV">
                <button class="btn bcCustom w-50" type="button" (click)="toggleProductService('product', true)" [ngClass]="{'selected-button': selectedOptionProSer === 'product'}">{{'Product' | translate}}</button>
                <button class="btn bcCustom w-50" type="button" (click)="toggleProductService('service', false)" [ngClass]="{'selected-button': selectedOptionProSer === 'service'}">{{'Service' | translate}}</button>
            </div>
          </div>
          <ng-container *ngIf="this.bookService.invoiceType!=10">
            <div class="d-flex justify-content-center mx-auto my-2 bg-gray w-70" *ngIf="this.bookService.invoiceType!=11 && this.bookService.invoiceType!=2 && this.bookService.invoiceType!=7 && this.bookService.invoiceType!=8">
              <div class="btn-group filterButtons bg-gray align-items-center w-100" id="myDIV">
                  <button class="btn bcCustom w-50" type="button" (click)="togglesentReceived('sent');" [ngClass]="{'selected-button': selectedOption === 'sent'}">{{'Sent' | translate}}</button>
                  <button class="btn bcCustom w-50" type="button" (click)="togglesentReceived('received');" [ngClass]="{'selected-button': selectedOption === 'received'}">{{'Received' | translate}}</button>
              </div>
          </div>
          <div class="d-flex justify-content-center mx-auto my-2 bg-gray w-70" *ngIf="this.bookService.invoiceType==2 || this.bookService.invoiceType==8">
            <div class="btn-group filterButtons bg-gray align-items-center w-100" id="myDIV">
                <button class="btn bcCustom w-50" type="button" (click)="togglesentReceived('accepted')" [ngClass]="{'selected-button': selectedOption === 'accepted '}">{{'One-Way' | translate}}</button>
                <button class="btn bcCustom w-50" type="button" (click)="togglesentReceived('received')" [ngClass]="{'selected-button': selectedOption === 'received'}">{{'Received' | translate}}</button>
            </div>
        </div>
          <div class="d-flex justify-content-center mx-auto my-2 bg-gray w-70" *ngIf="this.bookService.invoiceType!=11 && this.bookService.invoiceType!=7 && this.bookService.invoiceType!=8">
              <div class="btn-group filterButtons bg-gray align-items-center w-100" id="myDIV">
                <button class="btn bcCustom w-50" type="button" (click)="toggleAcceptReject('All')" [ngClass]="{'selected-button': selectedOptionAR === 'All'}">{{'All' | translate}}</button>
                <button class="btn bcCustom w-50" type="button" (click)="toggleAcceptReject('accepted')" [ngClass]="{'selected-button': selectedOptionAR === 'accepted'}">{{'Accepted' | translate}}</button>
                <button class="btn bcCustom w-50" type="button" (click)="toggleAcceptReject('pending')" [ngClass]="{'selected-button': selectedOptionAR === 'pending'}">{{'Pending' | translate}}</button>
              </div>
          </div>
          <div class="d-flex justify-content-center mx-auto my-2 bg-gray w-70" *ngIf="this.bookService.invoiceType==7 && this.bookService.invoiceType!=8 && this.bookService.invoiceType!=9 ">
            <div class="btn-group filterButtons bg-gray align-items-center w-100" id="myDIV">
              <button class="btn bcCustom w-50" type="button" (click)="toggleAcceptReject('All')" [ngClass]="{'selected-button': selectedOptionAR === 'All'}">{{'All' | translate}}</button>
              <button class="btn bcCustom w-50" type="button" (click)="toggleAcceptReject('accepted')" [ngClass]="{'selected-button': selectedOptionAR === 'accepted'}">{{'Confirmed' | translate}}</button>
              <button class="btn bcCustom w-50" type="button" (click)="toggleAcceptReject('pending')" [ngClass]="{'selected-button': selectedOptionAR === 'pending'}">{{'Pending' | translate}}</button>
            </div>
        </div>
          <div class="d-flex justify-content-center mx-auto my-2 w-70" *ngIf="this.bookService.invoiceType!=3 && this.bookService.invoiceType!=12 && this.bookService.invoiceType!=11 && this.bookService.invoiceType!=7 && this.bookService.invoiceType!=5 && this.bookService.invoiceType!=9 && this.bookService.invoiceType!=6">
              <div class="btn-group paidupaid w-100">
                  <div class="w-20">
                      <button (click)="toggleFilter('')" [ngClass]="{'selected-button': selectedOptionfilter === 'all'}" class="btn b-r-25 bg-gray w-100"> All</button>
                  </div>
                  <div class="w-20">
                      <button (click)="toggleFilter(true)" [ngClass]="{'selected-button': selectedOptionfilter === 'paid'}" class="btn b-r-25 bg-gray w-100"> Paid</button>
                  </div>
                  <div class="w-25">
                      <button (click)="toggleFilter(false)" [ngClass]="{'selected-button': selectedOptionfilter === 'unpaid'}" class="btn b-r-25 bg-gray w-100"> Unpaid</button>
                  </div>
                  <div class="w-25">
                      <button (click)="toggleFilter('overdue')" [ngClass]="{'selected-button': selectedOptionfilter === 'overdue'}" class="btn b-r-25 bg-gray w-100"> Overdue</button>
                  </div>
              </div>
          </div>
          </ng-container>
           
        </div>
       
          <div class="d-flex justify-content-center mx-auto w-80">
              <div class="mt-2 d-flex align-items-center w-100">
                <!-- {{this.bookService.invoiceType}} -->
                  <div class=" w-100" *ngIf="this.bookService.invoiceType==1">
                    <label  for="floatingInput" class="f-14 text-light"><span>Search by Invoice Number</span></label>
                    <input class="form-control form-control-sm" id="floatingInput"  [(ngModel)]="filterInvoiceNumber" type="text">
                  </div>
                  <div class=" w-100" *ngIf="this.bookService.invoiceType==2">
                    <label  for="floatingInput" class="f-14 text-light"><span>Search by Purchase Bill Number</span></label>
                    <input class="form-control form-control-sm" id="floatingInput"  [(ngModel)]="filterInvoiceNumber" type="text">
                  </div>
                  <div class=" w-100" *ngIf="this.bookService.invoiceType==3">
                    <label  for="floatingInput" class="f-14 text-light"><span>Search by Estimate Number</span></label>
                    <input class="form-control form-control-sm" id="floatingInput"  [(ngModel)]="filterInvoiceNumber" type="text">
                  </div>
                  <div class=" w-100" *ngIf="this.bookService.invoiceType==4">
                    <label  for="floatingInput" class="f-14 text-light"><span>Search by Sales Return Number</span></label>
                    <input class="form-control form-control-sm" id="floatingInput"  [(ngModel)]="filterInvoiceNumber" type="text">
                  </div>
                  <div class=" w-100" *ngIf="this.bookService.invoiceType==5">
                    <label  for="floatingInput" class="f-14 text-light"><span>Search by Purchase Return Number</span></label>
                    <input class="form-control form-control-sm" id="floatingInput"  [(ngModel)]="filterInvoiceNumber" type="text">
                  </div>
                  <div class=" w-100" *ngIf="this.bookService.invoiceType==6">
                    <label  for="floatingInput" class="f-14 text-light"><span>Search by Purchase Order Number</span></label>
                    <input class="form-control form-control-sm" id="floatingInput"  [(ngModel)]="filterInvoiceNumber" type="text">
                  </div>
                  <div class=" w-100" *ngIf="this.bookService.invoiceType==7">
                    <label  for="floatingInput" class="f-14 text-light"><span>Search by Packing Slip Number</span></label>
                    <input class="form-control form-control-sm" id="floatingInput"  [(ngModel)]="filterInvoiceNumber" type="text">
                  </div>
                  <div class=" w-100" *ngIf="this.bookService.invoiceType==10">
                    <label  for="floatingInput" class="f-14 text-light"><span>Search by Receipt Number</span></label>
                    <input class="form-control form-control-sm" id="floatingInput"  [(ngModel)]="filterInvoiceNumber" type="text">
                  </div>
                  <div class=" w-100" *ngIf="this.bookService.invoiceType==11">
                    <label  for="floatingInput" class="f-14 text-light"><span>Search by Receipt Number</span></label>
                    <input class="form-control form-control-sm" id="floatingInput"  [(ngModel)]="filterInvoiceNumber" type="text">
                  </div>
                  <div class=" w-100" *ngIf="this.bookService.invoiceType==12">
                    <label  for="floatingInput" class="f-14 text-light"><span>Search by Sale Order Number</span></label>
                    <input class="form-control form-control-sm" id="floatingInput"  [(ngModel)]="filterInvoiceNumber" type="text">
                  </div>
                 
              </div>
          </div>
          <div class="d-flex justify-content-center mx-auto mt-2 w-80" *ngIf="this.bookService.invoiceType != 10">
              <div class="w-100">                         
                  <div class=" w-100">
                    <div *ngIf="this.bookService.invoiceType==2">
                      <label  for="floatingInput" class="f-14 text-light"><span>Choose Vendor</span></label> 
                    </div>
                    <div *ngIf="this.bookService.invoiceType!=2" >
                      <label for="floatingInput" class="f-14 text-light"><span>Choose Customer</span></label>  
                    </div>
                                               
                    <input class="form-control form-control-sm" type="text" id="floatingInput" (keyup)="searchCustomer()" [(ngModel)]="querySearchValuesCust" [ngModelOptions]="{standalone: true}"> 
                  </div>  
                  <div *ngIf="CustloadData==true" class="shadow-27">                                                 
                      <div class="cursor-pointer" *ngFor="let item of this.bookService?.allBusinessList">                                                      
                          <div class="w-100 px-1 f-14" (click)="getCustVendVal(item,0);selectBusiness(item)">                                                         
                              {{item?.with_business?.business_name}}                                                      
                          </div>
                      </div>                                              
                  </div>     
              </div>              
          </div>
          <div class=" mx-auto mt-2 w-80" *ngIf="selectedOptionProSer=='product'">
            <div *ngIf="this.bookService.invoiceType!=11 && this.bookService.invoiceType!=8">
              <p class="f-14 m-0 text-light">{{'Categories' | translate}}</p>
              <div class="cursor-pointer m-0 border borderR-4px w-100 form-control-sm">
                  <div class="d-flex justify-content-between" (click)="openaddnew(addnew)">
                    <p *ngIf="!categoryName" class="f-14 m-0 text-light">{{'Choose Categories' | translate}}</p>
                      <p *ngIf="categoryName" class="f-14 m-0 text-dark">{{categoryName}}</p>
                      <p class=" m-0"><i class="fa fa-angle-down f-20 text-light"></i></p>
                  </div>             
              </div>
            </div>
            <div  *ngIf="this.bookService.invoiceType==8">
              <!-- <label for="" class="text-primary">Categories</label> -->
              <div class="remarks">
                <h6 class="m-0 f-11">{{'Categories' | translate }}</h6>
              </div>
              <div class="cursor-pointer w-100 m-0 py-2 mt-2 b-r-5 border br-light">
                <div class="d-flex justify-content-between px-2 w-100" (click)="openaddnewEx(addnewEx)">
                  <p *ngIf="!categoryName" class="f-14 m-0 text-dark">{{'Categories' | translate}}</p>
                  <p *ngIf="categoryName" class="f-14 m-0 text-dark">{{categoryName}}</p>
                  <!-- <p class="f-14 m-0 text-dark">{{expCategories}}</p> -->
                </div>
              </div>
            </div>
          </div>
       
          <div class="d-flex justify-content-center mx-auto mt-2 w-80" *ngIf="this.bookService.invoiceType!=11">
              <div class="w-100">                         
                  <div class=" w-100"  *ngIf="item1!='item1'">
                    <label for="floatingInput" class="f-14 text-light"><span>Item Name</span></label>
                    <input class="form-control form-control-sm" type="text" id="floatingInput" (keyup)="searchFilters($event,0,false)" [(ngModel)]="querySearchValues" [ngModelOptions]="{standalone: true}">              
                  </div>
                  <label  class="f-14 text-light" *ngIf="item1=='item1'"><span>Item Name</span></label>  
                  <div class="p-q1 border b-r-4" *ngIf="item1=='item1'">
                    <div class="w-50">
                      <div class="d-flex justify-content-between align-items-center badge bdgee bg-invoice text-white">
                        <div class="f-14">{{querySearchValues}}</div>
                        <button type="button" class="btn p-0 ml-1 close text-white" (click)="Closeitem(0)"><span aria-hidden="true">×</span></button>
                      </div>
                    </div>
                  </div> 
                  <div *ngIf="loadData==true" class="shadow-27">                                                 
                      <div  class="bundle-div" >
                          <ng-container *ngIf="listing$ | async as listing ">
                              <ng-container *ngFor="let item of this.ledgerService.searchList">                                                     
                                  <div class="border-listing">                                                      
                                      <div class="d-flex justify-content-between p-2">
                                          <div class="f-14" (click)="getItemVal(item)">                                                         
                                              {{item?.product_name}}                                                      
                                          </div>
                                          <!-- <div class="f-14 text-primary">
                                              {{item?.unit_price}}  
                                          </div>    -->
                                      </div>                                                   
                                  </div>
                              </ng-container>
                          </ng-container>
                      </div>                                        
                  </div>
              </div>              
          </div>
          <div class="d-flex justify-content-center mx-auto mt-2 w-80">
              <div class="d-flex justify-content-center bg-gray">
                  <div class="btn-group filterButtons bg-gray align-items-center w-100" id="myDIV" (click)="activeunc()">
                      <img class="img-15 cursor-pointer mx-2" src="https://s3.amazonaws.com/accountect.assets/ciwacAssets/Cash Icons/Filter.svg">
                      <button class="btn bcCustom " type="button" [ngClass]="{'bg-btn-primary' :  timeFilter=== 'today'}" (click)="TimeFIlter('today')">Today</button>
                      <button class="btn bcCustom " type="button" [ngClass]="{'bg-btn-primary' :  timeFilter === 'week'}"  (click)="TimeFIlter('week')">Week</button>
                      <button class="btn bcCustom " type="button" [ngClass]="{'bg-btn-primary' :  timeFilter === 'month'}" (click)="TimeFIlter('month')">Month</button>
                      <button class="btn bcCustom " type="button" [ngClass]="{'bg-btn-primary' :  timeFilter === 'year'}" (click)="TimeFIlter('year')">Year</button>
                      <button class="btn bcCustom " type="button" [ngClass]="{'bg-btn-primary' :  timeFilter === 'all'}" (click)="TimeFIlter('all')">All</button> 
                  </div>
              </div>
          </div>
          <div class="d-flex justify-content-center mx-auto mt-2 w-80">
              <div class="w-49 mx-auto datepicker py-2">
                  <div class="form-inline custom-datepicker custom-datepicker-dropdown">
                    <div class="form-group m-0">
                      <div class="input-group">
                        <div class="input-group-append rtl" *ngIf="auth.dirWeb === 'rtl'">
                          <button (click)="fvalid.toggle()" class="btn calendar" type="button"><img src="../../../../assets/Accountect/calendar.svg" class="img-18" alt=""></button>
                        </div>
                        <!-- <input #fvalid="ngbDatepicker" class="form-control cursor-pointer border-right-none" name="dp" (click)="fvalid.toggle()" ngbDatepicker
                          [placeholder]="'From'|translate" readonly> -->
                          <input #fvalid="ngbDatepicker" 
                          class="form-control form-control-sm cursor-pointer border-right-none" 
                          name="dp" 
                          (click)="fvalid.toggle()" 
                          [(ngModel)]="selectedDate" 
                          (ngModelChange)="onDateSelect($event)" 
                          ngbDatepicker 
                          [placeholder]="'From'|translate" 
                          readonly>
                        <div class="input-group-append ltr" *ngIf="auth.dirWeb === 'ltr'">
                          <button (click)="fvalid.toggle()" class="btn calendar border-left-none px-2 pt-1" type="button">
                            <img src="../../../../assets/Accountect/calendar.svg" alt="">
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>

              <div class="w-49 mx-auto datepicker py-2">
                <div class="form-inline custom-datepicker custom-datepicker-dropdown">
                  <div class="form-group m-0">
                    <div class="input-group">
                      <div class="input-group-append rtl" *ngIf="auth.dirWeb === 'rtl'">
                        <button (click)="valid.toggle()" class="btn calendar" type="button">
                          <img src="../../../../assets/Accountect/calendar.svg" class="img-18" alt="">
                        </button>
                      </div>
                      <input #valid="ngbDatepicker"
                             [(ngModel)]="selectedDateTo"
                             (ngModelChange)="onDateSelectTo($event)"
                             class="form-control form-control-sm cursor-pointer border-right-none"
                             name="dp"
                             (click)="valid.toggle()"
                             ngbDatepicker
                             [placeholder]="'To'|translate"
                             readonly>
                      <div class="input-group-append ltr" *ngIf="auth.dirWeb === 'ltr'">
                        <button (click)="valid.toggle()" class="btn calendar" type="button">
                          <img src="../../../../assets/Accountect/calendar.svg" alt="">
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
          </div>
          <div class="d-flex justify-content-between mx-auto mt-2 w-90">
              <button class="btn bg-white shadow-white text-dark w-25" (click)="reset()">
                  Reset
              </button>
              <button class="btn bg-invoice w-70" (click)="AppyFilters(); modal.dismiss()">
                  Apply
              </button>
          </div>
      </div>
  </div>
</div>



<ng-template #addnewEx let-modal>
  <div class="modal-header">
    <h5 class="modal-title f-16" id="modal-basic-title">
      <img src="../../../../assets/Accountect/Add Button.svg" class="img-25" alt="">
      {{'Choose Category' | translate}}
    </h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <!-- <div class="px-5">
          <input (keyup.enter)="getExpenseCategories($event?.target?.value)"  type="text" class="form-control bg-light" [placeholder]="'Search' | translate">
      </div> -->

    <div class="h-250" (scroll)="onScrollCat($event)">
      <div *ngIf="categoryListingLoader" class="overlay-loader">
        <div class="spinner-border spinner-border-sm text-primary m-auto" role="status">
        </div>
      </div>
      <ng-container *ngIf="!categoryListingLoader">
        <!-- <div *ngFor="let item of categoryListing" class="d-flex CategoryCat align-items-center w-100 px-2 position-relative hover-opacity">

            <div (click)="categoryAddToExpenseForm(item, modal)" class="d-flex justify-content-between w-100 py-2 border-btm">
                <div class="edit">
                    {{item?.title}}
                </div>
            </div>
          </div> -->

        <div *ngFor="let item of categoryListing; let i = index" (click)="categoryAddToExpenseForm(item, modal)"
          class="ShadowPayment mx-2 bg-light payment d-flex justify-content-between mt-2">
          <div class="form-check w-100 position-relative d-flex flex-row-reverse p-0">
            <input class="form-check-input cursor-pointer" type="radio" name="flexRadioDefaultas"
              [id]="'flexRadioDefaultas'+i">
            <label class="form-check-label w-100 cursor-pointer text-capitalize" [for]="'flexRadioDefaultas'+i">
              {{item?.title}}
            </label>
          </div>
        </div>

      </ng-container>
    </div>
  </div>

</ng-template>





<ng-template #addnew let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="modal-basic-title">
      <img src="../../../../assets/Accountect/Add Button.svg" alt="">
        {{'Add Category' | translate}}
    </h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="h-330">
      <div class="mx-2">
        <div *ngIf="categoryListingLoader" class="overlay-loader">
          <div class="spinner-border spinner-border-sm text-primary m-auto" role="status">
          </div>
        </div>
        <ng-container *ngIf="!categoryListingLoader">
          <div *ngFor="let item of ledgerService?.invnCat" class="ShadowPayment bg-light payment d-flex justify-content-between mt-2">

            <div (click)="categoryAddToExpenseForm(item, modal)"
              class="d-flex justify-content-between w-100 py-2 border-btm">
              <div class="edit">
                {{item?.title}}
              </div>
            </div>
            <div class="w-5 align-self-center py-2 text-end">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z"/>
              </svg>
            </div>

          </div>
        </ng-container>
      </div>
    </div>
  </div>

</ng-template>